import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AlertController, IonicSafeString, Platform } from '@ionic/angular';
import { PaymentService } from '../../services/payment.service';
import { Events } from '../../services/events.service';
import { MediaService } from '../../services/media.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Keyboard } from '@capacitor/keyboard';

import { User } from '../../interfaces/user';
import { Wallet } from 'src/app/interfaces/wallet';
import { filter, mapTo } from 'rxjs/operators';
import { combineLatest, forkJoin } from 'rxjs';

@Component({
  selector: 'app-send-stars',
  templateUrl: './send-stars.component.html',
  styleUrls: ['./send-stars.component.scss'],
})
export class SendStarsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('content', {static: false}) content: ElementRef;

  @Input() user: User = undefined;
  @Input() userId: number = undefined;
  @Input() origin = 'private';
  @Input() idOrigin = null;
  @Input() subText: boolean = true;

  @Output() sent = new EventEmitter;
  @Output() close = new EventEmitter;

  customValue: string;

  sending: boolean = false;
  sendingAmmount: number | undefined = undefined;
  sendingCustomAmmount: boolean = false;

  ready: boolean = true;

  keyboardHeight: number = 0;
  keyboardOnScreen: boolean = false;

  listener: any = {};

  wallet: Wallet;

  sub: any = {};

  constructor(
    private alertController: AlertController,
    private paymentService: PaymentService,
    private events: Events,
    private mediaService: MediaService,
    private router: Router,
    private platform: Platform,
    private cdRef: ChangeDetectorRef
  ) {
    if (this.platform.is('hybrid')) {
      this.listener.keyboardWillShow = Keyboard.addListener('keyboardWillShow', info => {
        this.keyboardOnScreen = true;
        // @ts-ignore
        this.keyboardHeight = info.keyboardHeight;

        this.cdRef.detectChanges();

        setTimeout(() => {
          this.content.nativeElement.scrollTop = this.content.nativeElement.scrollHeight;
          // this.content.nativeElement.scrollIntoView({block: 'end', behavior: 'smooth'});
        }, 300);
      });

      this.listener.keyboardWillHide = Keyboard.addListener('keyboardWillHide', () => {
        this.keyboardOnScreen = false;
        this.keyboardHeight = 0;

        this.cdRef.detectChanges();
      });
    }
  }

  ngOnInit() {
    this.sub.wallet = this.paymentService.getWallet()
    .pipe(filter(wallet => !!wallet))
    .subscribe(wallet => {
      this.wallet = wallet;
    });


    this.sub.ready = combineLatest([
      this.paymentService.walletStatus, 
      this.paymentService.sendStarsStatus
    ]).subscribe(([walletStatus, sendStarsStatus]) => {
      this.ready = walletStatus === 'ready' && sendStarsStatus === 'ready';
    });
  }

  ngOnDestroy() {
    this.listener?.keyboardWillShow?.remove();
    this.listener?.keyboardWillHide?.remove();
    this.sub.wallet?.unsubscribe();
    this.sub.ready?.unsubscribe();
  }

  ngAfterViewInit() {
    const scrollcontainer = this.content.nativeElement;

    scrollcontainer.ontouchmove = (e) => {
      if ( this.content.nativeElement.scrollTop !== 0 )
        e.stopPropagation();
    };
  }

  async presentAlertSendStars(stars) {
    let quantity = parseInt(stars);

    if (quantity === 0 || quantity === undefined || this.sending) {
      return;                              // BREAK EXECUTION
    }

    const quantityInCredits = quantity * 0.02;

    let buttons;

    if (quantityInCredits <= this.getCreditsBalance()) {
      buttons = [
        {
          text: 'Aceptar',
          handler: () => {
            this.sendStars(quantity);
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel'
        }
      ];
    } else {
      buttons = [
        {
          text: 'Aceptar',
          handler: () => {
            this.alertController.dismiss();
          }
        }
      ];
    }

    const alert = await this.alertController.create({
      cssClass: 'alert-with-icons send-stars',
      header: 'Enviar estrellas',
      message: quantityInCredits <= this.getCreditsBalance() 
        ? new IonicSafeString(`<span class="bigger">Vas a enviar <span class="inline"><b>${quantity}</b> <img handleError class="star-icon" src="/assets/star-l.png" /></span></span><br>Equivalente a <span class="inline"><b>${+parseFloat(quantityInCredits.toString()).toFixed(2)}</b> <img handleError class="coin-icon" src="assets/coin.svg"/></span>`) 
        : 'No tienes suficientes créditos',
      buttons
    });

    await alert.present();
  }

  getImgProfile() {
    return this.mediaService.generateImgProfileURL(this.user.id, this.user.imgProfile);
  }
  getCreditsBalance() {
    if (this.wallet) {
      return this.wallet.balance;
    } else {
      return 0;
    }
  }

  sendStars(quantity) {
    this.sending = true;
    this.sendingAmmount = Number(quantity);

    this.paymentService.sendStars(quantity, this.userId, this.origin, this.idOrigin).subscribe(
      res => {
        if (res.done) {
          this.sent.emit(quantity);
        } else {
          this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, res.msg);
        }

        this.sending = false;
        this.sendingAmmount = undefined;
        this.sendingCustomAmmount = false;
      }, error => {
        this.sending = false;
        this.sendingAmmount = undefined;
        this.sendingCustomAmmount = false;
      });
  }

  recharge() {
    this.close.emit();
    this.router.navigate(['/payment/add-tokens']);
  }

  getSensStarsPaddingBottom() {
    if (this.keyboardOnScreen && this.keyboardHeight > 141) {
      // 141 es el alto del div que contiene las estrellas en el coponente send-stars
      return 'calc(' + Math.abs(this.keyboardHeight - 141) + 'px + 32px)';
    } else {
      return 'calc(env(safe-area-inset-bottom) + 20px)';
    }
  }
}
