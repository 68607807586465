import {
  Component,
  OnDestroy,
  OnChanges,
  AfterViewInit,
  AfterViewChecked,
  SimpleChanges,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  NgZone,
  ChangeDetectionStrategy
} from '@angular/core';
import { User } from '../../interfaces/user';
import { MediaService } from '../../services/media.service';
import { environment } from '../../../environments/environment';
import { Events } from '../../services/events.service';
import { PaymentService } from '../../services/payment.service';
import { ProfileService } from '../../services/profile.service';
import { AuthService } from '../../services/auth.service';
import { AlertController } from '@ionic/angular';
import { inOutFade } from '../../animations/animations';
import { trigger, transition, animate, style } from '@angular/animations';
import { FeedService } from '../../services/feed.service';
import { UserConfig } from '../../interfaces/userConfig';
import { SettingsService } from '../../services/settings.service';
import { UserService } from '../../services/user.service';
import { ViewerType } from '../../enums/viewer-type';
import {goToUserProfile} from '../../utils/routing';
import {Router} from '@angular/router';
import { LiveKitClient } from 'src/app/services/livekit.service';
import { Preferences } from '@capacitor/preferences';
import { FilePickerService } from 'src/app/services/file-picker.service';
import { ContentType } from 'src/app/enums/content-type';
import { DomSanitizer } from '@angular/platform-browser';
import { CacheData } from 'src/app/enums/cache-data';
import { take } from 'rxjs/operators';

export type Orientation = ('prev' | 'next' | 'none');

enum PickTarget {
  Banner,
  Avatar
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: environment.isWeb ? ['./profile-header.component.scss', './profile-header-desktop.component.scss'] : ['./profile-header.component.scss'],
  animations: [
    inOutFade,
    trigger('inOutAnimation', [
      transition('next => prev', // <--- Leaving <---
        [
          style({transform: 'translateX(0%)'}),
          animate('300ms ease', style({transform: 'translateX(-100%)'}))
        ]),
      transition('prev => next', // ---> Leaving --->
        [
          style({transform: 'translateX(0%)'}),
          animate('300ms ease', style({transform: 'translateX(+100%)'}))
        ]),
      transition('* => prev', // ---> Entering --->
        [
          style({transform: 'translateX(-100%)'}),
          animate('300ms ease', style({transform: 'translateX(0%)'}))
        ]),
      transition('prev => *', // ---> Leaving --->
        [
          style({transform: 'translateX(0%)'}),
          animate('300ms ease', style({transform: 'translateX(+100%)'}))
        ]),
      transition('* => next', // <--- Entering <---
        [
          style({transform: 'translateX(+100%)'}),
          animate('300ms ease', style({transform: 'translateX(0%)'}))
        ]),
      transition('next => *', // <--- Leaving <---
        [
          style({transform: 'translateX(0%)'}),
          animate('300ms ease', style({transform: 'translateX(-100%)'}))
        ]),
    ]),
  ]
})
export class ProfileHeaderComponent implements OnDestroy, OnChanges, AfterViewInit, AfterViewChecked {

  @ViewChild('step') step: ElementRef;

  @Input() user: User;
  @Input() itsMe = false;
  @Input() reduced = false;
  @Input() userBlocked = false;
  @Input() showSuggestions = false;
  @Input() isCreator = false;
  @Input() isCollaborator = false;
  @Input() isSubscribed = false;
  @Input() canInvite = false;
  @Input() onlyAudio = false;
  @Input() canPing = false;
  @Input() canSendStars = false;
  @Input() skeleton = false;
  @Input() checkTextColorOverBanner: boolean = false;
  @Input() canKick: boolean = true;
  @Input() canSilent: boolean = true;

  @Output() showFollowers = new EventEmitter();
  @Output() showFollowed = new EventEmitter();
  @Output() showCollaborators = new EventEmitter();
  @Output() toggleFollowUser = new EventEmitter();
  @Output() block = new EventEmitter();
  @Output() unlock = new EventEmitter();
  @Output() silent? = new EventEmitter();
  @Output() kick = new EventEmitter();
  @Output() sendStars = new EventEmitter();
  @Output() sendInviteUser = new EventEmitter();
  @Output() sendPingUser = new EventEmitter();
  @Output() closed = new EventEmitter();
  @Output() textOverBannerShouldBeWhite = new EventEmitter()
  @Output() userReported = new EventEmitter();
  @Output() userUpdated = new EventEmitter();

  softLoading = false;
  fullBiography = false;
  recommendedUserList: User[] = [];
  userConfig: UserConfig;

  sub: any = {};

  animationDirection: Orientation;
  settingsSlideStep = 0;
  stepsContainerHeight: number;

  viewInited = false;

  kickOptions: any = {};

  stepsHeightTimeout = undefined;
  deletedAllMessages: boolean;

  pickTarget: PickTarget;

  uploadingAvatar: boolean = false;
  uploadingBanner: boolean = false;

  get userValidated() {
    return this.authService.validated;
  }
  
  constructor(
    private mediaService: MediaService,
    private events: Events,
    private paymentService: PaymentService,
    private profileService: ProfileService,
    private authService: AuthService,
    private cdRef: ChangeDetectorRef,
    private alertController: AlertController,
    private feedService: FeedService,
    private settingsService: SettingsService,
    private hostElement: ElementRef,
    private userService: UserService,
    private router: Router,
    private liveKit: LiveKitClient,
    private zone: NgZone,
    private filePickerService: FilePickerService,
    private domSanitizer: DomSanitizer
  ) {
    this.animationDirection = 'none';
  }

  async ngOnInit() {
    if ( this.reduced ) {
      // If user not complete, try loading profile from cache
      const cachedUser = await this.userService.getCachedUser(this.user.username)

      if ( !this.userService.userDataIsComplete(this.user) && cachedUser ) {
        this.user = cachedUser;
      } else if ( !this.userService.userDataIsComplete(this.user) && !cachedUser ) {
        this.skeleton = true;
      }
      
      // Fetch data from server
      this.authService.getExtendedUserDataByUsername(this.user.username)
      .subscribe((user: User) => {
        this.user = user;
        this.skeleton = false;
        this.cdRef.detectChanges();
      })
    }

    this.sub.user = this.userService.userStatusChanged.subscribe((user: User) => {
      if ( user.id === this.user.id ) {
        this.user = user;
        this.cdRef.detectChanges();
      }
    });
  }

  ngOnDestroy() {
    this.sub.subscribed?.unsubscribe();
    this.sub.filePicked?.unsubscribe();
    this.sub.user?.unsubscribe();
    clearTimeout(this.stepsHeightTimeout);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.user && this.user) {
      this.activateStep(0);

      if (!this.reduced) {
        this.feedService.loadRecommendedUsers().subscribe((recommendedUsers) => {
          this.recommendedUserList = recommendedUsers.filter(rec => rec.id !== this.user.id);
          this.cdRef.detectChanges();
        });
      }
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.viewInited = true;
      this.cdRef.detectChanges();
    });
  }

  ngAfterViewChecked() {
    this.zone.runOutsideAngular(() => {
      clearTimeout(this.stepsHeightTimeout);

      this.stepsHeightTimeout = setTimeout(() => {
        const height = this.step?.nativeElement.getBoundingClientRect().height;

        if (height !== this.stepsContainerHeight) {
          this.stepsContainerHeight = height;
          this.cdRef.detectChanges();
        }
      }, 10);
    });
  }

  getBadgeSrc() {
    return this.mediaService.getBadgeSrc(this.user);
  }

  toggleFullBiography() {
    this.fullBiography = !this.fullBiography;
  }

  getImgProfile() {
    return this.mediaService.generateImgProfileURL(this.user.id, this.user.imgProfile);
  }

  getImgBanner() {
    return this.mediaService.generateImgProfileURL(this.user.id, this.user.profileCover, false);
  }

  activateStep(step, ...args) {
    if (this.settingsSlideStep === step) {
      return;
    }

    // Check steps direction effect
    if (this.settingsSlideStep < step) {
      this.animationDirection = 'next';
      this.cdRef.detectChanges();
    } else {
      this.animationDirection = 'prev';
      this.cdRef.detectChanges();
    }

    if ( step === 3 ) {
      this.kickOptions.deleteMessages = args[0];
    }

    // Store current step
    this.settingsSlideStep = step;
    this.cdRef.detectChanges();
  }

  async followButtonClick() {
    if (!this.reduced) {
      this.toggleFollowUser.emit(this.user);
    } else {
      if (!this.userFollowingStatus()) {
        this.followUser();
      } else {
        await this.presentAlertUnfollow();
      }
    }
  }

  subscribeButtonClick() {
    this.userService.subscribeToUser(this.user);
  }

  collaboratorButtonClick() {
    this.userService.collaborateWithUser(this.user);
  }

  showFounder() {
    this.userService.openFounderModal(this.user);
  }

  showExperience() {
    this.userService.openExperienceModal(this.user);
  }

  getRouteGoToProfile() {
    return goToUserProfile(this.router.url, this.user.username);
  }

  blockUserClick() {
    if (!this.reduced) {
      this.block.emit();
      this.closed.emit();
    } else {
      this.presentAlertBlockUser();
    }
  }

  async silentUserClick() {
    this.silent?.emit();
    this.closed.emit();
  }

  unlockUserClick() {
    if (!this.reduced) {
      this.unlock.emit();
      this.closed.emit();
    } else {
      this.presentAlertUnlockUser();
    }
  }

  async presentAlertUnfollow() {
    let closed = false;

    const alert = await this.alertController.create({
      cssClass: 'my-custom-alert-class',
      header: 'Dejar de seguir',
      message: '¿Quieres dejar de seguir a este usuario?',
      buttons: [
        {
          text: 'Dejar de seguir',
          handler: () => {
            this.unfollowUser();
            closed = true;
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel'
        }
      ]
    });

    await alert.present();
    const {data} = await alert.onDidDismiss();
    return closed;
  }

  async presentAlertBlockUser() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-alert-class',
      header: 'Bloquear usuario',
      message: '¿Estás seguro de que quieres bloquear a este usuario?',
      buttons: [
        {
          text: 'Bloquear',
          cssClass: 'alert-button-danger',
          handler: () => {
            this.softLoading = true;

            this.profileService.blockUser(this.user.id).subscribe(
              res => {
                if (res.done) {
                  this.events.publish(environment.EVENTS.ALERT_TOAST_NOTICE, 'Usuario bloqueado con éxito');
                  this.userBlocked = true;
                  this.activateStep(0);
                  this.closed.emit();
                } else {
                  this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR,
                    'Ha ocurrido un error al intentar bloquear al usuario, vuelve a intentarlo');
                }
                this.softLoading = false;
              }, error => {
                this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error, vuelve a intentarlo');

                this.softLoading = false;
              });
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'alert-button-dark'
        }
      ]
    });

    await alert.present();
  }


  async presentAlertUnlockUser() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-alert-class',
      header: 'Desbloquear usuario',
      message: '¿Estás seguro de que quieres desbloquear a este usuario?',
      buttons: [
        {
          text: 'Desbloquear',
          cssClass: 'alert-button-success',
          handler: () => {
            this.softLoading = true;

            this.profileService.unblockUser(this.user.id).subscribe(
              data => {
                if (data.msg === 'Ok') {
                  this.events.publish(environment.EVENTS.ALERT_TOAST_NOTICE, 'Usuario desbloqueado con éxito');
                  this.userBlocked = false;
                  this.activateStep(0);
                  this.closed.emit();
                } else {
                  this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR,
                    'Ha ocurrido un error al intentar desbloquear al usuario, vuelve a intentarlo');
                }

                this.softLoading = false;
              }, () => {
                this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR);

                this.softLoading = false;
              });
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'alert-button-dark'
        }
      ]
    });

    await alert.present();
  }

  followUser() {
    this.user.numFollowers++;
    this.user.following = true;

    this.profileService.follow(this.user.id, true).subscribe(
      (data) => {
        if (!data || !data.following) {
          this.user.numFollowers--;
          this.user.following = false;
        } else {
          this.userUpdated.emit(this.user);
          this.closed.emit();
        }

        this.cdRef.detectChanges();
      }, (error) => {
        this.user.numFollowers--;
        this.user.following = false;
        this.cdRef.detectChanges();
      });
  }

  unfollowUser() {
    this.user.numFollowers--;
    this.user.following = false;

    this.profileService.follow(this.user.id, false).subscribe(
      (data) => {
        if (!data || data.following) {
          this.user.numFollowers++;
          this.user.following = true;
        } else {
          this.userUpdated.emit(this.user);
          this.closed.emit();
        }

        this.cdRef.detectChanges();
      }, (error) => {
        this.user.numFollowers++;
        this.user.following = true;
        this.cdRef.detectChanges();
      });
  }

  triggerFollowers() {
    if (this.itsMe || (this.user.numFollowers && this.user.numFollowers > 0)) {
      this.showFollowers.emit();
    }
  }

  triggerFollowed() {
    if (this.itsMe || (this.user.numFollowed && this.user.numFollowed > 0)) {
      this.showFollowed.emit();
    }
  }

  triggerCollaborators() {
    if (this.itsMe || (this.user.numCollaborators && this.user.numCollaborators > 0)) {
      this.showCollaborators.emit();
    }
  }

  kickUser(timeKick: number/*, time: number*/) {
    this.kickOptions.deleteMessages = this.deletedAllMessages;
    this.kickOptions.timeKick = timeKick;
    this.kick.emit(this.kickOptions);
    this.closed.emit();
  }

  kickConfig(deletedAllMessages: boolean){
    this.deletedAllMessages = deletedAllMessages;
  }

  async inviteUser() {
    if(this.inLiveStreaming()){ return; }

    if(this.onlyAudio){
      this.sendInviteUser.emit(0);
      this.closed.emit();
      return;
    }
    const data = await this.userService.inviteUser(this.user, this.getViewerType());

    if ( data !== undefined ) {
      this.sendInviteUser.emit(data.type);
      this.closed.emit();
    }
  }

  async pingUser() {
    const data = await this.userService.pingUser(this.user, undefined, this.getViewerType());

    if ( data !== undefined ) {
      this.sendPingUser.emit(data.message);
    }
  }

  getViewerType(): ViewerType {
    let viewerType = ViewerType.Random;

    if ( this.isCreator )
      {viewerType = ViewerType.Creator;}

    if ( this.isCollaborator )
      {viewerType = ViewerType.Collaborator;}

    if ( this.isSubscribed )
      {viewerType = ViewerType.Subscriber;}

    return viewerType;
  }

  inLiveStreaming(): boolean{
    try {
      const participants = this.liveKit.getEmitters();
      const participant = participants.find(p=> JSON.parse(p.metadata).id === this.user.id);
      return !!participant;

    } catch (error) {
      console.log('inLiveStreaming->', error);
    }
    return false;
  }

  onBannerLoad($event) {
    if ( this.checkTextColorOverBanner ) {
      setTimeout(() => {
        this.textOverBannerShouldBeWhite.emit(
          this.mediaService.shouldTextBeWhiteOverImage($event)
        )
      }, 100);
    }
  }

  async report() {
    const report = await this.userService.report(this.user.id);

    if ( report ) {
      this.userReported.emit(report);
    }
  }

  userFollowingStatus(): boolean {
    return this.profileService.getFollowingStatusByUser(this.user);
  }

  canEditProfile(): boolean {
    return this.itsMe && !this.reduced;
  }

  /**
   * Pick banner image
   */
  pickBanner() {
    this.pickTarget = PickTarget.Banner;
    this.subscribeFilePick();
    this.filePickerService.pickFile(ContentType.Image);
  }

  /**
   * Pick avatar image
   */
  pickAvatar() {
    this.pickTarget = PickTarget.Avatar;
    this.subscribeFilePick();
    this.filePickerService.pickFile(ContentType.Image);
  }

  subscribeFilePick() {
    this.sub.filePicked?.unsubscribe();
    
    // Subscribe to poster file pick
    this.sub.filePicked = this.filePickerService.filePicked
    .pipe(take(1))
    .subscribe(async (file: File) => {
      console.log(file);
      if ( file !== undefined && file.type.includes('image') ) {
        // Ask user to crop
        const croppedImage = await this.mediaService.generateUserCroppedImage(file, this.pickTarget === PickTarget.Avatar ? 'profile' : 'banner');

        if ( this.pickTarget === PickTarget.Banner ) {
          const banner = croppedImage !== undefined ? croppedImage : file;
          this.uploadingBanner = true;
          this.updateImage(banner, 'banner');
        } else {
          const avatar = croppedImage !== undefined ? croppedImage : file;
          this.uploadingAvatar = true;
          this.updateImage(avatar, 'profile');
        }

        this.cdRef.detectChanges();
      }
    });
  }

  /**
   * Update image
   *
   * @param imageFile File to update
   * @param imgTarget If update banner or profile image (avatar)
   * @return Request status
   */
  async updateImage(imageFile: File, imgTarget: string): Promise<boolean> {
    let result;

    try {
      result = await this.profileService.uploadProfileImg(imageFile, this.user.username, imgTarget).toPromise();

      if ( result && result.done ) {
        switch( imgTarget ) {
        case 'profile':
          this.user.imgProfile = result.file_cdn;
          this.uploadingAvatar = false;
          break;
        case 'banner':
          this.user.profileCover = result.file_cdn;
          this.uploadingBanner = false;
          break;
        }
        
        this.cdRef.detectChanges();
      } else {
        this.uploadingAvatar = false;
        this.uploadingBanner = false;
      }

      this.authService.invalidateCacheData(CacheData.MyUser);

    } catch (e) {
      this.uploadingAvatar = false;
      this.uploadingBanner = false;

      return false;
    }

    // @ts-ignore
    return result && result.done;
  }
}
