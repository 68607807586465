<div #content class="content">
  <!-- <div class="section">
    <div class="title">Invitados</div>

  </div> -->

  <div class="section">
    <div class="title">Usuarios en sala</div>

    <div class="list">


      <!-- Creator -->
      <div class="element" *ngIf="isAuthorOnline()">
        <div class="data"
          [ngClass]="{'no-buttons': itsMe(publication.author)}"
          (click)="reducedProfile.emit(publication.author)">
          <lazy-img class="avatar" [size]="150" [source]="getImgProfile(publication.author)"></lazy-img>

          <span class="user">
            <img handleError class="badge" *ngIf="publication.author.isFounder" src="assets/badges/founder-badge.svg" alt="">
            <img handleError class="badge special creator" src="assets/badges/special/creator-1x.png" alt="">
            <img handleError class="badge" [src]="getBadgeSrc(publication.author)" alt="">{{publication.author.username}}
          </span>
        </div>

        <div class="buttons" *ngIf="!itsMe(publication.author)">
          <ion-button
            (click)="imFollowing(publication.author) ? unfollowUser(publication.author) : followUser(publication.author)"
            [color]="imFollowing(publication.author) ? 'light' : 'darkteal'">
            {{imFollowing(publication.author) ? 'Siguiendo' : 'Seguir'}}
          </ion-button>
        </div>
      </div>

      <!-- Collaborators -->
      <div class="element" *ngFor="let user of getCollaborators()">
        <div class="data"
          [ngClass]="{'no-buttons': itsMe(user)}"
          (click)="reducedProfile.emit(user)">
          <lazy-img class="avatar" [size]="150" [source]="getImgProfile(user)"></lazy-img>

          <span class="user">
            <img handleError class="badge" *ngIf="user.isFounder" src="assets/badges/founder-badge.svg" alt="">
            <img handleError class="badge special collaborator" src="assets/badges/special/collaborator-1x.png" alt="">
            <img handleError class="badge" [src]="getBadgeSrc(user)" alt="">{{user.username}}
          </span>
        </div>

        <div class="buttons"  *ngIf="!itsMe(user)">
          <ion-button
            (click)="imFollowing(user) ? unfollowUser(user) : followUser(user)"
            [color]="imFollowing(user) ? 'light' : 'darkteal'">
            {{imFollowing(user) ? 'Siguiendo' : 'Seguir'}}
          </ion-button>
        </div>
      </div>

      <!-- Assistants subscribed  -->
      <div class="element" *ngFor="let assistant of getSubscribers()">
        <div class="data"
          [ngClass]="{'no-buttons': itsMe(assistant)}"
          (click)="reducedProfile.emit(assistant)">
          <lazy-img class="avatar" [size]="150" [source]="getImgProfile(assistant)"></lazy-img>

          <span class="user">
            <img handleError class="badge" *ngIf="assistant.isFounder" src="assets/badges/founder-badge.svg" alt="">
            <img handleError class="badge special subscriber" src="assets/badges/special/subscriber-1x.png" alt="">
            <img handleError class="badge" [src]="getBadgeSrc(assistant)" alt="">{{assistant.username}}
          </span>
        </div>

        <div class="buttons" *ngIf="!itsMe(assistant)">
          <ion-button
            (click)="imFollowing(assistant) ? unfollowUser(assistant) : followUser(assistant)"
            [color]="imFollowing(assistant) ? 'light' : 'darkteal'">
            {{imFollowing(assistant) ? 'Siguiendo' : 'Seguir'}}
          </ion-button>
        </div>
      </div>

      <!-- Assistants not subscribed  -->
      <div class="element" *ngFor="let assistant of getNotSubscribed()">
        <div class="data"
          [ngClass]="{'no-buttons': itsMe(assistant)}"
          (click)="reducedProfile.emit(assistant)">
          <lazy-img class="avatar" [size]="150" [source]="getImgProfile(assistant)"></lazy-img>

          <span class="user">
            <img handleError class="badge" *ngIf="assistant.isFounder" src="assets/badges/founder-badge.svg" alt="">
            <img handleError class="badge" [src]="getBadgeSrc(assistant)" alt="">{{assistant.username}}
          </span>
        </div>

        <div class="buttons" *ngIf="!itsMe(assistant)">
          <ion-button
            (click)="imFollowing(assistant) ? unfollowUser(assistant) : followUser(assistant)"
            [color]="imFollowing(assistant) ? 'light' : 'darkteal'">
            {{imFollowing(assistant) ? 'Siguiendo' : 'Seguir'}}
          </ion-button>
        </div>
      </div>

    </div>
  </div>
</div>
