import { Component, OnInit } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { environment } from '../../../../../environments/environment';

import { BaseComponent as ExtrimPollBaseComponent } from '../base.component';

import { PilotsPage } from '../pilots/pilots.page';
import { InfoLivePage } from '../info-live/info-live.page';

@Component({
  selector: 'app-intro-live',
  templateUrl: './intro-live.page.html',
  styleUrls: environment.isWeb ? ['./intro-live.page.scss', '../../../../../assets/extrim/css/main.css', '../../../../../assets/extrim/css/main-desktop.css'] : ['./intro-live.page.scss', '../../../../../assets/extrim/css/main.css'],
})
export class IntroLivePage extends ExtrimPollBaseComponent {

  loading: boolean = false;
  alreadyVoted: boolean = false;

  constructor(
    protected nav: IonNav,
    protected modalCtrl: ModalController 
  ) {
    super(nav, modalCtrl);
  }

  nextPage() {
    this.next(PilotsPage, this.user, undefined, this.state);
  }

  showInfo() {
    this.next(InfoLivePage, this.user);
  }
}
