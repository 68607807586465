<ion-content class="ion-padding">
  <div class="flex-content">
    <div class="content">
      <!-- Principal -->
      <div class="login-details">
        <ion-icon class="logo" src="assets/estrim/logo.svg"></ion-icon>
        <ion-text class="ion-text-center" color="primary"
          ><h3 class="mb-0 title">Iniciar sesión</h3>
        </ion-text>

        <form
          autocomplete="off"
          [formGroup]="loginForm"
          (ngSubmit)="onFormSubmit(loginForm.value)"
        >
          <ion-item class="ion-text-wrap form-field">
            <ion-label position="floating">Email o nombre de usuario</ion-label>
            <ion-input
              type="email"
              formControlName="username"
              color="dark"
              autocomplete="false"
              value=""
              [class.k-invalid]="checkFieldInvalid(loginForm.get('username'))"
              [class.k-valid]="checkFieldValid(loginForm.get('username'))"
            ></ion-input>
          </ion-item>
          <ion-item lines="none" class="ion-text-wrap form-error">
            <div
              class="ion-text-left"
              *ngIf="checkFieldInvalid(loginForm.get('username'))"
            >
              <ion-text color="danger">
                <small *ngIf="loginForm.get('username').hasError('required')">
                  <ion-icon name="alert-circle-outline"></ion-icon>
                  <span>Este campo es obligatorio</span>
                </small>
              </ion-text>
            </div>
          </ion-item>

          <ion-item class="ion-text-wrap form-field">
            <app-show-hide-password>
              <ion-label position="floating">Contraseña</ion-label>
              <ion-input
                type="password"
                formControlName="password"
                color="dark"
                [class.k-invalid]="checkFieldInvalid(loginForm.get('password'))"
                [class.k-valid]="checkFieldValid(loginForm.get('password'))"
                (keydown.enter)="onFormSubmit(loginForm.value)"
              ></ion-input>
            </app-show-hide-password>
          </ion-item>
          <!--          <ion-item>-->
          <!--            <ion-label class="termsCheck">-->
          <!--              Acepto los <span class="termsLink" routerLink="/legal/useConditions">términos y condiciones de uso</span>-->
          <!--              así como las <span class="termsLink" routerLink="/legal/community">normas de la comunidad.</span>-->
          <!--            </ion-label>-->
          <!--            <ion-checkbox formControlName="termsAccepted" slot="end"></ion-checkbox>-->
          <!--          </ion-item>-->
          <ion-item lines="none" class="ion-text-wrap form-error">
            <div
              class="ion-text-left"
              *ngIf="checkFieldInvalid(loginForm.get('password'))"
            >
              <ion-text color="danger">
                <small *ngIf="loginForm.get('password').hasError('required')">
                  <ion-icon name="alert-circle-outline"></ion-icon>
                  Este campo es obligatorio</small
                >
                <small *ngIf="loginForm.get('password').hasError('minlength')">
                  <ion-icon name="alert-circle-outline"></ion-icon>
                  La contraseña debe tener al menos 6 caracteres</small
                >
              </ion-text>
            </div>
          </ion-item>
          <div class="ion-margin-bottom">
            <ion-button
              class="ion-margin-top entrar"
              expand="block"
              type="submit"
              color="darkteal"
            >
              Entrar
              <ion-spinner
                class="spinner-energized"
                *ngIf="loading"
                color="white"
                name="crescent"
              ></ion-spinner>
            </ion-button>
          </div>

          <div class="ion-text-right ion-margin-top recuperar">
            <a routerLink="/forgot-password">¿No recuerdas tu contraseña?</a>
          </div>
        </form>

        <div class="invitation" [ngClass]="{'on-web': isWeb()}">
          <div class="app-div">
            <!-- <hr> -->
            <p>¿Aún no eres miembro?</p>
          </div>

          <div class="comprobar">
            <ion-button
              class="ion-margin-top ion-margin-bottom ctuinvit"
              expand="block"
              fill="clear"
              (click)="goToRegister()"
            >
              Comprueba tu invitación
            </ion-button>
          </div>
        </div>
      </div>
    </div>

    <app-web-footer></app-web-footer>
  </div>
</ion-content>
