import { Injectable } from '@angular/core';

import { User } from '../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class ReputationService {

  constructor() { }

  /**
   * Returns the experience level of a user
   * @param {User} user
   * @return {number} The experience level
   */
  getExperienceLevel(user: User): number {
    return this.transformReputationToLevel(user.reputation);
  }

  /**
   * Returns the remaining ponts to reach next level
   * @param {User} user
   * @return {number | undefined} The remaining reputation points or undefined
   * if maximum level already reached
   */
  getRemainingPointsToNextLevel(user: User): number | undefined {
    const userLevel = this.transformReputationToLevel(user.reputation);
    const nextLevelBreakpoint = this.getLevelBreakpoint(userLevel + 1);

    if ( nextLevelBreakpoint ) {
      return nextLevelBreakpoint - user.reputation;
    } else {
      return undefined;
    }
  }

  /**
   * Transforms reputation points into experience level
   * @param {number} reputation
   * @return {number} The experience level
   */
  private transformReputationToLevel(reputation: number): number {
    let level = undefined;

    if ( reputation < this.getLevelBreakpoint(2) ) level = 1;
    else if ( reputation < this.getLevelBreakpoint(3) ) level = 2;
    else if ( reputation < this.getLevelBreakpoint(4) ) level = 3;
    else if ( reputation < this.getLevelBreakpoint(5) ) level = 4;
    else if ( reputation < this.getLevelBreakpoint(6) ) level = 5;
    else if ( reputation < this.getLevelBreakpoint(7) ) level = 6;
    else if ( reputation < this.getLevelBreakpoint(8) ) level = 7;
    else if ( reputation < this.getLevelBreakpoint(9) ) level = 8;
    else if ( reputation < this.getLevelBreakpoint(10) ) level = 9;
    else if ( reputation < this.getLevelBreakpoint(11) ) level = 10;
    else if ( reputation < this.getLevelBreakpoint(12) ) level = 11;
    else if ( reputation < this.getLevelBreakpoint(13) ) level = 12;
    else if ( reputation < this.getLevelBreakpoint(14) ) level = 13;
    else if ( reputation < this.getLevelBreakpoint(15) ) level = 14;
    else if ( reputation < this.getLevelBreakpoint(16) ) level = 15;
    else if ( reputation < this.getLevelBreakpoint(17) ) level = 16;
    else if ( reputation < this.getLevelBreakpoint(18) ) level = 17;
    else if ( reputation < this.getLevelBreakpoint(19) ) level = 18;
    else if ( reputation < this.getLevelBreakpoint(20) ) level = 19;
    else if ( reputation >= this.getLevelBreakpoint(20) ) level = 20;

    return level;
  }

  /**
   * Get the reputation breakpoint for each level
   * @param {number} level
   * @return {number | undefined} The reputation breakpoint or undefined
   * if unknown level
   */
  private getLevelBreakpoint(level: number): number | undefined {
    switch(level) {
    case 1: return 0; break;
    case 2: return 10; break;
    case 3: return 20; break;
    case 4: return 30; break;
    case 5: return 40; break;
    case 6: return 50; break;
    case 7: return 200; break;
    case 8: return 400; break;
    case 9: return 600; break;
    case 10: return 1000; break;
    case 11: return 1500; break;
    case 12: return 2000; break;
    case 13: return 2500; break;
    case 14: return 3000; break;
    case 15: return 5000; break;
    case 16: return 8000; break;
    case 17: return 11000; break;
    case 18: return 14000; break;
    case 19: return 17000; break;
    case 20: return 20000; break;
    default: return undefined; break;
    }
  }

}
