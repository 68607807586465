<div class="profile-img"
     *ngIf="!isMyMessage()"
     [ngClass]="{'like': msg.userLikeData?.length > 0}">
  <lazy-img
    *ngIf="shouldShowProfileImage"
    [size]="150"
    [source]="getProfileImg()">
  </lazy-img>
</div>
<div class="message" [id]="msg.messageId"
     [ngClass]="{
        'my-message': isMyMessage(),
        'bigMessage': checkBigMessage(msg),
        'fileMessage': isFile,
        'image': isImage,
        'audio': isVoiceRecording,
        'call': isCall,
        'videocall': isVideoCall,
        'location': isLocation,
        'file': isFile,
        'contact': isContact,
        'star': isStar,
        'message__response': !!msg.reply,
        'like': msg.userLikeData?.length > 0}">

  <div class="reply" *ngIf="msg.reply && isValidResponse()" (click)="emitScrollToMessage()">
    <span class="replyColorBar"></span>
    <span class="replyUsername">{{ msg.reply.username }}</span>
    <span class="replyText">
      <img handleError  *ngIf="isImages(msg.reply)" [src]="getPrivatePhotoReplySrc(isMyMessage())" alt="">

      <div *ngIf="msg.reply.type && msg.reply.type === 'file' && !isVideo(msg.reply)" class="file-div">
        <ion-icon name="cloud-download-outline"></ion-icon>
        <span>Archivo</span>
      </div>

      <div *ngIf="msg.reply.type && msg.reply.type === 'location' && !isVideo(msg.reply)" class="file-div">
        <ion-icon name="location-outline"></ion-icon>
        <span>Ubicación</span>
      </div>

      <div *ngIf="msg.reply.type && msg.reply.type === 'stars' && !isVideo(msg.reply)" class="file-div">
        <img class="start" handleError src="/assets/star-l.png"/>
        <span>{{msg.reply.data}}</span>
      </div>

      <div *ngIf="msg.reply.type && msg.reply.type === 'contact' && !isVideo(msg.reply)" class="file-div">
        <ion-icon name="person-outline"></ion-icon>
        <div class="data">
          <span class="name">{{msg.reply.data}}</span>
          <span>{{msg.reply.data}}</span>
        </div>
      </div>

      <div *ngIf="msg.reply.type && msg.reply.type === 'audio' && !isVideo(msg.reply)" class="file-div disabled-pointer">
        <app-audio-player
          [media]="getMediaRef(isMyMessage())"
          (audioLoaded)="audioLoaded($event)">
        </app-audio-player>
      </div>

      <div *ngIf="isVideo(msg.reply)" >
        <app-video-player #VideoPlayer class="video-player" [videoSrc]="getMediaRef(isMyMessage())" [maximized]="false" [autoplay]="false"></app-video-player>
      </div>

      <ion-icon name="mic-outline" *ngIf="msg.reply.type === 'audio'"></ion-icon>

      <span *ngIf="msg.reply.type !== 'image'">{{ msg.reply.data }}</span>
    </span>
  </div>

  <div class="like-div mine"
       *ngIf="msg.userLikeData?.length > 0"
       @inOutLike
       [ngClass]="{'mine': isMyMessage()}">
    <ion-icon name="heart"></ion-icon>
    <span *ngIf="msg.userLikeData?.length>1">{{msg.userLikeData.length}}</span>
  </div>

  <div class="text-time-row">
    <lazy-img
      *ngIf="isImage"
      [size]="1200"
      [source]="getPrivatePhotoSrc(isMyMessage())"
      [zoomable]="true"
      (onLoad)="imageLoading = false">
    </lazy-img>

    <div class="image-loading" *ngIf="isImage && imageLoading">
      <ion-spinner name="crescent" *ngIf="isImage"></ion-spinner>
    </div>

    <div *ngIf="isVoiceRecording" class="audio-div">
      <app-audio-player
        (click)="stopPropagation($event)"
        [media]="getMedia(isMyMessage())"
        (audioLoaded)="audioLoaded($event)">
      </app-audio-player>
    </div>

    <div *ngIf="isVideo(msg)" class="container-video">
      <app-video-player #VideoPlayer class="video-player" [videoSrc]="getMedia(isMyMessage())" [maximized]="false" [autoplay]="false" [showOverlayInPaused]="true"></app-video-player>
    </div>

    <!-- <audio controls *ngIf="isVoiceRecording" [src]="getPrivatePhotoSrc(true)" alt=""></audio> -->

    <div *ngIf="isLocation" class="location-div"
         (click)="goToLocation(latitude, longitude)">
      <ion-icon name="location-outline"></ion-icon>
      <span>Ubicación</span>
    </div>

    <div *ngIf="isFile && !isVideo(msg)" class="file-div" (click)="downloadFile(isMyMessage())">
      <ion-icon name="cloud-download-outline"></ion-icon>
      <span>{{getFileName(filename)}}</span>
    </div>

    <a *ngIf="isContact" class="contact-div" [href]="'tel:' + contactPhone">
      <ion-icon name="person-outline"></ion-icon>
      <div class="data">
        <span class="name">{{contactName}}</span>
        <span>{{contactPhone}}</span>
      </div>
    </a>

    <div *ngIf="isStar" class="star-div">
      <!-- <ion-icon name="star"></ion-icon> -->
      <img handleError src="/assets/star-l.png"/>
      <span>{{starQuantity}}</span>
    </div>

    <div *ngIf="isVideoCall" class="videocall-div">
      <div *ngIf="callStatus === 1" class="call-div">
        <span>Videollamada iniciada</span>
        <div class="buttons">
          <ion-button class="answer"
                      *ngIf="!isMyMessage()"
                      (click)="answerVideoCall(1,callType, $event)"
                      (touchstart)="stopPropagation($event)"
                      (touchend)="stopPropagation($event)"
                      (mousedown)="stopPropagation($event)"
                      (mouseup)="stopPropagation($event)">
            <ion-icon name="videocam-outline"></ion-icon>
          </ion-button>
          <ion-button
            (click)="answerVideoCall(0,callType,$event)"
            (touchstart)="stopPropagation($event)"
            (touchend)="stopPropagation($event)"
            (mousedown)="stopPropagation($event)"
            (mouseup)="stopPropagation($event)">
            <ion-icon name="close-outline"></ion-icon>
          </ion-button>
        </div>
      </div>
      <span *ngIf="callStatus === 0" class="call-div">
        <span>Videollamada finalizada</span>
      </span>
    </div>

    <div *ngIf="isCall" class="audiocall-div">
      <div *ngIf="callStatus === 1" class="call-div">
        <span>Llamada iniciada</span>
        <div class="buttons">
          <ion-button class="answer" *ngIf="!isMyMessage()" (click)="answerCall(1, callType)">
            <ion-icon name="call-outline"></ion-icon>
          </ion-button>
          <ion-button (click)="answerCall(0)">
            <ion-icon name="close-outline"></ion-icon>
          </ion-button>
        </div>
      </div>
      <span *ngIf="callStatus === 0" class="call-div">
        <span>Llamada finalizada</span>
      </span>
    </div>

    <span class="msg-span" *ngIf="isText">{{msg.data}}</span>

    <span *ngIf="isVoiceRecording" class="audio-time">
      {{ audioDuration }}
      <!-- TODO: implementar tiempo del fichero de audio -->
    </span>

    <span class="time">{{msg.date | date:'H:mm'}}</span>
  </div>
</div>
