<div class="video-channels" *ngIf="recommendedVideos.length > 0">
  <p class="d-flex ion-justify-content-between">
    <span class="font-medium">Canales de vídeo</span>
    <a (click)="goToViewAll()" class="font-medium color-gray">Ver más</a>
  </p>
  <ul class="thumb-list mb-4">
    <div *ngIf="isLoading">
      <div *ngFor="let live of [0, 1, 2, 3, 4]; let i = index">
        <div class="d-flex">
          <ion-skeleton-text
            [animated]="true"
            style="
              width: 3em;
              height: 2em;
              margin-right: 0.5em;
              border-radius: 0.2em;
            "
          ></ion-skeleton-text>
          <div>
            <ion-skeleton-text
              [animated]="true"
              style="width: 4em"
            ></ion-skeleton-text>
            <ion-skeleton-text
              [animated]="true"
              style="width: 7em"
            ></ion-skeleton-text>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!isLoading">
      <div
        *ngFor="
          let recommendedVideo of recommendedVideos.slice(0, 5);
          let i = index
        "
      >
        <!--        TODO APLICAR ESTILO PARA CUANDO ESTE EMITIENDO Y PONE EL MENSAJE DE EN DIRECTO-->
        <li [class]="recommendedVideo.emitting ? 'emitting' : ''">
          <a (click)="goToPublication(recommendedVideo)">
            <div class="thumb wide">
              <!--              TODO REVISAR PORQUE NO SE VE BIEN -->
              <div
                *ngIf="recommendedVideo.isPremium"
                class="locked-content"
                [ngClass]="{
                  open:
                    recommendedVideo.collaborating ||
                    recommendedVideo.subscribed ||
                    recommendedVideo.author.id === user?.id
                }"
              >
                <ion-icon
                  *ngIf="
                    !(
                      recommendedVideo.collaborating ||
                      recommendedVideo.subscribed ||
                      recommendedVideo.author.id === user?.id
                    )
                  "
                  name="lock-closed"
                ></ion-icon>
                <ion-icon
                  *ngIf="
                    recommendedVideo.collaborating ||
                    recommendedVideo.subscribed ||
                    recommendedVideo.author.id === user?.id
                  "
                  name="lock-open"
                ></ion-icon>
              </div>
              <lazy-img
                alt="image"
                [size]="400"
                [source]="getPublicationImg(recommendedVideo)"
              ></lazy-img>
            </div>
            <div>
              <strong>{{ recommendedVideo.author.username }}</strong>
              <span>{{ recommendedVideo.title }}</span>
            </div>
          </a>
        </li>
      </div>
    </div>
  </ul>
</div>
