import { Component, OnInit, OnDestroy, AfterViewInit, AfterViewChecked, Input, ViewChild, ElementRef, ChangeDetectorRef, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { MediaService } from '../../services/media.service';
import { PaymentService } from '../../services/payment.service';
import { Events } from '../../services/events.service';
import { UserService } from '../../services/user.service';
import { ProfileService } from '../../services/profile.service';
import { AuthService } from '../../services/auth.service';

import { environment } from '../../../environments/environment';
import { trigger, transition, animate, style } from '@angular/animations';

import { User } from '../../interfaces/user';

import { CacheData } from 'src/app/enums/cache-data';

export type Orientation = ('prev' | 'next' | 'none');

@Component({
  selector: 'app-collaborate',
  templateUrl: './collaborate.component.html',
  styleUrls: ['./collaborate.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition('next => prev', // <--- Leaving <---
        [
          style({transform: 'translateX(0%)'}),
          animate('300ms ease', style({transform: 'translateX(-100%)'}))
        ]),
      transition('prev => next', // ---> Leaving --->
        [
          style({transform: 'translateX(0%)'}),
          animate('300ms ease', style({transform: 'translateX(+100%)'}))
        ]),
      transition('* => prev', // ---> Entering --->
        [
          style({transform: 'translateX(-100%)'}),
          animate('300ms ease', style({transform: 'translateX(0%)'}))
        ]),
      transition('prev => *', // ---> Leaving --->
        [
          style({transform: 'translateX(0%)'}),
          animate('300ms ease', style({transform: 'translateX(+100%)'}))
        ]),
      transition('* => next', // <--- Entering <---
        [
          style({transform: 'translateX(+100%)'}),
          animate('300ms ease', style({transform: 'translateX(0%)'}))
        ]),
      transition('next => *', // <--- Leaving <---
        [
          style({transform: 'translateX(0%)'}),
          animate('300ms ease', style({transform: 'translateX(-100%)'}))
        ]),
    ]),
  ]
})
export class CollaborateComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {

  @ViewChild('step') step: ElementRef;

  @Input() user: User = undefined;

  animationDirection: Orientation;
  slideStep: number = 0;
  stepsContainerHeight: number;

  viewInited: boolean = false;

  loading: boolean = false;

  stepsHeightTimeout = undefined;

  constructor( 
    private router: Router,
    private mediaService: MediaService, 
    private events: Events,
    private paymentService: PaymentService,
    private userService: UserService,
    private profileService: ProfileService,
    private authService: AuthService,
    private cdRef: ChangeDetectorRef,
    private zone: NgZone
  ) {
    this.animationDirection = 'next';
  }

  ngOnInit() {
    console.log(this.user);
  }
  ngOnDestroy() {
    clearTimeout(this.stepsHeightTimeout);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.viewInited = true;
      this.cdRef.detectChanges();
    }, 400)
  }

  ngAfterViewChecked() {
    this.zone.runOutsideAngular(() => {
      clearTimeout(this.stepsHeightTimeout);

      this.stepsHeightTimeout = setTimeout(() => {
        const height = this.step?.nativeElement.getBoundingClientRect().height;

        if (height !== this.stepsContainerHeight) {
          this.stepsContainerHeight = height;
          this.cdRef.detectChanges();
        }
      }, 10)
    })
  }

  getImgProfile() {
    return this.mediaService.generateImgProfileURL(this.user?.id, this.user?.imgProfile);
  }

  dismiss() {
    this.userService.closeCollaborate();
  }

  activateStep(step) {
    if (this.slideStep === step) {
      return;
    }

    // Check steps direction effect
    if (this.slideStep < step) {
      this.animationDirection = 'next';
      this.cdRef.detectChanges();
    } else {
      this.animationDirection = 'prev';
      this.cdRef.detectChanges();
    }

    // Store current step
    this.slideStep = step;
    this.cdRef.detectChanges();
  }

  collaborateWithUser() {
    this.loading = true;
    
    this.profileService.inviteCollaborator(this.user.id).subscribe(
      res => {
        this.profileService.checkCacheDataUpdate(CacheData.MyCollaborators, true);

        if (res.done) {
          // this.events.publish(environment.EVENTS.ALERT_TOAST_NOTICE, 'Invitación enviada');
          this.user.collaboration = res.data;
          this.user.collaborationRequests = true;
          this.userService.userChanged(this.user);

          this.activateStep(1);
        } else {
          this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, res.msg);
        }

        this.loading = false;
        this.cdRef.detectChanges();
      }, err => {
        this.loading = false;
        this.cdRef.detectChanges();

        this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error, intentalo de nuevo más tarde.');
      }
    );
  }
}
