import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { IonNav, IonInfiniteScroll } from '@ionic/angular';

import { FormPage } from '../form/form.page';

import { PageMode } from '../event-premiere.component';

import { CreateContentService } from '../../../../../services/create-content.service';
import { AuthService } from '../../../../../services/auth.service';
import { FeedService } from '../../../../../services/feed.service';

import { User } from '../../../../../interfaces/user';
import { Event } from '../../../../../interfaces/event';

import { ContentType } from '../../../../../enums/content-type';

const REQUEST_LENGTH: number = 20;

@Component({
  selector: 'app-list',
  templateUrl: './list.page.html',
  styleUrls: ['./list.page.scss'],
})
export class ListPage implements OnInit {

  @ViewChild('infiniteScroll') infiniteScroll: IonInfiniteScroll;

  @Input() type: ContentType = ContentType.Event;

  ContentType = ContentType;

  loading: boolean = true;
  user: User;
  events: Event[] = [];

  listOffset: number = 0;
  listRemaining: number | undefined = undefined;

  constructor(
    private nav: IonNav,
    private createContentService: CreateContentService,
    private authService: AuthService,
    private feedService: FeedService
  ) { }

  ngOnInit() {
    this.authService.getUserDetails().then((data) => {
      this.user = data;

      this.requestData();
    });
  }

  dismiss() {
    this.createContentService.closeModal();
  }

  goToForm() {
    this.nav.push(
      FormPage, 
      { 
        pageMode: PageMode.Form, 
        type: this.type
      }, 
      { mode: 'ios' }
    );
  }

  showInfo() {
    if ( this.type === ContentType.Event ) {
      this.createContentService.openEventInfoModal(false);
    } else {
      this.createContentService.openPremiereInfoModal(false);
    }
  }

  requestData() {
    if ( this.type === ContentType.Event ) {
      this.feedService.loadEvents(this.listOffset, REQUEST_LENGTH, [], this.user.id, true).subscribe(response => {
        if ( response.done ) {
          // @ts-ignore
          this.events = this.events.concat(response.data.list);

          this.loading = false;

          this.listOffset += REQUEST_LENGTH;
          // @ts-ignore
          this.listRemaining = response.data.meta.total - this.events.length;

          this.infiniteScroll.complete();

          if (this.listRemaining <= 0) {
            this.infiniteScroll.disabled = true;
          }
        }
      });
    } else {
      this.feedService.loadPremieres(this.listOffset, REQUEST_LENGTH, [], this.user.id, true).subscribe(response => {
        if ( response.done ) {
          // @ts-ignore
          this.events = this.events.concat(response.data.list);

          this.loading = false;

          this.listOffset += REQUEST_LENGTH;
          // @ts-ignore
          this.listRemaining = response.data.meta.total - this.events.length;
        
          this.infiniteScroll.complete();
        
          if (this.listRemaining <= 0) {
            this.infiniteScroll.disabled = true;
          }
        }
      });
    }
  }
}
