<div class="contact-item">
  <!-- <div (click)="goToProfile(user)" [ngStyle]="styleFor(user)" class="back-contact"></div> -->

  <lazy-img
    class="avatar"
    alt="image"
    [ngClass]="{
      online: user.emitting,
      video: user.emitting
    }"
    [size]="150"
    [source]="getImageProfile()"
    (click)="goToProfile(user)"
  >
  </lazy-img>
  <div class="content flex-fill">
    <div
      (click)="goToProfile(user)"
      class="data"
      [ngClass]="{ removable: removable }"
    >
      <div *ngIf="user.nameToShow" class="username-profile">
        <span class="username">{{ user.nameToShow }}</span>
        <img
          handleError
          class="founder-badge"
          *ngIf="user.isFounder"
          src="assets/badges/founder-badge.svg"
        />
      </div>
      <span class="sub mb-1 block">@{{ user.username }}</span>
    </div>

    <ion-button
      *ngIf="!itsMe && followed !== null && !subscribeMode"
      (click)="followed ? emitUnfollowUser(user) : emitFollowUser(user)"
      [color]="followed ? 'light' : 'darkteal'"
    >
      {{ followed ? "Siguiendo" : "Seguir" }}
    </ion-button>
    <ion-button
      *ngIf="!itsMe && subscribeMode"
      (click)="subscribeUser.emit(user)"
      [color]="subscribed ? 'light' : 'darkteal'"
    >
      {{ followed ? "Suscrito" : "Suscribirse" }}
    </ion-button>
    <ion-button
      *ngIf="
        (!itsMe && collaborator !== null && collaborateWithMe) ||
        (collaborateRequest && collaborator?.invitationAccepted)
      "
      (click)="presentAlertRemoveCollab()"
      [color]="collaborator?.invitationAccepted ? 'light' : 'darkteal'"
    >
      {{ collaborator?.invitationAccepted ? "Colaborando" : "Pendiente" }}
    </ion-button>

    <ion-button
      class="remove"
      *ngIf="!itsMe && removable"
      color="clear"
      (click)="removeUser.emit(user)"
    >
      <ion-icon name="close-outline"></ion-icon>
    </ion-button>

    <div
      class="collabotation-request-buttons"
      *ngIf="collaborateRequest && !collaborator?.invitationAccepted"
    >
      <ion-button
        class="acceptInvitation"
        color="darkteal"
        (click)="acceptCollaboration()"
      >
        Aceptar
      </ion-button>
      <ion-button
        class="cancelInvitation"
        color="light"
        (click)="presentAlertRemoveCollab()"
      >
        Rechazar
      </ion-button>
    </div>
  </div>
</div>
