import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DarkMode as CapacitorDarkMode} from '@aparajita/capacitor-dark-mode';
import { DarkMode } from '../enums/dark-mode';

@Injectable({
  providedIn: 'root'
})
export class AppearenceService {

  private _prefersDarkStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  prefersDarkStatus: Observable<boolean> = this._prefersDarkStatus.asObservable();

  get prefersDark(): boolean {
    return document.body.classList.contains('dark');
  }

  constructor() {
    this.setDarkMode(this.getCurrentMode());

    // Listen for changes to the prefers-color-scheme media query
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (mediaQuery) => {
      if ( this.getCurrentMode() === DarkMode.System) {
        mediaQuery.matches 
          ? document.body.classList.toggle('dark', true) 
          : document.body.classList.toggle('dark', false);

        this._prefersDarkStatus.next(mediaQuery.matches);
      }
    });
  }

  getCurrentMode(): DarkMode {
    const mode = localStorage.getItem('darkMode');
    if (mode) {
      return mode as DarkMode;
    }

    return DarkMode.System;
  }
  private setCurrentMode(mode: DarkMode) {
    localStorage.setItem('darkMode', mode);
    this._prefersDarkStatus.next(this.prefersDark);
  }

  async setDarkMode(mode: DarkMode) {
    switch (mode) {
      case DarkMode.System:
        const capacitorDarkMode = await CapacitorDarkMode.isDarkMode();
        document.body.classList.toggle('dark', capacitorDarkMode.dark);
        break;
      case DarkMode.Dark:
        document.body.classList.toggle('dark', true);
        break;
      case DarkMode.Light:
        document.body.classList.toggle('dark', false);
        break;
    }

    this.setCurrentMode(mode);
  }
}
