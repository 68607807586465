import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { KService } from './k.service';
import { JsonApiResponse } from '../interfaces/JsonApiResponse';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileTransferService extends KService {

  sendPhotoPrivateUrl = 'api/auth/aforum/send_photo_private';
  sendVoiceRecordingUrl = 'api/auth/aforum/send_voice_recording';
  uploadPrivateFileUrl = 'api/auth/aforum/send_file_private';
  sendFilePublicUrl = 'api/auth/aforum/send_file_public';
  downloadFileUrl = 'api/auth/aforum/download_file_private';
  getDiskUsageUrl = 'api/auth/user/get_disk_usage';
  uploadChunkUrl = 'api/auth/estrim/upload_file_chunk';

  chunkSize = 10000000;
  chunkCounter = 0;
  uploadType = '';
  uploadId;
  progress = 0;

  constructor(protected http: HttpClient) {
    super(http);
  }

  sendPhotoPrivate(photo, receiverId) {
    const formData = new FormData();
    formData.append('photo', photo, photo.name);
    formData.append('receiverId', receiverId);
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.sendPhotoPrivateUrl),
      formData);
  }

  uploadPrivateFile(file: File, senderId, receiverId, keepFilename: boolean = true, contentType: string | undefined = undefined) {
    let headers = {};

    if ( contentType ) {
      headers = {
        'Content-Type': contentType
      }
    } 

    return this.http.put<{done: boolean, file: string}>(
      `${environment.R2_UPLOAD_WORKER_URL}?type=private&filename=${file.name}&senderId=${senderId}&receiverId=${receiverId}&keepFilename=${keepFilename ? 'true' : 'false'}`, 
      file, {
        headers: headers
      })
      .pipe(
        map(data => {
            return data;
        }),
        catchError(error => { return throwError("Cannot upload file") })
      );
  }
  uploadPrivateB64(b64: string, filename: string, contentType: string, senderId, receiverId, keepFilename: boolean = true) {
    return this.http.put<{done: boolean, file: string}>(
      `${environment.R2_UPLOAD_WORKER_URL}?type=private&filename=${filename}&senderId=${senderId}&receiverId=${receiverId}&keepFilename=${keepFilename ? 'true' : 'false'}`, 
      b64, {
        headers: {
          'Content-Type': contentType
        }
      })
      .pipe(
        map(data => {
            return data;
        }),
        catchError(error => { return throwError("Cannot upload file") })
      );
  }

  sendFilePublic(file, publicationId, isEvent = false) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('publicationId', publicationId);
    formData.append('isEvent', isEvent.toString());
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.sendFilePublicUrl),
      formData);
  }

  downloadFile(url: string) {
  }

  getDiskUsage() {
    return this.http.get<any>(this.generateAbsoluteAPIURL(this.getDiskUsageUrl));
  }

  async uploadByChunks(file, uploadId, type = 'premiere') {
    this.uploadType = type;
    this.uploadId = uploadId;
    const filename = file.name;
    const numberofChunks = Math.ceil(file.size / this.chunkSize);
    console.log(`Se subirá el archivo en ${numberofChunks} chunks de ${this.chunkSize / 1024} megas.`)
    const start = this.chunkSize * this.chunkCounter;
    //upload the first chunk to get the videoId
    return await this.createChunk(file, start);
  }

  createChunk(file, start) {
    return new Promise<any>((resolve, reject) => {
      this.chunkCounter++;
      console.log('Creado chunk: ', this.chunkCounter);
      const chunkEnd = Math.min(start + this.chunkSize, file.size);
      const chunk = file.slice(start, chunkEnd);
      console.log('He creado un chunk desde' + start + ' a ' + chunkEnd);
      const chunkForm = new FormData();
      if (file) {
        //we have a videoId
        const numberofChunks = Math.ceil(file.size / this.chunkSize);
        chunkForm.append('uploadId', this.uploadId);
        chunkForm.append('uploadType', this.uploadType);
        chunkForm.append('chunkSize', this.chunkSize.toString());
        chunkForm.append('chunkCounter', this.chunkCounter.toString());
        chunkForm.append('totalSize', file.size);
        chunkForm.append('numberOfChunks', numberofChunks.toString());
        console.log('added videoId', this.uploadId);

      }
      chunkForm.append('file', chunk, file.name);
      chunkForm.append('start', start);
      chunkForm.append('chunkEnd', chunkEnd.toString());
      console.log('added file');
      //created the chunk, now upload iit
      this.uploadChunk(chunkForm).subscribe(async res => {
        this.progress = Math.trunc(res.data.quantityCompleted * 100 / file.size);
        if (this.progress === 100) {
          this.chunkCounter = 0;
          this.uploadType = '';
          this.uploadId = null;
          setTimeout(() => {
            this.progress = 0;
          }, 10000)
          resolve(res.data);
        } else {
          start = this.chunkSize * this.chunkCounter;
          resolve(await this.createChunk(file, start));
        }
      });
    });

  }

  uploadChunk(chunkForm) {
    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.uploadChunkUrl),
      chunkForm);
  }

  getUniqueId() {
    const dateString = Date.now().toString(36);
    const randomness = Math.random().toString(36).substr(2);
    return dateString + randomness;
  };
}
