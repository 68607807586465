<ion-header class="general-header-app">
  <app-header
    [title]="''"
    [menuButton]="false"
    [defaultBackAction]="false"
    (back)="exit()"
  >
  </app-header>
</ion-header>

<ion-content>
  <div class="flex-content">
    <div class="content has-logo">
      <!-- Description -->
      <h4 class="description">Te hemos enviado un código por sms</h4>

      <div class="phone">
        +{{country.code}} {{phone}}
      </div>

      <!-- Label -->
      <label class="label">Introduce tu código</label><br />

      <!-- Code input -->
      <input
        class="input"
        type="text"
        autocomplete="one-time-code"
        (change)="error.code = false"
        [(ngModel)]="code"
      />

      <!-- Code error -->
      <div class="field">
        <ion-text class="field-error" *ngIf="error.code" color="danger">
          <small>
            <ion-icon name="alert-circle-outline"></ion-icon>
            <span>El código introducido no es válido.</span>
          </small>
        </ion-text>
      </div>

      <!-- Submit button -->
      <div class="submit-button">
        <ion-button fill="clear" [disabled]="loading" (click)="checkSmsCode()">
          Comprobar
          <ion-spinner
            color="white"
            name="crescent"
            *ngIf="loading"
          ></ion-spinner>
        </ion-button>
      </div>

      <div class="submit-button aux">
        <ion-button
          color="medium"
          [disabled]="resendSmsCountdown > 0 || sendingSms"
          (click)="resendSms()"
        >
          Enviar de nuevo
          <span *ngIf="resendSmsCountdown > 0"
            >&nbsp;{{resendSmsCountdown}}</span
          >
        </ion-button>
      </div>
    </div>
  </div>
  <app-web-footer></app-web-footer>
</ion-content>
