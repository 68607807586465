<ng-container *ngIf="!skeleton">
  <ion-card class="sugerencias">
    <ion-card-content *ngIf="!listMode">
      <ion-icon
        *ngIf="removeButton && !removingUser"
        (click)="removeUserSuggestion($event)"
        class="close"
        name="close-outline"
      ></ion-icon>
      <ion-spinner *ngIf="removingUser" class="close spinner" color="dark" name="crescent"></ion-spinner>

      <div class="img-profile-sugerencia" (click)="getRouteUserProfile()">
        <lazy-img
          alt="image"
          [size]="400"
          [source]="getImgProfile()"
          [ngClass]="{ pro: user.isPro }"
        ></lazy-img>

        <app-pro-tag *ngIf="user.isPro" class="pro-tag"></app-pro-tag>
        <!-- <span *ngIf=" user.isPro">PRO</span> -->
      </div>
      <div class="container-info-sugerencia" (click)="getRouteUserProfile()">
        <div class="myname">
          <span>{{ user.nameToShow }}</span>
          <ion-icon
            handleError
            class="founder-badge"
            *ngIf="user.isFounder"
            src="assets/badges/founder-badge.svg"
          >
          </ion-icon>
        </div>
        <div class="myemail">@{{ user.username }}</div>
      </div>
      <ion-button
        class="sugerencia-btn"
        (click)="followOrUnfollow($event)"
        [color]="following ? 'light' : 'darkteal'"
        >{{ following ? "Siguiendo" : "Seguir" }}</ion-button
      >
    </ion-card-content>

    <ion-card-content class="list" *ngIf="listMode">
      <ion-icon
        *ngIf="!removingUser"
        (click)="removeUserSuggestion($event)"
        class="close"
        name="close-outline"
      ></ion-icon>
      <ion-spinner *ngIf="removingUser" class="close spinner" color="dark" name="crescent"></ion-spinner>


      <div class="img-profile-sugerencia" (click)="getRouteUserProfile()">
        <lazy-img
          alt="image"
          [size]="400"
          [source]="getImgProfile()"
          [ngClass]="{ pro: user.isPro }"
        ></lazy-img>

        <app-pro-tag *ngIf="user.isPro" class="pro-tag"></app-pro-tag>
        <!-- <span *ngIf=" user.isPro">PRO</span> -->
      </div>
      <div class="info">
        <div class="container-info-sugerencia" (click)="getRouteUserProfile()">
          <div class="myname">
            <span>{{ user.nameToShow }}</span>
            <ion-icon
              handleError
              class="founder-badge"
              *ngIf="user.isFounder"
              src="assets/badges/founder-badge.svg"
            ></ion-icon>
          </div>
          <div class="myemail">@{{ user.username }}</div>
        </div>
        <div class="container-subinfo-sugerencia">
          <div class="diamond" *ngIf="user?.reputation !== undefined">
            <img handleError [src]="getBadgeSrc()" />
            {{ user.reputation }}
          </div>
          <div class="button-container">
            <ion-button
              class="sugerencia-btn"
              (click)="followOrUnfollow($event)"
              [color]="following ? 'light' : 'darkteal'"
              >{{ following ? "Siguiendo" : "Seguir" }}</ion-button
            >
          </div>
        </div>
      </div>
    </ion-card-content>
  </ion-card>
</ng-container>
<ng-container *ngIf="skeleton">
  <ion-card class="sugerencias">
    <ion-card-content *ngIf="!listMode">
      <div class="img-profile-sugerencia">
        <ion-thumbnail>
          <ion-skeleton-text [animated]="true"></ion-skeleton-text>
        </ion-thumbnail>
      </div>
      <div class="container-info-sugerencia">
        <div class="myname">
          <ion-skeleton-text
            [animated]="true"
            style="width: 80px; --border-radius: 5px; margin: 4px auto"
          ></ion-skeleton-text>
        </div>
        <div class="myemail">
          <ion-skeleton-text
            [animated]="true"
            style="width: 70px; --border-radius: 5px; margin: 4px auto"
          ></ion-skeleton-text>
        </div>
      </div>
      <ion-skeleton-text
        [animated]="true"
        style="
          width: 100%;
          height: 32px;
          --border-radius: 20px;
          margin: 0;
          margin-top: 9px;
        "
      ></ion-skeleton-text>
    </ion-card-content>

    <ion-card-content class="list" *ngIf="listMode">
      <div class="img-profile-sugerencia">
        <ion-thumbnail>
          <ion-skeleton-text [animated]="true"></ion-skeleton-text>
        </ion-thumbnail>
      </div>
      <div class="info">
        <div class="container-info-sugerencia">
          <div class="myname">
            <ion-skeleton-text
              [animated]="true"
              style="width: 80px; --border-radius: 5px"
            ></ion-skeleton-text>
          </div>
          <div class="myemail">
            <ion-skeleton-text
              [animated]="true"
              style="width: 70px; --border-radius: 5px"
            ></ion-skeleton-text>
          </div>
        </div>
      </div>
    </ion-card-content>
  </ion-card>
</ng-container>
