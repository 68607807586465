<div class="icon-container">
  <ion-icon
    class="aspect-square black-outline filled"
    *ngIf="filled"
    src="assets/item/heart.svg"
    @inOutStarAnimation1
    [@starAnimation1]="animateLike"
    (@starAnimation1.done)="animateLike = false"
  ></ion-icon>
  <ion-icon
    class="aspect-square black-outline"
    *ngIf="!filled"
    [ngClass]="{ invert: whiteLike }"
    [@starAnimation1]="animateLike"
    (@starAnimation1.done)="animateLike = false"
    src="assets/item/heart.svg"
  ></ion-icon>
</div>

<div class="count-content">
  <div class="count" *ngIf="animateCount" @inOutCount>
    {{ count | shortNumber }}
  </div>
</div>
