import { Component } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { environment } from '../../../../../environments/environment';

import { BaseComponent as ExtrimPollBaseComponent } from '../base.component';

import { PilotsPage } from '../pilots/pilots.page';
import { InfoPage } from '../info/info.page';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.page.html',
  styleUrls: environment.isWeb ? ['./intro.page.scss', '../../../../../assets/extrim/css/main.css', '../../../../../assets/extrim/css/main-desktop.css'] : ['./intro.page.scss', '../../../../../assets/extrim/css/main.css'],
})
export class IntroPage extends ExtrimPollBaseComponent {

  loading: boolean = false;
  alreadyVoted: boolean = false;

  constructor(
    protected nav: IonNav,
    protected modalCtrl: ModalController 
  ) {
    super(nav, modalCtrl);
  }

  nextPage() {
    this.next(PilotsPage, this.user, undefined, this.state);
  }

  showInfo() {
    this.next(InfoPage, this.user);
  }
}
