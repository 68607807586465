import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from '../../../../../environments/environment';

import { ExtrimService } from 'src/app/services/extrim.service';
import { MediaService } from 'src/app/services/media.service';

import * as Sentry from "@sentry/capacitor";

import { ExtrimState } from 'src/app/enums/extrim-state';

@Component({
  selector: 'app-vote-confirm',
  templateUrl: './vote-confirm.component.html',
  styleUrls: environment.isWeb ? ['./vote-confirm.component.scss', '../../../../../assets/extrim/css/main.css', '../../../../../assets/extrim/css/main-desktop.css'] : ['./vote-confirm.component.scss', '../../../../../assets/extrim/css/main.css'],
})
export class VoteConfirmComponent {

  @Input() pilot: any = {};
  @Input() state: ExtrimState;

  ExtrimState = ExtrimState;

  loading: boolean = false;

  constructor(
    private extrimRace: ExtrimService,
    private modalCtrl: ModalController,
    private cdRef: ChangeDetectorRef,
    public mediaService: MediaService,
  ) {}

  vote() {
    this.loading = true;

    this.extrimRace.votePilot(this.pilot.id).subscribe(
      data => {
        if ( data.done ) {
          this.modalCtrl.dismiss(this.pilot, 'confirm');
        } else {
          Sentry.captureMessage(`[EXTRIM POLL] vote fail with message ${data.msg}`, "error");
        }

        this.loading = false;
        this.cdRef.detectChanges();
      },
      error => {
        if ( !error.error?.msg ) {
          Sentry.captureMessage(`[EXTRIM POLL] vote fail, request status code ${error.status}`, "error");
        } else {
          Sentry.captureMessage(`[EXTRIM POLL] vote fail, request status code ${error.status} with message ${error.error.msg}`, "error");
        }

        this.loading = false;
        this.cdRef.detectChanges();
      },
    )
  }

  dismiss() {
    this.modalCtrl.dismiss(null, 'cancel');
  }
}
