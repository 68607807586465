import {NgModule} from '@angular/core';
import { HideHeaderDirective } from './hide-header.directive';
import { LongPressDirective } from './long-press.directive';
import { ImgErrorDirective } from './img-error.directive';
import { VideoPosterDirective } from './video-poster.directive';
import { ImgZoomableDirective } from './img-zoomable.directive';
import { AvatarZoomableDirective } from './avatar-zoomable.directive';
import { DoubleTapDirective } from './double-tap.directive';
import { PinchDirective } from './pinch.directive';
import { OnlyNumericDirective } from './only-numeric.directive';
import { DragGestureDirective } from './drag-gesture.directive';
import { ThumbnailDirective } from './thumbnail.directive';
import { VideoDirective } from './video-live.directive';
import { CacheDirective } from './cache.directive';
import { ScoreDebugDirective } from './score-debug.directive';

@NgModule({
  declarations: [
    HideHeaderDirective, 
    LongPressDirective, 
    ImgErrorDirective, 
    VideoPosterDirective, 
    ImgZoomableDirective,
    AvatarZoomableDirective,
    DoubleTapDirective,
    PinchDirective,
    OnlyNumericDirective,
    DragGestureDirective,
    ThumbnailDirective,
    VideoDirective,
    CacheDirective,
    ScoreDebugDirective
  ],
  imports: [],
  exports: [
    HideHeaderDirective, 
    LongPressDirective,
    ImgErrorDirective,
    VideoPosterDirective,
    ImgZoomableDirective,
    AvatarZoomableDirective,
    DoubleTapDirective,
    PinchDirective,
    OnlyNumericDirective,
    DragGestureDirective,
    ThumbnailDirective,
    VideoDirective,
    CacheDirective,
    ScoreDebugDirective
  ],
})

export class DirectivesModule {
}
