<ion-header class="general-header-app">
  <app-header
    [title]="''"
    [menuButton]="false"
    [defaultBackAction]="false"
    (back)="back()"
  >
  </app-header>
</ion-header>

<ion-content #content class="ion-padding">
  <div class="flex-content">
    <div class="content has-logo">
      <!-- Title -->
      <div class="title">Perfil</div>

      <!-- Media -->
      <div class="media">
        <!-- Banner -->
        <div class="banner" (click)="pickBanner()">
          <div class="empty" *ngIf="!banner">
            <button class="replace-button">
              <ion-icon src="assets/svg-icon/camera2-clear.svg"></ion-icon>
              <span class="text">banner</span>
            </button>
          </div>
          <img *ngIf="banner" handleError [src]="bannerSrc" />
        </div>
        <!-- Avatar -->
        <div class="avatar" (click)="pickAvatar()">
          <div class="empty" *ngIf="!avatar">
            <button class="replace-button">
              <ion-icon src="assets/svg-icon/camera2-clear.svg"></ion-icon>
              <span class="text">perfil</span>
            </button>
          </div>
          <img *ngIf="avatar" handleError [src]="avatarSrc" />
        </div>
      </div>

      <!-- Name -->
      <div class="field ion-item-input">
        <div class="content">
          <ion-item>
            <ion-label position="floating">Nombre</ion-label>

            <ion-input
              required
              maxlength="30"
              autocomplete="name"
              [disabled]="loading"
              [(ngModel)]="name"
              (ionFocus)="inputFocused($event)"
              (ionChange)="error.name = false"
            >
            </ion-input>
          </ion-item>

          <ion-text class="field-error" *ngIf="error.name" color="danger">
            <small>
              <ion-icon name="alert-circle-outline"></ion-icon>
              <span>Este campo es obligatorio</span>
            </small>
          </ion-text>
        </div>
      </div>

      <!-- Submit button -->
      <div class="submit-button">
        <ion-button fill="clear" [disabled]="loading" (click)="submit()">
          Siguiente
          <ion-spinner
            color="white"
            name="crescent"
            *ngIf="loading"
          ></ion-spinner>
        </ion-button>
      </div>

      <div
        class="keyboard-space"
        [ngStyle]="{'height': keyboardHeight + 'px'}"
      ></div>
    </div>
  </div>
  <app-web-footer></app-web-footer>

  <div class="ios-bottom-safe-area"></div>
</ion-content>
