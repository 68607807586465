<video
    [class]="'peer'+peerUsername + ' not-freezed'"
    [ngClass]="{
      'colaborator': isCollaborator,
      'paused': track.isMuted,
      'local': local,
      'girated': girated
    }"
    *ngIf="isVideo && !onlyAudio"
    width="100%"
    height="100%"
    #stream
    poster='../../../../assets/estrim/poster/logo-light-square.png'
    autoplay muted playsInline
></video>
<audio *ngIf="!isVideo"
    [ngClass]="{'local': local}"
    #stream
    [muted]="local"
    autoplay playsInline
></audio>
