<!-- Icon -->
<div class="title-icon">
  <ion-icon class="icon upload" src="assets/svg-icon/ticket3.svg"></ion-icon>
</div>

<!-- Title -->
<div class="title">
  Estrenos
</div>

<!-- Subtitle -->
<div class="subtitle">
  Elige una fecha para convocar a tu audiencia al estreno de tu podcast, corto, videoclip o serie e interactúa con tu audiencia a través del chat.
</div>

<!-- Subtitle -->
<div class="subtitle">
  Puedes establecer un precio y obtener beneficios con la venta de cada pase.
</div>

<!-- Buttons -->
<div class="buttons">
  <ion-button color="darkteal" (click)="confirm()" *ngIf="confirmButton">
    Crear estreno
  </ion-button>
  <ion-button 
    fill="clear" 
    color="dark"
    class="go-back" 
    (click)="dismiss()">
    Cerrar
  </ion-button>
</div>