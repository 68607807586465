import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Platform, IonNav, ModalController, NavController } from '@ionic/angular';
import { environment } from '../../../environments/environment';

import { BasePage } from '../base.page';
import { AuthService } from '../../services/auth.service';
import { Events } from '../../services/events.service';
import { AnalyticsService } from '../../services/analytics.service';
import { PhoneCheckComponent } from '../../components/phone-check/phone-check.component';
import { COUNTRIES } from '../../constants/countries';

import * as Sentry from "@sentry/capacitor";

import { forkJoin } from 'rxjs';

@Component({
  template: '',
})
export class BaseComponent extends BasePage {

  @Input() nav: IonNav;

  countries = COUNTRIES;

  loading: boolean = false;

  error: any = {};

  phone: string = '';
  selectedCountry: {
    code;
    countryName;
    countryImg;
  };

  mobilePhoneESRegex = /^((6|7){1}[0-9]{8})$/g;
  phoneGlobalRegex = /^[0-9]{7,14}$/g;
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    protected platform: Platform,
    protected modalCtrl: ModalController,
    protected navCtrl: NavController,
    protected cdRef: ChangeDetectorRef,
    protected authService: AuthService,
    protected events: Events,
    protected analyticsService: AnalyticsService
  ) {
    super(platform, cdRef);

    // Sort countries
    this.countries.sort((a, b) => a.countryName < b.countryName ? -1 : 1);
    // Set default country to Spain
    this.selectedCountry = this.countries.find(c => c.code === '34');

    // Handle backbutton
    this.platform.backButton.subscribeWithPriority(10, async (processNextHandler) => {
      !this.loading ? this.back() : null;
    });
  }

  isWeb() {
    return !this.platform.is('hybrid');
  }

  goToPage(page: any, data?: any, root: boolean = false) {
    if ( root ) {
      this.nav.setRoot(
        page,
        {
          nav: this.nav,
          ...data
        },
        { 
          animated: true,
          direction: 'forward',
          mode: 'ios' }
      );
    } else {
      this.nav.push(
        page,
        {
          nav: this.nav,
          ...data
        },
        { mode: 'ios' }
      );
    }
  }

  async back() {
    if ( (await this.nav.canGoBack()) ) 
      this.nav.pop({ mode: 'ios' });
    else 
      this.exit();
  }

  exit() {
    this.navCtrl.navigateRoot('/login', { animationDirection: 'back' });
  }

  /**
   * On phone country flag changed
   * @param {any} event
   */
  changeFlag(event: any) {
    this.selectedCountry = this.countries
      .find(c => c.code === event.detail.value.code && c.countryName === event.detail.value.countryName);


    if ( this.phone !== undefined && this.phone.trim() !== '' ) {
        this.error.phone = this.selectedCountry.code === '34'
          ? this.phone.trim().match(this.mobilePhoneESRegex) === null
          : this.phone.trim().match(this.phoneGlobalRegex) === null;
    }
  }

  /**
   * Returns formatted country prefix
   */
  getCountryPrefix(): string {
    return '+' + this.selectedCountry.code;
  }

  async reCheckPhone(phone: string, selectedCountry: {code: string; countryName: string; countryImg: string}): Promise<any> {
    const modal = await this.modalCtrl.create({
      cssClass: 'fit-modal floating-modal',
      component: PhoneCheckComponent,
      componentProps: {
        countries: this.countries,
        selectedCountry: selectedCountry,
        phoneNumber: phone
      }
    });
    modal.present();

    const { data } = await modal.onWillDismiss();

    return data;
  }

  saveImages(profile: any): Promise<{avatar: string, banner: string}> {
    const promise = new Promise<{avatar: string, banner: string}>((resolve, reject) => {
      const response: any = {avatar: undefined, banner: undefined}

      if ( profile.bannerSrc ) {
        forkJoin([
          this.authService.uploadProfileTempImage(profile.avatar).toPromise(),
          this.authService.uploadProfileTempImage(profile.banner, 'banner').toPromise()
        ]).subscribe(
          result => {
            if ( result[0] && result[0].done ) {
              response.avatar = result[0].file_cdn ? result[0].file_cdn : result[0].file;
            } else {
              this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error al subir tu imagen de perfil, vuelve a intentarlo.');
              Sentry.captureMessage(`[REGISTER] upload user ${profile.name} profile image`, "error");
            }

            if ( result[1] && result[1].done ) {
              response.banner = result[1].file_cdn ? result[1].file_cdn : result[1].file;
            } else {
              this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error al subir tu imagen de banner, vuelve a intentarlo.');
              Sentry.captureMessage(`[REGISTER] upload user ${profile.name} banner image`, "error");
            }

            resolve(response);
          }, error => {
            reject();
          }
        );
      } else {
        this.authService.uploadProfileTempImage(profile.avatar).subscribe(
          result => {
            if ( result && result.done ) {
              response.avatar = result.file_cdn ? result.file_cdn : result.file;
            } else {
              this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error al subir tu imagen de perfil, vuelve a intentarlo.');
              Sentry.captureMessage(`[REGISTER] upload user ${profile.name} profile image`, "error");
            }

            resolve(response);
          }, error => {
            reject();
          })
      }
    })

    return promise;
  }

  loginAndGoHome(username, password) {
    this.authService.login(username, password).then(
      (res) => {
        if (res) {
          this.events.publish(environment.EVENTS.START_INVITATION_TIMEOUT);
          this.events.publish(environment.EVENTS.USER_LOGIN, environment.APP_ROUTES.HOME);
          this.analyticsService.logEvent('new_user_registered');
          Sentry.captureMessage(`[REGISTER] user ${username} registered successfully`, "info");
        } else {
          this.navCtrl.navigateRoot('/login', { animationDirection: 'back' });
          this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR);
          Sentry.captureMessage(`[REGISTER] user ${username} registered but login failed`, "error");
        }
      }, (err) => {
        this.navCtrl.navigateRoot('/login', { animationDirection: 'back' });
        this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR);
        Sentry.captureMessage(`[REGISTER] user ${username} registered but login failed`, "error");
      }
    );
  }

  login(username, password) {
    this.authService.login(username, password, true).then(
      (res) => {
        if (res) {
          this.analyticsService.logEvent('new_user_registered');
          Sentry.captureMessage(`[REGISTER] user ${username} registered successfully`, "info");
        } else {
          this.navCtrl.navigateRoot('/login', { animationDirection: 'back' });
          this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR);
          Sentry.captureMessage(`[REGISTER] user ${username} registered but login failed`, "error");
        }
      }, (err) => {
        this.navCtrl.navigateRoot('/login', { animationDirection: 'back' });
        this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR);
        Sentry.captureMessage(`[REGISTER] user ${username} registered but login failed`, "error");
      }
    );
  }
  
  goHome() {
    this.events.publish(environment.EVENTS.START_INVITATION_TIMEOUT);
    this.events.publish(environment.EVENTS.USER_LOGIN, environment.APP_ROUTES.HOME);
  }
}
