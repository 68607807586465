<div
  class="video-stream video-participants"
  *ngIf="
    (inEmition || remoteEmition || preparingEmit) &&
    (privateRoom || (publication && !publication.onlyAudio && !isUploaded()))
  "
  (mouseenter)="activateOverlay(); autoPlay()"
>
  <div
    class="owner-videos video"
    [ngClass]="{
      maximized: maximized,
      emitting: inEmition || showEmit
    }"
  >
    <div
      class="preview-video-stream-container"
      (mouseenter)="activateOverlay()"
      [ngClass]="'anims-video'"
    >
      <div class="anim"></div>
      <div #videoLocal></div>
      <div class="prepared-emit"></div>
      <div class="streams-videos">
        <div class="stream-local" *ngIf="preparingEmit">
          <div
            class="poster"
            *ngIf="preparedTrack.isMuted || processChangeCamera"
            alt=""
          ></div>
          <video
            #stream
            class="local not-freezed"
            [ngClass]="{
              paused: preparedTrack.isMuted,
              girated: isGirated
            }"
            *ngIf="preparingEmit && !processChangeCamera"
            width="100%"
            height="100%"
            poster="../../../assets/estrim/poster/logo-light-square.png"
            autoplay
            muted
            playsInline
          ></video>
        </div>

        <div class="stream" *ngIf="userCenter">
          <div class="owner-videos" style="width: 100%; height: 100%">
            <div
              style="
                display: block;
                width: 100% !important;
                height: 100%;
                max-height: 100vh;
                aspect-ratio: 16/9;
                background-color: rgb(255 255 255 / 0%);
                position: relative;
              "
              class="container-peer pidPA_7kGnxXNdY2hC csidTR_VCdSjAf4L5j46V local-peer ng-tns-c184-3"
            >
              <div
                class="user-icon"
                *ngIf="
                  !haveCamera(userCenter.participant) ||
                  cameraIsPaused(userCenter.participant)
                "
              >
                <app-stream-icon
                  (showProfileEvent)="showProfile($event)"
                  [user]="userCenter"
                  [isCenter]="true"
                  [onlyAudio]="false"
                ></app-stream-icon>
              </div>
              <div
                style="
                  position: absolute;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  z-index: 1;
                "
                class="overlay"
                (click)="centerInScreen(userCenter)"
              ></div>
              <div
                style="
                  position: absolute;
                  top: 10px;
                  right: 10px;
                  z-index: 5;
                  color: var(--white);
                "
                class="icons"
              >
                <span class="mic" *ngIf="!userCenter.local && inEmition">
                  <svg
                    *ngIf="haveMic(userCenter.participant)"
                    class="unmute"
                    fill="currentColor"
                    height="20"
                    viewBox="0 0 16 16"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z"
                    />
                    <path
                      d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z"
                    />
                  </svg>
                  <svg
                    *ngIf="!haveMic(userCenter.participant)"
                    class="mute active"
                    fill="currentColor"
                    height="20"
                    viewBox="0 0 16 16"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 8c0 .564-.094 1.107-.266 1.613l-.814-.814A4.02 4.02 0 0 0 12 8V7a.5.5 0 0 1 1 0v1zm-5 4c.818 0 1.578-.245 2.212-.667l.718.719a4.973 4.973 0 0 1-2.43.923V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 1 0v1a4 4 0 0 0 4 4zm3-9v4.879l-1-1V3a2 2 0 0 0-3.997-.118l-.845-.845A3.001 3.001 0 0 1 11 3z"
                    />
                    <path
                      d="m9.486 10.607-.748-.748A2 2 0 0 1 6 8v-.878l-1-1V8a3 3 0 0 0 4.486 2.607zm-7.84-9.253 12 12 .708-.708-12-12-.708.708z"
                    />
                  </svg>
                </span>
                <span class="camera" *ngIf="!userCenter.local && inEmition">
                  <svg
                    *ngIf="
                      haveCamera(userCenter.participant) &&
                      !cameraIsPaused(userCenter.participant)
                    "
                    class="unmute"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 59.54 42"
                  >
                    <defs></defs>
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Capa_1" data-name="Capa 1">
                        <g id="Video_Recorder" data-name="Video Recorder">
                          <rect x="2" y="2" width="38" height="38" rx="11.55" />
                          <polyline
                            points="40 10.77 57.54 4.92 57.54 37.08 40 31.23"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                  <svg
                    *ngIf="
                      !haveCamera(userCenter.participant) ||
                      cameraIsPaused(userCenter.participant)
                    "
                    class="mute active"
                    viewBox="0 0 59.54 42"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="4"
                      x1="10"
                      x2="45"
                      y1="0"
                      y2="45"
                    />
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Capa_1" data-name="Capa 1">
                        <g id="Video_Recorder" data-name="Video Recorder">
                          <rect x="2" y="2" width="38" height="38" rx="11.55" />
                          <polyline
                            points="40 10.77 57.54 4.92 57.54 37.08 40 31.23"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
              </div>
              <div
                style="
                  display: block;
                  width: 100% !important;
                  height: 100%;
                  max-height: 100vh;
                  aspect-ratio: 16/9;
                  background-color: rgb(255 255 255 / 0%);
                  position: relative;
                "
                class="video"
              >
                <app-stream-video
                  *ngFor="
                    let media of userCenter.medias;
                    let i = index;
                    trackBy: trackTrack
                  "
                  [ngStyle]="{ display: !hideStream ? 'inline' : 'none' }"
                  [giratedVideos]="giratedVideos"
                  [participant]="userCenter.participant"
                  [track]="media.track"
                  [autoplay]="true"
                  [local]="userCenter.local"
                >
                </app-stream-video>
              </div>
              <div>
                <style>
                  .icons span {
                    display: inline-block;
                    margin-right: 5px;
                  }

                  .icons span svg {
                    display: none;
                  }

                  .icons span svg.active {
                    display: inline-block;
                  }

                  .icons .camera svg {
                    stroke: var(--white);
                    stroke-width: 4px;
                    width: 20px;
                    height: 20px;
                    fill: transparent;
                  }

                  @keyframes fixingFreezeAnim {
                    from {
                      outline: 1px solid rgba(1, 1, 1, 0);
                    }

                    to {
                      outline: 2px solid rgba(0, 0, 0, 0.1);
                    }
                  }

                  .fixNotFreeze {
                    animation-name: fixingFreezeAnim;
                    animation-duration: 1s;
                    animation-iteration-count: infinite;
                  }

                  .screen {
                    object-fit: contain !important;
                    background-color: #000 !important;
                  }
                </style>
              </div>
            </div>
          </div>
        </div>

        <div
          class="collaborator-videos"
          [ngClass]="{ overlay: overlayActivated, privated: privateRoom }"
        >
          <div
            [ngClass]="{
              'share-camera':
                haveCamera(user.participant) &&
                !cameraIsPaused(user.participant)
            }"
            *ngFor="let user of users; let i = index; trackBy: trackPeer"
            class="root"
            style="
              width: calc(33.33% - 4px);
              height: auto;
              max-height: 100vh;
              object-fit: contain !important;
              aspect-ratio: 16/9;
              border-radius: 7px; /*margin: 2px;*/
              position: relative;
            "
          >
            <div
              style="
                display: block;
                width: 100%;
                height: 100%;
                max-height: 100vh;
                aspect-ratio: 16/9;
                background-color: rgb(255 255 255 / 0%);
                position: relative;
              "
              class="container-peer pidPA_7kGnxXNdY2hC csidTR_VCdSjAf4L5j46V local-peer ng-tns-c184-3"
            >
              <div
                class="user-icon"
                *ngIf="
                  !haveCamera(user.participant) ||
                  cameraIsPaused(user.participant)
                "
              >
                <app-stream-icon
                  (showProfileEvent)="showProfile($event)"
                  [user]="user"
                  [onlyAudio]="false"
                ></app-stream-icon>
              </div>
              <div
                style="
                  position: absolute;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  z-index: 1;
                "
                class="overlay"
                (click)="centerInScreen(user)"
              ></div>
              <div
                style="
                  position: absolute;
                  top: 10px;
                  right: 10px;
                  z-index: 5;
                  color: var(--white);
                "
                class="icons"
              >
                <span class="mic" *ngIf="!user.local && inEmition">
                  <svg
                    *ngIf="haveMic(user.participant)"
                    class="unmute"
                    fill="currentColor"
                    height="20"
                    viewBox="0 0 16 16"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z"
                    />
                    <path
                      d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z"
                    />
                  </svg>
                  <svg
                    *ngIf="!haveMic(user.participant)"
                    class="mute active"
                    fill="currentColor"
                    height="20"
                    viewBox="0 0 16 16"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 8c0 .564-.094 1.107-.266 1.613l-.814-.814A4.02 4.02 0 0 0 12 8V7a.5.5 0 0 1 1 0v1zm-5 4c.818 0 1.578-.245 2.212-.667l.718.719a4.973 4.973 0 0 1-2.43.923V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 1 0v1a4 4 0 0 0 4 4zm3-9v4.879l-1-1V3a2 2 0 0 0-3.997-.118l-.845-.845A3.001 3.001 0 0 1 11 3z"
                    />
                    <path
                      d="m9.486 10.607-.748-.748A2 2 0 0 1 6 8v-.878l-1-1V8a3 3 0 0 0 4.486 2.607zm-7.84-9.253 12 12 .708-.708-12-12-.708.708z"
                    />
                  </svg>
                </span>
                <span class="camera" *ngIf="!user.local && inEmition">
                  <svg
                    *ngIf="
                      haveCamera(user.participant) &&
                      !cameraIsPaused(user.participant)
                    "
                    class="unmute"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 59.54 42"
                  >
                    <defs></defs>
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Capa_1" data-name="Capa 1">
                        <g id="Video_Recorder" data-name="Video Recorder">
                          <rect x="2" y="2" width="38" height="38" rx="11.55" />
                          <polyline
                            points="40 10.77 57.54 4.92 57.54 37.08 40 31.23"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                  <svg
                    *ngIf="
                      !haveCamera(user.participant) ||
                      cameraIsPaused(user.participant)
                    "
                    class="mute active"
                    viewBox="0 0 59.54 42"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="4"
                      x1="10"
                      x2="45"
                      y1="0"
                      y2="45"
                    />
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Capa_1" data-name="Capa 1">
                        <g id="Video_Recorder" data-name="Video Recorder">
                          <rect x="2" y="2" width="38" height="38" rx="11.55" />
                          <polyline
                            points="40 10.77 57.54 4.92 57.54 37.08 40 31.23"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
              </div>
              <div
                style="
                  display: block;
                  width: 100% !important;
                  height: 100%;
                  max-height: 100vh;
                  aspect-ratio: 16/9;
                  background-color: rgb(255 255 255 / 0%);
                  position: relative;
                "
                class="video"
              >
                <app-stream-video
                  *ngFor="
                    let media of user.medias;
                    let i = index;
                    trackBy: trackTrack
                  "
                  [ngStyle]="{ display: !hideStream ? 'inline' : 'none' }"
                  [privateRoom]="privateRoom"
                  [giratedVideos]="giratedVideos"
                  [participant]="user.participant"
                  [isCollaborator]="true"
                  [track]="media.track"
                  [autoplay]="true"
                  [local]="user.local"
                >
                </app-stream-video>
              </div>
              <div>
                <style>
                  .icons span {
                    display: inline-block;
                    margin-right: 5px;
                  }

                  .icons span svg {
                    display: none;
                  }

                  .icons span svg.active {
                    display: inline-block;
                  }

                  .icons .camera svg {
                    stroke: var(--white);
                    stroke-width: 4px;
                    width: 20px;
                    height: 20px;
                    fill: transparent;
                  }

                  @keyframes fixingFreezeAnim {
                    from {
                      outline: 1px solid rgba(1, 1, 1, 0);
                    }

                    to {
                      outline: 2px solid rgba(0, 0, 0, 0.1);
                    }
                  }

                  .fixNotFreeze {
                    animation-name: fixingFreezeAnim;
                    animation-duration: 1s;
                    animation-iteration-count: infinite;
                  }

                  .screen {
                    object-fit: contain !important;
                    background-color: #000 !important;
                  }
                </style>
              </div>
            </div>
          </div>
        </div>

        <div
          class="call-ui-buttons"
          *ngIf="privateRoom && !publication && overlayActivated"
        >
          <div drag class="pressable-cycle-div">
            <div class="handle"></div>
          </div>

          <div class="live-tool-row">
            <div class="col">
              <ion-button
                shape="round"
                [ngClass]="{
                  'button-disabled':
                    processChangeCamera || processPrepared || processEmited,
                  'button-enabled':
                    !processChangeCamera && !processPrepared && !processEmited
                }"
                [disabled]="
                  processChangeCamera || processPrepared || processEmited
                "
                *ngIf="inEmition || preparingEmit"
                fill="clear"
                class="camera icon"
                (click)="toggleCamera()"
              >
                <svg
                  *ngIf="shareCamera"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 59.54 42"
                >
                  <defs></defs>
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Capa_1" data-name="Capa 1">
                      <g id="Video_Recorder" data-name="Video Recorder">
                        <rect x="2" y="2" width="38" height="38" rx="11.55" />
                        <polyline
                          points="40 10.77 57.54 4.92 57.54 37.08 40 31.23"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
                <svg
                  *ngIf="!shareCamera"
                  viewBox="0 0 59.54 42"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="4"
                    x1="10"
                    x2="45"
                    y1="0"
                    y2="45"
                  />
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Capa_1" data-name="Capa 1">
                      <g id="Video_Recorder" data-name="Video Recorder">
                        <rect x="2" y="2" width="38" height="38" rx="11.55" />
                        <polyline
                          points="40 10.77 57.54 4.92 57.54 37.08 40 31.23"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </ion-button>
            </div>

            <div class="col">
              <ion-button
                shape="round"
                [disabled]="
                  processToogleMicrophone || processPrepared || processEmited
                "
                *ngIf="inEmition || preparingEmit"
                fill="clear"
                class="mic icon"
                (click)="toggleMic()"
              >
                <svg
                  *ngIf="shareMic"
                  class="bi bi-mic unmute active"
                  fill="currentColor"
                  height="28"
                  viewBox="0 0 16 16"
                  width="28"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z"
                  />
                  <path
                    d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z"
                  />
                </svg>
                <svg
                  *ngIf="!shareMic"
                  class="bi bi-mic-mute mute active"
                  fill="currentColor"
                  height="28"
                  viewBox="0 0 16 16"
                  width="28"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 8c0 .564-.094 1.107-.266 1.613l-.814-.814A4.02 4.02 0 0 0 12 8V7a.5.5 0 0 1 1 0v1zm-5 4c.818 0 1.578-.245 2.212-.667l.718.719a4.973 4.973 0 0 1-2.43.923V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 1 0v1a4 4 0 0 0 4 4zm3-9v4.879l-1-1V3a2 2 0 0 0-3.997-.118l-.845-.845A3.001 3.001 0 0 1 11 3z"
                  />
                  <path
                    d="m9.486 10.607-.748-.748A2 2 0 0 1 6 8v-.878l-1-1V8a3 3 0 0 0 4.486 2.607zm-7.84-9.253 12 12 .708-.708-12-12-.708.708z"
                  />
                </svg>
              </ion-button>
            </div>

            <div
              class="col"
              *ngIf="
                (inEmition || preparingEmit) &&
                numVideoDevices >= 1 &&
                isHybrid()
              "
            >
              <ion-button
                [ngClass]="{
                  'button-disabled':
                    inFacingMode ||
                    processChangeCamera ||
                    !shareCamera ||
                    processEmited ||
                    processPrepared ||
                    !localCamera,
                  'button-enabled':
                    !inFacingMode &&
                    !processChangeCamera &&
                    shareCamera &&
                    !processEmited &&
                    !processPrepared &&
                    localCamera
                }"
                shape="round"
                [disabled]="
                  inFacingMode ||
                  processChangeCamera ||
                  !shareCamera ||
                  processEmited ||
                  processPrepared ||
                  !localCamera
                "
                fill="clear"
                class="mic icon"
                (click)="activeFaceCamera()"
              >
                <ion-icon name="camera-reverse-outline"></ion-icon>
              </ion-button>
            </div>

            <div
              class="col"
              *ngIf="
                (inEmition || preparingEmit) &&
                numVideoDevices >= 1 &&
                !isHybrid()
              "
            >
              <ion-button
                shape="round"
                [disabled]="
                  inFacingMode ||
                  processChangeCamera ||
                  !shareCamera ||
                  processEmited ||
                  processPrepared ||
                  !localCamera
                "
                fill="clear"
                class="mic icon"
                (click)="facingMode()"
              >
                <ion-icon name="camera-reverse-outline"></ion-icon>
              </ion-button>
            </div>

            <div class="col" *ngIf="inPreview || inEmition">
              <ion-button
                [disabled]="!shareCamera || processGirated"
                shape="round"
                fill="clear"
                class="mic icon"
                (click)="girateCamera()"
              >
                <ion-icon src="assets/mirror-icon.svg"></ion-icon>
              </ion-button>
            </div>

            <div class="col" *ngIf="inEmition">
              <ion-button
                shape="round"
                fill="clear"
                class="mic icon call-off"
                (click)="stopEmite(false)"
              >
                <ion-icon name="call-outline"></ion-icon>
              </ion-button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="buttons-bottom-styles buttons bottom overLay"
        *ngIf="overlayActivated && !privateRoom"
      >
        <div class="left">
          <ion-button
            [disabled]="processPrepared || processEmited"
            *ngIf="inPreview || inEmition"
            fill="clear"
            id="startEmittingBtn"
            class="startEmitting"
            (click)="emite(false)"
          >
            <span *ngIf="inPreview">Empezar a emitir</span>
            <span *ngIf="inEmition">Dejar emisión</span>
          </ion-button>
        </div>
        <div class="right">
          <ion-button
            [ngClass]="{
              'button-disabled': processGirated || !shareCamera,
              'button-enabled': !processGirated && !shareCamera
            }"
            [disabled]="processGirated || !shareCamera"
            fill="clear"
            class="camera"
            *ngIf="inPreview || inEmition"
            (click)="girateCamera()"
          >
            <ion-icon
              *ngIf="shareCamera"
              src="assets/mirror-icon.svg"
            ></ion-icon>
          </ion-button>

          <ion-button
            fill="clear"
            class="camera"
            [ngClass]="{
              'button-disabled':
                processChangeCamera || processPrepared || processEmited,
              'button-enabled':
                !processChangeCamera && !processPrepared && !processEmited
            }"
            [disabled]="processChangeCamera || processPrepared || processEmited"
            *ngIf="inEmition || preparingEmit"
            (click)="toggleCamera()"
          >
            <svg
              *ngIf="shareCamera"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 59.54 42"
            >
              <defs></defs>
              <g id="Layer_2" data-name="Layer 2">
                <g id="Capa_1" data-name="Capa 1">
                  <g id="Video_Recorder" data-name="Video Recorder">
                    <rect x="2" y="2" width="38" height="38" rx="11.55" />
                    <polyline
                      points="40 10.77 57.54 4.92 57.54 37.08 40 31.23"
                    />
                  </g>
                </g>
              </g>
            </svg>
            <svg
              *ngIf="!shareCamera"
              viewBox="0 0 59.54 42"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="4"
                x1="10"
                x2="45"
                y1="0"
                y2="45"
              />
              <g id="Layer_2" data-name="Layer 2">
                <g id="Capa_1" data-name="Capa 1">
                  <g id="Video_Recorder" data-name="Video Recorder">
                    <rect x="2" y="2" width="38" height="38" rx="11.55" />
                    <polyline
                      points="40 10.77 57.54 4.92 57.54 37.08 40 31.23"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </ion-button>

          <ion-button
            [ngClass]="{
              'button-disabled':
                processToogleMicrophone || processPrepared || processEmited,
              'button-enabled':
                !processToogleMicrophone && !processPrepared && !processEmited
            }"
            [disabled]="
              processToogleMicrophone || processPrepared || processEmited
            "
            fill="clear"
            class="mic"
            *ngIf="inEmition || preparingEmit"
            (click)="toggleMic()"
          >
            <svg
              *ngIf="shareMic"
              class="bi bi-mic unmute active"
              fill="currentColor"
              height="28"
              viewBox="0 0 16 16"
              width="28"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z"
              />
              <path
                d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z"
              />
            </svg>
            <svg
              *ngIf="!shareMic"
              class="bi bi-mic-mute mute active"
              fill="currentColor"
              height="28"
              viewBox="0 0 16 16"
              width="28"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 8c0 .564-.094 1.107-.266 1.613l-.814-.814A4.02 4.02 0 0 0 12 8V7a.5.5 0 0 1 1 0v1zm-5 4c.818 0 1.578-.245 2.212-.667l.718.719a4.973 4.973 0 0 1-2.43.923V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 1 0v1a4 4 0 0 0 4 4zm3-9v4.879l-1-1V3a2 2 0 0 0-3.997-.118l-.845-.845A3.001 3.001 0 0 1 11 3z"
              />
              <path
                d="m9.486 10.607-.748-.748A2 2 0 0 1 6 8v-.878l-1-1V8a3 3 0 0 0 4.486 2.607zm-7.84-9.253 12 12 .708-.708-12-12-.708.708z"
              />
            </svg>
          </ion-button>
          <ion-button
            fill="clear"
            class="maximize"
            [ngClass]="{
              'button-disabled': processPrepared || processEmited,
              'button-enabled': !processPrepared && !processEmited
            }"
            [disabled]="processPrepared || processEmited"
            *ngIf="
              !publication?.onlyAudio &&
              (inEmition || preparingEmit) &&
              canShareScreen
            "
            (click)="shareScreen()"
          >
            <ion-img
              *ngIf="!isShareScreen"
              class="share-icon"
              src="assets/icons-app/share-screen.png"
            ></ion-img>
            <ion-img
              *ngIf="isShareScreen"
              class="share-icon"
              src="assets/icons-app/on-share-screen.png"
            ></ion-img>
          </ion-button>

          <!--
          <ion-button
            fill="clear"
            class="maximize"
            [disabled]="processPrepared || processEmited"
            *ngIf="!publication?.onlyAudio || preparingEmit"
            (click)="maximize()"
          >
            <ion-icon name="expand-outline" *ngIf="!maximized"></ion-icon>
            <ion-icon name="contract-outline" *ngIf="maximized"></ion-icon>
          </ion-button>-->
        </div>
      </div>
    </div>

    <!-- Overlay viewers -->
    <div
      class="viewers"
      *ngIf="!preparingEmit && !privateRoom"
      [ngClass]="{ overlayOn: overlayActivated }"
    >
      <!-- Viewers -->
      <ion-button fill="clear" (click)="showViewersModal()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54.63 61.86">
          <defs></defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Capa_1" data-name="Capa 1">
              <g id="New_User_Add_User" data-name="New User, Add User">
                <circle cx="20.08" cy="12.85" r="10.85" />
                <path
                  d="M2,59.86V49A14.46,14.46,0,0,1,16.46,34.55H23.7A14.45,14.45,0,0,1,38.16,49V59.86"
                />
                <circle cx="20.08" cy="12.85" r="10.85" />
                <path
                  d="M2,59.86V49A14.46,14.46,0,0,1,16.46,34.55H23.7A14.45,14.45,0,0,1,38.16,49V59.86"
                />
              </g>
            </g>
          </g>
        </svg>
        <span class="num">{{ numParticipants }}</span>
      </ion-button>
      <!-- Viewers modal -->
      <ion-modal
        class="inline-modal viewers-modal"
        [isOpen]="showViewers"
        [initialBreakpoint]="1"
        [breakpoints]="[0, 1]"
        (willDismiss)="viewersModalDismiss()"
      >
        <ng-template>
          <app-user-list
            [publication]="publication"
            [collaboratorsOnline]="listOnline"
            [assistants]="assistants"
            [iamFollowing]="getUserDataArrays"
            (reducedProfile)="showProfile($event)"
            handleBehavior="cycle"
          >
          </app-user-list>
        </ng-template>
      </ion-modal>
    </div>
    <!-- collaboratorsOnline -->

    <!-- Overlay buttons top -->
    <div
      class="buttons top isWeb"
      *ngIf="
        overlayActivated ||
        preparingEmit ||
        inEmition ||
        remoteEmition ||
        inPreview
      "
      @inOutFade
      [ngClass]="{ 'right-padding': !itsMe && !canCast }"
    >
      <div class="left">
        <ion-button class="ghost" disabled></ion-button>
      </div>

      <div class="right">
        <ion-button
          fill="clear"
          class="changeCamera overLay"
          [ngClass]="{
            'button-disabled':
              inFacingMode ||
              processChangeCamera ||
              !shareCamera ||
              processEmited ||
              processPrepared,
            'button-enabled':
              !inFacingMode &&
              !processChangeCamera &&
              shareCamera &&
              !processEmited &&
              !processPrepared
          }"
          [disabled]="
            inFacingMode ||
            processChangeCamera ||
            !shareCamera ||
            processEmited ||
            processPrepared
          "
          *ngIf="
            (inEmition || preparingEmit) &&
            publication &&
            !publication.onlyAudio &&
            numVideoDevices >= 1 &&
            isHybrid() &&
            overlayActivated &&
            !processChangeCamera &&
            shareCamera &&
            (localCamera || preparingEmit)
          "
          (click)="preparingEmit ? activeFaceCamera() : activeFaceCamera()"
        >
          <ion-icon name="camera-reverse-outline"></ion-icon>
        </ion-button>

        <ion-button
          fill="clear"
          class="changeCamera overLay"
          [disabled]="
            inFacingMode ||
            processChangeCamera ||
            !shareCamera ||
            processEmited ||
            processPrepared
          "
          *ngIf="
            (inEmition || preparingEmit) &&
            publication &&
            !publication.onlyAudio &&
            numVideoDevices >= 1 &&
            !isHybrid() &&
            overlayActivated &&
            !processChangeCamera &&
            shareCamera &&
            (localCamera || preparingEmit)
          "
          (click)="preparingEmit ? facingMode() : facingMode()"
        >
          <ion-icon name="camera-reverse-outline"></ion-icon>
        </ion-button>

        <!-- Star counter -->
        <ion-button
          class="auxiliary star"
          fill="clear"
          *ngIf="isHybrid() && (inEmition || preparingEmit)"
          (click)="showStarSenderList.emit()"
        >
          <app-star-counter
            [count]="publication.stars"
            [filled]="starFilled"
            [whiteStar]="true"
          >
          </app-star-counter>
        </ion-button>
      </div>
    </div>
  </div>
</div>

<!-- Audio stream -->
<div
  class="audio-stream audios-participants div-audio"
  *ngIf="
    (inEmition || remoteEmition) &&
    publication &&
    publication.onlyAudio &&
    !isUploaded()
  "
  (mouseenter)="activateOverlay()"
>
  <div class="owner-videos audio">
    <!-- Overlay viewers -->
    <div class="viewers" [ngClass]="{ overlayOn: overlayActivated }">
      <!-- Viewers -->
      <ion-button fill="clear" (click)="showViewersModal()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54.63 61.86">
          <defs></defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Capa_1" data-name="Capa 1">
              <g id="New_User_Add_User" data-name="New User, Add User">
                <circle cx="20.08" cy="12.85" r="10.85" />
                <path
                  d="M2,59.86V49A14.46,14.46,0,0,1,16.46,34.55H23.7A14.45,14.45,0,0,1,38.16,49V59.86"
                />
                <circle cx="20.08" cy="12.85" r="10.85" />
                <path
                  d="M2,59.86V49A14.46,14.46,0,0,1,16.46,34.55H23.7A14.45,14.45,0,0,1,38.16,49V59.86"
                />
              </g>
            </g>
          </g>
        </svg>
        <span class="num">{{ numParticipants }}</span>
      </ion-button>
      <!-- Viewers modal -->
      <ion-modal
        class="inline-modal viewers-modal"
        [isOpen]="showViewers"
        [initialBreakpoint]="1"
        [breakpoints]="[0, 1]"
        (willDismiss)="viewersModalDismiss()"
      >
        <ng-template>
          <app-user-list
            [publication]="publication"
            [collaboratorsOnline]="listOnline"
            [assistants]="assistants"
            [iamFollowing]="getUserDataArrays"
            (reducedProfile)="showProfile($event)"
            handleBehavior="cycle"
          >
          </app-user-list>
        </ng-template>
      </ion-modal>
    </div>

    <!-- Overlay buttons top -->
    <div
      class="buttons top isWeb"
      *ngIf="
        overlayActivated ||
        preparingEmit ||
        inEmition ||
        remoteEmition ||
        inPreview
      "
      @inOutFade
      [ngClass]="{ 'right-padding': !itsMe && !canCast }"
    >
      <div class="left">
        <ion-button class="ghost" disabled></ion-button>
      </div>

      <div class="right">
        <!-- Star counter -->
        <ion-button
          class="auxiliary star"
          fill="clear"
          *ngIf="isHybrid() && inEmition"
          (click)="showStarSenderList.emit()"
        >
          <app-star-counter
            [count]="publication.stars"
            [filled]="starFilled"
            [whiteStar]="true"
          >
          </app-star-counter>
        </ion-button>
      </div>

      <div
        #collaboratorList
        class="online-collaborators"
        *ngIf="itsMe && publication && !publication.onlyAudio"
      >
        <div
          class="collaborator"
          *ngFor="let collabOn of getOnlineCollaboratorList()"
        >
          <img
            handleError
            thumbnail
            [size]="150"
            [source]="
              mediaServ.generateImgProfileURL(collabOn.id, collabOn.imgProfile)
            "
          />
        </div>
      </div>
    </div>

    <div
      class="speakers"
      [ngClass]="{
        'reduced-1': users.length > 6 && users.length <= 8,
        'reduced-2': users.length > 8
      }"
    >
      <div class="user-icon">
        <app-stream-icon
          (showProfileEvent)="showProfile($event)"
          *ngFor="let peer of users; let i = index; trackBy: trackPeer"
          [user]="peer"
          [showStreams]="true"
        ></app-stream-icon>
      </div>
    </div>
  </div>
  <div class="collaborator-videos"></div>
</div>
