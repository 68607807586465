<div class="content" (click)="close()">
  <!-- Cerrar -->
  <div class="close">
    <ion-icon name="chevron-back"></ion-icon>
  </div>

  <pinch-zoom [auto-zoom-out]="true">
    <!-- <img 
      handleError
      thumbnail
      [size]="1024"
      [source]="imageSrc"> -->
    <lazy-img
      [size]="1024"
      [source]="imageSrc">    
    </lazy-img>
  </pinch-zoom>
</div>