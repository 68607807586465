import { Component, Input, Output, ChangeDetectorRef, ElementRef, EventEmitter, AfterViewChecked, NgZone, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';

import { PublicationThumbnailsBaseComponent as BaseComponent } from '../publication-thumbnails-base.component';

import { MediaService } from 'src/app/services/media.service';
import { UserService } from 'src/app/services/user.service';
import { PublicationService } from 'src/app/services/publication.service';
import { Events } from 'src/app/services/events.service';
import { AuthService } from 'src/app/services/auth.service';
import { AnalyticsService } from 'src/app/services/analytics.service';

import { VideoPlayerComponent } from '../../video-player/video-player.component';
import { Publication } from 'src/app/interfaces/publication';

@Component({
  selector: 'app-record-video-thumbnail-v2',
  templateUrl: './record-video-thumbnail-v2.component.html',
  styleUrls: ['../publication-thumbnails.scss','./record-video-thumbnail-v2.component.scss']
})
export class RecordVideoThumbnailV2Component extends BaseComponent implements AfterViewChecked {

  @ViewChild(VideoPlayerComponent) videoPlayer: VideoPlayerComponent

  @Input() publication: Publication;

  @Output() onViewport: EventEmitter<any> = new EventEmitter();

  animateLikeThumbnail: boolean = false;
  animateLikeIcon: boolean = false;

  playing: boolean = false;

  componentHeight: number = 0;

  constructor(
    private platform: Platform,
    protected el: ElementRef,
    protected zone: NgZone,
    protected mediaService: MediaService,
    protected userService: UserService,
    protected publicationService: PublicationService,
    protected events: Events,
    protected authService: AuthService,
    protected analyticsService: AnalyticsService,
    protected router: Router,
    protected cdRef: ChangeDetectorRef
  ) {
    super(
      el,
      zone,
      mediaService, 
      userService, 
      publicationService,
      events,
      authService,
      analyticsService,
      router,
      cdRef
    )
  }

  getVideoSource() {
    return this.mediaService.generateVideoURL(this.publication);
  }

  enterPublication() {
    if ( this.playing ) {
      this.stop();
    }

    super.enterPublication('/publications/view');
  }

  sharePublication() {
    super.sharePublication('/publications/view');
  }

  checkIfOnViewport() {
    const top = this.el.nativeElement.getBoundingClientRect().top;

    if ( top > 56 && top < (this.platform.height() / 2) - 40 ) {
      this.onViewport.emit({top: top, component: this});
    } else {
      this.stop();
    }
  }

  play() {
    if ( !this.isLocked() && !this.playing ) {
      this.playing = true;
      this.cdRef.detectChanges();
      this.videoPlayer?.setMute(true);
      this.videoPlayer?.play();
    }
  }
  pause() {
    this.videoPlayer?.pause();
    this.playing = false;
    this.cdRef.detectChanges();
  }
  stop() {
    this.videoPlayer?.pause();
    this.videoPlayer?.seek({target: {value: 0}});
    this.playing = false;
    this.cdRef.detectChanges();
  }
  mute() {

  }
  unmute() {

  }
}
