<ion-content>
  <section id="er-info" class="section">
    <div class="content">
      <a class="go-back" (click)="back()">
        <img
          class="d-block"
          src="assets/extrim/img/arrow-left.webp"
          width="100"
        />
      </a>
      <div class="info-content">
        <img class="icon" src="assets/extrim/img/icons/i1.svg" alt="Icon 1" />
        <div class="title font-venera">SORTEO</div>
        <div>
          Para participar solo tienes que dejar tu voto. El sorteo finalizará el
          13 de Enero, día del evento en el Circuito Ricardo Tormo, antes de que
          comiencen las carreras. Si eres uno de los ganadores recibirás una
          notificación en Estrim App. Enviaremos el casco a tu casa, sin coste
          para ti. ¡Si eres uno de los asistentes del evento, el premio te lo
          entregará el XDRIVER que hayas votado!
        </div>
      </div>
      <div class="info-content">
        <img class="icon" src="assets/extrim/img/icons/i2.svg" alt="Icon 2" />
        <div class="title font-venera">INMUNIDAD</div>
        <div>
          Los 3 pilotos más votados serán inmunes en una de las 6 pruebas que se
          disputarán en el primer evento de Extrim Race. ¿A qué? ¡Top secret!
        </div>
      </div>
      <div class="info-content">
        <img class="icon" src="assets/extrim/img/icons/i3.svg" alt="Icon 3" />
        <div class="title font-venera">LASTRE</div>
        <div>
          Los 3 pilotos menos votados contarán con un culturista que pesará
          alrededor de 120kg. Esto influye muy negativamente en el
          comportamiento del coche, tendrá menos aceleración, menos velocidad,
          girará y frenará peor debido a la relación peso/potencia.
        </div>
      </div>
      <div class="info-content">
        <img
          class="circuit"
          src="assets/extrim/img/circuit.webp"
          alt="Circuito"
        />
        <div class="title font-venera">EL CIRCUITO</div>
        <div>
          Os damos la bienvenida a Extrim Race desde Cheste. Los pilotos
          preparan sus coches y calientan motores para verse las caras por
          primera vez en este emblemático circuito. Un misterioso piloto al que
          llaman Ghost Driver, estará en la parrilla de salida y correrá junto
          al resto, pero no revelará su identidad hasta la carrera final. Tomad
          asiento, comienza el espectáculo.
        </div>
      </div>
    </div>
  </section>
</ion-content>
