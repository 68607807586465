import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {KHeaderDesktopComponent} from './k-header-desktop.component';
import {IonicModule} from '@ionic/angular';
import {LazyImgModule} from "../lazy-img/lazy-img.module";
import {RouterLink} from "@angular/router";


@NgModule({
  declarations: [
    KHeaderDesktopComponent
  ],
    imports: [
        CommonModule,
        IonicModule,
        LazyImgModule,
        RouterLink,
    ],
  exports: [
    KHeaderDesktopComponent,
  ]
})
export class KHeaderDesktopModule { }
