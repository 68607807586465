<ng-container *ngIf="!skeleton">
  <div class="item app-record-video-thumbnail-v2" *ngIf="publication">
    <div class="thumb-wrapper aspect-video" (click)="enterPublication()">
      <div class="emitting-tag" *ngIf="false">
        <span>Directo</span>
      </div>
      <lazy-img
        class="thumb"
        alt="image"
        [size]="600"
        [source]="getThumbnail()"
        [ngClass]="{ hide: playing }"
      >
      </lazy-img>

      <app-video-player
        #videoPlayer
        *ngIf="playing"
        [autoplay]="false"
        [maximized]="false"
        [canMaximize]="false"
        [videoSrc]="getVideoSource()"
        [preload]="'auto'"
        [layoutButtons]="false"
        [seekButtons]="false"
        [poster]="getThumbnail()"
        [disableLoadingSpinner]="true"
      >
      </app-video-player>

      <div class="thumb-info timer" *ngIf="publication.duration">
        <span>{{publication.duration | duration}}</span>
      </div>
      <div class="thumb-info active-users" *ngIf="false">
        <ion-icon
          class="aspect-square"
          src="assets/item/user-white.svg"
        ></ion-icon>
        <span>11</span>
      </div>
      <div class="thumb-info event-price" *ngIf="false">
        <ion-icon class="aspect-square" src="assets/item/coin.svg"></ion-icon>
        <span>30</span>
      </div>
      <ion-icon
        class="thumb-options"
        *ngIf="showDots && publication?.author.id !== user?.id"
        src="assets/item/dots-with-space.svg"
        (click)="presentBlockPopover($event, ContentType.Video)"
      ></ion-icon>

      <div
        *ngIf="publication.isPremium"
        class="locked-content"
        [ngClass]="{ open: !isLocked() }"
      >
        <ion-icon *ngIf="isLocked()" name="lock-closed"></ion-icon>
        <ion-icon *ngIf="!isLocked()" name="lock-open"></ion-icon>
      </div>

      <app-heart-like
        *ngIf="animateLikeThumbnail"
        (animationDone)="animateLikeThumbnail = false"
      >
      </app-heart-like>
    </div>
    <div class="content">
      <div class="flex justify-between items-center mb-1">
        <div class="user-profile flex items-center" (click)="goToUserProfile()">
          <lazy-img
            class="thumb aspect-square rounded-full"
            alt="profile"
            [size]="60"
            [source]="getUserImg()"
          >
          </lazy-img>
          <div
            class="title username flex items-center capitalize-first-letter font-bold"
          >
            <span class="line-clamp line-clamp-1">
              {{ publication.author.username }}
            </span>
            <img
              handleError
              class="founder-badge"
              *ngIf="publication.author.isFounder"
              src="assets/badges/founder-badge.svg"
              alt="Founder badge"
            />
          </div>
        </div>
        <ul class="actions my-0">
          <li (click)="sharePublication()">
            <ion-icon
              class="aspect-square"
              src="assets/item/share.svg"
            ></ion-icon>
          </li>
          <li
            (click)="savePublication()"
            [ngClass]="{ filled: publication.isSaved }"
          >
            <ion-icon
              class="aspect-square"
              src="assets/item/bookmark.svg"
            ></ion-icon>
          </li>
          <li
            *ngIf="!isLocked()"
            (click)="likePublication()"
            class="like-action"
            [ngClass]="{ filled: publication.isLiked }"
          >
            <ion-icon
              class="aspect-square"
              src="assets/item/heart.svg"
              [@starAnimation1]="animateLikeIcon"
              (@starAnimation1.done)="animateLikeIcon = false"
            >
            </ion-icon>
          </li>
        </ul>
      </div>
      <div
        class="desc line-clamp line-clamp-2 mb-1"
        (click)="enterPublication()"
      >
        {{ publication.title }}
      </div>
      <div class="flex justify-between items-center">
        <!--<div class="event-date">
          <ion-icon
              class="aspect-square"
              src="assets/item/calendar.svg"
            ></ion-icon>
        </div>-->
        <ul
          class="interactions flex justify-between"
          (click)="enterPublication()"
        >
          <li class="likes">
            <strong>{{ publication.likesNumber | shortNumber }}</strong>
            <ion-icon
              class="aspect-square"
              src="assets/item/likes.svg"
              aria-label="Like"
            ></ion-icon>
          </li>
          <li class="answers" title="Answers">
            <strong>{{ publication.commentsNumber | shortNumber }}</strong>
            <ion-icon
              class="aspect-square"
              src="assets/item/answer.svg"
            ></ion-icon>
          </li>
          <li class="stars">
            <strong>{{ publication.stars | shortNumber }}</strong>
            <ion-icon
              class="aspect-square"
              src="assets/item/star.svg"
            ></ion-icon>
          </li>
        </ul>
        <div class="text-darkgray text-smaller flex inline-dot-list">
          <!-- <div class="views" *ngIf="publication.numViewers > 0">
            {{ publication.numViewers }}
            {{
              publication.numViewers > 1 ? "visualizaciones" : "visualización"
            }}
          </div> -->
          <div class="date">{{ publication.createdAt | dateAgo }}</div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="skeleton">
  <div class="item app-record-video-thumbnail-v2 mb-1">
    <ion-skeleton-text
      [animated]="true"
      class="thumb-wrapper aspect-video"
    ></ion-skeleton-text>

    <div class="content" style="padding-top: 0">
      <div class="flex justify-between items-center">
        <div class="user-profile flex items-center mb-1">
          <ion-skeleton-text
            class="rounded-full aspect-square mr-1"
            [animated]="true"
            style="width: 2em"
          ></ion-skeleton-text>
          <div class="title font-bold" style="width: 7em">
            <ion-skeleton-text [animated]="true"></ion-skeleton-text>
          </div>
        </div>
        <div class="flex justify-between items-center">
          <div class="flex">
            <ion-skeleton-text
              style="width: 2em; padding-bottom: 0.7em"
              class="mt-0 ml-1 rounded"
              [animated]="true"
            ></ion-skeleton-text>
            <ion-skeleton-text
              style="width: 2em"
              class="mt-0 ml-1 rounded"
              [animated]="true"
            ></ion-skeleton-text>
            <ion-skeleton-text
              style="width: 2em"
              class="mt-0 ml-1 rounded"
              [animated]="true"
            ></ion-skeleton-text>
          </div>
        </div>
      </div>
      <div class="desc line-clamp line-clamp-2 mb-1">
        <ion-skeleton-text [animated]="true"></ion-skeleton-text>
        <ion-skeleton-text
          [animated]="true"
          style="width: 75%"
        ></ion-skeleton-text>
      </div>
      <div class="flex justify-between items-center">
        <div class="flex">
          <ion-skeleton-text
            style="width: 2.7em; padding-bottom: 0.2em"
            class="mr-1"
            [animated]="true"
          ></ion-skeleton-text>
          <ion-skeleton-text
            style="width: 2.7em"
            class="mr-1"
            [animated]="true"
          ></ion-skeleton-text>
          <ion-skeleton-text
            style="width: 2.7em"
            class="mr-1"
            [animated]="true"
          ></ion-skeleton-text>
        </div>
        <ion-skeleton-text
          style="width: 40%"
          [animated]="true"
        ></ion-skeleton-text>
      </div>
    </div>
  </div>
</ng-container>
