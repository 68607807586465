<ion-content>
  <a (click)="dismiss()" class="close">
    <img src="assets/extrim/img/icons/close-white.svg" width="30" />
  </a>

  <section id="ranking-er" class="section">
    <div class="content">
      <div class="section-title relative h1 text-center font-venera">
        RANKING
        <a (click)="showInfo()">
          <img
            width="18"
            class="trigger-info"
            src="assets/extrim/img/icons/icon_info.svg"
            alt="Icon Info"
        /></a>
        <div class="live" *ngIf="state === ExtrimState.Live">LIVE</div>
      </div>
      <ul class="ranking">
        <li *ngFor="let pilot of pilots" (click)="confirmVote(pilot)" [ngClass]="{'started': state !== ExtrimState.BeforeRace}">
          <div class="content">
            <div class="thumb">
              <lazy-img [source]="mediaService.generateThumbnailImgURL(pilot.thumbnail)" [size]="400"></lazy-img>
            </div>
            <div>
              <h2>{{pilot.name}}</h2>
              <div *ngIf="pilot.voted" class="voted">VOTADO</div>
            </div>
          </div>
          <span class="stats">{{pilot.percent}}%</span>
        </li>
      </ul>

      <div class="result" *ngIf="state !== ExtrimState.BeforeRace">
        <div class="result-title font-venera">RESULTADO DE LA VOTACIÓN ANTERIOR</div>
        
        <div class="result-section winner">
          <div class="title font-venera">INMUNES</div>
          <ul class="pilots">
            <li *ngFor="let pilot of winners | slice:0:3">
              <div class="thumb">
                <lazy-img [source]="mediaService.generateThumbnailImgURL(pilot.thumbnail)" [size]="400"></lazy-img>
              </div>
              <h2>{{pilot.name}}</h2>
            </li>
          </ul>
        </div>
        <div class="result-section loser">
          <div class="title font-venera">LASTRADOS</div>
          <ul class="pilots">
            <li *ngFor="let pilot of losers | slice:0:3">
              <div class="thumb">
                <lazy-img [source]="mediaService.generateThumbnailImgURL(pilot.thumbnail)" [size]="400"></lazy-img>
              </div>
              <h2>{{pilot.name}}</h2>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</ion-content>
