<div class="steps">
<!--  [ngStyle]="{-->
<!--    'transition': viewInited ? 'height .3s' : 'none',-->
<!--    'height': stepsContainerHeight + 'px'-->
<!--  }">-->

  <div class="title" *ngIf="type === ProAlertType.Withdraw">
    Necesitas ser <app-pro-tag></app-pro-tag> para poder retirar tus ganancias.
  </div>

  <div class="title" *ngIf="type === ProAlertType.Storage">
    Hazte <app-pro-tag></app-pro-tag> para conseguir 100GB.
  </div>

  <div class="title" *ngIf="type === ProAlertType.OneRestricted">
    Necesitas ser <app-pro-tag></app-pro-tag> para poder crear contenido <span class="primary-color">One</span>.
  </div>

  <div class="title" *ngIf="type === ProAlertType.EventRestricted">
    Necesitas ser <app-pro-tag></app-pro-tag> para poder crear eventos de pago.
  </div>

  <div class="title" *ngIf="type === ProAlertType.PremiereRestricted">
    Necesitas ser <app-pro-tag></app-pro-tag> para poder crear estrenos de pago.
  </div>

  <div class="subtitle">
    Te convertirás en PRO automáticamente cuando consigas:
  </div>

  <!-- Info -->
  <div class="info">
    <div class="item">
      <div class="icon badge">
        <img handleError src="assets/badges/badge-3.png" alt="">
      </div>

      <div class="text">
        <div class="intro">
          20 Puntos de experiencia
        </div>

        <div class="description">
          Debes tener al menos 20 puntos de experiencia. Encontrarás tus puntos y nivel de experiencia en tu perfil.
        </div>
      </div>
    </div>

    <div class="item">
      <div class="icon user">
        <ion-icon src="assets/svg-icon/new-version/Outline_Perfil.svg"></ion-icon>
      </div>

      <div class="text">
        <div class="intro">
          3 Contactos invitados
        </div>

        <div class="description">
          Necesitas que al menos 3 contactos se hayan registrado en la plataforma mediante tu invitación.
        </div>
      </div>
    </div>
  </div>

  <!-- Buttons -->
  <div class="buttons">
<!--    *ngIf="!loading"-->
    <ion-button fill="clear" color="dark" (click)="dismiss()" >
      Cerrar
    </ion-button>
  </div>

</div>
