<div #content class="content">

  <div class="section">
    <div class="total">
      <app-star-counter 
        *ngIf="!loading"
        [count]="count" 
        [filled]="true">
      </app-star-counter>
      <ion-skeleton-text 
        *ngIf="loading"
        [animated]="true" 
        style="width: 40px; --border-radius: 5px;">
      </ion-skeleton-text>
    </div>

    <div class="list">
      <ng-container *ngIf="!loading">
        <!-- User -->
        <div class="element" *ngFor="let transaction of transactionsParsed; let i = index">
          <div class="index">{{ i + 1 }}</div>

          <div class="data">
            <lazy-img class="avatar" [size]="150" [source]="getImgProfile(transaction.user)"></lazy-img>

            <span class="user">
              <img handleError class="badge" *ngIf="transaction.user.isFounder" src="assets/badges/founder-badge.svg" alt="">
              <img handleError class="badge special collaborator" *ngIf="userIsCollaborator(transaction.user.id) && !userIsSubscribed(transaction.user.id)"
                src="assets/badges/special/collaborator-1x.png" alt="">
            <img handleError class="badge special subscriber" *ngIf="!userIsCollaborator(transaction.user.id) && userIsSubscribed(transaction.user.id)"
                src="assets/badges/special/subscriber-1x.png" alt="">
              <img handleError class="badge" [src]="getBadgeSrc(transaction.user)" alt="">{{transaction.user.username}}
            </span>
          </div>

          <div class="quantity">
            <img src="assets/star-l.png"/>
            <div class="count-content">
              <div class="count">
                {{ transaction.quantity | shortNumber }}
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- Skeleton -->
      <ng-container *ngIf="loading">
        <div class="element">
          <div class="index">
            <ion-skeleton-text [animated]="true" style="width: 8px; --border-radius: 5px;"></ion-skeleton-text>
          </div>

          <div class="data">
            <ion-thumbnail class="avatar">
              <ion-skeleton-text [animated]="true"></ion-skeleton-text>
            </ion-thumbnail>

            <span class="user">
              <ion-skeleton-text [animated]="true" style="width: 80px; --border-radius: 5px;"></ion-skeleton-text>
            </span>
          </div>

          <div class="quantity">
            <div class="count-content">
              <div class="count">
                <ion-skeleton-text [animated]="true" style="width: 40px; --border-radius: 5px;"></ion-skeleton-text>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
