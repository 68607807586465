<div class="content" [ngStyle]="{'margin-bottom': marginBottom}">
  <!-- User -->
  <div class="user">
    <div class="avatar"
      *ngIf="mode === PingMode.Display">

      <lazy-img
          [ngClass]="{'avatar-image--loaded': loadingImage}"
          class="activity-img-wrapper"
          [size]="400"
          alt="profile-image"
          [source]="getImgProfile()">
      </lazy-img>

      <ion-icon
        class="headset-display"
        src="assets/svg-icon/headset.svg">
      </ion-icon>
    </div>

    <ion-icon
      class="headset-send"
      src="assets/svg-icon/headset.svg"
      *ngIf="mode === PingMode.Send">
    </ion-icon>

    <span class="username">
      <img handleError
        class="badge special creator"
        src="assets/badges/special/creator-1x.png"
        *ngIf="viewerType === ViewerType.Creator">
      <img handleError
        class="badge special collaborator"
        src="assets/badges/special/collaborator-1x.png"
        *ngIf="viewerType === ViewerType.Collaborator">
      <img handleError
        class="badge special subscriber"
        src="assets/badges/special/subscriber-1x.png"
        *ngIf="viewerType === ViewerType.Subscriber">
      @{{user?.username}}
    </span>
  </div>

  <!-- Message -->
  <div class="message" *ngIf="mode === PingMode.Display">{{message}}</div>

  <!-- Input -->
  <div class="input" *ngIf="mode === PingMode.Send">
    <ion-textarea
      placeholder="Escribe algo..."
      rows="3"
      maxlength="200"
      autoGrow="true"
      [(ngModel)]="message"
      (keyup)="updateTotalLength()">
    </ion-textarea>

    <div class="count">
      {{totalLength}}/200
    </div>
  </div>

  <!-- Buttons -->
  <div class="buttons">
    <ion-button
      color="darkteal"
      *ngIf="!loading && mode === PingMode.Send"
      (click)="sendPing()">
      Enviar
    </ion-button>

    <div class="loading" *ngIf="loading">
      <ion-spinner name="crescent"></ion-spinner>
    </div>

    <ion-button
      fill="clear"
      color="dark"
      class="go-back"
      (click)="dismiss()">
      <span *ngIf="mode === PingMode.Send">Cancelar</span>
      <span *ngIf="mode === PingMode.Display">Cerrar</span>
    </ion-button>
  </div>
</div>
