<div class="loading container-video-expand">
  <div #videoElement ngClass="vid">
    <div ngClass="overlap"></div>
    <video 
      [playsInline]=""
      (load)="this.playing();"
      (loadeddata)="this.loaded()"
      (timeupdate)="this.playing();"
      [class]="!this.isLoaded ? 'hidden' : ''"
      [src]="this.videoSrc"
      width="100%"
      height="100%"
      [poster]='mediaService.generatePublicationThumbnailImgURL(publication?.id,publication?.thumbnail)'
      loop muted autoplay playsinline
      ></video>
  </div>
</div>