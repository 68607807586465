import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Publication } from 'src/app/interfaces/publication';
import { LiveKitClient } from 'src/app/services/livekit.service';
import { Events } from 'src/app/services/events.service';
import { Room, ConnectionState, DisconnectReason } from 'livekit-client';
import { environment } from 'src/environments/environment';
import { Collaborator } from 'src/app/interfaces/collaborator';
import { ProfileService } from 'src/app/services/profile.service';
import { UserService } from 'src/app/services/user.service';
import { AlertController, Platform } from '@ionic/angular';
import { RouterDataService } from 'src/app/services/router-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/interfaces/user';
import { LocalTrackPublication, Participant, RemoteTrackPublication, Track } from 'livekit-client';
import { Capacitor } from '@capacitor/core';
import { StatusBar } from '@capacitor/status-bar';
import { PublicationService } from 'src/app/services/publication.service';
import { inOutFade, inOutFromBottom, inOutFromBottomFade, inOutFromTop } from 'src/app/animations/animations';
import { trigger, transition, style, animate } from '@angular/animations';
import { MediaService } from 'src/app/services/media.service';
import { UserStream, UserStreamMetadata } from 'src/app/interfaces/stream-user';
import { Subscription } from 'src/app/interfaces/subscription';
import { AuthService } from 'src/app/services/auth.service';
import { ScreenOrientationService } from 'src/app/services/screen-orientation.service';
import { WebsocketIoService } from 'src/app/services/websocket-io.service';

@Component({
  selector: 'app-live-stream',
  templateUrl: environment.isWeb ? './livekit-live-stream-desktop.component.html' : './livekit-live-stream.component.html',
  styleUrls: ['./livekit-live-stream.component.scss'],
  animations: [inOutFade, inOutFromTop, inOutFromBottom, inOutFromBottomFade,
    trigger('inOutProfileHeader', [
      transition(':enter', [
        style({transform: 'translateY(120%)'}),
        animate('400ms ease', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('400ms ease', style({transform: 'translateY(120%)'}))
      ])
    ]),
    trigger('inOutSendButton', [
      transition(':enter', [
        style({transform: 'translateX(100%)', opacity: '0'}),
        animate('400ms ease', style({transform: 'translateX(0%)', opacity: '1'}))
      ]),
      transition(':leave', [
        animate('400ms ease', style({transform: 'translateX(100%)', opacity: '0'}))
      ])
    ]),
    trigger('inOutStarBottom', [
      transition(':enter', [
        style({transform: 'translateY(100%)', opacity: '0'}),
        animate('400ms ease', style({transform: 'translateY(0%)', opacity: '1'}))
      ]),
      transition(':leave', [
        animate('400ms ease', style({transform: 'translateY(100%)', opacity: '0'}))
      ])
    ]),
    trigger('inOutCountStars', [
      transition(':enter', [
        style({transform: 'translateY(30%)', opacity: '0'}),
        animate('250ms ease', style({transform: 'translateY(0%)', opacity: '1'}))
      ]),
      transition(':leave', [
        animate('250ms ease', style({transform: 'translateY(-30%)', opacity: '0'}))
      ])
    ])
  ]
})
export class LivekitLiveStreamComponent implements OnInit, OnDestroy {
  @Input() publication: Publication;
  @Input() privateRoom: boolean;
  @Input() privateDefaultCamera: boolean;
  @Input() privateDefaultMicrophone: boolean;
  @Input() token: number;
  @Input() roomId: number;
  @Input() user: User;
  @Input() giratedVideos: any;
  @Input() collaboratorsOnline: any[] = [];
  @Input() subscribers: Subscription[];
  @Input() assistants: { user: User; subscribed: boolean; following: boolean }[] = [];
  @Input() starFilled: boolean = false;
  @Input() isEvent: boolean = false;

  @Output() showProfileEvent: EventEmitter<User> = new EventEmitter();
  @Output() showStarSenderList = new EventEmitter();

  /** For check if can init emit */
  @Input() canCast = false;


  /** Use for change my state in stream */
  @Output() onStateChange = new EventEmitter<string>();
  @Output() onLostConnection = new EventEmitter();
  @ViewChild('videoElement') zoneVideo;
  @ViewChild('stream', {static: false}) videoStream: ElementRef;
  @ViewChild('containerParticipants', {static: false}) containerParticipants: ElementRef<HTMLElement>;
  preparedTrack: Track;


  /**
   * Events for Livekit
   */
  @Output() onMutedLocal? = new EventEmitter<boolean>();


  /** Globals vars */
  room: Room;
  liveKitToken: string;
  users: UserStream[];
  userCenter: UserStream;
  haveAccess: boolean;
  connected: boolean;
  remoteEmition: boolean;
  preparingEmit: boolean;
  overlayTimeout: any;
  speakerInAudioRoom = [];
  mediaServ: MediaService;


  /** For enter in publication */
  collaborators: Collaborator[] = null;
  showEmit: boolean;
  inEmition: boolean;
  inPreview: boolean;
  isLostConnection: boolean;
  overlayActivated: boolean;
  maximized: boolean;
  isGirated: boolean;
  invited: boolean;

  /** Controls Media */
  videoFacingMode: string;
  shareCamera: boolean;
  shareMic: boolean;
  isShareScreen: boolean;
  inFacingMode: boolean;
  canShareScreen: boolean;
  emitedNotCamAndNotMic: boolean;
  processEmited: boolean;
  invitationAudio: boolean;
  invitationVideo: boolean;
  videoRaised: boolean;
  audioRaised: boolean;
  processPrepared: boolean;
  processGirated: boolean;
  searchVideos: any;
  videosCaptureInterval: any;
  acceptedHands = [];
  raisedHands = [];
  numVideoDevices: number;
  numParticipants: number;
  showViewers: boolean;
  authorOnline: boolean;
  hiddeabbleInfoHidden: boolean;
  itsMe: boolean;
  listOnline: any[];
  getUserDataArrays: any[];
  closeEndEmitTimeOut: any;
  isEnded: boolean;
  showCover: boolean;
  leaved: boolean;
  isPip: boolean;
  capacitorPlatform: string;
  widthScreen: number;
  heightScreen: number;
  intervalCheckPip: any;
  processChangeCamera: boolean;
  processToogleMicrophone: boolean;
  localCamera: boolean;

  hideStream: boolean = false;
  isGestureUser: boolean = false;

  exited: boolean = false;

  sub: any = {};
  tryIntent: number = 0;



  constructor(
    private liveKit: LiveKitClient,
    private profileService: ProfileService,
    private userService: UserService,
    private routerDataService: RouterDataService,
    private router: Router,
    private alertCtrl: AlertController,
    private cdRef: ChangeDetectorRef,
    private events: Events,
    private platform: Platform,
    private mediaService: MediaService,
    private publicationService: PublicationService,
    private alertController: AlertController,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private screenOrientation: ScreenOrientationService,
    private elementRef: ElementRef,
    private websocketIoService: WebsocketIoService
  ) {
    this.mediaServ = mediaService;
    this.connected = false;
    this.localCamera = false;
    this.remoteEmition = false;
    this.preparingEmit = false;
    this.haveAccess = false;
    this.inPreview = false;
    this.isLostConnection = false;
    this.overlayActivated = false;
    this.maximized = false;
    this.isGirated = true;
    this.invited = false;
    this.invitationAudio = false;
    this.invitationVideo = false;
    this.processEmited = false;
    this.processPrepared = false;
    this.processGirated = false;
    this.processChangeCamera = false;
    this.processToogleMicrophone = false;
    this.emitedNotCamAndNotMic = false;
    this.canShareScreen = false;
    this.videoRaised = false;
    this.audioRaised = false;
    this.isShareScreen = false;
    this.inFacingMode = false;
    this.shareCamera = true;
    this.shareMic = true;
    this.showViewers = false;
    this.authorOnline = false;
    this.hiddeabbleInfoHidden = false;
    this.videoFacingMode = 'user';
    this.numVideoDevices = 1;
    this.numParticipants = 0;
    this.users = [];
    this.getUserDataArrays = [];
  }



  ngOnInit() {
    this.capacitorPlatform = Capacitor.getPlatform();
    this.connect();

    /** Setting screen size */
    this.widthScreen = this.platform.width();
    this.heightScreen = (this.widthScreen / 16) * 9;


    /** If its desktop platform */
    if(this.capacitorPlatform !== 'android' && this.capacitorPlatform !== 'ios'){
      this.canShareScreen = true;

      //Desactivar compartir pantalla si es un evento y no es video
      if(this.isEvent && !this.publication.isVideo){
        this.canShareScreen = false;
      }

    }

    /** Events app */
    this.events.subscribe(environment.EVENTS.MINIMIZE_APP, async () => {
      const element = this.elementRef.nativeElement;
      if(!element.offsetParent) { return; }

      if(this.capacitorPlatform === 'android' && (this.remoteEmition || this.inEmition) && !this.inPreview && !this.processPrepared
          && !this.processEmited && !this.publication.onlyAudio){
          this.isPip = true;
        try {
          // eslint-disable-next-line @typescript-eslint/dot-notation
          window['PictureInPicture'].enter(this.widthScreen, this.heightScreen,
            (success2) => {
              this.screenOrientation.lockLandscape();
              this.maximized = true;
              this.onStateChange.emit('on-maximized-enabled');
              console.log('success');
            },
            (error) => {
              this.isPip = false;
              console.log('error', error);
            }
          );
        } catch(e) {
          console.log('Error in PIP')
        }
      }


      if(this.capacitorPlatform === 'ios' && this.inEmition && !this.publication.onlyAudio){
        this.shareCamera = false;
        this.shareMic = false;
        this.liveKit.mediaControls.microphone.onAppMinimized((state, messages)=>{
          if(state){
            console.log('EVENTS.EVENTS=> onAppMinimized');
          }
        });
        this.liveKit.mediaControls.camera.onAppMinimized((state, messages)=>{
          if(state){
            console.log('EVENTS.EVENTS=> cameraOnAppMinimized');
          }
        });
      }

      if(this.capacitorPlatform === 'ios' && this.inPreview && !this.publication.onlyAudio){
        //this.liveKit.stopMediasPreview();
        //this.shareCamera = false;
      }

    });

    this.events.subscribe(environment.EVENTS.RESUME_APP, () => {
      const element = this.elementRef.nativeElement;
      if(!element.offsetParent) { return; }

      if(this.capacitorPlatform === 'ios' && this.inEmition && !this.publication.onlyAudio){
        this.shareCamera = false;
        this.shareMic = false;
      }

    });


    /** Detect change orientation */
    if(this.capacitorPlatform === 'android' || this.capacitorPlatform === 'ios'){
      this.sub.screenOrientation = this.screenOrientation.screenOrientationChanged.subscribe(() => {
        if ( this.platform.is('ios') ) {
          this.hideStream = true;
          this.cdRef.detectChanges();

          setTimeout(() => {
            this.hideStream = false;
            this.cdRef.detectChanges();
          }, 100);
        } else {
          this.cdRef.detectChanges();
        }
      })
    }

    if(this.capacitorPlatform === 'android'){
      this.intervalCheckPip = setInterval(()=>{
        try {
          // eslint-disable-next-line @typescript-eslint/dot-notation,
          window['PictureInPicture'].isPip((success) =>{
            if(success === true || success === 'true'){
              this.isPip = true;
            } else {
              this.isPip = false;
            }
          }, (error) =>{
            //code to execute if pip mode check fails
          });
        } catch(e) {
          console.log('Error in PIP')
        }
      }, 1000);
    }
  }


  ngOnDestroy(){
    if(this.connected){ this.disconnect(); }
    if(this.preparedTrack != null){ this.preparedTrack.stop(); }

    /** Disabled speaker mode */
    this.speakerVolumeControl(false);

    /** Unsubscribe to events */
    this.events.destroy(environment.EVENTS.MINIMIZE_APP);
    this.events.destroy(environment.EVENTS.RESUME_APP);

    /** Unsubscribe to change orientation */
    if(this.capacitorPlatform === 'android' || this.capacitorPlatform === 'ios'){
      this.sub.screenOrientation?.unsubscribe();
    }



    /** Autoscreen lock */
    this.autoScreenLock(true);

    /** Clear interval */
    if(this.capacitorPlatform === 'android'){
      if(this.intervalCheckPip != null){
        clearInterval(this.intervalCheckPip);
        this.isPip = false;
      }
    }

    this.exited = true;
  }




  ionViewDidLeave() {
    if(this.connected){ this.disconnect(); }
  }



  /**
   * Connected to Room
   */
  async connect(): Promise<boolean>{
    if(this.liveKit === null){ return false; }
    await this.disconnect();

    const getLivekitToken = this.privateRoom ? { state: true, token: this.token } : await this.liveKit.getToken(this.roomId, this.isEvent);
    if(getLivekitToken.state){
      this.haveAccess = true;
      this.liveKitToken = getLivekitToken.token;

      /** Connect to livekit */
      const connected = await this.liveKit.connect(
        this.liveKitToken,
        this.onConnected,
        this.onRemoteMedia,
        this.onRemoveMedia,
        this.onRemoveMediaLocal,
        this.onSpeaker,
        this.onDisconnect,
        this.onLocalTrack,
        this.onMute,
        this.onUnMute,
        this.onParticipantConnected,
        this.onParticipantDisconnected,
      );

      if(connected && this.privateRoom){
        this.shareCamera = this.privateDefaultCamera;
        this.shareMic = this.privateDefaultMicrophone;
        this.emite();
      }

      return connected;

    }

    return false;
  }

  /**
   * Reconnect to server
   * @returns
   */
  async reconnect(): Promise<void>{
    if(this.liveKit === null){ return; }
    if(this.tryIntent > 3){ return; }
    this.tryIntent++;
    console.log('reconnecting');
    await this.connect()
    if(!this.connected){
      //Reconnect again because the connection was lost, after 1 second
      setTimeout(() => this.reconnect(), 1000);
    }
  }



  /**
   * Disconnected to Room
   */
  async disconnect(){
    this.localCamera = false;
    if(this.preparedTrack != null){ this.preparedTrack.stop(); }
    await this.liveKit.disconnect(()=>{});
    this.connected = false;
  }



  /**
   * @deprecated The method should not be used
   */
  async enterPublication() {
    if (!this.collaborators) {
      const res = await this.profileService.getCollaborators(this.publication.author.id).toPromise();
      this.collaborators = res.data.accepted;
    }
    // eslint-disable-next-line max-len
    if (this.publication.isPremium && !(this.publication.collaborating || this.publication.subscribed || this.publication.author.id === this.user?.id)) {

      this.userService.subscribeToUser(this.publication.author);

      // const alert = await this.alertCtrl.create({
      //   cssClass: 'my-custom-alert-class',
      //   message: `Este contenido es solo para suscriptores. ¿Suscribirte por ${this.publication.author.subscriptionPrice} créditos?`,
      //   buttons: [
      //     {
      //       text: 'Aceptar',
      //       handler: () => {
      //         this.paymentService.subscribeToUser(this.publication.author).subscribe(res => {
      //           if (res.done) {
      //             this.events.publish(environment.EVENTS.ALERT_TOAST_NOTICE, 'Te has suscrito con éxito.');
      //             this.paymentService.wallet = res.data.wallet;
      //             this.router.navigate([`/publications/view/${this.publication.id}`]);
      //           } else {
      //             this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, res.msg);
      //           }
      //         });
      //       }
      //     },
      //     {
      //       text: 'Cancelar',
      //       role: 'cancel'
      //     },
      //   ]
      // });
      // await alert.present();
    } else if (this.publication.kicked) {
      const alert = await this.alertCtrl.create({
        cssClass: 'my-custom-alert-class',
        message: `Has sido expulsado de esta emisión.`,
        buttons: [
          {
            text: 'Aceptar',
            role: 'cancel'
          },
        ]
      });
      await alert.present();
    } else {
      this.routerDataService.setData(this.publication);
      this.router.navigate([`/publications/view/${this.publication.id}`]);
      this.disconnect();
    }
  }






  /** Functions */
  isUploaded() {
    return this.publication?.publicationType === 'uploaded';
  }
  getOnlineCollaboratorList(): any[] {
    return this.collaboratorsOnline.filter(collaborator => !collaborator.emitting);
  }
  showProfile(user: User) {
    this.showViewers = false;
    this.showProfileEvent.emit(user);
  }
  updateUserFollow(user: User, follow){
    const participant = this.listOnline.find(p => p.id === user.id);
    if(participant){
      participant.following = follow;
    }
  }



  /**
   * Autoplay for the stream
   * @description This method is required for autoplay witoout user gesture (https://goo.gl/xX8pDD)
   */
  autoPlay(): void{


    if (this.containerParticipants && !this.isGestureUser) {
      this.isGestureUser = true;


      const videos = this.containerParticipants.nativeElement.querySelectorAll('video');
      for (let i = 0; i < videos.length; i++) {
        if (videos[i].muted) {
          if (videos[i].classList.contains(`local`)) {
            continue;
          }
          videos[i].muted = false;
          videos[i].play();
        }
      }


      const audios = this.containerParticipants.nativeElement.querySelectorAll('audio');
      for (let i = 0; i < audios.length; i++) {
        if (audios[i].muted) {
          if (audios[i].classList.contains(`local`)) {
            continue;
          }
          audios[i].muted = false;
          audios[i].play();
        }
      }

    }


  }


  /**
   * Get Livekit token sesion
   *
   * @returns Token - in used for Livekit
   */
  getToken(): string{
    return this.liveKitToken;
  };


  /**
   * Activated overlay for show ui controls for the stream
   *
   * @returns
   */
  activateOverlay() {
    if(this.inPreview){
      this.overlayActivated = true;
      return;
    }

    this.overlayActivated = true;

    const elements = document.querySelectorAll('.overLay');
    elements.forEach(element => {
        element.classList.remove('autohide');
    });
    //this.changeDetector.detectChanges();

    clearTimeout(this.overlayTimeout);

    this.overlayTimeout = setTimeout(() => {
      this.overlayActivated = false;
    }, 4000);
  }


  /**
   * Detect if the plataform is android or ios
   *
   * @deprecated This method is not 100% reliable.
   * @returns true | false - if the plataform is android or ios
   */
  isHybrid(): boolean{
    return this.platform.is('hybrid');
  }





  /**
   * Auto screen lock
   */
  autoScreenLock(onExit: boolean = false){
    /**
     * Obligated block on exit
     */
    if(this.capacitorPlatform === 'android' || this.capacitorPlatform === 'ios'){
      if(onExit){
        this.screenOrientation.lockPortrait();
        return;
      }
    }
    if(this.maximized){ return; }
    if(this.capacitorPlatform === 'android' || this.capacitorPlatform === 'ios'){

      try {


        /**
         * lock in portrait if its privated room
         */
        if(this.privateRoom){
          this.screenOrientation.lockPortrait();
          return;
        }


        /**
         * lock in portrait if is room audio
         */
        if(this.publication.onlyAudio){
          this.screenOrientation.lockPortrait();
          return;
        }


        /**
         * block in portrait if not connected in the room or disconnected
         */
        if(this.liveKit.getRoomState() === ConnectionState.Disconnected){
          this.screenOrientation.lockPortrait();
          return;
        }


        /**
         * lock or unlock in preview
         */
        if(this.inPreview && this.preparingEmit && !this.inEmition){
            if(this.shareCamera){
              if(this.capacitorPlatform === 'ios'){ this.screenOrientation.lockLandscape(); }
              if(this.capacitorPlatform === 'android'){ this.screenOrientation.lockLandscape(); }
            } else {
              this.screenOrientation.unlock();
            }
          return;
        }

        /**
         * if local participant have video in the streaming
         */
        if(this.inEmition && this.liveKit.localSharingVideo()){
          if(this.capacitorPlatform === 'ios'){ this.screenOrientation.lockLandscape(); }
          if(this.capacitorPlatform === 'android'){ this.screenOrientation.lockLandscape(); }
          return;
        }

        /**
         * if have remote emition
         */
        if(this.remoteEmition || this.inEmition){
          this.screenOrientation.unlock();
          return;
        }

        /**
         * if not have video in the streaming and not have emition
         */
        if(!this.remoteEmition && !this.liveKit.remoteSharingVideo()){
          this.screenOrientation.lockPortrait();
          return;
        }

        /**
         * block in portrait
         */
        this.screenOrientation.lockPortrait();
      } catch (error) {
        console.log('Error on autoScreenLock', error);
      }

    }
  }



  /**
   * Autocenter streams in screen
   *
   * @returns
   */
  autoCenter(){
    if(this.publication && this.publication.onlyAudio){ return; }

    /** Auto search video and center in stream */
    if(this.userCenter && this.users.length > 0){
      const haveVideoTrack = this.userCenter.medias.find(_track => _track.kind === 'video' && !_track.track.isMuted);
      const remotePeerWithTrack = this.users.find(_user => _user.medias.find(_track => _track.kind === 'video' && !_track.track.isMuted));
      if(!haveVideoTrack && remotePeerWithTrack){
        this.centerInScreen(remotePeerWithTrack);
        return;
      }
    }

    /** Auto center */
    if(!this.userCenter && this.users.length > 0 || (this.userCenter && this.userCenter.medias.length === 0 && this.users.length > 0)){
      this.userCenter = undefined;
      this.cdRef.detectChanges();
      /** Center element */
      this.userCenter = this.users[0];
      /** Removed from collaborator list */
      this.users = this.users.filter(ufind => ufind.sid !== this.userCenter.sid);
      this.cdRef.detectChanges();
      return;
    }
  }


  /**
   * Center user in the screen
   *
   * @param user - Participant to center in screen
   * @returns
   */
  centerInScreen(user: UserStream){
    if(this.publication && this.publication.onlyAudio){ return; }

    /** Better way */
    this.users = this.users.filter(ufind => ufind.sid !== user.sid);

    if(this.userCenter){
      /** Move center stream to last position */
      if(this.userCenter.sid !== user.sid){ this.users = this.users.concat(this.userCenter); }
      /** Center element */
      this.userCenter = user;
    } else {
      /** Center element */
      this.userCenter = user;
    }

    return;
    /**
     * Change from position in stream
     */
    // Decide which is the best way.
    const userMoving = user;
    this.users = this.users.filter(ufind => ufind.sid !== userMoving.sid);
    this.users.unshift(userMoving);

    return;
    // Other way..
    const fromIndex = this.users.indexOf(user);
    if(fromIndex === 0){ return; }
    this.users.splice(0, 0, this.users.splice(fromIndex, 1)[0]);
  }

  /**
   * Maximize stream screen
   *
   * @returns
   */
  async maximize() {
    if (this.publication && this.publication.onlyAudio) {
      return;
    }
    this.maximized = !this.maximized;
    if(this.maximized){
      this.onStateChange.emit('on-maximized-enabled');
    } else {
      this.onStateChange.emit('on-maximized-disabled');
    }
    if (this.maximized) {
      if (this.platform.is('android') || this.platform.is('ios')) {
        if(this.capacitorPlatform === 'ios'){ this.screenOrientation.lockLandscape(); }
        if(this.capacitorPlatform === 'android'){ this.screenOrientation.lockLandscape(); }
        StatusBar.hide();
      }
    } else {

      if(this.inPreview && this.preparingEmit && !this.inEmition){
        this.autoScreenLock();
        return;
      }

      this.autoScreenLock();
      return;

      this.onStateChange.emit('on-maximized-disabled');
    }
  }

  /**
   * Girate camera *required event (onStateChange)
   * */
  girateCamera() {
    if(this.processGirated){ return; }
    this.processGirated = true;

    this.isGirated = !this.isGirated;
    this.onStateChange.emit('girate-camera');
    this.processGirated = false;


    if(this.inPreview){
      const video = document.querySelector('video.local') as HTMLVideoElement;
      if(video){
        if(video.classList.contains('girateCamera')){
          video.classList.remove('girateCamera');
          video.style.setProperty('transform', 'none', '!important');
        } else {
          video.classList.add('girateCamera');
          video.style.setProperty('transform', 'scaleX(-1)', '!important');
        }
      }
    }
  }

  /**
   * If user is creator room
   *
   * @param userId required user id
   * @returns true | false
   */
  userIsCreator(userId: number): boolean {
    if (this.publication)
      {
        if(Number(this.publication.author.id) === Number(userId)){
          return true;
        } else {
          return false;
        }
      }
    else
      {return false;}
  }


  /**
   * If user is collaborator for room
   *
   * @param userId required user id
   * @returns true | false
   */
  userIsCollaborator(userId: number): boolean {
    if (this.collaborators && this.collaborators.length)
      {return this.collaborators.some(c => c.user.id === Number(userId));}
    else
      {return false;}
  }


  /**
   * If user is subscribed
   *
   * @param userId required user id
   * @returns true | false
   */
  userIsSubscribed(userId: number): boolean {
    if (this.subscribers && this.subscribers.length)
      {
        return this.subscribers.some(s => s.subscriberUser.id === Number(userId));
      }
    else
      {
        return false;
      }
  }

  /**
   * If user logged is author or collaborator
   *
   * @returns true | false
   */
  isAuthorOrCollaborator(): boolean {
    return this.user.id === this.publication.author.id || this.publication.collaborating;
  }

  /**
   * Capture image from the stream
   *
   * @deprecated The method should not be used
   * @description This method is disabled
   * @returns
   */
  capturePhoto() {
    return;
    if (!this.isAuthorOrCollaborator() || this.publication.onlyAudio) {
      return;
    }
    this.videosCaptureInterval = setInterval(() => {
      if (!this.inEmition) {
        return;
      }
      this.searchVideos = document.querySelectorAll('video.istalking');
      if (this.searchVideos.length === 0) {
        this.searchVideos = document.querySelectorAll('video');
        if (this.searchVideos.length === 0) {
          return false;
        }
      }
      if (this.searchVideos[0].classList.contains('preview-video-stream')) {
        return;
      }


      const canvas = document.querySelector('canvas');

      canvas.width = this.searchVideos[0].videoWidth;
      canvas.height = this.searchVideos[0].videoHeight;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(this.searchVideos[0], 0, 0, canvas.width, canvas.height);
      const dataURL = canvas.toDataURL();


      this.saveCapture(dataURL);

      //create img
      const img = document.createElement('img');
      //console.log('dataURL', dataURL);
    }, 10000);
  }

  /**
   * Save capture for the stream in backend
   *
   * @deprecated The method should not be used
   * @description This method is disabled
   * @param dataURL as image in data format
   * @returns
   */
  saveCapture(dataURL) {
    this.publicationService.saveScreenShot(this.publication.id, dataURL).subscribe(res => {
      //console.log('res', res);
    });
    return;
  }



  /**
   * Event on success local init emition
   *
   * @param invited its boolean param
   */
  emiteSuccess(invited) {
    this.onStateChange.emit('start-emit');
    this.activateOverlay();



    /** PENDING */
    //this.websocket.startEmitting(this.roomId, invited, this.isAuthorOrCollaborator() || this.videoRaised);
    if(this.inEmition){
      const peersTemp = this.liveKit.getParticipants();
      let found = false;
      for (const [key, value] of peersTemp) {
          const peer = value;
          if(peer.tracks.size > 0 || peer.videoTracks.size > 0){
            found = true;
            break;
          }
      }
      if(!found){
        if (!this.videosCaptureInterval){
            this.capturePhoto();
        }
      }
    }
    if (!this.privateRoom && this.isAuthorOrCollaborator()) {
      this.publicationService.setUsersEmitting(this.publication.id, this.user.id, true).subscribe();
    }
  }


  /**
   * Method to redirect app
   */
  redirectToApp() {
    let url = '';

    if (this.platform.is('ios')) {
      url = 'estrim://';
    }

    if (this.platform.is('android')) {
      url = 'intent://estrim.com/#Intent;scheme=estrim;package=com.estrim.app;end';
    }

    window.location.replace(url);
  }


  /**
   * Method to show viewer modal
   */
  showViewersModal() {
    this.showViewers = true;
  }

  /**
   *  Method to hide viewer modal
   */
  viewersModalDismiss() {
    this.showViewers = false;
    this.cdRef.detectChanges();
  }

  /**
   * Method to change rear/front camera
   *
   * @returns;
   */
  activeFaceCamera() {
    const isFacingModeSupported = navigator.mediaDevices.getSupportedConstraints().facingMode;
    if (!isFacingModeSupported) {
      this.events.publish(environment.EVENTS.ALERT_TOAST_NOTICE, 'Está opción no está disponible en tu dispositivo.');
      return;
    }


    if (!this.privateRoom && !this.isAuthorOrCollaborator() && !this.videoRaised && !this.invitationVideo) {
      this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Lo sentimos no tienes permisos para habilitar la cámara.');
      return;
    }

    if(this.processChangeCamera){

      return;
    }

    this.processChangeCamera = true;
    this.inFacingMode = true;


    //NEW CODE LIVEKIT
    this.videoFacingMode = this.videoFacingMode === 'user' ? 'environment' : 'user';
    //this.videoFacingMode === 'user' ? this.videoFacingMode = 'environment' : this.videoFacingMode = 'user';

    this.liveKit.activeFaceCamera(true, this.videoFacingMode, (state, message, stream)=>{
      if(state){
        this.inFacingMode = false;
        this.processChangeCamera = false;

        /**
         * Update preview stream
         */
        if(this.preparingEmit){
          if(this.preparedTrack != null){
            try {
              this.preparedTrack.stop();
            } catch (error) { }
          }
          this.preparedTrack = stream;
          this.refreshStream();
        }

        if ( (this.videoFacingMode === 'user' && !this.isGirated) ||
             (this.videoFacingMode !== 'user' && this.isGirated) ) {
          this.girateCamera();
        }
      } else {
        this.events.publish(environment.EVENTS.ALERT_TOAST_NOTICE, message);
        this.inFacingMode = false;
        this.processChangeCamera = false;
      }
    });
  }


  /**
   * Method to change cameras
   *
   * @returns;
   */
  facingMode() {
    const isFacingModeSupported = navigator.mediaDevices.getSupportedConstraints().facingMode;
    if (!isFacingModeSupported) {
      this.events.publish(environment.EVENTS.ALERT_TOAST_NOTICE, 'Está opción no está disponible en tu dispositivo.');
      return;
    }


    if (!this.privateRoom && !this.isAuthorOrCollaborator() && !this.videoRaised && !this.invitationVideo) {
      this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Lo sentimos no tienes permisos para habilitar la cámara.');
      return;
    }

    if(this.processChangeCamera || !this.shareCamera){
      return;
    }

    this.processChangeCamera = true;

    this.inFacingMode = true;


    //NEW CODE this.LiveKit
    this.videoFacingMode = this.videoFacingMode === 'user' ? 'environment' : 'user';
    //this.videoFacingMode === 'user' ? this.videoFacingMode = 'environment' : this.videoFacingMode = 'user';

    this.liveKit.faceMode(true, this.videoFacingMode, (state, message)=>{
      if(state){
        this.inFacingMode = false;
        this.processChangeCamera = false;
        this.refreshStream();
      } else {
        this.events.publish(environment.EVENTS.ALERT_TOAST_NOTICE, message);
        this.inFacingMode = false;
        this.processChangeCamera = false;
      }
    });
  }



   /**
    * This method its in used for (track changes for elements in the iterable)
    *
    * @param peer Participant in the stream
    * @returns
    */
  trackPeer(index, peer) {
    return peer.sid;
  }


  /**
   * This method its in used for (track changes for elements in the iterable)
   *
   * @param peer Participant in the stream
   * @returns
   */
  trackTrack(index, peer) {
    return peer.sid;
  }


  /**
   * This mode is used to check if the participant has a camera in the stream
   *
   * @param peer Participant in the stream
   * @returns true | false
   */
  haveCamera(peer: Participant): boolean{
    if(peer.tracks.size === 0){
      return false;
    }

    for (const [track, publication] of peer.tracks) {
      if (typeof publication.track  === 'undefined'){ continue; }
      if(publication.kind === 'video'){
        return true;
      }
    }

    return false;
  }


  /**
   * This method is used to check if the participant has a camera paused in the stream
   *
   * @param peer Participant in the stream
   * @returns true | false
   */
  cameraIsPaused(peer: Participant): boolean{
    if(peer.tracks.size === 0){
      return false;
    }

    for (const [track, publication] of peer.tracks) {
      if (typeof publication.track  === 'undefined'){ continue; }
      if(publication.kind === 'video' && publication.track.isMuted){
        return true;
      }
    }

    return false;
  }


  /**
   * This mode is used to check if the participant has a microphone in the stream
   *
   * @param peer Participant in the stream
   * @returns true | false
   */
  haveMic(peer: Participant): boolean{
    if(peer.tracks.size === 0){
      return false;
    }

    for (const [track, publication] of peer.tracks) {
      if (typeof publication.track  === 'undefined'){ continue; }
      if(publication.kind === 'audio' && !publication.track.isMuted){
        return true;
      }
    }

    return false;
  }


  /**
   * Method for share screen in the stream or preview
   *
   * @returns
   */
  async shareScreen(){
    const capacitorPlatform = Capacitor.getPlatform();
    if(capacitorPlatform === 'android' || capacitorPlatform === 'ios'){
      this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'No está disponible en Android o iOS.');
      return false;
    }

    const inSharingScreen = await this.liveKit.inSharingScreen();
    if(inSharingScreen){
      const alert = await this.alertController.create({
        cssClass: 'my-custom-alert-class',
        header: 'Dejar de compartir',
        message: '¿Quieres dejar de compartir pantalla?',
        buttons: [
          {
            text: 'Dejar de compartir',
            handler: () => {
              this.isShareScreen = false;
              this.liveKit.sharingScreenCancel(this.inEmition, async (stateCancel, messageCancel)=>{
                if(!stateCancel){ this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, messageCancel); }
                if(this.inPreview){
                  await this.prepareStream();
                }
              });
            }
          },
          {
            text: 'Cancelar',
            role: 'cancel'
          }
        ]
      });
      await alert.present();
      return;
    }

    this.liveKit.addScreen((state, message, track)=>{
      if(state){

        if(this.preparingEmit){
          if(this.preparedTrack != null){
            this.preparedTrack.stop();
          }
          this.preparedTrack = track;
        }

        this.liveKit.wait(true, this.publication.onlyAudio, async ()=>{
          if(this.preparingEmit){
             /** Wait for front */
             const intervalCheck = setInterval(() => {
              if (this.videoStream) {
                  clearInterval(intervalCheck);
                  this.liveKit.getStream(this.videoStream.nativeElement, track, true);
                  return;
              }
            }, 100);
          }
          this.isShareScreen = true;

          track.mediaStreamTrack.onended = (e) => {
            this.isShareScreen = false;
            this.liveKit.sharingScreenCancel(this.inEmition, async (stateCancel, messageCancel)=>{
              if(!stateCancel){ this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, messageCancel); }
              if(this.inPreview){
                await this.prepareStream();
              }
            });
          };

          if(this.inEmition){
            return;
          }
        });


        return;


        this.liveKit.wait(true, this.publication.onlyAudio, ()=>{
          //this.LiveKit.iOSFix();
          this.isShareScreen = true;

          track.mediaStreamTrack.onended = (e) => {
            this.isShareScreen = false;
            this.liveKit.sharingScreenCancel(this.inEmition, async (stateCancel, messageCancel)=>{
              if(!stateCancel){ this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, messageCancel); }
              if(this.inPreview){
                await this.prepareStream();
              }
            });
          };

          if(this.inEmition){
            return;
          }

          const element = track.attach();
          this.liveKit.setAttrPrepared(element, 'screen');
          document.querySelector('.preview-video-stream-container .prepared-emit').appendChild(element);
          document.querySelector('.prepared-emit').classList.add('active');

          const cssFixFreeze = document.createElement('div');
          cssFixFreeze.innerHTML = `
          <style>
          @keyframes fixingFreezeAnim {
            from { outline: 1px solid rgba(1, 1, 1, 0); }
            to { outline: 2px solid rgba(0, 0, 0, .1); }
          }
          @-webkit-keyframes fixingFreezeAnim {
            from { outline: 1px solid rgba(1, 1, 1, 0); }
            to { outline: 2px solid rgba(0, 0, 0, .1); }
          }
          @-moz-keyframes fixingFreezeAnim {
            from { outline: 1px solid rgba(1, 1, 1, 0); }
            to { outline: 2px solid rgba(0, 0, 0, .1); }
          }
          @-o-keyframes fixingFreezeAnim {
            from { outline: 1px solid rgba(1, 1, 1, 0); }
            to { outline: 2px solid rgba(0, 0, 0, .1); }
          }

          .fixNotFreeze{
            animation-name: fixingFreezeAnim;
            animation-duration: 1s;
            animation-iteration-count: infinite;
          }
          </style>
          `;

          document.querySelector('.preview-video-stream-container .prepared-emit').appendChild(cssFixFreeze);
          //document.querySelector('.preview-video-stream-container').appendChild(element);
        });
      } else {
        this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, message);
      }
    }, this.inEmition);

  }

  /**
   * Method for toogle camera in the stream or preview
   *
   * @returns
   */
  async toggleCamera(){
    if(!this.overlayActivated){ return; }
    if (!this.privateRoom && !this.isAuthorOrCollaborator() && !this.videoRaised && !this.invitationVideo) {
      this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Lo sentimos no tienes permisos para habilitar la cámara.');
      return;
    }

    if(this.processChangeCamera){
      return;
    }



    this.processChangeCamera = true;

    if (this.inEmition && (this.privateRoom || this.publication && !this.publication.onlyAudio)) {
      const inSharingScreen = await this.liveKit.inSharingScreen();
      if(inSharingScreen){
        const alert = await this.alertController.create({
          cssClass: 'my-custom-alert-class',
          header: 'Dejar de compartir',
          message: '¿Quieres dejar de compartir pantalla?',
          buttons: [
            {
              text: 'Dejar de compartir',
              handler: () => {
                this.processChangeCamera = false;
                this.liveKit.sharingScreenCancel(this.inEmition, async (stateCancel, messageCancel)=>{
                  if(!stateCancel){ this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, messageCancel); }
                  if(this.inPreview){
                    await this.prepareStream();
                  }
                });
              }
            },
            {
              text: 'Cancelar',
              role: 'cancel'
            }
          ]
        });
        await alert.present();
        this.processChangeCamera = false;
        return;
      }


      //window['sissoa'].devices.camera.toogle();
      this.liveKit.mediaControls.camera.toggle((state, message, cameraState) => {
        if (state) {
          this.processChangeCamera = false;
          this.shareCamera = cameraState;
          this.autoScreenLock();
        } else {
          this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, message);
          this.processChangeCamera = false;
        }
      }, this.videoFacingMode);
    } else {
      this.liveKit.pausedMirror((state, message, stateMirror) => {
        if (state) {
          this.shareCamera = stateMirror;
          this.processChangeCamera = false;
          this.autoScreenLock();
          this.refreshStream();
        } else {
          this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, message);
          this.processChangeCamera = false;
        }
      });
    }
  }



   /**
    * Method for toogle microphone in the stream or preview
    *
    *
    * @returns
    */
   toggleMic() {
    if(!this.overlayActivated && (this.privateRoom || (this.publication && !this.publication.onlyAudio))){ return; }
    if(this.processToogleMicrophone){ return; }
    if (this.inEmition && (this.privateRoom || this.publication)) {
      this.processToogleMicrophone = true;
      //window['sissoa'].devices.mic.toogle();
      this.liveKit.mediaControls.microphone.toggle((state, message, micState) => {
        if (state) {
          this.shareMic = micState;
          this.processToogleMicrophone = false;
        } else {
          this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, message);
          this.processToogleMicrophone = false;
        }
      });
    } else {
      this.shareMic = !this.shareMic;
      this.processToogleMicrophone = false;
    }
  }


  /**
   * Refresh stream preview
   */
  refreshStream(){
    /** Wait for front */
    const intervalCheck = setInterval(() => {
      if (this.videoStream) {
          clearInterval(intervalCheck);
          this.liveKit.getStream(this.videoStream.nativeElement, this.preparedTrack, true);
          return;
      }
    }, 100);
  }



  /**
   * This method is used when the transmission is completely finished
   */
  endEmitFix() {
    // Si no hay nadie mas emitiendo, bloqueo la rotacion en modo retrato
    if (this.platform.is('hybrid') && (this.privateRoom || (this.publication && !this.publication.onlyAudio))) {
      this.autoScreenLock();
      StatusBar.show();
    }
    //this.publication.emitting = false;
    this.isEnded = true;
    this.preparingEmit = false;
    this.showEmit = false;
    this.localCamera = false;

    //this.emitting = false;
    //this.imEmitting = false;
    //this.messages = [];
    this.showCover = true;
    this.liveKit.logout(()=>{  });


    //clearInterval(this.VideosCaptureInterval);

    if (this.platform.is('hybrid')) {
      // Si no hay nadie emitiendo, deshabilito insomnia
      //this.insomnia.allowSleepAgain().then();
    }
  }


  /**
   * Alert for the confirmation of the closing of the transmission
   */
  async stopEmiteAlert() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-alert-class',
      header: 'Dejar de emitir',
      message: '¿Quieres dejar de emitir?',
      buttons: [
        {
          text: 'Dejar de emitir',
          handler: () => {
            this.stopEmite(true);
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel'
        }
      ]
    });

    await alert.present();
  }


  /**
   * This method is used for stop the stream local
   * */
  stopEmite(reconect: boolean = true) {
    try {
      if(this.preparedTrack != null){ this.preparedTrack.stop(); }
    } catch (error) {
      console.log('Error on stop track->', error);
    }

    this.invitationVideo = false;
    this.invitationAudio = false;
    this.localCamera = false;
    this.shareCamera = true;
    this.shareMic = true;
    this.inEmition = false;
    this.websocketIoService.announceLive(false, this.publication);

    clearInterval(this.videosCaptureInterval);
    this.videosCaptureInterval = null;
    this.maximized = false;

    // Si no hay nadie mas emitiendo, bloque la rotacion en modo retrato
    this.autoScreenLock();
    /*
    if (this.platform.is('hybrid') && !this.publication.onlyAudio) {
      if (!this.inEmition && !this.remoteEmition) {
        this.screenOrientation.lockPortrait();
      }
      StatusBar.show();
    }
    */


    this.users = this.users.filter(user => user.local !== true);
    this.onStateChange.emit('stop-emit');

    if (this.videoRaised || this.audioRaised) {

    }

    this.leaved = true;
    this.inPreview = false;
    this.inEmition = false;
    this.showEmit = false;
    this.preparingEmit = false;




    this.liveKit.stopMediaTracks((state, message) => {
    });

  }



  /**
   * Setup invitation configuration from WebSocket
   *
   * @param audio - true | false (Access to audio)
   * @param video - true | false (Access to video)
   */
  setInvitation(audio: boolean, video: boolean){
    this.invitationVideo = video;
    this.invitationAudio = audio;
  }

  /**
   * Setup hand invitation configuration from WebSocket
   *
   * @param audio - true | false (Access to audio)
   * @param video - true | false (Access to video)
   */
  setHandInvitation(audio: boolean, video: boolean) {
    this.videoRaised = video;
    this.audioRaised = audio;
  }



  /**
   * Method for init preparation for the stream (enter in preview)
   *
   * @param invited - If the user is invited or no (boolean)
   * @returns
   */
  async prepareStream(invited = false) {
    /**
     * Notification for the stream
     */
    if(this.publication && this.publication.publicationType === 'live' && this.publication.mustBeRecorded &&
       this.publication.author.isPro && this.isAuthorOrCollaborator()){
      let isAccept = false;
      const alert = await this.alertCtrl.create({
        cssClass: 'my-custom-alert-class',
        message: `La emisión se grabará, ¿Deseas continuar?`,
        buttons: [
          {
            text: 'Grabar emisión',
            handler: () => {
              isAccept = true;
            }
          },
          {
            text: 'No grabar emisión',
            role: 'cancel',
            handler: () => {
              isAccept = true;
            }
          },
        ]
      });
      await alert.present();
      await alert.onDidDismiss();
      if(!isAccept){
        /**
         * Update publication for not recording [Pending]
         */
        return;
      }
    }

    if (!this.platform.is('hybrid') && (this.platform.is('ios') || this.platform.is('android'))) {
      this.redirectToApp();
      return;
    }

    if(this.processPrepared){
      return;
    }

    this.processPrepared = true;

    //Definimos siempre desde el inicio que nos de la primera camara del usuario
    this.videoFacingMode = 'user';


    // Obtengo dispositivos de entrada
    const facingsupported = navigator.mediaDevices.getSupportedConstraints().facingMode;

    if (facingsupported) {
      this.numVideoDevices = 2;
    }

    /* if have invitation from video or audio */
    if(this.audioRaised || this.videoRaised){
      if(this.audioRaised && !this.videoRaised){
        this.emite();
        this.processPrepared = false;
        return;
      }

      if(this.preparedTrack != null){ this.preparedTrack.stop(); }

      const $this = this;
      navigator.mediaDevices.getUserMedia({audio: true}).then((mediaStream) => {
        const tracks = mediaStream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });

        let mode;
        if($this.videoRaised){
          mode = true;
        } else {
          mode = null;
        }



        $this.liveKit.preparedStream(false, { facingMode: mode },(state, message, stream)=>{
            if(state){
              $this.shareMic = true;
              $this.shareCamera = true;
              $this.preparingEmit = true;
              $this.inPreview = true;
              $this.showEmit = true;
              $this.processPrepared = false;
              $this.processEmited = false;
              this.onStateChange.emit('start-prepared');

            if ($this.platform.is('hybrid')) {
              if(this.capacitorPlatform === 'ios'){ this.screenOrientation.lockLandscape(); }
              if(this.capacitorPlatform === 'android'){ this.screenOrientation.lockLandscape(); }
              StatusBar.hide();
            }

            this.preparedTrack = stream;

            /** Wait for front */
            const intervalCheck = setInterval(() => {
              if (this.videoStream) {
                  clearInterval(intervalCheck);
                  this.liveKit.getStream(this.videoStream.nativeElement, stream, true);
                  return;
              }
            }, 100);

            this.inPreview = true;
            this.inEmition = false;
          } else {
            $this.processPrepared = false;
            $this.processEmited = false;
            $this.shareMic = false;
            $this.shareCamera = false;
            $this.preparingEmit = false;
            $this.showEmit = false;
            $this.inPreview = false;
            $this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, message);
          }
        });

      }).catch((err) => {
        console.log('Error en permisos', err);
        /* handle the error */
        $this.processPrepared = false;
        $this.shareMic = false;
        $this.shareCamera = false;
        $this.preparingEmit = false;
        $this.showEmit = false;
        $this.inPreview = false;
        $this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, err.message);
      });
      return;
    }




    if (!this.privateRoom && (this.publication && this.publication.onlyAudio || (this.isEvent && !this.publication.isVideo))) {
      if(this.preparedTrack != null){ this.preparedTrack.stop(); }
      const $this = this;
      navigator.mediaDevices.getUserMedia({audio: true}).then((mediaStream) => {
        const tracks = mediaStream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });

        $this.processPrepared = false;
        $this.processEmited = false;

        if($this.audioRaised){

        }

        $this.emite(false, invited);
      }).catch((err) => {
        console.log('Error en permisos', err);
        /* handle the error */
        $this.processPrepared = false;
        $this.shareMic = false;
        $this.shareCamera = false;
        $this.preparingEmit = false;
        $this.showEmit = false;
        $this.inPreview = false;
        $this.processEmited = false;
        $this.events.publish(
          environment.EVENTS.ALERT_TOAST_ERROR, 'Debes aceptar los permisos del micrófono para poder continuar');
      });
    } else {
      if(this.preparedTrack != null){ this.preparedTrack.stop(); }

      const $this = this;
      navigator.mediaDevices.getUserMedia({audio: true}).then((mediaStream) => {
        const tracks = mediaStream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });

        $this.liveKit.preparedStream(false,{ facingMode: $this.videoFacingMode }, async(state, message, track)=>{
          if ( this.exited ) {
            this.disconnect();
          } else if(state){
            $this.shareMic = true;
            $this.shareCamera = true;
            $this.preparingEmit = true;
            $this.inPreview = true;
            $this.showEmit = true;
            $this.processPrepared = false;
            $this.processEmited = false;
            this.onStateChange.emit('start-prepared');
            this.activateOverlay();

            const capacitorPlatform = Capacitor.getPlatform();
            if(capacitorPlatform === 'android' || capacitorPlatform === 'ios'){
              if(this.capacitorPlatform === 'ios'){ this.screenOrientation.lockLandscape(); }
              if(this.capacitorPlatform === 'android'){ this.screenOrientation.lockLandscape(); }
              StatusBar.hide();
            }

            if(this.preparedTrack != null){ this.preparedTrack.stop(); }
            this.preparedTrack = track;

            /** Wait for front */
            const intervalCheck = setInterval(() => {
              if (this.videoStream) {
                  clearInterval(intervalCheck);
                  this.liveKit.getStream(this.videoStream.nativeElement, track, true);
                  return;
              }
            }, 100);

            if ( this.videoFacingMode === 'user' && !this.isGirated ) {
              this.girateCamera();
            }

            this.inPreview = true;
            this.inEmition = false;
          } else {
            $this.processPrepared = false;
            $this.processEmited = false;
            $this.shareMic = false;
            $this.shareCamera = false;
            $this.preparingEmit = false;
            $this.showEmit = false;
            $this.inPreview = false;
            $this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Debes aceptar los permisos de la cámara para poder continuar');
          }
        });

      }).catch((err) => {
        console.log('Error en permisos', err);
        /* handle the error */
        $this.processPrepared = false;
        $this.shareMic = false;
        $this.shareCamera = false;
        $this.preparingEmit = false;
        $this.showEmit = false;
        $this.inPreview = false;
        $this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Debes aceptar los permisos del micrófono para poder continuar');
      });


    }

    if (this.platform.is('hybrid')) {
      // Habilito insomnia
      //this.insomnia.keepAwake().then();
    }
  }




  /**
   * Method for init the stream in the room
   *
   * @param camera - If the user init emition with camera (boolean)
   * @param invited - If the user is invited or no (boolean)
   * @returns
   */
  async emite(camera = true, invited = false) {
    this.invited = invited;
    if(!this.overlayActivated && camera && this.inEmition){ return; }


    if(this.inEmition){
      this.stopEmiteAlert();
      return;
    }

    if(this.processEmited){ return; }
    this.processEmited = true;

    const facingsupported = navigator.mediaDevices.getSupportedConstraints().facingMode;

    if (facingsupported) {
      this.numVideoDevices = 2;
    }


    if (!this.privateRoom && (this.publication && this.publication.onlyAudio || (this.invitationAudio && !this.invitationVideo) || (this.isEvent && !this.publication.isVideo))) {
      this.liveKit.createTrack({ camera: false, audio: true, onlyAudio: this.publication.onlyAudio }, (state, message)=>{
          if(state){
            console.log('Success!');
            this.inPreview = false;
            this.inEmition = true;
            this.preparingEmit = false;
            this.processEmited = false;
            this.emitedNotCamAndNotMic = false;
            this.onStateChange.emit('start-emit');
            this.websocketIoService.announceLive(true, this.publication);

            if(this.preparedTrack != null){ this.preparedTrack.stop(); }
          } else {
            this.processEmited = false;
            this.emitedNotCamAndNotMic = false;
            this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, message);
          }
      });
      return;
    }


    let isCamera = this.shareCamera;
    if (!this.privateRoom && (!this.isAuthorOrCollaborator() && !this.videoRaised && !this.invitationVideo)) {
      isCamera = false;
      this.shareCamera = false;
    }

    if(!this.shareCamera && !this.shareMic){
        //const video = document.querySelector('#video');
        //if(video){ video.remove(); }
        this.inPreview = false;
        this.inEmition = true;
        this.preparingEmit = false;
        this.processEmited = false;
        this.emitedNotCamAndNotMic = true;
        this.onStateChange.emit('start-emit');
        this.websocketIoService.announceLive(true, this.publication);

        document.querySelector('.prepared-emit').classList.remove('active');

        const sTemp = document.querySelector('#startEmittingBtn');
        if(sTemp != null){
          sTemp.innerHTML = 'Dejar emisión';
          sTemp.classList.add('removeBorders');
        }

        if(this.preparedTrack != null){ this.preparedTrack.stop(); }
        return;
    }


    this.liveKit.createTrack({
      camera: isCamera,
      audio: this.shareMic,
      mode: this.videoFacingMode,
      onlyAudio: this.privateRoom ? false : this.publication.onlyAudio
    }, (state, message)=>{
      if(state){
        this.inPreview = false;
        this.inEmition = true;
        this.preparingEmit = false;
        this.processEmited = false;
        this.emitedNotCamAndNotMic = false;
        this.websocketIoService.announceLive(true, this.publication);

        if(!this.privateRoom){
          document.querySelector('.prepared-emit').classList.remove('active');
        }

        const sTemp = document.querySelector('#startEmittingBtn');
        if(sTemp != null){
          sTemp.innerHTML = 'Dejar emisión';
          sTemp.classList.add('removeBorders');
        }

        this.emiteSuccess(invited);

        if(!this.isShareScreen && this.preparedTrack != null){
          this.preparedTrack.stop();
        }
      } else {
        this.processEmited = false;
        this.emitedNotCamAndNotMic = false;
        this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, message);
      }
    });
  }






  /**
   * Event fired when connected to room
   *
   * @event onConnected
   * @param state - Status for the connection
   */
  private onConnected = (state) => {
    console.log('onConnected');
    this.onStateChange.emit('connected');

    this.speakerVolumeControl(true);

    //this.users = [];
    this.connected = true;

    this.isLostConnection = false;
    this.tryIntent = 0;

    this.updatePeers();
  };



  /**
   * Event fired when is disconnected from room
   *
   */
  private onDisconnect = (e: DisconnectReason) => {
    console.log('onDisconnect', e);

    if(this.inEmition || this.preparingEmit || this.inPreview){
      this.stopEmite(true);
    }

    switch (e) {
      case DisconnectReason.DUPLICATE_IDENTITY:
        this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ya estás conectado en otra sesión');
        break;
      case DisconnectReason.PARTICIPANT_REMOVED:
        this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Has sido expulsado de la sala');
        break;
      case DisconnectReason.ROOM_DELETED:
        this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'La sala ha sido cerrada');
        setTimeout(async() => {
          await this.connect();
        }, 100);
        break;
      case DisconnectReason.UNKNOWN_REASON:
        this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Has sido desconectado de la sala');
        break;
    }

    //If is lost last connection
    if(!e){
      this.isLostConnection = true;
      this.onLostConnection.emit();
      this.reconnect();
    }

    this.autoScreenLock();
    this.onStateChange.emit('disconnected');
  };




  /**
   * Refresh users emitting on the room
   */
  private updatePeers() {
    this.autoCenter();
    const numPeers = this.liveKit.getNumParticipants();
    this.numParticipants = numPeers;

    const peersTemp = this.liveKit.getParticipants();

    const arr = [];
    const lPeer = this.liveKit.getLocalPeer();
    const lobj = JSON.parse(lPeer.metadata);
    arr.push(lobj);


    //TODO: Change forEach to for


    // eslint-disable-next-line guard-for-in
    peersTemp.forEach((peer, key) => {
      if(!peer.metadata){
        //Only for livekit ingress
        this.liveKit.getMetaData(peer.name, this.roomId.toString()).then((metadata) => {
          if (metadata && !metadata.isPreview) {
            arr.push(metadata);
            this.processUpdatePeers();
          }
        }, (error) => {
          console.log('Error on get meta data', error);
        });
        return;
      }
      const metadata = JSON.parse(peer.metadata);
      if(peer.metadata && metadata.isPreview){
        return;
      }
      arr.push(metadata);
    });
    this.listOnline = arr;


    this.processUpdatePeers();
  }

  processUpdatePeers(): void{
    const peersTemp = this.liveKit.getParticipants();
 // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let index = 0; index < this.listOnline.length; index++) {
      const user = this.listOnline[index];
      if(this.user.id === user.id){ continue; }
      if(!this.getUserDataArrays[user.id]){
        try {
          this.peerGetUserdata(user);
        } catch (error) {
        }
      } else {
        const userStream = this.users.find(_user => _user.info.id === user.id);
        const indx = this.listOnline.findIndex(_user => _user.id === user.id);
        if(indx){
          const options = {
            isAuthor: this.listOnline[indx].isAuthor,
            isCollaborator: this.listOnline[indx].isCollaborator,
            isSubscribed: this.listOnline[indx].isSubscribed,
          };
          this.listOnline[indx] = this.getUserDataArrays[user.id];
          this.listOnline[indx].isAuthor = options.isAuthor;
          this.listOnline[indx].isCollaborator = options.isCollaborator;
          this.listOnline[indx].isSubscribed = options.isSubscribed;
        }
        if(userStream){
          userStream.user = this.getUserDataArrays[user.id];
          this.cdRef.detectChanges();
        } else {
          if(this.userCenter && this.userCenter.info.id === user.id){
            this.userCenter.user = this.getUserDataArrays[user.id];
            this.cdRef.detectChanges();
          }
        }
      }
    }


    if(this.inEmition){
      let found = false;
      for (const [key, value] of peersTemp) {
          const peer = value;
          if(peer.tracks.size > 0 || peer.videoTracks.size > 0){
            found = true;
            break;
          }
      }
      if(!found){
        if (!this.videosCaptureInterval){
            this.capturePhoto();
        }
      }
    }


    if(this.liveKit.debug){ console.log('usersOnline', this.listOnline); }
    this.cdRef.detectChanges();
  }



  /**
   * Get data from user id
   *
   * @param user
   */
  private peerGetUserdata(user: any){
    const userStream = this.users.find(_user => _user.info.id === user.id);
    this.getUserDataArrays[user.id] = user;

    if(userStream){
      userStream.user = user;
      this.cdRef.detectChanges();
    } else {
      if(this.userCenter && this.userCenter.info.id === user.id){
        this.userCenter.user = this.getUserDataArrays[user.id];
        this.cdRef.detectChanges();
      }
    }

    return;
    this.authService.getUserDataById(user.id).subscribe(res => {
      if(!res.msg){ return; }
      this.getUserDataArrays[user.id] = res.data;
      const userStream = this.users.find(_user => _user.info.id === user.id);
      const index = this.listOnline.findIndex(_user => _user.id === user.id);
      if( index !== -1 ){
        const options = {
          isAuthor: this.listOnline[index].isAuthor,
          isCollaborator: this.listOnline[index].isCollaborator,
          isSubscribed: this.listOnline[index].isSubscribed,
        };

        this.listOnline[index] = res.data;
        this.listOnline[index].isAuthor = options.isAuthor;
        this.listOnline[index].isCollaborator = options.isCollaborator;
        this.listOnline[index].isSubscribed = options.isSubscribed;
      }
      if(userStream){
        userStream.user = res.data;
        this.cdRef.detectChanges();
      } else {
        if(this.userCenter && this.userCenter.info.id === user.id){
          this.userCenter.user = this.getUserDataArrays[user.id];
          this.cdRef.detectChanges();
        }
      }
    });
  }




  /**
   * Method for structure the media stream data
   *
   * @param track - Track from stream
   * @param local - Is local user (boolean)
   * @returns StreamMedia
   */
  private getMedia(track: Track, local: boolean): { sid: Track['sid']; kind: Track['kind']; track: Track}{

    const media = {
      sid: track.sid,
      kind: track.kind,
      track
    };

    return media;
  }



  /**
   * Method for find Participant in the stream
   *
   * @param participant - Participant to search or added
   * @returns Participant or undefined
   */
  private getPeer(participant: any): UserStream | undefined{
    /** Search video in center screen */
    if(this.userCenter && this.userCenter.sid === participant.sid){
      return this.userCenter;
    }
    /** Search video in collaborator list */
    const peer = this.users.find(user => user.sid === participant.sid);
    if(peer){
      return peer;
    }
    return undefined;
  }




  /**
   * Method for added or return Participant in the stream
   *
   * @param participant - Participant to search or added
   * @param local  - Is local participant (boolean)
   * @returns UserStream - Participant structure completed
   */
  private async createOrGetPeer(participant: any, local: boolean): Promise<UserStream>{
    /** Search participant */
    const peer = this.getPeer(participant);
    if(peer){
      return peer;
    }

    let data = null;

    if(participant.metadata){
      data = JSON.parse(participant.metadata);
    }


    const user: UserStream = {
      local,
      info: {
        id: data ? data.id : 0,
        username: data ? data.username : participant.name,
        imgProfile: data ? this.mediaServ.generateImgProfileURL(data.id, data.imgProfile) : '',
        isCreator: data ? this.userIsCreator(data.id) : false,
        isCollaborator: data ? this.userIsCollaborator(data.id): false,
        isSubscribed: data ? this.userIsSubscribed(data.id) : false,
        isAuthor: data ? data.isAuthor : false,
        reputation: data ? data.reputation : 0,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        reputation_level: data ? data.reputation_level : 0
      },
      sid: participant.sid,
      participant,
      tracks: [],
      medias: []
    };

    this.users.push(user);

    //Get data from user if not exist
    if(!data){

      let username;
      const identitySplit = participant.identity.split('_');
      if (identitySplit.length > 0) { username = identitySplit[0]; }
      if (username) {
        this.liveKit.getMetaData(username, this.roomId.toString()).then((data: UserStreamMetadata) => {
          const user = this.users.find(user => user.sid === participant.sid);
          if (data && user) {
            user.info.id = data.id;
            user.info.username = data.username;
            user.info.imgProfile = this.mediaServ.generateImgProfileURL(data.id, data.imgProfile);
            user.info.isCreator = this.userIsCreator(data.id);
            user.info.isCollaborator = this.userIsCollaborator(data.id);
            user.info.isSubscribed = this.userIsSubscribed(data.id);
            user.info.isAuthor = data.isAuthor;
            user.info.reputation = data.reputation;
            user.info.reputation_level = data.reputation_level;
          }
        }).catch((err) => {
          console.log('Error on get metadata', err);
        });
      } else {
        console.log("Error username not found, identity->", participant.identity);
      }

    }

    return this.users[this.users.length-1];
  }







/**
 * Event fired when the track local is publicated
 *
 * @param track - Track local
 * @param participant - Participant local
 * @returns
 */
  private onLocalTrack = async (track: LocalTrackPublication, participant: Participant) => {
    if(this.liveKit.debug){ console.log('onLocalTrack', track, participant); }

    const peer = await this.createOrGetPeer(participant, true);

    if(track.source === 'camera'){
      /*
      if(this.inEmition){
        if (this.platform.is('android') || this.platform.is('ios')) {
          try {
            this.screenOrientation.lockLandscape();
          } catch (error) {
            console.log('Error on lock', error);
          }
        }
      }*/
      this.localCamera = true;
      this.autoScreenLock();
    }

    if(peer){
      /**
       * Added new track from Participant
       */
      peer.tracks.push(track.track);
      const media = this.getMedia(track.track, true);
      peer.medias.push(media);
      this.autoCenter();
      return;
    }

  };




/**
 * Event fired when a new remote track is received
 *
 * @param track - Track remote
 * @param publication - Publication remote (not in used)
 * @param participant - Participant remote
 * @returns
 */
  private onRemoteMedia = async (track: Track, publication: any, participant: Participant) => {
    if(this.liveKit.debug){ console.log('onRemoteMedia', track, participant); }

    this.onStateChange.emit('remote-emit');
    this.activateOverlay();


    const peer = await this.createOrGetPeer(participant, false);
    if(peer){
      /** Addtrack */
      peer.tracks.push(track);
      const media = this.getMedia(track, false);
      peer.medias.push(media);

      /** */
      this.remoteEmition = true;
      this.updatePeers();
      this.autoCenter();
      this.autoScreenLock();
      return;
    }
  };




  /**
   * Method is when a track (local or remote) is removed from the stream
   *
   * @param sid - Track sid (string identificator)
   * @param participant - Participant (local or remote)
   */
  private removeStream = (sid: string, participant: Participant) => {
      if(this.liveKit.debug){ console.log('removeStream'); }
      /** Update peers */
      this.updatePeers();

      /** Remove stream */
      const remotePeer = this.getPeer(participant);
      if(remotePeer){
        remotePeer.medias = remotePeer.medias.filter(media => media.sid !== sid);
        remotePeer.tracks = remotePeer.tracks.filter(_track => _track.sid !== sid);
        if(remotePeer.tracks.length === 0){
          this.users = this.users.filter(user => user.sid !== participant.sid);
          if(this.userCenter && this.userCenter.sid === participant.sid){
            this.userCenter = undefined;
          }
          this.autoCenter();
        }
      }

      /** Remove user from list streams */
      setTimeout(()=>{
        const peer = this.getPeer(participant);
        this.autoCenter();
        if(peer){
          if(peer.tracks.length === 0){
            this.users = this.users.filter(user => user.sid !== participant.sid);
            this.inEmition = false;
          }
        }
        if(!this.inEmition){
          const peersStreams = this.users.find(user => user.tracks.length > 0);
          if(this.userCenter && this.userCenter.tracks.length > 0){ return; }
          if(!peersStreams){
            this.users = [];
            this.userCenter = undefined;
            this.remoteEmition = false;
            this.inEmition = false;
            this.endEmitFix();
            this.autoScreenLock();
            this.onStateChange.emit('closed-emit');
          }
        }
      }, 700);
  };


  /**
   * Event fired when a track local is removed from the stream
   *
   * @param track - Track removed
   * @param participant - Participant (local)
   */
  private onRemoveMediaLocal = (track: LocalTrackPublication, participant: Participant) => {
    if(track.source === 'camera'){
      if(this.inEmition){
        if (this.platform.is('android') || this.platform.is('ios')) {
          try {
              this.autoScreenLock();
          } catch (error) {
            console.log('Error on unlock', error);
          }
        }
      }
      this.localCamera = false;
    }
    this.removeStream(track.trackSid, participant);
  };


  /**
   * Event fired when a track remote is removed from the stream
   *
   * @param track - Track removed
   * @param publication - Publication remote (not in used)
   * @param participant - Participant (remote)
   */
  private onRemoveMedia = (track: Track, publication: any, participant: Participant) => {
    this.removeStream(track.sid, participant);
  };




/**
 * Event fired when on a participant is talking or left talk
 *
 * @deprecated This method not in used
 * @param participantInfo - Information from participant
 */
  private onSpeaker = (participantInfo: any) => {
  };


  /**
   * Event fired when on a participant mute a track (video or audio)
   *
   * @param track - Track muted
   * @param participant - Participant (local or remote)
   */
  private onMute = (track: Track, participant: Participant) => {
    console.log('onMute', track, participant);

    //if the user local is muted the mic and is the same user, emit event
    if(participant.identity === this.user.username && track.kind === Track.Kind.Audio){
      this.shareMic = false;
      this.onMutedLocal?.emit(true);
    }

    this.autoScreenLock();
    this.autoCenter();
  };

  /**
   * Event fired when on a participant unmute a track (video or audio)
   *
   * @param track - Track muted
   * @param participant - Participant (local or remote)
   */
  private onUnMute = (track: Track, participant: Participant) => {
    console.log('onUnMute', track, participant);
    this.autoScreenLock();
    this.autoCenter();
  };

  /**
   * Event fired when a new participant is connected to room
   *
   * @param participant - Participant connected in the room (remote)
   */
  private onParticipantConnected = (participant: Participant) => {
    console.log('onParticipantConnected', participant);
    this.updatePeers();
  };

  /**
   * Event fired when a participant is disconnected to room
   *
   * @param participant - Participant disconnected in the room (remote)
   */
  private onParticipantDisconnected = (participant: Participant) => {
    console.log('onParticipantDisconnected', participant);
    this.users = this.users.filter(user => user.sid !== participant.sid);
    this.updatePeers();
    this.onStateChange.emit('participant-disconnected');
  };

  /**
   * Method for enabled/disabled device speaker.
   *
   * @param enabled - New state for device speaker
   */
  private speakerVolumeControl = (enabled: boolean) => {
    const capacitorPlatform = Capacitor.getPlatform();
    if(capacitorPlatform === 'android' || capacitorPlatform === 'ios'){
      this.liveKit.setAudioSpeaker((state, message)=>{
        if(state){
          console.log('setAudioSpeaker', message);
        } else {
          console.log('setAudioSpeaker - Error', message);
        }
      }, enabled);
    }
  };


  get canShareVideo(): boolean {
    if (!this.publication) {
      return false;
    }
    if (this.publication.onlyAudio) {
      return false;
    }
    if (this.isEvent && !this.publication.isVideo) {
      return false;
    }
    return true;
  }





}
