import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-valoration-star',
  templateUrl: './valoration-star.component.html',
  styleUrls: ['./valoration-star.component.scss'],
})
export class ValorationStarComponent implements OnInit, AfterViewInit {
  @ViewChild('starTemplate') starTemplate: TemplateRef<any>;
  @Input() fill = 0;

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }


}
