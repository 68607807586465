import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Wallet } from '../interfaces/wallet';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Subscription } from '../interfaces/subscription';
import { Transaction } from '../interfaces/transaction';
import { AuthService } from './auth.service';
import { JsonApiResponse } from '../interfaces/JsonApiResponse';
import { environment } from '../../environments/environment';
import { User } from '../interfaces/user';
import { Events } from './events.service';
import {catchError, map} from "rxjs/operators";
import { CacheService } from './cache.service';

import { CacheData } from '../enums/cache-data';
import { JsonApiResponsePaginated } from '../interfaces/JsonApiResponsePaginated';

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends CacheService {
  getWalletURl = 'api/auth/user/get_wallet';
  addTokensToWalletURL = 'api/auth/user/add_tokens_to_wallet';
  acceptSubscriptionURL = 'api/auth/aforum/accept_subscription';
  cancelSubscriptionURL = 'api/auth/aforum/cancel_subscription';
  getSubscriptionsURL = 'api/auth/aforum/subscriptions';
  activateSubscriptionsUrl = 'api/auth/aforum/activate-subscriptions';
  saveSubscriptionPriceUrl = 'api/auth/aforum/save-subscription-price';
  getDonationsURL = 'api/auth/aforum/get-my-donations';
  makeDonationUrl = 'api/auth/aforum/make_donation';
  sendStarsUrl = 'api/auth/aforum/send_stars';
  buyOneContentUrl = 'api/auth/aforum/buy_one_content';
  getTransactionsUrl = 'api/auth/user/transactions/{offset}/{limit}';
  payEventUrl = 'api/auth/publication/pay_event';
  cancelEventAttendUrl = 'api/auth/publication/cancel_event_attend';
  payInvitationEventURL = 'api/publication/pay_invitation_event';
  checkAlreadyPurchasedUrl = 'api/auth/publication/check_if_already_purchased';
  PAYMENT_INTENT_URL = 'api/stripe/create-payment-intent/{quantity}';
  connectStripeAccountUrl = 'api/auth/stripe/connect-stripe-account';
  withdrawUrl = 'api/auth/user/withdraw';
  buyTokensInAppUrl = 'api/auth/user/buy_tokens_in_app';

  wallet: Wallet;
  isWalletCacheValid = false;

  PAYMENT_CACHE_SUBSCRIBERS_KEY = 'PAYMENT_CACHE_SUBSCRIBERS';
  WALLET_CACHE_KEY = 'WALLET_CACHE';

  private _subscribers: BehaviorSubject<Subscription[]> = new BehaviorSubject<Subscription[]>([]);
  subscribers = this._subscribers.asObservable();

  private _wallet: BehaviorSubject<Wallet> = new BehaviorSubject<Wallet>(undefined);
  walletObservable = this._wallet.asObservable();

  private _walletStatus: BehaviorSubject<'ready' | 'fetching'> = new BehaviorSubject<'ready' | 'fetching'>('ready');
  walletStatus = this._walletStatus.asObservable();

  private _sendStarsStatus: BehaviorSubject<'ready' | 'sending'> = new BehaviorSubject<'ready' | 'sending'>('ready');
  sendStarsStatus = this._sendStarsStatus.asObservable();

  constructor(protected http: HttpClient, private auth: AuthService, private events: Events) {
    super(http);

    this.events.subscribe(environment.EVENTS.USER_LOGOUT, () => {
      this.wallet = undefined;
      this.isWalletCacheValid = false;
      this._subscribers.next([]);
    });

    this.loadCacheData(CacheData.MySubscribers);
    this.loadCacheData(CacheData.MyWallet);
  }

  protected fetchCacheData(type: CacheData) {
    switch ( type ) {
      case CacheData.MySubscribers: 
        this.loadSubscriptions(this.auth.user.id).subscribe();
      case CacheData.MyWallet:
        this.loadWallet().subscribe();
      break;
    }
  }
  protected getCacheDataObservable(type: CacheData): BehaviorSubject<any> {
    switch ( type ) {
      case CacheData.MySubscribers: return this._subscribers; break;
      case CacheData.MyWallet: return this._wallet; break;
    }
  }
  protected getCacheDataObservableKey(type: CacheData): string {
    switch ( type ) {
      case CacheData.MySubscribers: return this.PAYMENT_CACHE_SUBSCRIBERS_KEY; break;
      case CacheData.MyWallet: return this.WALLET_CACHE_KEY; break;
    }
  }

  getWallet(force?: boolean): Observable<Wallet> {
    return this.watchCacheData(CacheData.MyWallet);
  }

  loadWallet() {
    this._walletStatus.next('fetching');

    return this.http.get<Wallet>(this.generateAbsoluteAPIURL(this.getWalletURl))
    .pipe(
      map((wallet) => {
        this.setCacheData(CacheData.MyWallet, wallet);
        this._walletStatus.next('ready');
        return wallet;
      }),
      catchError((err) => {
        this._walletStatus.next('ready');
        return of(err);
      })
    );
  }

  addTokensToWallet(data): Observable<any> {
    return this.http.post(this.generateAbsoluteAPIURL(this.addTokensToWalletURL), data);
  }

  subscribeToUser(user: User) {
    this.loadWallet().subscribe((wallet) => {
      if (wallet.balance < user.subscriptionPrice) {
        this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR,
          'No hay suficiente saldo en tu cartera. Accede a las opciones de tu perfil para añadir más.');
      } else {
        return this.acceptSubscription(user.username);
      }
    });
  }

  acceptSubscription(username: string) {
    const observable = this.http.post<JsonApiResponse<{ wallet: Wallet; subscription: Subscription }>>(
      this.generateAbsoluteAPIURL(this.acceptSubscriptionURL), {
        username
      });

    observable.subscribe((res) => {
      if (res.done) {
        this.wallet = res.data.wallet;
        this.isWalletCacheValid = true;
      }
    });

    return observable;
  }

  loadSubscriptions(userId = null) {
    return this.http.post<JsonApiResponse<{ mine: Subscription[]; toMe: Subscription[] }>>(
      this.generateAbsoluteAPIURL(this.getSubscriptionsURL), {
        userId
      }
    ).pipe(map((res) => {
      if (res.done) {
        // If its my user, store the data
        if ( userId !== null && !this.auth.user ) {
          console.warn('Local user not ready');
        } else if ( userId === null || userId === this.auth.user.id ){
          this.setCacheData(CacheData.MySubscribers, res.data.toMe)
        }
      }

      return res;
    }));
  }

  cancelSubscription(id: number) {
    return this.http.post(this.generateAbsoluteAPIURL(this.cancelSubscriptionURL),
      {
        id
      });
  }

  public generatePaymentIntent(quantity: number) {
    return this.http.get<any>(this.generateAbsoluteAPIURL(this.PAYMENT_INTENT_URL
      .replace('{quantity}', quantity.toString())));
  }

  donate(otherUserId, quantity) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.makeDonationUrl), {
      otherUserId,
      quantity
    });
  }

  loadDonations() {
    return this.http.get<Transaction[]>(this.generateAbsoluteAPIURL(this.getDonationsURL));
  }

  payEvent(eventId: number) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.payEventUrl), {
      eventId
    });
  }

  payInvitationEvent(data: { eventId: any; phone: any; surname: any; price: number; cardId: any; paymentIntent: string; name: any; userId: any }) {
    return this.http.post(this.generateAbsoluteAPIURL(this.payInvitationEventURL), data);
  }

  connectStripeAccount() {
    return this.http.get<any>(this.generateAbsoluteAPIURL(this.connectStripeAccountUrl));
  }

  activateSubscriptions(checked) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.activateSubscriptionsUrl), {
      checked
    });
  }

  saveSubscriptionPrice(price) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.saveSubscriptionPriceUrl),
      {
        price
      });
  }

  sendStars(quantity, receiverId, origin, idOrigin) {
    this._sendStarsStatus.next('sending');

    return this.http.post<any>(this.generateAbsoluteAPIURL(this.sendStarsUrl),
      {
        quantity,
        receiverId,
        origin,
        idOrigin
      })
      .pipe(
        map((res) => {
          if (res.done) {
            this.isWalletCacheValid = false;
            this.loadWallet().subscribe();
          }

          this._sendStarsStatus.next('ready');
          return res;
        }),
        catchError((err) => {
          this._sendStarsStatus.next('ready');
          return of(err);
        })
      );
  }

  buyOneContent(publicationId) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.buyOneContentUrl),
      {
        publicationId
      }).pipe(map((res) => {
      if (res.done) {
        this.isWalletCacheValid = false;
        this.loadWallet().subscribe();
      }

      return res;
    }));
  }

  checkIfPurchased(contentId, type = 'one') {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.checkAlreadyPurchasedUrl),
      {
        contentId,
        type
      });
  }

  getTransactions(offset = 0, limit = 40, profit: boolean = null, filters: string = '', dateStart = null, dateEnd = null) {
    return this.http.post<JsonApiResponsePaginated<Transaction[]>>(
      this.generateAbsoluteAPIURL(this.getTransactionsUrl)
        .replace('{offset}', offset.toString())
        .replace('{limit}', limit.toString()),
      {
        profit,
        filters,
        dateStart,
        dateEnd
      });
  }

  withdraw(amount: number, paypalAccount: string, billing: any) {
    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.withdrawUrl),
      {
        amount,
        paypalAccount,
        billing
      });
  }

  cancelEventAttend(eventId: number) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.cancelEventAttendUrl), {
      eventId
    });
  }

  buyTokensInApp(quantity, price, currency, transactionId, transactionReceipt, transactionType) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.buyTokensInAppUrl), {
      quantity, 
      price, 
      currency, 
      transactionId, 
      transactionReceipt, 
      transactionType
    });
  }
}
