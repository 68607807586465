<ion-header class="general-header-app">
  <app-header
    [title]="type === ContentType.LiveVideo ? 'Configuración del canal' : 'Configuración de la sala'"
    [menuButton]="false"
    [defaultBackAction]="false"
    (back)="goBack()">
  </app-header>
</ion-header>

<ion-content #content>

  <!--
    -- Content
    -->

  <ng-container *ngIf="mode === FormMode.Edit">
    <!-- Chat welcome message -->
    <div class="field less-margin description input">
      <div class="content">
        <ion-textarea
          required
          rows="3"
          maxlength="200"
          placeholder="¡Te damos la bienvenida al canal! Interactúa con el resto de usuarios respetando las normas de la comunidad"
          [disabled]="uploading"
          [autoGrow]="true"
          [(ngModel)]="welcome"
          (ionFocus)="inputFocused($event)"
          (ionChange)="error.welcome = false">
        </ion-textarea>

        <ion-text class="field-error" *ngIf="error.welcome" color="danger">
          <small>
            <ion-icon name="alert-circle-outline"></ion-icon>
            <span>Debes introducir una descripción</span>
          </small>
        </ion-text>
      </div>
    </div>

    <!-- Notification -->
    <div class="field input">
      <div class="content">
        <ion-input
          required
          maxlength="250"
          placeholder="Introduce texto notificación del directo"
          [disabled]="uploading"
          [(ngModel)]="notification"
          (ionFocus)="inputFocused($event)"
          (ionChange)="error.notification = false">
        </ion-input>

        <ion-text class="field-error" *ngIf="error.notification" color="danger">
          <small>
            <ion-icon name="alert-circle-outline"></ion-icon>
            <span>Debes introducir un título</span>
          </small>
        </ion-text>
      </div>
    </div>
  </ng-container>

  <!-- Video -->
  <div class="field video" *ngIf="type === ContentType.LiveVideo">
    <div class="content" (click)="pickPoster()">

      <ion-thumbnail class="thumbnail">
        <img
          *ngIf="!poster && posterSrc"
          handleError
          thumbnail
          [size]="600"
          [source]="asString(posterSrc)" />
        <img
          *ngIf="poster || !(!poster && posterSrc)"
          handleError
          [src]="posterSrc" />
      </ion-thumbnail>

      <ion-button class="choose-file" fill="clear">
        <ion-icon src="assets/svg-icon/camera-svg-svf-clear.svg"></ion-icon>
        <!-- <ion-icon src="assets/svg-icon/camera2-clear.svg"></ion-icon> -->
      </ion-button>
    </div>

    <ion-text class="field-error" *ngIf="error.poster" color="danger">
      <small>
        <ion-icon name="alert-circle-outline"></ion-icon>
        <span>Debes seleccionar una imagen de portada</span>
      </small>
    </ion-text>
  </div>

  <!-- Audio -->
  <div class="field audio" *ngIf="type === ContentType.LiveAudio">
    <div class="content" (click)="pickPoster()">

      <ion-thumbnail class="thumbnail">
        <img
          *ngIf="!poster && posterSrc"
          handleError
          thumbnail
          [size]="600"
          [source]="asString(posterSrc)" />
        <img
          *ngIf="poster || !(!poster && posterSrc)"
          handleError
          [src]="posterSrc" />
      </ion-thumbnail>

      <ion-button class="choose-file" fill="clear">
        <ion-icon src="assets/svg-icon/mic-svg-svf.svg"></ion-icon>
        <!-- <ion-icon src="assets/svg-icon/camera2-clear.svg"></ion-icon> -->
      </ion-button>
    </div>

    <ion-text class="field-error" *ngIf="error.poster" color="danger">
      <small>
        <ion-icon name="alert-circle-outline"></ion-icon>
        <span>Debes seleccionar una imagen de portada</span>
      </small>
    </ion-text>
  </div>

  <!-- Title -->
  <div class="field less-margin input">
    <div class="content">
      <ion-input
        required
        maxlength="200"
        placeholder="Introduce un título"
        [disabled]="uploading"
        [(ngModel)]="title"
        (ionFocus)="inputFocused($event)"
        (ionChange)="error.title = false">
      </ion-input>

      <ion-text class="field-error" *ngIf="error.title" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>Debes introducir un título</span>
        </small>
      </ion-text>
    </div>
  </div>

  <!-- Description -->
  <div class="field description input">
    <div class="content">
      <ion-textarea
        required
        rows="1"
        maxlength="10000"
        placeholder="Introduce una descripción"
        [disabled]="uploading"
        [autoGrow]="true"
        [(ngModel)]="description"
        (ionFocus)="inputFocused($event)"
        (ionChange)="error.description = false">
      </ion-textarea>

      <ion-text class="field-error" *ngIf="error.description" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>Debes introducir una descripción</span>
        </small>
      </ion-text>
    </div>
  </div>

  <!-- Categories -->
  <div class="field category select">
    <div class="title">Categoría</div>

    <div class="content" (click)="pickCategory()">
      <ion-select
        mode="md"
        placeholder="Selecciona una categoría"
        [disabled]="uploading"
        [(ngModel)]="category"
        (ionChange)="error.category = false">
        <ion-select-option *ngFor="let category of categories" [value]="category.id">
          {{category.name}}
        </ion-select-option>
      </ion-select>

      <ion-text class="field-error" *ngIf="error.category" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>Debes seleccionar una categoría</span>
        </small>
      </ion-text>
    </div>
  </div>

  <!-- Privacy -->
  <div class="field privacy select">
    <div class="title">Privacidad</div>

    <div class="content">
      <ion-select
        mode="md"
        ok-text="Seleccionar"
        cancel-text="Cancelar"
        placeholder="Seleccionar una opción"
        [disabled]="uploading"
        [(ngModel)]="privacy"
        (ionChange)="privacyChanged()">
        <ion-select-option value="all">Público</ion-select-option>
        <ion-select-option value="subscribers">Solo suscriptores</ion-select-option>
      </ion-select>

      <ion-text class="field-error" *ngIf="error.privacy" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>Debes seleccionar la privacidad</span>
        </small>
      </ion-text>
    </div>

    <div class="info" *ngIf="privacy === 'subscribers'">
      Solo tus suscriptores podrán entrar a esta sala de
      <span *ngIf="type === ContentType.LiveVideo">vídeo</span>
      <span *ngIf="type === ContentType.LiveAudio">audio</span>
    </div>
  </div>

  <!-- Chat -->
  <div class="field chat select">
    <div class="title">Chat</div>

    <div class="content">
      <ion-select
        mode="md"
        ok-text="Seleccionar"
        cancel-text="Cancelar"
        placeholder="Seleccionar una opción"
        [disabled]="uploading"
        [(ngModel)]="chat"
        (ionChange)="error.chat = false">
        <ion-select-option value="all" *ngIf="privacy !== 'subscribers'">Público</ion-select-option>
        <ion-select-option value="followers" *ngIf="privacy !== 'subscribers'">Solo seguidores</ion-select-option>
        <ion-select-option value="subscribers">{{privacy === 'subscribers' ? 'Activado' : 'Solo suscriptores'}}</ion-select-option>
        <ion-select-option value="disabled">Desactivado</ion-select-option>
      </ion-select>

      <ion-text class="field-error" *ngIf="error.chat" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>Debes seleccionar la participación en el chat</span>
        </small>
      </ion-text>
    </div>

    <div class="info" *ngIf="chat === 'all'">
      Todo el mundo podrá participar en el chat
    </div>
    <div class="info" *ngIf="chat === 'followers'">
      Solo tus seguidores podrán participar en el chat
    </div>
    <div class="info" *ngIf="chat === 'subscribers'">
      Solo tus suscriptores podrán participar en el chat
    </div>
    <div class="info" *ngIf="chat === 'disabled'">
      El chat estará deshabilitado
    </div>
  </div>

  <!-- Rise hand -->
  <div class="field hand select">
    <div class="title">Levantar la mano</div>

    <div class="content" *ngIf="type === ContentType.LiveAudio">
      <ion-select
        mode="md"
        ok-text="Seleccionar"
        cancel-text="Cancelar"
        placeholder="Seleccionar una opción"
        [disabled]="uploading"
        [(ngModel)]="hand"
        (ionChange)="error.hand = false">
        <ion-select-option value="all" *ngIf="privacy !== 'subscribers'">Público</ion-select-option>
        <ion-select-option value="followers" *ngIf="privacy !== 'subscribers'">Solo seguidores</ion-select-option>
        <ion-select-option value="subscribers">{{privacy === 'subscribers' ? 'Activado' : 'Solo suscriptores'}}</ion-select-option>
        <ion-select-option value="disabled">Desactivado</ion-select-option>
      </ion-select>

      <ion-text class="field-error" *ngIf="error.hand" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>Debes seleccionar quién puede levantar la mano</span>
        </small>
      </ion-text>
    </div>

    <div class="content video-hand" *ngIf="type === ContentType.LiveVideo">
      <div class="with-title">
        <div class="select-title">Vídeo</div>

        <ion-select
          mode="md"
          ok-text="Seleccionar"
          cancel-text="Cancelar"
          placeholder="Seleccionar una opción"
          [disabled]="uploading"
          [(ngModel)]="videoHand"
          (ionChange)="error.videoHand = false">
          <ion-select-option value="all" *ngIf="privacy !== 'subscribers'">Público</ion-select-option>
          <ion-select-option value="followers" *ngIf="privacy !== 'subscribers'">Solo seguidores</ion-select-option>
          <ion-select-option value="subscribers">{{privacy === 'subscribers' ? 'Activado' : 'Solo suscriptores'}}</ion-select-option>
          <ion-select-option value="disabled">Desactivado</ion-select-option>
        </ion-select>
      </div>

      <ion-text class="field-error" *ngIf="error.videoHand" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>Debes seleccionar quién puede levantar la mano con vídeo</span>
        </small>
      </ion-text>
    </div>
    <div class="content" *ngIf="type === ContentType.LiveVideo">
      <div class="with-title">
        <div class="select-title">Audio</div>

        <ion-select
          mode="md"
          ok-text="Seleccionar"
          cancel-text="Cancelar"
          placeholder="Seleccionar una opción"
          [disabled]="uploading"
          [(ngModel)]="audioHand"
          (ionChange)="error.audioHand = false">
          <ion-select-option value="all" *ngIf="privacy !== 'subscribers'">Público</ion-select-option>
          <ion-select-option value="followers" *ngIf="privacy !== 'subscribers'">Solo seguidores</ion-select-option>
          <ion-select-option value="subscribers">{{privacy === 'subscribers' ? 'Activado' : 'Solo suscriptores'}}</ion-select-option>
          <ion-select-option value="disabled">Desactivado</ion-select-option>
        </ion-select>
      </div>

      <ion-text class="field-error" *ngIf="error.audioHand" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>Debes seleccionar quién puede levantar la mano con audio</span>
        </small>
      </ion-text>
    </div>
  </div>

  <!-- Save -->
  <div class="field save select" *ngIf="user?.isPro">
    <div class="title">Guardar emisión <app-pro-tag></app-pro-tag></div>

    <div class="content">
      <ion-select
        mode="md"
        [disabled]="uploading"
        [(ngModel)]="save"
        ok-text="Seleccionar"
        cancel-text="Cancelar"
        placeholder="Seleccionar">
        <ion-select-option [value]="0">No</ion-select-option>
        <ion-select-option [value]="1">Si</ion-select-option>
      </ion-select>
    </div>
  </div>

  <!-- Retransmisión -->
  <div class="field less-margin input">
    <div class="title">Servidor de retransmisión<app-pro-tag></app-pro-tag></div>

    <div class="content">
      <ion-input
        required
        maxlength="200"
        placeholder="Disponible después de guardar"
        readonly="true"
        [(ngModel)]="streamUrl">
      </ion-input>
    </div>
  </div>
  <div class="field less-margin input">
    <div class="title">Clave de retransmisión<app-pro-tag></app-pro-tag></div>

    <div class="content">
      <ion-input
        required
        maxlength="200"
        placeholder="Disponible después de guardar"
        readonly="true"
        [(ngModel)]="streamKey">
      </ion-input>
    </div>
  </div>

  <div class="submit-button">
    <ion-button
      fill="clear"
      [disabled]="uploading"
      (click)="submitForm()">
      Guardar
      <ion-spinner color="white" *ngIf="uploading" name="crescent"></ion-spinner>
    </ion-button>
  </div>

  <div class="keyboard-space" [ngStyle]="{'height': keyboardHeight + 'px'}"></div>

  <div class="ios-bottom-safe-area"></div>

</ion-content>
