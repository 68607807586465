import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Platform, ModalController, NavController } from '@ionic/angular';
import { trigger, transition, animate, style } from '@angular/animations';

import { BaseComponent } from '../base.component';

import { FormPage } from '../form/form.page';
import { SmsPage } from '../sms/sms.page';

import { AuthService } from '../../../services/auth.service';
import { Events } from '../../../services/events.service';
import { AnalyticsService } from '../../../services/analytics.service';

import { RegisterType } from '../../../enums/register-type';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.page.html',
  styleUrls: ['./invitation.page.scss', '../base.component.scss', '../../../../theme/field.scss'],
  animations: [
    trigger('inOutBottomFade', [
      transition(':enter', [
        style({opacity: '0', transform: 'translateY(10px)'}),
        animate('500ms ease', style({opacity: '1', transform: 'translateY(0px)'}))
      ])
    ])
  ]
})
export class InvitationPage extends BaseComponent {

  @Input() registerType: RegisterType = RegisterType.Code;
  @Input() code: string = '';
  @Input() autoCheck: boolean = false;

  RegisterType = RegisterType;

  invitationError: string = '';

  invitationChecked: boolean = false;

  phoneValidated: boolean = false;

  constructor(
    public platform: Platform,
    public modalCtrl: ModalController,
    public navCtrl: NavController,
    public authService: AuthService,
    public events: Events,
    public cdRef: ChangeDetectorRef,
    public analyticsService: AnalyticsService
  ) {
    super(platform, modalCtrl, navCtrl, cdRef, authService, events, analyticsService);

    // Check if register restore point is setted
    this.authService.getRegisterState().then(data => {
      if ( data !== undefined && data.invitation ) {
        this.registerType = data.registerType;
        
        this.code = data.invitation.code;
        this.phoneValidated = data.invitation.phoneValidated;
        this.phone = data.invitation.phone;
        this.selectedCountry = data.invitation.country;

        this.goToForm();
      } else {
        this.authService.deleteRegisterState();
      }
    })
  }

  ngOnInit() {
    if ( this.autoCheck ) {
      this.checkCode();
    }
  }

  ionViewDidEnter() {
    this.analyticsService.setScreenName('register_invitation_check');
  }

  /**
   * Check invitation code
   */
  checkCode() {
    if ( this.code === undefined || this.code.trim() === '' ) {
      this.error.code = true;
      this.invitationError = 'Este campo es obligatorio';
      return;   // BREAK EXECUTION
    }

    this.loading = true;

    // Save current invitation code
    this.authService.setCurrentInvitationCode(this.code);
    // Check invitation code with backend
    this.authService.checkInvitationCode(this.code, null).subscribe(
      res => {

        /*
         * The loading screen should delay at least 1s for
         * a better user experience
         */

        setTimeout(() => {
          this.loading = false;

          if (res.done) {
            
            /*
             * This 1.5s timeout is for invitation accepted animation
             */

            this.invitationChecked = true;
            this.cdRef.detectChanges();

            setTimeout(() => {
              this.invitationChecked = false;
              this.cdRef.detectChanges();
              this.goToForm();
            }, 1500);
          } else {
            this.invitationError = 'El código introducido no es válido';
            this.error.code = true;
          }

          this.cdRef.detectChanges();
        }, 1000);
      }, err => {
        this.loading = false;
        this.invitationError = 'El código introducido no es válido';
        this.error.code = true;
        this.cdRef.detectChanges();
      }
    );
  }

  /**
   * Check invitation by phone
   */
  checkPhone() {
    if ( this.phone === undefined || this.phone.trim() === '' ) {
      this.error.phone = true;
      this.invitationError = 'Este campo es obligatorio';
      return;   // BREAK EXECUTION
    }

    this.error.phone = this.selectedCountry.code === '34' 
      ? this.phone.trim().match(this.mobilePhoneESRegex) === null
      : this.phone.trim().match(this.phoneGlobalRegex) === null;

    if ( this.error.phone ) {
      this.invitationError = 'El número introducido no es correcto';
    } else {
      this.loading = true;

      this.authService.checkPhoneBeforeRegister(this.selectedCountry.code, this.phone).subscribe(
        res => {

          /*
           * The loading screen should delay at least 1s for
           * a better user experience
           */

          setTimeout(() => {
            this.loading = false;

            if (res.done) {
              const registered = res.data.registered;
              const needInvitation = res.data.needInvitation;
              this.phoneValidated = res.data.validated;

              if ( needInvitation ) {
                this.invitationError = 'El número introducido no tiene ninguna invitación asociada.';
                this.error.phone = true;
              } else if ( !registered ) {

                /*
                 * This 1.5s timeout is for invitation accepted animation
                 */

                this.invitationChecked = true;
                this.cdRef.detectChanges();

                setTimeout(() => {
                  this.invitationChecked = false;
                  this.cdRef.detectChanges();
                  this.goToForm();
                }, 1500);
              } else {

                /*
                 * This 1.5s timeout is for invitation accepted animation
                 */

                this.invitationChecked = true;
                this.cdRef.detectChanges();

                setTimeout(() => {
                  this.invitationChecked = false;
                  this.cdRef.detectChanges();
                  this.goToSms();
                }, 1500);
              }
            } else {
              this.invitationError = 'El número introducido no es válido';
              this.error.phone = true;
            }

            this.cdRef.detectChanges();
          }, 1000);
        }, err => {
          this.loading = false;
          this.invitationError = 'El número introducido no es válido';
          this.error.phone = true;
          this.cdRef.detectChanges();
        }
      )
    }
  }

  /**
   * Navigate to user register form
   */
  goToForm() {
    this.goToPage(FormPage, 
      { 
        registerType: this.registerType,
        invitation: {
          code: this.code,
          phoneValidated: this.phoneValidated,
          phone: this.registerType === RegisterType.Phone ? this.phone : undefined,
          country: this.registerType === RegisterType.Phone ? this.selectedCountry : undefined
        }
      }
    );
  }

  /**
   * Navigate to sms checker
   */
  goToSms() {
    this.goToPage(SmsPage, 
      { 
        registerType: this.registerType,
        phone: this.phone
      }
    );
  }
}
