import { Component, Input } from "@angular/core";

@Component({
    selector: 'search-video-item',
    templateUrl: './search-video-item.component.html',
    styleUrls: ['./search-video-item.component.scss']
})
export class SearchVideoItemComponent {
    @Input()
    video: any;

    profilePictureStyleFor(user: { imgProfile: string, id: number}|undefined) {
      if (!user || !user.id || !user.imgProfile) return {};
  
      return {
        'background-image': `url('https://estrim.kimerikal.com/img/profile/${user.id}/${user.imgProfile}`
      };
    }
}