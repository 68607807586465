import { Component, Input } from '@angular/core';

import { CreateContentService } from '../../services/create-content.service';

@Component({
  selector: 'app-generic-alert',
  templateUrl: './generic-alert.component.html',
  styleUrls: ['./generic-alert.component.scss'],
})
export class GenericAlertComponent {

  @Input() message: string = '';

  constructor(
    private createContentService: CreateContentService
  ) { }

  dismiss() {
    this.createContentService.closeModal();
  }
}
