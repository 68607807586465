import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import {
  ActionSheetController,
  AlertController,
  GestureController,
  IonRouterOutlet,
  ModalController, Platform
} from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../../interfaces/user';
import { AuthService } from '../../../services/auth.service';
import { environment } from '../../../../environments/environment';
import { Events } from '../../../services/events.service';
import { MediaService } from '../../../services/media.service';
import { NotificationService } from '../../../services/notification.service';
import { WebsocketService } from '../../../services/websocket.service';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { FileTransferService } from '../../../services/file-transfer.service';
import { Geolocation } from '@capacitor/geolocation';
import { ContactListToSendPage } from '../../../pages/contact-list-to-send/contact-list-to-send.page';
import { RecordingData, VoiceRecorder } from 'capacitor-voice-recorder';
import { PaymentService } from '../../../services/payment.service';
import { Preferences } from '@capacitor/preferences';
import { trigger, transition, animate, style } from '@angular/animations';
import {goToUserProfile} from "../../../utils/routing";

@Component({
  selector: 'app-chat-with-user-content',
  templateUrl: './chat-with-user-content.component.html',
  styleUrls: ['./chat-with-user-content.component.scss'],
  animations: [
    trigger('inOutFromBottom', [
      transition(':enter', [
        style({transform: 'translateY(100%)'}),
        animate('300ms ease', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('300ms ease', style({transform: 'translateY(100%)'}))
      ])
    ])
  ]
})
export class ChatWithUserContentComponent implements OnInit {

  @ViewChild('divChat', {static: false}) divChat: ElementRef;
  @ViewChild('content') private content: any;
  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;
  username;
  messages = [];
  cachedMessages = [];
  currentUser = '';
  newMsg = '';
  cachedUserToTalk: User;
  user: User;
  cachedUser: User;
  roomId;
  intervalFunctions = [];
  websocketRoomExists = false;
  currentInputText = '';
  addBubbleOpened = false;
  scrollInBottom = false;
  uploadLoading = false;
  recordingAudio = false;
  recordingDuration = 0;
  recordingDurationDisplay = '';
  starsDivOpened = false;
  starsQuantity = 0;
  videoCallPending = false;
  videoCallStarted = false;
  videoCallId = '';
  closeWebsocketOnExit = true;

  PRIVATE_CHAT_MESSAGES = '';
  PRIVATE_CHAT_USER = '';
  PRIVATE_CHAT_USERTOTALK = '';

  private touchTime = 0;
  private touchElement = null;
  longPress = false;
  longPressOptions = false;

  constructor(private activatedRoute: ActivatedRoute, private authService: AuthService,
              private changeDetector: ChangeDetectorRef, private events: Events,
              private routerOutlet: IonRouterOutlet, private mediaService: MediaService, private router: Router,
              private notificationService: NotificationService, private websocket: WebsocketService,
              private fileTransfer: FileTransferService, private alertController: AlertController, private modalCtrl: ModalController,
              private gestureCtrl: GestureController, private paymentService: PaymentService,
              private actionSheetCtrl: ActionSheetController, private platform: Platform) {
    // TODO: arreglar
    // this.username = this.activatedRoute.snapshot.url[1].path;

    this.events.subscribe(environment.EVENTS.SPEAKER_LEFT, () => {
      this.stopVideoCall(null);
    });
  }


  async ngOnInit() {
    this.PRIVATE_CHAT_MESSAGES = 'private_chat_messages_' + this.username;
    this.PRIVATE_CHAT_USER = 'private_chat_user_' + this.username;
    this.PRIVATE_CHAT_USERTOTALK = 'private_chat_usertotalk_' + this.username;
    const jsonMessages = await Preferences.get({key: this.PRIVATE_CHAT_MESSAGES});
    if (jsonMessages.value) {
      this.cachedMessages = JSON.parse(jsonMessages.value);
    }

    const jsonUser = await Preferences.get({key: this.PRIVATE_CHAT_USER});
    if (jsonUser.value) {
      this.cachedUser = JSON.parse(jsonUser.value);
    }

    const jsonUserToTalk = await Preferences.get({key: this.PRIVATE_CHAT_USERTOTALK});
    if (jsonUserToTalk.value) {
      this.cachedUserToTalk = JSON.parse(jsonUserToTalk.value);
    }
    console.log('messages', this.cachedMessages);
    console.log('user', this.cachedUser);
    console.log('userToTalk', this.cachedUserToTalk);
    if (!this.cachedUserToTalk) {
      this.authService.getUserDataByUsername(this.username).subscribe(response => {
        this.cachedUserToTalk = response.data;
        Preferences.set({key: this.PRIVATE_CHAT_USERTOTALK, value: JSON.stringify(this.cachedUserToTalk)}).then(() => {
        });
        if (!this.cachedUser) {
          this.authService.getUserDetails().then(data => {
            this.cachedUser = data;
            Preferences.set({key: this.PRIVATE_CHAT_USER, value: JSON.stringify(this.cachedUser)}).then(() => {
            });
            this.roomId =
              '4444' + Math.min(this.cachedUser.id, this.cachedUserToTalk.id).toString() +
              Math.max(this.cachedUser.id, this.cachedUserToTalk.id).toString();

            this.initializeWebsocket();
          });
        } else {
          this.roomId =
            '4444' + Math.min(this.cachedUser.id, this.cachedUserToTalk.id).toString() +
            Math.max(this.cachedUser.id, this.cachedUserToTalk.id).toString();
          this.initializeWebsocket();
        }

      });
    } else {
      this.roomId =
        '4444' + Math.min(this.cachedUser.id, this.cachedUserToTalk.id).toString() +
        Math.max(this.cachedUser.id, this.cachedUserToTalk.id).toString();

      this.initializeWebsocket();
    }


    const mymessage: any = document.getElementById('myMessage');
    this.events.subscribe(environment.EVENTS.CHAT_MESSAGE, (msg) => {
      this.messages.push(msg);
      mymessage.value = '';
      this.changeDetector.detectChanges();
      this.scrollToBottom(100)
    });
    setTimeout(() => {
      this.changeDetector.detectChanges();
      this.scrollToBottom();
    }, 150);
    this.events.subscribe(environment.EVENTS.RESUME_APP, () => {
      this.checkReconnection();
    });

  }

  ngAfterViewInit() {
  }

  onRightClick(event: MouseEvent, msg: any) {
    event.preventDefault();
    if (this.cachedUser.id.toString() !== msg.userId) {
      return;
    }
    this.openMsgOptions(msg);
  }

  enableLongPress(event: TouchEvent, msg) {
    // if (event.cancelable) {
    //   event.preventDefault();
    // }
    if (this.cachedUser.id.toString() !== msg.userId) {
      return;
    }

    this.longPress = true;
    setTimeout(() => {
      if (this.longPress && this.longPressOptions === false) {
        this.longPressOptions = true;
        setTimeout(() => {
          this.openMsgOptions(msg);
          this.longPress = false;
        });
      }

    }, 500);
  }

  cancelLongPress() {
    this.longPress = false;
  }

  async openMsgOptions(msg) {
    const buttons = [
      {
        text: 'Eliminar',
        icon: 'trash-outline',
        handler: () => {
          this.presentAlertDelete(msg);
        }
      }
    ];

    const actionSheet = await this.actionSheetCtrl.create({
      buttons
    });
    await actionSheet.present();

    const {data} = await actionSheet.onDidDismiss();
    this.longPressOptions = false;
  }

  async presentAlertDelete(msg) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-alert-class',
      message: '¿Quieres eliminar este mensaje?',
      buttons: [
        {
          text: 'Eliminar',
          handler: (data) => {
            this.websocket.deleteMessage(this.roomId, msg._id);
            this.messages = this.messages.filter(m => m._id !== msg._id && m.idMensaje !== msg._id);
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel'
        }
      ],
    });
    alert.present();
  }

  getCreditsBalance() {
    return this.paymentService.getWallet(false).subscribe((res) => res.balance);
  }

  updateInputText(event) {
    if (event && event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.sendChatMessage();
    }
    if (this.scrollInBottom)
      this.scrollToBottom();
  }


  sendChatMessage() {
    if (this.currentInputText.trim() === '') {
      return;
    }
    this.websocket.sendMessage(this.roomId, this.currentInputText.trim(), null, null, true);
    this.notificationService.sendPushNotification(this.cachedUserToTalk.id, this.currentInputText,
      'Nuevo mensaje de ' + this.cachedUser.username,
      'private_chat', this.cachedUser.username).subscribe(res => {
    });
    this.currentInputText = '';

  }

  sendChatFile(file, type = 'Image') {
    this.websocket.sendMessage(this.roomId, '', file, type, true);
    this.notificationService.sendPushNotification(this.cachedUserToTalk.id, 'archivo', 'Nuevo archivo de ' + this.cachedUser.username,
      'private_chat', this.cachedUser.username).subscribe(res => {
    });

    this.addBubbleOpened = false;
    setTimeout(() => {
      this.scrollToBottom();
    }, 200);

  }

  ionViewDidLeave() {
    if (this.videoCallPending) {
      // this.videoCallPending = false;
      // this.sendChatFile({type: 'video', status: 0}, 'Call');
    }
    if (this.closeWebsocketOnExit)
      this.websocket.close();
  }

  ionViewDidEnter() {
    this.closeWebsocketOnExit = false;
    this.checkReconnection();

  }

  canGoBack() {
    return this.routerOutlet.canGoBack();
  }

  goBack() {
    if (this.routerOutlet.canGoBack()) {
      this.routerOutlet.pop();
    } else {
      this.router.navigate([goToUserProfile(this.router.url, this.cachedUserToTalk.username)], {replaceUrl: true});
    }
  }

  getProfileImg(user: User) {
    return this.mediaService.generateImgProfileURL(user.id, user.imgProfile);
  }

  private async initializeWebsocket() {
    await this.websocket.init();
    this.intervalFunctions.push(setInterval(() => {
      this.websocket.roomExists(0);
    }, 50000));
    this.websocket.messages.subscribe(msg => {
      this.manageSocketResponse(msg);
    });
  }

  scrollToBottom(delay = 0) {
    setTimeout(() => {
      // TODO: arreglar

      // this.content.scrollToBottom(200);
    }, delay);
  }

  private manageSocketResponse(msg: any) {
    switch (true) {
      case msg.hasOwnProperty('mensaje') && msg.mensaje === 'Usuario Conectado !!!':
        if (!this.websocketRoomExists) {
          this.websocket.createRoom(this.roomId);
        }
        break;
      case msg.evento === 'createSala' || msg.mensaje === 'La sala ya existe !!!':
        this.websocketRoomExists = true;
        this.websocket.joinRoom(this.roomId);
        break;
      case msg.evento === 'joinSala':
        if (msg.hasOwnProperty('data')) {
          this.messages = msg.data;
          this.changeDetector.detectChanges();
          this.scrollToBottom(200);
        }
        this.websocket.numParticipants(this.roomId);
        break;
      case msg.evento === 'sendSala':
        this.messages.push(msg);
        this.scrollToBottom(200);
        break;
      case msg.hasOwnProperty('idMensaje') && msg.hasOwnProperty('likesTotales'):
        this.messages.find(m => m._id === msg.idMensaje).like = msg.likesTotales;
        break;
      case msg.hasOwnProperty('file') && msg.file.type === 'Call':
        this.messages.push(msg);
        this.scrollToBottom(200);
        if (msg.file.data.status === 1) {
          this.videoCallPending = true;
          this.videoCallId = msg.idMensaje;
        } else {
          this.stopVideoCall(this.videoCallId, false);
        }
        break;
      default:
        break;
    }
    if (this.messages.length > 0) {
      this.cachedMessages = this.messages;
      Preferences.set({key: this.PRIVATE_CHAT_MESSAGES, value: JSON.stringify(this.cachedMessages)}).then(() => {
      });
      console.log('respuesta del socket ', msg);
    }

  }

  isAnotherDate(msg, previousMsg) {
    if (!previousMsg) {
      return false;
    }
    const date1 = new Date(previousMsg.date);
    const date2 = new Date(msg.date);
    return date2.getFullYear() !== date1.getFullYear() ||
      date2.getMonth() !== date1.getMonth() ||
      date2.getDate() !== date1.getDate();
  }

  isToday(msg) {
    const date1 = new Date(msg.date);
    const date2 = new Date();
    return date2.getFullYear() === date1.getFullYear() &&
      date2.getMonth() === date1.getMonth() &&
      date2.getDate() === date1.getDate();
  }


  async checkScroll(event) {
    const scrollElement = await event.target.getScrollElement();
    this.scrollInBottom = scrollElement.scrollTop + scrollElement.clientHeight === scrollElement.scrollHeight;
  }

  takePhoto() {
    this.addImage(CameraSource.Camera);
  }

  pickFromGallery() {
    this.addImage(CameraSource.Photos);
  }

  async addImage(source: CameraSource) {
    const image = await Camera.getPhoto({
      quality: 60,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      source
    });

    const blobData = this.mediaService.b64toBlob(image.base64String, `image/${image.format}`);
    const imageFile = new File([blobData], 'image.png');
    this.uploadLoading = true;
    this.addBubbleOpened = false;
    this.fileTransfer.sendPhotoPrivate(imageFile, this.cachedUserToTalk.id).subscribe(res => {
      if (res.done) {
        this.sendChatFile({filename: res.file}, 'Image');
      } else {
        this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error al subir el archivo');
      }
      this.uploadLoading = false;

    });


  }

  private checkReconnection() {
    const socketState = this.websocket.readyState();
    if (socketState === 3 || socketState === 0) {
      this.websocketRoomExists = false;
      this.initializeWebsocket();
    } else {
    }
  }

  async presentAlertSendLocation() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-alert-class',
      header: 'Enviar ubicación',
      message: '¿Deseas enviar tu ubicación actual?',
      buttons: [
        {
          text: 'Enviar',
          handler: () => {
            this.sendLocation();
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel'
        }
      ]
    });

    await alert.present();
  }

  private async sendLocation() {
    const coordinates = await Geolocation.getCurrentPosition();
    console.log('Current position:', coordinates);

    const latitude = coordinates.coords.latitude;
    const longitude = coordinates.coords.longitude;

    this.sendChatFile({latitude, longitude}, 'Location');
    this.addBubbleOpened = false;

  }

  isMobile() {
    return this.platform.is('hybrid');
  }

  async presentContactsModal() {
    const modal = await this.modalCtrl.create({
      component: ContactListToSendPage,
      componentProps: {}
    });
    await modal.present();

    const {data} = await modal.onDidDismiss();
    if (data.selectedContact) {
      const contact = data.selectedContact;
      this.sendChatFile(contact, 'Contact');
    }
  }

  startRecordAudio() {
    if (this.recordingAudio) {
      return;
    }
    VoiceRecorder.requestAudioRecordingPermission();
    this.recordingAudio = true;
    VoiceRecorder.startRecording();
    this.calculateRecordingDuration();
  }

  stopRecordAudio() {
    if (!this.recordingAudio) {
      return;
    }
    this.recordingAudio = false;
    VoiceRecorder.stopRecording().then(async (result: RecordingData) => {
      if (result.value && result.value.recordDataBase64) {
        const recordData = result.value.recordDataBase64;
        const mimeType = result.value.mimeType;
        const audioRef = new Audio(`data:${mimeType};base64,${recordData}`);
        console.log('grabado', recordData);
        //TODO guardar audio en servidor
        // this.fileTransfer.sendVoiceRecording(recordData, this.cachedUserToTalk.id).subscribe(res => {
        //   if (res.done) {
        //     this.sendChatFile({filename: res.file}, 'VoiceRecording');
        //   }
        // });
      }
    });
  }

  recordAction() {
    if (!this.recordingAudio) {
      this.startRecordAudio()
    } else {
      this.stopRecordAudio()
    }
  }

  private calculateRecordingDuration() {
    if (!this.recordingAudio) {
      this.recordingDuration = 0;
      this.recordingDurationDisplay = '';
      return;
    }
    this.recordingDuration += 1;
    const minutes = Math.floor(this.recordingDuration / 60);
    const seconds = (this.recordingDuration % 60).toString().padStart(2, '0');
    this.recordingDurationDisplay = `${minutes}:${seconds}`;

    setTimeout(() => {
      this.calculateRecordingDuration();
    }, 1000);
  }

  uploadFile(event) {
    const file = event.target.files[0];
    const filename = file.name;
    const size = file.size;
    const type = file.type;
    this.uploadLoading = true
    if (size > 50000000) {
      this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'El archivo es demasiado grande.');
    } else {
      console.log('file', file);
      if (type.startsWith('image/')) {
        this.fileTransfer.sendPhotoPrivate(file, this.cachedUserToTalk.id).subscribe(res => {
          if (res.done) {
            this.sendChatFile({filename: res.file}, 'Image');
          } else {
            this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error al subir el archivo');
          }
          this.uploadLoading = false;

        });
      } else {
        // this.fileTransfer.sendFilePrivate(file, this.cachedUserToTalk.id).subscribe(res => {
        //   if (res.done) {
        //     this.sendChatFile({filename: environment.CDN_BASE_URL + res.file}, 'File');
        //   } else {
        //     this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error al subir el archivo');
        //   }
        //   this.uploadLoading = false;
        // });
      }

      this.fileInput.nativeElement.value = '';
    }

  }

  addFile() {
    this.fileInput.nativeElement.click()
  }


  async presentAlertSendStars(stars) {
    let quantity = stars;
    if (!stars) {
      quantity = this.starsQuantity;
    }
    if (quantity === 0) {
      return;
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-alert-class',
      header: 'Enviar estrellas',
      message: `Vas a enviar ${quantity} estrellas y a gastar ${quantity} créditos.`,
      buttons: [
        {
          text: 'Aceptar',
          handler: () => {
            // this.sendStars(quantity);
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel'
        }
      ]
    });

    await alert.present();
  }

  likeMessage(event, msg) {

    if (this.touchTime === 0) {
      // set first click
      this.touchTime = new Date().getTime();
      this.touchElement = event.target;
    } else {
      // compare first click to this click and see if they occurred within double click threshold
      if (((new Date().getTime()) - this.touchTime) < 800 && this.touchElement === event.target) {
        // double click occurred
        this.websocket.likeMessage(this.roomId, msg._id);
        this.touchTime = 0;
      } else {
        // not a double click so set as a new first click
        this.touchTime = new Date().getTime();
      }
    }


  }

  startVideoCall() {
    if (this.videoCallPending) {
      return;
    }
    this.closeWebsocketOnExit = false;
    this.videoCallPending = true;
    this.sendChatFile({type: 'video', status: 1}, 'Call');
    this.router.navigate(['/messages/private-video-call/' + this.roomId],);

  }

  stopVideoCall(msgId, notifyEnd = true) {
    if (!this.videoCallPending) {
      return;
    }
    let idMessage;
    if (msgId) {
      idMessage = msgId;
    } else {
      idMessage = this.videoCallId;
    }
    this.websocket.deleteMessage(this.roomId, idMessage);
    this.messages = this.messages.filter(m => m._id !== idMessage && m.idMensaje !== idMessage);
    this.videoCallPending = false;
    if (notifyEnd) {
      this.sendChatFile({type: 'video', status: 0, idMensaje: idMessage}, 'Call');
    }

    if (this.router.url.startsWith('/messages/private-video-call')) {
      this.goBack();
    }
  }

  acceptVideoCall() {
    this.closeWebsocketOnExit = false;
    this.router.navigate([`/messages/private-video-call/${this.roomId}`],);
  }

  answerVideoCall(data) {
    if (data.status === 1) {
      console.log('llamada aceptada')
      this.acceptVideoCall();
    } else {
      console.log('llamada cancelada', data.msgId)
      this.stopVideoCall(data.msgId);
    }
  }

  checkShouldShowProfileImage(index: number): boolean {
    if (this.cachedMessages[index + 1] !== undefined) {
      return this.cachedMessages[index].username !== this.cachedMessages[index + 1].username ||
        this.isAnotherDate(this.cachedMessages[index], this.cachedMessages[index + 1]);
    } else {
      return true;
    }
  }

}
