export enum ChatMessageType {
  Text = 'text',
  Image = 'image',
  Video = 'video',
  Audio = 'audio',
  File = 'file',
  Location = 'location',
  Contact = 'contact',
  Stars = 'stars',
  Event = 'event',
  Kick = 'kick',
}

export enum ChatEventMessageType {
  VideoCall = 'videoCall',
  AudioCall = 'call',
  RaiseHand = 'raiseHand',
  PutHandDown = 'putHandDown',
  AcceptHand = 'acceptHand',
  RejectHand = 'rejectHand',
  KickUser = 'kickUser',
  ToggleVideoMirror = 'toggleVideoMirror',
  PrivateMessage = 'privateMessage',
  LiveStreamInvitation = 'liveStreamInvitation',
}