import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { IntroLivePageRoutingModule } from './intro-live-routing.module';

import { IntroLivePage } from './intro-live.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    IntroLivePageRoutingModule
  ],
  declarations: [IntroLivePage]
})
export class IntroLivePageModule {}
