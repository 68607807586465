<ion-searchbar
  #searchBar
  mode="ios"
  type="text"
  [placeholder]="placeholder"
  searchIcon="search-outline"
  debounce="1000"
  [showClearButton]="clearButton"
  (ionInput)="ionInput.emit($event)"
  (ionChange)="inputChange.emit($event)"
  (ionCancel)="clear.emit()"
  (ionClear)="clear.emit()">
</ion-searchbar>
