<div #content class="content" 
  [ngStyle]="{
    'height': containerHeight + 'px',
    'maxHeight': containerMaxHeight,
    'bottom': bottomDescription + 'px',
    'transform': 'translateY(-' + contentTranslate + 'px)'
  }">

  <div #step class="step" 
    *ngIf="details === undefined || !details" 
    [@inOutFromLeft]="details !== undefined"
    [@.disabled]="!viewInited">

    <ion-button fill="clear" class="close" (click)="dismiss()">
      <ion-icon name="close-outline"></ion-icon>
    </ion-button>

    <div class="title">¿Por qué quieres denunciar <span *ngIf="contentReport">este contenido</span> <span *ngIf="!contentReport">esta cuenta</span>?</div>
    <div class="description">
      Tu denuncia es anónima, a menos que denuncies una infracción de propiedad intelectual. 
      Si alquien se encuentra en peligro inminente, llama a los servicios de emergencia locales.
      No esperes.
    </div>
    <div class="option-buttons">
      <ion-button color="light" (click)="reasonReport(0,true)">Es spam</ion-button>
      <ion-button color="light" (click)="reasonReport(1,true)">Se hace pasar por otra persona</ion-button>
      <ion-button color="light" (click)="reasonReport(2,true)">Contenido inapropiado</ion-button>
      <ion-button color="light" (click)="reasonReport(3,true)">Es posible que sea menor de edad</ion-button>
      <ion-button color="light" (click)="reasonReport(4,true)">No me gusta</ion-button>
    </div>
  </div>

  <div #step class="step detail" 
    *ngIf="details" 
    @inOutFromRight 
    [ngStyle]="{'height': containerHeight + 'px'}"
    [@.disabled]="!viewInited">
    <ion-button fill="clear" class="close" (click)="dismiss()">
      <ion-icon name="close-outline"></ion-icon>
    </ion-button>

    <div class="title">Danos más detalles sobre tu denuncia</div>

    <div class="textarea">
      <ion-textarea autoGrow="true" rows="10" [(ngModel)]="description"></ion-textarea>
    </div>

    <div class="action-buttons">
      <ion-button fill="clear" class="back" (click)="reasonReport(0,false)">
          <ion-icon slot="icon-only" name="chevron-back"></ion-icon>
        </ion-button>
        <ion-button class="send" color="light" (click)="send()">Enviar</ion-button>
    </div>
  </div>
</div>

<div class="soft-loading-container" *ngIf="softLoading" @inOutFade>
  <ion-spinner color="primary" name="crescent"></ion-spinner>
</div>