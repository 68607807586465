import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { RaiseHandPopoverOption } from '../../../enums/raise-hand-popover-option';

@Component({
  selector: 'app-raise-hand-popover',
  templateUrl: './raise-hand-popover.component.html',
  styleUrls: ['./raise-hand-popover.component.scss'],
})
export class RaiseHandPopoverComponent implements OnInit {

  @Input() canVideo: boolean = true;
  @Input() canAudio: boolean = true;
  @Input() videoRaised: boolean = false;
  @Input() audioRaised: boolean = false;
  @Input() emitting: boolean = false;

  RaiseHandPopoverOption = RaiseHandPopoverOption;

  constructor(private popoverCtrl: PopoverController) {
  }

  ngOnInit() {
  }

  pickOption(option: RaiseHandPopoverOption) {
    this.popoverCtrl.dismiss(option);
  }
}
