import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Keyboard } from '@capacitor/keyboard';

import { MediaService } from '../../services/media.service';

import { User } from '../../interfaces/user';

import { PingMode } from '../../enums/ping-mode';
import { ViewerType } from '../../enums/viewer-type';

@Component({
  selector: 'app-ping',
  templateUrl: './ping.component.html',
  styleUrls: ['./ping.component.scss'],
})
export class PingComponent implements OnInit, OnDestroy {

  @Input() user: User;
  @Input() message: string;
  @Input() mode: PingMode = PingMode.Send;
  @Input() viewerType: ViewerType = ViewerType.Random;

  PingMode = PingMode;
  ViewerType = ViewerType;

  totalLength: number = 0;

  loading: boolean = false;

  loadingImage = true;

  keyboardHeight: number = 0;
  keyboardOnScreen: boolean = false;

  marginBottom: string = '0px';

  listener: any = {};

  constructor(
    private modalCtrl: ModalController,
    private mediaService: MediaService,
    private cdRef: ChangeDetectorRef,
    private platform: Platform
  ) {
    if (this.platform.is('hybrid')) {
      this.listener.keyboardWillShow = Keyboard.addListener('keyboardWillShow', info => {
        this.keyboardOnScreen = true;
        // @ts-ignore
        this.keyboardHeight = info.keyboardHeight;

        /*
         * Calculate max margin bottom to prevent container
         * overflow top screen.
         * 326px is the height of the content
         * 10px is an extra space
         */
        const maxMarginBottom = this.platform.height() - 326 - 10;

        /*
         * Check if keyboard height is not bigger than max margin
         * in that case use max margin, otherwise use keyboard height
         * as margin bottom
         */
        this.keyboardHeight < maxMarginBottom
          ? this.marginBottom = `${this.keyboardHeight}px`
          : this.marginBottom = `${maxMarginBottom}px`;

        this.cdRef.detectChanges();
      });

      this.listener.keyboardWillHide = Keyboard.addListener('keyboardWillHide', () => {
        this.keyboardOnScreen = false;
        this.keyboardHeight = 0;

        this.marginBottom = `${this.keyboardHeight}px`;

        this.cdRef.detectChanges();
      });
    }
  }

  ngOnInit() {
    this.updateTotalLength();
  }

  ngOnDestroy() {
    this.listener?.keyboardWillShow?.remove();
    this.listener?.keyboardWillHide?.remove();
  }

  onLoadImage() {
    this.loadingImage = false;
  }

  /**
   * Get profile image
   */
  getImgProfile(): string {
    return this.mediaService.generateImgProfileURL(this.user?.id, this.user?.imgProfile);
  }

  /**
   * Update remaining characters indicator
   */
  updateTotalLength() {
    this.message
      ? this.totalLength = this.message.length
      : this.totalLength = 0;
  }

  /**
   * Return collected data to modal controller
   */
  sendPing() {
    return this.modalCtrl.dismiss({
      user: this.user,
      message: this.message
    }, 'confirm')
  }

  /**
   * Close modal
   */
  dismiss() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }
}
