import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';

import { inOutFade } from '../../animations/animations';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  animations: [ 
    inOutFade,
    trigger('inOutDropdown', [ 
      transition(':enter', [
        style({transform: 'translateY(-10px)', filter: 'blur(10px)', opacity: '0'}),
        animate('300ms cubic-bezier(0.175, 0.285, 0.32, 1.1)', style({transform: 'translateY(0)', filter: 'blur(0)', opacity: '1'})) 
      ]),
      transition(':leave', [
        animate('300ms cubic-bezier(0.175, 0.285, 0.32, 1.1)', style({transform: 'translateY(-10px)', filter: 'blur(10px)', opacity: '0'}))
      ]) 
    ])
  ]
})
export class SelectComponent implements OnInit {

  @Input() placeholder: string = '';
  @Input() options: {id: number, value: string, selected?: boolean}[] = [];
  @Input() selectedOption: number | undefined = undefined;

  @Output() onSelect: EventEmitter<number> = new EventEmitter<number>();

  option: {id: number, value: string, selected?: boolean} | undefined = undefined;
  dropdownVisible: boolean = false;

  constructor() { }

  ngOnInit() {
    this.getSelectedOption();
  }

  showDropdown() {
    this.dropdownVisible = true;
  }

  closeDropdown() {
    this.dropdownVisible = false;
  }

  select(id: number) {
    this.options.forEach(option => option.selected = false);
    this.option = this.options.find(option => option.id === id);
    this.option.selected = true;

    this.onSelect.emit(id);
    this.closeDropdown();
  }

  getSelectedOption() {
    if ( this.selectedOption )
      this.option = this.options.find(option => option.id === this.selectedOption);
  }
}
