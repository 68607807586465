<ion-content>
  <a (click)="dismiss()" class="close">
    <img src="assets/extrim/img/icons/close-white.svg" width="30" />
  </a>

  <section id="hero-er" class="section">
    <div class="content">
      
      <div class="section-title relative h1 text-center font-venera">
        VOTACIÓN
        <br>
        FINAL
      </div>

      <div class="result-section winner">
        <div class="icon">
          <img src="assets/extrim/img/icons/i2.svg" />
        </div>
        <div class="title font-venera">INMUNES</div>
        <ul class="pilots">
          <li *ngFor="let pilot of winners | slice:0:3">
            <div class="thumb">
              <lazy-img [source]="mediaService.generateThumbnailImgURL(pilot.thumbnail)" [size]="400"></lazy-img>
            </div>
            <h2>{{pilot.name}}</h2>
          </li>
        </ul>
      </div>
      <div class="result-section loser">
        <div class="icon">
          <img src="assets/extrim/img/icons/i3.svg" />
        </div>
        <div class="title font-venera">LASTRADOS</div>
        <ul class="pilots">
          <li *ngFor="let pilot of losers | slice:0:4; let i = index">
            <div class="sub">
              <div class="thumb lastre">
                <lazy-img [source]="'assets/extrim/img/lastre/Lastre_' + (i + 1) + '.png'" [size]="400"></lazy-img>
              </div>
              <div class="thumb xdriver">
                <lazy-img [source]="mediaService.generateThumbnailImgURL(pilot.thumbnail)" [size]="400"></lazy-img>
              </div>
            </div>
            <h2>{{pilot.name}}</h2>
          </li>
        </ul>
      </div>
    </div>
  </section>
</ion-content>
