import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { KComponent } from '../k.component';
import { slideAnimation } from '../../animations/slide-animation';
import {IonContent, MenuController, ModalController} from '@ionic/angular';
import { AuthService } from '../../services/auth.service';
import { ProfileService } from '../../services/profile.service';
import { User } from '../../interfaces/user';
import { Notification } from '../../interfaces/notification';
import { Events } from '../../services/events.service';
import { environment } from '../../../environments/environment';
import { NotificationService } from '../../services/notification.service';
import {Router} from '@angular/router';
import {CreateContentService} from '../../services/create-content.service';
import {MediaService} from '../../services/media.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-k-header-desktop',
  templateUrl: './k-header-desktop.component.html',
  styleUrls: ['./k-header-desktop.component.scss'],
})
export class KHeaderDesktopComponent extends KComponent implements OnInit {
  @Input() title: string;
  @Input() homeContent?: IonContent;
  @Output() publicationCreated?: EventEmitter<boolean> = new EventEmitter();

  slideAnimation = slideAnimation;
  user: User;
  notifications: Notification[];
  unread = 0;
  unreadChat = [];


  constructor(private router: Router,
              private modalCtrl: ModalController,
              private authService: AuthService,
              private profileService: ProfileService,
              private events: Events,
              private menuCtrl: MenuController,
              private notificationService: NotificationService,
              private changeDetector: ChangeDetectorRef,
              private createContentService: CreateContentService,
              private mediaService: MediaService,
              private auth: AuthService
  ) {
    super();
    this.title = '';
  }

  get unreadChatNumber() {
    const unreadFiltered = {};
    Object.keys(this.unreadChat).forEach((key, index) => {
      if (!this.unreadChat[key].isRequest) {
        unreadFiltered[key] = this.unreadChat[key];
      }
    });
    return Object.keys(unreadFiltered).length;
  }

  toSearch(): void {
    this.router.navigate(['/search']).catch();
  }

  ngOnInit() {
    this.auth.watchUser()
      .pipe(
        filter(data => !!data)
      )
      .subscribe((user: User) => {
        this.user = user
      });
    
    // this.events.subscribe(environment.EVENTS.USER_UPDATED, () => {
    //   this.user = this.auth.user;
    // });

    this.notificationService.notifications.subscribe(response => {
      this.notifications = response;
      this.changeDetector.detectChanges();
    });
    this.notificationService.unread.subscribe(response => {
      this.unread = response;
      this.changeDetector.detectChanges();
    });
    this.notificationService.unreadChat.subscribe(response => {
      this.unreadChat = response;
      this.changeDetector.detectChanges();
    });

    this.events.subscribe((environment.EVENTS.BACK_BUTTON_PRESSED), async () => {
      await this.menuCtrl.enable(true, 'lateral');
      const opened = this.menuCtrl.isOpen('lateral');
      if (opened) {
        await this.menuCtrl.close('lateral');
      }
    });
  }

  async openLateralMenu() {
    await this.menuCtrl.enable(true, 'lateral');
    const opened = await this.menuCtrl.isOpen('lateral');
    if (opened) {
      await this.menuCtrl.close('lateral');
    } else {
      await this.menuCtrl.open('lateral');
    }
  }

  async openInfoHub() {
    const result = await this.createContentService.openHubInfoModal();
    console.log(result);
  }

  getImgProfile() {
    try {
      return this.mediaService.generateImgProfileURL(this.user?.id, this.user?.imgProfile);
    } catch {
      console.log('error get img profile header');
    }
  }

  goTo(route: string) {
    this.router.navigate([route]);
  }

  scrollToTop() {
    if(!this.homeContent) {return;}
    this.homeContent.scrollToTop(500);
  }


}
