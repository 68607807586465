<ion-grid *ngIf="!skeleton">
  <ion-row>
    <ion-col class="py-0" size="12">
      <div class="container-estrim-one">
        <ion-button
          class="dots"
          *ngIf="showDots && publication?.author.id !== user?.id"
          color="clear"
          (click)="presentBlockPopover($event)"
        >
          <ion-icon src="assets/svg-icon/dots-with-space.svg"></ion-icon>
        </ion-button>
        <div
          *ngIf="publication.isPremium"
          class="locked-content"
          [ngClass]="{
            open:
              publication.collaborating ||
              publication.subscribed ||
              publication.author.id === user?.id
          }"
        >
          <ion-icon
            *ngIf="
              !(
                publication.collaborating ||
                publication.subscribed ||
                publication.author.id === user?.id
              )
            "
            name="lock-closed"
          ></ion-icon>
          <ion-icon
            *ngIf="
              publication.collaborating ||
              publication.subscribed ||
              publication.author.id === user?.id
            "
            name="lock-open"
          ></ion-icon>
        </div>

        <app-video-preview
          (click)="enterPublication()"
          *ngIf="showVideoPreview()"
          [publication]="publication"
        ></app-video-preview>

        <lazy-img
          class="publication-thumbnail"
          alt="image"
          [size]="600"
          (click)="enterPublication()"
          [source]="
            mediaServ.generatePublicationThumbnailImgURL(
              publication.id,
              publication.thumbnail
            )
          "
        ></lazy-img>
      </div>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="12">
      <div
        [routerLink]="getRouteUserProfile(publication.author.username)"
        class="container-profile-center"
        *ngIf="userImage"
      >
        <div class="profile-img" *ngIf="userImage">
          <lazy-img
            [size]="60"
            [source]="
              mediaServ.generateImgProfileURL(
                publication.author.id,
                publication.author.imgProfile
              )
            "
          >
          </lazy-img>
        </div>
        <div class="profile-name" *ngIf="userName">
          <span>{{ publication.author.username }}</span>
          <img
            handleError
            class="founder-badge"
            *ngIf="publication.author.isFounder"
            src="assets/badges/founder-badge.svg"
          />
        </div>
      </div>
      <p class="description-estrim-one" (click)="enterPublication()">
        {{ publication.title }}
      </p>
      <p *ngIf="!showCreatedAt && publication.numViewers > 0" class="views">
        {{ publication.numViewers }}
        {{ publication.numViewers > 1 ? "reproducciones" : "reproducción" }}
      </p>
      <p *ngIf="!showCreatedAt" class="age">
        {{ publication.createdAt | dateAgo }}
      </p>
      <p *ngIf="showCreatedAt" class="age">
        Creado el {{ publication.createdAt | date : "M/d/y" }}
      </p>
    </ion-col>
  </ion-row>
</ion-grid>

<ion-grid *ngIf="skeleton">
  <ion-row>
    <ion-col size="12">
      <div class="container-estrim-one">
        <ion-thumbnail class="publication-thumbnail">
          <ion-skeleton-text [animated]="true"></ion-skeleton-text>
        </ion-thumbnail>
      </div>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="12">
      <div class="container-profile-center">
        <div class="profile-img">
          <ion-thumbnail>
            <ion-skeleton-text [animated]="true"></ion-skeleton-text>
          </ion-thumbnail>
        </div>
        <div class="profile-name">
          <label>
            <ion-skeleton-text
              [animated]="true"
              style="width: 60px; --border-radius: 5px"
            ></ion-skeleton-text>
          </label>
        </div>
      </div>
      <p class="description-estrim-one">
        <ion-skeleton-text
          [animated]="true"
          style="width: 100%; --border-radius: 5px"
        ></ion-skeleton-text>
        <ion-skeleton-text
          [animated]="true"
          style="width: 50%; --border-radius: 5px"
        ></ion-skeleton-text>
      </p>
    </ion-col>
  </ion-row>
</ion-grid>
