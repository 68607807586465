<div class="steps" 
  [ngStyle]="{
    'transition': viewInited ? 'height .3s' : 'none',
    'height': stepsContainerHeight + 'px'
  }">

  <div class="close" (click)="dismiss()">
    <ion-icon name="close-outline"></ion-icon>
  </div>

  <div #step class="step" 
    *ngIf="slideStep === 0" 
    [@inOutAnimation]="animationDirection"
    [@.disabled]="!viewInited">
    <!-- User -->
    <app-user-display [user]="user"></app-user-display>

    <!-- Info -->
    <div class="info">
      <div class="item">
        <div class="icon">
          <img handleError class="badge special subscriber" src="assets/badges/special/subscriber-1x.png" alt="">
        </div>

        <div class="text">
          <div class="intro">
            Insignia de suscriptor
          </div>

          <div class="description">
            Obtén una insignia especial junto a tu nombre de usuario tanto en los comentarios como en los directos de sus publicaciones.
          </div>
        </div>
      </div>

      <div class="item">
        <div class="icon lock">
          <img handleError class="badge special subscriber" src="assets/lock.png" alt="">
        </div>

        <div class="text">
          <div class="intro">
            Contenido exclusivo
          </div>

          <div class="description">
            Acceso a todo el contenido para suscriptores publicado por este usuario.
          </div>
        </div>
      </div>

      <div class="item">
        <div class="icon users">
          <img handleError class="badge special subscriber" src="assets/users.png" alt="">
        </div>

        <div class="text">
          <div class="intro">
            Más interacción
          </div>

          <div class="description">
            Más posibilidades de interacción con este usuario. Participa en sus directos y comenta en los chat exclusivos.
          </div>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="buttons">
      <ion-button color="darkteal" (click)="subscribeToUser()" *ngIf="!loading">
        Suscribirse por <img handleError class="coin" src="assets/coin.png" alt=""> {{user?.subscriptionPrice}}/mes
      </ion-button>

      <div class="loading" *ngIf="loading">
        <ion-spinner name="crescent"></ion-spinner>
      </div>

      <div class="detail">
        Equivale a {{user?.subscriptionPrice}}€/mes. Cancela cuando quieras.
      </div>
    </div>
  </div>

  <div #step class="step step-2" 
    *ngIf="slideStep === 1"
    [@inOutAnimation]="animationDirection"
    [@.disabled]="!viewInited">
    <div class="title">
      Te damos la <span class="special">bienvenida</span> a la suscripción de <span>{{user?.username}}</span>
    </div>

    <div class="subtitle">
      Disfruta de tu nueva insignia de suscriptor y accede al contenido más exclusivo con más posibilidades de interacción.
    </div>

    <!-- User -->
    <div class="user">
      <div class="user-image">
        <img handleError class="badge special subscriber" src="assets/badges/special/subscriber-1x.png" alt="">

        <div class="avatar badge">
          <div class="pro-border" *ngIf="user?.isPro" >
            <div class="image-place"></div>
          </div>

          <lazy-img handleError 
            [ngClass]="{'pro': user?.isPro}"
            [size]="400"
            [source]="getImgProfile()"></lazy-img>
          <app-pro-tag *ngIf="user?.isPro" class="pro-tag"></app-pro-tag>
        </div>
      </div>

      <div class="data">
        <div class="name">
          <span>{{user?.firstname}}</span>
          <img handleError class="founder-badge" *ngIf="user?.isFounder" src="assets/badges/founder-badge.svg">
        </div>
        <div class="username">@{{user?.username}}</div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="buttons">
      <ion-button color="darkteal" (click)="goToProfile()">
        Explora su perfil
      </ion-button>
      <ion-button 
        fill="clear" 
        color="dark"
        class="go-back" 
        (click)="dismiss()">
        Ahora no
      </ion-button>
    </div>
  </div>

</div>