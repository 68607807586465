import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    const rawData = Math.round(value);
    
    const hours = Math.floor(rawData / 3600);
    const minutes = Math.floor(rawData / 60) % 60;
    const seconds = rawData % 60;

    const result = `${hours > 0 ? hours + ':' : ''}${hours > 0 ? ("0" + minutes).slice(-2) : minutes}:${("0" + seconds).slice(-2)}`;

    return !isNaN(rawData) ? result : '';
  }

}
