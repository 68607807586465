<swiper #swiper [config]="swiperParams">
  <ng-template class="new-pro-slide1" swiperSlide>
    <div class="profile-thumb pro-thumb animated-thumb">
      <lazy-img
        class="profile"
        [size]="400"
        [source]="getImgProfile()">
      </lazy-img>
      <app-pro-tag class="pro-tag"></app-pro-tag>
    </div>
    <div class="content gradient-bg">
      <ion-img class="halo" src="assets/popups/halo.png" />
      <div>
        <h2 class="title text-white mt-40px">¡ENHORABUENA @{{user.username}}!</h2>
        <h4 class="text-white mb-3">
          <strong>Ya eres un usuario PRO.</strong>
        </h4>
        <ion-button color="darkteal" (click)="nextSlide()">
          Siguiente
        </ion-button>
      </div>
    </div></ng-template
  >
  <ng-template class="new-pro-slide2" swiperSlide>
    <div class="profile-thumb pro-thumb">
      <lazy-img
        class="profile"
        [size]="400"
        [source]="getImgProfile()">
      </lazy-img>
      <app-pro-tag class="pro-tag"></app-pro-tag>
    </div>
    <div class="content bg-white">
      <div>
        <h4 class="mt-0">
          Una gran experiencia conlleva una gran responsabilidad.
        </h4>
        <p>
          Ahora podrás crear tu propio código de invitación y compartirlo con tu
          audiencia para generar comunidad. Cada persona que entre con tu código
          te seguirá automáticamente.
        </p>
        <ion-button class="mb-1" color="darkteal" (click)="goToInvitations()">
          Crear código
        </ion-button>

        <ion-button color="white" class="finish-button" (click)="close()">
          Cerrar
        </ion-button>
      </div>
    </div></ng-template
  >
</swiper>
<div class="swiper-pagination"></div>
