<ion-item lines="none">
  <ion-label class="ion-text-wrap">
    <lazy-img
      *ngIf="!initialMessage && user"
      [size]="60"
      [source]="getImgProfileSrc()"
      (click)="emitReducedProfile(user)"
    ></lazy-img>

    <ng-container *ngIf="!initialMessage">
      <span class="username" (click)="emitReducedProfile(user)">
      <img handleError class="badge" *ngIf="userLoaded && user?.isFounder" src="assets/badges/founder-badge.svg" alt="">
      <img handleError class="badge special" *ngIf="isCreator" src="assets/badges/special/creator-1x.png" alt="">
      <img handleError class="badge special" *ngIf="!isCreator && isCollaborator"
           src="assets/badges/special/collaborator-1x.png" alt="">
      <img handleError class="badge special" *ngIf="!isCreator && !isCollaborator && isSubscribed"
           src="assets/badges/special/subscriber-1x.png" alt="">
      <img handleError class="badge" *ngIf="userLoaded" [src]="getBadgeSrc()" alt="">{{username}}:
      </span>
      <span *ngIf="!isStar"
            class="msg">{{' ' + msg.data}}</span>

      <span *ngIf="isStar" class="star-div">
        ha enviado
        <span class="quantity">{{starQuantity}} <img handleError src="/assets/star-l.png"/></span>
      </span>
      <span class="like-div mine"
            *ngIf="msg.userLikeData?.length > 0"
            @inOutLike>
        <ion-icon name="heart"></ion-icon>
        <span *ngIf="msg.userLikeData?.length>1">{{msg.userLikeData.length}}</span>
      </span>
      <span *ngIf="isFile" class="file-div" (click)="downloadFile()">
        <ion-icon name="cloud-download-outline"></ion-icon>
        <span>{{getFileName(filename)}}</span>
      </span>
    </ng-container>
    <span *ngIf="initialMessage" class="msg initial">{{' ' + initialMessageText}}</span>
  </ion-label>
</ion-item>
