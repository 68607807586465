import { Component, Input, OnInit } from '@angular/core';
import { IonRouterOutlet } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-back',
  templateUrl: './header-back.component.html',
  styleUrls: ['./header-back.component.scss'],
})
export class HeaderBackComponent implements OnInit {

  @Input() title = '';
  @Input() centered = false;
  @Input() textColor = 'black';

  constructor(private routerOutlet: IonRouterOutlet, private router: Router) {
  }

  ngOnInit() {
  }

  canGoBack() {
    return this.routerOutlet.canGoBack();
  }

  goBack() {
    if (this.canGoBack()) {
      this.routerOutlet.pop();

    } else {
      this.router.navigate(['/'], {replaceUrl: true});
    }
  }

}
