<div class="user">
  <div class="user-image">
    <div class="avatar">
      <div class="pro-border" *ngIf="user?.isPro" >
        <div class="image-place"></div>
      </div>

      <lazy-img
        [ngClass]="{'pro': user?.isPro}"
        [size]="400"
        [source]="getImgProfile()">
      </lazy-img>
      <app-pro-tag *ngIf="user?.isPro" class="pro-tag"></app-pro-tag>
    </div>
  </div>

  <div class="data">
    <div class="name">
      <span>{{user?.nameToShow}}</span>
      <img handleError class="founder-badge" *ngIf="user?.isFounder" src="assets/badges/founder-badge.svg">
    </div>
    <div class="username">@{{user?.username}}</div>
  </div>
</div>