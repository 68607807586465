import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {AppLayoutComponent} from './app-layout.component';
import {KHeaderDesktopModule} from '../../components/k-header-desktop/k-header-desktop.module';
import {SidebarModule} from '../sidebar/sidebar.module';

@NgModule({
  declarations: [
    AppLayoutComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    KHeaderDesktopModule,
    SidebarModule
  ],
  exports: [
    AppLayoutComponent,
  ]
})
export class AppLayoutModule { }
