import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable, finalize, of, shareReplay, tap } from 'rxjs';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  cache: Map<string, HttpEvent<unknown>> = new Map();

  // Queue to store ongoing requests
  queue: Map<string, Observable<HttpEvent<unknown>>> = new Map();

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if(request.method !== 'GET') {
      return next.handle(request);
    }

    // Check if request is currently in queue
    const queued = this.queue.get(request.urlWithParams);
    if(queued) {
      return queued;
    }

    const cached = this.cache.get(request.urlWithParams);
    if(cached) {
      return of(cached);
    }

    const shared = next.handle(request).pipe(
      tap(event => {
        if( event instanceof HttpResponse){
          this.cache.set(request.urlWithParams, event.clone());

          setTimeout(() => {
            this.cache.delete(request.urlWithParams);
          }, 2000)
        }
      }),
      // Delete from queue, since the request has been answered
      finalize(() => this.queue.delete(request.urlWithParams)),
      // Share replay to not cause duplicate subscriptions
      shareReplay()
    );

    // Add request to queue 
    this.queue.set(request.urlWithParams, shared);

    return shared;
  }
}
