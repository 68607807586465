import { Component, Input, OnInit, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { Follower } from '../../../interfaces/follower';
import { MediaService } from '../../../services/media.service';
import { ModalController, ViewWillLeave } from '@ionic/angular';
import { Router } from '@angular/router';
import { Events } from '../../../services/events.service';

@Component({
  selector: 'app-list-followers-to-invite',
  templateUrl: './list-followers-to-invite.page.html',
  styleUrls: ['./list-followers-to-invite.page.scss'],
})
export class ListFollowersToInvitePage implements OnInit, AfterViewChecked, ViewWillLeave {

  @Input() followers: Follower[];
  @Input() collaborators;

  invited = [];

  constructor(
    private mediaService: MediaService,
    private modalCtrl: ModalController,
    private router: Router,
    private events: Events,
    private cdRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.followers.forEach(fol => {
      if (this.collaborators.some(col => col.id === fol.id)) {
        this.invited.push(fol);
      }
    });
  }

  ngAfterViewChecked() {
  }

  ionViewWillLeave() {
    this.close();
  }

  ionViewWillEnter() {
  }
  ionViewDidEnter() {
  }

  dismiss() {
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

  getImg(id, img) {
    return this.mediaService.generateImgProfileURL(id, img);
  }

  invite(fol) {
    if (this.invited.some(f => f.id === fol.id)) {
      this.invited = this.invited.filter(f => f.id !== fol.id);
    } else {
      this.invited.push(fol);
      console.log('invitado');
    }
  }

  close() {
    this.modalCtrl.dismiss({
      invited: this.invited
    });
  }

  goToProfile(user: { username: string }) {
    if (!user) {return;}
    this.router.navigate(['/', 'user', user.username]);
  }

  getUserImg( user: { imgProfile: string; id: number } ) {
    return this.mediaService.generateImgProfileURL(user?.id, user?.imgProfile);
  }
}
