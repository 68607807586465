import { AnimationController, Animation } from '@ionic/angular';

export const slideAnimation = (baseEl: any, opts?: any): Animation => {
  const DURATION = 300;
  const animationCtrl = new AnimationController();

  if (opts.direction === 'forward') {
    const rootAnimation = animationCtrl.create()
      .addElement(opts.enteringEl)
      .duration(DURATION)
      .easing('ease')
      .fromTo('transform', 'translateX(100%)', 'translateX(0%)')
      .fromTo('opacity', '1', '1');

    const leavingAnimation = animationCtrl.create()
      .addElement(opts.leavingEl)
      .duration(DURATION)
      .easing('ease')
      .fromTo('transform', 'translateX(0%)', 'translateX(-100%)');

    return animationCtrl.create().addAnimation([rootAnimation, leavingAnimation]);
  } else {
    const rootAnimation = animationCtrl.create()
      .addElement(opts.enteringEl)
      .duration(DURATION)
      .easing('ease')
      .fromTo('transform', 'translateX(-100%)', 'translateX(0%)')
      .fromTo('opacity', '1', '1');

    const leavingAnimation = animationCtrl.create()
      .addElement(opts.leavingEl)
      .duration(DURATION)
      .easing('ease')
      .fromTo('transform', 'translateX(0%)', 'translateX(100%)');

    return animationCtrl.create().addAnimation([rootAnimation, leavingAnimation]);
  }
};
