<ion-header class="general-header-app">
  <app-header 
    [title]="mode === FormMode.Edit ? 'Editar publicación' : 'Publicación nueva'" 
    [menuButton]="false"
    [defaultBackAction]="false"
    (back)="goBack()">    
  </app-header>
</ion-header>

<ion-content #content>

  <!-- 
    -- Content 
    -->

  <!-- Video -->
  <div class="field video" *ngIf="(type === ContentType.Video || type === ContentType.Clip) && fieldAvailable()">
    <div class="title" *ngIf="type === ContentType.Clip && mode === FormMode.Edit">Portada</div>
    
    <div class="content" (click)="pickPoster()">

      <ion-thumbnail 
        class="thumbnail"
        [ngClass]="{'clip': type === ContentType.Clip}">
        <img 
          *ngIf="!poster && posterSrc"
          handleError 
          thumbnail 
          [size]="600" 
          [source]="asString(posterSrc)" />
        <img 
          *ngIf="poster || !(!poster && posterSrc)"
          handleError 
          [src]="posterSrc" />
      </ion-thumbnail>

      <ion-button class="choose-file" fill="clear">
        <ion-icon *ngIf="type === ContentType.Video" src="assets/svg-icon/new-version/Outline_Video.svg"></ion-icon>
        <ion-icon *ngIf="type === ContentType.Clip" src="assets/svg-icon/new-version/Outline_Clips.svg"></ion-icon>
      </ion-button>
    </div>

    <ion-text class="field-error" *ngIf="error.poster" color="danger">
      <small>
        <ion-icon name="alert-circle-outline"></ion-icon>
        <span>Debes seleccionar una imagen de portada</span>
      </small>
    </ion-text>
  </div>

  <!-- Image -->


  <!-- Podcast -->
  <div class="field audio" *ngIf="type === ContentType.Audio && fieldAvailable()">
    <div class="content" (click)="pickPoster()">

      <ion-thumbnail class="thumbnail">
        <img 
          *ngIf="!poster && posterSrc"
          handleError 
          thumbnail 
          [size]="600" 
          [source]="asString(posterSrc)" />
        <img 
          *ngIf="poster || !(!poster && posterSrc)"
          handleError 
          [src]="posterSrc" />
      </ion-thumbnail>

      <ion-button class="choose-file" fill="clear">
        <ion-icon src="assets/svg-icon/new-version/Outline_Podcast.svg"></ion-icon>
      </ion-button>
    </div>

    <ion-text class="field-error" *ngIf="error.poster" color="danger">
      <small>
        <ion-icon name="alert-circle-outline"></ion-icon>
        <span>Debes seleccionar una imagen de portada</span>
      </small>
    </ion-text>
  </div>

  <!-- Title -->
  <div class="field less-margin input">
    <div class="content">
      <ion-input
        required
        placeholder="Introduce un título"
        [disabled]="uploading"
        [(ngModel)]="title"
        (ionFocus)="inputFocused($event)"
        (ionChange)="error.title = false">
      </ion-input>

      <ion-text class="field-error" *ngIf="error.title" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>Debes introducir un título</span>
        </small>
      </ion-text>
    </div>
  </div>

  <!-- Description -->
  <div class="field description input">
    <div class="content">
      <ion-textarea
        required
        rows="1"
        maxlength="10000"
        placeholder="Introduce una descripción"
        [disabled]="uploading"
        [autoGrow]="true"
        [(ngModel)]="description"
        (ionFocus)="inputFocused($event)"
        (ionChange)="error.description = false">
      </ion-textarea>

      <ion-text class="field-error" *ngIf="error.description" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>Debes introducir una descripción</span>
        </small>
      </ion-text>
    </div>
  </div>

  <!-- Visibility -->
  <div class="field visibility select" *ngIf="fieldAvailable()">
    <div class="title">Elige una sección</div>

    <div class="content">
      <ion-select
        mode="md"
        ok-text="Seleccionar"
        cancel-text="Cancelar"
        placeholder="Elegir"
        [disabled]="uploading"
        [(ngModel)]="saveIn"
        (ionChange)="onVisibilityChange()">
        <ion-select-option value="inicio">Público</ion-select-option>
        <ion-select-option value="suscriptores">Solo suscriptores</ion-select-option>
        <ion-select-option value="one">One</ion-select-option>
      </ion-select>

      <ion-text class="field-error" *ngIf="error.saveIn" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>Debes elegir la sección</span>
        </small>
      </ion-text>
    </div>

    <div class="info" *ngIf="saveIn === 'inicio'">
      Todos los usuarios podrán reproducir este 
      <span *ngIf="type === ContentType.Video">vídeo</span>
      <span *ngIf="type === ContentType.Clip">clip</span>
      <span *ngIf="type === ContentType.Audio">podcast</span>
    </div>
    <div class="info" *ngIf="saveIn === 'suscriptores'">
      Solo tus suscriptores podrán reproducir este 
      <span *ngIf="type === ContentType.Video">vídeo</span>
      <span *ngIf="type === ContentType.Clip">clip</span>
      <span *ngIf="type === ContentType.Audio">podcast</span>
    </div>
    <div class="info" *ngIf="saveIn === 'one'">
      Pon un precio al 
      <span *ngIf="type === ContentType.Video">vídeo</span>
      <span *ngIf="type === ContentType.Clip">clip</span>
      <span *ngIf="type === ContentType.Audio">podcast</span> 
      para que pueda ser reproducido
    </div>
  </div>

  <!-- Price -->
  <div class="field price input" *ngIf="saveIn === 'one'">
    <div class="title">Importe <ion-icon class="coin" src="assets/coin.svg"></ion-icon></div>

    <div class="content">
      <ion-input
        only-numeric
        min="0"
        required
        type="tel"
        placeholder="Introduce cantidad"
        [disabled]="uploading"
        [(ngModel)]="price"
        (ionFocus)="inputFocused($event)"
        (ionChange)="error.price = false">
      </ion-input>
      <div class="equals" *ngIf="saveIn == 'one'">
        Equivale a
        <span *ngIf="price !== null && price !== undefined">{{(price) | number:'1.0-0'}}</span>
        <span *ngIf="price === null || price === undefined">-</span>€
      </div>

      <ion-text class="field-error" *ngIf="price < CONTENT_MIN_PRICE" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>El importe debe ser mayor o igual que {{ CONTENT_MIN_PRICE }}</span>
        </small>
      </ion-text>
      <ion-text class="field-error" *ngIf="price > CONTENT_MAX_PRICE" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>El importe no puede ser superior a {{ CONTENT_MAX_PRICE }}</span>
        </small>
      </ion-text>
      <ion-text class="field-error" *ngIf="error.price && !(price < CONTENT_MIN_PRICE || price > CONTENT_MAX_PRICE)" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>El precio es incorrecto</span>
        </small>
      </ion-text>
    </div>
  </div>

  <!-- Categories -->
  <div class="field category select" *ngIf="fieldAvailable()">
    <div class="title">Selecciona una categoría</div>

    <div class="content" (click)="pickCategory()">
      <ion-select
        mode="md"
        placeholder="Seleccionar"
        [disabled]="uploading"
        [(ngModel)]="category"
        (ionChange)="error.category = false">
        <ion-select-option *ngFor="let category of categories" [value]="category.id">
          {{category.name}}
        </ion-select-option>
      </ion-select>

      <ion-text class="field-error" *ngIf="error.category" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>Debes seleccionar una categoría</span>
        </small>
      </ion-text>
    </div>
  </div>

  <!-- Preview -->
  <div class="field preview" *ngIf="saveIn === 'one'">
    <div class="title">
      Preview

      <ion-button 
        id="preview-info-modal"
        class="info-button"
        fill="clear">
        <ion-icon class="info-icon" name="information-circle-outline"></ion-icon>
      </ion-button>

      <ion-modal #modal trigger="preview-info-modal" class="fit-modal info-modal">
        <ng-template>
          <div class="inner">
            <ion-button class="close" fill="clear" (click)="modal.dismiss()">
              <ion-icon name="close-outline"></ion-icon>
            </ion-button>

            <div class="icon">
              <ion-icon name="information-circle-outline"></ion-icon>
            </div>

            <div class="message">
              La preview puede ser tanto un archivo de vídeo como de audio
            </div>
          </div>
        </ng-template>
      </ion-modal>
    </div>

    <div class="content">
      <ion-button class="upload-preview" fill="clear" (click)="pickPreview()" *ngIf="!previewSrc">
        <ion-icon name="eye-outline"></ion-icon>
      </ion-button>

      <div class="file" *ngIf="previewSrc">
        <app-video-player
          *ngIf="previewType === ContentType.Video"
          [videoSrc]="previewSrc"
          [canMaximize]="false">
        </app-video-player>
        <app-audio-player
          *ngIf="previewType === ContentType.Audio"
          [media]="previewSrc">
        </app-audio-player>
        <ion-button fill="clear" class="change-button" (click)="pickPreview()">
          <ion-icon name="repeat-outline"></ion-icon>
        </ion-button>
      </div>
    </div>
  </div>

  <div class="submit-button">
    <ion-button
      fill="clear"
      [disabled]="uploading"
      (click)="submitForm()">
      <span>{{ mode === FormMode.Edit ? 'Guardar' : 'Compartir' }}</span>
      <ion-spinner color="white" *ngIf="uploading" name="crescent"></ion-spinner>
      <div class="progress"
        *ngIf="uploading"
        [ngStyle]="{'width': fileProgress + '%'}">
      </div>
    </ion-button>
  </div>

  <div class="uploading-message" *ngIf="uploading" @inOutFade>
    <span>Se está subiendo tu contenido, mientras tanto, puedes seguir navegando por la APP. <b>No cierres la aplicación hasta que la subida se haya completado</b></span>
  </div>

  <div class="next-button submit-button" *ngIf="uploading" @inOutFade>
    <ion-button
      color="light"
      (click)="goBack()">
      <span>Seguir</span>
    </ion-button>
  </div>

  <div class="keyboard-space" [ngStyle]="{'height': keyboardHeight + 'px'}"></div>

  <div class="ios-bottom-safe-area"></div>
</ion-content>
