import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import {Router} from '@angular/router';

@Component({
  selector: 'app-web-footer',
  templateUrl: './web-footer.component.html',
  styleUrls: ['./web-footer.component.scss'],
})
export class WebFooterComponent implements OnInit {

  constructor(private platform: Platform, private router: Router) {
  }

  ngOnInit() {
  }

  isWeb() {
    return !this.platform.is('hybrid');
  }

  goTo(route: string) {
    this.router.navigate([route]);
  }

}
