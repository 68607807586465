import {Component, Input, OnInit} from '@angular/core';
import {KComponent} from '../k.component';

@Component({
    selector: 'app-scroll-top-button',
    templateUrl: './scroll-top-button.component.html',
    styleUrls: ['./scroll-top-button.component.scss'],
})
export class ScrollTopButtonComponent extends KComponent implements OnInit {
    @Input() slug: string;

    constructor() {
        super();
        this.slug = '';
    }

    ngOnInit() {
    }

    public scrollToTop() {
        let id = 'top-page';
        if (this.slug !== '') {
            id += '-' + this.slug;
        }

        const elem = document.getElementById(id);
        if (elem) {
            elem.scrollIntoView({block: 'end', behavior: 'smooth'});
        }
    }

}
