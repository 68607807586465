<ion-header class="general-header-app">
  <app-header 
    [title]="'Filtros'" 
    [menuButton]="false"
    [defaultBackAction]="false"
    (back)="dismiss()">    
  </app-header>
</ion-header>

<ion-content>
  <div class="container">
    <div class="section">
      <div class="title">Fecha</div>
      <div class="content date">
        <div class="buttons">
          <ion-button 
            class="item button-only filters" 
            fill="clear"
            (click)="setShortcut(ShortcutRange.Today)"
            [ngClass]="{'active': filters.shortcutRange === ShortcutRange.Today}"
          >
            <span class="name">Hoy</span>
          </ion-button>
          
          <ion-button 
            class="item button-only filters" 
            fill="clear"
            (click)="setShortcut(ShortcutRange.Tomorrow)"
            [ngClass]="{'active': filters.shortcutRange === ShortcutRange.Tomorrow}"
          >
            <span class="name">Mañana</span>
          </ion-button>
          
          <ion-button 
            class="item button-only filters" 
            fill="clear"
            (click)="setShortcut(ShortcutRange.ThisWeek)"
            [ngClass]="{'active': filters.shortcutRange === ShortcutRange.ThisWeek}"
          >
            <span class="name">Esta semana</span>
          </ion-button>
          
          <ion-button 
            class="item button-only filters" 
            fill="clear"
            (click)="setShortcut(ShortcutRange.ThisMonth)"
            [ngClass]="{'active': filters.shortcutRange === ShortcutRange.ThisMonth}"
          >
            <span class="name">Este mes</span>
          </ion-button>
        </div>

        <div class="dates">
          <div 
            class="button from" 
            (click)="showDates()"
            [ngClass]="{'active': filters.startDate}"
          >
            Desde <span *ngIf="filters.startDate">{{formatDate(filters.startDate)}}</span>
          </div>
          <div 
            class="button to" 
            (click)="showDates()"
            [ngClass]="{'active': filters.endDate}"
          >
            Hasta <span *ngIf="filters.endDate">{{formatDate(filters.endDate)}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="title">
        Categoría
        <span class="seeAll" (click)="showCategories()">Ver todo</span>
      </div>
      <div class="content categories">
        <app-category-button
          class="category-button"
          *ngFor="let category of categories | slice:0:6"
          [category]="category"
          [active]="filters.categories?.includes(category.id)"
          (click)="toggleCategory(category)"
        >
        </app-category-button>
      </div>
    </div>

    <div class="section">
      <div class="title">
        Precio
        <span class="toggle">
          Solo gratis
          <ion-toggle
            #subscriptionToggle
            mode="ios"
            [(ngModel)]="filters.free"
            (ionChange)="freeToggleChange($event)">
          </ion-toggle>
        </span>
      </div>
      <div class="content price">
        <div class="range-container">
          <div class="range-bar-container">
            <div class="range-star-pin"
              [ngStyle]="{left: pinLeftPosition}">
              <ng-container *ngIf="priceBarValue !== 0">
                Hasta {{floor(priceBarValue)}}€
              </ng-container>
              <ng-container *ngIf="priceBarValue === 0">
                Cualquiera
              </ng-container>
            </div>
            <ion-range
              min="0" [max]="100"
              step="1"
              dualKnobs="false" 
              pin="false"
              mode="ios"
              snaps="true"
              fill="clear"
              ticks="true" 
              [(ngModel)]="priceBar2"
              (ionInput)="changePriceValue($event)">
            </ion-range>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="title">
        Tipo
      </div>
      <div class="content type">
        <div class="types">
          <div 
            class="button video" 
            (click)="setEventType(ContentType.Video)"
            [ngClass]="{'active': filters.contentType === ContentType.Video}"
          >
            <ion-icon src="assets/svg-icon/new-version/Outline_Video.svg"></ion-icon>
            <span>Eventos Vídeo</span>
          </div>
          <div 
            class="button audio" 
            (click)="setEventType(ContentType.Audio)"
            [ngClass]="{'active': filters.contentType === ContentType.Audio}"
          >
            <ion-icon src="assets/svg-icon/new-version/Outline_Podcast.svg"></ion-icon>
            <span>Eventos Audio</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>