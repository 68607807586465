import { Component, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Platform, IonContent, IonRefresher } from '@ionic/angular';

import { Keyboard } from '@capacitor/keyboard';

@Component({
  template: ''
})
export abstract class BasePage implements OnDestroy {

  @ViewChild('content') protected content: IonContent;

  keyboardHeight: number;

  sub: any = {};
  listener: any = {};

  constructor(
    protected platform: Platform,
    protected cdRef: ChangeDetectorRef
  ) {

    if (this.platform.is('hybrid')) {

      /*
       * Handle keyboard events
       */

      this.listener.keyboardWillShow = Keyboard.addListener('keyboardWillShow', info => {
        const safeSpace = document.querySelector('.ios-bottom-safe-area');

        // @ts-ignore
        this.keyboardHeight = info.keyboardHeight + (safeSpace ? safeSpace.offsetHeight : 0);
        this.cdRef.detectChanges();
      });

      this.listener.keyboardWillHide = Keyboard.addListener('keyboardWillHide', () => {
        this.keyboardHeight = 0;
        this.cdRef.detectChanges();
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribeAll();
    this.removeAllListeners();
  }

  protected asString(value: any): string { return value; }

  protected inputFocused($event) {
    const inputDOMRect = $event.target.getBoundingClientRect();
    const screenHeight = window.innerHeight;

    const middleDifference = (screenHeight / 2) - (inputDOMRect.top + inputDOMRect.height);

    if (middleDifference < 0) {
      this.content.scrollByPoint(undefined, Math.abs(middleDifference), 200);
    }
  }

  protected unsubscribeAll() {
    Object.keys(this.sub).forEach(key => {
      this.sub[key]?.unsubscribe();
    });
  }

  protected removeAllListeners() {
    Object.keys(this.listener).forEach(key => {
      this.listener[key]?.remove();
    });
  }

  protected async completeRefresher(refresher: IonRefresher) {
    const progress = await refresher?.getProgress();

    if ( progress > 0 ) {
      refresher?.complete();
    }
  }
}
