import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {FeedService} from '../../services/feed.service';

import {  ModalController } from '@ionic/angular';

import { PollComponent } from 'src/app/pages/extrim/poll/poll.component';

import { ExtrimService } from 'src/app/services/extrim.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent  implements OnInit {

  loadingExtrimRacePool: boolean = false;

  constructor( 
    private feedService: FeedService, 
    public modalCtrl: ModalController,
    private cdRef: ChangeDetectorRef,
    private extrimRaceService: ExtrimService,
  ) { }
  

  ngOnInit() {
    // this.feedService.loadHomeContent([], 0, false);
  }

  async launchExtrimRacePoll() {
    this.loadingExtrimRacePool = true;
    this.cdRef.detectChanges();

    try {
      await this.extrimRaceService.getRanking().toPromise();
    } catch (e) {}

    this.loadingExtrimRacePool = false;
    this.cdRef.detectChanges();

    const modal = await this.modalCtrl.create({
      cssClass: "fullscreen-modal",
      component: PollComponent,
      mode: "ios",
    })

    modal.present();
  }

}
