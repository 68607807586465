<div class="title">{{monthName}}</div>
<div class="month">
  <div class="empty" *ngFor="let space of empty"></div>
  <div class="day"
    *ngFor="let day of days; let i = index"
    [ngClass]="{
      'disabled': day?.disabled,
      'selected': day?.selected,
      'start': day?.start && endDate,
      'end': day?.end,
      'between': day?.between,
    }"
    (click)="selectDay(i + 1)"
  >
    {{ i + 1 }}
  </div>
</div>