<ion-grid
  *ngIf="!skeleton"
  (click)="enterPublication()" 
  [ngClass]="{'passed': eventPassed}">
  <ion-row>
    <ion-col size="12">
      <ion-card class="container-event"
                [ngClass]="{'proximos-estrenos': premieres}">
        <div class="event-image">
          <ion-button class="dots" 
            *ngIf="showDots && event?.author.id !== user?.id" 
            color="clear" 
            (click)="presentBlockPopover($event)">
            <ion-icon src="assets/svg-icon/dots-with-space.svg"></ion-icon>
          </ion-button>

          <lazy-img 
            class="image"
            alt="image"
            [size]="600"
            [source]="mediaServ.generateEventThumbnailImgURL(event.id,event.thumbnail)"></lazy-img>

        </div>
        <ion-card-content [ngClass]="{'bigger': biggerFont}">
          <div class="data">
            <div class="title-event">{{event.title}}</div>
            <div class="event-profile" *ngIf="userName">
              <span>{{event.author.username}}</span>
              <img handleError 
                class="founder-badge" 
                *ngIf="event.author.isFounder" 
                src="assets/badges/founder-badge.svg">
            </div>
          </div>
          <ul class="option-event">
            <li>
              <!-- <ion-icon *ngIf="!event.isVideo" style="font-size:22px" name="mic-outline"></ion-icon> -->
              <div class="svg-icon mic" *ngIf="!event.isVideo">
                <ion-icon
                  src="assets/svg-icon/new-version/Outline_Podcast.svg"
                  alt="icon"
                ></ion-icon>
                <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96">
                  <defs>
                    <style>.cls-1 {
                    }

                    .cls-2 {
                      fill: none;
                      stroke-miterlimit: 10;
                      stroke-width: 4px;
                    }</style>
                  </defs>
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Capa_1" data-name="Capa 1">
                      <rect class="cls-1" width="96" height="96"/>
                      <g id="Mic">
                        <path class="cls-2"
                              d="M48.27,56.82h0a8.56,8.56,0,0,1-8.56-8.56V31.14a8.56,8.56,0,0,1,8.56-8.56h0a8.56,8.56,0,0,1,8.56,8.56V48.26A8.56,8.56,0,0,1,48.27,56.82Z"/>
                        <line class="cls-2" x1="48.27" y1="73.94" x2="48.27" y2="65.38"/>
                        <path class="cls-2" d="M65.39,48.26a17.12,17.12,0,0,1-34.24,0"/>
                      </g>
                    </g>
                  </g>
                </svg> -->
              </div>
              <div class="svg-icon camera" *ngIf="event.isVideo">
                <ion-icon
                  class="camera"
                  src="assets/svg-icon/new-version/Outline_Video.svg"
                  alt="icon"
                ></ion-icon>
                <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54">
                  <defs>
                    <style>.cls-1 {
                      stroke-miterlimit: 10;
                    }

                    .cls-2 {
                      fill: none;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      stroke-width: 3px;
                    }</style>
                  </defs>
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <rect class="cls-1" x="0.5" y="0.5" width="53" height="53"/>
                      <g id="Video_Recorder" data-name="Video Recorder">
                        <rect class="cls-2" x="11.1" y="17.03" width="21.64" height="21.64" rx="6.58"/>
                        <polyline class="cls-2" points="32.73 22.02 42.72 18.7 42.72 37 32.73 33.67"/>
                      </g>
                    </g>
                  </g>
                </svg> -->
              </div>
              <span>{{event.isVideo ? 'Vídeo' : 'Audio'}}</span>
            </li>
            <li>
              <div class="svg-icon calendar">
                <ion-icon
                  class="calendar"
                  src="assets/svg-icon/new-version/Outline_Eventos.svg"
                  alt="icon"
                ></ion-icon>
                <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54">
                  <defs>
                    <style>.cls-1 {
                      stroke-miterlimit: 10;
                    }

                    .cls-2 {
                      fill: none;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      stroke-width: 2.41px;
                    }</style>
                  </defs>
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <rect class="cls-1" x="0.5" y="0.5" width="53" height="53"/>
                      <g id="Calendar_Appointment" data-name="Calendar, Appointment">
                        <rect class="cls-2" x="12.57" y="13.51" width="29" height="29" rx="4"/>
                        <line class="cls-2" x1="12.57" y1="24.39" x2="41.57" y2="24.39"/>
                        <line class="cls-2" x1="23.45" y1="18.95" x2="30.7" y2="18.95"/>
                        <line class="cls-2" x1="32.51" y1="24.39" x2="32.51" y2="42.51"/>
                        <line class="cls-2" x1="21.64" y1="24.39" x2="21.64" y2="42.51"/>
                        <line class="cls-2" x1="12.57" y1="33.45" x2="41.57" y2="33.45"/>
                      </g>
                    </g>
                  </g>
                </svg> -->
              </div>
              <span>{{event.eventDateStart.date.toString().replace(' ', 'T') | date:'dd/MM/yy \| HH:mm\'h\''}}</span>
            </li>
            <li>
              <div class="img-icon">
                <ion-thumbnail class="icon-coin">
                  <img handleError src="assets/coin.svg">
                </ion-thumbnail>
              </div>
              <span>{{event.eventPrice === 0 ? 'Gratuito' : event.eventPrice}}</span>
            </li>
          </ul>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
</ion-grid>

<ion-grid *ngIf="skeleton">
  <ion-row>
    <ion-col size="12">
      <ion-card class="container-event" [ngClass]="{'proximos-estrenos': premieres}">
        <div class="event-image">
          <ion-thumbnail>
            <ion-skeleton-text [animated]="animatedSkeleton" style="--border-radius: 10px 10px 0 0;"></ion-skeleton-text>
          </ion-thumbnail>
        </div>
        <ion-card-content>
          <div class="data">
            <div class="title-event">
              <ion-skeleton-text [animated]="animatedSkeleton" style="width: 100%; --border-radius: 5px;"></ion-skeleton-text>
              <ion-skeleton-text [animated]="animatedSkeleton" style="width: 100%; --border-radius: 5px;"></ion-skeleton-text>
              <ion-skeleton-text [animated]="animatedSkeleton" style="width: 50%; --border-radius: 5px;"></ion-skeleton-text>
            </div>
            <div class="event-profile">
              <ion-skeleton-text [animated]="animatedSkeleton" style="width: 70px; --border-radius: 5px;"></ion-skeleton-text>
            </div>
          </div>
          <ul class="option-event">
            <li>
              <ion-skeleton-text [animated]="animatedSkeleton"
                                 style="width: 40%; height:  fit-content; --border-radius: 5px;"></ion-skeleton-text>
            </li>
            <li>
              <ion-skeleton-text [animated]="animatedSkeleton"
                                 style="width: 70%; height:  fit-content; --border-radius: 5px;"></ion-skeleton-text>
            </li>
            <li>
              <ion-skeleton-text [animated]="animatedSkeleton"
                                 style="width: 45%; height:  fit-content; --border-radius: 5px;"></ion-skeleton-text>
            </li>
          </ul>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
</ion-grid>
