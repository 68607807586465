import { Component, OnInit, ViewChild } from '@angular/core';
import {SwiperOptions} from 'swiper';
import {SwiperComponent} from 'swiper/angular';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { skipWhile, take } from 'rxjs/operators';

import { AuthService } from 'src/app/services/auth.service';
import { MediaService } from 'src/app/services/media.service';

import { User } from 'src/app/interfaces/user';

@Component({
  selector: 'app-new-pro-user-popup',
  templateUrl: './new-pro-user-popup.component.html',
  styleUrls: ['./new-pro-user-popup.component.scss'],
})
export class NewProUserPopupComponent implements OnInit {

  @ViewChild('swiper', { static: true }) swiper: SwiperComponent;

  user: User;

  swiperParams: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 30,
    simulateTouch: false,
    //pagination: {clickable: true},
    pagination: {
      el: '.swiper-pagination',
    },
  };

  constructor(
    private authService: AuthService,
    private modalCtrl: ModalController,
    private router: Router,
    private mediaService: MediaService
  ) { }

  ngOnInit(): void {
    if ( !this.user ) {
      this.authService.watchUser()
      .pipe(
        skipWhile(data => !data),
        take(1))
      .subscribe((user: User) => {
        this.user = user;
      });
    }
  }

  getImgProfile() {
    return this.mediaService.generateImgProfileURL(this.user?.id, this.user?.imgProfile);
  }

  nextSlide() {
    this.swiper.swiperRef.slideNext();
  }

  close() {
    this.modalCtrl.dismiss();
  }

  goToInvitations() {
    this.modalCtrl.dismiss().finally(() => {
      this.router.navigate(['/invitations']);
    });
  }
}
