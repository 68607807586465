import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {PipesModule} from '../../pipes/pipes.module';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DirectivesModule} from '../../directives/directives.module';
import {LazyImgModule} from '../lazy-img/lazy-img.module';
import {UserRecommendationThumbnailComponent} from './user-recommendation-thumbnail.component';
import {ProTagModuleModule} from '../pro-tag/pro-tag-module.module';


@NgModule({
  declarations: [
    UserRecommendationThumbnailComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    RouterModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    DirectivesModule,
    LazyImgModule,
    ProTagModuleModule
  ],
  exports: [
    UserRecommendationThumbnailComponent
  ]
})
export class UserRecommendationModule { }
