import { SlugifyPipe } from '../pipes/slugify.pipe';
import { ModalController } from '@ionic/angular';
import { Capacitor, Plugins } from '@capacitor/core';
import { environment } from '../../environments/environment';
import { AbstractControl } from '@angular/forms';

const {Share, Browser} = Plugins;

export class KComponent {
  public baseUrl: string;
  public capacitorShareSupported: boolean;
  public appRoutes: any;

  constructor() {
    this.baseUrl = environment.BASE_URL;
    this.capacitorShareSupported = true;
    this.capacitorShareSupported = Capacitor.getPlatform() !== 'web';
    this.appRoutes = environment.APP_ROUTES;
  }

  public async share($title: string, tailUrl: string, useTitleAsAnchor: boolean = false) {
    let anchor = '';
    if (useTitleAsAnchor) {
      anchor = '#' + new SlugifyPipe().transform($title);
    }
    await Share.share({
      title: $title,
      url: this.fullUrl(tailUrl, anchor),
    }).catch(err => {
      console.error(err);
    });
  }

  public shareURL(where: string, title: string, url: string) {
    title = encodeURIComponent(title);
    switch (where) {
      case 'facebook':
        this.openLink(`https://www.facebook.com/share.php?u=${url}&title=${title}`);
        break;
      case 'twitter':
        this.openLink(`https://twitter.com/intent/tweet?url=${url}&title=${title}`);
        break;
      case 'linkedin':
        this.openLink(`https://www.linkedin.com/sharing/share-offsite/?url=${url}&title=${title}`);
        break;
      case 'whatsapp':
        const str = title + '%3A%20' + url;
        this.openLink(`https://api.whatsapp.com/send/?phone&text=${str}`);
        break;
    }
  }

  public shareIn(where: string, title: string, tailUrl: string) {
    this.shareAnchor(tailUrl, where, title, false);
  }

  public shareAnchor(tailUrl: string, where: string, title: string, useTitleAsAnchor: boolean = true) {
    let anchor = '';
    if (useTitleAsAnchor) {
      anchor = '#' + new SlugifyPipe().transform(title);
    }

    const url = this.fullUrl(tailUrl, encodeURIComponent(anchor));
    title = encodeURIComponent(title);
    switch (where) {
      case 'facebook':
        this.openLink(`https://www.facebook.com/share.php?u=${url}&title=${title}`);
        break;
      case 'twitter':
        this.openLink(`https://twitter.com/intent/tweet?url=${url}&title=${title}`);
        break;
      case 'linkedin':
        this.openLink(`https://www.linkedin.com/sharing/share-offsite/?url=${url}&title=${title}`);
        break;
      case 'whatsapp':
        const str = title + '%3A%20' + url;
        this.openLink(`https://api.whatsapp.com/send/?phone&text=${str}`);
        break;
    }
  }


  public goToAnchor(elem, id) {
    document.getElementById(id).scrollIntoView({block: 'start', behavior: 'smooth'});
  }

  protected async showModal(modalCtrl: ModalController, modalPage: any, data: any, $cssClass: string = '') {
    const modal = await modalCtrl.create({
      component: modalPage,
      componentProps: data,
      cssClass: $cssClass
    });
    return await modal.present();
  }


  private openLink($url: string) {
    /*  if (window) {
          window.open($url, '_blank');
      } else { */
    Browser.open({url: $url});
    // }
  }

  private fullUrl(tailUrl: string, anchor: string = '') {
    return this.baseUrl + tailUrl + anchor;
  }


}
