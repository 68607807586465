import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { trigger, transition, animate, style } from '@angular/animations';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  animations: [
    trigger('inOutSelection', [
      transition(':enter', [
        style({transform: 'translateY(120%)'}),
        animate('600ms cubic-bezier(.1, .7, .1, 1)', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('600ms cubic-bezier(.1, .7, .1, 1)', style({transform: 'translateY(120%)'}))
      ])
    ]),
  ]
})
export class CalendarComponent  implements OnInit {

  @Input() startDate: moment.Moment | undefined = undefined;
  @Input() endDate: moment.Moment | undefined = undefined;

  @Output() datesSelected = new EventEmitter<{startDate: moment.Moment, endDate: moment.Moment}>();

  currentDate = new Date();
  months: any[] = [];

  constructor() { }

  ngOnInit() {
    for (let i = 0; i < 6; i++) {
      this.months.push(moment(this.currentDate).add(i, 'months'));
    }
  }

  onDaySelected($event: moment.Moment) {
    if ( !this.startDate ) {
      this.startDate = $event;
    } else if ( !this.endDate && $event.isAfter(this.startDate) ) {
      this.endDate = $event;
    } else {
      this.startDate = $event;
      this.endDate = undefined;
    }
  }

  formatDate(date: moment.Moment): string {
    return date.format('DD MMMM');
  }

  done() {
    this.datesSelected.emit({startDate: this.startDate!, endDate: this.endDate!});
  }
}
