<!-- Title -->
<div class="title">
  Bienvenid@<br>a Estrim
</div>

<!-- Hub -->
<div class="hub">
  <img handleError 
    src="/assets/welcome-hub.png">

  <div class="user-image">
    <div class="avatar">
      <lazy-img
        [size]="400" 
        [source]="getImgProfile()">
      </lazy-img>
    </div>
  </div>
</div>

<!-- Subtitle -->
<div class="subtitle">Crea, conecta, comparte<br>simplifica y disfruta.</div>

<!-- Buttons -->
<div class="buttons">
  <ion-button color="darkteal" (click)="close()">
    ¡Empezar!
  </ion-button>
</div>
