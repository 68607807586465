import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';

import { StatusbarService } from 'src/app/services/statusbar.service';

@Component({
  selector: 'app-estrimfest-popup',
  templateUrl: './estrimfest-popup.component.html',
  styleUrls: ['./estrimfest-popup.component.scss'],
})
export class EstrimfestPopupComponent  implements OnInit {

  @Input() applicable: boolean = false;

  constructor( 
    private modalCtrl: ModalController,
    private router: Router,
    private platform: Platform,
    private statusbar: StatusbarService
  ) { }

  ngOnInit() {
    if ( this.platform.is('ios') ) 
      this.statusbar.setTextLight();
  }

  ngOnDestroy(): void {
    if ( this.platform.is('ios') ) 
      this.statusbar.setTextDark();
  }

  goToInvitations() {
    this.modalCtrl.dismiss(null, 'confirm').finally(() => {
      this.router.navigate(['/invitations']);
    });
  }

  dismiss() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }
}
