import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {ModalController} from '@ionic/angular';
import {environment} from '../../environments/environment';
import {Category} from '../interfaces/category';
import {Publication} from '../interfaces/publication';
import {User} from '../interfaces/user';
import {Directory, Filesystem} from '@capacitor/filesystem';
import {CapacitorHttp, HttpResponse, HttpResponseType} from '@capacitor/core';

import {CropImagePage} from '../pages/crop-image/crop-image.page';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  private CACHE_FOLDER = 'APP_CACHE';

  private _videoThumbnailGenerated = new Subject<File>();
  videoThumbnailGenerated = this._videoThumbnailGenerated.asObservable();

  private _videoMetadataLoaded = new Subject<HTMLVideoElement>();
  videoMetadataLoaded = this._videoMetadataLoaded.asObservable();

  constructor( private modalCtrl: ModalController ) {
    // Create cache folder
    this.createCacheFolder().catch();
  }

  get cacheFolderName(): string {
    return this.CACHE_FOLDER;
  }

  async createCacheFolder() {
    // Check if cache folder exists
    await Filesystem.stat({
      directory: Directory.Cache,
      path: this.CACHE_FOLDER
    }).catch(async () => {
      // Cache folder not exists
      await Filesystem.mkdir({
        directory: Directory.Cache,
        path: this.CACHE_FOLDER
      });
    });
  }

  // Helper function
  // https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});
  }

  /**
   * Convert a blob to a file
   *
   * @param blob
   * @param fileName
   * @return
   */
  blobToFile = (blob: Blob, fileName: string): File => {
    const b: any = blob;
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>blob;
  };

  //Helper function to check if file is image
  isFileImage(file) {
    const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];

    return file && acceptedImageTypes.includes(file.type);
  }

  //Helper function to check if file is video
  isFileVideo(file) {
    const acceptedImageTypes = ['video/mp4', 'video/quicktime', 'video/avi', 'video/m4v', 'video/mpg', 'video/mov', 'video/wmv',
      'video/x-ms-wmv', 'application/vnd.apple.mpegurl'];

    return file && acceptedImageTypes.includes(file.type);
  }

  //Helper function to check if file is video
  isFileAudio(file) {

    return file && file.type.startsWith('audio/');
  }

  public generateImgProfileURL(userID: number, filename: string, profile = true)
  {
    let url: string = '';

    if (!filename || filename === 'null')
    {
      url = profile ? 'assets/no-photo.jpg' : 'assets/no-banner.jpg';
    }
    else
    {
      if ( filename.includes('cdn-estrim') || filename.includes('http://') || filename.includes('https://') ) {
        url = filename;
      } else if ( filename.includes('r2.cloudflarestorage.com/') ) {
        url = `${environment.CDN_BASE_URL}${filename.split('r2.cloudflarestorage.com/')[1]}`;
      } else {
        url = `${environment.CDN_BASE_URL}img/profile/${userID}/${filename}`;
      }
    }
    return url;
  }

  generatePublicationThumbnailImgURL(publicationID: number, filename: string) {
    let url;

    try {
      if ( filename.includes('cdn-estrim') ) {
        url = filename;
      } else if ( filename.includes('r2.cloudflarestorage.com/') ) {
        url = `${environment.CDN_BASE_URL}${filename.split('r2.cloudflarestorage.com/')[1]}`;
      } else {
        url = `${environment.CDN_BASE_URL}img/publications/${publicationID}/${filename}`;
      }
    } catch(e) {
      url = filename;
    }

    return url;
  }

  generateNotificationPublicationThumbnailImgURL(image: string) {
    let url;

    try {
      if ( image.includes('cdn-estrim') ) {
        url = image;
      } else if ( image.includes('r2.cloudflarestorage.com/') ) {
        url = `${environment.CDN_BASE_URL}${image.split('r2.cloudflarestorage.com/')[1]}`;
      } else {
        url = `${environment.CDN_BASE_URL}/${image}`;
      }
    } catch(e) {
      url = image;
    }

    return url;
  }

  generateThumbnailImgURL(image: string) {
    let url;

    try {
      if ( image.includes('cdn-estrim') ) {
        url = image;
      } else if ( image.includes('r2.cloudflarestorage.com/') ) {
        url = `${environment.CDN_BASE_URL}${image.split('r2.cloudflarestorage.com/')[1]}`;
      } else {
        url = `${environment.CDN_BASE_URL}${image}`;
      }
    } catch(e) {
      url = image;
    }

    return url;
  }


  generateEventThumbnailImgURL(eventID: number, filename: string) {
    let url;

    try {
      if ( filename.includes('cdn-estrim') ) {
        url = filename;
      } else if ( filename.includes('r2.cloudflarestorage.com/') ) {
        url = `${environment.CDN_BASE_URL}${filename.split('r2.cloudflarestorage.com/')[1]}`;
      } else {
        url = `${environment.CDN_BASE_URL}img/events/${eventID}/${filename}`;
      }
    } catch(e) {
      url = filename;
    }

    return url;
  }

  generateCategoryThumbnailImgURL(category: Category) {
    if (category.image === 'defaultCategory.png') {
      return `${environment.CDN_BASE_URL}categories/${category.image}`;
    }
    return `${environment.CDN_BASE_URL}categories/${category.id}/${category.image}`;
  }

  generateVideoURL(publication: Publication, preview = false, selectedVideo = null) {
    if (!publication.emitting && selectedVideo) {
      return selectedVideo.filename.includes('cdn-estrim')
        ? selectedVideo.filename : `${environment.CDN_BASE_URL}recordings/${publication.id}/${selectedVideo.filename}`;
    } else if ( preview ) {

      if ( publication.videoFilePreview.includes('cdn-estrim') ) {
        return publication.videoFilePreview;
      } else if ( publication.videoFilePreview.includes('r2.cloudflarestorage.com/') ) {
        return `${environment.CDN_BASE_URL}${publication.videoFilePreview.split('r2.cloudflarestorage.com/')[1]}`;
      } else {
        return `${environment.CDN_BASE_URL}video/publications/${publication.id}/preview/${publication.videoFilePreview}`;
      }
    } else if ( publication.videoFile?.includes('cdn-estrim') || publication.videoFile?.includes('.m3u8') ) {
      return publication.videoFile;
    } else {
      return `${environment.CDN_BASE_URL}video/publications/${publication?.id}/${publication?.videoFile}`;
    }
  }

  getBadgeSrc(user: User) {
    let badgeSrc = '';
    let rep = user?.reputation;

    if (!rep) {
      rep = 0;
    }

    if ( user.reputationLevel ) {
      badgeSrc = `assets/badges/badge-${user.reputationLevel}.png`;
    } else {
      switch (true) {
        case rep < 10:
          badgeSrc = 'assets/badges/badge-1.png';
          break;
        case rep < 20:
          badgeSrc = 'assets/badges/badge-2.png';
          break;
        case rep < 30:
          badgeSrc = 'assets/badges/badge-3.png';
          break;
        case rep < 40:
          badgeSrc = 'assets/badges/badge-4.png';
          break;
        case rep < 50:
          badgeSrc = 'assets/badges/badge-5.png';
          break;
        case rep < 200:
          badgeSrc = 'assets/badges/badge-6.png';
          break;
        case rep < 400:
          badgeSrc = 'assets/badges/badge-7.png';
          break;
        case rep < 600:
          badgeSrc = 'assets/badges/badge-8.png';
          break;
        case rep < 1000:
          badgeSrc = 'assets/badges/badge-9.png';
          break;
        case rep < 1500:
          badgeSrc = 'assets/badges/badge-10.png';
          break;
        case rep < 2000:
          badgeSrc = 'assets/badges/badge-11.png';
          break;
        case rep < 2500:
          badgeSrc = 'assets/badges/badge-12.png';
          break;
        case rep < 3000:
          badgeSrc = 'assets/badges/badge-13.png';
          break;
        case rep < 5000:
          badgeSrc = 'assets/badges/badge-14.png';
          break;
        case rep < 8000:
          badgeSrc = 'assets/badges/badge-15.png';
          break;
        case rep < 11000:
          badgeSrc = 'assets/badges/badge-16.png';
          break;
        case rep < 14000:
          badgeSrc = 'assets/badges/badge-17.png';
          break;
        case rep < 17000:
          badgeSrc = 'assets/badges/badge-18.png';
          break;
        case rep < 20000:
          badgeSrc = 'assets/badges/badge-19.png';
          break;
        case rep > 20000:
          badgeSrc = 'assets/badges/badge-20.png';
          break;
      }
    }

    return badgeSrc;
  }

  /**
   * Generate thumbnail from video source
   *
   */
  generateThumbnailFromVideo(source: string, time?: number) {
    const video = document.createElement('video');

    video.onloadedmetadata = (data) => {
      setTimeout(() => {
        if ( time && time > 0 && time < video.duration ) {
          video.currentTime = time;
        } else {
          video.currentTime = video.duration / 2;
        }
      }, 500);

      this._videoMetadataLoaded.next(video);
    };

    video.onseeked = () => {
      const canvas = document.createElement('canvas');
      canvas.height = video.videoHeight;
      canvas.width = video.videoWidth;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      const blob = this.b64toBlob(
        canvas.toDataURL().split(',')[1],
        canvas.toDataURL().split(',')[0].split(';')[0].split(':')[1]
      );
      const file = this.blobToFile(blob, `${Math.floor(Date.now()).toString()}.png`);

      this._videoThumbnailGenerated.next(file as File);

      video.removeAttribute('src');
      video.load();
    };

    video.src = source;
    video.load();
  }

  /**
   * Ask user to crop image
   *
   * @param image
   * @param target Crop type ('live', 'audio')
   * @return the cripped image
   */
  async generateUserCroppedImage(image: File, target: string, canCancel: boolean = true): Promise<File | undefined> {
    const modal = await this.modalCtrl.create({
      component: CropImagePage,
      componentProps: {
        imageFile: image,
        imgTarget: target,
        canCancel
      }
    });
    await modal.present();

    const { data } = await modal.onDidDismiss();

    if (data?.croppedFile) {
      return data.croppedFile;
    } else {
      return undefined;
    }
  }

  async cacheImage(url, path) {
    const options = {
      url,
      responseType: ('blob' as HttpResponseType)
    };

    try {
      const response: HttpResponse = await CapacitorHttp.get(options);

      if ( response && response.status === 200 ) {
        const blob = await response.data;

        const savedImage = await Filesystem.writeFile({
          path: `${this.CACHE_FOLDER}/${path}`,
          data: blob,
          directory: Directory.Cache
        });
      }
    } catch {
      console.log('error - cacheImage');
    }
  }

  /**
   * Calculate average color of a image
   * @param {HTMLImageElement} image Image element
   * @return Average color of image
   */
  getImageAverageColor(image: HTMLImageElement): { red: number, green: number, blue: number } {
    var blockSize = 5, // only visit every 5 pixels
        defaultRGB = { red: 0, green: 0, blue: 0}, // for non-supporting envs
        canvas = document.createElement('canvas'),
        context = canvas.getContext && canvas.getContext('2d'),
        data, width, height,
        i = -4,
        length,
        rgb = { red: 0, green: 0, blue: 0 },
        count = 0;

    if (!context) {
        return defaultRGB;
    }

    image.crossOrigin = "Anonymous";

    height = canvas.height = image.naturalHeight || image.offsetHeight || image.height;
    width = canvas.width = image.naturalWidth || image.offsetWidth || image.width;

    context.drawImage(image, 0, 0);

    try {
      data = context.getImageData(0, 0, width, height);
    } catch(e) {
      console.log(`CATCH ${e}`);
      return defaultRGB;
    }

    length = data.data.length;

    while ( (i += blockSize * 4) < length ) {
        ++count;
        rgb.red += data.data[i];
        rgb.green += data.data[i+1];
        rgb.blue += data.data[i+2];
    }

    // ~~ used to floor values
    rgb.red = ~~(rgb.red/count);
    rgb.green = ~~(rgb.green/count);
    rgb.blue = ~~(rgb.blue/count);

    return rgb;
  }

  /**
   * Calculate brightness of a color
   * @param {{number, number, number}} color Color to calculate
   * @return Brightness of the color
   */
  getColorBrightness(color: {red: number, green: number, blue: number}): number {
    return  Math.round((
      ( color.red * 299 ) +
      ( color.green * 587 ) +
      ( color.blue * 114 )) / 1000);
  }

  /**
   * Check if text should be white over a given image
   * @param {HTMLImageElement} image Image element
   * @return If text should be white
   */
  shouldTextBeWhiteOverImage(image: HTMLImageElement): boolean {
    const threshold = 125;
    const brightness = this.getColorBrightness(this.getImageAverageColor(image));

    if ( brightness > 125 ) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Get duratio of video file or audio source
   * @param source 
   */
  getMediaDuration(source: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const audio = new Audio(source);
      audio.onloadedmetadata = () => resolve(audio.duration);
    });
  }
}
