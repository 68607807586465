<div class="steps">
  <div class="title">
    Función no disponible.
  </div>

  <!-- Info -->
  <div class="info">
    <div class="item">
      <div class="text">
        <div class="description">
          {{text}}
        </div>
      </div>
    </div>
  </div>

  <!-- Buttons -->
  <div class="buttons">
    <ion-button fill="clear" color="dark" (click)="dismiss()" >
      Cerrar
    </ion-button>
  </div>

</div>
