import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-profile-verification-modal',
  templateUrl: './profile-verification-modal.component.html',
  styleUrls: ['./profile-verification-modal.component.scss'],
})
export class ProfileVerificationModalComponent implements OnInit {

  constructor( 
    private modalCtrl: ModalController,
    private router: Router
  ) { }

  ngOnInit() {}

  editProfile() {
    this.router.navigate(['/profile/edit']);
    this.dismiss();
  }

  dismiss() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }
}
