<ion-header
  no-border
  class="estrim-header ion-padding-horizontal general-header-app"
>
  <ion-toolbar class="ion-justify-content-center">
    <ion-title (click)="scrollToTop()">
      <ion-icon
        handleError
        src="assets/desktop/logo.svg"
        alt="Estrim"
      ></ion-icon>
    </ion-title>
    <ion-buttons slot="end">
      <!-- Buscador -->
      <ion-button
        class="reduced icon-svg-search"
        (click)="goTo('/search')"
        mode="ios"
      >
        <ion-icon src="assets/svg-icon/lupa.svg"></ion-icon>
      </ion-button>

      <!-- Notificaciones -->
      <ion-button
        (click)="goTo('/notifications')"
        class="reduced icon-svg-search"
        mode="ios"
      >
        <ion-icon src="assets/svg-icon/campana.svg"></ion-icon>

        <ion-badge
          class="notifications-badge"
          *ngIf="notifications && unread > 0"
          color="danger"
        >
          {{ unread }}
        </ion-badge>
      </ion-button>

      <!-- Chat -->
      <ion-button
        (click)="goTo('/messages/chat-list')"
        class="reduced icon-svg-search chat"
        mode="ios"
      >
        <ion-icon src="assets/svg-icon/bocadillo.svg"></ion-icon>

        <ion-badge
          class="notifications-badge"
          *ngIf="unreadChatNumber && unreadChatNumber > 0"
          color="danger"
        >
          {{ unreadChatNumber }}
        </ion-badge>
      </ion-button>

      <!-- Menu -->
      <ion-button
        (click)="openLateralMenu()"
        color="dark"
        class="reduced icon-svg-search menu"
        [ngClass]="{ 'notification-indicator': invitationsIndicator }"
        mode="ios"
      >
        <ion-icon src="assets/svg-icon/main2.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <div class="upload" *ngIf="fileUploading">
    <ion-skeleton-text
      class="upload-indicator"
      [ngStyle]="{ width: fileProgress + '%' }"
      [animated]="true"
    >
    </ion-skeleton-text>

    <div
      #fileProgressText
      class="text"
      [ngStyle]="{
        transform: 'translateX(' + fileProgressTextLeft + ')',
        color: fileProgressTextColor
      }"
    >
      Subiendo&nbsp;
      <b>{{ fileProgress }}%</b>
    </div>
  </div>
</ion-header>
