import { Directive, AfterViewInit, OnDestroy, Output, ElementRef, EventEmitter } from '@angular/core';

import { createGesture, GestureDetail } from "@ionic/angular";

@Directive({
  selector: '[pinch]'
})
export class PinchDirective implements OnDestroy {

  @Output() public pinchIn: EventEmitter<boolean> = new EventEmitter();
  @Output() public pinchOut: EventEmitter<boolean> = new EventEmitter();

  private gesture: any;
  private initialDistance: number = undefined;
  private eventTriggered: boolean = false;

  constructor( private element: ElementRef ) {
    this.gesture = createGesture({
      gestureName: 'pinch',
      el: element.nativeElement,
      threshold: 0,
      onMove: (detail: GestureDetail) => { this.onMove(detail); },
      onEnd: (detail: GestureDetail) => { 
        this.initialDistance = undefined; 
        this.eventTriggered = false;
      }
    });

    this.gesture.enable();
  }

  ngOnDestroy() {
    this.gesture.destroy();
  }

  onMove = (detail: GestureDetail) => {
    let distance;

    // @ts-ignore
    if ( detail?.event?.touches?.length === 2 ) {
      // @ts-ignore
      distance = Math.hypot(detail.event.touches[0].clientX - detail.event.touches[1].clientX, detail.event.touches[0].clientY - detail.event.touches[1].clientY);

      if ( this.initialDistance === undefined ) {
        this.initialDistance = distance;
      } else if ( this.initialDistance > distance && Math.abs(this.initialDistance - distance) > 50 && !this.eventTriggered ) {
        this.pinchOut.emit();
        this.eventTriggered = true;
      } else if ( this.initialDistance < distance && Math.abs(this.initialDistance - distance) > 50 && !this.eventTriggered ) {
        this.pinchIn.emit();
        this.eventTriggered = true;
      }
    }
  }
}
