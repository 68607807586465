import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { IonNav, ModalController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { format, utcToZonedTime } from 'date-fns-tz';

import { BasePage } from '../../../../../pages/base.page';

import { environment } from '../../../../../../environments/environment';

import { CreateContentService } from '../../../../../services/create-content.service';
import { AuthService } from '../../../../../services/auth.service';
import { FilePickerService } from '../../../../../services/file-picker.service';
import { MediaService } from '../../../../../services/media.service';
import { Events } from '../../../../../services/events.service';
import { PublicationService } from '../../../../../services/publication.service';
import { FileTransferService } from '../../../../../services/file-transfer.service';
import { UserService } from '../../../../../services/user.service';

import { Event } from '../../../../../interfaces/event';
import { User } from '../../../../../interfaces/user';

import { ContentType } from '../../../../../enums/content-type';
import { FormMode } from '../../../../../enums/form-mode';
import { ProAlertType } from '../../../../../enums/pro-alert-type';

@Component({
  selector: 'app-media',
  templateUrl: './media.page.html',
  styleUrls: ['./media.page.scss', '../../../../../../theme/field.scss'],
})
export class MediaPage extends BasePage implements OnInit {

  @Input() type: ContentType = ContentType.Event;
  @Input() mode: FormMode = FormMode.Create;
  @Input() form: any = {};
  @Input() event: Event = undefined;

  CONTENT_MIN_PRICE = environment.CONTENT_MIN_PRICE;
  CONTENT_MAX_PRICE = environment.CONTENT_MAX_PRICE;

  ContentType = ContentType;
  FormMode = FormMode;

  uploading: boolean = false;

  error: any = {};

  user: User;

  chosenType: ContentType | 'preview' = undefined;

  previewType: ContentType;

  /**
   * Form data
   */
  isFree: boolean = true;
  price: number;
  hand: string;
  videoHand: string;
  audioHand: string;
  chat: string;
  save: number = 0;
  poster: File;
  posterSrc: SafeUrl | string;
  file: File;
  fileSrc: SafeUrl;
  eventDate: any;
  eventDateEnd: any;
  preview: File;
  previewSrc: SafeUrl;

  constructor(
    public platform: Platform,
    private nav: IonNav,
    private createContentService: CreateContentService,
    private mediaService: MediaService,
    private authService: AuthService,
    private filePickerService: FilePickerService,
    private publicationService: PublicationService,
    private fileTransfer: FileTransferService,
    private events: Events,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private userService: UserService,
    private modalCtrl: ModalController,
    public cdRef: ChangeDetectorRef
  ) {
    super(platform, cdRef);
  }

  ngOnInit() {
    if ( this.mode === FormMode.Edit && this.event !== undefined ) {
      this.isFree = this.event.eventPrice === 0;
      this.price = this.event.eventPrice;
      this.chat = this.event.chatConfiguration;
      this.hand = this.event.handConfiguration;
      this.posterSrc = this.event.thumbnail;
    }
  }

  ionViewWillEnter() {
    this.authService.getUserDetails().then((data) => {
      this.user = data;
    });

    // Subscribe to file pick
    this.sub.filePicked = this.filePickerService.filePicked.subscribe(async (file: File) => {
      if ( file !== undefined && file.type.includes('image') && this.chosenType === ContentType.Image ) {
        this.error.poster = false;

        // Ask user to crop poster
        const croppedImage = await this.mediaService.generateUserCroppedImage(file, 'live');

        this.poster = croppedImage !== undefined ? croppedImage : file;
        this.posterSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(this.poster));
      } else if ( file !== undefined && this.chosenType === ContentType.Video ) {
        this.error.file = false;

        if ( file.type.includes('video') ) {
          this.file = file;
          this.fileSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(this.file));
        } else {
          this.events.publish('alert:toast:show', 'Debes seleccionar un archivo de video');
        }
      } else if ( file !== undefined && this.chosenType === ContentType.Audio ) {
        if ( file.type.includes('audio') ) {
          this.file = file;
          this.fileSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(this.file));
        } else {
          this.events.publish('alert:toast:show', 'Debes seleccionar un archivo de audio');
        }
      } else if ( file !== undefined && this.chosenType === 'preview' ) {
        // Compruebo que el archivo de preview tien un formato adecuado
        if ( file.type.includes('video') ) {
          this.previewType = ContentType.Video;
        }
        else if ( file.type.includes('audio') ) {
          this.previewType = ContentType.Audio;
        }
        else {
          this.previewType = undefined;
        }

        if ( this.previewType !== undefined && (file.size / 1000000) <= 100 ) {
          this.preview = file;
          this.previewSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(this.preview));
        } else if (this.previewType !== undefined && (file.size / 1000000) > 100) {
          this.events.publish('alert:toast:show', 'La preview no puede superar los 100 MB');
        } else {
          this.events.publish('alert:toast:show', 'La preview debe ser un archivo de video o de audio');
        }
      }
    })
  }

  dismiss() {
    this.createContentService.closeModal();
  }

  goBack() {
    if ( !this.uploading ) {
      this.nav.pop({ mode: 'ios' });
    }
  }

  switchPrice() {
    if ( !this.uploading ) {
      if ( this.isFree && !this.user.isPro ) {
        // Alert
        this.userService.openShouldBeProModal(
          this.type === ContentType.Event 
            ? ProAlertType.EventRestricted 
            : ProAlertType.PremiereRestricted
        );
      } else {
        this.isFree = !this.isFree;
      }
    }

    this.error.price = false
  }

  chooseFile(type: ContentType) {
    if ( !this.uploading ) {
      this.chosenType = type;
      this.filePickerService.pickFile(type);
    }
  }

  pickPoster() {
    if ( !this.uploading ) {
      this.chosenType = ContentType.Image;
      this.filePickerService.pickFile(ContentType.Image);
    }
  }

  pickPreview() {
    if ( !this.uploading ) {
      this.chosenType = 'preview';
      this.filePickerService.pickFile(undefined);
    }
  }

  get uploadProgress() {
    return this.fileTransfer.progress / 100;
  }

  checkForm(): boolean {
    let status = true;

    if ( this.type === ContentType.Event ) {
      if ( !(this.chat && this.chat.trim() !== '') ) {
        status = false;
        this.error.chat = true;
      }

      if ( !(this.hand && this.hand.trim() !== '') ) {
        status = false;
        this.error.hand = true;
      }
    }

    if ( this.mode === FormMode.Create && this.poster === undefined ) {
      status = false;
      this.error.poster = true;
    }

    if ( this.mode === FormMode.Create && this.type === ContentType.Premiere && this.file === undefined ) {
      status = false;
      this.error.file = true;
    }

    if ( !this.isFree && (this.price === undefined || this.price < environment.CONTENT_MIN_PRICE || this.price > environment.CONTENT_MAX_PRICE) ) {
      status = false;
      this.error.price = true;
    }

    return status;
  }

  async submitForm() {
    if ( !this.checkForm() ) {
      return ;  // BREAK EXECUTION
    }

    /*
     * Uploading started
     */

    this.uploading = true;
    let posterFileName: SafeUrl | string;
    let posterResult: any = undefined;

    /*
     * Generate date
     */

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = new Date(this.form.date);
    const hour = new Date(this.form.hour);

    date.setHours(hour.getHours());
    date.setMinutes(hour.getMinutes());

    const zonedTime = utcToZonedTime(date, timeZone);
    this.eventDate = format(zonedTime, 'yyyy-MM-dd HH:mm', {timeZone: timeZone});

    if ( this.type === ContentType.Event) {
      const time = Number((new Date(this.form.time)).getHours() * 60) + Number((new Date(this.form.time)).getMinutes());
      const dateEnd = new Date(this.form.date).setMinutes(date.getMinutes() + time);
      const zonedTimeEnd = utcToZonedTime(dateEnd, timeZone);
      this.eventDateEnd = format(zonedTimeEnd, 'yyyy-MM-dd HH:mm', {timeZone: timeZone});
    }

    /*
     * Upload poster
     */

    if ( this.mode === FormMode.Create || ( this.mode === FormMode.Edit && this.poster ) ) {
      try {
        posterResult = await this.publicationService
          .uploadPublicationTempImage((this.poster as File))
          .toPromise();
      } catch(e) {}

      if ( !(posterResult && posterResult.done && posterResult.msg === 'Ok') ) {
        // Throw error
        this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, "Ha ocurrido un error, intentalo de nuevo más tarde");
        this.uploading = false;
        return ;  // BREAK EXECUTION
      }

      posterFileName = posterResult.file_cdn ? posterResult.file_cdn : posterResult.file;
    } else {
      posterFileName = this.posterSrc;
    }

    /*
     * Upload content
     */

    if ( this.type === ContentType.Event ) {
      this.onCreateEventSubmit(this.event, posterFileName);
    } else if ( this.type === ContentType.Premiere ) {
      this.onUploadPremiereForm(this.event, posterFileName);
    }
  }

  /**
   * Upload event
   */
  onCreateEventSubmit(event: Event = null, poster: SafeUrl | string) {
    this.uploading = true;

    const data = {
      title: this.form.title,
      description: this.form.description,
      eventDate: this.eventDate,
      eventDateEnd: this.eventDateEnd,
      categorySelected: this.form.category,
      price: !this.isFree ? this.price : 0,
      imageFile: poster,
      isVideo: this.form.isVideo,
      chat: this.chat,
      hand: this.hand,
      // videoHand: this.videoHand,
      // audioHand: this.audioHand,
      collaborators: this.form.collaborators,
      save: this.save,
      uploadedFilePreview: this.preview,
      fileSizePreview: this.preview?.size
    };

    !this.preview ? delete data.uploadedFilePreview : null;

    this.publicationService.createEvent(data, event?.id)
    .subscribe(
      (res) => {
        this.uploading = false;

        if (res.done) {
          this.form.collaborators.forEach(col => {
            this.publicationService.inviteEventCollaborator(col.id, res.data).subscribe();
          });

          if (this.event) {
            data.eventDate = res.newEvent.eventDateStart;
            data.imageFile = res.newEvent.thumbnail;

            this.event.eventDateStart = res.newEvent.eventDateStart;
            this.event.thumbnail = res.newEvent.thumbnail;
            this.event.category.id = data.categorySelected;
            this.event.collaboratorList = data.collaborators;
            this.event.chatConfiguration = data.chat;
            this.event.description = data.description;
            this.event.handConfiguration = data.hand;
            this.event.isVideo = data.isVideo;
            this.event.eventPrice = data.price;
            this.event.title = data.title;

            return this.modalCtrl.dismiss(this.event, 'confirm');
          }

          this.events.publish(
            environment.EVENTS.ALERT_TOAST_NOTICE,
            'Tu evento se ha creado correctamente.'
          );

          this.events.publish(environment.EVENTS.EVENT_CREATED);

          this.router.navigate(['/publications/pre-event/' + res.data], {replaceUrl: true});

          this.dismiss();
        } else {
          this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, res.msg);
        }
      }, (error) => {
        this.uploading = false;
        error.msg
          ? this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, error.msg)
          : this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error, intentalo de nuevo más tarde.');
      }
    );
  }

  /**
   * Upload premiere
   */
  onUploadPremiereForm(event: Event = null, poster: SafeUrl | string) {
    this.uploading = true;
    this.content.scrollToBottom();

    const uploadId = this.fileTransfer.getUniqueId();

    const data = {
      title: this.form.title,
      description: this.form.description,
      uploadedFile: this.file,
      uploadedFilePreview: this.preview,
      fileSizePreview: this.preview?.size,
      imageFile: poster,
      categorySelected: this.form.category,
      isVideo: this.form.isVideo,
      price: !this.isFree ? this.price : 0,
      eventDate: this.eventDate,
      collaborators: this.form.collaborators,
      save: this.save,
      uploadId
    };

    !this.preview ? delete data.uploadedFilePreview : null;

    this.fileTransfer.uploadByChunks(data.uploadedFile, uploadId).then(fileres => {
      if (fileres.finished) {
        this.publicationService.createPremiere(data, event?.id)
        .subscribe(
          (res) => {
            this.uploading = false;

            if (res.done) {
              this.form.collaborators.forEach(col => {
                this.publicationService.inviteEventCollaborator(col.id, res.data).subscribe();
              });

              this.events.publish(environment.EVENTS.ALERT_TOAST_NOTICE, 'Tu estreno se ha creado correctamente.');

              this.events.publish(environment.EVENTS.EVENT_CREATED);

              this.router.navigate(['/publications/pre-event/' + res.data], {replaceUrl: true});

              return this.modalCtrl.dismiss(this.event, 'confirm');
            } else {
              this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, res.msg);
            }
          }, (error) => {
            this.uploading = false;
            error.msg
              ? this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, error.msg)
              : this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error, intentalo de nuevo más tarde.');
          }
        );
      }
    });
  }
}
