import { Component, OnInit, OnChanges, SimpleChanges, Input, ChangeDetectorRef } from '@angular/core';
import { trigger, transition, animate, keyframes, style } from '@angular/animations';

import { inOutStarAnimation1, starAnimation1 } from '../../animations/animations';

@Component({
  selector: 'app-star-counter',
  templateUrl: './star-counter.component.html',
  styleUrls: ['./star-counter.component.scss'],
  animations: [
    inOutStarAnimation1,
    starAnimation1,
    trigger('inOutCount', [
      transition(':enter', [
        animate('300ms ease', keyframes([
          style({transform: 'translateY(100%)', opacity: '0', offset: 0}),
          style({transform: 'translateY(0%)', opacity: '1', offset: 0.5}),
          style({transform: 'translateY(-50%)', opacity: '1', offset: 0.8}),
          style({transform: 'translateY(0%)', opacity: '1', offset: 1})
        ]))
      ]),
      transition(':leave', [
        style({position: 'absolute'}),
        animate('150ms ease', style({transform: 'translateY(-100%)', opacity: '0'}))
      ])
    ])
  ]
})
export class StarCounterComponent  implements OnInit, OnChanges {

  @Input() count: number = 0;
  @Input() filled: boolean = false;
  @Input() whiteStar: boolean = false;

  animateCount: boolean = false;
  animateStar: boolean = false;

  constructor( private cdRef: ChangeDetectorRef ) { }

  ngOnInit() {
    this.count = this.count??0;
  }

  ngOnChanges(changes: SimpleChanges) {
    // Animate star
    this.animateStar = true;
    
    // Animate counter
    this.animateCount = false;
    this.cdRef.detectChanges();
    this.animateCount = true;
    this.cdRef.detectChanges();
  }
}
