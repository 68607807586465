<ion-content class="ion-padding" scroll-y="false">
  <div class="content">
    <div class="blur top"></div>
    <div class="blur bottom"></div>
    <ion-icon class="logo" src="assets/estrim/logo.svg"></ion-icon>
    <div class="centered-section p-desktop-0">
      <div class="title mb-desktop-4">Ya casi estás</div>
      <p>
        En Estrim queremos que te sientas como en casa. Para
        <b>finalizar el registro invita a un amig@ o familar</b>
        para que se una a esta gran comunidad.
      </p>
    </div>

    <!-- Submit button -->
    <div class="submit-button">
      <ion-button fill="clear" [disabled]="loading" (click)="invite()">
        Invitar
        <ion-spinner
          color="white"
          name="crescent"
          *ngIf="loading"
        ></ion-spinner>
      </ion-button>
    </div>

    <div class="skip-button">
      <ion-button
        class="ion-margin-top ion-margin-bottom"
        expand="block"
        fill="clear"
        (click)="exitWithoutInvitation()"
      >
        Más adelante
      </ion-button>
    </div>

    <ion-modal
      class="page-modal"
      [isOpen]="showModal"
      (willDismiss)="onWillDismiss($event)"
    >
      <ng-template>
        <app-local-contact-list [contacts]="contacts"> </app-local-contact-list>
      </ng-template>
    </ion-modal>
  </div>
</ion-content>
