  import { Injectable } from '@angular/core';
import { Category } from '../interfaces/category';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, combineLatest, forkJoin, merge, of } from 'rxjs';
import { Publication } from '../interfaces/publication';
import { Event } from '../interfaces/event';
import { JsonApiResponse } from '../interfaces/JsonApiResponse';
import { User } from '../interfaces/user';
import { SuggestionList } from '../interfaces/SuggestionList';
import { JsonApiResponsePaginated } from '../interfaces/JsonApiResponsePaginated';
import { Events } from './events.service';
import { SearchSection } from '../enums/search-section';
import { BehaviorSubject, Subject } from 'rxjs';
import { Preferences } from '@capacitor/preferences';
import { AuthService } from './auth.service';
import { filter, map } from 'rxjs/operators';
import { ContentType } from '../enums/content-type';
import { HomeLayout } from '../enums/home-layout';
import moment from 'moment';

import { shuffle } from '../utils/array';

import { ViewedPublication } from '../interfaces/viewed-publication';

import { ShortcutRange } from '../pages/home-v2/layouts/event-layout/event-layout.component';

@Injectable({
  providedIn: 'root'
})
export class FeedService {

  public currentCategory: Category = null;

  categories: Category[] = [];
  slidingList: Publication[] = [];

  getCategoriesUrl = 'api/aforum/get-categories';
  getSearchTermUrl = 'api/aforum/search-term';
  getFeedUrl = 'api/auth/publication/get-feed';
  getHomeContentUrl = 'api/auth/publication/home/{categoryId}/{userId}';
  getHomeVideoContentUrl = 'api/auth/publication/new-video-home/{categoryId}/{userId}';
  getHomeAudioContentUrl = 'api/auth/publication/new-audio-home/{categoryId}/{userId}';
  getLivesUrl = 'api/auth/publication/lives/{offset}/{limit}/{categoryId}/{userId}';
  getOneContentUrl = 'api/auth/publication/ones/{offset}/{limit}/{categoryId}/{userId}/{audio}';
  getUploadedVideosUrl = 'api/auth/publication/videos/{offset}/{limit}/{categoryId}/{userId}';
  getUploadedAudiosUrl = 'api/auth/publication/audios/{offset}/{limit}/{categoryId}/{userId}';
  getAudiosUrl = 'api/auth/publication/podcasts/{offset}/{limit}/{categoryId}/{userId}';
  getFollowingUrl = 'api/auth/publication/get-following-publications/{offset}/{limit}/{categoryId}';
  getRecommendedUsersUrl = 'api/auth/publication/recommended-users/{offset}/{limit}/{categoryId}';
  getRecommendedVideosUrl = 'api/auth/publication/get-recommended-videos/{offset}/{limit}/{categoryId}';
  getRecommendedAudiosUrl = 'api/auth/publication/get-recommended-audios/{offset}/{limit}/{categoryId}';
  getEventsUrl = 'api/auth/publication/events/{offset}/{limit}/{categoryId}/{userId}/{showPassedEvents}';
  getSearchEventsUrl = 'api/auth/events/search/{offset}/{limit}/{categoryId}/{userId}/{showPassedEvents}/{isVideo}';
  getPremieresUrl = 'api/auth/publication/premieres/{offset}/{limit}/{userId}/{showPassedEvents}';
  getClipsUrl = 'api/auth/publication/clips/{offset}/{limit}/{categoryId}/{userId}';

  CACHE_HOME_LIVE_LIST = 'FEED_CACHE_LIVE_LIST';
  CACHE_HOME_DEBUG_LIVE_LIST = 'FEED_CACHE_DEBUG_LIVE_LIST';
  CACHE_HOME_EVENT_LIST = 'FEED_CACHE_EVENT_LIST';
  CACHE_HOME_ONEVIDEOS_LIST = 'FEED_CACHE_ONEVIDEOS_LIST';
  CACHE_HOME_ONEAUDIOS_LIST = 'FEED_CACHE_ONEAUDIOS_LIST';
  CACHE_HOME_PREMIERE_LIST = 'FEED_CACHE_PREMIERE_LIST';
  CACHE_HOME_AUDIO_LIST = 'FEED_CACHE_AUDIO_LIST';
  CACHE_HOME_DEBUG_AUDIO_LIST = 'FEED_CACHE_DEBUG_AUDIO_LIST';
  CACHE_HOME_FOLLOWING_LIST = 'FEED_CACHE_FOLLOWING_LIST';
  CACHE_HOME_RECOMMENDED_LIST = 'FEED_CACHE_RECOMMENDED_LIST';
  CACHE_HOME_DEBUG_RECOMMENDED_LIST = 'FEED_CACHE_DEBUG_RECOMMENDED_LIST';
  CACHE_HOME_RECOMMENDEDVIDEOS_LIST = 'FEED_CACHE_RECOMMENDED_VIDEOS_LIST';
  CACHE_HOME_RECOMMENDEDUPLOADEDVIDEOS_LIST = 'FEED_CACHE_RECOMMENDED_UPLOADED_VIDEOS_LIST';
  CACHE_HOME_RECOMMENDEDAUDIOS_LIST = 'FEED_CACHE_RECOMMENDED_AUDIOS_LIST';
  CACHE_HOME_PODCASTS_LIST = 'FEED_CACHE_PODCASTS_LIST';
  CACHE_HOME_DEBUG_PODCASTS_LIST = 'FEED_CACHE_DEBUG_PODCASTS_LIST';
  CACHE_HOME_VIDEOS_LIST = 'FEED_CACHE_VIDEOS_LIST';
  CACHE_HOME_DEBUG_VIDEOS_LIST = 'FEED_CACHE_DEBUG_VIDEOS_LIST';
  CACHE_HOME_CLIPS_LIST = 'FEED_CACHE_CLIPS_LIST';
  CACHE_HOME_DEBUG_CLIPS_LIST = 'FEED_CACHE_DEBUG_CLIPS_LIST';
  CACHE_SEARCH_RECENT_USER_LIST = 'FEED_CACHE_RECENT_USER_LIST';

  CACHE_VIEWED_VIDEOS_KEY = 'FEED_CACHE_VIEWED_VIDEOS';
  CACHE_VIEWED_PODCASTS_KEY = 'FEED_CACHE_VIEWED_PODCASTS';
  CACHE_VIEWED_CLIPS_KEY = 'FEED_CACHE_VIEWED_CLIPS';

  private readonly KEY_HOME_LAYOUT = 'HOME_LAYOUT';

  currentSlidingItem;

  private recommendedUsersCache: User[] = [];
  private isRecommendedUsersCacheValid = false;

  private _homeContent: BehaviorSubject<Record<string, any[]>> = new BehaviorSubject<Record<string, []>>(undefined);
  homeContent: Observable<Record<string, any[]>> = this._homeContent.asObservable();

  private _homeVideoContent: BehaviorSubject<Record<string, any[]>> = new BehaviorSubject<Record<string, []>>(undefined);
  homeVideoContent: Observable<Record<string, any[]>> = this._homeVideoContent.asObservable();

  private _homeAudioContent: BehaviorSubject<Record<string, any[]>> = new BehaviorSubject<Record<string, []>>(undefined);
  homeAudioContent: Observable<Record<string, any[]>> = this._homeAudioContent.asObservable();

  private _homeEventContent: BehaviorSubject<{events: Event[], total: number}> = new BehaviorSubject<{events: Event[], total: number}>(undefined);
  homeEventContent: Observable<{events: Event[], total: number}> = this._homeEventContent.asObservable();

  private _homeLayout: BehaviorSubject<HomeLayout> = new BehaviorSubject<HomeLayout>(HomeLayout.Video);
  homeLayout: Observable<HomeLayout> = this._homeLayout.asObservable();

  private _homeContentUpdated = new Subject<boolean>();
  homeContentUpdated = this._homeContentUpdated.asObservable();

  private homeContentRequest = undefined;
  private homeVideoContentRequest = undefined;
  private homeAudioContentRequest = undefined;
  private homeEventContentRequest = undefined;

  constructor(
    private events: Events, 
    private authService: AuthService,
    protected http: HttpClient
  ) {
    this.loadHomeLayout();

    this.events.subscribe(environment.EVENTS.USER_LOGIN, (destination: string = environment.APP_ROUTES.HOME) => {
      // this.loadHomeContent([], 0, true);
    });

    this.events.subscribe(environment.EVENTS.USER_SUGGESTIONS_UPDATED, () => {
      this.isRecommendedUsersCacheValid = false;
    });

    this.events.subscribe(environment.EVENTS.USER_FOLLOW, () => {
      this.isRecommendedUsersCacheValid = false;
    });

    this.events.subscribe(environment.EVENTS.USER_LOGOUT, () => {
      this._homeContent.next(undefined);
      this._homeVideoContent.next(undefined);
      this._homeAudioContent.next(undefined);
      this._homeLayout.next(HomeLayout.Video);
      this.recommendedUsersCache = [];
      this.isRecommendedUsersCacheValid = false;
    });

    // Subscribe to content delete to remove it from cache
    this.events.subscribe(environment.EVENTS.PUBLICATION_DELETED, (data: {type: ContentType, id: number}) => {
      this.removePublicationFromCache(data.type, data.id);
    });

    // Subscribe to content block to remove it from cache
    this.events.subscribe(environment.EVENTS.PUBLICATION_BLOCKED, (data: {type: ContentType, id: number}) => {
      this.removePublicationFromCache(data.type, data.id);
    });

    // Subscribe to content report to remove it from cache
    this.events.subscribe(environment.EVENTS.PUBLICATION_REPORTED, (data: {type: ContentType, id: number}) => {
      this.removePublicationFromCache(data.type, data.id);
    });

    // Subscribe to content modify to remove it from cache
    this.events.subscribe(environment.EVENTS.PUBLICATION_MODIFIED, (data: {type: ContentType, id: number}) => {
      this.removePublicationFromCache(data.type, data.id);
    });

    // Subscribe to user modification to update stored publications
    this.events.subscribe(environment.EVENTS.USER_MODIFIED, (user: User) => {
      this.cleanCachedData();
      this.cleanFeed();
    });
  }

  get currentHomeLayout(): HomeLayout {
    return this._homeLayout.value;
  }

  public generateAbsoluteAPIURL($tail: string) {
    return environment.API_BASE_URL + $tail;
  }

  async loadCachedHomeContent() {
    let liveList = [];
    let audioList = [];
    let recommendedUserList = [];
    let recommendedVideosList = [];
    let recommendedUploadedVideosList = [];
    let recommendedAudiosList = [];
    let videosList = [];
    let clipsList = [];
    let podcastsList = [];

    // const jsonLives = await Preferences.get({ key: this.CACHE_HOME_LIVE_LIST });
    const jsonAudios = await Preferences.get({ key: this.CACHE_HOME_AUDIO_LIST });
    const jsonRecommended = await Preferences.get({ key: this.CACHE_HOME_RECOMMENDED_LIST });
    const jsonRecommendedVideos = await Preferences.get({ key: this.CACHE_HOME_RECOMMENDEDVIDEOS_LIST });
    const jsonRecommendedUploadedVideos = await Preferences.get({ key: this.CACHE_HOME_RECOMMENDEDUPLOADEDVIDEOS_LIST });
    const jsonRecommendedAudios = await Preferences.get({ key: this.CACHE_HOME_RECOMMENDEDAUDIOS_LIST });
    const jsonVideos = await Preferences.get({ key: this.CACHE_HOME_VIDEOS_LIST });
    const jsonClips = await Preferences.get({ key: this.CACHE_HOME_CLIPS_LIST });
    const jsonPodcasts = await Preferences.get({ key: this.CACHE_HOME_PODCASTS_LIST });

    if (
      // !jsonLives.value?.length &&
      !jsonAudios.value?.length &&
      !jsonRecommended.value?.length &&
      !jsonRecommendedVideos.value?.length &&
      !jsonRecommendedUploadedVideos.value?.length &&
      !jsonRecommendedAudios.value?.length &&
      !jsonVideos.value?.length &&
      !jsonClips.value?.length &&
      !jsonPodcasts.value?.length
    ) {
      return;
    }

    // if (jsonLives.value) {
    //   liveList = JSON.parse(jsonLives.value);
    // }

    if (jsonAudios.value) {
      audioList = JSON.parse(jsonAudios.value);
    }

    if (jsonRecommended.value) {
      recommendedUserList = JSON.parse(jsonRecommended.value);
    }

    if (jsonRecommendedVideos.value) {
      recommendedVideosList = JSON.parse(jsonRecommendedVideos.value);
    }

    if (jsonRecommendedUploadedVideos.value) {
      recommendedUploadedVideosList = JSON.parse(jsonRecommendedUploadedVideos.value);
    }

    if (jsonRecommendedAudios.value) {
      recommendedAudiosList = JSON.parse(jsonRecommendedAudios.value);
    }

    if (jsonVideos.value) {
      videosList = JSON.parse(jsonVideos.value);
    }

    if (jsonClips.value) {
      clipsList = JSON.parse(jsonClips.value);
    }

    if (jsonPodcasts.value) {
      podcastsList = JSON.parse(jsonPodcasts.value);
    }

    this._homeContent.next({
      recommendedAudios: recommendedAudiosList,
      recommendedVideos: recommendedVideosList,
      recommendedUploadedVideos: recommendedUploadedVideosList,
    });

    this._homeVideoContent.next({
      lives: liveList,
      users: recommendedUserList,
      videos: videosList,
      clips: clipsList
    });

    this._homeAudioContent.next({
      audios: audioList,
      podcasts: podcastsList
    });
  }

  loadCategories(): Observable<Category[]> {
    if (!!this.categories.length) {
      return of(this.categories);
    }
    return this.http.get<Category[]>(this.generateAbsoluteAPIURL(this.getCategoriesUrl)).pipe(map((categories) => {
      if (categories) {
        this.categories = categories.sort((a, b) => a.name.localeCompare(b.name));
      }
      return categories;
    }));
  }

  loadFeed(): Observable<Publication[]> {
    return this.http.get<Publication[]>(this.generateAbsoluteAPIURL(this.getFeedUrl));
  }

  loadEvents(
    offset = 0, 
    limit = 10, 
    categoriesSelected: Category[] = [], 
    userId = 0, 
    showPassedEvents: any = false, 
    cache: boolean = false,
    filters: any = {},
    withSearch: boolean = true
  ) {
    showPassedEvents = showPassedEvents ? '1' : '0';
    let isVideo;
    let params;

    let start_date: string = '';
    let end_date: string = '';

    if ( withSearch ) {
      switch ( filters.shortcutRange ) {
        case ShortcutRange.None: 
          start_date = filters.startDate ? filters.startDate.startOf('day').format("YYYY-MM-DDTHH:mm:ss") : '';
          end_date = filters.endDate ? filters.endDate.endOf('day').format("YYYY-MM-DDTHH:mm:ss") : '';
        break;
        case ShortcutRange.Today: 
          start_date =  moment().startOf('day').format("YYYY-MM-DDTHH:mm:ss");
          end_date = moment().endOf('day').format("YYYY-MM-DDTHH:mm:ss");
        break;
        case ShortcutRange.Tomorrow: 
          start_date = moment().add(1, 'days').startOf('day').format("YYYY-MM-DDTHH:mm:ss");
          end_date = moment().add(1, 'days').endOf('day').format("YYYY-MM-DDTHH:mm:ss");
        break;
        case ShortcutRange.ThisWeek:
          start_date = moment().startOf('isoWeek').format("YYYY-MM-DDTHH:mm:ss");
          end_date = moment().endOf('isoWeek').format("YYYY-MM-DDTHH:mm:ss");
        break;
        case ShortcutRange.ThisMonth:
          start_date = moment().startOf('month').format("YYYY-MM-DDTHH:mm:ss");
          end_date = moment().endOf('month').format("YYYY-MM-DDTHH:mm:ss");
        break;
      }

      params = new HttpParams()
      .set('term', filters.query || '')
      .set('price', filters.free ? '0' : filters.price?.toString() || '')
      .set('start_date', start_date)
      .set('end_date', end_date)
      .set('categories', filters.categories ? filters.categories.join(',') : '')


      switch ( filters.contentType ) {
        case ContentType.Audio: isVideo = '0'; break;
        case ContentType.Video: isVideo = '1'; break;
        default: isVideo = '3'; break;
      }
    }

    return this.http.get<JsonApiResponsePaginated<Event[]>>(
      `${this.generateAbsoluteAPIURL(withSearch ? this.getSearchEventsUrl : this.getEventsUrl)
        .replace('{offset}', offset.toString())
        .replace('{limit}', limit.toString())
        .replace('{userId}', userId.toString())
        .replace('{categoryId}', categoriesSelected.length === 0 ? '0' : categoriesSelected.map(cat => cat.id).toString())
        .replace('{showPassedEvents}', showPassedEvents)
        .replace('{isVideo}', isVideo)} ${withSearch ? '?' + params?.toString() : ''}`
    ).pipe(map((res) => {

      if ( res?.done && cache ) {
        Preferences.set({ key: this.CACHE_HOME_EVENT_LIST, value: JSON.stringify(res.data.list) });
      }

      return res;
    }));
  }

  loadHomeContent(categoriesSelected: Category[] = [], userId = 0, force: boolean = false, cache: boolean = true) {
    let forceDataUpdate: boolean = false;

    if (this.homeContentRequest !== undefined) {
      this.homeContentRequest.unsubscribe();
    }

    this.homeContentRequest = this.http.get<JsonApiResponse<any>>(
      this.generateAbsoluteAPIURL(this.getHomeContentUrl)
        .replace('{categoryId}', categoriesSelected.length === 0 ? '0' : categoriesSelected.map(cat => cat.id).toString())
        .replace('{userId}', userId.toString()))
        .subscribe(res => {
          if (res.done) {

            if ( cache ) {
              Preferences.set({ key: this.CACHE_HOME_RECOMMENDEDAUDIOS_LIST, value: JSON.stringify(res.data.recommendedAudios) });
              Preferences.set({ key: this.CACHE_HOME_RECOMMENDEDVIDEOS_LIST, value: JSON.stringify(res.data.recommendedLiveVideos) });
              Preferences.set({ key: this.CACHE_HOME_RECOMMENDEDUPLOADEDVIDEOS_LIST, value: JSON.stringify(res.data.recommendedVideos) });
            }

            res.data.recommendedUploadedVideos = res.data.recommendedVideos;
            res.data.recommendedVideos = res.data.recommendedLiveVideos;

            if (!force && !!this._homeContent.getValue()) {
              res.data.recommendedVideos.forEach((item, index) => {
                if ( !this._homeContent.value.recommendedVideos[index] ) {
                  this._homeContent.value.recommendedVideos.push(item);
                  forceDataUpdate = true;
                } else if (item.id !== this._homeContent.value.recommendedVideos[index].id) {
                  this._homeContent.value.recommendedVideos[index] = item;
                }
              });

              res.data.recommendedUploadedVideos.forEach((item, index) => {
                if ( !this._homeContent.value.recommendedUploadedVideos[index] ) {
                  this._homeContent.value.recommendedUploadedVideos.push(item);
                  forceDataUpdate = true;
                } else if (item.id !== this._homeContent.value.recommendedUploadedVideos[index].id) {
                  this._homeContent.value.recommendedUploadedVideos[index] = item;
                }
              });

              res.data.recommendedAudios.forEach((item, index) => {
                if ( !this._homeContent.value.recommendedAudios[index] ) {
                  this._homeContent.value.recommendedAudios.push(item);
                  forceDataUpdate = true;
                } else if (item.id !== this._homeContent.value.recommendedAudios[index].id) {
                  this._homeContent.value.recommendedAudios[index] = item;
                }
              });

            } else {
              this._homeContent.next(res.data);
            }

            if ( forceDataUpdate ) {
              this._homeContent.next(this._homeContent.value);
            }

            this._homeContentUpdated.next(true);
          }
        },
        err => {
          // ERROR!
        }
      );
  }

  loadHomeVideoContent(categoriesSelected: Category[] = [], userId = 0, force: boolean = false, cache: boolean = true) {
    let forceDataUpdate: boolean = false;

    if (this.homeVideoContentRequest !== undefined) {
      this.homeVideoContentRequest.unsubscribe();
    }

    this.homeVideoContentRequest = this.http.get<JsonApiResponse<any>>(
      this.generateAbsoluteAPIURL(this.getHomeVideoContentUrl)
        .replace('{categoryId}', categoriesSelected.length === 0 ? '0' : categoriesSelected.map(cat => cat.id).toString())
        .replace('{userId}', userId.toString()))
        .subscribe(async (res) => {
          if (res.done) {
            this.recommendedUsersCache = res.data.users;
            this.isRecommendedUsersCacheValid = true;

            res.data.videos = shuffle([...res.data.uploadedVideos, ...res.data.recommendedVideos]);
            res.data.clips = shuffle(res.data.clips);

            // Remove publications with null authors
            res.data.lives = res.data.lives.filter((publication: Publication) => !!publication.author);
            res.data.videos = res.data.videos.filter((publication: Publication) => !!publication.author);
            res.data.clips = res.data.clips.filter((publication: Publication) => !!publication.author);

            // Filter viewed videos
            const viewedVideos = (await this.getViewedPublicationsByType(ContentType.Video)).map(viewed => viewed.id);
            res.data.videos = res.data.videos.filter((publication: Publication) => !viewedVideos.includes(publication.id))

            // Filter viewed clips
            const viewedClips = (await this.getViewedPublicationsByType(ContentType.Clip)).map(viewed => viewed.id);
            res.data.clips = res.data.clips.filter((publication: Publication) => !viewedClips.includes(publication.id))

            if ( cache ) {
              Preferences.set({ key: this.CACHE_HOME_LIVE_LIST, value: JSON.stringify(res.data.lives) });
              Preferences.set({ key: this.CACHE_HOME_RECOMMENDED_LIST, value: JSON.stringify(res.data.users) });
              Preferences.set({ key: this.CACHE_HOME_VIDEOS_LIST, value: JSON.stringify(res.data.videos) });
              Preferences.set({ key: this.CACHE_HOME_CLIPS_LIST, value: JSON.stringify(res.data.clips) });
            }

            if ( res.debug ) {
              Preferences.get({ key: this.CACHE_HOME_DEBUG_LIVE_LIST })
              .then((data) => {
                if ( data.value ) {
                  let list = JSON.parse(data.value) || [];
                  list = [...list, ...res.debug.lives];
                  list = list.reverse().filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);
                  Preferences.set({ key: this.CACHE_HOME_DEBUG_LIVE_LIST, value: JSON.stringify(list) })
                } else {
                  Preferences.set({ key: this.CACHE_HOME_DEBUG_LIVE_LIST, value: JSON.stringify(res.debug.lives) })
                }
              })

              Preferences.get({ key: this.CACHE_HOME_DEBUG_VIDEOS_LIST })
              .then((data) => {
                if ( data.value ) {
                  let list = JSON.parse(data.value) || [];
                  list = [...list, ...res.debug.uploadedVideos];
                  list = list.reverse().filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);
                  Preferences.set({ key: this.CACHE_HOME_DEBUG_VIDEOS_LIST, value: JSON.stringify(list) })
                } else {
                  Preferences.set({ key: this.CACHE_HOME_DEBUG_VIDEOS_LIST, value: JSON.stringify(res.debug.uploadedVideos) })
                }
              })

              Preferences.get({ key: this.CACHE_HOME_DEBUG_CLIPS_LIST })
              .then((data) => {
                if ( data.value ) {
                  let list = JSON.parse(data.value) || [];
                  list = [...list, ...res.debug.clips];
                  list = list.reverse().filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);
                  Preferences.set({ key: this.CACHE_HOME_DEBUG_CLIPS_LIST, value: JSON.stringify(list) })
                } else {
                  Preferences.set({ key: this.CACHE_HOME_DEBUG_CLIPS_LIST, value: JSON.stringify(res.debug.clips) })
                }
              })
            }

            if (!force && !!this._homeVideoContent.getValue()) {

              !this._homeVideoContent.value.lives ? this._homeVideoContent.value.lives = [] : null;
              !this._homeVideoContent.value.users ? this._homeVideoContent.value.users = [] : null;
              !this._homeVideoContent.value.videos ? this._homeVideoContent.value.videos = [] : null;
              !this._homeVideoContent.value.clips ? this._homeVideoContent.value.clips = [] : null;

              res.data.lives.forEach((item, index) => {
                this._homeVideoContent.value.lives[index]
                  ? this._homeVideoContent.value.lives[index] = item
                  : this._homeVideoContent.value.lives.push(item);
              });

              res.data.users.forEach((item, index) => {
                this._homeVideoContent.value.users[index]
                  ? this._homeVideoContent.value.users[index] = item
                  : this._homeVideoContent.value.users.push(item);
              });

              res.data.videos.forEach((item, index) => {
                this._homeVideoContent.value.videos[index]
                  ? this._homeVideoContent.value.videos[index] = item
                  : this._homeVideoContent.value.videos.push(item);
              });

              res.data.clips.forEach((item, index) => {
                this._homeVideoContent.value.clips[index]
                  ? this._homeVideoContent.value.clips[index] = item
                  : this._homeVideoContent.value.clips.push(item);
              });
            } else {
              this._homeVideoContent.next(res.data);
            }

            if ( forceDataUpdate ) {
              this._homeVideoContent.next(this._homeVideoContent.value);
            }

            this._homeContentUpdated.next(true);
          }
        },
        err => {
          // ERROR!
        }
      );
  }

  loadHomeAudioContent(categoriesSelected: Category[] = [], userId = 0, force: boolean = false, cache: boolean = true) {    
    let forceDataUpdate: boolean = false;

    if (this.homeAudioContentRequest !== undefined) {
      this.homeAudioContentRequest.unsubscribe();
    }

    const podcastRequest = this.loadUploadedAudios(0, 10, categoriesSelected, userId, cache);
    const audioRoomsRequest = this.loadAudios(0, 10, categoriesSelected, userId, cache);

    this.homeAudioContentRequest = forkJoin({ podcasts: podcastRequest, audioRooms: audioRoomsRequest })
    .subscribe(async (res) => {

      // Remove publications with null authors
      res.podcasts.data.list = res.podcasts.data.list.filter((publication: Publication) => !!publication.author);
      res.audioRooms.data.list = res.audioRooms.data.list.filter((publication: Publication) => !!publication.author);

      res.podcasts.data.list = shuffle(res.podcasts.data.list);

      // Filter viewed videos
      const viewedPodcasts = (await this.getViewedPublicationsByType(ContentType.Audio)).map(viewed => viewed.id);
      res.podcasts.data.list = res.podcasts.data.list.filter((publication: Publication) => !viewedPodcasts.includes(publication.id))

      if (!force && !!this._homeAudioContent.getValue()) {

        !this._homeAudioContent.value.audios ? this._homeAudioContent.value.audios = [] : null;
        !this._homeAudioContent.value.podcasts ? this._homeAudioContent.value.podcasts = [] : null;

        res.audioRooms.data.list.forEach((item, index) => {
          this._homeAudioContent.value.audios[index] 
            ? this._homeAudioContent.value.audios[index] = item
            : this._homeAudioContent.value.audios.push(item);
        });

        res.podcasts.data.list.forEach((item, index) => {
          this._homeAudioContent.value.podcasts[index]
            ? this._homeAudioContent.value.podcasts[index] = item
            : this._homeAudioContent.value.podcasts.push(item)
        });
      } else {
        this._homeAudioContent.next({
          podcasts: res.podcasts.data.list,
          audios: res.audioRooms.data.list
        });
      }

      if ( forceDataUpdate ) {
        this._homeAudioContent.next(this._homeAudioContent.value);
      }

      this._homeContentUpdated.next(true);
    })
  }

  loadHomeEventContent(categoriesSelected: Category[] = [], userId = 0, force: boolean = false, cache: boolean = true, filters: any = {}) {
    let forceDataUpdate: boolean = false;

    if (this.homeEventContentRequest !== undefined) {
      this.homeEventContentRequest.unsubscribe();
    }

    const eventRequest = this.loadEvents(0, 20, categoriesSelected, userId, false, cache, filters);

    this.homeEventContentRequest = forkJoin({ events: eventRequest })
    .subscribe(async (res) => {
      // Remove publications with null authors
      res.events.data.list = res.events.data.list.filter((publication: Publication) => !!publication.author);

      if (!force && !!this._homeEventContent.getValue()) {
        !this._homeEventContent.value.events ? this._homeEventContent.value.events = [] : null;

        res.events.data.list.forEach((item, index) => {
          this._homeEventContent.value.events[index]
            ? this._homeEventContent.value.events[index] = item
            : this._homeEventContent.value.events.push(item)
        });
      } else {
        this._homeEventContent.next({
          events: res.events.data.list,
          total: res.events.data.meta.total
        });
      }

      if ( forceDataUpdate ) {
        this._homeEventContent.next(this._homeEventContent.value);
      }

      this._homeContentUpdated.next(true);
    })
  }

  loadLives(offset = 0, limit = 10, categoriesSelected: Category[] = [], userId = 0, cache: boolean = false) {
    return this.http.get<JsonApiResponsePaginated<Publication[]>>(
      this.generateAbsoluteAPIURL(this.getLivesUrl)
        .replace('{offset}', offset.toString())
        .replace('{limit}', limit.toString())
        .replace('{categoryId}', categoriesSelected.length === 0 ? '0' : categoriesSelected.map(cat => cat.id).toString())
        .replace('{userId}', userId.toString())
    ).pipe(map((res) => {

      if ( res?.done ) {
        // Remove publications with null authors
        res.data.list = res.data.list.filter((publication: Publication) => !!publication.author);

        cache ? Preferences.set({ key: this.CACHE_HOME_LIVE_LIST, value: JSON.stringify(res.data.list) }) : null;
      }

      return res;
    }));
  }

  loadAudios(offset = 0, limit = 10, categoriesSelected: Category[] = [], userId = 0, cache: boolean = false) {
    return this.http.get<JsonApiResponsePaginated<Publication[]>>(
      this.generateAbsoluteAPIURL(this.getAudiosUrl).replace('{offset}', offset.toString())
        .replace('{limit}', limit.toString())
        .replace('{categoryId}', categoriesSelected.length === 0 ? '0' : categoriesSelected.map(cat => cat.id).toString())
        .replace('{userId}', userId.toString())
    ).pipe(map((res) => {

      if ( res?.done ) {
        // Remove publications with null authors
        res.data.list = res.data.list.filter((publication: Publication) => !!publication.author);
      
        if ( res.data.debug ) {
          Preferences.get({ key: this.CACHE_HOME_DEBUG_AUDIO_LIST })
          .then((data) => {
            if ( data.value ) {
              let list = JSON.parse(data.value) || [];
              list = [...list, ...res.data.debug];
              list = list.reverse().filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);
              Preferences.set({ key: this.CACHE_HOME_DEBUG_AUDIO_LIST, value: JSON.stringify(list) })
            } else {
              Preferences.set({ key: this.CACHE_HOME_DEBUG_AUDIO_LIST, value: JSON.stringify(res.data.debug) })
            }
          })
        }

        cache ? Preferences.set({ key: this.CACHE_HOME_AUDIO_LIST, value: JSON.stringify(res.data.list) }) : null;
      }

      return res;
    }));
  }

  loadFollowing(offset = 0, limit = 10, categoriesSelected: Category[] = []) {
    return this.http.get<JsonApiResponsePaginated<Publication[]>>(
      this.generateAbsoluteAPIURL(this.getFollowingUrl).replace('{offset}', offset.toString())
        .replace('{limit}', limit.toString())
        .replace('{categoryId}', categoriesSelected.length === 0 ? '0' : categoriesSelected.map(cat => cat.id).toString()));
  }

  loadRecommendedUsers(force: boolean = false): Observable<User[]> {
    if (this.isRecommendedUsersCacheValid && !force) {
      return of(this.recommendedUsersCache);
    }
    return this.http.get<JsonApiResponsePaginated<User[]>>(this.generateAbsoluteAPIURL(this.getRecommendedUsersUrl)
      .replace('{offset}', '0')
      .replace('{limit}', '20')
      .replace('{categoryId}', '0')
    ).pipe(map((res) => {
      if (res.done) {
        this.isRecommendedUsersCacheValid = true;
        this.recommendedUsersCache = res.data.list;
        Preferences.set({ key: this.CACHE_HOME_RECOMMENDED_LIST, value: JSON.stringify(res.data.list) });
        
        if ( res.data.debug ) {
          Preferences.get({ key: this.CACHE_HOME_DEBUG_RECOMMENDED_LIST })
          .then((data) => {
            if ( data.value ) {
              let list = JSON.parse(data.value) || [];
              list = [...list, ...res.data.debug];
              list = list.reverse().filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);
              Preferences.set({ key: this.CACHE_HOME_DEBUG_RECOMMENDED_LIST, value: JSON.stringify(list) })
            } else {
              Preferences.set({ key: this.CACHE_HOME_DEBUG_RECOMMENDED_LIST, value: JSON.stringify(res.data.debug) })
            }
          })
        }
      }
      return res.data.list;
    }));
  }

  loadAllRecommendedUsers(offset: number, limit: number, categoriesSelected: Category[]): Observable<JsonApiResponsePaginated<User[]>> {
    return this.http.get<JsonApiResponsePaginated<User[]>>(
      this.generateAbsoluteAPIURL(this.getRecommendedUsersUrl)
        .replace('{offset}', offset.toString())
        .replace('{limit}', limit.toString())
        .replace('{categoryId}', categoriesSelected.length === 0 ? '0' : categoriesSelected.map(cat => cat.id).toString()))
      .pipe(map(res => {
        if ( res.data.debug ) {
          Preferences.get({ key: this.CACHE_HOME_DEBUG_RECOMMENDED_LIST })
          .then((data) => {
            if ( data.value ) {
              let list = JSON.parse(data.value) || [];
              list = [...list, ...res.data.debug];
              list = list.reverse().filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);
              Preferences.set({ key: this.CACHE_HOME_DEBUG_RECOMMENDED_LIST, value: JSON.stringify(list) })
            } else {
              Preferences.set({ key: this.CACHE_HOME_DEBUG_RECOMMENDED_LIST, value: JSON.stringify(res.data.debug) })
            }
          })
        }

        return res;
      }));
  }

  loadRecommendedVideos(offset = 0, limit = 10, categoriesSelected: Category[] = [], userId = 0) {
    return this.http.get<JsonApiResponsePaginated<Publication[]>>(
      this.generateAbsoluteAPIURL(this.getRecommendedVideosUrl).replace('{offset}', offset.toString())
        .replace('{limit}', limit.toString())
        .replace('{categoryId}', categoriesSelected.length === 0 ? '0' : categoriesSelected.map(cat => cat.id).toString()
        ).replace('{userId}', userId.toString())
    ).pipe(map((res) => {
      if ( res?.done ) {
        // Remove publications with null authors
        res.data.list = res.data.list.filter((publication: Publication) => !!publication.author);
      }
      return res;
    }));
  }

  loadRecommendedAudios(offset = 0, limit = 10, categoriesSelected: Category[] = [], userId = 0) {
    return this.http.get<JsonApiResponsePaginated<Publication[]>>(
      this.generateAbsoluteAPIURL(this.getRecommendedAudiosUrl).replace('{offset}', offset.toString())
        .replace('{limit}', limit.toString())
        .replace('{categoryId}', categoriesSelected.length === 0 ? '0' : categoriesSelected.map(cat => cat.id).toString()
        ).replace('{userId}', userId.toString())
    ).pipe(map((res) => {
      if ( res?.done ) {
        // Remove publications with null authors
        res.data.list = res.data.list.filter((publication: Publication) => !!publication.author);
      }
      return res;
    }));;
  }

  loadOneContent(offset = 0, limit = 10, categoriesSelected: Category[] = [], userId = 0, audio = null, cache: boolean = false) {
    return this.http.get<JsonApiResponsePaginated<Publication[]>>(
      this.generateAbsoluteAPIURL(this.getOneContentUrl).replace('{offset}', offset.toString())
        .replace('{limit}', limit.toString())
        .replace('{categoryId}', categoriesSelected.length === 0 ? '0' : categoriesSelected.map(cat => cat.id).toString())
        .replace('{userId}', userId.toString())
        .replace('{audio}', audio !== null ? audio.toString() : null)
    ).pipe(map((res) => {

      if ( res?.done ) {
        // Remove publications with null authors
        res.data.list = res.data.list.filter((publication: Publication) => !!publication.author);

        if ( cache ) {
          if ( audio !== null && audio.toString() === '1' ) {
            Preferences.set({ key: this.CACHE_HOME_ONEAUDIOS_LIST, value: JSON.stringify(res.data.list) });
          } else {
            Preferences.set({ key: this.CACHE_HOME_ONEVIDEOS_LIST, value: JSON.stringify(res.data.list) });
          }
        }
      }

      return res;
    }));
  }

  loadUploadedVideos(offset = 0, limit = 10, categoriesSelected: Category[] = [], userId = 0, cache: boolean = false) {
    return this.http.get<JsonApiResponsePaginated<Publication[]>>(
      this.generateAbsoluteAPIURL(this.getUploadedVideosUrl).replace('{offset}', offset.toString())
        .replace('{limit}', limit.toString())
        .replace('{categoryId}', categoriesSelected.length === 0 ? '0' : categoriesSelected.map(cat => cat.id).toString())
        .replace('{userId}', userId.toString())
    ).pipe(map((res) => {

      if ( res?.done ) {
        // Remove publications with null authors
        res.data.list = res.data.list.filter((publication: Publication) => !!publication.author);

        cache ? Preferences.set({ key: this.CACHE_HOME_VIDEOS_LIST, value: JSON.stringify(res.data.list) }) : null;
      
        if ( res.data.debug ) {
          Preferences.get({ key: this.CACHE_HOME_DEBUG_VIDEOS_LIST })
          .then((data) => {
            if ( data.value ) {
              let list = JSON.parse(data.value) || [];
              list = [...list, ...res.data.debug];
              list = list.reverse().filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);
              Preferences.set({ key: this.CACHE_HOME_DEBUG_VIDEOS_LIST, value: JSON.stringify(list) })
            } else {
              Preferences.set({ key: this.CACHE_HOME_DEBUG_VIDEOS_LIST, value: JSON.stringify(res.data.debug) })
            }
          })
        }
      }

      return res;
    }));
  }

  loadUploadedAudios(offset = 0, limit = 10, categoriesSelected: Category[] = [], userId = 0, cache: boolean = false) {
    return this.http.get<JsonApiResponsePaginated<Publication[]>>(
      this.generateAbsoluteAPIURL(this.getUploadedAudiosUrl).replace('{offset}', offset.toString())
        .replace('{limit}', limit.toString())
        .replace('{categoryId}', categoriesSelected.length === 0 ? '0' : categoriesSelected.map(cat => cat.id).toString())
        .replace('{userId}', userId.toString())
    ).pipe(map((res) => {

      if ( res?.done ) {
        // Remove publications with null authors
        res.data.list = res.data.list.filter((publication: Publication) => !!publication.author);

        cache ? Preferences.set({ key: this.CACHE_HOME_PODCASTS_LIST, value: JSON.stringify(res.data.list) }) : null;
      
        if ( res.data.debug ) {
          Preferences.get({ key: this.CACHE_HOME_DEBUG_PODCASTS_LIST })
          .then((data) => {
            if ( data.value ) {
              let list = JSON.parse(data.value) || [];
              list = [...list, ...res.data.debug];
              list = list.reverse().filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);
              Preferences.set({ key: this.CACHE_HOME_DEBUG_PODCASTS_LIST, value: JSON.stringify(list) })
            } else {
              Preferences.set({ key: this.CACHE_HOME_DEBUG_PODCASTS_LIST, value: JSON.stringify(res.data.debug) })
            }
          })
        }
      }

      return res;
    }));
  }

  loadPremieres(offset = 0, limit = 10, categoriesSelected: Category[] = [], userId = 0, showPassedEvents: any = false, cache: boolean = false) {
    showPassedEvents = showPassedEvents ? '1' : '0';
    return this.http.get<JsonApiResponsePaginated<Event[]>>(
      this.generateAbsoluteAPIURL(this.getPremieresUrl).replace('{offset}', offset.toString())
        .replace('{limit}', limit.toString())
        .replace('{categoryId}', categoriesSelected.length === 0 ? '0' : categoriesSelected.map(cat => cat.id).toString())
        .replace('{userId}', userId.toString())
        .replace('{showPassedEvents}', showPassedEvents)
    ).pipe(map((res) => {

      if ( res?.done ) {
        // Remove publications with null authors
        res.data.list = res.data.list.filter((publication: Event) => !!publication.author);

        cache ? Preferences.set({ key: this.CACHE_HOME_PREMIERE_LIST, value: JSON.stringify(res.data.list) }) : null;
      }

      return res;
    }));
  }

  loadClips(offset = 0, limit = 10, categoriesSelected: Category[] = [], userId = 0, cache: boolean = false) {
    return this.http.get<JsonApiResponsePaginated<Publication[]>>(
      this.generateAbsoluteAPIURL(this.getClipsUrl).replace('{offset}', offset.toString())
        .replace('{limit}', limit.toString())
        .replace('{categoryId}', categoriesSelected.length === 0 ? '0' : categoriesSelected.map(cat => cat.id).toString())
        .replace('{userId}', userId.toString())
    ).pipe(map((res) => {

      if ( res?.done ) {
        // Remove publications with null authors
        res.data.list = res.data.list.filter((publication: Publication) => !!publication.author);

        cache ? Preferences.set({ key: this.CACHE_HOME_CLIPS_LIST, value: JSON.stringify(res.data.list) }) : null;

        if ( res.data.debug ) {
          Preferences.get({ key: this.CACHE_HOME_DEBUG_CLIPS_LIST })
          .then((data) => {
            if ( data.value ) {
              let list = JSON.parse(data.value) || [];
              list = [...list, ...res.data.debug];
              list = list.reverse().filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);
              Preferences.set({ key: this.CACHE_HOME_DEBUG_CLIPS_LIST, value: JSON.stringify(list) })
            } else {
              Preferences.set({ key: this.CACHE_HOME_DEBUG_CLIPS_LIST, value: JSON.stringify(res.data.debug) })
            }
          })
        }
      }

      return res;
    }));
  }

  searchBar(
    searchTerm,
    gender: number,
    country: string,
    category: number,
    searchSection: SearchSection = SearchSection.Global,
    offset: number = 0,
    limit: number = 20
  ) {
    return this.http.post<JsonApiResponse<SuggestionList>>(this.generateAbsoluteAPIURL(this.getSearchTermUrl),
      {
        searchTerm,
        gender,
        country,
        category,
        searchSection,
        offset,
        limit
      }
    ).pipe(map((res) => {

      if ( res?.done ) {
        // Remove publications with null authors
        res.data.audios ? res.data.audios.data.list = res.data.audios.data.list.filter((publication: Publication) => !!publication.author) : null;
        res.data.oneAudios ? res.data.oneAudios.data.list = res.data.oneAudios.data.list.filter((publication: Publication) => !!publication.author) : null;
        res.data.oneVideos ? res.data.oneVideos.data.list = res.data.oneVideos.data.list.filter((publication: Publication) => !!publication.author) : null;
        res.data.premieres ? res.data.premieres.data.list = res.data.premieres.data.list.filter((publication: Event) => !!publication.author) : null;
        res.data.videos ? res.data.videos.data.list = res.data.videos.data.list.filter((publication: Publication) => !!publication.author) : null;
        res.data.events ? res.data.events.data.list = res.data.events.data.list.filter((publication: Event) => !!publication.author) : null;
        res.data.liveAudios ? res.data.liveAudios.data.list = res.data.liveAudios.data.list.filter((publication: Publication) => !!publication.author) : null;
        res.data.liveVideos ? res.data.liveVideos.data.list = res.data.liveVideos.data.list.filter((publication: Publication) => !!publication.author) : null;
        res.data.clips ? res.data.clips.data.list = res.data.clips.data.list.filter((publication: Publication) => !!publication.author) : null;
      }

      return res;
    }));
  }

  chargeSlidingList(publications: Publication[], currentSlidingItem) {
    this.slidingList = publications;
    this.currentSlidingItem = publications.indexOf(currentSlidingItem);
  }

  cleanFeed() {
    this._homeContent.next(undefined);
    this._homeVideoContent.next(undefined);
    this._homeAudioContent.next(undefined);
  }

  async getRecentUserList(): Promise<User[]> {
    let userList;

    const jsonEvents = await Preferences.get({key: this.CACHE_SEARCH_RECENT_USER_LIST});

    if (jsonEvents.value) {
      userList = JSON.parse(jsonEvents.value);
      return userList;
    } else {
      return [];
    }
  }

  async cacheRecentUser(user: User) {
    let userList;

    // Get user list from storage
    const jsonEvents = await Preferences.get({key: this.CACHE_SEARCH_RECENT_USER_LIST});

    // Check if data stored
    if (jsonEvents.value) {
      userList = JSON.parse(jsonEvents.value);
      userList = userList.filter((u: User) => u.id !== user.id);
    } else {
      userList = [];
    }

    // Add user
    userList.unshift(user);

    // Keep only 10 users in this list
    userList = userList.slice(0, 10);

    // Save data to storage
    await Preferences.set({key: this.CACHE_SEARCH_RECENT_USER_LIST, value: JSON.stringify(userList)});
  }

  async deleteRecentUserFromCache(user: User) {
    let userList;

    // Get user list from storage
    const jsonEvents = await Preferences.get({key: this.CACHE_SEARCH_RECENT_USER_LIST});

    // Check if data stored
    if (jsonEvents.value) {
      userList = JSON.parse(jsonEvents.value);
      userList = userList.filter((u: User) => u.id !== user.id);
    }

    // Save data to storage
    await Preferences.set({key: this.CACHE_SEARCH_RECENT_USER_LIST, value: JSON.stringify(userList)});
  }

  async cleanRecentUserListCache() {
    await Preferences.remove({key: this.CACHE_SEARCH_RECENT_USER_LIST});
  }

  /**
   * Update a key of a publication
   *
   * @param id Publication identified
   * @param key Name of the key to update
   * @param value Data to store for the key
   */
  updatePublicationKey(id: number, key: string, value: any) {
    let homeContent;

    switch ( key ) {
      case 'lives':
      case 'videos':
      case 'clips':
        homeContent = this._homeVideoContent.getValue();
        break;
      case 'audios':
      case 'podcasts':
        homeContent = this._homeAudioContent.getValue();
        break;
    }

    if ( homeContent !== undefined ) {
      let feed = [];

      // Generate single array with all elements excluding users
      Object.keys(homeContent)
        .filter(key => key !== 'users')
        .forEach(key => {
          feed = [...feed, ...homeContent[key]];
        });

      // Find publication to modify
      const publication = feed.find(publication => publication.id === id);

      // Update key
      if ( publication ) {
        publication[key] = value;
      }
    }
  }

  /**
   * Mark all categories as not selected
   */
  cleanCategoriesSelection() {
    this.categories.forEach(category => category.selected = false);
  }

  /**
   * Update a key of a publication
   *
   * @param {ContentType} type Publication type
   * @param {number} id Publication identifier
   */
  removePublicationFromCache(type: ContentType, id: number) {
    switch (type) {
      case ContentType.LiveVideo:
        this._homeVideoContent.value.lives = this._homeVideoContent.value.lives.filter(item => item.id !== id);
        Preferences.set({ key: this.CACHE_HOME_LIVE_LIST, value: JSON.stringify(this._homeVideoContent.value.lives) });
        break;
      case ContentType.LiveAudio:
        this._homeAudioContent.value.audios = this._homeAudioContent.value.audios.filter(item => item.id !== id);
        Preferences.set({ key: this.CACHE_HOME_AUDIO_LIST, value: JSON.stringify(this._homeAudioContent.value.audios) });
        break;
      case ContentType.Video:
        this._homeVideoContent.value.videos = this._homeVideoContent.value.videos.filter(item => item.id !== id);
        Preferences.set({ key: this.CACHE_HOME_VIDEOS_LIST, value: JSON.stringify(this._homeVideoContent.value.videos) });
        break;
      case ContentType.Audio:
        this._homeAudioContent.value.podcasts = this._homeAudioContent.value.podcasts.filter(item => item.id !== id);
        Preferences.set({ key: this.CACHE_HOME_PODCASTS_LIST, value: JSON.stringify(this._homeAudioContent.value.podcasts) });
        break;
      case ContentType.Clip:
        this._homeVideoContent.value.clips = this._homeVideoContent.value.clips.filter(item => item.id !== id);
        Preferences.set({ key: this.CACHE_HOME_CLIPS_LIST, value: JSON.stringify(this._homeVideoContent.value.clips) });
        break;
      default:
        break;
    }
  }

  async loadHomeLayout() {
    // Get home layout from storage
    const jsonEvents = await Preferences.get({key: this.KEY_HOME_LAYOUT});

    // Check if data stored
    if (jsonEvents.value) {
      this._homeLayout.next(Number(jsonEvents.value));
    }
  }
  setHomeLayout(layout: HomeLayout) {
    this._homeLayout.next(layout);
    Preferences.set({key: this.KEY_HOME_LAYOUT, value: layout.toString()});
  }

  private cleanCachedData() {
    Preferences.keys().then(({keys}) => {
      keys.forEach(key => {
        if (key.includes('FEED_CACHE')) {
          Preferences.remove({key});
        }
      });
    });
  }

  /**
   * Get viewed publications by type
   * @param type 
   * @returns publications
   */
  async getViewedPublicationsByType(type: ContentType): Promise<ViewedPublication[]> {
    try {
      const json = await Preferences.get({key: this.getViewedPublicationCacheKey(type)});
      const data = JSON.parse(json.value);

      return data || [];
    } catch(e) {
      return [];
    }
  }
  /**
   * Cache a publication as viewed
   * @param type 
   * @param id 
   */
  cacheViewedPublication(type: ContentType, id: number) {
    this.checkPublicationViewed(type, id).then(async (viewed: boolean) => {
      if ( !viewed ) {
        const publications = await this.getViewedPublicationsByType(type);

        publications.push({
          id: id,
          age: new Date().getTime()
        })

        Preferences.set({ 
          key: this.getViewedPublicationCacheKey(type), 
          value: JSON.stringify(publications) 
        });
      }
    })
  }
  /**
   * Delete publication from viewed cache
   * @param type 
   * @param id 
   */
  deleteViewedPublication(type: ContentType, id: number) {
    this.getViewedPublicationsByType(type).then((publications: ViewedPublication[]) => {
      Preferences.set({ 
        key: this.getViewedPublicationCacheKey(type), 
        value: JSON.stringify(publications.filter(publication => publication.id !== id)) 
      });
    });
  }
  /**
   * Get cache key of publication type
   * @param type 
   * @returns cache key
   */
  getViewedPublicationCacheKey(type: ContentType): string {
    let key: string = ''

    switch( type ) {
      case ContentType.Video: key = this.CACHE_VIEWED_VIDEOS_KEY; break;
      case ContentType.Audio: key = this.CACHE_VIEWED_PODCASTS_KEY; break;
      case ContentType.Clip: key = this.CACHE_VIEWED_CLIPS_KEY; break;
    }

    return key;
  }
  /**
   * Check if a publication was viewed
   * @param type 
   * @param id 
   * @returns status if was viewed
   */
  async checkPublicationViewed(type: ContentType, id: number): Promise<boolean> {
    const publications = await this.getViewedPublicationsByType(type);

    const found = publications.find((item: ViewedPublication) => item.id === id);

    if ( found ) {
      const currentDate = new Date().getTime();

      if ( currentDate - Number(found.age) > 432000000 ) {
        this.deleteViewedPublication(type, id);
        return false;
      } else {
        found.age = new Date().getTime();
        Preferences.set({ 
          key: this.getViewedPublicationCacheKey(type), 
          value: JSON.stringify(publications) 
        });

        return true;
      }
    } else {
      return false;
    }
  }
}
