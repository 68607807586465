<div class="audio-container">
  <ion-button
    fill="clear"
    class="button-control"
    aria-label="play"
    (click)="playPlauseAudio()">

    <ion-icon *ngIf="audioIsPaused" name="play"></ion-icon>
    <ion-icon *ngIf="!audioIsPaused" name="pause"></ion-icon>
  </ion-button>

  <div class="range"
       [ngClass]="{'playing': !audioIsPaused}">
    <ion-range
      #range
      min="0"
      [max]="audioDuration !== undefined ? audioDuration : 100"
      [value]="audioDuration !== undefined ? audioProgress : 0"
      color="primary"
      pin="false"
      mode="md"
      debounce="0"
      (touchstart)="pauseWhileSeeking()"
      (mousedown)="pauseWhileSeeking()"
      (touchend)="seek($event)"
      (mouseup)="seek($event)">
    </ion-range>
  </div>

  <ion-button
    fill="clear"
    class="speed-control"
    *ngIf="!audioIsPaused"
    (click)="changePlaybackRate()">
    <ion-chip>
      <ion-label>{{playbackRate}}x</ion-label>
    </ion-chip>
  </ion-button>

  
</div>
