import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Publication} from '../../../../interfaces/publication';
import {FeedService} from '../../../../services/feed.service';
import {Events} from '../../../../services/events.service';
import {MediaService} from '../../../../services/media.service';
import {environment} from '../../../../../environments/environment';
import {UserService} from '../../../../services/user.service';
import {RouterDataService} from '../../../../services/router-data.service';
import {AuthService} from '../../../../services/auth.service';
import {User} from '../../../../interfaces/user';

@Component({
  selector: 'app-sidebar-audio-rooms',
  templateUrl: './audio-rooms.component.html',
  styleUrls: ['./audio-rooms.component.scss', '../../sidebar.component.scss'],
})
export class AudioRoomsComponent  implements OnInit {
  recommendedAudiosList: Publication[] = [];
  user: User;
  isLoading = true;
  sub: any = {};

  constructor(
    private feedService: FeedService,
    private events: Events,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private mediaService: MediaService,
    private userService: UserService,
    private routerDataService: RouterDataService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.subscribeLiveList();
    this.user = this.authService.user;

    this.sub.liveCreated = this.events.subscribe(environment.EVENTS.LIVE_CREATED, () => {
      this.subscribeLiveList();
    });
    this.subscribeToRemoveItemFromList();
  }

  getPublicationImg(publication: Publication) {
    if (publication.screenshot && publication.screenshot !== '' && publication.emitting) {
      return this.mediaService.generatePublicationThumbnailImgURL(publication.id, publication.screenshot) + '?v=' +
        new Date().getTime();
    } else {
      return this.mediaService.generatePublicationThumbnailImgURL(publication.id, publication.thumbnail);
    }
  }

  goToPublication(publication: Publication) {
    if (publication.isPremium &&
      !(publication.collaborating || publication.subscribed || publication.author.id === this.authService.user.id)) {
      this.userService.subscribeToUser(publication.author);
    } else {
      this.routerDataService.setData(publication);
      this.router.navigate([`/publications/view/${publication.id}`]);
    }
  }

  goToViewAll() {
    this.router.navigate(['/publications/view-all/recommendedAudios']);
  }

  private subscribeLiveList() {
    this.isLoading = true;
    this.cdRef.detectChanges();
    this.feedService.homeContent.subscribe(data => {
      if (!data) {
        return;
      }
      if (!!data.recommendedAudios) {
        this.recommendedAudiosList = data.recommendedAudios;
      }
      this.isLoading = false;
      this.cdRef.detectChanges();
    });
  }

  private subscribeToRemoveItemFromList() {
    this.events.subscribe(environment.EVENTS.PUBLICATION_BLOCKED, (res) => {
      if (res.type === 'live') {
        this.recommendedAudiosList = this.recommendedAudiosList.filter(l => l.id !== res.id);
      }
      this.cdRef.detectChanges();
    });
  }
}
