import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

import { FeedService } from '../../../../services/feed.service';

import { Event } from '../../../../interfaces/event';

import { ContentType } from '../../../../enums/content-type';
import { FormMode } from '../../../../enums/form-mode';

export enum PageMode {
  List,
  Form,
  Media
}

@Component({
  selector: 'app-event-premiere',
  templateUrl: './event-premiere.component.html'
})
export class EventPremiereComponent implements OnInit, OnDestroy {

  @Input() pageMode: PageMode = PageMode.List;
  @Input() rootPage: any | undefined = undefined;
  @Input() type: ContentType;
  @Input() mode: FormMode = FormMode.Create;
  @Input() event: Event;

  constructor( private feedService: FeedService ) { }

  ngOnInit() { }

  ngOnDestroy() {
    this.feedService.cleanCategoriesSelection();
  }
}
