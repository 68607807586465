import { Component, EventEmitter, Input, Output } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { User } from '../../../app/interfaces/user';
import { MediaService } from '../../services/media.service';
import { Collaborator } from '../../interfaces/collaborator';
import { AlertController } from '@ionic/angular';
import { environment } from '../../../environments/environment';
import { ProfileService } from '../../services/profile.service';
import { Events } from '../../services/events.service';
import {goToUserProfile} from "../../utils/routing";

@Component({
  selector: 'search-contact-item',
  templateUrl: environment.isWeb ? './search-contact-item-desktop.component.html' : './search-contact-item.component.html',
  styleUrls: environment.isWeb ? ['./search-contact-item.component.scss', './search-contact-item-desktop.component.scss'] : ['./search-contact-item.component.scss'],
})
export class SearchContactItemComponent {
  constructor(private router: Router, private mediaService: MediaService, private alertCtrl: AlertController,
              private profileService: ProfileService, private activatedRoute: ActivatedRoute, private events: Events) {
  }

  @Input()
  user;
  @Input() collaborator: Collaborator = null;
  @Input()
  followed: boolean = null;

  @Input()
  subscribed: boolean = null;

  @Input()
  itsMe = false;

  @Input()
  subscribeMode = false;

  @Input()
  collaborateWithMe = false;

  @Input()
  collaborateRequest = false;

  @Input() removable = false;

  @Input() skeleton: boolean = false;

  @Output()
  followUser: EventEmitter<User> = new EventEmitter<User>();

  @Output()
  unfollowUser: EventEmitter<User> = new EventEmitter<User>();

  @Output()
  subscribeUser: EventEmitter<any> = new EventEmitter<any>();

  @Output() collaboratorAccepted = new EventEmitter<Collaborator>();
  @Output() collaboratorRemoved = new EventEmitter<User>();

  @Output() profileVisited = new EventEmitter<boolean>();

  @Output() removeUser: EventEmitter<User> = new EventEmitter<User>();

  emitUnfollowUser(user) {
    this.unfollowUser.emit(user);
  }

  emitFollowUser(user) {
    this.followUser.emit(user);
  }

  goToProfile(user: { username: string }) {
    if (!user || this.itsMe) {return;}
    this.profileVisited.emit(true);
    this.router.navigate([goToUserProfile(this.activatedRoute.snapshot.url[0]?.path || '', user.username)]);
  }

  getImageProfile() {
    return this.mediaService.generateImgProfileURL(this.user.id, this.user.imgProfile);
  }

  acceptCollaboration() {
    this.collaboratorAccepted.emit(this.collaborator);

    this.profileService.answerCollabInvitation(true, this.collaborator.id, false).subscribe(res => {
      if (res.done) {
        const previousId = this.collaborator.id;
        const accept = true;
        const isEvent = false;
        this.events.publish(environment.EVENTS.COLLABORATION_ANSWERED, {previousId, collaboration: res.data, accept, isEvent});
      }
    });
  }
  async presentAlertRemoveCollab() {
    if (!this.collaborator) {
      return;
    }

    if (this.collaborator.invitationAccepted) {
      const alert = await this.alertCtrl.create({
        cssClass: 'my-custom-alert-class',
        message: `Eliminar colaborador`,
        buttons: [
          {
            text: 'Aceptar',
            cssClass: 'alert-button-danger',
            handler: () => {
              this.removeCollab(this.collaborator.user);
            }
          },
          {
            text: 'Cancelar',
            role: 'cancel'
          },

        ]
      });
      await alert.present();
    } else if (!this.collaborator.invitationAccepted && !this.collaborateRequest) {
      const alert = await this.alertCtrl.create({
        cssClass: 'my-custom-alert-class',
        message: `Eliminar solicitud de colaboración`,
        buttons: [
          {
            text: 'Aceptar',
            cssClass: 'alert-button-danger',
            handler: () => {
              this.removeCollab(this.collaborator.user);
            }
          },
          {
            text: 'Cancelar',
            role: 'cancel'
          },

        ]
      });
      await alert.present();
    } else {
      const alert = await this.alertCtrl.create({
        cssClass: 'my-custom-alert-class',
        message: `Rechazar invitación`,
        buttons: [
          {
            text: 'Aceptar',
            cssClass: 'alert-button-danger',
            handler: () => {
              this.removeCollab(this.collaborator.user);
            }
          },
          {
            text: 'Cancelar',
            role: 'cancel'
          },

        ]
      });
      await alert.present();
    }
  }

  private removeCollab(user: User) {
    this.collaboratorRemoved.emit(user);

    this.profileService.removeCollaborator(user.id).subscribe(res => {
      if (res.done) {
        if (this.collaborator.invitationAccepted) {
          this.events.publish(environment.EVENTS.ALERT_TOAST_NOTICE, 'Colaborador eliminado.');
        } else {
          this.events.publish(environment.EVENTS.ALERT_TOAST_NOTICE, 'Invitación cancelada.');
        }
      } else {
        this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, res.msg);
      }
    });
  }
}
