import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { FeedService } from '../../services/feed.service';
import { Category } from '../../interfaces/category';
import { MediaService } from '../../services/media.service';
import { environment } from '../../../environments/environment';

import { inOutFade } from 'src/app/animations/animations';

@Component({
  selector: 'app-categories-buttons',
  templateUrl: './categories-buttons.component.html',
  styleUrls: environment.isWeb ? [
    './categories-buttons.component.scss',
    './categories-buttons-desktop.component.scss',
    '../category-button/category-button.component.scss',
  ] :  [
    './categories-buttons.component.scss',
    '../category-button/category-button.component.scss'
  ],
  animations: [inOutFade]
})
export class CategoriesButtonsComponent implements OnInit, AfterViewInit {

  @Output() categoryChanged: EventEmitter<Category> = new EventEmitter();

  @ViewChild('scrollList') scrollList: ElementRef;

  mediaServ: MediaService;

  currentCategory: Category = null;
  isWeb: boolean = environment.isWeb;

  scrollPosition: 'start' | 'middle' | 'end' = 'start';

  constructor(
    private feedService: FeedService,
    private mediaService: MediaService) {
    this.mediaServ = this.mediaService;
  }

  ngOnInit() {
    this.feedService.loadCategories().subscribe();
  }

  ngAfterViewInit() {}

  get categories() {
    return this.feedService.categories;
  }

  selectCategory(cat: Category) {
    this.currentCategory = cat;
    this.categoryChanged.emit(cat);
  }

  onListScroll($event) {
    const scrollLeft = this.scrollList.nativeElement.scrollLeft;
    const scrollWidth = this.scrollList.nativeElement.scrollWidth;
    const offsetWidth = this.scrollList.nativeElement.offsetWidth;

    if ( scrollLeft < 30 ) {
      this.scrollPosition = 'start';
    } else if ( scrollLeft + offsetWidth > scrollWidth - 30 ) {
      this.scrollPosition = 'end';
    } else {
      this.scrollPosition = 'middle';
    }
  }

  scrollLeft() {
    this.scrollList.nativeElement.scrollTo({ 
      left: this.scrollList.nativeElement.scrollLeft - 300, 
      behavior: 'smooth' 
    });
  }
  scrollRight() {
    this.scrollList.nativeElement.scrollTo({ 
      left: this.scrollList.nativeElement.scrollLeft + 300, 
      behavior: 'smooth' 
    });
  }
}
