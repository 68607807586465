import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { AddContentPopoverOption } from '../../../enums/add-content-popover-option';

@Component({
  selector: 'app-add-content-popover',
  templateUrl: './add-content-popover.component.html',
  styleUrls: ['./add-content-popover.component.scss'],
})
export class AddContentPopoverComponent implements OnInit {

  AddContentPopoverOption = AddContentPopoverOption;

  constructor( private popoverCtrl: PopoverController ) { }

  ngOnInit() {}

  pickOption(option: AddContentPopoverOption) {
    this.popoverCtrl.dismiss(option);
  }
}
