<ion-header class="general-header-app">
  <app-header
    [title]="''"
    [menuButton]="false"
    [defaultBackAction]="false"
    (back)="back()"
  >
  </app-header>
</ion-header>

<ion-content class="ion-padding">
  <div class="flex-content">
    <div class="content">
      <ion-icon class="logo" src="assets/estrim/logo.svg"></ion-icon>
      <div class="centered-section p-desktop-0">
        <!-- <div class="title mb-desktop-4">Conseguir una invitación</div>
        <hr class="hidden-desktop" /> -->
        <!-- <p>
          Puede que alguno de tus contactos ya sea miembro de Estrim. Si es así,
          puedes solicitarle que te envíe una invitación si aún dispone de
          alguna. Tendrá la posibilidad de enviártela para que te registres
          <span class="bold">a través de tu número de teléfono.</span>
        </p>
        <p>
          Ciertos creadores de contenido de Estrim disponen de un
          <span class="bold">código de invitación limitado</span>. Consigue uno
          para poder registrarte.
        </p> -->
        <p>
          Durante el periodo de lanzamiento puedes registrarte con el código de
          invitación
          <a routerLink="/register/BETAESTRIM" class="bold">BETAESTRIM</a>.
        </p>
        <!-- <hr class="hidden-desktop" /> -->
      </div>
    </div>
  </div>
</ion-content>
