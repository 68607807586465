import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {KHeaderComponent} from './k-header.component';
import {IonicModule} from '@ionic/angular';


@NgModule({
  declarations: [
    KHeaderComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
  ],
  exports: [
    KHeaderComponent
  ]
})
export class KHeaderModule { }
