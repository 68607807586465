import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateToTimestamp'
})
export class DateToTimestampPipe implements PipeTransform {

  transform(value: string, format: string = 'Y-m-d H:i'): any {
    if (!value) {
      return 0;
    }
    return (new Date(value.replace('-', '/'))).getTime();
  }

}
