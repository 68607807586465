import { Component, OnInit, Input, Output, Optional, EventEmitter } from '@angular/core';
import { IonRouterOutlet, MenuController } from '@ionic/angular';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Events } from '../../services/events.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input() title: string = '';
  @Input() backButton: boolean = true;
  @Input() hidebackButton: boolean = true;
  @Input() menuButton: boolean = true;
  @Input() saveButton: boolean = false;
  @Input() shareButton: boolean = false;
  @Input() optionsButton: boolean = false;
  @Input() configureButton: boolean = false;
  @Input() searchbar: boolean = false;
  @Input() defaultBackAction: boolean = true;
  @Input() saved: boolean = false;
  @Input() filterButton: boolean = false;
  @Input() hideRightButtons: boolean = false;
  @Input() clearButton: string = 'always';

  @Output() search = new EventEmitter();
  @Output() back = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() share = new EventEmitter();
  @Output() configure = new EventEmitter();
  @Output() showOptions = new EventEmitter();
  @Output() filter = new EventEmitter();

  constructor(
    private router: Router,
    private menuCtrl: MenuController,
    private events: Events,
    @Optional() private readonly routerOutlet?: IonRouterOutlet,
  ) {
  }

  ngOnInit() {
  }

  canGoBack() {
    return this.routerOutlet.canGoBack();
  }

  goBack() {
    if (this.defaultBackAction) {
      if (this.canGoBack()) {
        this.routerOutlet.pop();
      } else {
        this.router.navigate(['/'], {replaceUrl: true});
      }
    } else {
      this.back.emit();
    }
  }

  async openLateralMenu() {
    await this.menuCtrl.enable(true, 'lateral');

    const opened = await this.menuCtrl.isOpen('lateral');

    if (opened) {
      await this.menuCtrl.close('lateral');
    } else {
      await this.menuCtrl.open('lateral');
    }
  }
}
