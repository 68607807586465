import { Component, ChangeDetectorRef } from '@angular/core';
import { Platform, ModalController, NavController } from '@ionic/angular';

import { BaseComponent } from '../base.component';

import { AuthService } from '../../../services/auth.service';
import { Events } from '../../../services/events.service';
import { AnalyticsService } from '../../../services/analytics.service';

@Component({
  selector: 'app-post-invite',
  templateUrl: './post-invite.page.html',
  styleUrls: ['./post-invite.page.scss', '../base.component.scss', '../../../../theme/field.scss'],
})
export class PostInvitePage extends BaseComponent {

  constructor(
    public platform: Platform,
    public modalCtrl: ModalController,
    public navCtrl: NavController,
    public cdRef: ChangeDetectorRef,
    public authService: AuthService,
    public events: Events,
    public analyticsService: AnalyticsService
  ) {
    super(platform, modalCtrl, navCtrl, cdRef, authService, events, analyticsService);
  }

  ionViewDidEnter() {
    this.analyticsService.setScreenName('register_post_invite');
  }
}
