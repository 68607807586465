<ion-content>
  <section id="er-info" class="section live-info">
    <div class="content">
      <a class="go-back" (click)="back()">
        <img
          class="d-block"
          src="assets/extrim/img/arrow-left.webp"
          width="100"
        />
      </a>

      <div class="section-title relative h1 text-center font-venera">
        VOTACIÓN
        <div class="live">LIVE</div>
      </div>

      <div class="info-content">
        <div>
          En la votación anterior 3 pilotos consiguieron la inmunidad y otros 3 pilotos quedaron lastrados con un copiloto de 120 kgs
        </div>
        <div>
          ¡Aún hay un lastre más!
          <br>
          Vota ahora para evitar que tu piloto favorito lleve un lastre de 120kgs
        </div>
      </div>
      <div class="info-content">
        <img class="icon" src="assets/extrim/img/icons/i3.svg" alt="Icon 3" />
        <div class="title font-venera">LASTRE</div>
        <div>
          El piloto menos votado contará con un culturista que pesará alrededor de 120kg. Esto influye muy negativamente en el comportamiento del coche, tendrá menos aceleración, menos velocidad, girará y frenará peor debido a la relación peso/potencia.
        </div>
      </div>
    </div>
  </section>
</ion-content>
