import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'slugify',
    pure: true
})
export class SlugifyPipe implements PipeTransform {

    static camelCaseToSlug(str: string) {
        let final = str.replace(/[A-Z]/g, '-$&').toLowerCase();
        if (final.indexOf('-') === 0) {
            final = final.substring(1, final.length);
        }

        return final.trim();
    }

    static slug(str: string) {
        return SlugifyPipe.removeAccents(str).toLowerCase().replace(/[^a-z0-9\- ]/g, '').trim().split(' ').join('-');
    }

    static removeAccents(str) {
        const accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
        const accentsOut = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';
        str = str.split('');
        for (let i = 0; i < str.length; i++) {
            const x = accents.indexOf(str[i]);
            if (x !== -1) {
                str[i] = accentsOut[x];
            }
        }
        return str.join('');
    }

    transform(value: string): string {
        if (!value) {
            return value;
        }

        return SlugifyPipe.slug(value);
    }


}
