import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AlertController, ModalController, IonNav, NavParams, Platform } from '@ionic/angular';
import { BasePage } from '../../../../base.page';
import { AllCategoriesPage } from '../../../../all-categories/all-categories.page';
import { environment } from '../../../../../../environments/environment';
import { CreateContentService } from '../../../../../services/create-content.service';
import { FeedService } from '../../../../../services/feed.service';
import { FilePickerService } from '../../../../../services/file-picker.service';
import { MediaService } from '../../../../../services/media.service';
import { AuthService } from '../../../../../services/auth.service';
import { PublicationService } from '../../../../../services/publication.service';
import { Events } from '../../../../../services/events.service';
import { UserService } from '../../../../../services/user.service';
import { Category } from '../../../../../interfaces/category';
import { User } from '../../../../../interfaces/user';
import { Publication } from '../../../../../interfaces/publication';
import { ContentType } from '../../../../../enums/content-type';
import { FormMode } from '../../../../../enums/form-mode';
import { ProAlertType } from '../../../../../enums/pro-alert-type';
import { CloudflareService } from 'src/app/services/cloudflare.service';
import { inOutFade } from 'src/app/animations/animations';

import { take } from 'rxjs/operators';

enum PickTarget {
  Poster,
  Preview
}

@Component({
  selector: 'app-form',
  templateUrl: './form.page.html',
  styleUrls: ['./form.page.scss', '../../../../../../theme/field.scss'],
  animations: [inOutFade]
})
export class FormPage extends BasePage implements OnInit {

  @Input() type: ContentType = ContentType.Image;
  @Input() file: File;
  @Input() mediaDuration: number;
  @Input() poster: File;
  @Input() posterSrc: SafeUrl;
  @Input() mode: FormMode = FormMode.Create;
  @Input() publication: Publication;
  @Input() url: string;
  @Input() size: number;
  @Input() duration: number;
  @Input() isRecord: boolean = false;

  CONTENT_MIN_PRICE = environment.CONTENT_MIN_PRICE;
  CONTENT_MAX_PRICE = environment.CONTENT_MAX_PRICE;

  ContentType = ContentType;
  FormMode = FormMode;

  categories: Category[] = [];

  uploading: boolean;

  error: any = {};

  user: User;

  pickTarget: PickTarget;

  previewType: ContentType;

  fileProgress: number = 0;

  /**
   * Form data
   */
  title: string;
  description: string;
  saveIn = 'inicio';
  price: number;
  category: number;
  preview: File;
  previewSrc: SafeUrl;

  constructor(
    public platform: Platform,
    private nav: IonNav,
    private createContentService: CreateContentService,
    private filePickerService: FilePickerService,
    private feedService: FeedService,
    private mediaService: MediaService,
    private authService: AuthService,
    private publicationService: PublicationService,
    private events: Events,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private domSanitizer: DomSanitizer,
    private navParams: NavParams,
    private router: Router,
    private userService: UserService,
    private cloudflareService: CloudflareService,
    public cdRef: ChangeDetectorRef
  ) {
    super(platform, cdRef);

    // Get categories
    this.categories = feedService.categories;
  }

  ngOnInit() {
    if ( this.publication !== undefined ) {
      this.title = this.publication.title;
      this.description = this.publication.description;
      this.category = this.publication.category.id;
      this.posterSrc = this.publication.thumbnail;

      if ( this.publication.isOne ) {
        this.saveIn = 'one';
        this.price = Number(this.publication.onePrice);
      } else if ( this.publication.isPremium ) {
        this.saveIn = 'suscriptores';
      }
    }

    this.sub.backbutton = this.platform.backButton.subscribeWithPriority(101, () => {
      this.goBack();
    });
  }

  ionViewWillEnter() {
    // Subscribe to poster file pick
    this.sub.filePicked = this.filePickerService.filePicked.subscribe(async (file: File) => {
      if ( file !== undefined &&
           file.type.includes('image') &&
           this.pickTarget === PickTarget.Poster ) {
        let target = '';

        this.error.poster = false;

        // Prepare props to crop image page
        switch ( this.type ) {
          case ContentType.Video: target = 'live'; break;
          case ContentType.Image: target = 'audio'; break;
          case ContentType.Audio: target = 'audio'; break;
          case ContentType.Clip: target = 'clip'; break;
          default: target = 'live';
        }

        // Ask user to crop poster
        const croppedImage = await this.mediaService.generateUserCroppedImage(file, target);

        this.poster = croppedImage !== undefined ? croppedImage : file;
        this.posterSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(this.poster));
      } else if ( file !== undefined && this.pickTarget === PickTarget.Preview ) {
        // Compruebo que el archivo de preview tien un formato adecuado
        if ( file.type.includes('video') )
          {this.previewType = ContentType.Video;}
        else if ( file.type.includes('audio') )
          {this.previewType = ContentType.Audio;}
        else
          {this.previewType = undefined;}

        if ( this.previewType !== undefined && (file.size / 1000000) <= 100 ) {
          this.preview = file;
          this.previewSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(this.preview));
        } else if (this.previewType !== undefined && (file.size / 1000000) > 100) {
          this.events.publish('alert:toast:show', 'La preview no puede superar los 100 MB');
        } else {
          this.events.publish('alert:toast:show', 'La preview debe ser un archivo de video o de audio');
        }
      }
    });

    // Subscribe to file uploaded successfully
    this.sub.fileUploadError = this.cloudflareService.fileUploadError.subscribe(() => {
      this.cdRef.detectChanges();
    })
    // Subscribe to file start uploading
    this.sub.fileUploading = this.cloudflareService.onUploading.subscribe((data: boolean) => {
      this.cdRef.detectChanges();
    })
    // Subscribe to file progress
    this.sub.fileProgress = this.cloudflareService.onProgress.subscribe((data: number) => {
      this.fileProgress = data;
      this.cdRef.detectChanges();
    })
  }

  ionViewDidEnter() {
    this.authService.getUserDetails().then((data) => {
      this.user = data;
    });
  }

  ionViewWillLeave() {
    this.unsubscribeAll();
  }

  dismiss() {
    this.createContentService.closeModal();
  }

  goBack() {
    if ( !this.uploading && this.mode === FormMode.Create && !this.isRecord ) {
      const callback = this.navParams.get('callback');
      callback(this.poster, this.posterSrc);

      this.nav.pop({ mode: 'ios' });
    } else {
      this.dismiss();
    }
  }

  fieldAvailable() {
    return this.mode !== FormMode.Edit || (this.mode === FormMode.Edit && this.saveIn !== 'one');
  }

  onVisibilityChange() {
    this.error.saveIn = false;

    if ( this.saveIn === "one" && !this.user.isPro ) {
      this.saveIn = undefined;
      this.cdRef.detectChanges();
      this.saveIn = "inicio";
      this.cdRef.detectChanges();

      this.userService.openShouldBeProModal(ProAlertType.OneRestricted);
    }
  }

  pickPoster() {
    this.pickTarget = PickTarget.Poster;
    this.filePickerService.pickFile(ContentType.Image);
  }

  pickPreview() {
    this.pickTarget = PickTarget.Preview;
    this.filePickerService.pickFile(undefined);
  }

  async pickCategory() {
    const modal = await this.modalCtrl.create({
      component: AllCategoriesPage,
      // mode: 'ios',
      componentProps: {
        customCategories: this.categories,
        defaultBackAction: false,
        isModalPage: true,
        multipleSelection: false
      }
    });
    await modal.present();

    const { data } = await modal.onDidDismiss();

    if ( data.length ) {
      this.category = data[0].id;
    }
  }

  checkForm(): boolean {
    let status = true;

    if ( this.mode === FormMode.Create && this.poster === undefined && !this.isRecord ) {
      status = false;
      this.error.poster = true;
    }

    if ( !(this.title && this.title.trim() !== '') ) {
      status = false;
      this.error.title = true;
    }

    if ( !(this.description && this.description.trim() !== '') ) {
      status = false;
      this.error.description = true;
    }

    if ( !(this.saveIn && this.saveIn.trim() !== '') ) {
      status = false;
      this.error.saveIn = true;
    } else if ( this.saveIn === 'one' && (this.price === undefined || this.price < environment.CONTENT_MIN_PRICE || this.price > environment.CONTENT_MAX_PRICE) ) {
      status = false;
      this.error.price = true;
    }

    if ( !this.category ) {
      status = false;
      this.error.category = true;
    }


    return status;
  }

  async checkSubscriptionContent(): Promise<boolean> {
    if (!this.user.subscriptionsActivated && this.saveIn === 'suscriptores') {

      const alert = await this.alertCtrl.create({
        message: `No puedes crear contenido para suscriptores porque no has activado tus suscripciones`,
        buttons: [
          {
            text: 'Configurar suscripciones',
            handler: () => {
              this.router.navigate(['/payment/my-subscriptions']);
              this.dismiss();
            }
          },
          {
            text: 'Cancelar',
            role: 'cancel'
          },
        ]
      });

      await alert.present();

      return false;
    }

    return true;
  }

  async submitForm() {
    if ( !(this.checkForm() && await this.checkSubscriptionContent()) ) {
      return ;  // BREAK EXECUTION
    }

    /*
     * Uploading started
     */

    this.uploading = true;
    let posterFileName: SafeUrl | string;
    let posterResult: any;
    let contentResult: any;

    if ( this.type === ContentType.Video || this.type === ContentType.Clip ) {
      setTimeout(() => {
        this.cdRef.detectChanges();
        this.content.scrollToBottom(300);
      }, 300)
    }

    /*
     * Upload poster
     */

    if ( (this.mode === FormMode.Create && !this.isRecord) || (this.isRecord && this.poster) || ( this.mode === FormMode.Edit && this.poster )  ) {
      try {
        posterResult = await this.publicationService
          .uploadPublicationTempImage((this.poster as File))
          .toPromise();
      } catch(e) {}

      if ( !(posterResult && posterResult.done && posterResult.msg === 'Ok') ) {
        // Throw error
        this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error, intentalo de nuevo más tarde');
        this.uploading = false;
        return ;  // BREAK EXECUTION
      }

      posterFileName = posterResult.file_cdn ? posterResult.file_cdn : posterResult.file;
    } else {
      posterFileName = this.posterSrc;
    }

    /*
     * Upload content
     */

    try {
      if ( this.isRecord ) {
        contentResult = await this.publicationService.createUploaded({
          uploadedFilePreview: this.saveIn === 'one' ? this.preview : null,
          imageFile: posterFileName,
          description: this.description,
          title: this.title,
          categorySelected: this.category,
          isPremium: this.saveIn === 'suscriptores',
          saveIn: this.saveIn,
          duration: Math.floor(this.duration),
          fileSize: this.size,
          fileSizePreview: this.saveIn === 'one' ? this.preview?.size : 0,
          isVideo: this.type === ContentType.Video || this.type === ContentType.Clip,
          onePrice: this.saveIn === 'one' ? this.price : 0,
          isClip: this.type === ContentType.Clip,
          videoStreamUrl: this.url
        })
        .toPromise()
        .catch((e) => {
          return false;
        });

      } else if ( this.mode === FormMode.Create && this.type !== ContentType.Audio ) {
        contentResult = await this.uploadVideo(posterFileName);
      } else if ( this.mode === FormMode.Create && this.type === ContentType.Audio ) {
        contentResult = await this.uploadPodcast(posterFileName);
      } else {
        // Edit existing content
        const data = {
          imageFile: posterFileName,
          description: this.description,
          title: this.title,
          onePrice: this.saveIn === 'one' ? this.price : 0,
          categorySelected: this.category,
          isPremium: this.saveIn === 'suscriptores',
          saveIn: this.saveIn,
          isVideo: (this.type === ContentType.Video || this.type === ContentType.Clip) ? 1 : 0,
          uploadedFilePreview: this.saveIn === 'one' ? this.preview : null,
        };

        if ( this.type === ContentType.Clip ) {
          contentResult = await this.publicationService.editClip(
            data,
            'live',
            this.publication?.id,
            this.getContentType()
          ).toPromise();
        } else {
          contentResult = await this.publicationService.editPublication(
            data,
            'live',
            this.publication?.id,
            this.getContentType()
          ).toPromise();
        }
      }
    } catch(e) {}

    if ( !(contentResult && contentResult.done) ) {
      // Throw error
      this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error, intentalo de nuevo más tarde');
      this.uploading = false;
      return ;  // BREAK EXECUTION
    }

    /*
     * Uploading done
     */

    if ( this.mode === FormMode.Create && !this.isRecord ) {
      // Navigate to newly created content
      if (this.saveIn === 'one') {
        this.router.navigate(['/publications/pre-one/' + contentResult.data], {replaceUrl: true});
        this.uploading = false;
        // Close modal
        this.dismiss();
      } else if ( this.type === ContentType.Audio ) {
        this.router.navigate(['/publications/view/' + contentResult.data], {replaceUrl: true});
        this.uploading = false;
        // Close modal
        this.dismiss();
      } else if ( this.type === ContentType.Video ) {
        this.cloudflareService.onReadyToStream
          .pipe(take(1))
          .subscribe(() => {
            this.events.publish(environment.EVENTS.ALERT_TOAST, {msg: 'El vídeo se ha subido con éxito', position: 'bottom'});
            this.router.navigate(['/home'], {replaceUrl: true});
            this.uploading = false;
            // Close modal
            this.dismiss();
          })
      } else if ( this.type === ContentType.Clip ) {
        this.cloudflareService.onReadyToStream
          .pipe(take(1))
          .subscribe(() => {
            this.events.publish(environment.EVENTS.ALERT_TOAST, {msg: 'El vídeo se ha subido con éxito', position: 'bottom'});
            this.router.navigate(['/home'], {replaceUrl: true});
            this.uploading = false;
            // Close modal
            this.dismiss();
          })
      }
    } else if ( this.isRecord ) {
      return this.modalCtrl.dismiss(this.publication, 'confirm');
    } else {
      // Update publication
      this.publication.title = this.title;
      this.publication.description = this.description;
      this.publication.isPremium = this.saveIn === 'suscriptores';
      this.publication.isOne = this.saveIn === 'one';
      this.publication.category.id = this.category;
      this.publication.onePrice = this.saveIn === 'one' ? this.price : 0;
      // @ts-ignore
      this.publication.thumbnail = posterFileName;

      return this.modalCtrl.dismiss(this.publication, 'confirm');
    }
  }

  getContentType(): ContentType {
    if ( this.saveIn === 'one' ) {
      return this.type === ContentType.Video ? ContentType.OneVideo : ContentType.OneAudio;
    } else {
      return this.type;
    }
  }

  uploadVideo(posterFileName: SafeUrl | string): Promise<any> {
    return new Promise(async (resolve) => {
      this.cloudflareService.uploadFile(this.file, this.title);
      // Subscribe to file uploaded successfully
      this.cloudflareService.fileUploaded
        .pipe(take(1))
        .subscribe(async () => {
        // Create new content
        const result = await this.publicationService.createUploaded({
          uploadedFilePreview: this.saveIn === 'one' ? this.preview : null,
          imageFile: posterFileName,
          description: this.description,
          title: this.title,
          categorySelected: this.category,
          isPremium: this.saveIn === 'suscriptores',
          saveIn: this.saveIn,
          duration: this.mediaDuration,
          fileSize: this.file.size,
          fileSizePreview: this.saveIn === 'one' ? this.preview?.size : 0,
          isVideo: this.type === ContentType.Video || this.type === ContentType.Clip,
          onePrice: this.saveIn === 'one' ? this.price : 0,
          isClip: this.type === ContentType.Clip,
          videoStreamUrl: environment.CLOUDFLARE_STREAM_BASE_URL.replace('<VIDEO_ID>', this.cloudflareService.response)
        })
          .toPromise()
          .catch((e) => {
            resolve(false);
          });
        
        resolve(result);
      })
    })
  }
  uploadPodcast(posterFileName: SafeUrl | string): Promise<any> {
    return this.publicationService.createUploaded({
      uploadedFile: this.file,
      uploadedFilePreview: this.saveIn === 'one' ? this.preview : null,
      imageFile: posterFileName,
      description: this.description,
      title: this.title,
      categorySelected: this.category,
      isPremium: this.saveIn === 'suscriptores',
      saveIn: this.saveIn,
      duration: this.mediaDuration,
      fileSize: this.file.size,
      fileSizePreview: this.saveIn === 'one' ? this.preview?.size : 0,
      isVideo: this.type === ContentType.Video || this.type === ContentType.Clip,
      onePrice: this.saveIn === 'one' ? this.price : 0,
      isClip: this.type === ContentType.Clip,
    }).toPromise();
  }
}
