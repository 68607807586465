import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../../../interfaces/user';
import {addAndRemoveItems} from '../../../../utils/array';
import {FeedService} from '../../../../services/feed.service';
import {environment} from '../../../../../environments/environment';
import {Events} from '../../../../services/events.service';
import {Router} from '@angular/router';
import {goToUserProfile} from '../../../../utils/routing';
import {MediaService} from '../../../../services/media.service';

@Component({
  selector: 'app-sidebar-recommended',
  templateUrl: './recommended.component.html',
  styleUrls: ['./recommended.component.scss', '../../sidebar.component.scss'],
})
export class RecommendedComponent  implements OnInit, OnDestroy {

  recommendedUserList: User[] = [];
  sub: any = {};

  constructor(
    private feedService: FeedService,
    private events: Events,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private mediaService: MediaService
  ) { }

  ngOnInit() {
    this.loadRecommendedUsers();
    this.sub.userFollow = this.events.subscribe(environment.EVENTS.USER_FOLLOW, () => {
      this.loadRecommendedUsers();
    });
  }

  ngOnDestroy() {
    this.sub.userFollow.unsubscribe();
    this.sub.recommended.unsubscribe();
  }

  getRouteUserProfile(user: string) {
    return goToUserProfile(this.router.url, user);
  }

  navigateToUserProfile(user: string) {
    this.router.navigate([goToUserProfile(this.router.url, user)]);
  }

  navigateToViewAll() {
    this.router.navigate(['publications/view-all/recommended']);
  }

  getImgProfile(user: User) {
    return this.mediaService.generateImgProfileURL(user.id, user.imgProfile);
  }

  loadRecommendedUsers() {
    this.sub.recommended = this.feedService.loadRecommendedUsers().subscribe((newRecommendedUsers) => {
      this.recommendedUserList = addAndRemoveItems(newRecommendedUsers, this.recommendedUserList, 'id').slice(0, 5);
      this.cdRef.detectChanges();
    });
  }

}
