import { Component, Input, ViewChild } from '@angular/core';
import {SwiperOptions} from 'swiper';
import {SwiperComponent} from 'swiper/angular';
import { ModalController } from '@ionic/angular';
import { skipWhile, take } from 'rxjs/operators';

import { MediaService } from 'src/app/services/media.service';
import { AuthService } from 'src/app/services/auth.service';

import { User } from 'src/app/interfaces/user';

@Component({
  selector: 'app-registered-user-with-code-popup',
  templateUrl: './registered-user-with-code-popup.component.html',
  styleUrls: ['./registered-user-with-code-popup.component.scss'],
})
export class RegisteredUserWithCodePopupComponent {

  @ViewChild('swiper', { static: true }) swiper: SwiperComponent;

  @Input() username: string = '';

  user: User;

  swiperParams: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 30,
    simulateTouch: false,
    //pagination: {clickable: true},
    pagination: {
      el: '.swiper-pagination',
    },
  };

  constructor(
    private authService: AuthService,
    private modalCtrl: ModalController,
    private mediaService: MediaService
  ) {
    if ( !this.user ) {
      this.authService.watchUser()
      .pipe(
        skipWhile(data => !data),
        take(1))
      .subscribe((user: User) => {
        this.user = user;
      });
    }
  }

  getImgProfile() {
    return this.mediaService.generateImgProfileURL(this.user?.id, this.user?.imgProfile);
  }

  nextSlide() {
    this.swiper.swiperRef.slideNext();
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
