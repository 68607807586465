import {Component, Input, Output, EventEmitter, Optional, OnInit} from '@angular/core';
import { MediaService } from '../../services/media.service';
import { FeedService } from '../../services/feed.service';
import { Category } from '../../interfaces/category';
import { Events } from '../../services/events.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { IonRouterOutlet, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-all-categories',
  templateUrl: './all-categories.page.html',
  styleUrls: ['./all-categories.page.scss'],
})
export class AllCategoriesPage implements OnInit {

  @Input() customCategories: Category[] = [];
  @Input() defaultBackAction: boolean = true;
  @Input() isModalPage: boolean = false;
  @Input() multipleSelection: boolean = true;

  @Output() back = new EventEmitter();

  mediaServ: MediaService;

  categories: Category[] = [];
  filteredCategories: Category[] = [];

  sub: any = {};

  constructor(
    private modalCtrl: ModalController,
    private mediaService: MediaService,
    private feedService: FeedService,
    private events: Events,
    private router: Router,
    @Optional() private readonly routerOutlet?: IonRouterOutlet,

  ) {
    this.mediaServ = this.mediaService;
  }

  ngOnInit() {
    if (this.customCategories.length) {
      this.categories = this.customCategories;
      this.filteredCategories = this.customCategories;
    } else {
      if (!!this.feedService.categories.length) {
        this.categories = this.feedService.categories;
        this.filteredCategories = this.feedService.categories;
      } else {
        this.feedService.loadCategories().subscribe((categories) => {
          this.categories = categories;
          this.filteredCategories = categories;
        });
      }
    }
  }

  ionViewDidLeave() {
    this.sub.categoriesLoaded?.unsubscribe();
  }

  toggleCategory(category: Category) {
    // If only one category can be selected, deselect all categories
    if ( !this.multipleSelection ) {
      this.categories.forEach((category: Category) => category.selected = false);
    }

    // Select desired category
    category.selected = !category.selected;

    // If only one can be selected, close page
    if ( !this.multipleSelection ) {
      this.goBack();
    }
  }


  canGoBack() {
    return this.routerOutlet.canGoBack();
  }

  goBack() {
    if (this.defaultBackAction) {
      if (this.canGoBack()) {
        this.routerOutlet.pop();
        this.events.publish(environment.EVENTS.CATEGORY_SELECTED, this.getSelectedCategories());

      } else {
        this.router.navigate(['/'], {replaceUrl: true});
      }
    } else if ( this.isModalPage ) {
      this.modalCtrl.dismiss(this.getSelectedCategories());
    } else {
      this.back.emit(this.getSelectedCategories());
    }
  }

  getSelectedCategories(): Category[] {
    return this.categories.filter(category => category.selected);
  }

  search($event: any) {
    if ( $event.detail.value !== undefined && $event.detail.value.trim() !== '' ) {
      this.filteredCategories = this.categories.filter(category => {
        return category.name.toLowerCase().trim().includes($event.detail.value.toLowerCase().trim());
      })
    } else {
      this.filteredCategories = this.categories;
    }
  }
}
