import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { IonNav, Platform } from '@ionic/angular';
import { FormPage } from '../form/form.page';
import { PageMode } from '../static-content.component';
import { VideoPlayerComponent } from '../../../../../components/video-player/video-player.component';
import { PodcastPlayerComponent } from '../../../../../components/podcast-player/podcast-player.component';
import { CreateContentService } from '../../../../../services/create-content.service';
import { MediaService } from '../../../../../services/media.service';
import { FilePickerService } from '../../../../../services/file-picker.service';
import { ContentType } from '../../../../../enums/content-type';
import { inOutFade } from 'src/app/animations/animations';

@Component({
  selector: 'app-media',
  templateUrl: './media.page.html',
  styleUrls: ['./media.page.scss', '../../../../../../theme/field.scss'],
  animations: [inOutFade]
})
export class MediaPage implements OnInit {

  @ViewChild('videoPlayer') protected videoPlayer: VideoPlayerComponent;
  @ViewChild('podcastPlayer') protected podcastPlayer: PodcastPlayerComponent;

  @Input() type: ContentType = ContentType.Image;
  @Input() file: File = undefined;
  @Input() poster: File;
  @Input() posterSrc: SafeUrl;

  ContentType = ContentType;

  fileSrc: SafeUrl;

  sub: any = {};

  mediaDuration: number;

  videoPlayerMode: string;

  // showldBeVideo: boolean = false;
  // showldBeClip: boolean = false;
  videoTooLong: boolean = false;

  videoRatio: number = 0;

  oversized: boolean = true;

  isHybrid: boolean = this.platform.is('hybrid');
  isIos: boolean = this.platform.is('ios');

  loading: boolean = false;

  timeBar = 0;
  pinLeftPosition = '0';
  timeBarValue = 0;

  posterType: 'frame' | 'image' = 'frame';
  posterLoading: boolean = false;

  floor = (value: number) => Math.floor(value);

  constructor(
    private nav: IonNav,
    private platform: Platform,
    private createContentService: CreateContentService,
    private filePickerService: FilePickerService,
    private mediaService: MediaService,
    private domSanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if ( this.file !== undefined ) {
      this.loading = true;
      this.oversized = (this.file.size / 1000000) > 250;  // If more than 250 mb

      this.fileSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(this.file));

      if ( this.type === ContentType.Video || this.type === ContentType.Clip ) {
        this.mediaService.generateThumbnailFromVideo(window.URL.createObjectURL(this.file));
        this.posterLoading = true;

        // Subscribe to video thumbnail
        this.sub.videoThumbnailGenerated = this.mediaService.videoThumbnailGenerated.subscribe(imageSrc => {
          this.poster = imageSrc;
          this.posterSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(imageSrc));
          this.posterLoading = false;
        })
  
        // Subscribe to video metadata
        this.sub.videoMetadataLoaded = this.mediaService.videoMetadataLoaded.subscribe(video => {
          this.onVideoMetadataLoaded({target: video});
        })
      }

      this.mediaService.getMediaDuration(window.URL.createObjectURL(this.file)).then(duration => {
        this.mediaDuration = Math.round(duration);
        this.timeBar = this.timeBarValue = this.mediaDuration / 2;
        this.calcPinPosition();
        this.loading = false;
        this.cdRef.detectChanges();
      });
    }
  }

  ionViewWillEnter() {
    // Subscribe to poster file pick
    this.sub.filePicked = this.filePickerService.filePicked.subscribe(async (file: File) => {
      if ( file !== undefined && file.type.includes('image') ) {
        let target = '';

        // Prepare props to crop image page
        switch ( this.type ) {
          case ContentType.Video: target = 'live'; break;
          case ContentType.Image: target = 'audio'; break;
          case ContentType.Audio: target = 'audio'; break;
          case ContentType.Clip: target = 'clip'; break;
          default: target = 'live';
        }

        // Ask user to crop poster
        const croppedImage = await this.mediaService.generateUserCroppedImage(file, target);

        this.poster = croppedImage !== undefined ? croppedImage : file;
        this.posterSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(this.poster));
        this.posterType = 'image';
      }
    })
  }

  ionViewWillLeave() {
    this.sub.videoThumbnailGenerated?.unsubscribe();
    this.sub.videoMetadataLoaded?.unsubscribe();
    this.sub.filePicked.unsubscribe();
  }

  onPop = (poster: File, posterSrc: SafeUrl) => {
    this.poster = poster;
    this.posterSrc = posterSrc;
  }

  dismiss() {
    this.pauseMedia();
    this.sub.videoThumbnailGenerated?.unsubscribe();
    this.sub.filePicked.unsubscribe();
    this.createContentService.closeModal();
  }

  goToForm() {
    this.pauseMedia();

    this.nav.push(
      FormPage,
      {
        pageMode: PageMode.Child,
        type: this.type,
        file: this.file,
        mediaDuration: this.mediaDuration,
        poster: this.poster,
        posterSrc: this.posterSrc,
        callback: this.onPop
      },
      { mode: 'ios' }
    );
  }

  pickPoster() {
    this.filePickerService.pickFile(ContentType.Image);
  }

  onVideoMetadataLoaded($event) {
    this.videoRatio = $event.target.videoWidth / $event.target.videoHeight;

    // Set video player mode
    this.setVideoPlayerMode();

    // Check if aspect ratio is wrong
    if ( this.videoRatio <= 0.66 && this.type === ContentType.Video ) {       // aspect ratio 2/3
      // this.showldBeClip = true;
      this.setCorrectVideoContentType(ContentType.Clip);
    } else if ( this.videoRatio > 0.66 && this.type === ContentType.Clip ) {  // aspect ratio 2/3
      // this.showldBeVideo = true;
      this.setCorrectVideoContentType(ContentType.Video);
    }

    // Check if duratio is wrong (clips cannot be longer than 150 minutes)
    if ( this.type === ContentType.Clip && $event.target.duration > 9000 ) {
      this.videoTooLong = true;
    }
  }

  setVideoPlayerMode() {
    if ( this.type === ContentType.Video ) {
      this.videoPlayerMode = 'normal';
    } else if ( this.type === ContentType.Clip ) {
      if ( this.videoRatio < 1 && this.videoRatio > 0.47 ) {   // 0.47 => 9/19
        this.videoPlayerMode = 'vertical-unlimited';
      } else {
        this.videoPlayerMode = 'vertical-limited';
      }
    }
  }

  pauseMedia() {
    if ( this.type === ContentType.Video || this.type === ContentType.Clip )
      this.videoPlayer?.pause();
    else if ( this.type === ContentType.Audio )
      this.podcastPlayer.pause();
  }

  setCorrectVideoContentType(type: ContentType) {
    this.type = type;

    // Set video player mode
    this.setVideoPlayerMode();
  }

  changeTimeValue(event) {
    this.timeBarValue = event.detail.value;
    this.calcPinPosition();
  }

  changeTimeSetted() {
    this.mediaService.generateThumbnailFromVideo(window.URL.createObjectURL(this.file), this.timeBarValue);
    this.posterLoading = true;
    this.posterType = 'frame';
  }

  private calcPinPosition() {
    let percentage, index;

    index = this.timeBarValue;

    percentage = this.mapRange(index, 0, Math.floor(this.mediaDuration), 0, 100);

    this.pinLeftPosition = 'calc(' + percentage + '% - 35px)';
  }

  /**
   * Re-mapea un valor de una escala a otra
   *
   * @param x Valor de entrada
   * @param inMin Min escala entrada
   * @param inMax Max escala entrada
   * @param outMin Min escala salida
   * @param outMax Max escala salida
   * @return Valor mapeado
   *
   */
  mapRange(x: number, inMin: number, inMax: number, outMin: number, outMax: number): number {
    return (x - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
  }
}
