import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'balanceFormat'
})
export class BalanceFormat implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    value = (Math.round(value * 100) / 100)
    const decimals = Math.round((value % 1) * 100) / 100;


    if (decimals === 0) {
      return Math.trunc(value);
    } else {
      return value.toFixed(2);
    }
  }

}
