<div #content class="content" [ngStyle]="{'padding-bottom': getSensStarsPaddingBottom()}">
  <div class="user" *ngIf="user !== undefined">
    <lazy-img
      class="avatar"
      [size]="150" 
      [source]="getImgProfile()">
    </lazy-img>
    <div class="text">
      <div class="t1">Envía estrellas a <b>{{user.username}}</b></div>
      <div class="t2" *ngIf="subText">Envía estrellas para mostrar tu apoyo al anfitrión</div>
    </div>
  </div>

  <div class="stars">
    <ion-button fill="clear" class="star" (click)="presentAlertSendStars(10)">
      <div class="star-content">
        <img handleError src="/assets/star-l.png" />
        <div class="value">{{"10" | number}}</div>
        <div class="exchange">0,20€</div>
      </div>

      <div class="loading" *ngIf="sending && sendingAmmount === 10">
        <ion-spinner name="crescent"></ion-spinner>
      </div>
    </ion-button>

    <ion-button fill="clear" class="star" (click)="presentAlertSendStars(50)">
      <div class="star-content">
        <img handleError src="/assets/star-l.png" />
        <div class="value">{{"50" | number}}</div>
        <div class="exchange">1€</div>
      </div>

      <div class="loading" *ngIf="sending && sendingAmmount === 50">
        <ion-spinner name="crescent"></ion-spinner>
      </div>
    </ion-button>

    <ion-button fill="clear" class="star" (click)="presentAlertSendStars(100)">
      <div class="star-content">
        <img handleError src="/assets/star-l.png" />
        <div class="value">{{"100" | number}}</div>
        <div class="exchange">2€</div>
      </div>

      <div class="loading" *ngIf="sending && sendingAmmount === 100">
        <ion-spinner name="crescent"></ion-spinner>
      </div>
    </ion-button>

    <ion-button fill="clear" class="star" (click)="presentAlertSendStars(500)">
      <div class="star-content">
        <img handleError src="/assets/star-l.png" />
        <div class="value">{{"500" | number}}</div>
        <div class="exchange">10€</div>
      </div>

      <div class="loading" *ngIf="sending && sendingAmmount === 500">
        <ion-spinner name="crescent"></ion-spinner>
      </div>
    </ion-button>

    <ion-button fill="clear" class="star" (click)="presentAlertSendStars(1000)">
      <div class="star-content">
        <img handleError src="/assets/star-l.png" />
        <div class="value">{{"1000" | number}}</div>
        <div class="exchange">20€</div>
      </div>

      <div class="loading" *ngIf="sending && sendingAmmount === 1000">
        <ion-spinner name="crescent"></ion-spinner>
      </div>
    </ion-button>

    <ion-button fill="clear" class="star" (click)="presentAlertSendStars(5000)">
      <div class="star-content">
        <img handleError src="/assets/star-l.png" />
        <div class="value">{{"5000" | number}}</div>
        <div class="exchange">100€</div>
      </div>

      <div class="loading" *ngIf="sending && sendingAmmount === 5000">
        <ion-spinner name="crescent"></ion-spinner>
      </div>
    </ion-button>
  </div>

  <div class="aux-content">
    <div class="input">
      <div class="icon star">
        <img handleError src="/assets/star-l.png" />
      </div>

      <div class="box">
        <ion-input 
          only-numeric
          placeholder="Otra cantidad" 
          type="tel"
          [disabled]="sending"
          [(ngModel)]="customValue">    
        </ion-input>

        <ion-button fill="clear" class="icon send" (click)="sendingCustomAmmount = true; presentAlertSendStars(customValue)">
          <img handleError src="/assets/send-button.png"/>

          <div class="loading" *ngIf="sending && sendingCustomAmmount">
            <ion-spinner name="crescent"></ion-spinner>
          </div>
        </ion-button>
      </div>
    </div>

    <div class="balances">
      <div class="balance stars" *ngIf="getCreditsBalance()">
        <span><b>Saldo disponible:</b></span>
        <img handleError class="coin" src="assets/star-l.png"/>
        <span class="valor"><b>{{getCreditsBalance() * 50 | number}}</b></span>
      </div>
    </div>

    <div class="recharge">
      <ion-button [disabled]="sending" (click)="recharge()">Recargar</ion-button>
    </div>
  </div>

  <div class="not-ready" *ngIf="!ready && !sending">
    <ion-spinner name="crescent"></ion-spinner>
    <span>Cargando cartera...</span>
  </div>
</div>
