import { NgModule } from '@angular/core';
import { DateAgoPipe } from './date-ago.pipe';
import { DateToTimestampPipe } from './date-to-timestamp.pipe';
import { SlugifyPipe } from './slugify.pipe';
import { SafePipe } from './safe.pipe';
import { StripTagsPipe } from './strip-tags.pipe';
import { EllipsisPipe } from './ellipsis.pipe';
import { FirstUppercasePipe } from './first-uppercase.pipe';
import { ThousandsKPipe } from './thousands-k.pipe';
import { BalanceFormat } from './balance-format.pipe';
import { ReversePipe } from './reverse.pipe';
import { ShortNumberPipe } from './short-number.pipe';
import { DurationPipe } from './duration.pipe';
import { DateFormattedPipe } from './date-formatted.pipe';

@NgModule({
  declarations: [
    DateAgoPipe, 
    DateToTimestampPipe, 
    SlugifyPipe, 
    SafePipe, 
    StripTagsPipe, 
    EllipsisPipe, 
    EllipsisPipe, 
    FirstUppercasePipe,
    ThousandsKPipe,
    BalanceFormat,
    ReversePipe,
    ShortNumberPipe,
    DurationPipe,
    DateFormattedPipe
  ],
  imports: [],
  exports: [
    DateAgoPipe, 
    DateToTimestampPipe, 
    SlugifyPipe, 
    SafePipe, 
    StripTagsPipe, 
    EllipsisPipe, 
    FirstUppercasePipe, 
    ThousandsKPipe,
    BalanceFormat,
    ReversePipe,
    ShortNumberPipe,
    DurationPipe,
    DateFormattedPipe
  ],
})

export class PipesModule {
}
