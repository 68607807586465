import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Category } from 'src/app/interfaces/category';

import { ShortcutRange } from '../event-layout.component';

import { DatesComponent } from '../dates/dates.component';

import { FeedService } from 'src/app/services/feed.service';

import { ContentType } from 'src/app/enums/content-type';
import { AllCategoriesPage } from 'src/app/pages/all-categories/all-categories.page';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent  implements OnInit {

  @Input() filters: any = {};
  @Input() categories: Category[] = [];

  allCategories: Category[] = [];

  ShortcutRange = ShortcutRange;
  ContentType = ContentType;

  priceBar2 = 0;
  pinLeftPosition = '0';
  priceBarValue = 0;

  floor = (value: number) => Math.floor(value);

  constructor(
    private modalCtrl: ModalController,
    private cdRef: ChangeDetectorRef,
    private feedService: FeedService,
  ) {
    // Get categories
    this.allCategories = [...feedService.categories];
  }

  ngOnInit() {
    this.priceBarValue = this.filters.price || 0;
    this.priceBar2 = this.priceBarValue;
    this.calcPinPosition();

    if ( !this.categories || !this.categories.length ) {
      this.categories = this.allCategories.slice(0, 6);
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  changePriceValue(event) {
    this.priceBarValue = this.floor(event.detail.value);

    if ( this.priceBarValue ) {
      this.filters.price = this.priceBarValue;
      this.filters.free = false;
    } else {
      this.filters.price = undefined;
    }

    this.calcPinPosition();
  }

  freeToggleChange($event) {
    if ( $event.detail.checked ) {
      this.priceBar2 = 0;
      this.priceBarValue = 0;
      this.filters.price = 0;
    } else {
      this.filters.price = undefined;
    }

    this.calcPinPosition();
  }

  private calcPinPosition() {
    let percentage, index;

    index = this.priceBarValue;

    percentage = this.mapRange(index, 0, 100, 0, 100);

    this.pinLeftPosition = 'calc(' + percentage + '% - 40px)';
  }

  /**
   * Re-mapea un valor de una escala a otra
   *
   * @param x Valor de entrada
   * @param inMin Min escala entrada
   * @param inMax Max escala entrada
   * @param outMin Min escala salida
   * @param outMax Max escala salida
   * @return Valor mapeado
   *
   */
  mapRange(x: number, inMin: number, inMax: number, outMin: number, outMax: number): number {
    return (x - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
  }

  formatDate(date: moment.Moment): string {
    return date.format('dddd DD MMMM');
  }

  async showDates() {
    const modal = await this.modalCtrl.create({
      cssClass: "",
      component: DatesComponent,
      componentProps: {
        startDate: this.filters.startDate,
        endDate: this.filters.endDate,
      },
      mode: "ios",
    });
    modal.present();

    const result = await modal.onWillDismiss();

    if ( result.role === 'confirm') {
      this.filters.startDate = result.data.startDate;
      this.filters.endDate = result.data.endDate;
      this.filters.shortcutRange = ShortcutRange.None;
      this.cdRef.detectChanges();
    }
  }

  async showCategories() {
    const modal = await this.modalCtrl.create({
      cssClass: "",
      component: AllCategoriesPage,
      componentProps: {
        defaultBackAction: false,
        isModalPage: true,
        customCategories: this.allCategories,
      },
      mode: "ios",
    });
    modal.present();

    const result = await modal.onWillDismiss();

    this.filters.categories = [];

    result.data
    .filter(category => category.selected)
    .forEach(category => {
      this.filters.categories.push(category.id);
    })
  }

  setShortcut(range: ShortcutRange) {
    if ( this.filters.shortcutRange === range ) {
      this.filters.shortcutRange = ShortcutRange.None;
    } else {
      this.filters.shortcutRange = range;
    }

    this.filters.startDate = undefined;
    this.filters.endDate = undefined;
  }

  setEventType(contentType: ContentType) {
    this.filters.contentType === contentType
      ? this.filters.contentType = undefined
      : this.filters.contentType = contentType;
  }

  toggleCategory(category: Category) {
    if ( this.filters.categories?.includes(category.id) ) {
      this.filters.categories = this.filters.categories?.filter( id => id !== category.id );
      this.allCategories.find( c => c.id === category.id ).selected = false;
    } else {
      !this.filters.categories && (this.filters.categories = []);
      this.filters.categories.push(category.id);
      this.allCategories.find( c => c.id === category.id ).selected = true;
    }
  }
}
