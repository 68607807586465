import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { MediaService } from '../../services/media.service';

import { User } from '../../interfaces/user';

import { InviteMode } from '../../enums/invite-mode';
import { InviteType } from '../../enums/invite-type';
import { ViewerType } from '../../enums/viewer-type';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss'],
})
export class InviteComponent implements OnInit {

  @Input() user: User;
  @Input() mode: InviteMode = InviteMode.Send;
  @Input() type: InviteType = InviteType.Audio;
  @Input() viewerType: ViewerType = ViewerType.Random;

  InviteMode = InviteMode;
  InviteType = InviteType;
  ViewerType = ViewerType;

  constructor(
    private modalCtrl: ModalController,
    private mediaService: MediaService
  ) { }

  ngOnInit() {}

  /**
   * Get profile image
   */
  getImgProfile(): string {
    return this.mediaService.generateImgProfileURL(this.user?.id, this.user?.imgProfile);
  }

  /**
   * Return collected data to modal controller
   */
  sendInvite(type: InviteType) {
    this.type = type;
    return this.modalCtrl.dismiss({
      user: this.user,
      type: this.type
    }, 'confirm')
  }

  /**
   * Return collected data to modal controller
   */
  acceptInvite() {
    return this.modalCtrl.dismiss({
      user: this.user,
      type: this.type
    }, 'confirm')
  }

  /**
   * Close modal
   */
  dismiss() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }
}
