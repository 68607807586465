import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {JsonApiResponse} from '../interfaces/JsonApiResponse';
import {KService} from './k.service';
import {Observable} from 'rxjs';
import {Preferences} from '@capacitor/preferences';
import {ContactInput, Contacts, PickContactResult} from '@capacitor-community/contacts';
import {AlertController, Platform} from '@ionic/angular';
import {Events} from './events.service';

type PhonePrefix = {
  prefix: string;
  phone: string;
};

export type ContactPayloadCustom = {
  contactId: string;
  name: string;
  phone: string;
  invited: boolean;
};

export const CONTACTS_PROJECTION = {
  projection: {
    phones: true,
    name: true
  }
};

@Injectable({
  providedIn: 'root'
})
export class ContactService extends KService {
  public saveContactsUrl = 'api/auth/user/save_contacts';
  getContactsInAppUrl = 'api/auth/aforum/get-contacts-in-app';
  removeUserContactsUrl = 'api/auth/user/remove_contacts_list';

  // eslint-disable-next-line @typescript-eslint/naming-convention
  CONTACTS_KEY_ACCEPTED = 'CONTACTS_PERMISSION_ACCEPTED_NODELETE';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CONTACTS_KEY_REJECT = 'CONTACTS_PERMISSION_REJECTED_NODELETE';
  maxTimesContactsRejected = 2;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  COUNTRY_CODES = [
    { code: '54', country: 'Argentina' },
    { code: '591', country: 'Bolivia' },
    { code: '56', country: 'Chile' },
    { code: '57', country: 'Colombia' },
    { code: '506', country: 'Costa Rica' },
    { code: '53', country: 'Cuba' },
    { code: '593', country: 'Ecuador' },
    { code: '503', country: 'El Salvador' },
    { code: '34', country: 'España' },
    { code: '502', country: 'Guatemala' },
    { code: '240', country: 'Guinea Ecuatorial' },
    { code: '504', country: 'Honduras' },
    { code: '52', country: 'México' },
    { code: '505', country: 'Nicaragua' },
    { code: '507', country: 'Panamá' },
    { code: '51', country: 'Perú' },
    { code: '1', country: 'Puerto Rico' },
    { code: '1', country: 'República Dominicana' },
    { code: '598', country: 'Uruguay' },
    { code: '58', country: 'Venezuela' },
    { code: '376', country: 'Andorra '}
  ];

  constructor(
    protected http: HttpClient,
    private platform: Platform,
    private events: Events,
    private alertCtrl: AlertController
  ) {
    super(http, 'user', '');
  }

  async getContactsWithPromptAndSave(showPromptAlways: boolean = false, save: boolean): Promise<[boolean, ContactPayloadCustom[]]> {
    if (!this.platform.is('hybrid')) {
      return [false, []];
    }
    let contactsAccepted = false;
    const contactsCheckPermissions = await Contacts.checkPermissions();
    contactsAccepted = contactsCheckPermissions.contacts === 'granted';

    let buttons = [
      {
        text: this.platform.is('ios') ? 'Continuar' : 'Aceptar',
        handler: () => {}
      },
      {
        text: 'Ahora no',
        role: 'cancel',
        handler: () => {}
      }
    ];
    const isIOS = this.platform.is('ios');
    const timesRejected = await this.getRejectedTimes();
    if (isIOS) {
      buttons = buttons.filter(b => b.text !== 'Ahora no');
      if (!contactsAccepted && timesRejected && parseInt(timesRejected, 10) >= 1) {
        return [false, []];
      }
    }
    if (!contactsAccepted &&
      (showPromptAlways || !timesRejected || parseInt(timesRejected, 10) < this.maxTimesContactsRejected)) {
      const alert = await this.alertCtrl.create({
        cssClass: 'contact-alert',
        header: 'Acceso a tus contactos',
        // eslint-disable-next-line max-len
        message: 'Para poder invitar a tus amigos, sugerirte contenido y/o personas a las que puedas conocer necesitamos tu permiso para acceder a tus contactos. Recogeremos los datos de teléfonos aunque no sabremos a quién pertenecen y los subiremos a nuestros servidores donde los procesaremos para ofrecerte el contenido que más te interesa. Nunca compartiremos esta información con nadie.',
        buttons,
        backdropDismiss: false
      });

      await alert.present();
      const { role } = await alert.onDidDismiss();
      if (role === 'cancel') {
        await this.setContactsRejected();
        if (save) {
          this.removeContactsDB().subscribe();
        }
        await this.alertCtrl.dismiss();
        return [false, []];
      } else {
        const contactsPermissions = await Contacts.requestPermissions();
        if (contactsPermissions.contacts !== 'granted') {
          await this.setContactsRejected();
          return [false, []];
        }
        await this.setContactsAccepted();
        const contactsList = await this.getContactsFromMobile(save);
        return [true, contactsList];
      }
    } else if (contactsAccepted) {
      await this.setContactsAccepted();
      const contactsList = await this.getContactsFromMobile(save);
      return [true, contactsList];
    }
    return [false, []];
  }

  getContactsInApp(): Observable<any> {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.getContactsInAppUrl), {});
  }

  normalizePhone(phone: string): PhonePrefix {
    let normalizedPhone = '';
    for (const country of this.COUNTRY_CODES) {
      const prefix = '+' + country.code;
      if (phone.startsWith(prefix)) {
        normalizedPhone = phone.replace(prefix, '').replace(/\D+/g, '');
        return { prefix, phone: normalizedPhone };
      }
      normalizedPhone = phone.replace(prefix, '').replace(/\D+/g, '');
    }
    return { prefix: '', phone: normalizedPhone };
  }

  private async getContactsFromMobile(save: boolean): Promise<ContactPayloadCustom[]> {
    const contacts = await Contacts.getContacts(CONTACTS_PROJECTION);
    const contactsList = contacts.contacts.filter(e =>
      !!e.phones && !!e.phones.length && !!e.phones[0].number && e.phones[0].number.length > 5
    ).map((c) => ({
      contactId: c.contactId,
      name: c.name?.display || c.name?.given || c.name?.family || c.phones[0].number,
      phone: c.phones[0].number,
      invited: false
    }));

    if (save) {
      this.saveContactsInDB(contactsList);
    }

    contactsList.sort((a, b) => a.name.localeCompare(b.name));

    return contactsList;
  }

  private removeContactsDB() {
    return this.http.get<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.removeUserContactsUrl));
  }

  private async setContactsRejected(): Promise<void> {
    const timesRejected = await Preferences.get({ key: this.CONTACTS_KEY_REJECT });
    await Preferences.remove({ key: this.CONTACTS_KEY_ACCEPTED });
    if (!timesRejected.value) {
      await Preferences.set({ key: this.CONTACTS_KEY_REJECT, value: '1' });
    } else {
      await Preferences.set({ key: this.CONTACTS_KEY_REJECT, value: (parseInt(timesRejected.value, 10) + 1).toString() });
    }
  }

  private async getRejectedTimes(): Promise<string> {
    return (await Preferences.get({ key: this.CONTACTS_KEY_REJECT })).value;
  }

  private async setContactsAccepted(): Promise<void> {
    await Preferences.set({ key: this.CONTACTS_KEY_ACCEPTED, value: '1' });
  }

  private saveContactsInDB(contactPayload: ContactPayloadCustom[]) {
    const contacts = this.fromContactPayloadCustomToContact(contactPayload);
    this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.saveContactsUrl),
      {
        contacts
      }).subscribe();
  }

  private fromContactPayloadCustomToContact(contactList: ContactPayloadCustom[]) {
    return contactList.map((contactPayload) => {
      const normalizedPhone = this.normalizePhone(contactPayload.phone);
      return {
        phoneNumbers: [
          {
            prefix: normalizedPhone.prefix,
            // eslint-disable-next-line id-blacklist
            number: normalizedPhone.phone,
            country: ''
          }
        ]
      };
    });
  }

  async pickContact(): Promise<PickContactResult> {
    return await Contacts.pickContact(CONTACTS_PROJECTION)
  }

  async createContact(contact: ContactInput) {
    return await Contacts.createContact({ contact });
  }
}
