import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MediaService } from '../../../services/media.service';
import { Comment } from '../../../interfaces/comment';
import { User } from '../../../interfaces/user';
import { Publication } from '../../../interfaces/publication';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit {

  @Input() comment: Comment;
  @Input() replies: Comment[];
  @Input() user;
  @Input() isCreator: boolean = false;
  @Input() isCollaborator: boolean = false;
  @Input() isSubscribed: boolean = false;
  @Input() skeleton: boolean = false;

  @Output() reducedProfile: EventEmitter<User> = new EventEmitter();
  @Output() reply = new EventEmitter();
  @Output() longPressed: EventEmitter<Comment> = new EventEmitter();

  showReplies = true;

  constructor(
    private mediaService: MediaService,
    private alertCtrl: AlertController) {
  }

  ngOnInit() {

    if (!this.replies) {
      this.replies = [];
    }
    if (this.replies && this.replies.length > 1) {
      this.showReplies = false;
    }
  }

  getImageProfile() {
    return this.mediaService.generateImgProfileURL(this.comment.authorUser.id, this.comment.authorUser.imgProfile);
  }

  getBadgeSrc() {
    return this.mediaService.getBadgeSrc(this.user);
  }

  emitReducedProfile(user: User) {
    console.log('emitted', user)
    this.reducedProfile.emit(user);
  }

  emitReply($event) {
    this.reply.emit($event);
  }
}
