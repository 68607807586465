import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import 'cordova-plugin-purchase';

import { SettingsService } from './settings.service';
import { Events } from './events.service';
import { PaymentService } from './payment.service';
import { AuthService } from './auth.service';
import { AnalyticsService } from './analytics.service';

import { User } from '../interfaces/user';

import * as Sentry from "@sentry/capacitor";

@Injectable({
  providedIn: 'root'
})
export class InAppPurchaseService {

  private _productApproved = new Subject<CdvPurchase.Transaction>();
  productApproved = this._productApproved.asObservable();

  private _productError = new Subject();
  productError = this._productError.asObservable();

  private _productVerified = new Subject<CdvPurchase.VerifiedReceipt>();
  productVerified = this._productVerified.asObservable();

  private _productUnverified = new Subject<CdvPurchase.UnverifiedReceipt>();
  productUnverified = this._productUnverified.asObservable();

  private _notSupported = new Subject();
  notSupported = this._notSupported.asObservable();

  private _consumableProductIds: string[] = [];
  private _subscriptionProductIds: string[] = [];

  private _iapPlatform: CdvPurchase.Platform;

  get products(): CdvPurchase.Product[] {
    return CdvPurchase.store.products;
  }

  constructor( 
    public platform: Platform,
    private settingsService: SettingsService,
    private events: Events,
    private paymentService: PaymentService,
    private authService: AuthService,
    private analyticsService: AnalyticsService
  ) {

    this.platform.ready().then(() => {

      /*
       * Get products
       */

      if ( this.platform.is('ios') ) {
        this._consumableProductIds = environment.PRODUCTS_IAP.CONSUMABLE_IOS;
        this._subscriptionProductIds = environment.PRODUCTS_IAP.SUBSCRIPTION_IOS;
      }

      if ( this.platform.is('android') ) {
        this._consumableProductIds = environment.PRODUCTS_IAP.CONSUMABLE_ANDROID;
        this._subscriptionProductIds = environment.PRODUCTS_IAP.SUBSCRIPTION_ANDROID;
      }

      if ( this.platform.is('hybrid') && (this.platform.is('ios') || this.platform.is('android')) ) {

        if ( this.platform.is('ios') )
          this._iapPlatform = CdvPurchase.Platform.APPLE_APPSTORE;

        if ( this.platform.is('android') )
          this._iapPlatform = CdvPurchase.Platform.GOOGLE_PLAY;

        /*
         * Store configuration
         */

        // Verbosity
        CdvPurchase.store.verbosity = CdvPurchase.LogLevel.DEBUG;
        // Validator
        CdvPurchase.store.validator = 
          environment.production 
            ? 'https://validator.iaptic.com/v3/validate?appName=com.estrim.app&apiKey=5157b214-a58c-402e-bbca-d0f3ecb278f0'
            : 'https://validator.iaptic.com/v3/validate?appName=es.xiltec.test&apiKey=ddf66448-d006-4a71-be24-08668f56d7ac';
        
        // CdvPurchase.store.validator = (receipt, callback) => {
        //   this.paymentService.buyTokensInApp(receipt).subscribe(
        //     data => {
        //       // @ts-ignore
        //       callback({ ok: data.done, data: { /* see CdvPurchase.Validator.Response.Payload for details */ } })
        //     }, err => {
        //       // @ts-ignore
        //       callback({ ok: false, message: 'ERROR' })
        //     }
        //   );
        // }

        /*
         * Register products
         */

        this._consumableProductIds.forEach((pid: string) => {
          // Register product
          CdvPurchase.store.register([{
            id: pid,
            type: CdvPurchase.ProductType.CONSUMABLE,
            platform: this._iapPlatform
          }])
        });

        // this._subscriptionProductIds.forEach((pid: string) => {
        //   // Register product
        //   CdvPurchase.store.register({
        //     id: pid,
        //     type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
        //     platform: this._iapPlatform
        //   })
        // });

        CdvPurchase.store
          .when()
          .productUpdated(this.storeProductUpdated)
          .approved(this.storeProductApproved)
          .verified(this.storeProductVerified)
          .unverified(this.storeProductUnverified)

        CdvPurchase.store.error((error) => {
          Sentry.captureMessage(`[IAP] purchase error`, "error");
          this.analyticsService.logEvent('purchase_error');
          this._productError.next(null);
        })

        // Set user identifier
        this.authService.watchUser()
          .pipe(
            filter(data => !!data), 
            take(1))
          .subscribe((user: User) => {
            CdvPurchase.store.applicationUsername = user.id.toString();

            /*
             * Initialize store
             */

            CdvPurchase.store.initialize();
            console.log('INITIALIZE');
          })

        // TEST
        // setTimeout(() => {
        //   CdvPurchase.store.order(this._subscriptionProductIds[0]);
        // }, 2000);
      }
    })
  
  }

  /**
   * Event on product updated
   * @param {Product} product
   */
  private storeProductUpdated = (product: CdvPurchase.Product) => {
    // console.log(product);
  }

  /**
   * Event on product approved
   * @param {Transaction} transaction
   */
  private storeProductApproved = (transaction: CdvPurchase.Transaction) => {
    transaction.verify();

    this.analyticsService.logEvent('purchase_approved');
    this._productApproved.next(transaction);
  }

  /**
   * Event on product verified
   * @param {VerifiedReceipt} receipt
   */
  private storeProductVerified = (receipt: CdvPurchase.VerifiedReceipt) => {
    receipt.finish();

    this.analyticsService.logEvent('purchase_verified');
    this._productVerified.next(receipt);
  }

  /**
   * Event on product unverified
   * @param {UnverifiedReceipt} receipt
   */
  private storeProductUnverified = (receipt: CdvPurchase.UnverifiedReceipt) => {
    this.analyticsService.logEvent('purchase_unverified');
    this._productUnverified.next(receipt);
  }

  /**
   * Purchase a consumable
   * @param {number} price Consumable price
   */
  purchaseConsumable(price: number) {
    if ( this.platform.is('ios') || this.platform.is('android') ) {
      // if ( this.authService.user.username === 'robert' || 
      //      this.authService.user.username === 'franmoreno' || 
      //      this.authService.user.username === 'borjare' ||
      //      this.authService.user.username === 'shakaran100' ) {

        this.analyticsService.logEvent('purchase_token_init', {
          product: `app.estrim.reload${price}`
        });

        CdvPurchase.store.get(`app.estrim.reload${price}`).getOffer().order()
      // } else {
      //   setTimeout(() => {
      //     this.settingsService.openFunctionNotAvailableModal('La compra de tokens estará disponible en breve.');
      //     this._notSupported.next(null);
      //   }, 3000)
      // }
    } else {
      this.events.publish(environment.EVENTS.ALERT_TOAST_NOTICE, 'Esta funcionalidad solo está disponible en la versión móvil.');
      this._notSupported.next(null);
    }
  }

  /**
   * Subscribe to product
   * @param {number} userId User id
   * @param {number} price Subscription price
   */
  purchaseSubscription(userId: number, price: number) {
    if ( this.platform.is('ios') || this.platform.is('android') ) {
      if ( this.authService.user.username === 'robert' || 
           this.authService.user.username === 'robertx' || 
           this.authService.user.username === 'franmoreno' || 
           this.authService.user.username === 'borjare' ||
           this.authService.user.username === 'borja' ||
           this.authService.user.username === 'dani' ||
           this.authService.user.username === 'gerher' ||
           this.authService.user.username === 'jenifergascon' ||
           this.authService.user.username === 'samuel' ||
           this.authService.user.username === 'samuelgomez' ||
           this.authService.user.username === 'streampro1' ||
           this.authService.user.username === 'rocky_power' ||
           this.authService.user.username === 'bunnyshell' ||
           this.authService.user.username === 'shakaran' ||
           this.authService.user.username === 'shakaran100' ||
           environment.production === false ) {

        try {
          CdvPurchase.store.register({
            id: `app.estrim.subscription.${userId}`,
            type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
            platform: this._iapPlatform
          })
        } catch (e) {
          Sentry.captureMessage(`[IAP] subscription product register failed (app.estrim.subscription.${userId})`, "error");
          this._productError.next(null);
        }

        CdvPurchase.store.update().then(() => {
          try {
            CdvPurchase.store.get(`app.estrim.subscription.${userId}`).getOffer().order()
          } catch (e) {
            Sentry.captureMessage(`[IAP] subscription order failed (app.estrim.subscription.${userId})`, "error");
            this._productError.next(null);
          }
        }).catch((e) => {
          Sentry.captureMessage(`[IAP] subscription store update failed (app.estrim.subscription.${userId})`, "error");
          this._productError.next(null);
        });
      } else {
        setTimeout(() => {
          this.settingsService.openFunctionNotAvailableModal('Las suscripciones estarán disponibles en breve.');
          this._notSupported.next(null);
        }, 3000)
      }
    } else {
      this.events.publish(environment.EVENTS.ALERT_TOAST_NOTICE, 'Esta funcionalidad solo está disponible en la versión móvil.');
      this._notSupported.next(null);
    }
  }
}
