import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { BlockPopoverOption } from '../../../enums/block-popover-option';

@Component({
  selector: 'app-block-popover',
  templateUrl: './block-popover.component.html',
  styleUrls: ['./block-popover.component.scss'],
})
export class BlockPopoverComponent implements OnInit {

  @Input() user: boolean = false;
  @Input() itsMe: boolean = false;
  @Input() canEmit: boolean = false;
  @Input() reportable: boolean = true;
  @Input() removable: boolean = true;
  @Input() editable: boolean = false;
  @Input() blocked: boolean = false;
  @Input() blockLabelText: string = 'No me interesa';

  BlockPopoverOption = BlockPopoverOption;

  constructor( private popoverCtrl: PopoverController ) { }

  ngOnInit() {}

  pickOption(option: BlockPopoverOption) {
    this.popoverCtrl.dismiss(option);
  }
}
