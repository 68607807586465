import { Component, Input, Output, OnInit, EventEmitter, ElementRef, NgZone } from '@angular/core';
import { Event } from '../../../interfaces/event';
import { MediaService } from '../../../services/media.service';
import { BlockPopoverComponent } from '../../popovers/block-popover/block-popover.component';
import { BlockPopoverOption } from '../../../enums/block-popover-option';
import { environment } from '../../../../environments/environment';
import { AlertController, PopoverController } from '@ionic/angular';
import { ProfileService } from '../../../services/profile.service';
import { Events } from '../../../services/events.service';
import { Router } from '@angular/router';
import { RouterDataService } from '../../../services/router-data.service';
import { AuthService } from '../../../services/auth.service';
import { User } from '../../../interfaces/user';
import { UserService } from '../../../services/user.service';
import { ContentType } from '../../../enums/content-type';

@Component({
  selector: 'app-event-thumbnail',
  templateUrl: './event-thumbnail.component.html',
  styleUrls: ['./event-thumbnail.component.scss'],
})
export class EventThumbnailComponent implements OnInit {

  @Input() event: Event;
  @Input() profileView = false;
  @Input() expanded = false;
  @Input() premieres = false;
  @Input() userName = true;
  @Input() biggerFont = false;
  @Input() skeleton = false;
  @Input() animatedSkeleton = true;
  @Input() showDots = false;
  @Input() calculateHeight: boolean = false;
  
  @Output() heightCalculated: EventEmitter<number> = new EventEmitter();

  componentHeight: number = 0;

  mediaServ: MediaService;
  eventPassed = false;

  user: User;

  constructor(
    private el: ElementRef,
    private zone: NgZone,
    private mediaService: MediaService,
    private alertController: AlertController,
    private popoverController: PopoverController,
    private profileService: ProfileService,
    private events: Events,
    private router: Router,
    private routerDataService: RouterDataService,
    private authService: AuthService,
    private userService: UserService
  ) {
    this.mediaServ = this.mediaService;
  }

  ngOnInit() {
    if (!this.skeleton) {
      this.user = this.authService.user;

      this.eventPassed = new Date().getTime() > new Date(this.event.eventDateStart.date).getTime();
    }
  }

  ngAfterViewChecked() {
    if ( this.calculateHeight ){
      this.zone.runOutsideAngular(() => {
        const height = this.el.nativeElement.getBoundingClientRect().height;
        if ( height !== 0 && height !== this.componentHeight ) {
          this.componentHeight = height;
          this.heightCalculated.emit(height);
        }
      });
    }
  }

  async presentBlockPopover(e) {
    e.preventDefault();
    e.stopPropagation();
    const popover = await this.popoverController.create({
      component: BlockPopoverComponent,
      componentProps: {
        blocked: false,
        itsMe: false,
        canEmit: false
      },
      event: e,
      animated: true,
      mode: 'ios',

    });

    await popover.present();

    const {data} = await popover.onDidDismiss();

    switch (data) {
      case BlockPopoverOption.Block:
        this.presentAlertBlockContent();
        break;
      case BlockPopoverOption.Report:
        this.presentAlertReportContent();
        break;
    }

    return false;
  }

  async presentAlertBlockContent() {

    this.profileService.blockEvent(this.event.id).subscribe(
      data => {
        if (data.msg === 'Ok') {
          this.events.publish(environment.EVENTS.ALERT_TOAST_NOTICE, 'No volverás a ver este contenido');
        } else {
          this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error, vuelve a intentarlo');
        }
      }, error => {
        this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error, vuelve a intentarlo');
      });
  }

  async presentAlertReportContent() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-alert-class',
      header: 'Denunciar contenido',
      message: '¿Estás seguro de que quieres denunciar este contenido?',
      buttons: [
        {
          text: 'Denunciar',
          cssClass: 'alert-button-danger',
          handler: () => {
            this.userService.report(this.event.id, this.premieres ? ContentType.Premiere : ContentType.Event, true, true)
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'alert-button-dark'
        }
      ]
    });

    await alert.present();
  }

  enterPublication() {
    this.routerDataService.setData(this.event);
    this.router.navigate([`/publications/pre-event/${this.event.id}`]);
  }
}
