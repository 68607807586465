import { Injectable } from '@angular/core';
import { ModalController, AlertController, PopoverController } from '@ionic/angular';
import { KService } from './k.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Publication } from '../interfaces/publication';
import { Event } from '../interfaces/event';
import { Comment } from '../interfaces/comment';
import { environment } from '../../environments/environment';
import { JsonApiResponse } from '../interfaces/JsonApiResponse';
import { JsonApiResponsePaginated } from '../interfaces/JsonApiResponsePaginated';
import { Review } from '../interfaces/review';
import { Subscription } from '../interfaces/subscription';
import { CreateReviewComponent } from '../components/create-review/create-review.component';
import { map, tap } from 'rxjs/operators';
import { Events } from './events.service';
import { ContentType } from '../enums/content-type';

import { CommentsPage } from '../pages/publications/comments/comments.page';

import { BlockPopoverComponent } from 'src/app/components/popovers/block-popover/block-popover.component';

import { BlockPopoverOption } from 'src/app/enums/block-popover-option';

@Injectable({
  providedIn: 'root'
})
export class PublicationService extends KService {

  createUploadedPublicationUrl = 'api/auth/publication/new';
  createClipPublicationUrl = 'api/auth/clip/new';
  loadPublicationsByUserUrl = 'api/auth/publication/load_publications_by_user';
  uploadPublicationTempImageUrl = 'api/auth/publication/upload_publication_temp_image';
  createLiveUrl = 'api/auth/publication/live';
  createEventUrl = 'api/auth/publication/event';
  createPremiereUrl = 'api/v2/auth/publication/premiere';
  getPublicationByIDUrl = 'api/auth/publication/{id}';
  getEventByIDUrl = 'api/publication/load_event_by_id/{offset}/{limit}/';
  sendCommentUrl = 'api/auth/publication/send_comment';
  loadCommentsUrl = 'api/v2/auth/publication/load_comments';
  removeCommentUrl = 'api/auth/publication/remove_comment';
  setEmittingPublicationUrl = 'api/auth/publication/set_emitting_publication';
  updateUsersEmittingUrl = 'api/auth/publication/update_users_emitting';
  getUsersEmittingUrl = 'api/auth/publication/get_users_emitting';
  setEmittingEventUrl = 'api/auth/publication/set_emitting_event';
  updateNumParticipantsUrl = 'api/auth/publication/update_num_participants';
  sendReportUrl = 'api/auth/publication/send_report';
  deletePublicationUrl = 'api/auth/publication/delete';
  editPublicationUrl = 'api/auth/publication/edit/{id}';
  editClipUrl = 'api/auth/clip/edit/{id}';
  setMustBeRecordedUrl = 'api/publication/set_must_be_recorded';
  getRecordedVideosUrl = 'api/auth/publication/get_recorded_videos';
  getReviewsFromPublicationUrl = 'api/auth/publication/get_reviews_from_publication';
  getSavedPublicationsUrl = 'api/auth/publication/get_saved_publications';
  getPremieresByLoggedUserUrl = 'api/auth/publication/get_premieres_by_logged_user';
  getEventCollaborationsUrl = 'api/auth/publication/get_event_collaborations';
  inviteEventcollaboratorUrl = 'api/auth/publication/invite_event_collaborator';
  savePublicationUrl = 'api/auth/publication/save';
  likePublicationUrl = 'api/auth/publication/{id}/{status}';
  likeLiveUrl = 'api/auth/live/{id}/{status}';
  likeClipUrl = 'api/auth/clip/{id}/{status}';
  likeEventUrl = 'api/auth/event/{id}/{status}';
  saveEventUrl = 'api/auth/publication/event/save';
  getPurchasedContentUrl = 'api/auth/publication/get_purchased_content';
  createReviewUrl = 'api/auth/publication/review';
  countViewerUrl = 'api/auth/publication/count_viewer';
  statusPublicationBlocked = 'api/auth/user/status_publication_blocked';
  statusEventBlocked = 'api/auth/user/status_event_blocked';
  complaintPublication = 'api/auth/user/complaint_publication';
  complaintEvent = 'api/auth/user/complaint_event';
  kickUserFromPublicationUrl = 'api/auth/publication/kick_user';
  saveScreenShotUrl = 'api/publication/screenshot';
  activatePublicationNotificationsUrl = 'api/auth/publication/activate_notifications';
  getPublicationStarHistoryUrl = 'api/auth/publication/history/{id}';
  blockPublicationUrl = 'api/auth/user/block_publication';
  unblockPublicationUrl = 'api/auth/user/unblock_publication';
  getPublicationLikeHistoryUrl = 'api/auth/publication/{id}/likes';
  getClipLikeHistoryUrl = 'api/auth/clip/{id}/likes';
  getLiveLikeHistoryUrl = 'api/auth/live/{id}/likes';

  /**
   * Objeto modal
   */
  private _modal: HTMLIonModalElement;

  constructor(
    protected http: HttpClient,
    private modalCtrl: ModalController,
    private popoverController: PopoverController,
    private alertController: AlertController,
    private events: Events
  ) {
    super(http);
  }

  createUploaded(data: any): Observable<any> {
    const formData = new FormData();

    if ( data.uploadedFile ) {
      formData.append('videoFile', data.uploadedFile, data.uploadedFile.name);
    }

    if (data.uploadedFilePreview) {
      formData.append('previewFile', data.uploadedFilePreview, data.uploadedFilePreview.name);
    }

    if (data.videoStreamUrl) {
      formData.append('videoStreamUrl', data.videoStreamUrl);
    }

    formData.append('imageFile', data.imageFile);
    formData.append('description', data.description);
    formData.append('title', data.title);
    formData.append('categorySelected', data.categorySelected);
    formData.append('isPremium', data.isPremium ? '1' : '0');
    formData.append('saveIn', data.saveIn);
    formData.append('duration', data.duration);
    formData.append('fileSize', data.fileSize);
    formData.append('fileSizePreview', data.fileSizePreview);
    formData.append('onePrice', data.onePrice ? data.onePrice : '');
    formData.append('isVideo', data.isVideo ? '1' : '0');
    formData.append('isClip', data.isClip ? '1' : '0');

    const url = data.isClip
      ? this.createClipPublicationUrl
      : this.createUploadedPublicationUrl;

    return this.http.post(this.generateAbsoluteAPIURL(url), formData, {
      reportProgress: true
    });
  }

  loadPublicationsByUser(userID: number) {
    return this.http.post<Publication[]>(this.generateAbsoluteAPIURL(this.loadPublicationsByUserUrl),
      {
        userID
      });
  }

  uploadPublicationTempImage(file: File, isEvent = false): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('isEvent', JSON.stringify(isEvent));
    console.log(JSON.stringify(isEvent));
    return this.http.post(this.generateAbsoluteAPIURL(this.uploadPublicationTempImageUrl), formData);
  }

  createLive(data, publicationId = null, type: ContentType = undefined): Observable<any> {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.createLiveUrl),
      {
        ...data,
        publicationId
      }).pipe(map ((res) => {
        if ( res.done && publicationId ) {
          this.events.publish(environment.EVENTS.PUBLICATION_MODIFIED, {type, id: publicationId});
        }
        return res;
      }));
  }

  createEvent(data, eventId = null): Observable<any> {
    const formData = new FormData();

    Object.keys(data).forEach(item => {
      if ( item === 'uploadedFilePreview' ) {
        formData.append('previewFile', data[item], data[item].name);
      } else if ( item === 'isVideo' ) {
        formData.append(item, data.isVideo ? '1' : '0');
      } else {
        formData.append(item, data[item]);
      }
    })

    eventId ? formData.append('eventId', eventId) : null;

    return this.http.post<any>(this.generateAbsoluteAPIURL(this.createEventUrl),
        formData
      ).pipe(map ((res) => {
        if ( res.done && eventId ) {
          this.events.publish(environment.EVENTS.PUBLICATION_MODIFIED, {type: ContentType.Event, id: eventId});
        }
        return res;
      }));
  }

  loadPublication(publicationId: string) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.getPublicationByIDUrl.replace('{id}', publicationId)),{
      publicationId
    });
  }

  loadEvent(eventId: string, offset: number = 0, limit: number = 20) {
    return this.http.post<any>(
      this.generateAbsoluteAPIURL(this.getEventByIDUrl)
      .replace('{offset}', offset.toString())
      .replace('{limit}', limit.toString()),
      {
        eventId
      });
  }

  sendComment(commentToSend: string, publicationId: any, isEvent: boolean = false, replyTo = null) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.sendCommentUrl),
      {
        commentToSend,
        publicationId,
        isEvent,
        replyTo
      });
  }

  loadComments(id: number, offset, limit, isEvent: boolean = false) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.loadCommentsUrl),
      {
        id,
        isEvent,
        limit,
        offset
      });
  }

  removeComment(id: number) {
    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.removeCommentUrl),
      {
        id
      });
  }

  sendReport(message: string, publicationId: number, isEvent: boolean = false) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.sendReportUrl),
      {
        message,
        publicationId,
        isEvent
      });
  }


  setEmittingPublication(emitting: boolean, publicationId, event = false) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.setEmittingPublicationUrl), {
      emitting,
      publicationId,
      event
    });
  }

  setEmittingEvent(emitting: boolean, publicationId) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.setEmittingEventUrl), {
      emitting,
      publicationId
    });
  }

  setUsersEmitting(publicationId, userId, adding) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.updateUsersEmittingUrl), {
      publicationId,
      userId,
      adding
    });
  }

  getUsersEmitting(publicationId) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.getUsersEmittingUrl), {
      publicationId
    });
  }

  updateNumParticipants(id, num, event = false) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.updateNumParticipantsUrl), {
      id,
      num,
      event
    });
  }

  deletePublication(type: ContentType, id) {
    let deleteType = '';

    if (type !== ContentType.Event && type !== ContentType.Premiere) {
      deleteType = 'live';
    } else {
      deleteType = 'event';
    }

    return this.http.post<any>(this.generateAbsoluteAPIURL(this.deletePublicationUrl), {
      type: deleteType,
      id
    })
    .pipe(map ((res) => {
      if ( res.done ) {
        this.events.publish(environment.EVENTS.PUBLICATION_DELETED, {type, id});
      }
      return res;
    }));
  }

  editPublication(
    data: {
      imageFile: any;
      description: any;
      title: any;
      onePrice: any;
      categorySelected: number;
      isPremium: boolean;
      saveIn: string;
      isVideo: number;
      uploadedFilePreview: File | null
    }, 
    type, 
    id,
    contentType: ContentType
  ): Observable<any> {
    const formData = new FormData();

    Object.keys(data).forEach(item => {
      if ( item === 'uploadedFilePreview' ) {
        formData.append('previewFile', data[item], data[item].name);
      } else {
        formData.append(item, data[item]);
      }
    })

    formData.append('type', type);
    formData.append('id', id);

    return this.http.post<any>(this.generateAbsoluteAPIURL(this.editPublicationUrl).replace('{id}', id),
        formData
      ).pipe(map ((res) => {
        if ( res.done ) {
          this.events.publish(environment.EVENTS.PUBLICATION_MODIFIED, {contentType, id});
        }
        return res;
      }));
  }
  editClip(
    data: {
      imageFile: any;
      description: any;
      title: any;
      onePrice: any;
      categorySelected: number;
      isPremium: boolean;
      saveIn: string;
    },
    type,
    id,
    contentType: ContentType
  ): Observable<any> {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.editClipUrl).replace('{id}', id),
      {
        imageFile: data.imageFile,
        description: data.description,
        title: data.title,
        onePrice: data.onePrice,
        categorySelected: data.categorySelected,
        isPremium: data.isPremium,
        saveIn: data.saveIn,
        isVideo: true,
        type,
        id
      }).pipe(map ((res) => {
        if ( res.done ) {
          this.events.publish(environment.EVENTS.PUBLICATION_MODIFIED, {contentType, id});
        }
        return res;
      }));
  }

  setMustBeRecorded(publicationId, mustBeRecorded: boolean) {
    return this.http.post(this.generateAbsoluteAPIURL(this.setMustBeRecordedUrl),
      {
        publicationId,
        mustBeRecorded
      });
  }

  getRecordedVideos(publicationId) {
    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.getRecordedVideosUrl),
      {
        publicationId
      });
  }

  loadReviewsFromPublication(publicationId: number) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.getReviewsFromPublicationUrl),
      {
        publicationId
      });
  }

  getSavedPublications() {
    return this.http.get<JsonApiResponse<{ publications: Publication[]; events: Event[] }>>(
      this.generateAbsoluteAPIURL(this.getSavedPublicationsUrl));
  }

  savePublication(publicationId) {
    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.savePublicationUrl),
      {
        publicationId
      });
  }

  likePublication(contentType: ContentType, publicationId: number, status: boolean) {
    let url: string;

    switch( contentType ) {
      case ContentType.Clip: 
        url = this.likeClipUrl; 
        break;
      case ContentType.Video:
      case ContentType.Audio: 
        url = this.likePublicationUrl; 
        break;
      case ContentType.LiveVideo:
      case ContentType.LiveAudio: 
        url = this.likeLiveUrl; 
        break;
      case ContentType.Event:
      case ContentType.Premiere:
        url = this.likeEventUrl;
        break;
    }

    return this.http.post<JsonApiResponse<any>>(
      this.generateAbsoluteAPIURL(
        url.replace('{id}', publicationId.toString())
          .replace('{status}', status ? 'like' : 'unlike')
      ),
      {}
    );
  }

  saveEvent(eventId) {
    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.saveEventUrl),
      {
        eventId
      });
  }

  getPremieresByLoggedUser() {
    return this.http.get<JsonApiResponse<Event[]>>(
      this.generateAbsoluteAPIURL(this.getPremieresByLoggedUserUrl));
  }

  getEventCollaborations(eventId) {
    return this.http.post<JsonApiResponse<any>>(
      this.generateAbsoluteAPIURL(this.getEventCollaborationsUrl), {
        eventId
      });
  }

  inviteEventCollaborator(userId, eventId) {
    return this.http.post<JsonApiResponse<any>>(
      this.generateAbsoluteAPIURL(this.inviteEventcollaboratorUrl), {
        userId,
        eventId
      });
  }

  createPremiere(data: any, eventId = null) {
    const formData = new FormData();

    Object.keys(data).forEach(item => {
      if ( item === 'uploadedFilePreview' ) {
        formData.append('previewFile', data[item], data[item].name);
      } else if ( item === 'isVideo' ) {
        formData.append(item, data.isVideo ? '1' : '0');
      } else {
        formData.append(item, data[item]);
      }
    })

    eventId ? formData.append('eventId', eventId) : null;

    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.createPremiereUrl),
      formData)
      .pipe(map ((res) => {
        if ( res.done && eventId ) {
          this.events.publish(environment.EVENTS.PUBLICATION_MODIFIED, {type: ContentType.Premiere, id: eventId});
        }
        return res;
      }));;
  }

  getPurchasedContent() {
    return this.http.get<JsonApiResponse<{ publications: Publication[]; events: Event[] }>>(
      this.generateAbsoluteAPIURL(this.getPurchasedContentUrl));
  }

  createReview(publicationId, score, comment) {
    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.createReviewUrl),
      {
        publicationId,
        score,
        comment
      });
  }


  /**
   * @param {number} id Publication id
   * @param {number} reason
   * @param {string} description
   */
  reportPublication(id: number, type: ContentType, reason: number, description: string) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.complaintPublication), {
      publication_id: id,
      reason: reason,
      description: description
    }).pipe(map ((res) => {
      if ( res.done ) {
        this.events.publish(environment.EVENTS.PUBLICATION_REPORTED, {type, id});
      }
      return res;
    }));
  }

  /**
   * @param {number} id Event id
   * @param {number} reason
   * @param {string} description
   */
  reportEvent(id: number, type: ContentType, reason: number, description: string) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.complaintEvent), {
      event_id: id,
      reason: reason,
      description: description
    }).pipe(map ((res) => {
      if ( res.done ) {
        this.events.publish(environment.EVENTS.PUBLICATION_REPORTED, {type, id});
      }
      return res;
    }));
  }


  countViewer(publicationId: number) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.countViewerUrl), {
      publicationId
    });
  }

  kickUserFromPublication(userId: string, publicationId, type = null, isEvent = false) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.kickUserFromPublicationUrl), {
      userId,
      publicationId
    });
  }

  saveScreenShot(pubId: number, screen: any) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.saveScreenShotUrl), {
      publicationId: pubId,
      screenshot: screen
    });
  }

  activatePublicationNotifications(pubId: number, isPublication = true) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.activatePublicationNotificationsUrl), {
      publicationId: pubId,
      isPublication
    });
  }

  async getUserReview() {
    this._modal = await this.modalCtrl.create({
      cssClass: "fit-modal floating-modal",
      component: CreateReviewComponent,
      mode: "ios",
    });
    this._modal.present();

    const { data, role } = await this._modal.onWillDismiss();

    if ( role === 'confirm' ) {
      return data
    } else {
      return undefined
    }
  }

  getPublicationStarHistory(id: number) {
    return this.http.get<any>(this.generateAbsoluteAPIURL(this.getPublicationStarHistoryUrl.replace('{id}', id.toString())));
  }
  getPublicationLikeHistory(id: number, contentType: ContentType) {
    let url = '';

    switch( contentType ) {
      case ContentType.Clip:
        url = this.getClipLikeHistoryUrl;
        break;
      case ContentType.LiveAudio:
      case ContentType.LiveVideo:
        url = this.getLiveLikeHistoryUrl;
        break;
      default:
        url = this.getPublicationLikeHistoryUrl
    }

    return this.http.get<any>(this.generateAbsoluteAPIURL(url.replace('{id}', id.toString())))
      .pipe(tap (
        (data) => {
          if ( data && !data.done ) {
            this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, data.msg);
          } else if ( !data ) {
            this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error, vuelve a intentarlo');
          }
        }, (error) => {
          if ( error?.error && error.error.msg ) {
            this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, error.error.msg);
          } else {
            this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error, vuelve a intentarlo');
          }
        }
      ));
  }

  /**
   * Open comments modal
   * @param {Publication} publication
   */
  async openCommentsModal(publication: Publication) {
    this._modal = await this.modalCtrl.create({
      component: CommentsPage,
      componentProps: { publication },
      mode: "ios",
    });
    this._modal.present();
  }

  closeModal() {
    this._modal.dismiss();
  }

  blockPublication(type: ContentType, id: number) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.blockPublicationUrl), {
      publication_id: id,
    })
    .pipe(map ((res) => {
      if ( res.done ) {
        this.events.publish(environment.EVENTS.PUBLICATION_BLOCKED, {type, id});
      }
      return res;
    }));
  }

  unblockPublication(type: ContentType, id: number) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.unblockPublicationUrl), {
      publication_id: id,
    }).pipe(map ((res) => {
      if ( res.done ) {
        this.events.publish(environment.EVENTS.PUBLICATION_UNBLOCKED, {type, id});
      }
      return res;
    }));
  }

  async presentBlockPopover(e, type: ContentType, publication: Publication): Promise<BlockPopoverOption | undefined> {
    e.preventDefault();
    e.stopPropagation();

    let result;

    const popover = await this.popoverController.create({
      component: BlockPopoverComponent,
      componentProps: {
        blocked: false,
        itsMe: false,
        canEmit: false
      },
      event: e,
      animated: true,
      mode: 'ios',

    });

    await popover.present();

    const {data} = await popover.onDidDismiss();

    switch (data) {
      case BlockPopoverOption.Block:
        this.presentAlertBlockContent(type, publication);
        break;
      case BlockPopoverOption.Report:
        result = await this.presentAlertReportContent(publication);
        break;
    }

    return result;
  }

  async presentAlertBlockContent(type: ContentType, publication: Publication) {

    this.blockPublication(type, publication.id).subscribe(
      data => {
        if (data.msg === 'Ok') {
          this.events.publish(environment.EVENTS.ALERT_TOAST_NOTICE, 'No volverás a ver este contenido');
        } else {
          this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error, vuelve a intentarlo');
        }
      }, error => {
        this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error, vuelve a intentarlo');
      });
  }

  async presentAlertReportContent(publication: Publication): Promise<BlockPopoverOption | undefined> {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-alert-class',
      header: 'Denunciar contenido',
      message: '¿Estás seguro de que quieres denunciar este contenido?',
      buttons: [
        {
          text: 'Denunciar',
          role: 'confirm',
          cssClass: 'alert-button-danger'
        },
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'alert-button-dark'
        }
      ]
    });

    await alert.present();

    const result = await alert.onDidDismiss();

    if ( result.role === 'confirm' ) {
      return BlockPopoverOption.Report;
    } else {
      return undefined;
    }
  }
}
