import { Component, OnInit, AfterViewInit, Input, Output, ViewChild, ElementRef, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { User } from '../../interfaces/user';
import { Collaborator } from '../../interfaces/collaborator';
import { Publication } from '../../interfaces/publication';
import { Event } from '../../interfaces/event';
import { environment } from '../../../environments/environment';
import { MediaService } from '../../services/media.service';
import { Events } from '../../services/events.service';
import { AuthService } from '../../services/auth.service';
import { ProfileService } from '../../services/profile.service';
import { NotificationService } from '../../services/notification.service';
import { skipWhile, take } from 'rxjs/operators';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserListComponent implements OnInit, AfterViewInit {

  @ViewChild('content', {static: false}) content: ElementRef;

  @Input() publication: Publication | Event;
  @Input() collaboratorsOnline: User[];
  @Input() iamFollowing: any;
  @Input() assistants: { user: User; subscribed: boolean; following: boolean }[] = [];

  @Output() reducedProfile = new EventEmitter<User>();

  me: User;

  constructor(
    private mediaService: MediaService,
    private events: Events,
    private profileService: ProfileService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.authService.watchUser()
      .pipe(
        skipWhile(data => !data),
        take(1))
      .subscribe((user: User) => {
        this.me = user;
        this.cdRef.detectChanges();
      });
  }

  ngAfterViewInit() {
    const scrollcontainer = this.content.nativeElement;



    for (const collaborator of this.collaboratorsOnline) {
      collaborator.id = Number(collaborator.id);
    }

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let index = 0; index < this.collaboratorsOnline.length; index++) {
      const collaborator = this.collaboratorsOnline[index];
      collaborator.following = this.itsFollowUser(collaborator);
    }

    scrollcontainer.ontouchmove = (e) => {
      if ( this.content.nativeElement.scrollTop !== 0 )
        e.stopPropagation();
    };
  }

  getImgProfile(user: User) {
    return this.mediaService.generateImgProfileURL(user.id, user.imgProfile);
  }

  isAuthorOnline() {
    return this.collaboratorsOnline.some(collaborator => Number(collaborator.id) === this.publication.author.id);
  }

  getCollaborators() {
    return this.collaboratorsOnline.filter(
    // @ts-ignore
      asistant => !asistant.isAuthor && asistant.isCollaborator && !asistant.isSubscribed
    );
  }

  getSubscribers() {
    return this.collaboratorsOnline.filter(
    // @ts-ignore
      asistant => !asistant.isAuthor && !asistant.isCollaborator && asistant.isSubscribed
    );
  }

  getNotSubscribed() {
    return this.collaboratorsOnline.filter(
    // @ts-ignore
      asistant => !asistant.isAuthor && !asistant.isCollaborator && !asistant.isSubscribed
    );
  }

  getBadgeSrc(user) {
    return this.mediaService.getBadgeSrc(user);
  }

  itsFollowUser(user: any){
    let found = false;
    let itsfollow = false;

    //console.log('iamFollowing', this.iamFollowing);
    for (const property in this.iamFollowing) {
      if (this.iamFollowing.hasOwnProperty(property)){
        const element = this.iamFollowing[property];
        if(element.id === user.id){
          if(element.following){ itsfollow = true; }
          found = true;
          break;
        }
      }
    }

    if(found && itsfollow){
      return true;
    } else {
      return false;
    }
  }

  followUser(user: User) {
    this.profileService.follow(user.id, true).subscribe((data) => {
      if (data.following) {
        user.following = true;
        this.cdRef.detectChanges();
        this.notificationService.publishOnFollow({ id: user.id, follow: true });
      }
    });
  }

  unfollowUser(user: User) {
    this.profileService.follow(user.id, false).subscribe((data) => {
      if (!data.following) {
        user.following = false;
        this.cdRef.detectChanges();
        this.notificationService.publishOnFollow({ id: user.id, follow: false });
      }
    });
  }

  imFollowing(user: User): boolean {
    return this.profileService.getFollowingStatusByUser(user);
  }

  itsMe(user: User): Boolean {
    return Number(user.id) === this.me?.id;
  }
}
