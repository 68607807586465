import { Component, ViewChild } from '@angular/core';
import {SwiperOptions} from 'swiper';
import {SwiperComponent} from 'swiper/angular';
import { ModalController } from '@ionic/angular';

import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.scss'],
})
export class PresentationComponent {

  @ViewChild('swiper', { static: true }) swiper: SwiperComponent;

  swiperParams: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 30,
    //pagination: {clickable: true},
    pagination: {
      el: '.swiper-pagination',
    },
  };

  constructor(
    private userService: UserService,
    private modalCtrl: ModalController
  ) { }

  nextSlide() {
    this.swiper.swiperRef.slideNext();
  }
  
  close() {
    this.modalCtrl.dismiss();
  }
}
