<!-- Icon -->
<div class="title-icon">
  <ion-icon class="icon upload" src="assets/svg-icon/micro2.svg"></ion-icon>
</div>

<!-- Title -->
<div class="title">
  Salas de Audio
</div>

<!-- Subtitle -->
<div class="subtitle">
  Crea una sala de audio y comienza una emisión en directo. Configura tu sala y elige tus preferencias para que otros usuarios puedan interactuar a través del “Chat” o “Levantar la mano”.
</div>

<!-- Subtitle -->
<div class="subtitle">
  Una vez finalizada la emisión, podrás volver a emitir en esa sala cada vez que quieras o interactuar con otros usuarios mediante los comentarios.
</div>

<!-- Buttons -->
<div class="buttons">
  <ion-button color="darkteal" (click)="confirm()" *ngIf="confirmButton">
    Crear canal de audio
  </ion-button>
  <ion-button 
    fill="clear" 
    color="dark"
    class="go-back" 
    (click)="dismiss()">
    Cerrar
  </ion-button>
</div>