import { Component, AfterViewInit, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { inOutFade, inOutFromLeft, inOutFromRight } from '../../animations/animations';
import { Keyboard } from '@capacitor/keyboard';
import { Platform } from '@ionic/angular';
import { ProfileService } from '../../services/profile.service';
import { PublicationService } from '../../services/publication.service';
import { Events } from '../../services/events.service';
import { environment } from '../../../environments/environment';
import { UserService } from '../../services/user.service';
import { ModalController } from '@ionic/angular';
import { ContentType } from '../../enums/content-type';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  animations: [inOutFade, inOutFromLeft, inOutFromRight]
})
export class ReportComponent implements OnDestroy, AfterViewInit {

  @ViewChild('content') content: ElementRef;
  @ViewChild('step') step: ElementRef;

  @Input() type: ContentType;
  @Input() contentReport: boolean = false;
  @Input() eventReport: boolean = false;
  @Input() identifier: number;

  containerHeight: number;
  containerMaxHeight: string = '90vh';

  details: boolean = false;

  softLoading: boolean = false;

  keyboardHeight: number;

  contentTranslate: number = 0;

  reason: number;
  description: string;

  viewInited: boolean = false;

  listener: any = {};

  bottomDescription = 0;
  constructor(
    private platform: Platform,
    private cdRef: ChangeDetectorRef,
    private profileService: ProfileService,
    private publicationService: PublicationService,
    private events: Events,
    private userService: UserService,
    private modalCtrl: ModalController
  ) {
    if (this.platform.is('hybrid')) {
      this.listener.keyboardWillShow = Keyboard.addListener('keyboardWillShow', info => {
        const contentTop = this.content?.nativeElement.getBoundingClientRect().top;
        // if (this.platform.is('ios')) {
        //   this.contentTranslate = contentTop - 50;
        // } else {
        //   this.contentTranslate = contentTop - 10;
        // }

        // @ts-ignore
        this.keyboardHeight = info.keyboardHeight;
        this.containerMaxHeight = (this.platform.height() - this.keyboardHeight) + 'px';
        this.cdRef.detectChanges();
      });

      this.listener.keyboardWillHide = Keyboard.addListener('keyboardWillHide', () => {
        this.contentTranslate = 0;
        this.containerMaxHeight = '90vh';
        this.cdRef.detectChanges();
      });
    }
  }

  ngOnDestroy() {
    this.listener?.keyboardWillShow?.remove();
    this.listener?.keyboardWillHide?.remove();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.calculateHeight();
    });

    setTimeout(() => {
      this.viewInited = true;
      this.cdRef.detectChanges();
    }, 400)
  }

  calculateHeight() {
    const height = this.step?.nativeElement.getBoundingClientRect().height;
    if (height !== this.containerHeight) {
      this.containerHeight = height;
    }
  }

  send() {
      const descriptionWithoutSpaces = this.description?.replace(/\s/g, '');
      const descriptionLength = descriptionWithoutSpaces.length;

      if (!descriptionWithoutSpaces || descriptionLength < 20) {
      this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Debes dar una descripción de al menos 20 carácteres en el reporte');
      return;
    }
    this.softLoading = true;

    if (!this.contentReport) {
      this.profileService.reportUser(this.identifier, this.reason, this.description).subscribe(
        data => {
          if (data.msg === 'Ok') {
            this.events.publish(environment.EVENTS.ALERT_TOAST_NOTICE, 'La denuncia ha sido enviada correctamente');
            this.reported();
          } else {
            this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error, vuelve a intentarlo');
          }

          this.softLoading = false;
        }, error => {
          this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error, vuelve a intentarlo');
          this.softLoading = false;
        });
    } else if (!this.eventReport) {
      this.publicationService.reportPublication(this.identifier, this.type, this.reason, this.description).subscribe(
        data => {
          if (data.msg === 'Ok') {
            this.events.publish(environment.EVENTS.ALERT_TOAST_NOTICE, 'La denuncia ha sido enviada correctamente');
            this.reported();
          } else {
            this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error, vuelve a intentarlo');
          }

          this.softLoading = false;
        }, error => {
          this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error, vuelve a intentarlo');
          this.softLoading = false;
        });
    } else {
      this.publicationService.reportEvent(this.identifier, this.type, this.reason, this.description).subscribe(
        data => {
          if (data.msg === 'Ok') {
            this.events.publish(environment.EVENTS.ALERT_TOAST_NOTICE, 'La denuncia ha sido enviada correctamente');
            this.reported();
          } else {
            this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error, vuelve a intentarlo');
          }

          this.softLoading = false;
        }, error => {
          this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error, vuelve a intentarlo');
          this.softLoading = false;
        });
    }
  }

  /**
   * Return reported
   */
  reported() {
    return this.modalCtrl.dismiss(true, 'confirm')
  }

  /**
   * Close modal
   */
  dismiss() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  reasonReport(reason: number, details: boolean ) {
    if(details){
      this.reason = reason;
      this.details = details;
      this.bottomDescription = 160;
      this.containerHeight = 400;
    }else{
      this.details = details;
      this.bottomDescription = 0;
      this.containerHeight = 500;
    }
  }
}
