import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { inOutStarAnimation1, starAnimation1 } from '../../animations/animations';

@Component({
  selector: 'app-star-like',
  templateUrl: './star-like.component.html',
  styleUrls: ['./star-like.component.scss'],
  animations: [
    inOutStarAnimation1,
    starAnimation1
  ]
})
export class StarLikeComponent  implements OnInit {

  @Output() animationDone: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  animateStar: boolean = false;
  hideStar: boolean = false;

  constructor() { 
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.animateStar = true;
  }

  done() {
    setTimeout(() => {
      this.hideStar = true;

      setTimeout(() => {
        this.animateStar = false;
        this.animationDone.emit(true);
      }, 600)
    }, 1000)
    
  }
}
