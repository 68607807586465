<ion-grid>
  <ion-row *ngIf="!skeleton">
    <ion-col size="12">
      <div class="container-audio">
        <div class="imagen-container">
          <ion-button class="dots"
            *ngIf="showDots && publication?.author.id !== user?.id"
            color="clear"
            (click)="presentBlockPopover($event)">
            <ion-icon src="assets/svg-icon/dots.svg"></ion-icon>
          </ion-button>
          <div *ngIf="publication.isPremium"
               class="locked-content"
               [ngClass]="{'open': publication.collaborating || publication.subscribed || publication.author.id === user?.id}">
            <ion-icon *ngIf="!(publication.collaborating || publication.subscribed || publication.author.id === user?.id)" name="lock-closed"></ion-icon>
            <ion-icon *ngIf="publication.collaborating || publication.subscribed || publication.author.id === user?.id" name="lock-open"></ion-icon>
          </div>

          <lazy-img class="profile-container-back"
            alt="image"
            [size]="400"
            (click)="enterPublication()"
            [source]="mediaServ.generatePublicationThumbnailImgURL(publication.id,publication.thumbnail)"></lazy-img>

          <lazy-img
            class="profile-container-user"
            *ngIf="userImage"
            [routerLink]="getRouteUserProfile(publication.author.username)"
            [size]="150"
            [source]="mediaServ.generateImgProfileURL(publication.author.id,publication.author.imgProfile)">
          </lazy-img>

        </div>
        <div *ngIf="publication.emitting" class="container-audio-viewers">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.63 26.5">
            <defs></defs>
            <g id="Layer_2" data-name="Layer 2">
              <g id="Capa_1" data-name="Capa 1">
                <g id="New_User_Add_User" data-name="New User, Add User">
                  <circle class="cls-166556" cx="9.31" cy="6.19" r="4.69"/>
                  <path class="cls-166556"
                        d="M1.5,26.5V21.81a6.25,6.25,0,0,1,6.25-6.25h3.13a6.24,6.24,0,0,1,6.24,6.25V26.5"/>
                </g>
              </g>
            </g>
          </svg>
          <span>{{publication.numParticipants}}</span>
        </div>
        <div class="container-text-image" (click)="enterPublication()">
          <p>{{publication.title}}</p>
          <p *ngIf="!showCreatedAt && publication.numViewers > 0" class="views">{{publication.numViewers}}
            {{publication.numViewers > 1 ? 'reproducciones' : 'reproducción'}}</p>
          <p *ngIf="!showCreatedAt" class="age">{{publication.createdAt  | dateAgo}}</p>
          <p *ngIf="showCreatedAt" class="age">Creado el {{publication.createdAt | date:"M/d/y"}}</p>
        </div>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>

<ion-grid *ngIf="skeleton">
  <ion-row>
    <ion-col size="12">
      <div class="container-audio">
        <div class="imagen-container">
          <ion-thumbnail class="profile-container-back">
            <ion-skeleton-text [animated]="true"></ion-skeleton-text>
          </ion-thumbnail>

          <ion-thumbnail class="profile-container-user">
            <ion-skeleton-text [animated]="true"></ion-skeleton-text>
          </ion-thumbnail>

        </div>

        <div class="container-text-image">
          <p>
            <ion-skeleton-text [animated]="true" style="width: 100%; --border-radius: 5px;"></ion-skeleton-text>
            <ion-skeleton-text [animated]="true"
                               style="width: 50%; --border-radius: 5px; margin: 4px auto"></ion-skeleton-text>
            <ion-skeleton-text [animated]="true"
                               style="width: 80%; --border-radius: 5px; margin: 4px auto"></ion-skeleton-text>
          </p>
        </div>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
