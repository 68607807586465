import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { FeedService } from '../../../../services/feed.service';

import { Publication } from '../../../../interfaces/publication';

import { ContentType } from '../../../../enums/content-type';
import { FormMode } from '../../../../enums/form-mode';

export enum PageMode {
  List,
  Form
}

@Component({
  selector: 'app-live-channel',
  templateUrl: './live-channel.component.html'
})
export class LiveChannelComponent implements OnInit, OnDestroy {

  @Input() pageMode: PageMode = PageMode.List;
  @Input() rootPage: any;
  @Input() type: ContentType;
  @Input() mode: FormMode = FormMode.Create;
  @Input() publication: Publication;

  constructor( private feedService: FeedService ) { }

  ngOnInit() {}

  ngOnDestroy() {
    this.feedService.cleanCategoriesSelection();
  }
}
