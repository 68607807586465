/* eslint-disable max-len */
import { Token } from '@angular/compiler';
import { ElementRef, EventEmitter, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';


import {
    Room,
    RoomEvent,
    RemoteParticipant,
    RemoteTrackPublication,
    RemoteTrack,
    VideoPresets,
    Track,
    createLocalTracks,
    createLocalScreenTracks,
    supportsAdaptiveStream,
    supportsDynacast,
    createLocalVideoTrack,
    LogLevel,
    Participant,
    LocalParticipant,
    TrackPublishOptions,
    VideoCaptureOptions,
    LocalTrackPublication,
    ConnectionState,
    LocalTrack,
    CreateLocalTracksOptions,
    TrackPublication,
} from 'livekit-client';
import { AndroidFullScreen, AndroidSystemUiFlags } from '@awesome-cordova-plugins/android-full-screen/ngx';
import { LiveKitService } from '../services/live-kit.service';
import { HttpClient, HttpHandler } from '@angular/common/http';
import { Capacitor } from '@capacitor/core';
import { UserStreamMetadata } from '../interfaces/stream-user';



declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        MediaControls: {
            setModeAudio: (str: any) => Promise<void>;
        };
        // eslint-disable-next-line @typescript-eslint/naming-convention
        AudioToggle?: {
            setAudioMode?: (str: any) => Promise<void>;
        };
    }
    interface Document {
        mozCancelFullscreen?: () => Promise<void>;
        msExitFullscreen?: () => Promise<void>;
        webkitExitFullscreen?: () => Promise<void>;
        mozFullScreenElement?: Element;
        msFullscreenElement?: Element;
        webkitFullscreenElement?: Element;
    }
}

const androidFullScreen = new AndroidFullScreen();


let roomObj;
let token;
const server = environment.LIVEKIT_BASE_URL;


let tracksPreview = [];
let tracksPreviewScreen;

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const AudioToggle;


// MEDIA CONTROLS VARS
let isMuted;
let cameraIndex;



@Injectable({
    providedIn: 'root'
})




export class LiveKitClient {

    /* CONTROLS MEDIA VARS */
    mediaControls;
    stylesContainerVideoBackground;
    stylesContainerVideoPeerLists;
    stylesVideoInBackground;
    stylesVideoInPeerLists;
    debug;
    cameraIndex: number;
    iwidth: number;
    iheight: number;
    failedIndex: boolean;
    devices;
    streamVideo;
    facingMode: string;
    liveKitToken;


    metaDataCache = [];


    //create suscription
    onConnectParticipant = new EventEmitter<number>();



    /* CONSTRUCTOR */
    constructor(
            protected liveKitService: LiveKitService,
            private authService: AuthService,
        ) {
        // eslint-disable-next-line max-len
        this.stylesContainerVideoBackground = 'display: block; width: 100% !important; height: 100%; max-height: 100vh; aspect-ratio: 16/9; background-color: rgb(255 255 255 / 0%); position: relative;';
        // eslint-disable-next-line max-len
        this.stylesContainerVideoPeerLists = 'display: block; width: calc(33.33% - 4px); height: auto; max-height: 100vh; object-fit: contain !important; aspect-ratio: 16/9; border-radius: 7px; /*margin: 2px;*/ position: relative;';
        // eslint-disable-next-line max-len
        this.stylesVideoInBackground = 'display: block; width: 100% !important; height: 100%; max-height: 100vh; object-fit: contain !important; aspect-ratio: 16/9; background-color: rgb(255 255 255 / 0%); position: relative;';
        // eslint-disable-next-line max-len
        this.stylesVideoInPeerLists = 'display: block; width: 100% !important; height: 100%; max-height: 100vh; object-fit: contain !important; aspect-ratio: 16/9; background-color: rgb(255 255 255 / 0%); position: relative; border-radius: 10px;';
        // Mode debug
        this.debug = true;

        this.devices = null;
        this.streamVideo = null;
        this.facingMode = 'user';
        this.liveKitToken = null;


        this.mediaControls = {
            microphone: {
                lastState: false,
                onAppMinimized: async (cb: any) => {
                    this.mediaControls.microphone.lastState = this.localSharingMic();
                    if (this.mediaControls.microphone.lastState) {
                        this.mediaControls.microphone.disabled(async (state, message) => {
                            if (state) {
                                cb(true, 'success');
                            } else {
                                cb(false, message);
                                return;
                            }
                        });
                    }
                },
                onAppResume: async (cb: any) => {
                    if (this.mediaControls.microphone.lastState) {
                        this.mediaControls.microphone.enabled(async (state, message) => {
                            if (state) {
                                cb(true, 'success');
                            } else {
                                cb(false, message);
                                return;
                            }
                        });
                    }
                },
                enabled: async (cb: any) => {
                    try {
                        await roomObj.localParticipant.setMicrophoneEnabled(true);
                        cb(true, 'success', roomObj.localParticipant.isMicrophoneEnabled);
                        this.audioLocalFix();
                        return;
                    } catch (error) {
                        this.onError(error);
                        cb(false, this.handlingMediaError(error));
                        return;
                    }
                },
                disabled: async (cb: any) => {
                    try {
                        await roomObj.localParticipant.setMicrophoneEnabled(false);
                        cb(true, 'success', roomObj.localParticipant.isMicrophoneEnabled);
                        this.audioLocalFix();
                        return;
                    } catch (error) {
                        this.onError(error);
                        cb(false, this.handlingMediaError(error));
                        return;
                    }
                },
                toggle: async (cb: any) => {
                    try {
                        if (!roomObj.localParticipant.isMicrophoneEnabled) {
                            await roomObj.localParticipant.setMicrophoneEnabled(true);
                            isMuted = false;
                            this.mediaControls.microphone.lastState = roomObj.localParticipant.isMicrophoneEnabled;
                            cb(true, 'success', roomObj.localParticipant.isMicrophoneEnabled);
                            return;
                        }
                        await roomObj.localParticipant.setMicrophoneEnabled(false);
                        isMuted = true;
                        this.mediaControls.microphone.lastState = roomObj.localParticipant.isMicrophoneEnabled;
                        cb(true, 'success', roomObj.localParticipant.isMicrophoneEnabled);
                        this.audioLocalFix();
                        return;
                    } catch (error) {
                        this.onError(error);
                        cb(false, this.handlingMediaError(error));
                        return;
                    }
                }
            },
            camera: {
                lastState: false,
                onAppMinimized: async (cb: any) => {
                  this.mediaControls.camera.lastState = this.localSharingVideo();
                    if (this.mediaControls.camera.lastState) {
                        this.mediaControls.camera.disabled(async (state, message) => {
                            if (state) {
                                cb(true, 'success');
                            } else {
                                cb(false, message);
                                return;
                            }
                        });
                    }
                },
                onAppResume: async (cb: any) => {
                    if (this.mediaControls.camera.lastState) {
                        this.mediaControls.camera.enabled(async (state, message) => {
                            if (state) {
                                cb(true, 'success');
                            } else {
                                cb(false, message);
                                return;
                            }
                        });
                    }
                },
                enabled: async (cb: any) => {
                    try {
                        const options: VideoCaptureOptions = {
                          resolution: {
                            aspectRatio: 1.77,
                            width: 1280,
                            height: 720
                          },
                        };

                        /**
                         * Catch errors and handler
                         */
                        const localParticipant: LocalParticipant = roomObj.localParticipant;
                        const localTrackPublication: LocalTrackPublication = await localParticipant.setCameraEnabled(true, options);

                        cb(true, 'success', roomObj.localParticipant.isCameraEnabled);
                        this.audioLocalFix();
                        return;
                    } catch (error) {
                        this.onError(error);
                        cb(false, this.handlingMediaError(error));
                        return;
                    }
                },
                disabled: async (cb: any) => {
                    try {
                        await roomObj.localParticipant.setCameraEnabled(false);
                        cb(true, 'success', roomObj.localParticipant.isCameraEnabled);
                        this.audioLocalFix();
                        return;
                    } catch (error) {
                        this.onError(error);
                        cb(false, this.handlingMediaError(error));
                        return;
                    }
                },
                toggle: async (cb: any, faceMode) => {
                    try {
                      const track = roomObj.localParticipant.getTrack(Track.Source.Camera);

                      if (roomObj.localParticipant.isCameraEnabled) {
                          await roomObj.localParticipant.setCameraEnabled(false);
                          cb(true, 'success', roomObj.localParticipant.isCameraEnabled);
                          return;
                      }


                      if (!roomObj.localParticipant.isCameraEnabled) {
                          if(track){
                              await track.unmute();
                              cb(true, 'success', roomObj.localParticipant.isCameraEnabled);
                              return;
                          }
                      }


                      const options: VideoCaptureOptions = {
                        facingMode: faceMode !== 'user' ? faceMode : 'user',
                        resolution: {
                          aspectRatio: 1.77,
                          width: 1280,
                          height: 720
                        },
                      };

                      /**
                       * Catch errors and handler
                       */
                      try {
                        const localParticipant: LocalParticipant = roomObj.localParticipant;
                        const localTrackPublication: LocalTrackPublication = await localParticipant.setCameraEnabled(true, options);
                        cb(true, 'success', roomObj.localParticipant.isCameraEnabled);
                      } catch (error) {
                        cb(false, error.message, false);
                        return;
                      }
                    } catch (error) {
                      this.onError(error);
                      cb(false, this.handlingMediaError(error));
                      return;
                    }

                }
            }
        };
    }


    setStyle(video, oldStyle) {
        setTimeout(() => {
            video.setAttribute('style', oldStyle);
        }, 500);
    };
    iOSFix() {
        const containerVideos = document.querySelectorAll('video.remote');
        if (containerVideos != null) {
            containerVideos.forEach(video => {
                const oldStyle = video.getAttribute('style');
                video.setAttribute('style', 'display:none !important');
                this.setStyle(video, oldStyle);
            });
        }
        return;
        //containerVideos.setAttribute('style', 'display:none !important');
    }


    async wait(mirror: boolean, isOnlyAudio: boolean, cb: any) {
        //const devices = await Room.getLocalDevices('videoinput');
        //console.log('devices', devices);

        if (mirror) {
            const intervalCheck = setInterval(() => {
                const selector = document.querySelector('.video-participants');
                if (selector != null) {
                    clearInterval(intervalCheck);
                    cb();
                    return;
                }
            }, 100);
        } else {

            let containersVideos = '.video-participants';

            if (isOnlyAudio) {
                containersVideos = '.audios-participants';
            }

            const intervalCheck = setInterval(() => {
                const selector = document.querySelector(containersVideos + ' .owner-videos');
                if (selector != null) {
                    clearInterval(intervalCheck);
                    cb();
                    return;
                }
            }, 100);
        }
    }

    managerMedia(local: boolean, element: any, cb: any) {
        if (local) {
            element.muted = true;
        } else {
            setTimeout(() => {
                const promise = element.play();
                if (promise !== undefined) {
                    promise.then(_ => {
                        cb(true);
                        return;
                    }).catch(error => {
                        try {
                            element.muted = true;
                            const promise2 = element.play();
                            if (promise2 !== undefined) {
                                cb(false);
                                return;
                            }
                        } catch (err) {
                            cb(false);
                            return;
                        }
                    });
                }
            }, 1000);
        }
    }
    setAttrPrepared(element: any, type: any = false) {
        let className = '';
        if (type !== false) { className = type; }
        element.setAttribute('autoplay', true);
        element.setAttribute('id', 'video');
        element.setAttribute('playsinline', '');
        element.setAttribute('class', 'local fixNotFreeze ' + className);
        // eslint-disable-next-line max-len
        element.setAttribute('style', 'display: block; width: 100% !important; height: auto; max-height: 100vh;object-fit: contain; aspect-ratio: 16/9; background-color: black');
        element.setAttribute('poster', 'assets/estrim/poster/logo-light-square.png');
        element.controls = false;
    }
    preparedPeerContainer(local, participant){
        let peerId;
        if (!local) {
          peerId = 'pid' + participant.sid;
        } else {
          peerId = 'pid' + participant.sid;
        }
        /** Seach container videos */
        const containerVideos = document.querySelector('.'+peerId);
        let containerVideoPeer;
        let videoPeer;
        let overlayPeer;
        let peerIcons;
        let cssFixFreeze;

        if(containerVideos == null){
          containerVideoPeer = document.createElement('div');
          videoPeer = document.createElement('div');
          overlayPeer = document.createElement('div');
          peerIcons = document.createElement('div');
          cssFixFreeze = document.createElement('div');




          /** Mic icons */
          if(!local){
          peerIcons.innerHTML = `
          <span class="mic">
            <svg class="unmute" fill="currentColor" height="20" viewBox="0 0 16 16" width="20" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z"/>
                    <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z"/>
            </svg>
            <svg class="mute active" fill="currentColor" height="20" viewBox="0 0 16 16" width="20" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 8c0 .564-.094 1.107-.266 1.613l-.814-.814A4.02 4.02 0 0 0 12 8V7a.5.5 0 0 1 1 0v1zm-5 4c.818 0 1.578-.245 2.212-.667l.718.719a4.973 4.973 0 0 1-2.43.923V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 1 0v1a4 4 0 0 0 4 4zm3-9v4.879l-1-1V3a2 2 0 0 0-3.997-.118l-.845-.845A3.001 3.001 0 0 1 11 3z"/>
                <path d="m9.486 10.607-.748-.748A2 2 0 0 1 6 8v-.878l-1-1V8a3 3 0 0 0 4.486 2.607zm-7.84-9.253 12 12 .708-.708-12-12-.708.708z"/>
            </svg>
          </span>
          <span class="camera">
            <svg class="unmute" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.54 42">
                    <defs></defs>
                    <g id="Layer_2" data-name="Layer 2">
                        <g id="Capa_1" data-name="Capa 1">
                            <g id="Video_Recorder" data-name="Video Recorder">
                            <rect x="2" y="2" width="38" height="38" rx="11.55"/>
                            <polyline points="40 10.77 57.54 4.92 57.54 37.08 40 31.23"/>
                            </g>
                        </g>
                    </g>
            </svg>
            <svg class="mute active" viewBox="0 0 59.54 42" xmlns="http://www.w3.org/2000/svg">
                        <line fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"
                              x1="10" x2="45" y1="0" y2="45"/>
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Capa_1" data-name="Capa 1">
                            <g id="Video_Recorder" data-name="Video Recorder">
                              <rect x="2" y="2" width="38" height="38" rx="11.55"/>
                              <polyline points="40 10.77 57.54 4.92 57.54 37.08 40 31.23"/>
                            </g>
                          </g>
                        </g>
            </svg>
          </span>
          `;
          }


          const stylesOverlay1 = 'position: absolute;top: 0;right: 0;bottom: 0;left: 0;z-index: 1;';
          const stylesIcons = 'position: absolute; top: 10px; right: 10px; z-index: 5; color: white;';
          cssFixFreeze.innerHTML = `
          <style>
          .icons span{
            display:inline-block;
            margin-right: 5px;
          }
          .icons span svg{
            display: none;
          }
          .icons span svg.active{
            display:inline-block;
          }
          .icons .camera svg{
            stroke: white;
            stroke-width: 4px;
            width: 20px;
            height: 20px;
            fill: transparent;
          }
          @keyframes fixingFreezeAnim {
              from { outline: 1px solid rgba(1, 1, 1, 0); }
              to { outline: 2px solid rgba(0, 0, 0, .1); }
          }

          .fixNotFreeze{
            animation-name: fixingFreezeAnim;
            animation-duration: 1s;
            animation-iteration-count: infinite;
          }
          .screen{
            object-fit: contain !important;
            background-color: #000 !important;
          }
          </style>
          `;

          /** Add styles inline */
          containerVideoPeer.setAttribute('style', this.stylesContainerVideoBackground);
          overlayPeer.setAttribute('style', stylesOverlay1);
          peerIcons.setAttribute('style', stylesIcons);
          videoPeer.setAttribute('style', this.stylesContainerVideoBackground);

          /** Add class names */
          overlayPeer.classList.add('overlay');
          videoPeer.classList.add('video');
          peerIcons.classList.add('icons');
          containerVideoPeer.classList.add('container-peer');
          containerVideoPeer.classList.add(peerId);

          /** Structure */
          containerVideoPeer.append(overlayPeer);
          containerVideoPeer.append(peerIcons);
          containerVideoPeer.append(videoPeer);
          containerVideoPeer.append(cssFixFreeze);
        } else {
          containerVideoPeer = containerVideos;
          videoPeer = containerVideos.querySelector('.video');
          overlayPeer = containerVideos.querySelector('.overlay');
          peerIcons = containerVideos.querySelector('.icons');
        }

        return {
            // eslint-disable-next-line object-shorthand
            containerVideoPeer: containerVideoPeer,
            // eslint-disable-next-line object-shorthand
            videoPeer: videoPeer,
            // eslint-disable-next-line object-shorthand
            overlayPeer: overlayPeer,
            // eslint-disable-next-line object-shorthand
            cssFixFreeze: cssFixFreeze,
            // eslint-disable-next-line object-shorthand
            peerIcons: peerIcons,
        };
    }
    createTrackLocal(cb: any) {
        try {
            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            navigator.mediaDevices.getUserMedia({ video: true }).then(function(mediaStream) {
                return cb(true, 'success', mediaStream);
            });
        } catch (error) {
            this.onError(error);
            alert(error);
            return cb(false, error);
        }
    }
    handlingMediaError(error: any): string{
      let message = error.message;
      if (error.name === 'NotReadableError' || error.name === 'TrackStartError') {
        message = 'camera or mic are already in use';
      }
      if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
        message = 'permissions denied';
      }
      return message;
    }

    async stopVideoTracks(cb, removeScreen = true) {
        try {
          if(roomObj != null){
              const localTracks = await roomObj.localParticipant.getTracks();
              /** Remove publications from localtracks */
              try {
                  if (localTracks) {
                      for (const track of localTracks) {
                          if (track.track) {
                              const publication = await roomObj.localParticipant.getPublicationForTrack(track.track);
                              if(!removeScreen && track.source === 'screen_share'){ continue; }
                              if (publication && track.kind === 'video') {
                                  await roomObj.localParticipant.unpublishTrack(track.track);
                                  this.cameraIndex = null;
                              }
                          }
                      }
                  }
              } catch (error) {
                  this.onError(error);
                  return cb(false, error);
              }
          }


            if (tracksPreview != null) {
                console.log('tracksPreview', tracksPreview);
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let i = 0; i < tracksPreview.length; i++) {
                    const result = await tracksPreview[i];
                    if (result.pub) {
                        const publication = await roomObj.localParticipant.getPublicationForTrack(result);
                        if (publication) {
                            await roomObj.localParticipant.unpublishTrack(result);
                        }
                    }
                    if (result.kind === 'video') {
                        result.stop();
                    }
                }
                tracksPreview = [];
            }
            if(this.streamVideo != null){
                this.streamVideo.getTracks().forEach(track => { track.stop(); });
                this.streamVideo = null;
            }
            if (tracksPreviewScreen != null && removeScreen) {
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let a = 0; a < tracksPreviewScreen.length; a++) {
                    const result = await tracksPreviewScreen[a];
                    if (result.pub) {
                        const publication = await roomObj.localParticipant.getPublicationForTrack(result);
                        if (publication) {
                            await roomObj.localParticipant.unpublishTrack(result);
                        }
                    }
                    result.stop();
                }
                tracksPreviewScreen = [];
            }
            return cb(true, '');
        } catch (error) {
            this.onError(error);
            return cb(false, error);
        }
    }

    async stopMedias(){
      if(roomObj == null){ return; }
      try {
        const localParticipant: LocalParticipant = roomObj.localParticipant;
        const localtracks = localParticipant.getTracks();
        for (const track of localtracks) {
          try {
            track.track.stop();
          } catch (error) {
            console.log('track stop error->', error);
          }
        }
      } catch (error) {
        console.error('stop medias error->', error);
      }
    }

    async stopMediaTracks(cb) {
        if(roomObj != null){
            const localTracks = await roomObj.localParticipant.getTracks();
            /** Remove publications from localtracks */
            try {
                if (localTracks) {
                    for (const track of localTracks) {
                        if (track.track) {
                            const publication = await roomObj.localParticipant.getPublicationForTrack(track.track);
                            if (publication) {
                                await roomObj.localParticipant.unpublishTrack(track.track);
                                if (track.kind === 'video') {
                                    this.cameraIndex = null;
                                }
                            }
                        }
                    }
                }
            } catch (error) {
                this.onError(error);
                return cb(false, error);
            }
        }


        /** Remove and stop localtracks manager */
        try {
            if(this.streamVideo != null){
                this.streamVideo.getTracks().forEach(track => { track.stop(); });
                this.streamVideo = null;
            }
            if (tracksPreview != null) {
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let i = 0; i < tracksPreview.length; i++) {
                    const result = await tracksPreview[i];
                    if (result.pub) {
                        const publication = await roomObj.localParticipant.getPublicationForTrack(result);
                        if (publication) {
                            await roomObj.localParticipant.unpublishTrack(result);
                        }
                    }
                    result.stop();
                }
                tracksPreview = [];
                return cb(true, '');
            }
            return cb(true, '');
        } catch (error) {
            this.onError(error);
            return cb(false, error);
        }
    }

    async preparedStream(audio: boolean, video: any, cb: any) {
        try {
            this.stopMediaTracks(async (state, message) => {
                if (state) {
                    this.stopMedias();
                    let facingMode;
                    if(video === null){
                        facingMode = {
                            width: { ideal: 1280 },
                            height: { ideal: 720 }
                        };
                    } else {
                        facingMode = {
                            facingMode: 'user',
                            width: { ideal: 1280 },
                            height: { ideal: 720 }
                        };
                    }
                    try{

                        const tempPreview = await createLocalTracks({
                            audio: false,
                            //video: true,
                            video: facingMode
                        });



                        const constraints = {
                            audio: false,
                            video: facingMode
                        };

                        tracksPreview.push(tempPreview[0]);

                        cb(true, 'success', tracksPreview[0]);
                        return;

                        navigator.mediaDevices.getUserMedia(constraints).then(async (stream)=>{
                            if(this.streamVideo != null){
                                this.streamVideo.getTracks().forEach(track => { track.stop(); });
                                this.streamVideo = null;
                            }
                            //stream.getTracks().forEach(track => { track.stop(); });
                            /** Save stream media */
                            this.streamVideo = stream;

                            /** Create video for preview */
                            const videoElement = document.createElement('video') as HTMLVideoElement;
                            videoElement.srcObject = stream;

                            cb(true, 'success', videoElement);
                        }).catch(async (e)=>{
                            cb(false, e);
                        });

                    } catch (error) {
                        this.onError(error);
                        cb(false, this.handlingMediaError(error));
                    }
                    return;
                } else {
                    this.onError(message);
                    cb(false, message);
                }
            });
        } catch (error) {
            this.onError(error);
            cb(false, error);
            return;
        }
    }

    async stopMediasPreview(){
      const localTrackVideo: LocalTrack = tracksPreview.find(t => t.kind === 'video');
      if(localTrackVideo){
        localTrackVideo.stop();
        tracksPreview = tracksPreview.filter(t => t.kind !== 'video');
      }
    }

    saveIndexResolution(mediaStream: any){
        const {width, height} = mediaStream.getTracks()[0].getSettings();
        this.iwidth = width;
        this.iheight = height;
        console.log(`${width}x${height}`); // 640x480
    }




    /** Camera in camera change */
    async nextFaceCamera(cb: any){
        const localTracks = roomObj.localParticipant.getTracks();

        if(this.cameraIndex == null){
            this.cameraIndex = 1;
        } else {
            this.cameraIndex++;
        }



        if(this.devices[this.cameraIndex] == null){
            this.cameraIndex = 0;
        }

        const deviceID = this.devices[this.cameraIndex].deviceId;

        let temp = { deviceId: null };
        if(this.streamVideo != null){
            temp = this.streamVideo.getTracks()[0].getConstraints();
        }

        let constraints: MediaStreamConstraints;
        // eslint-disable-next-line prefer-const
        constraints = {
            //audio: false,
            video: {
                deviceId: deviceID,
                aspectRatio: {
                    ideal: 1.77
                },
                width: { ideal: 1280 },
                height: { ideal: 720 }
            }
        };



        navigator.mediaDevices.getUserMedia(constraints).then(async (stream)=>{
            //stream.getTracks().forEach(track => { track.stop(); });
            if(localTracks.length > 0){
                for (const track of localTracks) {
                    if (track.kind === 'video') {
                        stream.getTracks().forEach(trackS => { trackS.stop(); });
                        await track.track.restartTrack({
                            deviceId: deviceID,
                            aspectRatio: {
                                ideal: 1.77
                            },
                            width: { ideal: 1280 },
                            height: { ideal: 720 }
                        }).then(async (newTrack)=>{
                            stream.getTracks().forEach(trackS => { trackS.stop(); });
                            console.log('succes new track');
                            cb(true, 'success');
                        }).catch(async (e)=>{
                            stream.getTracks().forEach(trackS => { trackS.stop(); });
                            console.log('BAD', e);
                            await this.nextFaceCamera(cb);
                        });
                    }
                }
            } else {

                for (const track of tracksPreview) {
                    if (track.kind === 'video') {
                        stream.getTracks().forEach(trackS => { trackS.stop(); });
                        await track.restartTrack({
                            deviceId: deviceID,
                            aspectRatio: {
                                ideal: 1.77
                            },
                            width: { ideal: 1280 },
                            height: { ideal: 720 }
                        }).then(async (newTrack)=>{
                            stream.getTracks().forEach(trackS => { trackS.stop(); });
                            cb(true, 'success');
                        }).catch(async (e)=>{
                            console.error('BAD-CHANGE', e);
                            cb(false, e.message);
                        });
                    }
                }

                return;
            }
        }).catch(async (e)=>{
            await this.nextFaceCamera(cb);
        });
    }


    async getToken(roomId: any, isEvent: boolean){
        let response = null;


            try {
            response = await this.liveKitService.getLivekitToken(roomId, isEvent).toPromise();
            if (response.done) {
                this.liveKitToken = response.data;
                return {
                    state: true,
                    token: this.liveKitToken
                };
                // console.log('LIVE TOKEN: ', this.livekitToken);
                // const link = document.createElement('script');
                // link.src = 'https://www.sissoa.com/embed/embed.js?key=5185be6b3e8f603b84c30337dab24a4d7fddd6673f67a09e91133e6b4360ebf7&room=DEFAULT';
                // // link.src = `https://live.b.estrim.app/embed/embed.js?key=${this.livekitToken}&room=DEFAULT`;
                // document.getElementsByTagName('head')[0].appendChild(link);
            }
            } catch (e) {
                console.log('Token error: ', e);
                return {
                    state: false,
                    token: null
                };
            }
    }

    async getTokenPreview(roomId: any, isEvent: boolean){
        let response = null;
        try {
            response = await this.liveKitService.getLivekitTokenPreview(roomId, isEvent).toPromise();
            if (response.done) {
                this.liveKitToken = response.data;
                return {
                    state: true,
                    token: this.liveKitToken
                };
            }
        } catch (e) {
            console.log('Token error: ', e);
            return {
                state: false,
                token: null
            };
        }
    }

    getRoomState(){
      const room: Room = roomObj;
      if(!room || !room.state){
        return ConnectionState.Disconnected;
      }
      return room.state;
    }



    async faceMode(mirror: boolean, mode: any, cb: any) {
        if(this.devices === null){
            this.devices = await Room.getLocalDevices('videoinput');
            console.log('thisdevices', this.devices);
        }

        const cb2 = async () => {
            console.log('nextFaceCamera');
            cb(true, 'success');
        };

        await this.nextFaceCamera(cb2);

        return;


    }



    /** Camera front and back change */
    async faceCamera(cb: any, mode){
        const localTracks = roomObj.localParticipant.getTracks();

        let constraints: any;

        //deviceId: deviceID,
        const deviceID = this.devices[this.devices.length - 1].deviceId;
        /*
        if(){

        }
        */

        const options: any = {
            //deviceId: deviceID,
            //facingMode: mode === 'environment' ? 'environment' : 'user',
            aspectRatio: {
                ideal: 1.77
            },
            width: { ideal: 1280 },
            height: { ideal: 720 }
        };

        if(mode === 'environment'){
            options.deviceId = deviceID;
        } else {
            options.facingMode = 'user';
        }



        /** If have video track en room */
        if(localTracks.length > 0){
            const trackVideo = localTracks.find(track => track.kind === 'video');
            if(trackVideo){
                this.cameraIndex = null;
                if(trackVideo.track.facingMode != null){
                    if(trackVideo.track.facingMode === mode){
                        if(mode === 'environment'){ mode='user'; }
                        if(mode === 'user'){ mode='environment'; }
                    }
                }

                await trackVideo.track.restartTrack(options).then(async (newTrack)=>{
                    this.facingMode = mode;
                    cb(true, 'success');
                }).catch(async (e)=>{
                    console.error('BAD-CHANGE', e);
                    cb(false, e.message);
                });
            } else {
              cb(false, 'No tienes una cámara en la emisión.');
            }
            return;
        }


        for (const track of tracksPreview) {
            if (track.kind === 'video') {
                await track.restartTrack(options).then(async (newTrack)=>{
                    this.facingMode = mode;
                    cb(true, 'success');
                }).catch(async (e)=>{
                    console.error('BAD-CHANGE', e);
                    cb(false, e.message);
                });
            }
        }

        cb(true, 'success');
        return;


        /** If is in preview mode */
        navigator.mediaDevices.getUserMedia(options).then(async (stream)=>{
            //stream.getTracks().forEach(track => { track.stop(); });
            this.cameraIndex = null;

            /** Update video actually */
            if(this.streamVideo != null){
                this.streamVideo.getTracks().forEach(track => { track.stop(); });
            }

            const videoElement = document.querySelector('#video') as HTMLVideoElement;
            if(videoElement){
                videoElement.srcObject = stream;
            }

            this.streamVideo = stream;
            cb(true, 'success');
        }).catch(async (e)=>{
            let message = 'No se pudo acceder a tu camara ' + (mode === 'environment' ? 'trasera' : 'delantera') + ', verifica que no este en uso.';
            if(e.message !== ''){
                message = e.message;
            }
            cb(false, message);
        });


        return;

        navigator.mediaDevices.getUserMedia(constraints).then(async (stream)=>{
            //stream.getTracks().forEach(track => { track.stop(); });
            this.cameraIndex = 0;

            if(localTracks.length > 0){
                for (const track of localTracks) {
                    if (track.kind === 'video') {
                        stream.getTracks().forEach(trackS => { trackS.stop(); });
                        await track.track.restartTrack({
                            facingMode: mode === 'environment' ? 'environment' : 'user',
                            aspectRatio: {
                                ideal: 1.77
                            },
                            width: { ideal: 1280 },
                            height: { ideal: 720 }
                            /*
                            facingMode: {
                                exact: mode === 'environment' ? 'environment' : 'user'
                            },
                            */
                            //width: { ideal: 1280 },
                            //height: { ideal: 720 }
                        }).then(async (newTrack)=>{
                            stream.getTracks().forEach(trackS => { trackS.stop(); });
                            cb(true, 'success');
                        }).catch(async (e)=>{
                            console.log('BAD', e);
                            await this.nextFaceCamera(cb);
                        });
                    }
                }
            } else {

                /** Update video actually */
                if(this.streamVideo != null){
                    this.streamVideo.getTracks().forEach(track => { track.stop(); });
                }

                const videoElement = document.querySelector('#video') as HTMLVideoElement;
                if(videoElement){
                    videoElement.srcObject = stream;
                }

                this.streamVideo = stream;
                cb(true, 'success');


                /*
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let index = 0; index < tracksPreview.length; index++) {
                    const element = tracksPreview[index];
                    if (element.kind === 'video') {
                        await element.restartTrack({
                            deviceId: deviceID,
                            width: { ideal: 1280 },
                            height: { ideal: 720 }
                        })
                        .then(async (newTrack)=>{
                            console.log('success');
                            cb(true, 'success');
                        }).catch(async (e)=>{
                            console.log('BAD', e);
                            await this.nextFaceCamera(cb);
                        });
                    }
                }*/
            }

        }).catch(async (e)=>{
            let message = 'No se pudo acceder a tu camara ' + (mode === 'environment' ? 'trasera' : 'delantera') + ', verifica que no este en uso.';
            if(e.message !== ''){
                message = e.message;
            }
            cb(false, message);
        });
    }
    async activeFaceCamera(mirror: boolean, mode: any, cb: any) {
        if(this.devices === null){
            this.devices = await Room.getLocalDevices('videoinput');
        }
        const capacitorPlatform = Capacitor.getPlatform();

        const localTracks: TrackPublication[] = roomObj.localParticipant.getTracks();
        const deviceID: string = this.devices[this.devices.length - 1].deviceId;
        let constraints: CreateLocalTracksOptions;
        let videoCaptureOptions: VideoCaptureOptions;


        if(mode === 'environment'){
          if(capacitorPlatform === 'ios'){
            videoCaptureOptions = {
              facingMode: 'environment',
              resolution: {
                width: 1280,
                height: 720,
                aspectRatio: 1.77,
              },
            };
          } else {
            videoCaptureOptions = {
              deviceId: deviceID,
              resolution: {
                width: 1280,
                height: 720,
                aspectRatio: 1.77,
              },
            };
          }

          constraints = {
              audio: false,
              video: videoCaptureOptions
          };
        } else {
          videoCaptureOptions =  {
            facingMode: 'user',
            resolution: {
              width: 1280,
              height: 720,
              aspectRatio: 1.77,
            },
          };
          constraints = {
              audio: false,
              video: videoCaptureOptions
          };
        }


        /**
         * Change camera in streaming
         */
        if(localTracks.length > 0){
          const p: LocalParticipant = roomObj.localParticipant;
          const track = p.getTrack(Track.Source.Camera);
          if(track){
            try {
              await track.videoTrack.restartTrack(videoCaptureOptions);
              mode = !!videoCaptureOptions.facingMode ? 'environment' : 'user';
              this.facingMode = mode;
              cb(true, 'success');
            } catch (error) {
              this.onError(error);
              cb(false, this.handlingMediaError(error));
            }
          } else {
            cb(false, 'No tienes una cámara en la emisión.');
          }
          return;
        }

        /**
         * Change camera in preview
         */
        try {
          const createLocalTrack = await createLocalTracks(constraints);
          const localTrackVideo: LocalTrack = tracksPreview.find(t => t.kind === 'video');
          if(localTrackVideo){
            localTrackVideo.stop();
            tracksPreview = tracksPreview.filter(t => t.kind !== 'video');
          }

          tracksPreview.push(createLocalTrack[0]);
          cb(true, 'success', createLocalTrack[0]);
        } catch (error) {
          this.onError(error);
          cb(false, this.handlingMediaError(error));
        }


    }





    async pausedMirror(cb: any) {
      const localVideo: LocalTrack = tracksPreview.find(t => t.kind === 'video');
      if(localVideo){
        try {
          if(localVideo.isMuted){
            await localVideo.unmute();
            //localVideo.isMuted = false;
            return cb(true, 'success', !localVideo.isMuted);
          } else {
            await localVideo.mute();
            //localVideo.isMuted = true;
            return cb(true, 'success', !localVideo.isMuted);
          }
        } catch (error) {
          this.onError(error);
          return cb(false, error);
        }
      }
      return cb(false, 'No hay vista previa de la camara activada');
    }


    /**
     * Get emitters from room
     *
     * @returns Remote participants list
     */
    getEmitters(): RemoteParticipant[]{
      const peers: Map<string, RemoteParticipant> = this.getParticipants();
      const emitters: RemoteParticipant[] = [];

      for (const [sid, peer] of peers) {
        if (peer.audioTracks.size > 0 || peer.videoTracks.size > 0) {
          emitters.push(peer);
        }
      }
      return emitters;
    }


    localSharingMic(): boolean{
      if(roomObj.localParticipant === null){ return false; }
      const localParticipant: LocalParticipant = roomObj.localParticipant;
      if(localParticipant.audioTracks.size === 0){
        return false;
      }
      for (const [trackSid, localTrackPublication] of localParticipant.audioTracks) {
        if(localTrackPublication.track.isMuted){
          return false;
        }
      }
      return true;
    }


    localSharingVideo(): boolean{
      if(roomObj.localParticipant === null){ return false; }
      const localParticipant: LocalParticipant = roomObj.localParticipant;
      if(localParticipant.videoTracks.size === 0){
        return false;
      }
      for (const [trackSid, localTrackPublication] of localParticipant.videoTracks) {
        if(localTrackPublication.track.isMuted){
          return false;
        }
      }
      return true;
    }
    remoteSharingVideo(): boolean{
      const peers: Map<string, RemoteParticipant> = this.getParticipants();
      if(!peers || peers.size === 0){
        return false;
      }

      const participants = Array.from(peers.values());
      const participantsWithVideo = participants.find(_peer => _peer.videoTracks.size > 0);
      if(!participantsWithVideo){ return false; }

      const remoteVideos = Array.from(participantsWithVideo.videoTracks.values()).find(_track => !_track.track?.isMuted);
      if (!remoteVideos) { return false; }

      return true;
    }


    async inSharingScreen() {
        if (tracksPreviewScreen == null) { return false; }
        if (tracksPreviewScreen.length == null) { return false; }
        if (tracksPreviewScreen.length === 0) { return false; }
        return true;
    }
    async sharingScreenCancel(inEmition: any, cb: any) {
        const inSharingScreen = this.inSharingScreen();
        if (!inSharingScreen) { return; }

        if (inEmition) {
            /**
             * Disabled for request
             */
            /*
            this.createTrack({ camera: true, removeMicrophones: false, removeCameras: true, removeScreens: true }, (state, message) => {
                if (!state) {
                    return cb(false, message);
                }
            });
            */

            //Stop local medias and remove screen
            this.stopLocalMedias(
                {
                  camera: true,
                  microphone: false,
                  screen: true,
                },
                async (state, message) => {
                    return cb(false, message);
                }
            );

            return;
        }
        try {
            if (tracksPreviewScreen != null) {
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let i = 0; i < tracksPreviewScreen.length; i++) {
                    const result = await tracksPreviewScreen[i];
                    if (result.pub) {
                        const publication = await roomObj.localParticipant.getPublicationForTrack(result);
                        if (publication) {
                            await roomObj.localParticipant.unpublishTrack(result);
                        }
                    }
                    result.stop();
                }
                tracksPreviewScreen = [];
            }
            return cb(true, '');
        } catch (error) {
            this.onError(error);
            return cb(false, '');
        }
    }
    async logout(cb) {
        this.stopVideoTracks(async (state, message) => {
            cb(true, 'success',);
        }, true);
    }
    async addScreen(cb, imEmitting = false) {
        if (this.debug) {
            console.log('Debug -> Try add Screen Track', imEmitting, tracksPreview);
        }
        let trackTemp;

        try {

            const optionsScreen = {
              audio: true,
              resolution: {
                  aspectRatio: 1.77,
                  width: 1920,
                  height: 1080
              }
            };

            //await roomObj.localParticipant.setScreenShareEnabled(true, optionsScreen);
            trackTemp = await createLocalScreenTracks({
                audio: true,
                resolution: {
                    aspectRatio: 1.77,
                    width: 1920,
                    height: 1080
                }
            });
            tracksPreviewScreen = trackTemp;
            if (imEmitting) {
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let index = 0; index < tracksPreviewScreen.length; index++) {
                    const element = tracksPreviewScreen[index];
                    await roomObj.localParticipant.publishTrack(element, {
                        name: 'screen',
                        simulcast: true,
                        source: Track.Source.ScreenShare,
                    });
                    element.pub = true;
                }
            }

            this.stopVideoTracks(async (state, message) => {
                cb(true, 'success', trackTemp[0]);
            }, false);
            //cb(true, 'success', trackTemp[0]);
            return;
        } catch (error) {
            cb(false, error.message, null);
            return;
        }
    }

    isSharingVideos() {
        if (tracksPreview == null) { return false; }
        if (tracksPreview.length == null) { return false; }
        if (tracksPreview.length === 0) { return false; }
        return true;
    }

    getStream(attach, track: Track, local: boolean): HTMLMediaElement{
      try {
        const video = track.attach(attach);
        if(local){
          video.muted = true;
        }
        video.controls = false;
        video.autoplay = true;
        return video;
      } catch (error) {
        console.error('getStream->', error);
      }
    }


    async stopLocalMedias(options: any, cb) {
        options = options ?? {};
        options.camera = options.camera ?? false;
        options.microphone = options.microphone ?? false;
        options.screen = options.screen ?? false;
        try {
            if(this.streamVideo != null){
                this.streamVideo.getTracks().forEach(track => { track.stop(); });
                this.streamVideo = null;
            }
            if (tracksPreview != null) {
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let i = 0; i < tracksPreview.length; i++) {
                    const result = await tracksPreview[i];
                    if ((result.kind === 'video' && options.camera) || (result.kind === 'audio' && options.microphone)) {
                        if (result.pub) {
                            const publication = await roomObj.localParticipant.getPublicationForTrack(result);
                            if (publication) {
                                await roomObj.localParticipant.unpublishTrack(result);
                            }
                        }
                        result.stop();
                    }
                }
                tracksPreview = [];
                return cb(true, '');
            }
            return cb(true, '');
        } catch (error) {
            this.onError(error);
            return cb(false, error);
        }
    }

    async addTrackNotUsed(camera: boolean, microphone: boolean, mode: any, cb: any, removeScreen = false) {
        const stopAllMedias = await this.stopLocalMedias(true, true);
        //const stopAllMedias = await this.stopLocalMedias(true, true, removeScreen);
        try {
            if (camera || microphone) {
                if (this.debug) {
                    console.log('Debug -> Try add Camera Track', tracksPreview);
                }
                let optionsVideos;
                if (mode != null) {
                    optionsVideos = { facingMode: mode };
                } else {
                    optionsVideos = true;
                }
                if (tracksPreviewScreen != null && !removeScreen) {
                    if (tracksPreviewScreen.length > 0) {
                        optionsVideos = false;
                    }
                }
                let localTracks = [];
                localTracks = await createLocalTracks({
                    audio: microphone,
                    video: optionsVideos,
                });
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let index = 0; index < localTracks.length; index++) {
                    const element = localTracks[index];
                    if (element.kind === 'audio' && element.pub == null) {
                        const pub = await roomObj.localParticipant.publishTrack(element, {
                            name: 'microphone',
                            simulcast: true,
                            source: Track.Source.Microphone,
                        });
                        element.pub = true;
                    }
                    if (element.kind === 'video' && element.pub == null) {
                        const pub = await roomObj.localParticipant.publishTrack(element, {
                            name: 'camera',
                            simulcast: true,
                            source: Track.Source.Camera,
                        });
                        element.pub = true;
                    }
                }

                this.mediaControls.microphone.lastState =
                    roomObj.localParticipant.isMicrophoneEnabled;
                this.mediaControls.camera.lastState =
                    roomObj.localParticipant.isCameraEnabled;
                tracksPreview = localTracks;
                cb(true, 'success');
                return;
            }
        } catch (error) {
            this.onError(error);
            cb(false, error);
            return;
        }
    }

    async createTrack(options: any, cb) {
        const emitters = this.getEmitters();

        /**
         * Limit room participants
         * */
        if(options.onlyAudio != null){
          if(options.onlyAudio){
            if(emitters.length >= 9){
              cb(
                false,
                'El número máximo de usuarios emitiendo es 9'
              );
              return;
            }
          } else {
            if(emitters.length >= 4){
              cb(
                false,
                'El número máximo de usuarios emitiendo es 4'
              );
              return;
            }
          }
        }


        options = options ?? {};
        options.camera = options.camera ?? false;
        options.audio = options.audio ?? false;
        options.screen = options.screen ?? false;
        options.mode = options.mode ?? false;
        options.removeScreens = options.removeScreens ?? false;
        options.removeCameras = options.removeCameras ?? true;
        options.removeMicrophones = options.removeMicrophones ?? true;

        if (!options.camera && !options.audio && !options.screen) {
          cb(
            false,
            'Debes compartir por lo menos la cámara, micrófono o pantalla'
          );
          return;
        }

        const inSharingScreen = this.inSharingScreen();
        if(inSharingScreen){ options.screen = inSharingScreen; }

        this.stopLocalMedias(
          {
            //camera: options.removeCameras,
            camera: options.removeCameras,
            microphone: options.removeMicrophones,
            screen: options.removeScreens,
          },
          async (state, message) => {
            if (state) {
              try {
                this.stopMedias();

                if (this.debug) {
                  console.log('Debug -> Try add Camera Track', tracksPreview);
                }


                let optionsVideos = options.camera;
                if (options.mode !== null && options.mode !== false && options.camera) {
                    optionsVideos = { facingMode: options.mode };
                    if(this.cameraIndex != null && !this.failedIndex){
                        const devices = await Room.getLocalDevices('videoinput');
                        if(devices[this.cameraIndex] != null){
                            optionsVideos = {
                                deviceId: devices[this.cameraIndex].deviceId,
                                aspectRatio: {
                                    ideal: 1.77
                                },
                                width: { ideal: 1280 },
                                height: { ideal: 720 }
                            };
                        }
                    }
                } else {
                    optionsVideos = options.camera;
                    if(this.cameraIndex != null && !this.failedIndex){
                        const devices = await Room.getLocalDevices('videoinput');
                        if(devices[this.cameraIndex] != null){
                            optionsVideos = {
                                deviceId: devices[this.cameraIndex].deviceId,
                                aspectRatio: {
                                    ideal: 1.77
                                },
                                width: { ideal: 1280 },
                                height: { ideal: 720 }
                            };
                        }
                    }
                }

                if (tracksPreviewScreen != null && options.screen && !options.removeScreens) {
                    if (tracksPreviewScreen.length > 0) {
                        optionsVideos = null;
                    }
                }

                //let trackTemp = [];

                if (optionsVideos !== null) {
                    const localOptions = {
                        audio: options.audio,
                        video: optionsVideos,
                    };
                    //trackTemp = await createLocalTracks(localOptions);
                    if(optionsVideos){ await roomObj.localParticipant.setCameraEnabled(true, optionsVideos); }
                    if(options.audio){ await roomObj.localParticipant.setMicrophoneEnabled(true); }
                }

                let pub1;
                let pub2;
                let pub3;


                  if (!optionsVideos && tracksPreviewScreen != null && options.screen) {
                      // eslint-disable-next-line @typescript-eslint/prefer-for-of
                      for (let index = 0; index < tracksPreviewScreen.length; index++) {
                          const element = tracksPreviewScreen[index];
                          pub3 = await roomObj.localParticipant.publishTrack(element, {
                              name: 'screen',
                              simulcast: true,
                              source: Track.Source.ScreenShare,
                          });
                          element.pub = true;
                      }
                  }


                /*
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let index = 0; index < trackTemp.length; index++) {
                    const element = trackTemp[index];
                    if (element.kind === 'audio' && element.pub == null) {
                        pub2 = await roomObj.localParticipant.publishTrack(element, {
                            name: 'microphone',
                            simulcast: true,
                            source: Track.Source.Microphone,
                        });
                        element.pub = true;
                    }
                    if (element.kind === 'video' && element.pub == null) {
                        pub1 = await roomObj.localParticipant.publishTrack(element, {
                            name: 'camera',
                            simulcast: true,
                            source: Track.Source.Camera,
                        });
                        element.pub = true;
                    }
                }

                if(!optionsVideos && tracksPreviewScreen != null && options.screen){
                              // eslint-disable-next-line @typescript-eslint/prefer-for-of
                    for (let index = 0; index < tracksPreviewScreen.length; index++) {
                        const element = tracksPreviewScreen[index];
                        pub3 = await roomObj.localParticipant.publishTrack(element, {
                                name: 'screen',
                                      simulcast: true,
                                      source: Track.Source.ScreenShare,
                                  });
                                  element.pub = true;
                              }
                          }
                */

                this.mediaControls.microphone.lastState =
                    roomObj.localParticipant.isMicrophoneEnabled;
                this.mediaControls.camera.lastState =
                    roomObj.localParticipant.isCameraEnabled;
                //tracksPreview = trackTemp;
                this.failedIndex = false;
                cb(true, 'success');
                return;
              } catch (error) {
                this.onError(error);
                cb(false, error);
              }
            } else {
              this.onError(message);
              cb(false, message);
            }
          }
        );
    }

    async createTrackOLD(options: any, cb) {
        options = options ?? {};
        options.camera = options.camera ?? false;
        options.audio = options.audio ?? false;
        options.screen = options.screen ?? false;
        options.mode = options.mode ?? false;
        options.removeScreens = options.removeScreens ?? false;
        options.removeCameras = options.removeCameras ?? true;
        options.removeMicrophones = options.removeMicrophones ?? true;

        if (!options.camera && !options.audio && !options.screen) {
          cb(
            false,
            'Debes compartir por lo menos la cámara, micrófono o pantalla'
          );
          return;
        }

        const inSharingScreen = this.inSharingScreen();
        if(inSharingScreen){ options.screen = inSharingScreen; }

        this.stopLocalMedias(
            { camera: options.removeCameras, microphone: options.removeMicrophones, screen: options.removeScreens },
            async (state, message) => {
                if (state) {
                    try {
                        if (this.debug) {
                            console.log('Debug -> Try add Camera Track', tracksPreview);
                        }

                        let optionsVideos = options.camera;
                        if (options.mode !== null && options.mode !== false && options.camera) {
                            optionsVideos = { facingMode: options.mode };
                            if(this.cameraIndex != null && !this.failedIndex){
                                const devices = await Room.getLocalDevices('videoinput');
                                if(devices[this.cameraIndex] != null){
                                    optionsVideos = {
                                        deviceId: devices[this.cameraIndex].deviceId,
                                        aspectRatio: {
                                            ideal: 1.77
                                        },
                                        width: { ideal: 1280 },
                                        height: { ideal: 720 }
                                    };
                                }
                            }
                        } else {
                            optionsVideos = options.camera;
                            if(this.cameraIndex != null && !this.failedIndex){
                                const devices = await Room.getLocalDevices('videoinput');
                                if(devices[this.cameraIndex] != null){
                                    optionsVideos = {
                                        deviceId: devices[this.cameraIndex].deviceId,
                                        aspectRatio: {
                                            ideal: 1.77
                                        },
                                        width: { ideal: 1280 },
                                        height: { ideal: 720 }
                                    };
                                }
                            }
                        }

                        if (tracksPreviewScreen != null && options.screen && !options.removeScreens) {
                            if (tracksPreviewScreen.length > 0) {
                                optionsVideos = null;
                            }
                        }

                        let trackTemp = [];

                        if (optionsVideos !== null) {
                            const localOptions = {
                                audio: options.audio,
                                video: optionsVideos,
                            };
                            trackTemp = await createLocalTracks(localOptions);
                            console.log('trackTemp', trackTemp);
                        }

                        let pub1;
                        let pub2;
                        let pub3;


                        // eslint-disable-next-line @typescript-eslint/prefer-for-of
                        for (let index = 0; index < trackTemp.length; index++) {
                            const element = trackTemp[index];
                            if (element.kind === 'audio' && element.pub == null) {
                                pub2 = await roomObj.localParticipant.publishTrack(element, {
                                    name: 'microphone',
                                    simulcast: true,
                                    source: Track.Source.Microphone,
                                });
                                element.pub = true;
                            }
                            if (element.kind === 'video' && element.pub == null) {
                                pub1 = await roomObj.localParticipant.publishTrack(element, {
                                    name: 'camera',
                                    simulcast: true,
                                    source: Track.Source.Camera,
                                });
                                element.pub = true;
                            }
                        }

                        if(!optionsVideos && tracksPreviewScreen != null && options.screen){
                                      // eslint-disable-next-line @typescript-eslint/prefer-for-of
                            for (let index = 0; index < tracksPreviewScreen.length; index++) {
                                const element = tracksPreviewScreen[index];
                                pub3 = await roomObj.localParticipant.publishTrack(element, {
                                        name: 'screen',
                                              simulcast: true,
                                              source: Track.Source.ScreenShare,
                                          });
                                          element.pub = true;
                                      }
                                  }

                        this.mediaControls.microphone.lastState =
                            roomObj.localParticipant.isMicrophoneEnabled;
                        this.mediaControls.camera.lastState =
                            roomObj.localParticipant.isCameraEnabled;
                        tracksPreview = trackTemp;
                        this.failedIndex = false;
                        cb(true, 'success');
                        return;
                    } catch (error) {
                        this.onError(error);
                        cb(false, error);
                    }
                } else {
                    this.onError(message);
                    cb(false, message);
                }
            }
        );
        //cb(true, 'message');
    }

    async addTrack(camera: boolean, microphone: boolean, mode: any, cb: any, removeScreen = false) {
        try {
            if (tracksPreview != null && camera) {
                this.stopMediaTracks(async (state, message) => {
                    if (state) {
                        if (this.debug) { console.log('Debug -> Try add Camera Track', tracksPreview); }

                        let optionsVideos;
                        if (mode != null) {
                            optionsVideos = { facingMode: mode };
                        } else {
                            optionsVideos = true;
                        }

                        if (tracksPreviewScreen != null && !removeScreen) {
                            if (tracksPreviewScreen.length > 0) {
                                optionsVideos = false;
                            }
                        }

                        let trackTemp = [];

                        if (optionsVideos !== false) {
                            trackTemp = await createLocalTracks({
                                audio: microphone,
                                video: optionsVideos,
                            });
                        }

                        let pub1;
                        let pub2;
                        let pub3;

                        // eslint-disable-next-line @typescript-eslint/prefer-for-of
                        for (let index = 0; index < trackTemp.length; index++) {
                            const element = trackTemp[index];
                            if (element.kind === 'audio' && element.pub == null) {
                                pub2 = await roomObj.localParticipant.publishTrack(element, {
                                    name: 'microphone',
                                    simulcast: true,
                                    source: Track.Source.Microphone,
                                });
                                element.pub = true;
                            }
                            if (element.kind === 'video' && element.pub == null) {
                                pub1 = await roomObj.localParticipant.publishTrack(element, {
                                    name: 'camera',
                                    simulcast: true,
                                    source: Track.Source.Camera,
                                });
                                element.pub = true;
                            }
                        }


                        if (!optionsVideos && tracksPreviewScreen != null && removeScreen === false) {
                            // eslint-disable-next-line @typescript-eslint/prefer-for-of
                            for (let index = 0; index < tracksPreviewScreen.length; index++) {
                                const element = tracksPreviewScreen[index];
                                pub3 = await roomObj.localParticipant.publishTrack(element, {
                                    name: 'screen',
                                    simulcast: true,
                                    source: Track.Source.ScreenShare,
                                });
                                element.pub = true;
                            }
                        }

                        this.mediaControls.microphone.lastState = roomObj.localParticipant.isMicrophoneEnabled;
                        this.mediaControls.camera.lastState = roomObj.localParticipant.isCameraEnabled;
                        tracksPreview = trackTemp;

                        /*
                        const pub = await roomObj.localParticipant.publishTrack(trackTemp, {
                            name: 'camera',
                            simulcast: true,
                            source: Track.Source.Camera,
                        });
                        */

                        cb(true, 'success');
                        return;
                    } else {
                        this.onError(message);
                        cb(false, message);
                        return;
                    }
                });
                //tracksPreview[0].stop();
                //tracksPreview[0].detach();
                return;
            }
            if (tracksPreview == null && camera) {
                if (this.debug) { console.log('Debug -> Try add Camera'); }
                const trackTemp = await createLocalTracks({
                    audio: false,
                    video: true,
                });
                await roomObj.localParticipant.publishTrack(trackTemp[0], {
                    name: 'camera',
                    simulcast: true,
                    source: Track.Source.Camera,
                });
            }
            if (microphone) {
                this.stopMediaTracks(async (state, message) => {
                    if (state) {
                        if (this.debug) { console.log('Debug -> Try add Microphone'); }
                        const trackTempMic = await createLocalTracks({
                            audio: true,
                            video: false,
                        });
                        await roomObj.localParticipant.publishTrack(trackTempMic[0], {
                            name: 'microphone',
                            simulcast: true,
                            source: Track.Source.Microphone,
                        });
                        this.mediaControls.microphone.lastState = roomObj.localParticipant.isMicrophoneEnabled;
                        this.mediaControls.camera.lastState = roomObj.localParticipant.isCameraEnabled;
                    } else {
                        this.onError(message);
                        cb(false, message);
                        return;
                    }
                });
            }
            cb(true, 'success');
        } catch (error) {
            this.onError(error);
            cb(false, error);
        }
    }

    async enabledMedia(camera: boolean, microphone: boolean, cb: any) {
        if (tracksPreview != null) {
            if (tracksPreview[0] != null) {
                tracksPreview[0].stop();
            }
        }
        const p = roomObj.localParticipant;
        try {
            if (camera && microphone) {
                await p.setCameraEnabled(true);
                await p.setMicrophoneEnabled(true);
                return cb(true);
            }

            if (camera && !microphone) {
                await p.setCameraEnabled(true);
                return cb(true);
            }

            if (!camera && microphone) {
                await p.setMicrophoneEnabled(true);
                return cb(true);
            }

            return cb(true, 'success');
        } catch (error) {
            this.onError(error);
            return cb(false, error);
        }
    }




    onError(e) {
        console.error('Error ->', e);
    }

    async disconnect(cb) {
        console.log('tracksPreview', tracksPreview);
        this.cameraIndex = null;

        this.stopMediaTracks((state, message) => { });
        this.stopMedias();

        try {
            if(roomObj == null){ cb(true, 'success'); return; }
            await roomObj.disconnect(true);


            if (tracksPreview != null) {
                tracksPreview.forEach(element => {
                    console.log('trackStop->', element);
                    //element.detach();
                    element.stop();
                });
                //if(tracksPreview[0] != null){ tracksPreview[0].stop(); }
                //if(tracksPreview[1] != null){ tracksPreview[1].stop(); }
            }

            roomObj = null;
            cb(true, 'success');
        } catch (error) {
            this.onError(error);
            cb(false, error);
        }
    }

    async connect(
        tokens: string,
        onConnected: any,
        onRemoteMedia: any,
        onRemoveMedia: any,
        onRemoveMediaLocal: any,
        onSpeaker: any,
        onDisconnect: any,
        onLocalTrack: any,
        onTrackMuted: any,
        onTrackUnmuted: any,
        participantConnected: any,
        participantDisconnected: any,
        adaptiveStream: boolean = false,
    ) {

        if (!supportsAdaptiveStream()) { this.onError('No soporta adaptiveStream'); }
        if (!supportsDynacast()) { this.onError('No soporta dynacast'); }

        token = tokens;

        // creates a new room with options
        roomObj = new Room({
            // automatically manage subscribed video quality
            //adaptiveStream: supportsAdaptiveStream() ? adaptiveStream : false,

            adaptiveStream: false,
            // optimize publishing bandwidth and CPU for published tracks
            dynacast: true,

            publishDefaults: {
                videoEncoding: {
                    maxBitrate: 2_500_000,
                    maxFramerate: 30,
                },
                screenShareEncoding: {
                    maxBitrate: 2_500_000,
                    maxFramerate: 30,
                },
                audioBitrate: 100_000,
                dtx: true,
            },
            // default capture settings
            videoCaptureDefaults: {
                //resolution: VideoPresets.h720.resolution,
                resolution: VideoPresets.h1080.resolution,
            },
            /* disabled audio cancellation and suppression */
            audioCaptureDefaults: {
                //echoCancellation: false,
                //noiseSuppression: false
            }
        });

        // set up event listeners
        roomObj
            .on(RoomEvent.Connected, onConnected)
            .on(RoomEvent.Disconnected, onDisconnect)
            .on(RoomEvent.TrackSubscribed, onRemoteMedia)
            .on(RoomEvent.TrackSubscriptionFailed, this.onError)
            .on(RoomEvent.TrackUnsubscribed, onRemoveMedia)
            .on(RoomEvent.ActiveSpeakersChanged, onSpeaker)
            .on(RoomEvent.LocalTrackPublished, onLocalTrack)
            /** Disabled for now. */
            .on(RoomEvent.TrackPublished, (remoteTrackPublication: RemoteTrackPublication, participant: RemoteParticipant)=>{
            })
            .on(RoomEvent.LocalTrackUnpublished, onRemoveMediaLocal)
            .on(RoomEvent.TrackMuted, onTrackMuted)
            .on(RoomEvent.TrackUnmuted, onTrackUnmuted)
            .on(RoomEvent.ParticipantConnected, () => {
              this.onConnectParticipant.next(this.getNumParticipants());
              participantConnected()
            })
            .on(RoomEvent.ParticipantDisconnected, participantDisconnected);




        /**
         * Connected to Livekit
         */
        try {
            this.cameraIndex = null;
            await roomObj.connect(server, token, {
                logLevel: LogLevel.debug,
                autoSubscribe: true
            });
            return true;
        } catch (error) {
            console.log(error.message);
        }

        return false;
        /*
        try {
            await roomObj.connect(server, token, {
                maxRetries: 3,
                peerConnectionTimeout: 1
            });
            return true;
        } catch (error) {
            return false;
            if(this.debug){ console.error('Error => ', error.message); }
            if(reconnectIntents < 5){
                reconnectIntents++;
                if(this.debug){ console.log('Reconnectando =>'); }
                setTimeout(() => {
                    this.connect(
                        token,
                        onConnected,
                        onRemoteMedia,
                        onRemoveMedia,
                        onRemoveMediaLocal,
                        onSpeaker,
                        onDisconnect,
                        onLocalTrack,
                        onTrackMuted,
                        onTrackUnmuted,
                        participantConnected,
                        participantDisconnected,
                    );
                }, 5000);
                return false;
            }
            if(this.debug){ console.error('No se ha podido establecer la conexión'); }
            return false;
        }*/
    }



    /* GLOBAL FUNCTIONS */
    audioLocalFix() {
        try {
            const audioElement = document.querySelector('audio.local') as HTMLAudioElement | null;
            const videoElement = document.querySelector('video.local') as HTMLVideoElement | null;
            if (audioElement != null) { audioElement.muted = true; }
            if (videoElement != null) { videoElement.muted = true; }
        } catch (error) {
            this.onError(error);
        }
    }
    addIcon(track: any, participant: any) {

        return;
        const searchElment = document.querySelector('.speakers .peer' + participant.sid);
        if (searchElment != null) { return; }

        const metadata = JSON.parse(participant.metadata);

        let imgProfile = 'assets/no-photo.jpg';
        if (metadata.imgProfile != null) {
            imgProfile = metadata.imgProfile;
        }
        console.log('metadata', metadata);
        return;

        const container = document.createElement('div');
        container.classList.add('speaker');
        container.innerHTML = `
        <div >
              <div class="audioSpeaker remote`+ participant.sid + `">
                <div class="avatar">
                  <span hidden class="wave">
                  </span>
                  <img handleError class="profile-pic"
                       src="`+ imgProfile + `">
                </div>
                <div class="data">
                  <img handleError class="crown"
                       src="assets/badges/special/creator-white-1x.png"
                       *ngIf="userIsCreator(speaker.user.id)">
                  <img handleError class="crown"
                       src="assets/badges/special/collaborator-white-1x.png"
                       *ngIf="!userIsCreator(speaker.user.id) && userIsCollaborator(speaker.user.id)">
                  <img handleError class="crown"
                       src="assets/badges/special/subscriber-white-1x.png"
                       *ngIf="!userIsCreator(speaker.user.id) && !userIsCollaborator(speaker.user.id) && userIsSubscribed(speaker.user.id)">
                  <span class="username">{{speaker.user.username}}</span>
                </div>
              </div>
        </div>
        `;
    }
    getLocalPeer() {
        const localpeer = roomObj?.localParticipant;
        return localpeer;
    }
    getParticipants(): Map<string, RemoteParticipant> | undefined {
        return roomObj?.participants;
    }
    getNumParticipants() {
        if(roomObj?.participants){
          const peersArray = Array.from(roomObj.participants);
          const peers = peersArray.filter(([_clave, participant]) => participant.metadata && !JSON.parse(participant.metadata).isPreview);
          return peers.length + 1;
        }
        return roomObj?.participants.size + 1;
    }
    async setAudioSpeaker(cb, speaker) {
        try {
            if (speaker) { window.MediaControls.setModeAudio('speaker'); }
            if (!speaker) { window.MediaControls.setModeAudio('normal'); }
            cb(true, 'success');
            return;
        } catch (error) {
            this.onError(error);
            cb(false, error);
            return;
        }
    }
    async immersiveMode(maximized, cb) {
        try {
            const isImmersiveModeSupported = await androidFullScreen.isImmersiveModeSupported();
            if (!isImmersiveModeSupported) {
                cb(false, 'not support immersivemode');
                return;
            }
            if (!maximized) {
                const showSystemUI = await androidFullScreen.showSystemUI();
                cb(true, showSystemUI);
                return;
            }


            //const immersiveMode = await androidFullScreen.setSystemUiVisibility(AndroidSystemUiFlags.Custom);
            //const immersiveMode = await androidFullScreen.setSystemUiVisibility(AndroidSystemUiFlags.Custom);
            const immersiveMode = await androidFullScreen.immersiveMode();
            cb(true, immersiveMode);
            return;
        } catch (error) {
            this.onError(error);
            cb(false, error);
            return;
        }
    }

    maximized(element: any, cb: any) {
        const fullscreenElement =
            document.fullscreenElement ||
            document.mozFullScreenElement ||
            document.webkitFullscreenElement;
        //video.webkitEnterFullScreen();
        if (fullscreenElement != null) {
            if (document.fullscreenElement) { document.webkitExitFullscreen(); }
            if (document.mozFullScreenElement) { document.mozCancelFullscreen(); }
            if (document.exitFullscreen) { document.exitFullscreen(); }
            cb(false);
            return;
        }

        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        }
        cb(true);
    }
    getLocalTrack() {
        let video;
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let index = 0; index < tracksPreview.length; index++) {
            const element = tracksPreview[index];
            if (element.kind === 'video') {
                video = element;
            }
        }
        return video;
        if (tracksPreview != null) {
            if (tracksPreview[0] != null) {
                return tracksPreview[0];
            }
        }
        return null;
    }


    getStreamKey(roomId: number, username: string): Promise<string> {
      return new Promise((resolve, reject) => {
        this.authService.getStreamKey(roomId, username).subscribe((data: any) => {
          console.log('data', data);
          if(!data.data){
            console.log('error', data);
            return reject();
          }
          resolve(data);
        }, (error: any) => {
          console.log('error', error);
          reject();
        });
      });
    }


    getMetaData(username: string, roomId: string): Promise<UserStreamMetadata> {
      return new Promise((resolve, reject) => {

        /**
         * Get username
         */
        let usernameReady;
        const identitySplit = username.split('_');
        if (identitySplit.length > 0) {
          usernameReady = identitySplit[0];
        }
        if (!usernameReady) {
          return reject('Username is required');
        }

        //Loading from cache if exists
        const metadata = this.metaDataCache.find((user) => user.username === usernameReady && user.roomId === roomId);
        if (metadata) {
          return resolve(metadata.data);
        }

        this.authService.getMetaData(usernameReady, roomId).subscribe((data: any) => {
          if (!data.data) {
            console.log('error', data);
            return reject();
          }
          data.roomId = roomId;
          this.metaDataCache.push(data.data);
          resolve(data.data);
        }, (error: any) => {
          console.log('error', error);
          reject();
        });

      });
    }


}


