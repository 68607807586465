<swiper #swiper [config]="swiperParams">
  <ng-template class="slide1" swiperSlide>
    <div class="illustration">
      <ion-icon
        class="aspect-square"
        src="assets/presentation/p1.svg"
      ></ion-icon>
    </div>
    <div class="content">
      <div>
        <h2 class="title">¡Te damos la bienvenida!</h2>
        <p>
          En Estrim podrás encontrar una gran variedad de contenido de audio y vídeo.
          Explora todas las secciones para no perderte nada.
        </p>
        <ion-button (click)="nextSlide()"> Siguiente </ion-button>
      </div>
    </div></ng-template
  ><ng-template class="slide2" swiperSlide>
    <div class="illustration">
      <ion-icon
        class="aspect-square"
        src="assets/presentation/p2.svg"
      ></ion-icon>
    </div>
    <div class="content">
      <div>
        <h2 class="title">Estrellas</h2>
        <p>
          Apoya a los creadores <strong>enviando estrellas</strong> en su
          contenido de audio, vídeo, directos o en el chat privado. El valor de
          una estrella es 0,02€.
        </p>
        <ion-button (click)="nextSlide()"> Siguiente </ion-button>
      </div>
    </div>
  </ng-template>
  <ng-template class="slide3" swiperSlide>
    <div class="illustration">
      <ion-icon
        class="aspect-square"
        src="assets/presentation/p3.svg"
      ></ion-icon>
    </div>
    <div class="content">
      <div>
        <h2 class="title">Cartera</h2>
        <p>
          En la cartera encontrarás tu balance de créditos y todos tus
          movimientos. Puedes <strong>recargar créditos</strong> y retirar tus
          fondos.
        </p>
        <ion-button (click)="nextSlide()"> Siguiente </ion-button>
      </div>
    </div>
  </ng-template>
  <ng-template class="slide4" swiperSlide>
    <div class="illustration">
      <ion-icon
        class="aspect-square"
        src="assets/presentation/p4.svg"
      ></ion-icon>
    </div>
    <div class="content">
      <div>
        <h2 class="title">Experiencia</h2>
        <p>
          En tu perfil encontrarás tu <strong>nivel de experiencia</strong>.
          Auméntalo interactuando con otros usuarios y creando comunidad.
        </p>
        <ion-button class="finish-button" (click)="close()">
          Empezar
        </ion-button>
      </div>
    </div>
  </ng-template>
</swiper>
<div class="swiper-pagination"></div>
