<!-- Icon -->
<div class="title-icon">
  <ion-icon class="icon upload" src="assets/svg-icon/calendar2.svg"></ion-icon>
</div>

<!-- Title -->
<div class="title">
  Eventos
</div>

<!-- Subtitle -->
<div class="subtitle">
  Programa un evento de vídeo o audio e invita a colaboradores que aporten valor. Comienza la emisión a la hora que hayas programado. Configura tu evento y elige tus preferencias para que otros usuarios puedan interactuar a través del “Chat” o “Levantar la mano” interviniendo en vídeo o audio.
</div>

<!-- Subtitle -->
<div class="subtitle">
  Puedes poner un precio y generar ingresos con cada pase.
</div>

<!-- Buttons -->
<div class="buttons">
  <ion-button color="darkteal" (click)="confirm()" *ngIf="confirmButton">
    Crear evento
  </ion-button>
  <ion-button 
    fill="clear" 
    color="dark"
    class="go-back" 
    (click)="dismiss()">
    Cerrar
  </ion-button>
</div>