<ion-content [ngClass]="{'card1': !applicable, 'card2': applicable}">
  <a (click)="dismiss()" class="close">
    <img src="assets/extrim/img/icons/close-white.svg" width="30" />
  </a>

  <ng-container *ngIf="!applicable">
    <div class="header">
      <div class="header-content">
        <lazy-img
          class="estrim logo"
          source="assets/estrim/1024/logo-light.png"
        >
        </lazy-img>
        <lazy-img
          class="estrimfest large logo"
          source="assets/estrimfest/img/estrimfest-logo-square.png"
        >
        </lazy-img>
      </div>
    </div>

    <div class="content bg-white">
      <div>
        <h4>
          Invita a 3 amig@s y consigue descuentos exclusivos en las consumiciones de Exrim Race
        </h4>
        
        <ion-button class="invite-button" color="primary" (click)="goToInvitations()">
          Invitar
        </ion-button>

        <p>
          <a
            href="https://extrimrace.com"
            target="_blank"
            class="link"
            >¿Aún no tienes tu entrada?</a
          >
        </p>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="applicable" class="card2">
    <div class="header">
      <div class="header-content">
        <lazy-img
          class="estrim logo"
          source="assets/estrim/1024/logo-light.png"
        >
        </lazy-img>
        <lazy-img
          class="estrimfest logo"
          source="assets/estrimfest/img/estrimfest-logo-square.png"
        >
        </lazy-img>
        <lazy-img
          class="qr"
          source="assets/estrimfest/img/qr.png"
        >
        </lazy-img>
      </div>
    </div>

    <div class="content bg-white">
      <div>
        <h4>
          Muestra este QR en las barras de Extrim Race y consigue un descuento de 1€ en cada consumición
        </h4>

        <p>
          <a
            href="https://extrimrace.com"
            target="_blank"
            class="link"
            >¿Aún no tienes tu entrada?</a
          >
        </p>
      </div>
    </div>
  </ng-container>
</ion-content>
