<ion-item *ngIf="kType==='text'; else textarea" class="ion-text-wrap form-field" [formGroup]="kFormGroup"
          [ngClass]="{'black-style': kClass} ">
  <ion-label [position]="kPosition">{{kLabel}}</ion-label>
  <ion-input type="text" [formControlName]=kName
             [ngClass]="{'k-valid' : checkFieldValid(kFormGroup.get(kName)), 'k-invalid' : kFormGroup.get(kName).touched && !checkFieldValid(kFormGroup.get(kName))
             , 'lowerCase': lowerCase}"
             [readonly]="kReadOnly"
             (ionFocus)="focus.emit($event)">
    <ion-spinner class="spinner-energized loading-icon" [hidden]="!loading" color="primary" name="crescent"></ion-spinner>
  </ion-input>
</ion-item>

<ng-template #textarea>
  <ion-item *ngIf="kType === 'textarea'; else password"
            class="form-field" [formGroup]="kFormGroup" [ngClass]="{'black-style': kClass} ">
    <ion-label [position]="kPosition">{{kLabel}}
    </ion-label>
    <ion-textarea [formControlName]=kName
                  [ngClass]="checkFieldValid(kFormGroup.get(kName)) ? 'k-valid' : 'k-invalid'"
                  auto-grow="true"
                  (ionFocus)="focus.emit($event)">
    </ion-textarea>
  </ion-item>
</ng-template>

<ng-template #password>
  <ion-item *ngIf="kType === 'password'; else checkbox" class="ion-text-wrap form-field"
            [formGroup]="kFormGroup" [ngClass]="{'black-style': kClass} ">
    <app-show-hide-password>
      <ion-label [position]="kPosition">{{kLabel}}</ion-label>
      <ion-input type="password" [formControlName]=kName
                 [ngClass]="{'k-valid' : checkFieldValid(kFormGroup.get(kName)), 'k-invalid' : kFormGroup.get(kName).touched && !checkFieldValid(kFormGroup.get(kName))
                    , 'lowerCase': lowerCase}"
                 (ionFocus)="focus.emit($event)">
      </ion-input>
    </app-show-hide-password>
  </ion-item>
</ng-template>

<ng-template #checkbox>
  <ion-item *ngIf="kType === 'checkbox'; else date" lines="none"
            class="form-field aside" [formGroup]="kFormGroup" [ngClass]="{'black-style': kClass} ">
    <ion-label [class.k-invalid]="checkFieldInvalid(kFormGroup.get(kName))"
               [class.k-valid]="checkFieldValid(kFormGroup.get(kName))">{{kLabel}}
    </ion-label>
    <ion-checkbox slot="start" [formControlName]=kName
                  [class.k-invalid]="checkFieldInvalid(kFormGroup.get(kName))"
                  [class.k-valid]="checkFieldValid(kFormGroup.get(kName))"
    ></ion-checkbox>
  </ion-item>
</ng-template>

<ng-template #date>
  <ion-item *ngIf="kType === 'date'; else number" class="ion-text-wrap form-field" [formGroup]="kFormGroup"
            [ngClass]="{'black-style': kClass} " (click)="triggerDatetime()">
    <ion-label [position]="kPosition">{{kLabel}}</ion-label>
    <!-- <ion-datetime displayFormat="DD/MMM/YYYY"
                  monthShortNames="Ene, Feb, Mar, Abr, May, Jun, Jul, Ago, Sep, Oct, Nov, Dec"
                  cancel-text="Atrás"
                  done-text="Aceptar"
                  [formControlName]=kName
                  [ngClass]="checkFieldValid(kFormGroup.get(kName)) ? 'k-valid' : 'k-invalid'"></ion-datetime> -->

    <ion-input
      type="text"
      [value]="kFormGroup.controls[kName].value.toString().replace(' ', 'T') | date:'dd/MM/yyyy'"></ion-input>

    <ion-button id="open-form-input-datetime-dialog" expand="block"></ion-button>

    <ion-modal id="form-input-datetime-modal" #modal trigger="open-form-input-datetime-dialog">
      <ng-template>
        <ion-datetime
          id="birtdate"
          locale="es-ES"
          displayFormat="DD/MMM/YYYY"
          presentation="date"
          [preferWheel]="true"
          monthShortNames="Ene, Feb, Mar, Abr, May, Jun, Jul, Ago, Sep, Oct, Nov, Dec"
          cancel-text="Atrás"
          done-text="Aceptar"
          [formControlName]="kName"
          [showDefaultButtons]="true"
          [ngClass]="checkFieldValid(kFormGroup.get(kName)) ? 'k-valid' : 'k-invalid'"
          (ionChange)="datetimeChange($event)">
        </ion-datetime>
      </ng-template>
    </ion-modal>
  </ion-item>
</ng-template>

<ng-template #number>
  <ion-item class="ion-text-wrap form-field" [formGroup]="kFormGroup">
    <ion-label [position]="kPosition">{{kLabel}}</ion-label>
    <ion-input type="number" [formControlName]=kName
               [ngClass]="checkFieldValid(kFormGroup.get(kName)) ? 'k-valid' : 'k-invalid'"
               (ionFocus)="focus.emit($event)">
      <ion-spinner class="spinner-energized loading-icon" [hidden]="!loading" color="primary" name="crescent"></ion-spinner>
    </ion-input>
  </ion-item>
</ng-template>

<ion-item lines="none" class="ion-text-wrap form-error">
  <div class="ion-text-left"
       *ngIf="checkFieldInvalid(kFormGroup.get(kName))">
    <ion-text color="danger">
      <small>
        <ion-icon name="alert-circle-outline"></ion-icon>
        {{errorMessage}}
      </small>
    </ion-text>
  </div>
</ion-item>
