import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { environment } from 'src/environments/environment';

@Directive({
  selector: '[scoreDebug]'
})
export class ScoreDebugDirective {

  @Input() debugDataId: number = 0;
  @Input() debugCacheKey: string = '';

  public static domElement: HTMLDivElement | undefined = undefined;

  constructor( private element: ElementRef ) {
  }

  @HostListener('mouseenter') mouseover(){
    environment.isWeb ? this.showData() : null;
  }

  @HostListener('mouseleave') mouseleave(){
    environment.isWeb ? ScoreDebugDirective.domElement?.remove() : null;
  }

  async showData(): Promise<any> {
    try {
      const rawData = await Preferences.get({key: this.debugCacheKey});

      if ( rawData.value ) {
        const data = JSON.parse(rawData.value);
        const item = data.find(data => data.id === this.debugDataId);

        if ( item ) {
          delete item.id;

          let totalScoring: number = 0;

          Object.keys(item).forEach(key => {
            if ( key.includes('scoring') ) {
              totalScoring += Number(item[key]);
            }
          })

          totalScoring > 0 ? item.total_scoring = totalScoring : null;

          this.createDomElement(item);
        }
      }
    } catch (e) {}
  }

  private createDomElement(data: any) {
    ScoreDebugDirective.domElement?.remove()
    ScoreDebugDirective.domElement = document.createElement("div");

    ScoreDebugDirective.domElement.style.position = "fixed";
    ScoreDebugDirective.domElement.style.top = '10px';
    ScoreDebugDirective.domElement.style.left = '10px';
    ScoreDebugDirective.domElement.style.width = "fit-content";
    ScoreDebugDirective.domElement.style.height = "fitn-content";
    ScoreDebugDirective.domElement.style.background = "red";
    ScoreDebugDirective.domElement.style.color = "white";
    ScoreDebugDirective.domElement.style.background = "rgba(0, 0, 0, 0.8)";
    ScoreDebugDirective.domElement.style.borderRadius = "4px";
    ScoreDebugDirective.domElement.style.padding = "5px 7px";

    let html: string = '';

    Object.keys(data).forEach((key, index) => {
      html += `<div ${index % 2 ? 'style="background: #ffffff1a"' : ''}><span style="min-width: 200px; display: inline-block;">${key}:</span>&emsp;<b>${data[key]}</b></div>`;
    })

    ScoreDebugDirective.domElement.innerHTML = html;

    document.getElementById("main").appendChild(ScoreDebugDirective.domElement);
  }
}
