import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const phoneValidatorES: ValidatorFn = (c: AbstractControl, type: string = null): ValidationErrors | null => {
   const regex = /^((6|7|9){1}[0-9]{8})$/g;

   if ( !c.value.toString().match(regex) ) {
      return { phoneError: true };
   } else {
      return null;
   }
}

export const mobilePhoneValidatorES: ValidatorFn = (c: AbstractControl, type: string = null): ValidationErrors | null => {
   const regex = /^((6|7){1}[0-9]{8})$/g;

   if ( !c.value.toString().match(regex) ) {
      return { phoneError: true };
   } else {
      return null;
   }
}

export const phoneValidatorGlobal: ValidatorFn = (c: AbstractControl, type: string = null): ValidationErrors | null => {
   const regex = /^[0-9]{7,14}$/g;

   if ( !c.value.toString().match(regex) ) {
      return { phoneError: true };
   } else {
      return null;
   }
}