<img
  src="assets/star-l.png"
  *ngIf="filled"
  @inOutStarAnimation1
  [@starAnimation1]="animateStar"
  (@starAnimation1.done)="animateStar = false"
/>
<ion-icon
  class="aspect-square black-outline"
  *ngIf="!filled"
  [ngClass]="{ invert: whiteStar }"
  [@starAnimation1]="animateStar"
  (@starAnimation1.done)="animateStar = false"
  src="assets/item/clip/star.svg"
></ion-icon>

<div class="count-content">
  <div class="count" *ngIf="animateCount" @inOutCount>
    {{ count | shortNumber }}
  </div>
</div>
