import { Directive, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Platform } from '@ionic/angular';

@Directive({
  selector: '[long-press]'
})
export class LongPressDirective {

  @Input() delay: number = 800;
  @Input() cancelMoveDistance: number = 30;
  @Output() longPressTrigger: EventEmitter<boolean> = new EventEmitter();

  private longPress: boolean = false;
  private longPressTimeout: any;
  private elementTouchEvent: any;

  constructor( private platform: Platform ) { }

  @HostListener('contextmenu', ['$event'])
  rightClick($event) {
    if ( !this.platform.is('hybrid') ) {
      this.longPressTrigger.emit(true);

      $event.preventDefault();
      $event.stopPropagation();
    }
  }

  @HostListener('touchend', ['$event'])
  @HostListener('touchmove', ['$event'])
  cancelled($event) {
    if (event === undefined || $event.touches === undefined || $event.touches[0] === undefined || !this.platform.is('hybrid')) {
      this.longPress = false;
    } else {
      if ($event.touches !== undefined) {
        const moveDistance = this.getDistance(
          this.elementTouchEvent.screenX,
          this.elementTouchEvent.screenY,
          $event?.touches[0].screenX,
          $event?.touches[0].screenY
        );

        if (moveDistance > this.cancelMoveDistance) {
          this.longPress = false;
        }
      }
    }
  }

  @HostListener('touchstart', ['$event'])
  pressStart($event){
    if (this.platform.is('hybrid')) {
      if ($event.touches !== undefined) {
        this.elementTouchEvent = $event.touches[0];
      }

      this.longPress = true;

      clearTimeout(this.longPressTimeout);

      this.longPressTimeout = setTimeout(() => {
        if (this.longPress) {
          // setTimeout(() => {
            this.longPress = false;
            this.longPressTrigger.emit(true);
          // });
        }
      }, this.delay);
    }
  }

  private getDistance(x1, y1, x2, y2) {
    let y = x2 - x1;
    let x = y2 - y1;

    return Math.sqrt(x * x + y * y);
  }
}
