import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile-avatar-zoom',
  templateUrl: './profile-avatar-zoom.component.html',
  styleUrls: environment.isWeb ?
  ['./profile-avatar-zoom.component.scss', './profile-avatar-zoom-desktop.component.scss'] :
  ['./profile-avatar-zoom.component.scss']
})
export class ProfileAvatarZoomComponent implements OnInit {

  @Input() imageSrc: string;

  constructor( private modalCtrl: ModalController ) { }

  ngOnInit() {}

  close() {
    this.modalCtrl.dismiss();
  }
}
