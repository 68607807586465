import { OnInit, OnChanges, Input, Directive, ElementRef, SimpleChanges } from "@angular/core";
import { environment } from "src/environments/environment";

import { MediaService } from '../services/media.service';

import { CacheDirective } from './cache.directive';

@Directive({
  selector: "img[thumbnail], ion-img[thumbnail]",
})
export class ThumbnailDirective extends CacheDirective implements OnInit, OnChanges {

  @Input() size: number;
  @Input() source: string;

  constructor(
    protected mediaService: MediaService,
    protected element: ElementRef
  ) {
    super(mediaService, element);
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    // Insert size if available
    if ( this.source && this.size && !this.localAsset(this.source) && !this.source.includes("ui-avatars") ) {
      this.source = `${environment.IMAGE_SERVICE_BASE_URL}?url=${this.source}&w=${this.size.toString()}&output=webp`;
    }

    // Call base init
    super.ngOnChanges(changes);
  }

  localAsset(source: String) {
    return source.includes('assets/') && !source.includes("http");
  }
}