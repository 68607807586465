<div class="steps">

  <div class="title">
    Tu cuenta ha sido verificada
  </div>

  <!-- Info -->
  <div class="info">
    <div class="item">
      <div class="icon user">
        <ion-icon src="assets/svg-icon/new-version/Outline_Perfil.svg"></ion-icon>
      </div>

      <div class="text">
        <div class="intro">
          Desata todo tu potencial
        </div>

        <div class="description">
          Ahora puedes hacer uso de todas las funciones que ofrece Estrim.
        </div>
      </div>
    </div>
  </div>

  <!-- Buttons -->
  <div class="buttons">
    <ion-button color="darkteal" (click)="goHome()">
      Continuar
    </ion-button>
  </div>

</div>
