import { Component, OnInit, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { IonNav, Platform, ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

import { BasePage } from '../../../../../pages/base.page';
import { MediaPage } from '../media/media.page';
import { AllCategoriesPage } from '../../../../../pages/all-categories/all-categories.page';
import { ListFollowersToInvitePage } from '../../../../../pages/publications/list-followers-to-invite/list-followers-to-invite.page';

import { PageMode } from '../event-premiere.component';

import { CreateContentService } from '../../../../../services/create-content.service';
import { FeedService } from '../../../../../services/feed.service';
import { ProfileService } from '../../../../../services/profile.service';
import { MediaService } from '../../../../../services/media.service';
import { AuthService } from '../../../../../services/auth.service';

import { Category } from '../../../../../interfaces/category';
import { Follower } from '../../../../../interfaces/follower';
import { Event } from '../../../../../interfaces/event';
import { User } from '../../../../../interfaces/user';

import { ContentType } from '../../../../../enums/content-type';
import { FormMode } from '../../../../../enums/form-mode';

@Component({
  selector: 'app-form',
  templateUrl: './form.page.html',
  styleUrls: ['./form.page.scss', '../../../../../../theme/field.scss'],
})
export class FormPage extends BasePage implements OnInit, AfterViewInit {

  @Input() type: ContentType = ContentType.Event;
  @Input() mode: FormMode = FormMode.Create;
  @Input() event: Event = undefined;

  ContentType = ContentType;

  categories: Category[] = [];

  error: any = {};

  minimumDate: string;
  maximumDate: string;
  minimumHour: string;

  followers: Follower[] = [];

  user: User;

  viewInited: boolean = false;

  /**
   * Form data
   */
  title: string;
  description: string;
  category: number;
  isVideo: boolean = true;
  date: string;
  hour: string;
  time: string = '1990-01-01T00:30';
  collaborators: Follower[] = [];
  streamKey: string;
  streamUrl: string;

  constructor(
    public platform: Platform,
    private nav: IonNav,
    private createContentService: CreateContentService,
    private feedService: FeedService,
    private profileService: ProfileService,
    private mediaService: MediaService,
    private modalCtrl: ModalController,
    private authService: AuthService,
    public cdRef: ChangeDetectorRef
  ) {
    super(platform, cdRef);

    // Get categories
    this.categories = feedService.categories;
  }

  ngOnInit() {
    this.user = this.authService.user;

    this.calculateMinMaxDate();

    if ( this.mode === FormMode.Edit && this.event !== undefined ) {
      this.title = this.event.title;
      this.description = this.event.description;
      this.category = this.event.category.id;
      this.isVideo = this.event.isVideo;

      if ( this.event.collaboratorList instanceof Array ) {
        // @ts-ignore
        this.collaborators = this.event.collaboratorList;
      }

      const eventDate = new Date(this.event.eventDateStart.date);
      const eventYear = eventDate.getFullYear();
      const eventMonth = (eventDate.getMonth() + 1).toString().padStart(2, '0');
      const eventDay = eventDate.getDate().toString().padStart(2, '0');
      const eventHour = eventDate.getHours().toString().padStart(2, '0');
      const eventMinutes = eventDate.getMinutes().toString().padStart(2, '0');

      this.hour = `${eventYear}-${eventMonth}-${eventDay}T${eventHour}:${eventMinutes}`;
      this.date = `${eventYear}-${eventMonth}-${eventDay}`;

      this.cdRef.detectChanges();
    } else if ( this.mode === FormMode.Create ) {
      this.date = this.minimumDate;
      this.hour = this.minimumHour;
    }

    // Get follower list
    this.profileService.getFollowers().subscribe((followers: Follower[]) => {
      this.followers = followers;
    });

    if ( this.mode === FormMode.Edit ) {
      // Get stream key
      const roomId = parseInt((environment.production ? 0 : 9) +  '333' + this.event.id, 10);
      this.authService.getStreamKey(roomId, this.user.username).subscribe((data) => {
        if(data.done){
          this.streamKey = data.streamKey;
          this.streamUrl = data.url;
        }
      });
    }
  }

  ngAfterViewInit() {
    this.viewInited = true;
    this.cdRef.detectChanges();
  }

  dismiss() {
    this.createContentService.closeModal();
  }

  goBack() {
    if ( this.mode === FormMode.Create )
      this.nav.pop({ mode: 'ios' });
    else
      this.dismiss();
  }

  checkForm(): boolean {
    let status = true;
    
    if ( !(this.date && this.date.trim() !== '') || !(this.hour && this.hour.trim() !== '') ) {
      status = false;
      this.error.date = true;
    }

    if ( !(this.title && this.title.trim() !== '') ) {
      status = false;
      this.error.title = true;
    }

    if ( !(this.description && this.description.trim() !== '') ) {
      status = false;
      this.error.description = true;
    }

    if ( !this.category ) {
      status = false;
      this.error.category = true;
    }

    return status;
  }

  submitForm() {
    if ( !this.checkForm() ) {
      return ;  // BREAK EXECUTION
    }

    this.nav.push(
      MediaPage, 
      { 
        pageMode: PageMode.Media, 
        type: this.type,
        mode: this.mode,
        event: this.event,
        form: {
          title: this.title,
          description: this.description,
          category: this.category,
          isVideo: this.isVideo,
          date: this.date,
          hour: this.hour,
          time: this.time,
          collaborators: this.collaborators
        }
      }, 
      { mode: 'ios' }
    );
  }

  async pickCategory() {
    const modal = await this.modalCtrl.create({
      component: AllCategoriesPage,
      // mode: 'ios',
      componentProps: {
        customCategories: this.categories,
        defaultBackAction: false,
        isModalPage: true,
        multipleSelection: false
      }
    });
    await modal.present();

    const { data } = await modal.onDidDismiss();

    if ( data.length ) {
      this.category = data[0].id;
      this.cdRef.detectChanges();
    }
  }

  calculateMinMaxDate() {
    const date = new Date();

    date.setMinutes(date.getMinutes() + 30);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    const dateMax = new Date(new Date().setMonth(new Date().getMonth() + 12));
    const monthMax = dateMax.getMonth().toString().padStart(2, '0');
    const yearMax = dateMax.getFullYear()

    this.minimumDate = `${year}-${month}-${day}T${hour}:${minutes}`;
    this.maximumDate = `${yearMax}-${monthMax}-${day}T${hour}:${minutes}`;
    this.minimumHour = `${year}-${month}-${day}T${hour}:${minutes}`;
  }

  changeDate($event) {
    let date = new Date();

    const selectedDate = new Date($event.detail.value);

    if (selectedDate.getDate() === date.getDate() && 
        selectedDate.getMonth() === date.getMonth() && 
        selectedDate.getFullYear() === date.getFullYear()) {

      while (!date.getMinutes().toString().endsWith('0') && !date.getMinutes().toString().endsWith('5')) {
        date = new Date(date.getTime() + 60000);
      }

      const minDate = new Date();
      minDate.setMinutes(date.getMinutes() + 30);
      
      const year = minDate.getFullYear();
      const month = (minDate.getMonth() + 1).toString().padStart(2, '0');
      const day = minDate.getDate().toString().padStart(2, '0');
      const hour = minDate.getHours().toString().padStart(2, '0');
      const minutes = minDate.getMinutes().toString().padStart(2, '0');
      
      this.minimumHour = `${year}-${month}-${day}T${hour}:${minutes}`;
      this.hour = this.minimumHour;
    } else {
      this.minimumHour = undefined;
    }
  }

  getCollaboratorImg(id, img: any) {
    return this.mediaService.generateImgProfileURL(id, img);
  }

  async openFollowersToInvite() {
    const modal = await this.modalCtrl.create({
      mode: 'ios',
      component: ListFollowersToInvitePage,
      backdropDismiss: true,
      componentProps: {
        followers: this.followers,
        collaborators: this.collaborators
      },
    });

    await modal.present();

    const {data} = await modal.onDidDismiss();

    if (data.invited) {
      this.collaborators = [];

      data.invited.forEach(inv => {
        this.collaborators.push(inv);
      });
    }
  }
}
