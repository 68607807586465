import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
// import { AnonymousSubject } from 'rxjs/internal-compatibility';
import { Observable, Observer, Subject } from 'rxjs';
import { User } from '../interfaces/user';
import { environment } from '../../environments/environment';
import { filter, map } from 'rxjs/operators';
import { Events } from './events.service';
import { InviteType } from '../enums/invite-type';
import { webSocket } from 'rxjs/webSocket';

import { ChatEvent } from '../enums/chat-event';
import { ChatMessage, ChatMessagePayload } from '../interfaces/chat-message';
import { ChatMessageType } from '../enums/chat-message-type';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  // private subject: AnonymousSubject<MessageEvent>;
  public messages: Subject<any>;

  /** Observers the open event on the websocket. */
  onOpen: Subject<any> = new Subject();

  /** Observes the close event on the websocket. */
  onClose: Subject<any> = new Subject();

  private status: number;

  private user: User;
  private url = '';
  private tokenId = environment.SOCKET_TOKEN_ID;

  constructor(private authService: AuthService, private events: Events) {
    this.tokenId = localStorage.getItem('access_token');

    this.events.subscribe(environment.EVENTS.ON_LOGIN_SCREEN, () => {
      this.user = undefined;
      this.url = '';
    });

    this.onOpen.subscribe(() => {
      console.log('Websocket connected');

      this.status = WebSocket.OPEN;
    });

    this.onClose.subscribe(() => {
      console.log('Websocket closed');

      this.status = WebSocket.CLOSED;
      this.events.publish(environment.EVENTS.WEBSOCKET_DESCONECTED);
    });
  }

  async init() {
    // Previene que se inicie el socket mas de una vez
    if ( this.status === WebSocket.OPEN || this.status === WebSocket.CONNECTING ) {
      return;
    }

    this.authService.watchUser()
    .pipe(filter(data => !!data))
    .subscribe((user: User) => {
      this.user = user;

      // Close connection in case already exists
      // this.close(false);

      //convert user to string
      let userString = JSON.stringify(this.user);
      userString = userString.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');

      //convert string to base64
      const base64 = btoa(userString);

      this.url = `${environment.SOCKET_NODE_SERVER}?tokenId=${this.tokenId}&info=${base64}`;

      this.connect(this.url);
    });
  }

  public connect(url) {
    this.status = WebSocket.CONNECTING;
    this.create(url);
  }

  private create(url) {
    this.messages = webSocket<MessageEvent>({
      url: url,
      openObserver: this.onOpen,
      closeObserver: this.onClose
    })

    this.messages.subscribe({
      next: (e: MessageEvent) => {
        try {
          const message = JSON.parse(e.data);

          if(message.evento === 'tokenError'){
            try {
              const message = JSON.parse(e.data);

              if(message.evento === 'tokenError'){
              try {
                this.authService.refreshToken().subscribe(res => {
                  this.close();
                });
              } catch (error) {
                console.error('Error refresh token', error);
              }
              }
              return message;
            } catch(e) {
              return {};
            }
          }
        } catch(e) {}
      },
      error: (e) => console.error(e),
      complete: () => console.log('complete')
    });
  }

  readyState() {
    return this.status;
  }

  close(reconnect: boolean = true) {
    this.messages?.complete();

    if (reconnect) {
      setTimeout(() => {
        this.events.publish(environment.EVENTS.WEBSOCKET_DESCONECTED);
      }, 1000);
    }
  }

  public roomExists(room: number) {
    const request = {
      evento: 'existeSala',
      sala: room,
      tokenId: this.tokenId
    };
    this.messages.next(request);
  }

  public createRoom(room: number) {
    const request = {
      evento: 'createSala',
      sala: room,
      tokenId: this.tokenId
    };
    this.messages.next(request);
  }

  /**
   * @param room
   * @param offset
   * @param limit
   * @deprecated This method is deprecated
   */
  public joinRoom(room: number, offset = 0, limit = 25) {
    const request = {
      evento: 'joinSala',
      sala: room,
      skip: offset,
      limit,
      tokenId: this.tokenId
    };
    this.messages.next(request);
  }
  public joinChatRoom(room: number, offset = 0, limit = 25) {
    const request = {
      event: ChatEvent.JOIN_ROOM,
      userId: this.user.id,
      roomId: room,
      messageOffset: offset,
      maxMessages: limit,
      tokenId: this.tokenId
    };
    this.messages.next(request);
  }

  public joinRoomPrivate(room: number, offset = 0, limit = 25) {
    const request = {
      evento: 'joinRoomPrivate',
      sala: room,
      skip: offset,
      limit,
      tokenId: this.tokenId
    };
    this.messages.next(request);
  }

  public sendInvitationToRoom(room: number, invitationType: InviteType, uid: number, lid: number) {
    const request = {
      evento: 'private-invitation-room',
      sala: room,
      uid: uid,
      lid: lid,
      invitationType: invitationType,
      tokenId: this.tokenId
    };
    this.messages.next(request);
  }

  public sendMessagePrivate(room: number, message: string, uid: number) {
    const request = {
      evento: 'private-message',
      sala: room,
      uid: uid,
      message: message,
      tokenId: this.tokenId
    };
    this.messages.next(request);
  }

  public sendMessage(message: ChatMessage, ...args: any[]) {
    this.messages.next(message);
  }

  public sendResponse(room: number, messageId: number, message: string, data: any = '', type = null, isPrivate = false) {
    let file = data;
    if (type && data) {
      file = {
        type,
        data
      };
    }
    const request = {
      evento: 'sendResponseSala',
      responseMessageId: messageId,
      sala: room,
      private: isPrivate,
      text: message,
      file,
      tokenId: this.tokenId
    };
    this.messages.next(request);
  }
  public numParticipants(room: number) {
    const request = {
      evento: 'numeroConectadosSala',
      sala: room,
      tokenId: this.tokenId
    };

    this.messages.next(request);
  }

  public raisehand(room: number, video) {
    const request = {
      evento: 'levantaManoSala',
      sala: room,
      video,
      tokenId: this.tokenId
    }

    this.messages.next(request);
  }

  public putHandDown(room: number) {
    const request = {
      evento: 'bajarManoSala',
      sala: room,
      tokenId: this.tokenId
    };

    this.messages.next(request);
  }


  /**
   * Accept the hand up of a user in a room
   * @param room roomid from livekit
   * @param username username of the user
   * @param permission permission to give to the user
   * @param clientid clientid of the user
   */
  public handAccepted(roomId: number, username: number, permission: boolean, clientId: number) {
    const request = {
      event: ChatEvent.ACCEPT_HANDS_UP,
      roomId,
      data: {
        permission,
        username,
        clientId
      },
    };

    this.messages.next(request);
  }

  /**
   * Change the hand permission of a user in a room
   * @param roomId roomid from livekit
   * @param username username of the user
   * @param permission permission to give to the user
   * @deprecated This method is deprecated and should be removed, use handAccepted instead
   */
  public changeHandPermission(roomId: number, username: string | number, permission: boolean) {

    const request = {
      event: ChatEvent.ACCEPT_HANDS_UP,
      roomId,
      data: {
        permission,
        username,
      },
    };

    this.messages.next(request);
  }

  public likeMessage(roomId, msgId, userId?) {
    const request = {
      event: ChatEvent.MESSAGE_LIKE,
      roomId: roomId,
      userId: userId,
      data: {
        messageId: msgId
      },
    };

    this.messages.next(request);
  }

  public deleteMessage(room, msgId, justForMe = false) {
    const request = {
      evento: 'borrarMensaje',
      sala: room,
      idMensaje: msgId,
      deleteMensajePorMe: justForMe,
      tokenId: this.tokenId
    };

    this.messages.next(request);
  }

  public getPrivateRooms(userId) {
    const request = {
      evento: 'informacionSalasPrivadas',
      userIdPrivado: userId.toString(),
      tokenId: this.tokenId
    };

    this.messages.next(request);
  }

  public startEmitting(room, invited, video = true) {
    const request = {
      evento: 'initEmit',
      sala: room,
      invited,
      video,
      tokenId: this.tokenId
    };

    this.messages.next(request);
  }

  stopEmitting(room) {
    const request = {
      evento: 'endEmit',
      sala: room,
      tokenId: this.tokenId
    };

    this.messages.next(request);
  }

  leaveRoom(room) {
    if(this.user == null){ return; }
    if(this.user.id == null){ return; }
    const request = {
      evento: 'salirChat',
      sala: room,
      userIdClose: this.user.id,
      tokenId: this.tokenId
    };

    this.messages.next(request);
  }

  infoUsers(room) {
    const request = {
      evento: 'usuariosSala',
      sala: room,
      tokenId: this.tokenId
    };

    this.messages.next(request);
  }

  kickUser(room, userId, deleteMessages, time = 1) {
    const request = {
      evento: 'expulsarUsuario',
      sala: room,
      userIdBaneado: userId,
      borrarMensajes: deleteMessages,
      tokenId: this.tokenId,
      time
    };

    this.messages.next(request);
  }

  silentUser(room, userId) {
    const request = {
      evento: 'silentUser',
      sala: room,
      uid: userId,
      tokenId: this.tokenId,
    };

    this.messages.next(request);
  }

  activateMirror(room, username, isGirated) {
    const request = {
      evento: 'activateMirror',
      sala: room,
      username,
      isGirated,
      tokenId: this.tokenId
    };
    this.messages.next(request);
  }
}
