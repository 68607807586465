import { Injectable } from '@angular/core';
import { Events } from './events.service';
import { ModalController, Platform } from '@ionic/angular';
import { BehaviorSubject, Subject } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';
import { Share, CanShareResult } from '@capacitor/share';
import { HttpClient } from '@angular/common/http';

import { AuthService } from './auth.service';
import { ProfileService } from './profile.service';

import { environment } from '../../environments/environment';

import { User } from '../interfaces/user';

import { PingMode } from '../enums/ping-mode';
import { InviteMode } from '../enums/invite-mode';
import { InviteType } from '../enums/invite-type';
import { ViewerType } from '../enums/viewer-type';
import { ProAlertType } from '../enums/pro-alert-type';
import { ContentType } from '../enums/content-type';
import { GamificationType } from '../enums/gamification-type';

import { SubscribeComponent } from '../components/subscribe/subscribe.component';
import { CollaborateComponent } from '../components/collaborate/collaborate.component';
import { ExperienceComponent } from '../components/experience/experience.component';
import { FounderComponent } from '../components/founder/founder.component';
import { PingComponent } from '../components/ping/ping.component';
import { PresentationComponent } from '../components/presentation/presentation.component';
import { InviteComponent } from '../components/invite/invite.component';
import { ReportComponent } from '../components/report/report.component';
import { ProAlertComponent } from '../components/pro-alert/pro-alert.component';
import { NewProUserPopupComponent } from '../components/new-pro-user-popup/new-pro-user-popup.component';
import { NewBadgeComponent } from '../components/new-badge/new-badge.component';
import { RegisteredUserWithCodePopupComponent } from '../components/registered-user-with-code-popup/registered-user-with-code-popup.component';

import * as Sentry from "@sentry/capacitor";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  WELCOME_DISMISSED_KEY = 'WELCOME_DISMISSED';
  WELCOME_IGNORED_KEY = 'WELCOME_IGNORED';
  INVITATION_MENU_DISMISSED_KEY = 'INVITATION_MENU_DISMISSED';

  profileWelcomeUrl = 'api/auth/aforum/profile/welcome';
  gamificationEventsUrl = 'api/auth/estrim/gamifications';

  /**
   * Observable para lanzar evento al cambiar datos de un usuario
   */
  private _userStatusChanged = new Subject<User>();
  userStatusChanged = this._userStatusChanged.asObservable();

  /**
   * Observable para informar el cerrar mensaje de bienvenida
   */
  private _welcomeDismissed = new Subject<boolean>();
  welcomeDismissed = this._welcomeDismissed.asObservable();

  /**
   * Observable para indicar estado del indicador del menu de invitaciones
   */
  private _invitationMenuIndicator = new BehaviorSubject<boolean>(false);
  invitationMenuIndicator = this._invitationMenuIndicator.asObservable();

  /**
   * Objeto modal
   */
  private _modal: HTMLIonModalElement;

  /**
   * Array para guardar eventos de gamificacion
   */
  private _gamificationEvents: Array<{type: GamificationType, [key: string]: any}> = [];

  constructor( 
    private http: HttpClient,
    private platform: Platform,
    private events: Events,
    private modalCtrl: ModalController,
    private alertController: AlertController,
    private authService: AuthService,
    private profileService: ProfileService,
  ) {
    this.init();
  }

  async init() {
    // Check invitation menu indicator
    const indicatorStatus = await Preferences.get({key: this.INVITATION_MENU_DISMISSED_KEY});
    indicatorStatus?.value === '1' ? this._invitationMenuIndicator.next(false) : this._invitationMenuIndicator.next(true);
  
    this.events.subscribe(environment.EVENTS.WELCOME, () => {
      this.openWelcomeModal();
    })
    this.events.subscribe(environment.EVENTS.WELCOME_IGNORED, () => {
      Preferences.set({key: this.WELCOME_IGNORED_KEY, value: '1'});
    })
    this.events.subscribe(environment.EVENTS.USER_LOGIN, async () => {
      if ( this.welcomeMessageIgnored() && !this.welcomeMessageShowed() ) {
        this.openWelcomeModal();
      }
    })
  }

  public generateAbsoluteAPIURL($tail: string) {
    return environment.API_BASE_URL + $tail;
  }

  /**
   * Lanza evento para indicar que un usuario ha cambiado
   * @param {User} user Usuario que ha cambiado
   */
  userChanged(user: User) {
    this._userStatusChanged.next(user);
  }

  /**
   * Comprueba si el objeto usurio tiene todos los datos necesarios
   * para mostrar su perfil
   * @param user 
   * @returns 
   */
  userDataIsComplete(user: User) {
    return user.hasOwnProperty('biography') &&
      user.hasOwnProperty('blocked') &&
      // user.hasOwnProperty('collaborating') &&  // No comprobamos collaborating porque no es un dato que venga en los usuarios de las publicaciones de momento
      user.hasOwnProperty('collaborationsActivated') &&
      user.hasOwnProperty('emitting') &&
      user.hasOwnProperty('id') &&
      user.hasOwnProperty('imgProfile') &&
      user.hasOwnProperty('isFounder') &&
      user.hasOwnProperty('isPro') &&
      user.hasOwnProperty('nameToShow') &&
      user.hasOwnProperty('numCollaborators') &&
      user.hasOwnProperty('numFollowed') &&
      user.hasOwnProperty('numFollowers') &&
      user.hasOwnProperty('privateMessages') &&
      user.hasOwnProperty('profileCover') &&
      user.hasOwnProperty('reputation') &&
      user.hasOwnProperty('reputationLevel') &&
      user.hasOwnProperty('subscriptionsActivated') &&
      user.hasOwnProperty('username')
  }

  /**
   * Devuelve un usuario de cache si este existe
   * @param username 
   * @returns 
   */
  async getCachedUser(username: string): Promise<User | undefined> {
    const json = await Preferences.get({key: `${username}_PROF_CACHED_PROFUSER`});

    if (json.value) {
      return JSON.parse(json.value);
    } else {
      return undefined
    }
  }

  /*
   ************* Subscribe 
   */

  /**
   * Inicia mecanismo de acción de boton de suscribir
   * @param {User} user Usuario destino
   */
  subscribeToUser(user: User) {
    if (user.subscribed) {
      this.events.publish(environment.EVENTS.ALERT_TOAST_NOTICE,
        'Si quieres cancelar tu suscripción ve a Menú principal > Mis suscripciones > Cancelar suscripción');
    } else {
      this.openSubscribeModal(user);
    }
  }

  /**
   * Cierra modal de suscripcion
   */
  closeSubscribe() {
    this._modal.dismiss();
    this._modal = undefined;
  }

  /**
   * Abre modal de suscripcion
   * @param {User} user Usuario destino
   */
  private async openSubscribeModal(user: User) {
    this._modal = await this.modalCtrl.create({
      cssClass: "fit-modal floating-modal",
      component: SubscribeComponent,
      componentProps: { user },
      mode: "ios",
    });
    this._modal.present();
  }

  /*
   ************* Collaborate 
   */

  /**
   * Inicia mecanismo de acción de boton de colaboracion
   * @param {User} user Usuario destino
   */
  collaborateWithUser(user: User) {
    if ( user.collaborating === 1 || user.collaborationRequests ) {
      this.presentAlertRemoveCollaboration(user);
    } else {
      this.openCollaborateModal(user);
      // this._collaborateUser.next(user);
    }
  }

  /**
   * Cierra modal de colaboracion
   */
  closeCollaborate() {
    this._modal.dismiss();
    this._modal = undefined;
    // this._collaborateUser.next(undefined);
  }

  /**
   * Abre modal de colaboracion
   * @param {User} user Usuario destino
   */
  private async openCollaborateModal(user: User) {
    this._modal = await this.modalCtrl.create({
      cssClass: "fit-modal floating-modal",
      component: CollaborateComponent,
      componentProps: { user },
      mode: "ios",
    });
    this._modal.present();
  }

  /**
   * Lanza alertas al intentar terminar una colaboracion, ya sea una 
   * colaboracion aceptada o una peticion
   * @param {User} user Usuario destino
   */
  private async presentAlertRemoveCollaboration(user: User) {
    if (user.collaborating === 1) {
      const alert = await this.alertController.create({
        cssClass: 'my-custom-alert-class',
        message: `Eliminar colaborador`,
        buttons: [
          {
            text: 'Aceptar',
            cssClass: 'alert-button-danger',
            handler: () => {
              this.removeCollaboration(user);
            }
          },
          {
            text: 'Cancelar',
            role: 'cancel'
          },

        ]
      });
      await alert.present();
    } else {
      const alert = await this.alertController.create({
        cssClass: 'my-custom-alert-class',
        message: `Eliminar solicitud de colaboración.`,
        buttons: [
          {
            text: 'Aceptar',
            cssClass: 'alert-button-danger',
            handler: () => {
              this.removeCollaboration(user);
            }
          },
          {
            text: 'Cancelar',
            role: 'cancel'
          },

        ]
      });
      await alert.present();
    }
  }

  /**
   * Elimina una colaboracion con el usuario destino
   * @param {User} user Usuario destino
   */
  private removeCollaboration(user: User) {
    this.profileService.removeCollaborator(user.id).subscribe(res => {
      if (res.done) {
        this.events.publish(environment.EVENTS.ALERT_TOAST_NOTICE, 'Invitación eliminada.');
        user.collaboration = null;
        user.collaborating = null;
        user.collaborationRequests = false;
        this.userChanged(user);
      } else {
        this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, res.msg);
      }
    });
  }

  /*
   ************* Experience 
   */

  async openExperienceModal(user: User) {
    this._modal = await this.modalCtrl.create({
      cssClass: "fit-modal floating-modal",
      component: ExperienceComponent,
      componentProps: { user },
      mode: "ios",
    });
    this._modal.present();
  }

  /*
   ************* Founder 
   */

  /**
   * Abre modal de founder
   * @param {User} user Usuario
   */
  async openFounderModal(user: User) {
    this._modal = await this.modalCtrl.create({
      cssClass: "founder-modal",
      component: FounderComponent,
      componentProps: { user },
      mode: "ios",
    });
    this._modal.present();
  }

  /*
   ************* PRO 
   */

  /**
   * Abre modal de advertencia de usuario no pro
   * @param {ProAlertType} type Tipo de alerta a mostrar
   */
  async openShouldBeProModal(type: ProAlertType) {
    this._modal = await this.modalCtrl.create({
      cssClass: "fit-modal floating-modal",
      component: ProAlertComponent,
      componentProps: { type },
      mode: "ios",
    });
    this._modal.present();
  }

  /**
   * Cierra modal de founder
   */
  closeFounder() {
    this._modal.dismiss();
    this._modal = undefined;
  }

  /*
   ************* Welcome 
   */

  /**
   * Abre modal de bienvanida
   */
  async openWelcomeModal() {
    if ( !this._modal ) {
      this._modal = await this.modalCtrl.create({
        cssClass: "fit-modal floating-modal box-shadow-none",
        component: PresentationComponent,
        mode: "ios",
      });
      this._modal.present();
      this._modal.onDidDismiss().then(() => {
        this.closeWelcome();
      })
    }
  }

  /**
   * Cierra modal de founder
   */
  closeWelcome() {
    Preferences.set({key: this.WELCOME_DISMISSED_KEY, value: '1'});
    this._modal.dismiss();
    this._modal = undefined;
    this._welcomeDismissed.next(null);
    this.http.post(this.generateAbsoluteAPIURL(this.profileWelcomeUrl), {}).subscribe();
  }

  async welcomeMessageShowed() {
    const status = await Preferences.get({key: this.WELCOME_DISMISSED_KEY});

    return status.value === '1';
  }
  async welcomeMessageIgnored() {
    const status = await Preferences.get({key: this.WELCOME_IGNORED_KEY});

    return status.value === '1';
  }

  /*
   ************* PRO upgrade 
   */

  /**
   * Abre modal de aviso de usuario PRO
   */
  async openProUpgdareModal() {
    if ( !this._modal ) {
      this._modal = await this.modalCtrl.create({
        cssClass: "fit-modal floating-modal box-shadow-none has-confetti",
        component: NewProUserPopupComponent,
        mode: "ios",
      });
      this._modal.present();
      this._modal.onDidDismiss().then(() => {
        this._modal.dismiss();
        this._modal = undefined;

        this.processGamificationEvent();
      })
    }
  }

  /**
   * Invitations page visited
   */
  invitationsPageViewed() {
    Preferences.set({key: this.INVITATION_MENU_DISMISSED_KEY, value: '1'});
    this._invitationMenuIndicator.next(false);
  }

  /*
    ************* New badge
    */

  /**
  * Abre modal de aviso al conseguir una nueva insignia
  */
  async openNewBadgeModal(level: number) {
    if ( !this._modal ) {
      this._modal = await this.modalCtrl.create({
        cssClass: "fit-modal floating-modal box-shadow-none",
        component: NewBadgeComponent,
        componentProps: { level },
        mode: "ios",
      });
      this._modal.present();
      this._modal.onDidDismiss().then(() => {
        this._modal.dismiss();
        this._modal = undefined;

        this.processGamificationEvent();
      })
    }
  }

  /*
   ************* Registered with invitation
   */

  /**
   * Abre modal de aviso cuando algún usuario se registra con tu invitación
   */
  async openNewInvitationRegister(username: string) {
    if ( !this._modal ) {
      this._modal = await this.modalCtrl.create({
        cssClass: "fit-modal floating-modal box-shadow-none",
        component: RegisteredUserWithCodePopupComponent,
        componentProps: { username },
        mode: "ios",
      });
      this._modal.present();
      this._modal.onDidDismiss().then(() => {
        this._modal.dismiss();
        this._modal = undefined;

        this.processGamificationEvent();
      })
    }
  }

  /*
   ************* Earpiece 
   */

  /**
   * Muestra popup para recoger datos a enviar por pinganillo a un usuario
   * @param {User} target Usuario destino
   * @param {string} message Mensaje predefinido
   * @param {ViewerType} userType Indica si el usuario es creador, suscriptor, ...
   * @return {any} Mensaje introducido por el usuario que se quiere enviar
   */
  async pingUser(target: User, message?: string, userType?: ViewerType): Promise<any | undefined> {
    this._modal = await this.modalCtrl.create({
      cssClass: "fit-modal floating-modal",
      component: PingComponent,
      componentProps: { 
        user: target, 
        message, 
        mode: PingMode.Send,
        viewerType: userType 
      },
      mode: "ios",
    });
    this._modal.present();

    const { data, role } = await this._modal.onWillDismiss();

    if ( role === 'confirm' ) {
      return data
    } else {
      return undefined
    }
  }

  /**
   * Muestra popup con mensaje recibido por pinganillo
   * @param {User} sender Usuario que envía el mensaje
   * @param {string} message Mensaje recibido
   * @param {ViewerType} userType Indica si el usuario es creador, suscriptor, ...
   */
  async showPing(sender: User, message: string, userType?: ViewerType) {
    this._modal = await this.modalCtrl.create({
      cssClass: "fit-modal floating-modal",
      component: PingComponent,
      componentProps: { 
        user: sender, 
        message, 
        mode: PingMode.Display,
        viewerType: userType
      },
      mode: "ios",
    });
    this._modal.present();

    await this._modal.onWillDismiss();
  }

  /*
   ************* Live invite 
   */

  /**
   * Muestra popup para preguntar como quiere invitar al directo
   * @param {User} target Usuario que se quiere invitar
   * @param {ViewerType} userType Indica si el usuario es creador, suscriptor, ...
   * @return {any} El usuario destino y el tipo de invitacion
   */
  async inviteUser(target: User, userType?: ViewerType): Promise<any | undefined> {
    this._modal = await this.modalCtrl.create({
      cssClass: "fit-modal floating-modal",
      component: InviteComponent,
      componentProps: { 
        user: target,
        mode: InviteMode.Send,
        viewerType: userType
      },
      mode: "ios",
    });
    this._modal.present();

    const { data, role } = await this._modal.onWillDismiss();

    if ( role === 'confirm' ) {
      return data
    } else {
      return undefined
    }
  }

  /**
   * Muestra popup para preguntar si quiere acceder a la invitacion
   * @param {User} sender Usuario que ha mandado la invitacion
   * @param {ViewerType} userType Indica si el usuario es creador, suscriptor, ...
   * @return {any} El usuario destino y el tipo de invitacion
   */
  async showInvitation(sender: User, type: InviteType, userType?: ViewerType): Promise<any | undefined> {
    this._modal = await this.modalCtrl.create({
      cssClass: "fit-modal floating-modal",
      component: InviteComponent,
      componentProps: { 
        user: sender,
        mode: InviteMode.Reply,
        type: type,
        viewerType: userType
      },
      mode: "ios",
    });
    this._modal.present();

    const { data, role } = await this._modal.onWillDismiss();

    if ( role === 'confirm' ) {
      return data
    } else {
      return undefined
    }
  }

  /*
   ************* Report 
   */

  /**
   * Muestra popup para poner una denuncia
   * @param {number} identifier Identificador
   * @param {boolean} contentReport Indica si se esta denunciando un contenido (true) o un usuario (false)
   * @param {ContentType} type Tipo de contenido (si procede)
   * @param {boolean} eventReport Indica si se esta denunciando un evento
   * @return {any} Si se ha hecho la denuncia
   */
  async report(identifier: number, type: ContentType = undefined, contentReport: boolean = false, eventReport: boolean = false): Promise<any | undefined> {
    this._modal = await this.modalCtrl.create({
      cssClass: "fit-modal floating-modal report-modal",
      component: ReportComponent,
      componentProps: { 
        identifier,
        type,
        contentReport,
        eventReport
      },
      mode: "ios",
    });
    this._modal.present();

    const { data, role } = await this._modal.onWillDismiss();

    if ( role === 'confirm' ) {
      return data
    } else {
      return undefined
    }
  }

  /*
   ************* Share 
   */

  /**
   * Comparte una URL
   * @param {string} title Set a title for any message. This will be the subject if sharing to email
   * @param {string} url Set a URL to share, can be http, https or file:// URL
   */
  shareUrl(text: string, url: string, title: string = ''): Promise<boolean> {
    // Check if can share
    return new Promise((resolve) => 
      Share.canShare()
      .then(async (canShare: CanShareResult) => {
        if ( canShare.value ) {
          // Native share
          if ( this.platform.is('ios') )
            await Share.share({ title, text: `${text} ${url}` });
          else
            await Share.share({ title, text, url });

          resolve(true);
        } else {
          // Copy to clipboard
          navigator.clipboard.writeText(url).then(() => {
            this.events.publish(environment.EVENTS.ALERT_TOAST_NOTICE, 'URL copiada en el portapapeles.');
            resolve(true);
          }, (err) => {
            resolve(false);
            this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error al copiar la URL, vuelve a intentarlo');
          });
        }
      })
    )
  }

  /**
   * Comparte un fichero
   * @param {string} file file:// URL of the files to be shared. Only supported on iOS and Android.
   */
  shareFile(file: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // Check if can share
      Share.canShare()
      .then(async (canShare: CanShareResult) => {
        if ( canShare.value ) {
          // Native share
          try {
            await Share.share({ url: file });
            resolve(true);
          } catch(e) {
            if ( e.message?.includes('canceled') ) {
              resolve(false);
            } else {
              reject();
            }
          }
        } else {
          reject();
        }
      })
    })
  }

  /**
   * Comprueba si hay nuevos eventos de gamificacion. La comprobacion tiene un enfriamiento
   * de 5 minutos
   */
  async checkGamificationEvents() {
    const ageData = await Preferences.get({key: `GAMIFICATION_AGE`});
    const currentDate = new Date().getTime();
    let shouldRequest: boolean = false;

    if ( ageData.value ) {
      const age = Number(ageData.value);

      // If age is > 5 minutes
      if ( currentDate - Number(age) > 300000) {
        shouldRequest = true;
      } else {
        shouldRequest = false;
      }
    } else {
      shouldRequest = true;
    }
    
    if ( shouldRequest ) {
      // Store last request
      Preferences.set({key: `GAMIFICATION_AGE`, value: currentDate.toString()});

      this.http.get<any>(this.generateAbsoluteAPIURL(this.gamificationEventsUrl)).subscribe(
        data => {
          if ( data.done && data.data && data.data.gamifications ) {
            data.data.gamifications.forEach(item => {
              this._gamificationEvents.push(item);
            })

            this.processGamificationEvent();
          } else if ( !data.done ) {
            Sentry.captureMessage(`[GAMIFICATION] check gamification events request result with done=false`, "warning");
          } else if ( !data.data || !data.data.gamifications ) {
            Sentry.captureMessage(`[GAMIFICATION] check gamification events request result with wrong data structure`, "error");
          }
        }, error => {
          Sentry.captureMessage(`[GAMIFICATION] check gamification events request failure`, "error");
        }
      );
    }
  }

  /**
   * Procesa la cola de eventos de gamificacion y ejecuta la accion correspondiente
   * a cada evento
   */
  processGamificationEvent() {
    if ( this._gamificationEvents.length ) {
      const event = this._gamificationEvents.shift();

      switch ( event.type ) {
        case GamificationType.Donation: /* TODO: anadir popup */ this.processGamificationEvent(); break;
        case GamificationType.Invitation: this.openNewInvitationRegister(event.username); break;
        case GamificationType.Reputation: this.openNewBadgeModal(event.reputation_level); break;
        case GamificationType.Pro: this.openProUpgdareModal(); break;
      }
    }
  }
}
