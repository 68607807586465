<div class="input" (click)="showDropdown()">
  <div class="text">
    <span class="placeholder" *ngIf="option === undefined">{{placeholder}}</span>
    <span class="selected" *ngIf="option !== undefined">{{option.value}}</span>
  </div>
  <div class="icon">
    <ion-icon name="chevron-down-outline" [ngClass]="{'rotated': dropdownVisible}"></ion-icon>
  </div>
</div>

<div class="backdrop" *ngIf="dropdownVisible" @inOutFade (click)="closeDropdown()"></div>

<div class="dropdown" *ngIf="dropdownVisible" @inOutDropdown>
  <div class="element" *ngFor="let option of options" (click)="select(option.id)">
    <div class="icon">
      <ion-icon name="ellipse-outline" *ngIf="!option.selected"></ion-icon>
      <ion-icon name="checkmark-circle" *ngIf="option.selected" color="teal"></ion-icon>
    </div>
    <span>{{option.value}}</span>
  </div>
</div>