import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {CreatefabButtonComponent} from './createfab-button.component';


@NgModule({
  declarations: [
    CreatefabButtonComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
  ],
  exports: [
    CreatefabButtonComponent
  ]
})
export class CreatefabModule { }
