import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Platform, ModalController, NavController } from '@ionic/angular';
import { SmsRetriever } from '@awesome-cordova-plugins/sms-retriever/ngx';
import { environment } from '../../../../environments/environment';

import { BaseComponent } from '../base.component';

import { InvitePage } from '../invite/invite.page';

import { AuthService } from '../../../services/auth.service';
import { Events } from '../../../services/events.service';
import { AnalyticsService } from '../../../services/analytics.service';

import * as Sentry from "@sentry/capacitor";

@Component({
  selector: 'app-sms',
  templateUrl: './sms.page.html',
  styleUrls: ['./sms.page.scss', '../base.component.scss', '../../../../theme/field.scss'],
})
export class SmsPage extends BaseComponent {

  @Input() username: string = '';
  @Input() password: string = '';
  @Input() phone: string = '';
  @Input() country: any = {};
  @Input() userId: string = '';

  resendSmsCountdown: number = 0;
  countdownInterval: any = undefined;
  resendCount: number = 0;
  sendingSms: boolean = false;

  code: string = '';

  constructor(
    private smsRetriever: SmsRetriever,
    public platform: Platform,
    public modalCtrl: ModalController,
    public navCtrl: NavController,
    public cdRef: ChangeDetectorRef,
    public authService: AuthService,
    public events: Events,
    public analyticsService: AnalyticsService
  ) {
    super(platform, modalCtrl, navCtrl, cdRef, authService, events, analyticsService);
  }

  ngOnInit() {
    this.countdownInterval = setInterval(() => {
      if (this.resendSmsCountdown > 0) {
        this.resendSmsCountdown--;
      }
    }, 1000);

    this.startSmsWatching();
  }

  ngOnDestroy() {
    clearInterval(this.countdownInterval);
  }

  ionViewDidEnter() {
    this.analyticsService.setScreenName('register_sms');
  }

  startSmsWatching() {
    if ( this.platform.is('android') ) {
      this.smsRetriever.startWatching()
        .then((res: any) => {
          try {
            const message = res.Message;

            if (message != -1) {
              const messageParsed = message.replace("  ", " ").replace("\n", " ");
              const split = messageParsed.split(" ");
              const dotIndex = split.findIndex(item => item.includes(":"));

              this.code = split[dotIndex + 1];
              this.cdRef.detectChanges();
            }
          } catch (e) {};
        })
        .catch((error: any) => console.error(error));
    }
  }

  /**
   * Check user inserted OTC
   */
  checkSmsCode() {
    this.loading = true;

    this.authService.checkSmsCode(this.code, this.phone).subscribe(
      (res) => {
        if (res.done) {
          if (this.username && this.password) {
            // If user and password available, login and go to home screen            
            this.events.publish((environment.EVENTS.ALERT_TOAST_NOTICE), 'Codigo válido.');
            this.login(this.username, this.password);
            this.goToPage(InvitePage, {username: this.username}, true);
          } else {
            // If user and password not available, got to login screen         
            this.events.publish((environment.EVENTS.ALERT_TOAST_NOTICE), 'Codigo válido. Ahora puedes iniciar sesión.');
            this.exit();
          }
        } else {
          this.error.code = true;
          Sentry.captureMessage(`[REGISTER] wrong OTC code, user ${this.username} - ${this.userId}`, "warning");
        }

        this.loading = false;
      }, (err) => {
        this.loading = false;
        this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error. Inténtalo más tarde o contacta con soporte');
        Sentry.captureMessage(`[REGISTER] OTC code check endpoint fail, user ${this.username} - ${this.userId}`, "error");
      }
    );
  }

  /**
   * Call API to resend a new OTC
   */
  resendSms() {
    this.sendingSms = true;
    this.resendCount++;

    this.authService.sendSmsValidation(this.phone, this.userId).subscribe(
      sms => {
        if (sms.done) {
        this.startSmsWatching();

          if (this.resendCount > 2) {
            this.resendSmsCountdown = 300;
          }
          else {
            this.resendSmsCountdown = 60;
          }
        } else {
          this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR,
            'Ha ocurrido un error al enviar el sms. Inténtalo más tarde o contacta con soporte');
          Sentry.captureMessage(`[REGISTER] sending SMS OTC code, response OK but done=false user (resend) ${this.username} - ${this.userId} with message ${sms.msg}`, "error");
        }

        this.sendingSms = false;
      }, error => {
        this.sendingSms = false;
        Sentry.captureMessage(`[REGISTER] sending SMS OTC code, user (resend) ${this.username} - ${this.userId}`, "error");
      }
    );
  }
}
