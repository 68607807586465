import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {WebFooterComponent} from './web-footer.component';
import {IonicModule} from '@ionic/angular';


@NgModule({
  declarations: [
    WebFooterComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
  ],
  exports: [
    WebFooterComponent
  ]
})
export class WebFooterModule { }
