import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators';
import { Preferences } from '@capacitor/preferences';

import { JsonApiResponse } from '../interfaces/JsonApiResponse';

import { ExtrimState } from '../enums/extrim-state';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExtrimService {

  extrimVoteUrl = 'api/auth/xtrimrace/vote';
  getRankingUrl = 'api/auth/xtrimrace/ranking';

  private _ranking: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(undefined);
  ranking = this._ranking.asObservable();

  PRE_RACE_DATE = Date.UTC(2024, 0, 12, 20, 0, 0);  // 12/01/2024 21:00:00 GMT+1
  PRE_LIVE_RACE_DATE = Date.UTC(2024, 0, 13, 7, 0, 0); // 13/01/2024 08:00:00 GMT+1
  LIVE_RACE_DATE = Date.UTC(2024, 0, 13, 14, 0, 0); // 13/01/2024 15:00:00 GMT+1
  POST_RACE_DATE = Date.UTC(2024, 0, 13, 20, 0, 0); // 13/01/2024 21:00:00 GMT+1

  constructor(
    protected http: HttpClient
  ) {}

  public generateAbsoluteAPIURL($tail: string) {
    return environment.API_BASE_URL + $tail;
  }

  alreadyVoted(): boolean {
    const raking = this._ranking.getValue();

    if ( raking ) {
      return raking.some(item => item.voted);
    } else {
      return false;
    }
  }

  getCurrentState(): ExtrimState {
    const date = new Date().getTime();

    if ( date < this.PRE_RACE_DATE ) {
      return ExtrimState.BeforeRace;
    } else if ( date < this.PRE_LIVE_RACE_DATE ) {
      return ExtrimState.PreLive;
    } else if ( date < this.LIVE_RACE_DATE ) {
      return ExtrimState.Live;
    } else if ( date < this.POST_RACE_DATE ) {
      return ExtrimState.AfterRace;
    } else {
      return ExtrimState.Ended;
    }
  }

  votePilot( pilotId: number ) {
    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.extrimVoteUrl), {
      competition_id: this.getCurrentState() === ExtrimState.BeforeRace || this.getCurrentState() === ExtrimState.PreLive ? 1 : 2,
      driver_id: pilotId
    });
  }

  getRanking() {
    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.getRankingUrl), {
      competition_id: this.getCurrentState() === ExtrimState.BeforeRace || this.getCurrentState() === ExtrimState.PreLive ? 1 : 2,
    }).pipe(tap((data) => {
      if ( data?.done ) {
        // @ts-ignore
        this._ranking.next(Object.values(data.ranking));
      }
    }));
  }
}
