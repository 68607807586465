<div class="container-video-search">
    <div class="video-c" [routerLink]="['/', 'publications', 'view', video.id]">
        <div class="container-video-r">
            <div class="red-direct" *ngIf="video.isEmitting">
                <span class="icon-r"></span>
                <div class="directo">Directo</div>
            </div>
            <img handleError class="publication-thumbnail" src="https://estrim.kimerikal.com/img/publications/{{video.id}}/{{video.thumbnail}}">
            <div class="container-cant-user">
                <span class="icon-user"></span>
                <span class="cant">{{video.numParticipants}}</span>
            </div>
        </div>
    </div>
    <div class="info-c">
        <div class="container-profile">
            <span class="img-profile" routerLink="user/{{video.author.username}}" [ngStyle]="profilePictureStyleFor(video.author)"></span>
            <span class="name-c">{{video.title}}</span>
        </div>
        <p>{{video.description}}</p>
    </div>
</div>
