import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalController } from '@ionic/angular';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { Events } from './events.service';
import { User } from '../interfaces/user';
import { Event } from '../interfaces/event';
import { Follower } from '../interfaces/follower';
import { Collaborator } from '../interfaces/collaborator';
import { JsonApiResponse } from '../interfaces/JsonApiResponse';
import { EventCollaborator } from '../interfaces/eventCollaborator';
import { Publication } from '../interfaces/publication';
import { BehaviorSubject } from 'rxjs';
import { MediaService } from './media.service';
import { map, skipWhile, take } from 'rxjs/operators';
import { ContentType } from '../enums/content-type';
import { Preferences } from '@capacitor/preferences';
import { CacheService } from './cache.service';
import { ProfileVerificationModalComponent } from '../components/profile-verification-modal/profile-verification-modal.component';

import { CacheData } from '../enums/cache-data';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends CacheService {

  saveProfileElementUrl = 'api/auth/user/save-profile-element';
  uploadProfileImgUrl = 'api/auth/user/upload-profile-img';
  getMySubscriptionsUrl = 'api/auth/aforum/subscriptions';
  getFollowersUrl = 'api/auth/user/get_followers2';
  getUsersFollowedUrl = 'api/auth/user/get_users_followed2';
  getCollaboratorsUrl = 'api/auth/user/get_collaborators2';
  getEventCollaboratorsUrl = 'api/auth/user/get_event_collaborators';
  getCollaborationsUrl = 'api/auth/user/get_collaborations';
  getCollaboratorInvitationsUrl = 'api/auth/user/get_collaborator_invitations';
  updateProfileInfoUrl = 'api/auth/user/edit-profile-info';
  followUserUrl = 'api/v2/auth/user/follow_user';
  inviteCollaboratorUrl = 'api/auth/user/collaborator/invite';
  removeCollaboratorUrl = 'api/auth/user/collaborator/remove';
  answerCollabInvitationUrl = 'api/auth/user/answer_collab_invitation';
  answerEventCollabInvitationUrl = 'api/auth/user/answer_eventcollab_invitation';
  getCollaboratorFromNotificationUrl = 'api/auth/user/get_collaborator_from_notification';
  blockUserUrl = 'api/auth/user/block_user';
  blockPublicationUrl = 'api/auth/user/block_publication';
  blockEventUrl = 'api/auth/user/block_event';
  unblockUserUrl = 'api/auth/user/unblock_user';
  unblockPublicationUrl = 'api/auth/user/unblock_publication';
  unblockEventUrl = 'api/auth/user/unblock_event';
  listBlockedUsersUrl = 'api/auth/user/list_block_user';
  listBlockedPublicationsUrl = 'api/auth/user/list_block_publication';
  listBlockedEventsUrl = 'api/auth/user/list_block_event';
  complaintUser = 'api/auth/user/complaint_user';
  listRegisteredBlocked = 'api/auth/user/list_registered_blocked';
  removeUserSuggestionUrl = 'api/auth/user/remove_user_suggestion';

  profileInfo = {
    id: 0,
    name: '',
    surname: '',
    username: '',
    biography: '',
    email: '',
    phone: '',
    imgProfile: '',
    profileCover: '',
    remainingInvitations: 0,
    privateMessages: false,
    coverIsVideo: false,
    isPro: false,
    facebook_account: '',
    instagram_account: '',
    youtube_account: '',
  };

  private blockedUsersCache: User[] = [];
  private isBlockedUsersCacheValid = false;

  private _collaborators: BehaviorSubject<{ accepted: Collaborator[]; pending: Collaborator[] }> = new BehaviorSubject<{ accepted: Collaborator[]; pending: Collaborator[] }>(undefined);
  collaborators = this._collaborators.asObservable();

  private _followers: BehaviorSubject<Follower[]> = new BehaviorSubject<Follower[]>([]);
  followers = this._followers.asObservable();

  private _followed: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
  followed = this._followed.asObservable();

  private _followStatusList: {userId: number, following: boolean}[] = [];

  CHAT_CACHE_LIST_KEY = 'CHAT_CACHE_LIST';
  CHAT_CACHE_REQUEST_LIST_KEY = 'CHAT_CACHE_REQUEST_LIST';

  MY_PROFILE_CACHE_COLLABORATORS_KEY = 'MY_PROFILE_CACHE_COLLABORATORS';
  MY_PROFILE_CACHE_FOLLOWERS_KEY = 'MY_PROFILE_CACHE_FOLLOWERS';
  MY_PROFILE_CACHE_FOLLOWED_KEY = 'MY_PROFILE_CACHE_FOLLOWED';

  constructor(
    protected http: HttpClient, 
    private authService: AuthService, 
    private events: Events, 
    private mediaService: MediaService,
    private modalCtrl: ModalController
  ) {
    super(http);

    this.events.subscribe(environment.EVENTS.USER_LOGOUT, () => {
      this.invalidateCacheData(CacheData.MyCollaborators, true);
      this.invalidateCacheData(CacheData.MyFollowers, true);
      this.invalidateCacheData(CacheData.MyFollowed, true);

      this.blockedUsersCache = [];
      this.isBlockedUsersCacheValid = false;
    });
    this.events.subscribe(environment.EVENTS.USER_FOLLOW, () => {
      this.invalidateCacheData(CacheData.MyCollaborators);
      this.invalidateCacheData(CacheData.MyFollowers);
      this.invalidateCacheData(CacheData.MyFollowed);
    });
    this.events.subscribe(environment.EVENTS.USER_UPDATED, (data) => {
      this.saveProfileInfo(data);
    });
    this.authService.getUserDetails().then((data) => {
      this.profileInfo = {
        id: Number(data?.id),
        name: data?.firstname,
        surname: data?.surname,
        username: data?.username,
        biography: data?.biography,
        email: data?.email,
        phone: data?.phone,
        imgProfile: this.mediaService.generateImgProfileURL(data?.id, data?.imgProfile, true),
        profileCover: this.mediaService.generateImgProfileURL(data?.id, data?.profileCover, false),
        privateMessages: data?.privateMessages,
        remainingInvitations: data?.remainingInvitations,
        coverIsVideo: data?.profileCoverIsVideo,
        isPro: data?.isPro,
        facebook_account: data?.facebook_account,
        instagram_account: data?.instagram_account,
        youtube_account: data?.youtube_account,
      };
    });

    this.events.subscribe(environment.EVENTS.ON_LOGIN_SCREEN, () => {
      this.profileInfo = {
        id: 0,
        name: '',
        surname: '',
        username: '',
        biography: '',
        email: '',
        phone: '',
        imgProfile: '',
        profileCover: '',
        remainingInvitations: 0,
        privateMessages: false,
        coverIsVideo: false,
        isPro: false,
        facebook_account: '',
        instagram_account: '',
        youtube_account: '',
      };

      this.invalidateCacheData(CacheData.MyCollaborators, true);
      this.invalidateCacheData(CacheData.MyFollowers, true);
      this.invalidateCacheData(CacheData.MyFollowed, true);
    });

    this.loadCacheData(CacheData.MyCollaborators);
    this.loadCacheData(CacheData.MyFollowers);
    this.loadCacheData(CacheData.MyFollowed);

    this.authService.watchUser()
      .pipe(
        skipWhile(data => !data),
        take(1))
      .subscribe((user: User) => {
        this.checkCacheDataUpdate(CacheData.MyFollowed);
      })
  }
  
  protected fetchCacheData(type: CacheData) {
    this.authService.watchUser()
      .pipe(
        skipWhile(data => !data),
        take(1))
      .subscribe((user: User) => {
        switch ( type ) {
          case CacheData.MyCollaborators: 
            this.getCollaborators(user.id).subscribe();
          break;
          case CacheData.MyFollowers:  
            this.getFollowers(user.id).subscribe();
          break;
          case CacheData.MyFollowed:  
            this.getUsersFollowed(user.id).subscribe();
          break;
        }
      });
  }
  protected getCacheDataObservable(type: CacheData): BehaviorSubject<any> {
    switch ( type ) {
      case CacheData.MyCollaborators: return this._collaborators; break;
      case CacheData.MyFollowers: return this._followers; break;
      case CacheData.MyFollowed: return this._followed; break;
    }
  }
  protected getCacheDataObservableKey(type: CacheData): string {
    switch ( type ) {
      case CacheData.MyCollaborators: return this.MY_PROFILE_CACHE_COLLABORATORS_KEY; break;
      case CacheData.MyFollowers: return this.MY_PROFILE_CACHE_FOLLOWERS_KEY; break;
      case CacheData.MyFollowed: return this.MY_PROFILE_CACHE_FOLLOWED_KEY; break;
    }
  }

  public generateAbsoluteAPIURL($tail: string) {
    return environment.API_BASE_URL + $tail;
  }

  public generateImgProfileURL(userID: string, filename: string, profile: boolean) {
    let url: string = '';

    if (!filename || filename === 'null')
    {
      url = profile
        ? 'assets/no-photo.jpg'
        : 'assets/no-banner.jpg';
    }
    else
    {
      url = filename.includes('cdn-estrim')
        ? filename
        : `${environment.CDN_BASE_URL}img/profile/${userID}/${filename}`
    }

    return url;
  }

  saveProfileElement(data: { elementId: string; elementValue: any; userId?: any }) {
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(this.generateAbsoluteAPIURL(this.saveProfileElementUrl), data)
        .subscribe(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  uploadProfileImg(file: File, username: string, imgTarget): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('filename', file.name);
    formData.append('username', username);
    formData.append('imgTarget', imgTarget);

    return this.http.post(this.generateAbsoluteAPIURL(this.uploadProfileImgUrl), formData);
  }

  saveProfileInfo(data) {
    this.profileInfo = {
      id: Number(data.id),
      name: data.firstname,
      surname: data.surname,
      username: data.username,
      biography: data.biography,
      email: data.email,
      phone: data.phone,
      imgProfile: this.mediaService.generateImgProfileURL(data.id, data.imgProfile, true),
      profileCover: this.mediaService.generateImgProfileURL(data.id, data.profileCover, false),
      privateMessages: data.privateMessages,
      remainingInvitations: data.remainingInvitations,
      coverIsVideo: data.profileCoverIsVideo,
      isPro: data.isPro,
      facebook_account: data.facebook_account,
      instagram_account: data.instagram_account,
      youtube_account: data.youtube_account,
    };
  }

  togglePrivateMessages(activated: boolean) {
    this.authService.getUserDetails().then((user) => {
      const data =
        {
          userId: user.id,
          elementId: 'privateMessages',
          elementValue: activated
        };
      this.saveProfileElement(data);
    });
  }

  getFollowers(userId: number = 0) {
    return this.http.post<Follower[]>(this.generateAbsoluteAPIURL(this.getFollowersUrl),
      {
        userId
      }
    ).pipe(map((res) => {
      if (res) {
        // If its my user, store the data
        if ( !this.authService.user ) {
          console.warn('Local user not ready');
        } else if ( userId === this.authService.user?.id ){
          this.setCacheData(CacheData.MyFollowers, res)
        }
      }

      return res;
    }));
  }

  getUsersFollowed(userId: number, offset: number = 0, limit: number = 50): Observable<JsonApiResponse<User[]>> {
    return this.http.post<JsonApiResponse<User[]>>(this.generateAbsoluteAPIURL(this.getUsersFollowedUrl),
      {
        userId,
        offset,
        limit
      }
    ).pipe(map((res) => {
      if (res.done) {
        // If its my user, store the data
        if ( !this.authService.user ) {
          console.warn('Local user not ready');
        } else if ( userId === this.authService.user?.id ){
          this.setCacheData(CacheData.MyFollowed, res.data)
        }
      }

      return res;
    }));
  }

  getCollaborators(userId: number) {
    return this.http.post<JsonApiResponse<{ accepted: Collaborator[]; pending: Collaborator[] }>>(
      this.generateAbsoluteAPIURL(this.getCollaboratorsUrl),
      {
        userId
      }
    ).pipe(map((res) => {
      if (res.done) {
        // If its my user, store the data
        if ( !this.authService.user ) {
          console.warn('Local user not ready');
        } else if ( userId === this.authService.user?.id ){
          this.setCacheData(CacheData.MyCollaborators, res.data);
        }
      }

      return res;
    }));
  }

  getCollaborations(userId: number) {
    return this.http.post<JsonApiResponse<Collaborator[]>>(this.generateAbsoluteAPIURL(this.getCollaborationsUrl),
      {
        userId
      });
  }

  getCollaboratorInvitations(userId: number) {
    return this.http.post<JsonApiResponse<{ userCollabs: Collaborator[]; eventCollabs: EventCollaborator[] }>>(
      this.generateAbsoluteAPIURL(this.getCollaboratorInvitationsUrl),
      {
        userId
      });
  }

  getEventCollaborators(eventId: number) {
    return this.http.post<EventCollaborator[]>(this.generateAbsoluteAPIURL(this.getEventCollaboratorsUrl),
      {
        eventId
      });
  }

  follow(userId: number, follow?: boolean) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.followUserUrl), { userId, follow})
      .pipe(map((res) => {
        if (res.done) {
          this.invalidateCacheData(CacheData.MyFollowed);
          this.fetchCacheData(CacheData.MyFollowed);

          this.events.publish(environment.EVENTS.USER_FOLLOW, {
            userId,
            following: follow !== undefined ? follow : true
          });

          const found = this._followStatusList.find(item => item.userId === userId);
          if ( found ) {
            found.following = follow !== undefined ? follow : true;
          } else {
            this._followStatusList.push({userId: userId, following: follow !== undefined ? follow : true});
          }
        }
        return res;
      }));
  }

  getFollowingStatusByPublication(publication: Publication): boolean {
    const found = this._followStatusList.find(item => item.userId === publication.author.id);
  
    if ( found )
      return found.following;
    else
      return publication.following;
  }
  getFollowingStatusByUser(user: User): boolean {
    const found = this._followed.getValue()?.find(item => item.id === Number(user.id));

    if ( found )
      return true;
    else
      return user.following;
  }

  editProfile(data: { biography: any; aboutMe: any; email: string; username: string; firstname: string, privateMessages: number }) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.updateProfileInfoUrl), data)
      .pipe(map((res) => {
        if (res.done) {
          this.cleanCachedData();
          this.events.publish(environment.EVENTS.USER_MODIFIED, this.authService.user);
        }

        return res;
      }));
  }

  inviteCollaborator(userId: number) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.inviteCollaboratorUrl),
      {
        userId
      });
  }

  removeCollaborator(userId: number) {
    return this.http.get<any>(this.generateAbsoluteAPIURL(`${this.removeCollaboratorUrl}/${userId}`))
      .pipe(map((res) => {
        this.fetchCacheData(CacheData.MyCollaborators);
        return res;
      }));
  }

  answerCollabInvitation(accept: boolean, collaborationId: number, isEvent: boolean) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.answerCollabInvitationUrl),
      {
        accept,
        collaborationId,
        isEvent
      });
  }

  answerEventCollabInvitation(accept: number, userId: number, collaborateEventId: number) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.answerEventCollabInvitationUrl),
      {
        accept,
        userId,
        collaborateEventId
      });
  }

  getCollaboratorFromNotification(collaborationId: string, isEvent: boolean) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.getCollaboratorFromNotificationUrl),
      {
        collaborationId,
        isEvent
      });
  }

  blockUser(id: number) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.blockUserUrl), {
      user_id: id,
    }).pipe(map((res) => {
      if (res.done) {
        this.isBlockedUsersCacheValid = false;
      }
      return res;
    }));
  }

  unblockUser(id: number) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.unblockUserUrl), {
      user_id: id,
    }).pipe(map((res) => {
      if (res.done) {
        this.isBlockedUsersCacheValid = false;
      }
      return res;
    }));
  }

  blockPublication(type: ContentType, id: number) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.blockPublicationUrl), {
      publication_id: id,
    })
    .pipe(map ((res) => {
      if ( res.done ) {
        this.events.publish(environment.EVENTS.PUBLICATION_BLOCKED, {type, id});
      }
      return res;
    }));
  }

  unblockPublication(type: ContentType, id: number) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.unblockPublicationUrl), {
      publication_id: id,
    }).pipe(map ((res) => {
      if ( res.done ) {
        this.events.publish(environment.EVENTS.PUBLICATION_UNBLOCKED, {type, id});
      }
      return res;
    }));
  }

  blockEvent(id: number) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.blockEventUrl), {
      event_id: id,
    });
  }

  unblockEvent(id: number) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.unblockEventUrl), {
      event_id: id,
    });
  }

  reportUser(id: number, reason: number, description: string) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.complaintUser), {
      user_id: id,
      reason: reason,
      description: description
    });
  }

  getMyBlockedUsers(force: boolean): Observable<User[]> {
    if (this.isBlockedUsersCacheValid && !force) {
      return of(this.blockedUsersCache);
    }
    return this.http.post<JsonApiResponse<User[]>>(this.generateAbsoluteAPIURL(this.listBlockedUsersUrl), {}).pipe(map((res) => {
      if (res.done) {
        this.isBlockedUsersCacheValid = true;
        this.blockedUsersCache = res.data;
      }
      return res.data;
    }));
  }

  getBlockedPublications() {
    return this.http.post<JsonApiResponse<Publication[]>>(this.generateAbsoluteAPIURL(this.listBlockedPublicationsUrl), {});
  }

  getBlockedEvents() {
    return this.http.post<JsonApiResponse<Event[]>>(this.generateAbsoluteAPIURL(this.listBlockedEventsUrl), {});
  }

  removeUserSuggestion(userId) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.removeUserSuggestionUrl), {
      userId
    });
  }

  private cleanCachedData() {


    Preferences.keys().then(({keys}) => {
      keys.forEach(key => {
        if (key.includes('MYPROFCACHED') || key.includes('MY_PROFILE_CACHE')) {
          Preferences.remove({key});
        }
      });
    });

    Preferences.keys().then(({keys}) => {
      keys.forEach(key => {
        if (key.includes('CHAT_CACHE')) {
          Preferences.remove({key});
        }
      });
    });
  }

  checkUserValidated(): boolean {
    if ( !this.authService.validated ) {
      this.modalCtrl.create({
        cssClass: "fit-modal floating-modal",
        component: ProfileVerificationModalComponent,
        mode: "ios",
      }).then((modal) => {
        modal.present();
        modal.onDidDismiss().then(() => {
          // this.closeWelcome();
        });
      });

      return false;
    }

    return true;
  }
}
