<ion-header>
  <ion-toolbar color="white" class="header-edit-profile">
    <ion-buttons slot="start" [ngClass]="{'centered': centered}">
      <div class="ion-activatable ripple-parent">
        <ion-icon name="chevron-back" (click)="goBack()" [ngStyle]="{'color': textColor}"></ion-icon>
        <ion-ripple-effect type="unbounded"></ion-ripple-effect>
      </div>
    </ion-buttons>
    <ion-title [ngClass]="{'centered': centered}" [ngStyle]="{'color': textColor}">{{title}}</ion-title>
  </ion-toolbar>
</ion-header>
