import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Preferences } from '@capacitor/preferences';
import { BehaviorSubject } from 'rxjs';

import { KService } from './k.service';

import { CacheData } from '../enums/cache-data';

@Injectable({
  providedIn: 'root'
})
export abstract class CacheService extends KService {

  constructor( protected http: HttpClient ) {
    super(http);
  }

  protected abstract getCacheDataObservable(type: CacheData | string): BehaviorSubject<any>;
  protected abstract getCacheDataObservableKey(type: CacheData | string): string;
  protected abstract fetchCacheData(type: CacheData | string);

  /**
   * Store data
   * @param key 
   * @param value 
   */
  async storeData(key, value) {
    await Preferences.set({key, value: JSON.stringify(value)});

    const currentDate = new Date().getTime();
    await Preferences.set({key: `${key}_AGE`, value: JSON.stringify(currentDate)});
    await Preferences.set({key: `${key}_VALID`, value: JSON.stringify(true)});
  }

  /**
   * Get stored data
   * @param key 
   * @returns 
   */
  async getData(key): Promise<any> {
    const data = await Preferences.get({key});

    if ( data.value ) {
      try {
        const parsedData = JSON.parse(data.value);
        return parsedData;
      } catch(e) {
        return undefined
      }
    } else {
      return undefined;
    }
  }

  /**
   * Clean specific data
   * @param key 
   */
  async cleanData(key) {
    await Preferences.remove({key});
  }

  /**
   * Invalidate specific data
   * @param key 
   */
  async invalidateData(key) {
    await this.cleanData(`${key}_VALID`)
  }

  /**
   * Check if data should be fetched again
   * @param key 
   * @returns 
   */
  async dataShouldBeUpdated(key) {
    const age = await this.getData(`${key}_AGE`);
    const valid = await this.getData(`${key}_VALID`);

    if ( age && valid ) {
      const currentDate = new Date().getTime();

      // If age is > 5 minutes
      if ( currentDate - Number(age) > 300000) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  /**
   * Load data from storage and fill observable
   * @param type Cache data type
   */
  loadCacheData(type: CacheData | string) {
    this.getData(this.getCacheDataObservableKey(type))
      .then(data => {
        this.getCacheDataObservable(type)
        .next(data);
      })
  }

  /**
   * Store data to cache and fill observable
   * @param type Cache data type
   * @param data Data to set
   */
  async setCacheData(type: CacheData | string, data: any) {
    this.getCacheDataObservable(type).next(data);
    await this.storeData(this.getCacheDataObservableKey(type), data);
  }

  /**
   * Gets current data from cache
   * @param type Cache data type
   * @returns Data
   */
  getCacheData(type: CacheData | string): any {
    return this.getCacheDataObservable(type).value; 
  }

  /**
   * Returns observable for desired data type and check
   * if data should be updated, if so, it updates the data
   * and then updates observable
   * @param type Cache data type
   * @param force Force fetch
   * @returns Observable for data
   */
  watchCacheData(type: CacheData | string, force: boolean = false): BehaviorSubject<any> {
    this.checkCacheDataUpdate(type, force);
    return this.getCacheDataObservable(type);
  }

  /**
   * Check if data should be updated and fetch data
   * @param type Cache data type
   * @param force Force fetch
   */
  checkCacheDataUpdate(type: CacheData | string, force: boolean = false) {
    if ( !force ) {
      this.dataShouldBeUpdated(this.getCacheDataObservableKey(type))
        .then(should => {
          if ( should ) {
            this.fetchCacheData(type);
          }
        })
    } else {
      this.fetchCacheData(type);
    }
  }

  /**
   * Invalidates cache for desired cache
   * @param type Cache data type
   * @param cleanData If data should be removed
   */
  invalidateCacheData(type: CacheData | string, cleanData: boolean = false) {
    this.invalidateData(this.getCacheDataObservableKey(type));

    if ( cleanData ) {
      this.getCacheDataObservable(type).next(undefined);
      this.cleanData(this.getCacheDataObservableKey(type));
    }
  }
}
