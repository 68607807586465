import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ProTagComponent} from './pro-tag.component';


@NgModule({
  declarations: [
    ProTagComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ProTagComponent
  ]
})
export class ProTagModuleModule { }
