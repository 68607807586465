<ion-tabs *ngIf="!fullScreen && isLoggedIn()">
  <ion-tab-bar slot="bottom" class="tab-bar">
    <!-- <ion-tab-button routerLink="/" routerDirection="back" [routerAnimation]="slideAnimation"> -->
    <ion-tab-button routerLink="/" routerDirection="back">
    <!-- <ion-tab-button tab="home"> -->
      <svg [ngClass]="currentIndex === 0 ? 'calendar-icon active' : 'calendar-icon'" class="home-footer-icon"
           xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61.79 55.7">
        <defs></defs>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Capa_1" data-name="Capa 1">
            <g id="Home">
              <polyline class="cls-10033" points="47.73 53.7 8.08 53.7 8.08 21.21"/>
              <polyline class="cls-10033" points="53.7 21.21 53.7 53.7 41.54 53.7"/>
              <polyline class="cls-10033" points="2 26.33 30.89 2 59.78 26.33"/>
            </g>
          </g>
        </g>
      </svg>

    </ion-tab-button>


    <ion-tab-button disabled="true"></ion-tab-button>


    <ion-tab-button routerLink="/profile" routerDirection="forward">
      <svg [ngClass]="currentIndex === 2 ? 'calendar-icon active' : 'calendar-icon'" class="user-footer-icon"
           xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.34 61.79">
        <defs></defs>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Capa_1" data-name="Capa 1">
            <g id="User">
              <circle class="cls-10033" cx="23.67" cy="12.83" r="10.83"/>
              <path class="cls-10033"
                    d="M2,59.79V49A14.45,14.45,0,0,1,16.45,34.5H30.89A14.45,14.45,0,0,1,45.34,49V59.79"/>
            </g>
          </g>
        </g>
      </svg>

    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
<app-createfab-button *ngIf="!fullScreen && isLoggedIn()"></app-createfab-button>
