import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Platform, ModalController, NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { SplashScreen } from '@capacitor/splash-screen';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';

import { BaseComponent } from '../base.component';

import { InstructionsPage } from '../instructions/instructions.page';
import { InvitationPage } from '../invitation/invitation.page';
import { FormPage } from '../form/form.page';

import { AuthService } from '../../../services/auth.service';
import { Events } from '../../../services/events.service';
import { StatusbarService } from "../../../services/statusbar.service";
import { AnalyticsService } from '../../../services/analytics.service';

import { RegisterType } from '../../../enums/register-type';

@Component({
  selector: 'app-pre',
  templateUrl: './pre.page.html',
  styleUrls: ['./pre.page.scss', '../base.component.scss', '../../../../theme/field.scss'],
})
export class PrePage extends BaseComponent implements OnInit {

  REGISTER_CODE = 'BETAESTRIM';

  constructor(
    private router: Router,
    private statusbar: StatusbarService,
    private activatedRoute: ActivatedRoute,
    public platform: Platform,
    public modalCtrl: ModalController,
    public navCtrl: NavController,
    public cdRef: ChangeDetectorRef,
    public authService: AuthService,
    public events: Events,
    public analyticsService: AnalyticsService
  ) { 
    super(platform, modalCtrl, navCtrl, cdRef, authService, events, analyticsService);

    // Check if register restore point is setted
    this.authService.getRegisterState().then(data => {
      if ( data !== undefined && data.registerType !== undefined ) {
        switch (data.registerType) {
          case RegisterType.Code:
            this.goToCodeInvitation();
            break;
          case RegisterType.Phone:
            this.goToPhoneInvitation();
            break;
        }
      } else {
        this.authService.deleteRegisterState();
      }
    })
  }

  ngOnInit(): void {
    const registerCode = this.activatedRoute.snapshot.paramMap.get('code');

    if ( registerCode ) {
      this.goToPage(
        InvitationPage, 
        {
          registerType: RegisterType.Code,
          code: registerCode,
          autoCheck: true
        }, 
        true
      );
    }
  }

  ionViewDidEnter() {
    this.analyticsService.setScreenName('register_pre');

    if (this.platform.is('ios')) {
      this.statusbar.setTextDark();
    }
    this.events.publish(environment.EVENTS.ON_LOGIN_SCREEN);
    
    this.platform.ready().then(async () => {
      // Oculta el splash screen despues de haber cargado la APP
      await SplashScreen.hide();
    });
  }

  /**
   * Navigate to instructions page
   */
  goToInstructions() {
    this.goToPage(InstructionsPage);
  }

  /**
   * Navigate to code invitation checker
   */
  goToCodeInvitation() {
    this.goToPage(InvitationPage, { registerType: RegisterType.Code });
  }

  /**
   * Navigate to phone invitation checker
   */
  goToPhoneInvitation() {
    this.goToPage(InvitationPage, { registerType: RegisterType.Phone });
  }

  /**
   * Register user without invitation
   */
  goToRegister() {
    this.goToPage(FormPage, 
      { 
        registerType: RegisterType.Code,
        invitation: {
          code: this.REGISTER_CODE,
          phoneValidated: false,
          phone: undefined,
          country: undefined
        }
      }
    );
  }

  /**
   * Check invitation code
   */
  checkCode() {
    this.loading = true;

    // Save current invitation code
    this.authService.setCurrentInvitationCode(this.REGISTER_CODE);
    // Check invitation code with backend
    this.authService.checkInvitationCode(this.REGISTER_CODE, null).subscribe(
      res => {

        /*
         * The loading screen should delay at least 1s for
         * a better user experience
         */

        setTimeout(() => {
          this.loading = false;

          if (res.done) {
            this.goToRegister();
          }

          this.cdRef.detectChanges();
        }, 1000);
      }, err => {
        this.loading = false;
        this.cdRef.detectChanges();
      }
    );
  }

  /**
   * Navigate to login page
   */
  goToLogin() {
    this.navCtrl.navigateForward('/login');
  }
}
