<div>
  <div
    class="description"
       #containerText
       [ngClass]="{'reduced': !viewAllDescription}">{{text}}</div>

  <div class="bottom">
    <div class="more ion-activatable ripple-parent" *ngIf="descriptionOverflows()">
      <span *ngIf="!viewAllDescription" (click)="toggleDescription()">Ver más</span>
      <span *ngIf="viewAllDescription" (click)="toggleDescription()">Ver menos</span>
      <ion-ripple-effect type="unbounded"></ion-ripple-effect>
    </div>
  </div>
</div>
