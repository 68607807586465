import {Component, Input, OnInit} from '@angular/core';
import {KComponent} from '../k.component';
import {PaymentService} from '../../services/payment.service';
import {Events} from '../../services/events.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-premium-message',
  templateUrl: './premium-message.component.html',
  styleUrls: ['./premium-message.component.scss'],
})
export class PremiumMessageComponent extends KComponent implements OnInit {
  @Input() btColor: string;
  @Input() extraTextIfSubscription: string;
  @Input() extraTextIfNoSubscription: string;
  public subscription: any;
  public subscriptionStatusLoading: boolean;

  constructor(private paymentServ: PaymentService, private events: Events) {
    super();
    this.btColor = 'secondary';
    this.extraTextIfSubscription = '';
    this.extraTextIfNoSubscription = '';
    this.subscriptionStatusLoading = true;
  }

  ngOnInit() {
    // if (!PaymentService.SUBSCRIPTION) {
    //   this.paymentServ.getStripeSubscriptionData().subscribe((response) => {
    //     if (response.done) {
    //       PaymentService.SUBSCRIPTION = response.data.subscriptions;
    //       this.subscription = PaymentService.SUBSCRIPTION;
    //     }
    //
    //     this.events.publish(environment.EVENTS.SUBSCRIPTION_STATUS, this.subscription);
    //     this.subscriptionStatusLoading = false;
    //   }, error => {
    //     this.subscriptionStatusLoading = false;
    //     console.log(error);
    //   });
    // } else {
    //   this.subscription = PaymentService.SUBSCRIPTION;
    //   this.subscriptionStatusLoading = false;
    // }
    //
    // this.events.subscribe(environment.EVENTS.SUBSCRIPTION_STATUS, (subscription) => {
    //   this.subscription = subscription;
    // });
  }
}
