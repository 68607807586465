import { Component, OnInit } from '@angular/core';
import { WebsocketService } from '../../../services/websocket.service';
import { AuthService } from '../../../services/auth.service';
import { User } from '../../../interfaces/user';
import { MediaService } from '../../../services/media.service';
import { IonRouterOutlet, ViewDidEnter, ViewDidLeave } from '@ionic/angular';
import { Router } from '@angular/router';
import { Preferences } from '@capacitor/preferences';
import { environment } from '../../../../environments/environment';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-chat-list-content',
  templateUrl: './chat-list-content.component.html',
  styleUrls: ['./chat-list-content.component.scss'],
})
export class ChatListContentComponent implements OnInit, ViewDidEnter, ViewDidLeave {

  user: User;
  fullRooms;
  rooms = [];
  cachedRooms = [];
  loading = false;
  showSearchBar = false;
  tabNumber = 1;
  mensajes = 0;

  CHAT_LIST_KEY = this.profileService.CHAT_CACHE_LIST_KEY;
  CHAT_REQUEST_LIST_KEY = this.profileService.CHAT_CACHE_REQUEST_LIST_KEY;

  constructor(
    private authService: AuthService, 
    private websocket: WebsocketService, 
    private mediaService: MediaService,
    private routerOutlet: IonRouterOutlet, 
    private profileService: ProfileService,
    private router: Router
  ) {}

  async ngOnInit() {
    const json = await Preferences.get({key: this.CHAT_LIST_KEY});
    if (json.value) {
      this.cachedRooms = JSON.parse(json.value);
    } else {
      this.loading = true;
    }
    this.authService.getUserDetails().then((data) => {
      this.user = data;
      this.initializeWebsocket();
    });
  }

  ionViewDidEnter() {
    // this.joinRooms();
  }

  ionViewDidLeave() {
    this.websocket.close();
  }

  private async initializeWebsocket() {
    await this.websocket.init();
    this.websocket.messages.subscribe(msg => {
      this.manageSocketResponse(msg);
    });
  }

  private async manageSocketResponse(msg: any) {
    switch (true) {
      case msg.hasOwnProperty('mensaje') && msg.mensaje === 'Usuario Conectado !!!':
        this.websocket.getPrivateRooms(this.user.id);
        break;
      case msg.evento === 'informacionSalasPrivadas':
        await this.getRooms(msg);
        break;
      case msg.evento === 'createSala' || msg.mensaje === 'La sala ya existe !!!':
        const room = msg.mensaje.replace('Creada Sala = ', '');
        this.websocket.joinRoom(room, 0, 99999);
        break;
      case msg.evento === 'joinSala':
        console.log('data', msg.data)
        const sala = msg.data[0].sala;
        this.rooms.find(r => r.sala === sala).lastMessage = msg.data[msg.data.length - 1];
        this.mensajes++;
        if (this.rooms.length === this.mensajes) {
          this.orderRooms();
          this.loading = false;
        }
        break;
      case msg.evento === 'sendSala':
        this.rooms.find(r => r.lastMessage.sala === msg.sala).lastMessage.idMensaje = msg.idMensaje;
        this.rooms.find(r => r.lastMessage.sala === msg.sala).lastMessage.file = msg.file;
        this.rooms.find(r => r.lastMessage.sala === msg.sala).lastMessage.date = msg.date;
        this.rooms.find(r => r.lastMessage.sala === msg.sala).lastMessage.text = msg.text;
        this.rooms.find(r => r.lastMessage.sala === msg.sala).lastMessage.userId = msg.userId;
        this.rooms.find(r => r.lastMessage.sala === msg.sala).lastMessage.username = msg.username;
        this.orderRooms();
        break;
      default:
        break;
    }
    console.log('respuesta del socket ', msg);
  }

  getProfileImage(user: User) {
    return this.mediaService.generateImgProfileURL(user.id, user.imgProfile);
  }

  search(event: any) {
    const searchTerm = event.detail.value;
    if (searchTerm.length > 0)
      this.rooms = this.fullRooms.filter(r => r.otherUser.username.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1);
    else {
      this.rooms = this.fullRooms;
    }
  }

  private async getRooms(msg) {
    let counter = 0;
    let max = msg.data.length;
    if (msg.data.length === 0) {
      this.loading = false;
    }
    msg.data.forEach((room) => {
      if (!room.otherUserId) {
        room.otherUserId = room.sala.replace('4444', '').replace(this.user.id, '');
      }
      this.authService.getUserDataById(room.otherUserId).subscribe(res => {
        if (res.data) {
          room.otherUser = res.data;
          this.rooms.push(room);
          counter++;

        } else {
          max--;
        }
        if (counter === max) {
          this.joinRooms();
        }
      });
    });
  }

  private joinRooms() {
    this.rooms.forEach(room => {
      this.websocket.createRoom(room.sala);
    });
    this.fullRooms = this.rooms;
    this.loading = false;
  }

  private orderRooms() {
    this.rooms.sort((a, b) => {
      return new Date(a.lastMessage.date) < new Date(b.lastMessage.date) ? 1 : -1;
    });
    this.cachedRooms = this.rooms;
    Preferences.set({key: this.CHAT_LIST_KEY, value: JSON.stringify(this.rooms)}).then(() => {
    });
    this.loading = false;
  }

  canGoBack() {
    return this.routerOutlet.canGoBack();
  }

  goBack() {
    if (this.canGoBack()) {
      this.routerOutlet.pop();

    } else {
      this.router.navigate(['/'], {replaceUrl: true});
    }
  }
}
