import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  OnChanges,
  OnDestroy,
  EventEmitter,
  ChangeDetectorRef,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'app-line-clamp',
  templateUrl: './line-clamp.component.html',
  styleUrls: ['./line-clamp.component.scss']
})
export class LineClampComponent implements OnChanges, OnDestroy {
  
  @Input() text: string;
  @Input() customDescriptionToggle: boolean = false;

  @Output() toggleDescriptionRequest: EventEmitter<any> = new EventEmitter;

  @ViewChild('containerText') containerText: ElementRef;
  
  availableFreeSpaceHeightPx: number[] = [];
  availableFreeSpaceHeightPc: number[] = [];
  fixedContentHeight: number;
  userInfoHeight: number;
  viewAllDescription: boolean;

  constructor(private cdRef: ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges) {
    // @ts-ignore
    if ( changes.text ) {
      this.cdRef.detectChanges();
    }
  }

  descriptionOverflows() {    
    if (this.containerText !== undefined) {
      return this.containerText.nativeElement.clientHeight < this.containerText.nativeElement.scrollHeight || this.viewAllDescription;
    } else {
      return false;
    }
  }

  toggleDescription() {
    if ( !this.customDescriptionToggle ) {
      this.userInfoHeight = 0;
      this.viewAllDescription = !this.viewAllDescription;
    } else {
      this.toggleDescriptionRequest.emit();
    }
  }


  ngOnDestroy() {
    this.containerText = undefined;
  }

}
