<ion-content [scrollY]="false">
  <ion-list lines="none">
    <ion-item button
        detail="false"
        *ngIf="!videoRaised && !audioRaised"
        [ngClass]="{'disabled': !canVideo}"
        (click)="pickOption(RaiseHandPopoverOption.Video)">
      <ion-icon slot="start" class="camera" src="assets/svg-icon/new-version/Outline_Video.svg"></ion-icon>
      <ion-label>Vídeo</ion-label>
    </ion-item>
    <ion-item button
        detail="false"
        *ngIf="!videoRaised && !audioRaised"
        [ngClass]="{'disabled': !canAudio}"
        (click)="pickOption(RaiseHandPopoverOption.Audio)">
      <ion-icon slot="start" class="audio" src="assets/svg-icon/new-version/Outline_Podcast.svg"></ion-icon>
      <ion-label>Audio</ion-label>
    </ion-item>
    <ion-item button
              detail="false"
              *ngIf="(videoRaised || audioRaised) && !emitting"
              (click)="pickOption(RaiseHandPopoverOption.PutDown)">
      <ion-icon slot="start" color="danger" name="close-outline"></ion-icon>
      <ion-label>Bajar la mano</ion-label>
    </ion-item>
    <ion-item button
              detail="false"
              *ngIf="(videoRaised || audioRaised) && emitting"
              (click)="pickOption(RaiseHandPopoverOption.Stop)">
      <ion-icon slot="start" color="danger" name="close-outline"></ion-icon>
      <ion-label>Dejar de emitir</ion-label>
    </ion-item>
  </ion-list>
</ion-content>
