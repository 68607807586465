import { NgxCookiebotConfig } from '@halloverden/ngx-cookiebot';
import {environment} from '../environments/environment';

export class KCookiebotConfig implements NgxCookiebotConfig {
    blockingMode = 'manual';
    cbId: string = environment.COOKIEBOT_CBID;
    culture?: string;
    framework?: string;
    level?: string;
    type?: string;
    cdn: 'com' | 'eu' | string = 'eu';
    loadScript = true;
}
