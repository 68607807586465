import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {LateralMenuComponent} from './lateral-menu.component';
import {LazyImgModule} from '../lazy-img/lazy-img.module';
import {PipesModule} from '../../pipes/pipes.module';
import {RouterModule} from '@angular/router';


@NgModule({
  declarations: [
    LateralMenuComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    LazyImgModule,
    PipesModule,
    RouterModule,
  ],
  exports: [
    LateralMenuComponent
  ]
})
export class LateralMenuModule { }
