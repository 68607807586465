import {Component} from '@angular/core';
import {User} from '../../interfaces/user';
import {ModalController, Platform} from '@ionic/angular';
import {ProfileService} from '../../services/profile.service';
import {MediaService} from '../../services/media.service';
import {AuthService} from '../../services/auth.service';
import {SettingsService} from '../../services/settings.service';
import {ContactPayloadCustom, ContactService} from '../../services/contact.service';

@Component({
  selector: 'app-contact-list-to-send',
  templateUrl: './contact-list-to-send.page.html',
  styleUrls: ['./contact-list-to-send.page.scss'],
})


export class ContactListToSendPage {
  contacts: ContactPayloadCustom[] = [];
  fullContacts: ContactPayloadCustom[] = [];
  user: User;
  noPermissionMessage = false;
  inloadingContacts = false;

  constructor(
    private platform: Platform,
    private profileService: ProfileService,
    private mediaService: MediaService,
    private modalCtrl: ModalController,
    private authService: AuthService,
    private settingsService: SettingsService,
    private contactService: ContactService
  ) {
    if (this.platform.is('hybrid')) {
      this.getContacts();
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  async getContacts() {
    this.inloadingContacts = true;
    const resolved = await this.contactService.getContactsWithPromptAndSave(true, false);
    if (resolved[0] && !!resolved[1].length) {
      this.contacts = resolved[1];
      this.fullContacts = resolved[1];
    } else {
      this.noPermissionMessage = true;
    }
    this.inloadingContacts = false;
  }

  get mediaServ() {
    return this.mediaService;
  }

  get profileInfo() {
    return this.profileService.profileInfo;
  }

  async search(event: any) {
    const searchTerm = event.detail.value;
    if (!!searchTerm.length) {
      this.contacts = this.fullContacts.filter((c) => c.name.toLowerCase().includes(searchTerm.toLowerCase()));
    } else {
      this.contacts = this.fullContacts;
    }
  }

  async sendContact(contact: ContactPayloadCustom) {
    const normalizedPhone = this.contactService.normalizePhone(contact.phone);
    const contactToSend = {
      name: contact.name,
      phone: normalizedPhone.prefix + normalizedPhone.phone
    };
    await this.modalCtrl.dismiss({
      selectedContact: contactToSend
    });
  }
}
