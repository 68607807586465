<ion-content>
  <a (click)="dismiss()" class="close">
    <img src="assets/extrim/img/icons/close-white.svg" width="30" />
  </a>

  <section id="hero-er" class="section">
    <div class="content">
      <!-- <a class="go-back" (click)="dismiss()">
        <img class="d-block" src="assets/extrim/img/arrow-left.webp" width="100" />
      </a> -->
      <img
        class="logo"
        src="assets/extrim/img/logo.png"
        alt="Logo Extrim Race"
      />
      
      <div class="section-title relative h1 text-center font-venera">
        VOTACIÓN
        <div class="live">LIVE</div>
      </div>

      <img 
        class="lastre"
        src="assets/extrim/img/lastre.png"
        alt="imagen lastre"
      />

      <div class="section-subtitle font-venera">QUEDA UN ÚLTIMO LASTRE POR ASIGNAR</div>

      <a *ngIf="!loading" (click)="nextPage()" class="button w-full">VOTAR</a>
      <a *ngIf="loading" class="button w-full">
        <ion-spinner color="light" name="crescent"></ion-spinner>
      </a>
      <p>
        <a
          href="https://extrimrace.com"
          target="_blank"
          class="font-venera td-none"
          >¿QUÉ ES EXTRIM RACE?</a
        >
      </p>
    </div>
  </section>
</ion-content>
