import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Events } from './events.service';

import { ContentType } from '../enums/content-type';

@Injectable({
  providedIn: 'root'
})
export class FilePickerService {

  /**
   * Observable para lanzar evento al cambiar datos de un usuario
   */
  videoExtensionAllowed = ['mp4', 'mov', 'avi'];
  imageExtensionAllowed = ['jpeg', 'jpg', 'png', 'raw'];
  audioExtensionAllowed = ['mp3', 'AAC', 'ALAC', 'FLAC', 'wav', 'ogg', 'midi', 'wma', 'AIF', 'AIFC', 'AIFF', 'AMF', 'ASF', 'FAR', 'PCM', 'MID', 'VOC', 'WAX', 'WM', 'WMA'];
  private _filePicked = new Subject<File>();
  filePicked = this._filePicked.asObservable();

  private _inputElement: HTMLInputElement;

  private _fileType: ContentType;

  constructor( private events: Events ) { }

  /**
   * Pick a file of certain type
   *
   * @param type
   */
  pickFile(type: ContentType) {
    // Store current file type pick
    this._fileType = type;

    // Try to remove input element in case it was still there
    this._inputElement?.remove();

    // Create the input element
    this._inputElement = document.createElement('input');
    // Set attributes to input element
    this._inputElement.setAttribute('id', 'pick-user-file');
    this._inputElement.setAttribute('type', 'file');
    // Set accepted formats
    switch( type ) {
      case ContentType.Video:
      case ContentType.Clip:
        this._inputElement.setAttribute('accept', 'video/*');
        break;
      case ContentType.Image:
        const acceptImageType = this.imageExtensionAllowed.map(format => `image/${format}`).join(',');
        this._inputElement.setAttribute('accept', `${acceptImageType}`);
        break;
      case ContentType.Audio:
        this._inputElement.setAttribute('accept', 'audio/*');
        break;
    }

    // Append input element to body
    document.body.appendChild(this._inputElement);

    // Add event listener
    this._inputElement.addEventListener('input', this.onFilePicked, false);

    // Trigger the input element
    this._inputElement.click();
  }

  private onFilePicked = (event: Event) => {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const file: File = target.files[0];

    const fileExtension = file?.name.split('.').pop().toLowerCase();

    let haveErrorExtension = false;

    switch( this._fileType ) {
      case ContentType.Video:
        if (this.videoExtensionAllowed.includes(fileExtension)) {
          this._filePicked.next(file);
        } else {
          haveErrorExtension = true;
        }
        break;
      case ContentType.Image:
        if (this.imageExtensionAllowed.includes(fileExtension)) {
          this._filePicked.next(file);
        } else {
          haveErrorExtension = true;
        }
        break;
      case ContentType.Audio:
        if (this.audioExtensionAllowed.includes(fileExtension)) {
          this._filePicked.next(file);
        } else {
          haveErrorExtension = true;
        }
        break;
      default:
        this._filePicked.next(file);
        break;
    }

    if(haveErrorExtension) {
      this.events.publish('alert:toast:show', 'El archivo seleccionado no es correcto');
    }


    this._inputElement.remove();
  };

}
