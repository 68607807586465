<div class="notification-active" *ngIf="!skeleton">
  <div class="container-profile">
    <lazy-img
      [ngClass]="{
        'online': notification.sender.emitting,
        'video': notification.sender.emitting
      }"
      [size]="150"
      [source]="getNotificationImg(notification)"
      [routerLink]="notification.sender ? '/user/' + notification.sender.username : undefined">
    </lazy-img>
  </div>
  <div class="container-notification-dat" (click)="manageNotificationRedirection()">
    <div class="name-notification">{{notification.title}}</div>
    <div class="ready-notification">
      <span class="content" [innerHTML]="notificationContent"></span>
      <span class="date">{{notification.createdAt.toString().replace(' ', 'T') | dateAgo: ''}}</span>
    </div>
  </div>

  <div class="container-notification-extradat button" *ngIf="notification.extraElementType === 'follower' || notification.id === -1">
    <ion-button class="follow-button"
                (click)="followButtonClick()"
                [color]="following ? 'light' : 'darkteal'">
      {{following ? 'Siguiendo' : 'Seguir'}}
    </ion-button>
  </div>

  <div class="container-notification-extradat image {{notification.extraElementType}} {{notification.extraElementPublicationType}}" (click)="manageNotificationRedirection(true)"
       *ngIf="shouldShowThumbnail()">
    <lazy-img
      [size]="200"
      [source]="imgSrc">
    </lazy-img>
  </div>

  <div class="collaborationButtons"
       *ngIf="notification.extraElementType === 'collaborator' || notification.extraElementType === 'eventCollaborator'">
    <ng-container
      *ngIf="collaboration && !collaborationRemove && !collaboration.invitationAccepted && collaboration.id">
      <ion-button class="acceptInvitation" (click)="acceptCollaboration()">Aceptar</ion-button>
      <ion-button class="cancelInvitation" color="light" (click)="presentAlertCancelCollaboration()">Rechazar
      </ion-button>
    </ng-container>
    <span *ngIf="collaboration && collaboration.invitationAccepted && !collaborationRemove">
      Aceptada
    </span>
    <span *ngIf="(!collaboration || !collaboration.id && !collaborationRemove)">
      Cancelada
    </span>
  </div>
</div>

<div class="notification-active" *ngIf="skeleton">
  <div class="container-profile">
    <ion-thumbnail>
      <ion-skeleton-text [animated]="true" style="--border-radius: 100px;"></ion-skeleton-text>
    </ion-thumbnail>
  </div>
  <div class="container-notification-dat">
    <div class="name-notification">
      <ion-skeleton-text [animated]="true" style="width: 100px; --border-radius: 5px;"></ion-skeleton-text>
    </div>
    <div class="ready-notification">
      <span class="content">
        <ion-skeleton-text [animated]="true" style="width: 100%; --border-radius: 5px;"></ion-skeleton-text>
        <ion-skeleton-text [animated]="true" style="width: 50%; --border-radius: 5px;"></ion-skeleton-text>
      </span>
    </div>
  </div>

  <!-- 
  <div class="container-notification-extradat button" *ngIf="notification.extraElementType === 'follower' || notification.id === -1">
    <ion-button class="follow-button"
                (click)="followButtonClick()"
                [color]="following ? 'light' : 'darkteal'">
      {{following ? 'Siguiendo' : 'Seguir'}}
    </ion-button>
  </div>

  <div class="container-notification-extradat image {{notification.extraElementType}} {{notification.extraElementPublicationType}}" (click)="manageNotificationRedirection(true)"
       *ngIf="shouldShowThumbnail()">
    <lazy-img
      [size]="200"
      [source]="imgSrc">
    </lazy-img>
  </div>

  <div class="collaborationButtons"
       *ngIf="notification.extraElementType === 'collaborator' || notification.extraElementType === 'eventCollaborator'">
    <ng-container
      *ngIf="collaboration && !collaborationRemove && !collaboration.invitationAccepted && collaboration.id">
      <ion-button class="acceptInvitation" (click)="acceptCollaboration()">Aceptar</ion-button>
      <ion-button class="cancelInvitation" color="light" (click)="presentAlertCancelCollaboration()">Rechazar
      </ion-button>
    </ng-container>
    <span *ngIf="collaboration && collaboration.invitationAccepted && !collaborationRemove">
      Aceptada
    </span>
    <span *ngIf="(!collaboration || !collaboration.id && !collaborationRemove)">
      Cancelada
    </span>
  </div> -->
</div>