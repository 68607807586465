import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-image-zoom',
  templateUrl: './image-zoom.component.html',
  styleUrls: ['./image-zoom.component.scss'],
})
export class ImageZoomComponent implements OnInit {

  @Input() imageSrc: string;

  constructor( private modalCtrl: ModalController ) { }

  ngOnInit() {}

  close() {
    this.modalCtrl.dismiss();
  }
}
