<ion-button
  fill="clear"
  *ngIf="category !== undefined && !skeleton"
  [ngClass]="{'active': active, 'fit': fit}"
  (click)="categorySelected.emit()">
  <img handleError cache [source]="mediaServ.generateCategoryThumbnailImgURL(category)">
  <span>{{category.name}}</span>
</ion-button>

<ion-skeleton-text *ngIf="skeleton" [animated]="true" style="width: 100%; height:  44px; margin: 0; --border-radius: 5px;"></ion-skeleton-text>
