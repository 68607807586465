import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { inOutFade } from 'src/app/animations/animations';
import { environment } from 'src/environments/environment';

import { Events } from 'src/app/services/events.service';
import { AuthService } from 'src/app/services/auth.service';

import { User } from 'src/app/interfaces/user';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-mini-profile',
  templateUrl: './mini-profile.component.html',
  styleUrls: ['./mini-profile.component.scss'],
  animations: [
    inOutFade,
    trigger('inOutProfileHeader', [
      transition(':enter', [
        style({transform: 'translateY(120%)'}),
        animate('600ms cubic-bezier(.1, .7, .1, 1)', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('600ms cubic-bezier(.1, .7, .1, 1)', style({transform: 'translateY(120%)'}))
      ])
    ]),
  ]
})
export class MiniProfileComponent  implements OnInit, OnDestroy {

  showMiniProfile: boolean = false;
  userToShowProfile: {
    user: User, 
    canSendStars: boolean,
    canInvite: boolean,
    canPing: boolean,
    canKick: boolean,
    canSilent: boolean,
    isCreator: boolean, 
    isCollaborator: boolean, 
    isSubscribed: boolean,
    userUpdated(event): void,
    sendPingUser(event): void,
    sendInviteUser(event): void,
    sendStars(): void,
    kick(event): void,
    silent(): void
  };

  zIndex: number = 1;

  sub: any = {};

  user: User;

  constructor( private events: Events, private authService: AuthService ) {
    this.events.subscribe(environment.EVENTS.SHOW_MINI_PROFILE, d => {
      const data = d[0];

      if ( Number(this.user?.id) !== Number(data.user.id) ) {
        const modals = document.getElementsByTagName("ion-modal");

        Array.from(modals).forEach(modal => {
          const zIndex = window.getComputedStyle(modal).getPropertyValue('z-index');
          Number(zIndex) >= this.zIndex ? this.zIndex = Number(zIndex) + 1 : null;
        });

        this.showProfile(data);
      }
    })

    this.events.subscribe(environment.EVENTS.CLOSE_MINI_PROFILE, () => {
      this.closeProfile();
    })
  }

  ngOnInit() {
    this.sub.user = this.authService.watchUser()
    .pipe(filter(data => !!data))
    .subscribe((user: User) => {
      this.user = user;
    })
  }

  ngOnDestroy(): void {
    this.sub.user.unsubscribe();
  }

  showProfile($event) {
    this.userToShowProfile = $event;
    this.showMiniProfile = true;
  }
  closeProfile() {
    this.showMiniProfile = false;
  }
}
