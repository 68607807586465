import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Preferences } from '@capacitor/preferences';
import { environment } from '../../environments/environment';
import { Notification } from '../interfaces/notification';
import { JsonApiResponse } from '../interfaces/JsonApiResponse';
import { AuthService } from './auth.service';
import { ProfileService } from './profile.service';
import { PrivateChat } from '../interfaces/privateChat';
import { BehaviorSubject, Subject } from 'rxjs';
import { Events } from './events.service';
import { User } from '../interfaces/user';
import { skipWhile, take } from 'rxjs/operators';
import { CacheService } from './cache.service';

import { CacheData } from '../enums/cache-data';
@Injectable({
  providedIn: 'root'
})
export class NotificationService extends CacheService {

  public notifications = new BehaviorSubject<Notification[]>(undefined);
  public notificationsData = new BehaviorSubject<any[]>([]);
  public chatNotifications = new BehaviorSubject<Notification[]>([]);
  public unread = new BehaviorSubject(0);
  public unreadChat = new BehaviorSubject([]);
  sendPushNotificationV1Url = 'api/auth/estrim/send_push_notification';
  sendPushNotificationUrl = 'api/v2/auth/estrim/send_push_notification';
  getNotificationsUrl = 'api/auth/estrim/get_notifications';
  setNotificationReadUrl = 'api/auth/estrim/set_notification_read';
  isChatRequestUrl = 'api/auth/estrim/is_chat_request';
  getPrivateChatsV1Url = 'api/auth/estrim/get_private_chats/';
  getPrivateChatsUrl = 'api/v2/auth/estrim/get_private_chats/{offset}/{limit}/{findRequests}';

  NOTIFICATIONS_CACHE_KEY = 'NOTIFICATIONS_CACHE';

  private onMessageEvent = new Subject<any>();
  private onFollowEvent = new Subject<any>();

  constructor(
    protected http: HttpClient, 
    private authService: AuthService, 
    private profileService: ProfileService,
    private events: Events
  ) {
    super(http);

    this.events.subscribe(environment.EVENTS.USER_LOGOUT, () => {
      this.notifications.next([]);
      this.notificationsData.next([]);
      this.chatNotifications.next([]);
      this.unread.next(0);
      this.unreadChat.next([]);
    });

    this.events.subscribe(environment.EVENTS.START_INVITATION_TIMEOUT, () => {
      this.startInvitationTimeout();
    });

    this.authService.watchUser()
      .pipe(
        skipWhile(data => !data),
        take(1))
      .subscribe((user: User) => {
        this.watchCacheData(CacheData.Notifications, true).subscribe();
      });
  }

  protected fetchCacheData(type: CacheData) {
    switch ( type ) {
      case CacheData.Notifications: 
        this.loadNotifications();
      break;
    }
  }
  protected getCacheDataObservable(type: CacheData): BehaviorSubject<any> {
    switch ( type ) {
      case CacheData.Notifications: return this.notifications; break;
    }
  }
  protected getCacheDataObservableKey(type: CacheData): string {
    switch ( type ) {
      case CacheData.Notifications: return this.NOTIFICATIONS_CACHE_KEY; break;
    }
  }

  publishOnMessage(data: any) {
    this.onMessageEvent.next(data);
  }

  publishOnFollow(data: any) {
    this.onFollowEvent.next(data);
  }

  /* NEWS EVENTS */
  onMessage(): Subject<any> {
      return this.onMessageEvent;
  }
  onFollow(): Subject<any> {
      return this.onFollowEvent;
  }

  public generateAbsoluteAPIURL($tail: string) {
    return environment.API_BASE_URL + $tail;
  }

  sendPushNotification(idUser, content, title, elementType, elementId) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.sendPushNotificationUrl), {
      idUser,
      content,
      title,
      elementType,
      elementId
    });
  }

  async loadNotifications() {
    const res = await this.http.get<JsonApiResponse<{ notifications: Notification[]; extraData: any[]; unread: number; chatNotifications: Notification[]; unreadChat: [] }>>(
      this.generateAbsoluteAPIURL(this.getNotificationsUrl))
      .toPromise();

    if ( res.data.notifications === null ) {
      res.data.notifications = [];
    }

    let loadedNotifications = res.data.notifications;
    if (!(res.data.notifications instanceof Array)) {
      loadedNotifications = Object.values(res.data.notifications);
    }
    // this.notifications.next(loadedNotifications);
    this.notificationsData.next(res.data.extraData);
    this.unread.next(res.data.unread);
    this.unreadChat.next(res.data.unreadChat);
    this.chatNotifications.next(res.data.chatNotifications);

    this.setCacheData(CacheData.Notifications, loadedNotifications)

    this.authService.watchUser()
      .pipe(
        skipWhile(data => !data),
        take(1))
      .subscribe((user: User) => {
        this.profileService.getCollaboratorInvitations(user.id).subscribe((res2) => {
          const collaborators = res2.data.userCollabs || [];
          const eventCollaborators = res2.data.eventCollabs || [];
          collaborators.forEach(c => {
            const notification = this.notifications.value.find(not => not.extraElementType === 'collaborator'
              && not.extraElementId === c.collaborateWith.id.toString());
            if (notification) {
              notification.collaborator = {collaborator: c, answer: 0};
  
            }
          });
          eventCollaborators.forEach(c => {
            const notificationEvent = this.notifications.value.find(not => not.extraElementType === 'eventCollaborator'
              && not.extraElementId === c.collaborateEvent.id.toString());
            if (notificationEvent) {
              notificationEvent.collaborator = {collaborator: c, answer: 0};
  
            }
          });
        });
      });
  }

  setNotificationsRead(notificationsIds) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.setNotificationReadUrl), {
      notificationsIds
    });
  }

  isChatRequest(otherUserId: number) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.isChatRequestUrl), {
      otherUserId
    });
  }

  getPrivateChats(offset = 0, limit = 20, findRequests = null) {
    if (findRequests !== null) {
      findRequests = findRequests === true ? '1' : '0';
    } else {
      findRequests = '';
    }

    let url = this.generateAbsoluteAPIURL(this.getPrivateChatsUrl).replace('{offset}', offset.toString())
      .replace('{limit}', limit.toString())
      .replace('{findRequests}', findRequests !== null ? findRequests.toString() : null)

    if ( url.substr(url.length - 1) === '/' ) {
      url = url.slice(0, -1);
    }

    return this.http.get<any>(url);
  }

  startInvitationTimeout() {
    setTimeout(() => {
      if ( !this.authService.user.isPro ) {
        this.createInvitationsAutoNotification();
      }
    }, 10000);
  }

  createInvitationsAutoNotification() {
    this.authService.getUserDataByUsername('estrim')
    .subscribe((user: JsonApiResponse<User>) => {
      const notification: Notification = {
        id: -1,
        user: (user.data as User),
        sender: (user.data as User),
        title: 'estrim',
        content: '¡Enhorabuena! Has recibido 3 invitaciones',
        isRead: false,
        extraElementType: undefined,
        extraElementPublicationType: undefined,
        extraElementId: undefined,
        createdAt: (new Date()).toISOString(),
        collaborator: undefined
      }

      this.notifications.next([notification, ...this.notifications.getValue()]);
      this.unread.next(
        this.notifications
          .getValue()
          .filter((notification: Notification) => !notification.isRead)
          .length
      )
    });
  }
}
