import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, AfterViewInit, Output, ViewChild } from '@angular/core';
import { Collaborator } from '../../interfaces/collaborator';
import { User } from '../../interfaces/user';
import { Publication } from '../../interfaces/publication';
import { Event } from '../../interfaces/event';
import { MediaService } from '../../services/media.service';
import { Events } from '../../services/events.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-hands-list',
  templateUrl: './hands-list.component.html',
  styleUrls: ['./hands-list.component.scss'],
})
export class HandsListComponent implements OnInit, AfterViewInit {
  @ViewChild('content', {static: false}) content: ElementRef;

  @Input() raisedHands: any[];
  @Input() acceptedHands: any[];
  @Input() isAuthor: boolean;

  @Output() answerHand: EventEmitter<{ userId: number; user: User; accepted: boolean; isVideo: boolean }> = new EventEmitter();

  constructor(
    private mediaService: MediaService,
    private events: Events
  ) {
  }

  ngOnInit() {}

  ngAfterViewInit() {
    const scrollcontainer = this.content.nativeElement;

    scrollcontainer.ontouchmove = (e) => {
      if ( this.content.nativeElement.scrollTop !== 0 )
        e.stopPropagation();
    };
  }

  getImgProfile(user: User) {
    return this.mediaService.generateImgProfileURL(user.id, user.imgProfile);
  }

  answerRaisedHand(userId: any, user: User, accepted: boolean, isVideo: any) {
    const answerData = {
      userId,
      user,
      accepted,
      isVideo
    };
    this.answerHand.emit(answerData);
  }

  getBadgeSrc(user) {
    return this.mediaService.getBadgeSrc(user);
  }
}
