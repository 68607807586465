import { Component, Input, OnInit } from '@angular/core';
import { Platform, PopoverController } from '@ionic/angular';
import { ProfileOptionsPopoverOption } from '../../../enums/profile-options-popover-option';

@Component({
  selector: 'app-profile-options-popover',
  templateUrl: './profile-options-popover.component.html',
  styleUrls: ['./profile-options-popover.component.scss'],
})
export class ProfileOptionsPopoverComponent implements OnInit {

  @Input() user: boolean = false;
  @Input() reportable: boolean = true;
  @Input() blocked: boolean = false;
  @Input() blockLabelText: string = 'No me interesa';
  @Input() shareLabelText: string = 'Compartir';
  @Input() canShare = false;


  ProfileOptionsPopoverOption = ProfileOptionsPopoverOption;

  constructor(private popoverCtrl: PopoverController, private platform: Platform) {

  }

  ngOnInit() {
    this.shareLabelText = this.canShare ? 'Compartir perfil' : 'Copiar URL';
  }

  pickOption(option: ProfileOptionsPopoverOption) {
    this.popoverCtrl.dismiss(option);
  }
}
