<div class="info">
  <div class="text">
    <div class="intro" *ngIf="title">
      {{title}}
    </div>

    <div class="description" *ngIf="description">
      {{description}}
    </div>
  </div>
</div>