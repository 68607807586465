<div #content class="content">
  <div class="section" *ngIf="acceptedHands.length">
    <div class="title">En línea</div>

    <div class="list">
      <div class="element" *ngFor="let hand of acceptedHands">
        <div class="data">
          <ion-thumbnail class="avatar">
            <img handleError [src]="hand.image" />
          </ion-thumbnail>

          <span class="user">
            <img handleError class="badge" *ngIf="hand.isFounder" src="assets/badges/founder-badge.svg" alt="">
            <img handleError class="badge special creator" *ngIf="hand.isCreator" src="assets/badges/special/creator-1x.png" alt="">
            <img handleError class="badge special collaborator" *ngIf="!hand.isCreator && hand.isCollaborator" src="assets/badges/special/collaborator-1x.png" alt="">
            <img handleError class="badge special subscriber" *ngIf="!hand.isCreator && !hand.isCollaborator && hand.isSubscribed" src="assets/badges/special/subscriber-1x.png" alt="">
            <img handleError class="badge" [src]="getBadgeSrc(hand.user)" alt="">{{hand.username}}
          </span>
        </div>

        <div class="buttons">
          <ion-button 
            color="light" 
            (click)="answerRaisedHand(hand.userId, hand.user, false, hand.isVideo)">
            Expulsar
          </ion-button>
        </div>
      </div>
    </div>
  </div>

  <div class="section" *ngIf="raisedHands.length">
    <div class="title">Solicitudes</div>

    <div class="list">
      <div class="element" *ngFor="let hand of raisedHands">
        <div class="data multiple-buttons">
          <ion-thumbnail class="avatar">
            <img handleError [src]="hand.image" />
          </ion-thumbnail>

          <span class="user">
            <img handleError class="badge" *ngIf="hand.isFounder" src="assets/badges/founder-badge.svg" alt="">
            <img handleError class="badge special creator" *ngIf="hand.isCreator" src="assets/badges/special/creator-1x.png" alt="">
            <img handleError class="badge special collaborator" *ngIf="!hand.isCreator && hand.isCollaborator" src="assets/badges/special/collaborator-1x.png" alt="">
            <img handleError class="badge special subscriber" *ngIf="!hand.isCreator && !hand.isCollaborator && hand.isSubscribed" src="assets/badges/special/subscriber-1x.png" alt="">
            <img handleError class="badge" [src]="getBadgeSrc(hand.user)" alt="">{{hand.username}}
          </span>
        </div>

        <div class="buttons">
          <ion-button 
            color="darkteal" 
            (click)="answerRaisedHand(hand.userId, hand.user, true, hand.isVideo)">
            <img src="assets/png-icon/mic-white.png" *ngIf="!hand.isVideo" />
            <img src="assets/png-icon/camera-white.png" *ngIf="hand.isVideo" /> 
            Aceptar
          </ion-button>

          <ion-button 
            color="light" 
            (click)="answerRaisedHand(hand.userId, hand.user, false, hand.isVideo)">
            Denegar
          </ion-button>
        </div>
      </div>
    </div>
  </div>
</div>
