<ion-header class="general-header-app">
  <app-header 
    [title]="'Fechas'" 
    [menuButton]="false"
    [defaultBackAction]="false"
    (back)="dismiss()">    
  </app-header>
</ion-header>

<ion-content>
  <app-calendar
    [startDate]="startDate"
    [endDate]="endDate"
    (datesSelected)="datesSelected($event)"
  >
  </app-calendar>
</ion-content>