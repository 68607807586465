import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Debouncer } from '../components/form-input/debouncer';
import { debounceTime } from 'rxjs/operators';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  //URLS
  public URL_CHECK_EMAIL_EXISTS = 'api/user/check-email-exists';
  public URL_CHECK_USERNAME_EXISTS = 'api/user/check-username-exists';
  public URL_CHECK_PHONE_EXISTS = 'api/user/check-phone-exists';

  //PATTERNS
  emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
  namePattern = '^[a-zA-ZñÑ\\säÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙ]*$';
  lettersAndNumbersPattern = '^[a-zA-Z0-9ñÑ\\säÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙ]*$';
  phonePattern = '^[0-9\\s]*$';
  usernamePattern = '^[a-zA-Z0-9ñÑ._]*$';
  passwordStrongPattern = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$¡!%*¿?&()=+-_])[A-Za-z\\d@$¡!%*¿?&()=+-_]{8,}$';
  passwordPattern = '^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9@$¡!%*¿?&()=+-_]+)$';

  //ERRORS
  defaultErrors: any = {
    required: 'Este campo es obligatorio',
    pattern: 'Se han detectado caracteres no permitidos en este campo',
    maxlength: 'Se ha superado el número de caracteres permitidos',
    min: 'El valor introducido está por debajo del mínimo permitido.',
    urlError: 'Introduce una url válida',
    ageError: 'Debes ser mayor de 18 años.'
  };
  customErrors: any = {
    email: {
      pattern: 'Introduce un email válido',
      alreadyExists: 'El email introducido ya existe'
    },
    password: {
      minlength: 'La contraseña debe tener al menos 8 caracteres',
      // pattern: 'La contraseña debe contener al menos un número, una letra minúscula, otra mayúscula y un caracter especial.'
      pattern: 'La contraseña debe contener al menos un número y una letra.'
    },
    repeatPassword: {
      notSame: 'Las contraseñas deben ser iguales'
    },
    acceptPrivacy: {
      required: 'Debes aceptar la política de privacidad para continuar.'
    },
    username: {
      pattern: 'El nombre de usuario no puede contener símbolos o espacios',
      alreadyExists: 'El nombre de usuario ya se encuentra registrado',
      minlength: 'El nombre de usuario debe tener al menos 2 caracteres'
    },
    phone: {
      alreadyExists: 'El teléfono ya se encuentra en uso',
      phoneError: 'El teléfono no es correcto'
    },
    quantityWithdraw: {
      min: 'La cantidad mínima a retirar es 100'
    },
    invitationCode: {
      minlength: 'El código debe tener entre 8 y 10 caracteres',
      maxlength: 'El código debe tener entre 8 y 10 caracteres'
    }
  };

  //DEBOUNCERS
  debouncers: { [key: string]: Debouncer } = {};

  constructor(private http: HttpClient) {
  }

  checkExists(event: any, inputName: string, currentValue = '') {
    const value = event.target.value;
    if (currentValue !== value) {
      this.debouncers[inputName].available = false;
      this.debouncers[inputName].loading = false;
      this.debouncers[inputName].debouncer.next(value);
    }


  }

  registerDebouncer(debouncer: Debouncer, inputName: string, formControl: AbstractControl) {
    this.debouncers[inputName] = debouncer;
    this.debouncers[inputName].debouncer
      .pipe(
        debounceTime(debouncer.delay),
      )
      .subscribe(value => {
        if (this.checkFieldValid(formControl)
          && value !== '') {
          this[this.debouncers[inputName].method](value, inputName)
            .subscribe(({user}) => {
              if (user) {
                formControl.setErrors({alreadyExists: true});
                this.debouncers[inputName].available = false;
              } else {
                this.debouncers[inputName].available = true;
              }
              this.debouncers[inputName].loading = false;
            });
        }
      });
  }

  public generateAbsoluteAPIURL($tail: string) {
    return environment.API_BASE_URL + $tail;
  }

  checkFieldValid(formControl: AbstractControl) {
    return formControl.valid;
  }

  checkFieldInvalid(formControl: AbstractControl) {
    return formControl.invalid && formControl.errors
      && (formControl.dirty || formControl.touched);
  }

  checkEmailExists(email: string) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.URL_CHECK_EMAIL_EXISTS), {
      email
    });
  }

  checkPhoneExists(phone: string, countryCode) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.URL_CHECK_PHONE_EXISTS), {
      phone,
      countryCode
    });
  }

  checkUsernameExists(username: string) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.URL_CHECK_USERNAME_EXISTS), {
      username
    });
  }

  checkFinishedDebouncers(form: UntypedFormGroup) {
    let finished = true;
    Object.keys(form.controls).forEach(key => {
      if (this.debouncers[key] && this.debouncers[key].available === false) {
        finished = false;
      }
    });
    return finished;
  }

  clearDebouncers() {
    this.debouncers = {};
  }

  forceFinishDebouncers() {
    Object.keys(this.debouncers).forEach(key => {
      this.debouncers[key].available = true;
    });
  }
}
