<ion-header class="general-header-app">
  <app-header
    [title]="''"
    [menuButton]="false"
    [defaultBackAction]="false"
    (back)="back()"
  >
  </app-header>
</ion-header>

<ion-content class="ion-padding">
  <div class="flex-content">
    <div class="content">
      <ion-icon class="logo" src="assets/estrim/logo.svg"></ion-icon>
      <ion-text class="page-title"> Comprueba tu invitación </ion-text>

      <!-- Code -->
      <div
        class="field less-margin input code"
        *ngIf="registerType === RegisterType.Code"
      >
        <div class="content">
          <ion-input
            required
            placeholder="Código de invitación"
            [(ngModel)]="code"
            [disabled]="loading || invitationChecked"
            (ionChange)="error.code = false"
          >
          </ion-input>

          <ion-text class="field-error" *ngIf="error.code" color="danger">
            <small>
              <ion-icon name="alert-circle-outline"></ion-icon>
              <span>{{invitationError}}</span>
            </small>
          </ion-text>
        </div>
      </div>

      <!-- Phone -->
      <div
        class="field less-margin phone"
        *ngIf="registerType === RegisterType.Phone"
      >
        <div class="content">
          <div class="country">
            <img
              handleError
              class="flag-image"
              [src]="'assets/country-flags/'+selectedCountry.countryImg+'.svg'"
            />

            <ion-select
              mode="md"
              class="select"
              value="34"
              cancelText="Cancelar"
              okText="Aceptar"
              [disabled]="loading || invitationChecked"
              [selectedText]="getCountryPrefix()"
              (ionChange)="changeFlag($event)"
            >
              <ion-select-option
                [value]="country"
                *ngFor="let country of countries"
              >
                <span>{{country.countryName}} +{{country.code}} </span>
              </ion-select-option>
            </ion-select>
          </div>

          <div class="input">
            <ion-input
              required
              type="tel"
              placeholder="Introduce tu número"
              [(ngModel)]="phone"
              [disabled]="loading || invitationChecked"
              (ionChange)="error.phone = false"
            >
            </ion-input>
          </div>

          <ion-text class="field-error" *ngIf="error.phone" color="danger">
            <small>
              <ion-icon name="alert-circle-outline"></ion-icon>
              <span>{{invitationError}}</span>
            </small>
          </ion-text>
        </div>
      </div>

      <!-- Submit button -->
      <div class="submit-button" *ngIf="!invitationChecked">
        <ion-button
          fill="clear"
          [disabled]="loading || invitationChecked"
          (click)="registerType === RegisterType.Phone ? checkPhone() : checkCode()"
        >
          Comprobar
          <ion-spinner
            color="white"
            name="crescent"
            *ngIf="loading"
          ></ion-spinner>
        </ion-button>
      </div>

      <div class="feedback" *ngIf="invitationChecked" @inOutBottomFade>
        ¡Invitación aceptada!
      </div>
    </div>
  </div>
  <app-web-footer></app-web-footer>
</ion-content>
