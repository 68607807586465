// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isWeb: true,
  APP_VERSION: '2.4.8-dev',
  API_BASE_URL: 'https://backend.dev.estrim.com/',
  CDN_BASE_URL: 'https://cdn-estrim-dev.estrim.com/',
  CLOUDFLARE_UPLOAD_BASE_URL: 'https://api.cloudflare.com/client/v4/accounts/f91010c412b9f8f4342691e4b6cad344/stream',
  CLOUDFLARE_STREAM_BASE_URL: 'https://customer-awhouzbt7jhs3g6j.cloudflarestream.com/<VIDEO_ID>/manifest/video.m3u8',
  IMAGE_SERVICE_BASE_URL: 'https://wsrv.nl/',
  LIVEKIT_BASE_URL: 'https://livekit.dev.estrim.com',
  GENERATE_IMAGE_BASE_URL: 'https://generateimg.dev.estrim.com/',
  SOCKET_NODE_SERVER: 'wss://chat.dev.estrim.com/chat',
  SOCKET_TOKEN_ID: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1NGE4Y2U2MThlOTFiMGIxMzY2NWUyZjkiLCJpYXQiOiIxNDI0MTgwNDg0IiwiZXhwIjoiMTQyNTM5MDE0MiJ9.yk4nouUteW54F1HbWtgg1wJxeDjqDA_8AhUPyjE5K0U',
  WEBSOCKET_BASE_URL: 'https://ws.dev.estrim.com/',
  R2_UPLOAD_WORKER_URL: 'https://fileuploader.dev.estrim.com/',
  // R2_UPLOAD_WORKER_URL: 'http://localhost:8787/',
  PRODUCTS_IAP: {
    CONSUMABLE_IOS: [
      'app.estrim.reload2', 'app.estrim.reload4', 'app.estrim.reload10',
      'app.estrim.reload16', 'app.estrim.reload20', 'app.estrim.reload30',
      'app.estrim.reload60', 'app.estrim.reload100', 'app.estrim.reload200',
      'app.estrim.reload240', 'app.estrim.reload300', 'app.estrim.reload400'
    ],
    CONSUMABLE_ANDROID: [
      'app.estrim.reload2', 'app.estrim.reload4', 'app.estrim.reload10',
      'app.estrim.reload16', 'app.estrim.reload20', 'app.estrim.reload30',
      'app.estrim.reload60', 'app.estrim.reload100', 'app.estrim.reload200',
      'app.estrim.reload240'
    ],
    SUBSCRIPTION_IOS: [
      'app.estrim.subscription1', 'app.estrim.subscription2', 'app.estrim.subscription3',
      'app.estrim.subscription4', 'app.estrim.subscription5', 'app.estrim.subscription10',
      'app.estrim.subscription15', 'app.estrim.subscription20', 'app.estrim.subscription25',
      'app.estrim.subscription30', 'app.estrim.subscription50', 'app.estrim.subscription100',
      'app.estrim.subscription200'
    ],
    SUBSCRIPTION_ANDROID: [
      'app.estrim.subscription1', 'app.estrim.subscription2', 'app.estrim.subscription3',
      'app.estrim.subscription4', 'app.estrim.subscription5', 'app.estrim.subscription10',
      'app.estrim.subscription15', 'app.estrim.subscription20', 'app.estrim.subscription25',
      'app.estrim.subscription30', 'app.estrim.subscription50', 'app.estrim.subscription100',
      'app.estrim.subscription200'
    ]
  },
  BASE_URL: 'https://development.estrim.com',
  STRIPE_SCRIPT_PATH: 'https://js.stripe.com/v3/',
  STRIPE_PUBLIC_KEY: 'pk_test_51KgWr3IZpM359GlwWvZVQF9UWJqGUGOPsbswTSW4zFNtWYb6hp7wvS0KBDEXnhVFvwo19kiufQwrQioBvrLO8hSm0025Y4B7jY',
  STRIPE_PRIVATE_KEY: 'sk_test_51KgWr3IZpM359GlwxNO9bAFs6blhQIG7vsq5GLY9mE1lImfFrtLGbSLdTsY8Mmsdtloec9zVlWXy6aHWqflFzgT0000aA3SqER',
  FACEBOOK_APP_ID: '',
  GOOGLE_APP_CLIENT_ID: '',
  GOOGLE_APP_SECRET_ID: '',
  COOKIEBOT_CBID: '529096c1-0457-4d5d-bf98-b006d451c50c',
  SOCIAL_LOGIN: {
    GOOGLE: {
      clientId: '940032478055-um6uh17tis98fcnls4dmf8udglnuejcv.apps.googleusercontent.com',
    },
    APPLE: {
      clientId: 'com.estrim.login',
      redirectURI: 'https://development.estrim.com/login',
    }
  },
  APP_ROUTES: {
    HOME: '',
    LOGIN: 'login',
    REGISTER: 'registro-usuario',
    USER_PROFILE: 'area-privada',
    REDIRECT_AFTER_LOGIN: '/'
  },
  EVENTS: {
    USER_LOGIN: 'user:login',
    USER_LOGOUT: 'user:logout',
    USER_UPDATED: 'user:updated',
    USER_MODIFIED: 'user:modified',
    USER_INTEREST_UPDATED: 'user-interest:updated',
    CONFIG_COUNTRIES_UPDATED: 'configuration:countries:updated',
    ALERT_TOAST_ERROR: 'alert:toast:show',
    ALERT_TOAST_NOTICE: 'alert:toast:notice',
    ALERT_TOAST: 'alert:toast',
    SUBSCRIPTION_STATUS: 'subscription:status',
    USER_FOLLOW: 'user:follow',
    CATEGORY_SELECTED: 'category:select',
    CHAT_MESSAGE: 'chat:newmessage',
    PARTICIPANTS_CHANGE: 'chat:participants:changed',
    UPDATE_VIDEO_REFERENCE: 'chat:update:video',
    EMISSION_IN_PROGRESS: 'emission:progress:on',
    EMISSION_PUBLISH: 'emission:publish',
    EMISSION_UNPUBLISH: 'emission:unpublish',
    EMISSION_STOPPED: 'emission:stopped',
    UPDATE_NUM_PARTICIPANTS: 'participants:update',
    JANUS_PARTICIPANT_ADDED: 'janus:participant:added',
    ADD_NEW_ASSISTANT: 'participants:new-assistant',
    REMOVE_ASSISTANT: 'participants:remove-assistant',
    UPDATE_NOTIFICATIONS: 'notifications:update',
    LIVE_CREATED: 'live:created',
    EVENT_CREATED: 'event:created',
    BACK_BUTTON_PRESSED: 'backbutton:pressed',
    MINIMIZE_APP: 'app:minimize',
    RESUME_APP: 'app:resume',
    SPEAKER_LEFT: 'app:speaker-left',
    SUBSCRIBED_REDUCED_PROFILE: 'app:subscribed-reduced-profile',
    COLLABORATION_ANSWERED: 'app:collaboration-answered',
    TOGGLE_FULLSCREEN: 'app:toggle-fullscreen',
    WEBSOCKET_DESCONECTED: 'app:websocket-desconected',
    ON_LOGIN_SCREEN: 'app:on-login-screen',
    START_INVITATION_TIMEOUT: 'app:start-invitation-timeout',
    PUBLICATION_DELETED: 'publication:deleted',
    PUBLICATION_BLOCKED: 'publication:blocked',
    PUBLICATION_UNBLOCKED: 'publication:unblocked',
    PUBLICATION_REPORTED: 'publication:reported',
    PUBLICATION_MODIFIED: 'publication:modified',
    USER_SUGGESTIONS_UPDATED: 'user-suggestions:updated',
    SHOW_MINI_PROFILE: 'mini-profile:show',
    CLOSE_MINI_PROFILE: 'mini-profile:close',
    WELCOME: 'welcome',
    WELCOME_IGNORED: 'welcome:ignored',
    TABS_PAGE_WILL_LEAVE: 'tabs:page:will:leave',
    TABS_PAGE_DID_LEAVE: 'tabs:page:did:leave',
    TABS_PAGE_WILL_ENTER: 'tabs:page:will:enter',
    TABS_PAGE_DID_ENTER: 'tabs:page:did:enter',
    AUTH_TOKEN_UPDATE: 'auth:token:update',
  },
  MESSAGES: {
    ERROR: 'Ocurrió un error inesperado. Comprueba tu conexión a internet e inténtalo de nuevo.'
  },
  CONTENT_MIN_PRICE: 1,
  CONTENT_MAX_PRICE: 250
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.