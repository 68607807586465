<ion-header class="general-header-app">
  <app-header 
    [title]="'Seguidores'" 
    [menuButton]="false"
    [defaultBackAction]="false"
    (back)="dismiss()">    
  </app-header>
</ion-header>

<ion-content>

  <div class="list">
    <div lines="none" class="follower" *ngFor="let fol of followers">
      <lazy-img
        class="back-contact"
        [size]="150"
        [source]="getUserImg(fol)">
      </lazy-img>
      <div class="data">
        <label class="name-contact">{{fol.nameToShow}}</label>
        <span class="sub">@{{fol.username}}</span>
      </div>
      <ion-button
        (click)="invite(fol)"
        [color]="invited.includes(fol) ? 'light' : 'darkteal'">
        {{invited.includes(fol) ? 'Invitado' : 'Invitar'}}
      </ion-button>
    </div>

    <span class="no-data" *ngIf="followers && followers.length === 0">No hay seguidores.</span>
  </div>

  <div class="floating-button">
    <ion-button fill="clear" (click)="close()">Aceptar</ion-button>
  </div>

  <div class="ios-bottom-safe-area"></div>

</ion-content>
