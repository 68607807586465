import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-progress-circle',
  templateUrl: './progress-circle.component.html',
  styleUrls: ['./progress-circle.component.scss'],
})
export class ProgressCircleComponent implements OnInit, OnChanges {

  @Input() progress = 0;
  arcOffset2 = 0;

  constructor(private changeDetector: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.calculate();
    this.changeDetector.detectChanges();
  }

  ngOnInit() {
    this.calculate();
  }

  private calculate() {
    const size = 100;
    const strokeWidth = 10;
    const center = size / 2;
    const radius = center - strokeWidth;
    const arcLength = 2 * Math.PI * radius;
    const arcOffset1 = arcLength * 1;
    this.arcOffset2 = arcLength * ((100 - this.progress) / 100);
  }
}
