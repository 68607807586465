import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ScreenOrientation, ScreenOrientationResult } from '@capacitor/screen-orientation';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreenOrientationService {

  /**
   * Observable para lanzar evento al cambiar orientacion de la pantalla
   */
  private _screenOrientationChanged = new BehaviorSubject<OrientationLockType>('portrait');
  screenOrientationChanged = this._screenOrientationChanged.asObservable();

  constructor( private platform: Platform ) {
    this.platform.ready().then(() => {
      ScreenOrientation.addListener('screenOrientationChange', (orientation: ScreenOrientationResult) => {
        this._screenOrientationChanged.next(orientation.type);
      });
    })
  }

  /**
   * Desbloquea y deja libre la orientación de la pantalla
   */
  async unlock() {
    if ( this.platform.is('hybrid') ) {
      await ScreenOrientation.unlock();
    }
  }

  /**
   * Bloquea la orientación de la pantalla según el parámetro introducido
   * 
   * Debido a peculiaridades en iOS, debemos invertir el modo landscape para que
   * se corresponde con la orientación deseada
   * 
   * @param orientation 
   */
  async lock( orientation: OrientationLockType ) {
    if ( this.platform.is('ios') && ( orientation === 'landscape' || orientation === 'landscape-primary') ) {
      await ScreenOrientation.lock({orientation: 'landscape-secondary'});
    } else if ( this.platform.is('ios') && orientation === 'landscape-secondary' ) {
      await ScreenOrientation.lock({orientation: 'landscape-primary'});
    } else {
      await ScreenOrientation.lock({orientation: orientation});
    }
  }

  /**
   * Bloquea la orientación de la pantalla en modo portrait
   */
  async lockPortrait() {
    if ( this.platform.is('hybrid') ) {
      await ScreenOrientation.lock({orientation: 'portrait'});
    }
  }

  /**
   * Bloquea la orientación de la pantalla en modo landscape
   * 
   * Debido a peculiaridades en iOS, debemos invertir el modo landscape para que
   * se corresponde con la orientación deseada
   */
  async lockLandscape() {
    if ( this.platform.is('ios') ) {
      await ScreenOrientation.lock({orientation: 'landscape-secondary'});
    } else if ( this.platform.is('android') ) {
      await ScreenOrientation.lock({orientation: 'landscape-primary'});
    }
  }
}
