import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { ProAlertType } from '../../enums/pro-alert-type';

@Component({
  selector: 'app-pro-alert',
  templateUrl: './pro-alert.component.html',
  styleUrls: ['./pro-alert.component.scss'],
})
export class ProAlertComponent implements OnInit {

  @Input() type: ProAlertType = ProAlertType.Withdraw

  ProAlertType = ProAlertType;

  constructor( private modalCtrl: ModalController ) { }

  ngOnInit() {}

  dismiss() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }
}
