import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { RegisterComponent } from './register.component';

import { PrePageModule } from './pre/pre.module';
import { InstructionsPageModule } from './instructions/instructions.module';
import { InvitationPageModule } from './invitation/invitation.module';
import { FormPageModule } from './form/form.module';
import { ProfilePageModule } from './profile/profile.module';
import { CategoriesPageModule } from './categories/categories.module';
import { SmsPageModule } from './sms/sms.module';
import { RedirectPageModule } from './redirect/redirect.module';
import { InvitePageModule } from './invite/invite.module';
import { PostInvitePageModule } from './post-invite/post-invite.module';

@NgModule({
  declarations: [ RegisterComponent ],
  imports: [
    CommonModule,
    IonicModule,
    PrePageModule,
    InstructionsPageModule,
    InvitationPageModule,
    FormPageModule,
    ProfilePageModule,
    CategoriesPageModule,
    SmsPageModule,
    RedirectPageModule,
    InvitePageModule,
    PostInvitePageModule
  ],
  exports: [ RegisterComponent ]
})
export class RegisterModule { }
