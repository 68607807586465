<div class="steps">

  <div class="title">
    Necesitas verificar tu cuenta para desbloquear todas las funciones que ofrece Estrim.
  </div>

  <!-- Info -->
  <div class="info">
    <div class="item">
      <div class="icon user">
        <ion-icon src="assets/svg-icon/new-version/Outline_Perfil.svg"></ion-icon>
      </div>

      <div class="text">
        <div class="intro">
          Verifica tu teléfono
        </div>

        <div class="description">
          Necesitamos validar tu número de teléfono para verificar que eres una personal real.
        </div>
      </div>
    </div>
  </div>

  <!-- Buttons -->
  <div class="buttons">
    <ion-button color="darkteal" (click)="editProfile()">
      Verificar cuenta
    </ion-button>

    <ion-button fill="clear" color="dark" (click)="dismiss()" >
      Cerrar
    </ion-button>
  </div>

</div>
