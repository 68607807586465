import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { environment } from '../../environments/environment';
import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { AuthService } from './auth.service';
import { Events } from './events.service';

import { User } from '../interfaces/user';
import { Publication } from '../interfaces/publication';
import { OnLiveEvent } from '../interfaces/websocket-io-events';
import { OnLiveRecordedEvent } from '../interfaces/websocket-io-events';

import { WebsocketEvent } from '../enums/websocket-event';

@Injectable({
  providedIn: 'root'
})
export class WebsocketIoService {

  private socket: Socket;

  private user: User;

  protected _onLive = new Subject();
  onLive = this._onLive.asObservable();

  protected _onLiveRecorded = new Subject();
  onLiveRecorded = this._onLiveRecorded.asObservable();

  constructor(
    private auth: AuthService, 
    private events: Events,

  ) { 
    this.events.subscribe(environment.EVENTS.USER_LOGOUT, () => {
      this.stop();
    });

    this.events.subscribe(environment.EVENTS.MINIMIZE_APP, () => {
      this.stop();
    });

    this.events.subscribe(environment.EVENTS.RESUME_APP, () => {
      this.start();
    });

    this.events.subscribe(environment.EVENTS.AUTH_TOKEN_UPDATE, (token: string) => {
      if ( this.socket.connected ) {
        this.updateToken(token);
      }
    });

    this.auth.watchUser()
    .pipe(filter(data => !!data))
    .subscribe((user: User) => {
      this.user = user;
      this.start();
    });
  }

  private init() {    
    this.socket = io(environment.WEBSOCKET_BASE_URL, {
      reconnection: true,
      autoConnect: false,
      // transports: ['websocket'],
      extraHeaders: {
        Authorization: `Bearer ${this.auth.getToken()}`
      }
    });

    this.socket.connect();

    this.socket.on('error', (error: Error) => {
      // this.errorSubject.next('error ' + error);
    });

    this.socket.on('connect_error', (connectionError: Error) => {
      // this.errorSubject.next('connect_error ' + {connectionError?.message || connectionError);
    });

    this.socket.on('connect_timeout', (connectionError: Error) => {
      // this.errorSubject.next('connect_timeout ' + {connectionError?.message || connectionError);
    });

    this.socket.on('reconnect_error', () => {
      // this.errorSubject.next('reconnect_error');
    });

    this.socket.on('reconnect_failed', () => {
      // this.errorSubject.next('reconnect_failed');
    });

    this.socket.on(WebsocketEvent.OnLive, (publication: OnLiveEvent) => {
      this._onLive.next({status: true, publication});
    })
    this.socket.on(WebsocketEvent.OffLive, (publication: OnLiveEvent) => {
      this._onLive.next({status: false, publication});
    })
    this.socket.on(WebsocketEvent.LiveRecorded, (data: OnLiveRecordedEvent) => {
      this._onLiveRecorded.next(data);
    })
  }

  start() {
    if ( this.user ) {
      if ( this.socket?.connected ) {
        this.socket.disconnect();

        this.socket.on('disconnected', () => {
          this.socket.offAny();
          this.init()
        })
      } else {
        this.init()
      }
    }
  }
  stop() {
    this.socket?.disconnect();
    this.socket?.offAny();
  }

  updateToken(token: string) {
    this.socket.emit(WebsocketEvent.TokenUpdate, token)
  }
  
  announceLive(status: boolean, publication: Publication) {
    this.socket.emit(status ? WebsocketEvent.OnLive : WebsocketEvent.OffLive, publication)
  }
}
