import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/app/interfaces/user';

@Component({
  selector: 'app-pinganillo',
  templateUrl: './pinganillo.component.html',
  styleUrls: ['./pinganillo.component.scss'],
})
export class PinganilloComponent implements OnInit {
  @Input() user: User = undefined;
  @Output() send = new EventEmitter();
  message: string;

  constructor() {
    this.message = '';
  }

  ngOnInit() {}

  public sending(){
    this.send.emit(this.message);
    this.message = '';
  }

}
