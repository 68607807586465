<ion-header class="general-header-app">
  <app-header 
    [title]="type === ContentType.Event ? 'Eventos' : 'Estrenos'" 
    [menuButton]="false"
    [defaultBackAction]="false"
    (back)="dismiss()">    
  </app-header>
</ion-header>

<ion-content>
  <div class="section">
    <div class="header" (click)="showInfo()">
      <h4 class="title" *ngIf="type === ContentType.Event">Eventos <span class="primary-color">virtuales</span></h4>
      <h4 class="title" *ngIf="type === ContentType.Premiere">Nuevos <span class="primary-color">Estrenos</span></h4>
      <ion-icon src="assets/svg-icon/info.svg"></ion-icon>
    </div>

    <div class="list">
      <div class="add-element" (click)="goToForm()">
        <app-event-thumbnail 
          [premieres]="type === ContentType.Premiere"
          [skeleton]="true" 
          [animatedSkeleton]="false">
        </app-event-thumbnail>

        <div class="icon">
          <ion-icon name="add-outline"></ion-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="section">
    <div class="header">
      <h4 class="title" *ngIf="type === ContentType.Event">Eventos <span class="primary-color">programados</span></h4>
      <h4 class="title" *ngIf="type === ContentType.Premiere">Estrenos <span class="primary-color">programados</span></h4>
    </div>

    <div class="list">
      <div class="loading" *ngIf="loading">
        <ion-spinner color="primary" name="crescent"></ion-spinner>
      </div>

      <ng-container *ngIf="!loading">
        <app-event-thumbnail *ngFor="let event of events"
          [premieres]="type === ContentType.Premiere"
          [event]="event">
        </app-event-thumbnail>
      </ng-container>
    </div>
  </div>

  <ion-infinite-scroll #infiniteScroll threshold="100px" (ionInfinite)="requestData()">
    <ion-infinite-scroll-content
      loadingSpinner="crescent"
      loadingText="">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
