<ion-content [scrollY]="false">
  <ion-list lines="none" *ngIf="!isForDeleted">
    <ion-item *ngIf="canResponse" button detail="false" (click)="pickOption(MessagePopoverOption.Reply)">
      <ion-label>Responder</ion-label>
      <ion-icon slot="end" name="arrow-undo-outline"></ion-icon>
    </ion-item>
    <ion-item button detail="false" *ngIf="copyable" (click)="pickOption(MessagePopoverOption.Copy)">
      <ion-label>Copiar</ion-label>
      <ion-icon slot="end" name="copy-outline"></ion-icon>
    </ion-item>
    <ion-item button detail="false" *ngIf="!like" (click)="pickOption(MessagePopoverOption.Like)">
      <ion-label>Me gusta</ion-label>
      <ion-icon slot="end" name="heart-outline"></ion-icon>
    </ion-item>
    <ion-item button detail="false" *ngIf="like" (click)="pickOption(MessagePopoverOption.Unlike)">
      <ion-label>No me gusta</ion-label>
      <ion-icon slot="end" name="heart-dislike-outline"></ion-icon>
    </ion-item>
    <!-- <ion-item button detail="false" (click)="pickOption(MessagePopoverOption.Reply)">
      <ion-label>Responder</ion-label>
      <ion-icon slot="end" name="arrow-undo-outline"></ion-icon>
    </ion-item>
    <ion-item button detail="false" *ngIf="downloadable" (click)="pickOption(MessagePopoverOption.Save)">
      <ion-label>Descargar</ion-label>
      <ion-icon slot="end" name="download-outline"></ion-icon>
    </ion-item> -->
    <ion-item button detail="false" (click)="pickOption(MessagePopoverOption.Delete)">
      <ion-label color="danger">Eliminar</ion-label>
      <ion-icon slot="end" color="danger" name="trash-outline"></ion-icon>
    </ion-item>
  </ion-list>
  <ion-list lines="none" *ngIf="isForDeleted">
    <ion-item button detail="false" (click)="pickOption(MessagePopoverOption.Delete)">
      <ion-label ngClass="remove" color="danger">Eliminar</ion-label>
    </ion-item>
  </ion-list>
</ion-content>
