<div class="er-modal">
  <div class="content text-center">
    <img class="thumb-bg rounded" src="assets/extrim/img/backgrounds/bg_modal.webp" alt="Background" />
    <a (click)="dismiss()" class="close dialog-close">
      <img src="assets/extrim/img/icons/close-white.svg" width="30" />
    </a>
    <div class="thumb" [ngClass]="{
      'icon': pilot.winner || pilot.loser,
      'winner': pilot.winner,
      'loser': pilot.loser,
    }">
      <lazy-img [source]="mediaService.generateThumbnailImgURL(pilot.thumbnail)" [size]="400"></lazy-img>
    </div>
    <p class="mb-1" *ngIf="!pilot.winner && !pilot.loser">Vas a votar a</p>
    <img class="mb-2" *ngIf="pilot.winner || pilot.loser" src="assets/extrim/img/icons/lock.svg" width="20" />
    <div class="driver-name mb-2 font-venera">{{pilot.name}}</div>
    <p *ngIf="state === ExtrimState.BeforeRace">Recuerda que puedes cambiar tu voto hasta el día de la carrera.</p>
    <p *ngIf="state === ExtrimState.Live && !pilot.winner && !pilot.loser">Podrás cambiar tu voto hasta una hora antes de la gymkhana final.</p>
    <p *ngIf="state === ExtrimState.Live && pilot.winner">Está inmunizado desde la votación anterior. Vota a otro piloto.</p>
    <p *ngIf="state === ExtrimState.Live && pilot.loser">Ya lleva un lastre de 120kg desde la votación anterior. Vota a otro piloto.</p>
    <a *ngIf="!loading && !pilot.winner && !pilot.loser" (click)="vote()" class="button">VOTAR</a>
    <a *ngIf="loading && !pilot.winner && !pilot.loser" class="button">
      <ion-spinner color="light" name="crescent"></ion-spinner>
    </a>
  </div>
</div>