import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { environment } from '../../../environments/environment';

import { ValidatorService } from '../../services/validator.service';
import { Events } from '../../services/events.service';

import { mobilePhoneValidatorES, phoneValidatorGlobal } from '../../form-validators/phone.validator';

@Component({
  selector: 'app-phone-check',
  templateUrl: './phone-check.component.html',
  styleUrls: ['./phone-check.component.scss', '../../../theme/field.scss'],
})
export class PhoneCheckComponent implements OnInit {

  @Input() title: string = 'Comprueba tu teléfono';
  @Input() okButtonText: string = 'Es correcto';
  @Input() countries: any[] = [];
  @Input() selectedCountry: any = {};
  @Input() phoneNumber: string = '';

  @Output() onChangeFlag = new EventEmitter();
  @Output() onValidate = new EventEmitter();

  phoneForm: UntypedFormGroup;

  phoneHaveToBeChecked: boolean = false;
  checkingPhone: boolean = true;

  error: any = {};
  errorMessage: string = '';

  checkPhoneTimeout: any;

  constructor(
    private modalCtrl: ModalController,
    private validatorService: ValidatorService,
    private formBuilder: UntypedFormBuilder,
    private events: Events,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.phoneForm = this.formBuilder.group({
      phone: [this.phoneNumber, [ this.selectedCountry.code === '34' ? mobilePhoneValidatorES : phoneValidatorGlobal ]]
    });

    this.checkPhoneExists();
  }

  changeFlag(event: any) {
    const selectedCountry = this.countries
      .find(c => c.code === event.detail.value.code && c.countryName === event.detail.value.countryName);

    if ( selectedCountry ) {
      this.selectedCountry = selectedCountry;

      this.onChangeFlag.emit(event);

      // @ts-ignore
      if ( this.selectedCountry.code === '34' ) {
        this.phoneForm.controls.phone.setValidators([mobilePhoneValidatorES]);
      } else {
        this.phoneForm.controls.phone.setValidators([phoneValidatorGlobal]);
      }

      this.phoneForm.controls.phone.updateValueAndValidity();


      this.checkPhoneExists();
    }
  }

  checkPhoneExists(debounce: boolean = false) {

    this.phoneHaveToBeChecked = true;

    clearTimeout(this.checkPhoneTimeout);
    this.checkPhoneTimeout = setTimeout(() => {
      if (!this.phoneForm.controls.phone.valid) {
        this.error.phoneInvalid = true;
        this.phoneHaveToBeChecked = false;
        return;
      } else {
        this.error.phoneInvalid = false;
      }
      this.checkingPhone = true;
  
      // @ts-ignore
      this.validatorService.checkPhoneExists(this.phoneForm.controls.phone.value, this.selectedCountry.code)
        .subscribe(
          res => {
  
            if (res?.exists) {
              this.error.phoneExists = true;
              this.errorMessage = 'El teléfono ya existe';
              this.phoneForm.controls.phone.setErrors({alreadyExists: true});
            } else if ( !res?.is_valid ) {
              this.error.phoneExists = true;
              this.errorMessage = res.msg;
              this.phoneForm.controls.phone.setErrors({alreadyExists: true});
            } else {
              this.error.phoneExists = false;
              this.errorMessage = '';
              this.phoneForm.controls.phone.setErrors(null);
            }
  
            this.checkingPhone = false;
            this.phoneHaveToBeChecked = false;
            this.cdRef.detectChanges();
          }, error => {
            if ( error.error.msg ) {
              this.errorMessage = error.error.msg;
            }
  
            this.phoneForm.controls.phone.setErrors({alreadyExists: true});
  
            this.error.phoneExists = true;
            this.checkingPhone = false;
            this.phoneHaveToBeChecked = false;
            this.cdRef.detectChanges();
          }
        );
    }, debounce ? 1000 : 0)

  }

  validate() {
    this.phoneForm.controls.phone.markAsTouched();
    if (!this.phoneForm.valid || this.checkingPhone) {return;}

    const data = {
      verified: true,
      // @ts-ignore
      code: this.selectedCountry.code,
      phone: this.phoneForm.controls.phone.value
    };

    this.onValidate.emit(data);
    this.modalCtrl.dismiss(data);
  }
}
