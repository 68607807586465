import { Component, OnInit, OnDestroy } from '@angular/core';

import { CreateContentService } from '../../services/create-content.service';

import { ContentType } from '../../enums/content-type';
import { FormMode } from '../../enums/form-mode';

import { FilePickerService } from '../../services/file-picker.service';

@Component({
  selector: 'app-choose-content-type',
  templateUrl: './choose-content-type.component.html',
  styleUrls: ['./choose-content-type.component.scss'],
})
export class ChooseContentTypeComponent implements OnInit, OnDestroy {

  ContentType = ContentType;

  sub: any = {};

  chosenType: ContentType = undefined;

  constructor( 
    private createContentService: CreateContentService,
    private filePickerService: FilePickerService
  ) { }

  ngOnInit() {
    this.sub.filePicked = this.filePickerService.filePicked.subscribe((file: File) => {
      this.dismiss();

      if ( file !== undefined ) {
        this.createContentService.openCreateStaticContent(this.chosenType, FormMode.Create, file);
      }
    })
  }

  ngOnDestroy() {
    this.sub.filePicked.unsubscribe();
  }

  dismiss() {
    this.createContentService.closeModal();
  }

  choose(type: ContentType) {
    this.chosenType = type;
    this.filePickerService.pickFile(type);
  }

  showInfo() {
    this.createContentService.openStaticContentInfoModal();
    this.dismiss();
  }
}
