import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse, HttpHeaders
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, from } from 'rxjs';
import { catchError, switchMap, filter, take, finalize, tap, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { Events } from '../services/events.service';
import { Platform } from '@ionic/angular';

type HttpMethod = 'get' | 'post' | 'put' | 'patch' | 'head' | 'delete' | 'upload' | 'download';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private router: Router, 
    private auth: AuthService, 
    private events: Events,
    private platform: Platform
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.injectToken(request)).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            case 401:
              if (!this.auth.getRefreshToken()
                || request.url.indexOf('api/user/refresh-token') !== -1) {
                this.logout();
                break;
              } else {
                return this.handle401Error(request, next);
              }
            case 400:
            case 403:
              this.logout();
              break;
            case 429:
              this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Has excedido el limite de peticiones, intentalo de nuevo más tarde.');
              break;
          }
        }
        return throwError(error);
      })
    );
  }

  private logout = () => {
    if (this.isRefreshing) {
      this.isRefreshing = false;
      this.events.publish('user:logout');
    }
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.auth.refreshToken().pipe(
        switchMap((response: any) => {
          this.isRefreshing = false;
          if (response.done) {
            this.events.publish(environment.EVENTS.AUTH_TOKEN_UPDATE, response.data.access_token);
            this.refreshTokenSubject.next(response.data.access_token);
          }

          return next.handle(this.injectToken(request));
        }), catchError((e) => throwError(e))
      );
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token != null),
      take(1),
      switchMap(() => {
        return next.handle(this.injectToken(request));
      })
    );
  }

  private injectToken(request: HttpRequest<any>) {
    if ((request.url.indexOf('fileuploader') === -1 && request.url.indexOf(environment.API_BASE_URL + 'api/') === -1) || 
        request.url.indexOf('refresh_token') !== -1) {
      return request;
    }

    const token = this.auth.getToken();

    if (token) {
      request = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`
        }
      });
    }

    return request;
  }
}
