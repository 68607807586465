import { Component, OnInit } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { environment } from '../../../../../environments/environment';
import { filter } from 'rxjs';

import { ExtrimService } from 'src/app/services/extrim.service';
import { MediaService } from 'src/app/services/media.service';

import { BaseComponent as ExtrimPollBaseComponent } from '../base.component';

import { RankingPage } from '../ranking/ranking.page';
import { InfoPage } from '../info/info.page';
import { InfoLivePage } from '../info-live/info-live.page';

import { VoteConfirmComponent } from '../vote-confirm/vote-confirm.component';

import { ExtrimState } from 'src/app/enums/extrim-state';

@Component({
  selector: 'app-pilots',
  templateUrl: './pilots.page.html',
  styleUrls: environment.isWeb ? ['./pilots.page.scss', '../../../../../assets/extrim/css/main.css', '../../../../../assets/extrim/css/main-desktop.css'] : ['./pilots.page.scss', '../../../../../assets/extrim/css/main.css'],
})
export class PilotsPage extends ExtrimPollBaseComponent implements OnInit {

  constructor(
    private extrimRace: ExtrimService,
    public mediaService: MediaService,
    protected nav: IonNav,
    protected modalCtrl: ModalController 
  ) {
    super(nav, modalCtrl)
  }

  ngOnInit(): void {
    this.sub.ranking = this.extrimRace.ranking
    .pipe(
      filter(data => !!data),
    ).subscribe(data => {
      this.pilots = data;
      this.pilots.sort((a,b) => b.order + a.order);
    })
  }

  nextPage(votedPilot: any) {
    this.next(RankingPage, this.user, votedPilot, this.state);
  }

  showInfo() {
    if ( this.state === ExtrimState.Live ) {
      this.next(InfoLivePage, this.user);
    } else {
      this.next(InfoPage, this.user);
    }
  }

  async confirmVote(pilot: any) {
    const modal = await this.modalCtrl.create({
      cssClass: "fit-modal floating-modal box-shadow-none",
      component: VoteConfirmComponent,
      componentProps: { pilot, state: this.state },
      mode: "ios",
    });
    modal.present();
    modal.onWillDismiss().then(result => {
      if ( result.role === 'confirm' ) {
        this.pilots.forEach(pilot => pilot.voted = false);
          this.pilots.find(pilot => pilot.name === result.data.name)
            .voted = true;

        this.extrimRace.getRanking().subscribe();
        this.nextPage(result.data);
      }
    })
  }
}
