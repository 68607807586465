<ion-item *ngIf="checkFieldInvalid(form.get(formName))" lines="none"
          class="ion-text-wrap form-error">
  <div class="ion-text-left">
    <ion-text color="danger">
      <small>
        <ion-icon name="alert-circle-outline"></ion-icon>
        <span>{{errorMessage()}}</span>
      </small>
    </ion-text>
  </div>
</ion-item>
