export const COUNTRIES = [
  {
    code: '54',
    countryName: 'Argentina',
    countryImg: 'ar'
  },
  {
    code: '591',
    countryName: 'Bolivia',
    countryImg: 'bo'
  },
  {
    code: '56',
    countryName: 'Chile',
    countryImg: 'cl'
  },
  {
    code: '57',
    countryName: 'Colombia',
    countryImg: 'co'
  },
  {
    code: '506',
    countryName: 'Costa Rica',
    countryImg: 'cr'
  },
  {
    code: '53',
    countryName: 'Cuba',
    countryImg: 'cu'
  },
  {
    code: '593',
    countryName: 'Ecuador',
    countryImg: 'ec'
  },
  {
    code: '503',
    countryName: 'El Salvador',
    countryImg: 'sv'
  },
  {
    code: '34',
    countryName: 'España',
    countryImg: 'es'
  },
  {
    code: '502',
    countryName: 'Guatemala',
    countryImg: 'gt'
  },
  {
    code: '240',
    countryName: 'Guinea Ecuatorial',
    countryImg: 'gq'
  },
  {
    code: '504',
    countryName: 'Honduras',
    countryImg: 'hn'
  },
  {
    code: '52',
    countryName: 'México',
    countryImg: 'mx'
  },
  {
    code: '505',
    countryName: 'Nicaragua',
    countryImg: 'ni'
  },
  {
    code: '507',
    countryName: 'Panamá',
    countryImg: 'pa'
  },
  {
    code: '51',
    countryName: 'Perú',
    countryImg: 'pe'
  },
  {
    code: '1',
    countryName: 'Puerto Rico',
    countryImg: 'pr'
  },
  {
    code: '1',
    countryName: 'República Dominicana',
    countryImg: 'do'
  },
  {
    code: '598',
    countryName: 'Uruguay',
    countryImg: 'uy'
  },
  {
    code: '58',
    countryName: 'Venezuela',
    countryImg: 've'
  },
  {
    code: '376',
    countryName: 'Andorra',
    countryImg: 'ad'
  },
  {
    code: '1',
    countryName: 'EEUU',
    countryImg: 'us'
  },
  {
    code: '595',
    countryName: 'Paraguay',
    countryImg: 'py'
  }
];
