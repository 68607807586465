import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter, ElementRef, NgZone, AfterViewChecked } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { skipWhile, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { MediaService } from 'src/app/services/media.service';
import { UserService } from 'src/app/services/user.service';
import { PublicationService } from 'src/app/services/publication.service';
import { Events } from 'src/app/services/events.service';
import { AuthService } from 'src/app/services/auth.service';
import { AnalyticsService } from 'src/app/services/analytics.service';

import { Publication } from 'src/app/interfaces/publication';
import { Event } from 'src/app/interfaces/event';
import { User } from 'src/app/interfaces/user';
import { JsonApiResponse } from 'src/app/interfaces/JsonApiResponse';

import { ContentType } from 'src/app/enums/content-type';
import { BlockPopoverOption } from 'src/app/enums/block-popover-option';

import { goToUserProfile } from 'src/app/utils/routing';

import { inOutStarAnimation1, starAnimation1 } from 'src/app/animations/animations';

@Component({
  template: '',
  animations: [
    inOutStarAnimation1,
    starAnimation1
  ]
})
export class PublicationThumbnailsBaseComponent implements OnInit, AfterViewChecked {

  @Input() publication: Publication | Event = undefined;
  @Input() user: User;
  @Input() showDots = false;
  @Input() skeleton = false;
  @Input() calculateHeight: boolean = false;

  @Output() heightCalculated: EventEmitter<number> = new EventEmitter();

  ContentType = ContentType;

  animateLikeThumbnail: boolean = false;
  animateLikeIcon: boolean = false;

  componentHeight: number = 0;

  constructor(
    protected el: ElementRef,
    protected zone: NgZone,
    protected mediaService: MediaService,
    protected userService: UserService,
    protected publicationService: PublicationService,
    protected events: Events,
    protected authService: AuthService,
    protected analyticsService: AnalyticsService,
    protected router: Router,
    protected cdRef: ChangeDetectorRef
  ) {}

  /**
   * Get the publication content type
   * @returns content type
   */
  get publicationType(): ContentType {
    if ( (this.publication as Publication)?.publicationType ) {
      if ( (this.publication as Publication)?.publicationType === 'clip' ) {
        return ContentType.Clip
      } else if ( (this.publication as Publication).onlyAudio ) {
        if ( (this.publication as Publication)?.publicationType === 'live' ) {
          return ContentType.LiveAudio
        } else if ( (this.publication as Publication)?.publicationType === 'uploaded' && !(this.publication as Publication)?.isOne ) {
          return ContentType.Audio
        } else if ( (this.publication as Publication)?.publicationType === 'uploaded' && (this.publication as Publication)?.isOne ) {
          return ContentType.OneAudio
        }
      } else if ( !(this.publication as Publication).onlyAudio ) { 
        if ( (this.publication as Publication)?.publicationType === 'live' ) {
          return ContentType.LiveVideo
        } else if ( (this.publication as Publication)?.publicationType === 'uploaded' && !(this.publication as Publication)?.isOne ) {
          return ContentType.Video
        } else if ( (this.publication as Publication)?.publicationType === 'uploaded' && (this.publication as Publication)?.isOne ) {
          return ContentType.OneVideo
        }
      }
    } else {
      if ( (this.publication as Event).eventType === 'premiere' ) {
        return ContentType.Premiere;
      } else {
        return ContentType.Event;
      }
    }
  }

  ngOnInit() {
    // If no user passed to input, get own user from auth service
    if ( !this.user ) {
      this.authService.watchUser()
      .pipe(
        skipWhile(data => !data),
        take(1))
      .subscribe((user: User) => {
        this.user = user;
      });
    }
  }

  ngAfterViewChecked() {
    if ( this.calculateHeight && this.componentHeight === 0 ){
      this.zone.runOutsideAngular(() => {
        const height = this.el.nativeElement.getBoundingClientRect().height;
        if ( height !== 0 && height !== this.componentHeight ) {
          this.componentHeight = height;
          this.heightCalculated.emit(height);
        }
      });
    }
  }


  /**
   * Get publication thumbnail
   * @returns url of the thumbnail
   */
  getThumbnail() {
    return this.mediaService.generatePublicationThumbnailImgURL(this.publication.id, this.publication.thumbnail);
  }
  /**
   * Get user image
   * @returns url of the image
   */
  getUserImg() {
    return this.mediaService.generateImgProfileURL(this.publication.author.id, this.publication.author.imgProfile);
  }

  /**
   * Check if publication is premium
   * @returns state
   */
  isPremium(): boolean {
    return (this.publication as Publication)?.isPremium;
  }
  /**
   * Check if publication is locked for me
   * @returns state
   */
  isLocked(): boolean {
    return (this.publication as Publication)?.isPremium && !(this.publication.collaborating || this.publication.subscribed || this.publication.author.id === this.user?.id)
  }

  /**
   * Check if user can view publication and navigate
   * @param path custom publication path
   */
  enterPublication(path: string) {
    if (this.isLocked()) {
      this.userService.subscribeToUser(this.publication.author);
    } else {
      this.router.navigate([`${path}/${this.publication.id}`], { state: {publication: this.publication} });
    }
  }

  /**
   * Go to user profile
   */
  goToUserProfile() {
    this.router.navigate([goToUserProfile(this.router.url, this.publication.author.username)]);
  }

  /**
   * Save publication
   */
  savePublication() {
    // Manually change status
    (this.publication as Publication).isSaved = !(this.publication as Publication).isSaved;

    let request: Observable<JsonApiResponse<any>>;

    if ( this.publicationType === ContentType.Event ) {
      request = this.publicationService.saveEvent(this.publication.id);
    } else {
      request = this.publicationService.savePublication(this.publication.id);
    }

    request.subscribe(
      res => {
        if (res.done) {
          (this.publication as Publication).isSaved ? this.events.publish(environment.EVENTS.ALERT_TOAST_NOTICE, 'Guardado con éxito') : null;

          this.analyticsService.logEvent('publication_save', {
            publication_type: this.publicationType,
            pulication_id: this.publication.id,
            from_thumbnail: true
          });
        } else {
          // Revert change
          (this.publication as Publication).isSaved = !(this.publication as Publication).isSaved;
          this.events.publish(environment.EVENTS.ALERT_TOAST_NOTICE, res.msg);
        }
      }, error => {
        // Revert change
        (this.publication as Publication).isSaved = !(this.publication as Publication).isSaved;
        this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR);
      }
    );
  }

  /**
   * Share publication
   * @param path custom publication path
   */
  sharePublication(path: string) {
    const url = `${environment.BASE_URL}${path}/${this.publication.id}`;
    this.userService.shareUrl('Mira este contenido de Estrim', url).then(result => {
      if ( result ) {
        this.analyticsService.logEvent('publication_share', {
          publication_type: this.publicationType,
          pulication_id: this.publication.id,
          from_thumbnail: true
        });
      }
    });
  }

  /** 
   * Like publication
   */
  likePublication() {
    (this.publication as Publication).isLiked = !(this.publication as Publication).isLiked;

    const origLikesNumber = (this.publication as Publication).likesNumber;
    
    // Animate counter
    if ( (this.publication as Publication).isLiked ){
      this.animateLikeThumbnail = true;
      this.animateLikeIcon = true;
      (this.publication as Publication).likesNumber++;
    } else {
      (this.publication as Publication).likesNumber--;
    }
    
    this.cdRef.detectChanges();

    this.publicationService.likePublication(this.publicationType, this.publication.id, (this.publication as Publication).isLiked).subscribe(
      res => {
        if ( !res.done ) {
          // Revert change
          (this.publication as Publication).isLiked = !(this.publication as Publication).isLiked;
          this.events.publish(environment.EVENTS.ALERT_TOAST_NOTICE, res.msg);
          (this.publication as Publication).likesNumber = origLikesNumber;
        } else {
          this.analyticsService.logEvent('publication_like', {
            publication_type: this.publicationType,
            pulication_id: this.publication.id,
            from_thumbnail: true
          });
        }
      }, error => {
        // Revert change
        (this.publication as Publication).isLiked = !(this.publication as Publication).isLiked;
        this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR);
        (this.publication as Publication).likesNumber = origLikesNumber;
      }
    )
  }

  /**
   * Show block popover
   * @param $event 
   * @param type ContentType
   */
  presentBlockPopover($event, type: ContentType) {
    this.publicationService
      .presentBlockPopover($event, type, (this.publication as Publication))
      .then((result: BlockPopoverOption | undefined) => {
        if ( result && (result as BlockPopoverOption) === BlockPopoverOption.Report ) {
          this.userService.report(this.publication.id, type, true, false)
        }
      });
  }
}
