import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { filter } from 'rxjs/operators';

import { MediaService } from 'src/app/services/media.service';
import { PublicationService } from 'src/app/services/publication.service';
import { ProfileService } from 'src/app/services/profile.service';
import { PaymentService } from 'src/app/services/payment.service';

import { User } from 'src/app/interfaces/user';
import { Collaborator } from 'src/app/interfaces/collaborator';
import { Subscription } from 'src/app/interfaces/subscription';
import { StarTransaction } from 'src/app/interfaces/star-transaction';
import { Publication } from 'src/app/interfaces/publication';

import { CacheData } from 'src/app/enums/cache-data';

@Component({
  selector: 'app-star-sender-list',
  templateUrl: './star-sender-list.component.html',
  styleUrls: ['./star-sender-list.component.scss'],
})
export class StarSenderListComponent  implements OnInit, OnDestroy {

  @Input() publication: Publication = undefined;
  @Input() count: number = 0;
  @Input() transactions: any;
  @Input() collaborators: Collaborator[] = [];
  @Input() subscribers: Subscription[] = [];

  transactionsParsed: any[] = [];

  loading: boolean = true;

  sub: any = {};

  constructor(
    private mediaService: MediaService,
    private publicationService: PublicationService,
    private profileService: ProfileService,
    private paymentService: PaymentService,
    private cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    if ( this.transactions ) {
      this.transactionsParsed = this.parseTransactions(this.transactions);
      this.loading = false;
    } else if ( this.publication && !this.publication.starTransactions ) {
      this.loading = true;
      this.transactions = [];
      this.getTransactions();
    } else if ( this.publication && this.publication.starTransactions ) {
      this.transactions = this.publication.starTransactions;
      this.count = this.publication.stars;
      this.transactionsParsed = this.parseTransactions(this.transactions);
      this.loading = false;
      this.getTransactions();
    } else {
      // Dismiss
    }

    // Get collaborators
    this.sub.collaboratos = this.profileService.watchCacheData(CacheData.MyCollaborators)
      .pipe(filter(data => !!data))
      .subscribe(data => {
        this.collaborators = data.accepted;
        this.cdRef.detectChanges();
      })

    this.sub.subscribers = this.paymentService.watchCacheData(CacheData.MySubscribers)
      .pipe(filter(data => !!data))
      .subscribe(data => {
        this.subscribers = data;
        this.cdRef.detectChanges();
      })
  }
  ngOnDestroy() {
    this.sub.collaboratos?.unsubscribe();
    this.sub.subscribers?.unsubscribe();
  }

  getTransactions() {
    // Fetch transactions
    this.publicationService.getPublicationStarHistory(this.publication.id).subscribe(
      res => {
        if ( res.done ) {
          this.transactions = res.history;
          this.publication.starTransactions = this.transactions;
          this.count = this.publication.stars;
          this.transactionsParsed = this.parseTransactions(this.transactions);
          this.loading = false;
          this.cdRef.detectChanges();
        } else {
          // Should we return a feedback?
          this.loading = false;
          this.cdRef.detectChanges();
        }
      }, err => {
        // Should we return a feedback?
        this.loading = false;
        this.cdRef.detectChanges();
      }
    );
  }

  parseTransactions(transactions: StarTransaction[]): any[] {
    const array = [];

    // Get all object keys
    Object.keys(transactions).forEach((key, value) => {
      // Generate parsed array
      array.push({
        user: transactions[key][0].from_user,
        // Add ammount of all transactions
        quantity: transactions[key]
          .map(item => item.quantity)
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
      })
    })

    // Order array
    array.sort((a,b) => (a.quantity < b.quantity) ? 1 : ((b.quantity < a.quantity) ? -1 : 0));

    return array;
  }

  getImgProfile(user: User) {
    return this.mediaService.generateImgProfileURL(user.id, user.imgProfile);
  }

  getBadgeSrc(user: User) {
    return this.mediaService.getBadgeSrc(user);
  }

  userIsCollaborator(userId: number) {
    if (this.collaborators && this.collaborators.length)
      return this.collaborators.some(c => c.user.id === Number(userId));
    else
      return false;
  }

  userIsSubscribed(userId: number) {
    if (this.subscribers && this.subscribers.length)
      return this.subscribers.some(s => s.subscriberUser.id === Number(userId));
    else
      return false;
  }
}
