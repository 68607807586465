import { Component, OnInit, Input } from '@angular/core';

import { MediaService } from '../../services/media.service';

import { User } from '../../interfaces/user';

@Component({
  selector: 'app-user-display',
  templateUrl: './user-display.component.html',
  styleUrls: ['./user-display.component.scss'],
})
export class UserDisplayComponent implements OnInit {

  @Input() user: User;

  constructor( private mediaService: MediaService ) { }

  ngOnInit() {}

  getImgProfile() {
    return this.mediaService.generateImgProfileURL(this.user?.id, this.user?.imgProfile);
  }
}
