import { Directive, Input, ElementRef, HostListener } from "@angular/core";
import { AnimationController } from '@ionic/angular';
import { createAnimation, Animation } from '@ionic/core';

import { ModalController } from '@ionic/angular';
import { ProfileAvatarZoomComponent } from '../components/profile-avatar-zoom/profile-avatar-zoom.component';

// function myEnterAnimation(
//   AnimationC: Animation,
//   baseEl: HTMLElement
// ): Promise<Animation> {
//   const baseAnimation = new AnimationC();
//   const backdropAnimation = new AnimationC();
//   backdropAnimation.addElement(baseEl.querySelector('ion-backdrop'));
//   const wrapperAnimation = new AnimationC();
//   wrapperAnimation.addElement(baseEl.querySelector('.modal-wrapper'));
//   wrapperAnimation
//     .beforeStyles({ opacity: 1 })
//     .fromTo('translateX', '-100%', '0%');
//   backdropAnimation.fromTo('opacity', 0.01, 0.4);
//   return Promise.resolve(
//     baseAnimation
//       .addElement(baseEl)
//       .easing('cubic-bezier(0.36,0.66,0.04,1)')
//       .duration(400)
//       .beforeAddClass('show-modal')
//       .add(backdropAnimation)
//       .add(wrapperAnimation)
//   );
// }


@Directive({
  selector: "img[avatarZoomable]",
})
export class AvatarZoomableDirective {

  @Input() zoomableSrc: string;

  constructor(
    private element: ElementRef,
    private modalCtrl: ModalController,
    private animationCtrl: AnimationController
  ) {}

  @HostListener("click", ['$event.target'])
  private async zoom($event) {
    if ( !this.element.nativeElement.src.includes('assets/no-image') ) {
      // const domRect = $event.getBoundingClientRect();

      const enterAnimation = (baseEl: HTMLElement) => {
        const root = baseEl.shadowRoot;

        const backdropAnimation = this.animationCtrl.create()
          .addElement(root.querySelector('ion-backdrop')!)
          .fromTo('opacity', '0', 'var(--backdrop-opacity)');

        const wrapperAnimation = this.animationCtrl.create()
          .addElement(root.querySelector('.modal-wrapper')!)
          .fromTo('transform', 'translateY(-100%)', 'translateY(0)');

        return this.animationCtrl.create()
          .addElement(baseEl)
          .easing('cubic-bezier(0.36,0.66,0.04,1)')
          .duration(500)
          .addAnimation([backdropAnimation, wrapperAnimation]);
      }

      const leaveAnimation = (baseEl: HTMLElement) => {
        const root = baseEl.shadowRoot;

        const backdropAnimation = this.animationCtrl.create()
          .addElement(root.querySelector('ion-backdrop')!)
          .fromTo('opacity', 'var(--backdrop-opacity)', '0');

        const wrapperAnimation = this.animationCtrl.create()
          .addElement(root.querySelector('.modal-wrapper')!)
          .fromTo('transform', 'translateY(0%)', 'translateY(-100%)');

        return this.animationCtrl.create()
          .addElement(baseEl)
          .easing('cubic-bezier(0.36,0.66,0.04,1)')
          .duration(500)
          .addAnimation([backdropAnimation, wrapperAnimation]);
      }

      const modal = await this.modalCtrl.create({
        cssClass: "fit-modal floating-modal profile-avatar",
        component: ProfileAvatarZoomComponent,
        enterAnimation,
        leaveAnimation,
        mode: 'ios',
        componentProps: {
          imageSrc: this.zoomableSrc
        }
      });
      modal.present();

      const {} = await modal.onWillDismiss();
    }
  }
}