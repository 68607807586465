import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-availability-alert',
  templateUrl: './availability-alert.component.html',
  styleUrls: ['./availability-alert.component.scss'],
})
export class AvailabilityAlertComponent  implements OnInit {

  @Input() text: string = "";

  constructor( private modalCtrl: ModalController ) { }

  ngOnInit() {}

  dismiss() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }
}
