import { Component, OnInit, Input } from '@angular/core';

import { MediaService } from '../../services/media.service';
import { UserService } from '../../services/user.service';

import { User } from '../../interfaces/user';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {

  @Input() user: User = undefined;

  constructor(
    private mediaService: MediaService,
    private userService: UserService
  ) { }

  ngOnInit() {}

  getImgProfile() {
    return this.mediaService.generateImgProfileURL(this.user?.id, this.user?.imgProfile);
  }

  close() {
    this.userService.closeWelcome();
  }
}
