import { Component, Input, OnChanges } from '@angular/core';
import { Transaction } from '../../interfaces/transaction';
import { User } from '../../interfaces/user';
import { MediaService } from '../../services/media.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {Router} from '@angular/router';
import {goToUserProfile} from '../../utils/routing';

@Component({
  selector: 'my-wallet-transaction-entry',
  templateUrl: './my-wallet-transaction-entry.component.html',
  styleUrls: ['./my-wallet-transaction-entry.component.scss'],
})
export class MyWalletTransactionEntryComponent implements OnChanges {

  @Input() transaction: Transaction;
  @Input() user: User;
  @Input() skeleton: boolean;

  transactionContent: SafeHtml;


  constructor(private mediaService: MediaService, private sanitized: DomSanitizer,private router: Router) {
  }

  ngOnChanges() {
    if ( this.transaction !== undefined && this.transaction.description !== undefined ) {
      // Replace star emoji with star icon
      this.transactionContent = this.sanitized.bypassSecurityTrustHtml(
        this.transaction.description
          .replace('🌟', '<img handleError class="star" src="/assets/star-l.png"/>')
      );
    }
  }

  getGoToUserProfile(transaction: Transaction) {
    this.router.navigate([goToUserProfile('',
      transaction.profit ? transaction?.fromUser?.username : transaction?.toUser?.username)], {replaceUrl: false});
  }

  getImgProfile() {
    if ( this.transaction !== undefined && this.transaction.toUser !== undefined ) {
      if (this.transaction.profit) {
        return this.mediaService.generateImgProfileURL(this.transaction?.fromUser?.id, this.transaction?.fromUser?.imgProfile);
      }
      return this.mediaService.generateImgProfileURL(this.transaction?.toUser?.id, this.transaction?.toUser?.imgProfile);
    }
  }
}
