import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { PollComponent } from './poll.component';

import { VoteConfirmComponent } from './vote-confirm/vote-confirm.component';

import { IntroPageModule } from './intro/intro.module';
import { PilotsPageModule } from './pilots/pilots.module';
import { RankingPageModule } from './ranking/ranking.module';
import { InfoPageModule } from './info/info.module';
import { InfoLivePageModule } from './info-live/info-live.module';
import { IntroLivePageModule } from './intro-live/intro-live.module';
import { FinalPageModule } from './final/final.module';

import { LazyImgModule } from 'src/app/components/lazy-img/lazy-img.module';

@NgModule({
  declarations: [PollComponent, VoteConfirmComponent],
  imports: [
    CommonModule,
    IonicModule,
    IntroPageModule,
    PilotsPageModule,
    RankingPageModule,
    InfoPageModule,
    InfoLivePageModule,
    IntroLivePageModule,
    FinalPageModule,
    LazyImgModule
  ],
  exports: [ PollComponent ]
})
export class PollModule {}
