<swiper #swiper [config]="swiperParams">
  <ng-template class="slide-registered-user" swiperSlide>
    <div class="illustration">
      <ion-icon
        class="aspect-square"
        src="assets/popups/illustrations/registered_user_with_code.svg"
      ></ion-icon>
    </div>
    <div class="content">
      <div>
        <h2 class="title font-medium" *ngIf="username !== ''">
          @Genial!<br /><span>@{{username}}</span> acaba de registrarse con tu
          invitación
        </h2>
        <h2 class="title font-medium" *ngIf="username === ''">
          @Genial!<br /> Alguien acaba de registrarse con tu
          invitación
        </h2>
        <p>
          Acabas de conseguir <strong>10 estrellas</strong> y
          <strong>2 puntos de experiencia</strong>.
        </p>
        <ng-container *ngIf="!user.isPro">
          <div class="profile-thumb pro-thumb animated-thumb">
            <lazy-img
              class="profile"
              [size]="400"
              [source]="getImgProfile()">
            </lazy-img>
            <app-pro-tag class="pro-tag"></app-pro-tag>
          </div>
          <div class="content-lightgray">
            Obtendrás la insignia PRO cuando consigas 20 puntos de experiencia.
          </div>
        </ng-container>
      </div>
    </div></ng-template
  >
</swiper>
