import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IonRouterOutlet, ModalController } from '@ionic/angular';
import { KComponent } from '../k.component';
import { slideAnimation } from '../../animations/slide-animation';
import { ModalBaseComponent } from '../modal-base/modal-base.component';
import { AuthService } from '../../services/auth.service';
import { Events } from '../../services/events.service';
import { Router } from '@angular/router';
import { Channel } from '../../interfaces/channel';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-in-footer',
  templateUrl: './in-footer.component.html',
  styleUrls: ['./in-footer.component.scss'],
})
export class InFooterComponent extends KComponent {
  @Output() publicationCreated: EventEmitter<boolean> = new EventEmitter();
  slideAnimation = slideAnimation;
  // public static currentIndex = 0;
  fullScreen = false;
  @Input() currentIndex: number;
  loggedIn = false;

  constructor(private modalCtrl: ModalController, private routerOutlet: IonRouterOutlet, private authService: AuthService,
              private events: Events, private router: Router) {
    super();
    this.events.subscribe(environment.EVENTS.TOGGLE_FULLSCREEN, res => {
      this.fullScreen = res;
    });
  }

  isLoggedIn() {
    return this.authService.isLoggedIn();
  }
}
