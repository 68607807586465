import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormatted',
  pure: true
})
export class DateFormattedPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return '';
    }

    const daysOfWeek = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    const montsOfYear = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    const date = new Date(value);

    const stringDate: string = `${daysOfWeek[date.getDay()]} ${date.getDate()} ${montsOfYear[date.getMonth()].toLocaleLowerCase()} ${date.getFullYear()}, ${date.getHours()}:${('0' + date.getMinutes()).slice(-2)}`;

    return stringDate;
  }
}
