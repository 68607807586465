<div class="avatar"
     long-press
     (longPressTrigger)="longPressed.emit(comment)"
     *ngIf="!skeleton">
  <img 
    handleError 
    thumbnail
    [size]="150" 
    [source]="getImageProfile()"
    (click)="emitReducedProfile(comment.authorUser)">
</div>

<div class="data" *ngIf="!skeleton">
  <div class="user"
       long-press
       (click)="emitReducedProfile(comment.authorUser)"
       (longPressTrigger)="longPressed.emit(comment)">
    <img handleError class="badge" *ngIf="comment.authorUser?.isFounder" src="assets/badges/founder-badge.svg" alt="">
    <img handleError class="badge special creator" *ngIf="isCreator" src="assets/badges/special/creator-1x.png" alt="">
    <img handleError class="badge special collaborator" *ngIf="!isCreator && isCollaborator"
         src="assets/badges/special/collaborator-1x.png" alt="">
    <img handleError class="badge special subscriber" *ngIf="!isCreator && !isCollaborator && isSubscribed"
         src="assets/badges/special/subscriber-1x.png" alt="">
    <img handleError class="badge" [src]="getBadgeSrc()" alt="">
    <span>{{comment.authorUser.nameToShow}}</span>
  </div>
  <div class="comment"
       [ngClass]="{'deleted':comment.softDelete}"
       long-press
       (longPressTrigger)="longPressed.emit(comment)">
    {{!comment.softDelete ? comment.commentText : '(Este comentario ha sido eliminado)'}}
  </div>

  <div class="aux"
       long-press
       (longPressTrigger)="longPressed.emit(comment)">
    <span class="date">{{comment.createdAt.toString().replace(' ', 'T') | dateAgo: ''}}</span>

    <ion-button
      class="reply-button"
      *ngIf="!comment.replyTo"
      fill="clear"
      (click)="emitReply($event)">
      Responder
    </ion-button>

    <ion-button
      class="view-button"
      fill="clear"
      *ngIf="replies.length"
      (click)="showReplies = !showReplies"
      [ngClass]="{'open': showReplies}">
      <span *ngIf="!showReplies">Ver {{replies.length}} {{replies.length > 1 ? 'respuestas' : 'respuesta'}}</span>
      <span *ngIf="showReplies">Ocultar respuestas</span>
    </ion-button>
  </div>

  <div class="replies" *ngIf="replies.length">
    <ng-container *ngIf="showReplies">
      <app-comment
        *ngFor="let reply of replies"
        [comment]="reply"
        [user]="reply.authorUser"
        (longPressed)="longPressed.emit($event)"
        (reducedProfile)="emitReducedProfile($event)"
      >
      </app-comment>
    </ng-container>
  </div>
</div>

<div class="avatar" *ngIf="skeleton">
  <ion-thumbnail>
    <ion-skeleton-text [animated]="true"></ion-skeleton-text>
  </ion-thumbnail>
</div>

<div class="data" *ngIf="skeleton">
  <div class="user">
    <ion-skeleton-text [animated]="true" style="width: 60px; --border-radius: 5px;"></ion-skeleton-text>
  </div>
  <div class="comment">
    <ion-skeleton-text [animated]="true" style="width: 100%; --border-radius: 5px;"></ion-skeleton-text>
    <ion-skeleton-text [animated]="true" style="width: 100%; --border-radius: 5px;"></ion-skeleton-text>
    <ion-skeleton-text [animated]="true" style="width: 100px; --border-radius: 5px;"></ion-skeleton-text>
  </div>

  <div class="aux">
    <div class="date">
      <ion-skeleton-text [animated]="true" style="width: 20px; --border-radius: 5px;"></ion-skeleton-text>
    </div>

    <ion-button
      class="reply-button" fill="clear">
      <ion-skeleton-text [animated]="true" style="width: 30px; --border-radius: 5px;"></ion-skeleton-text>
    </ion-button>
  </div>
</div>
