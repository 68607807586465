<ion-header
  id="header-desktop"
  no-border
  class="estrim-header ion-padding-horizontal general-header-app"
>
  <ion-toolbar
    class="ion-justify-content-lg-center d-lg-flex align-items-lg-center"
  >
    <div
      class="k-head-wrapper d-flex justify-content-lg-between align-items-lg-center"
    >
      <ion-title style="cursor: pointer" routerLink="/" routerDirection="root">
        <ion-icon
          handleError
          src="assets/desktop/logo.svg"
          alt="Estrim"
          ></ion-icon>
      </ion-title>
      <div class="search-trigger">
        <input
          type="search"
          class=""
          (focus)="toSearch()"
          placeholder="Buscar"
        />
        <div class="filter-trigger" (click)="goTo('/search')"></div>
      </div>
      <div
        class="k-head-actions d-flex justify-content-lg-between align-items-lg-center"
      >
        <ion-button (click)="openInfoHub()" class="create-button">
          Crear contenido
        </ion-button>
        <ion-buttons slot="end">
          <!-- Buscador -->
          <!--<ion-button
            class="reduced icon-svg-search"
            (click)="goTo('/search')"
            mode="ios"
          >
            <ion-icon src="assets/svg-icon/lupa.svg"></ion-icon>
          </ion-button>-->

          <!-- Notificaciones -->
          <ion-button
            (click)="goTo('/notifications')"
            class="reduced icon-svg-search"
            mode="ios"
          >
            <ion-icon src="assets/svg-icon/campana.svg"></ion-icon>

            <ion-badge
              class="notifications-badge"
              *ngIf="notifications && unread > 0"
              color="danger"
            >
              {{ unread }}
            </ion-badge>
          </ion-button>

          <!-- Chat -->
          <ion-button
            (click)="goTo('/messages/chat-list')"
            class="reduced icon-svg-search chat"
            mode="ios"
          >
            <ion-icon src="assets/svg-icon/bocadillo.svg"></ion-icon>

            <ion-badge
              class="notifications-badge"
              *ngIf="unreadChatNumber && unreadChatNumber > 0"
              color="danger"
            >
              {{ unreadChatNumber }}
            </ion-badge>
          </ion-button>

          <!-- Profile -->
          <div class="user-image" style="cursor: pointer">
            <lazy-img
              *ngIf="user"
              class="activity-img-wrapper"
              routerLink="/profile/me"
              [size]="400"
              [source]="getImgProfile()"
            />
          </div>

          <!-- Menu -->
          <ion-button
            (click)="openLateralMenu()"
            color="dark"
            class="reduced icon-svg-search dots"
            mode="ios"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.12 45.53">
              <g id="Layer_2" data-name="Layer 2">
                <g id="Capa_1" data-name="Capa 1">
                  <g id="More">
                    <circle cx="5.06" cy="40.47" r="5.06" />
                    <circle cx="5.06" cy="22.76" r="5.06" />
                    <circle cx="5.06" cy="5.06" r="5.06" />
                  </g>
                </g>
              </g>
            </svg>
          </ion-button>
        </ion-buttons>
      </div>
    </div>
  </ion-toolbar>
</ion-header>
