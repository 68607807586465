<!-- Icon -->
<div class="title-icon">
  <ion-icon class="icon upload" src="assets/svg-icon/upload2.svg"></ion-icon>
</div>

<!-- Title -->
<div class="title">
  Subir Contenido
</div>

<!-- Subtitle -->
<div class="subtitle">
  Sube tus vídeos, podcasts o fotos desde tu dispositivo y elige dónde guardarlos:
</div>

<!-- Info -->
<div class="info">
  <div class="item">
    <div class="icon users">
      <img handleError class="badge special subscriber" src="assets/users.png" alt="">
    </div>

    <div class="text">
      <div class="intro">
        Público
      </div>

      <div class="description">
        Todos los usuarios tendrán acceso a este contenido.
      </div>
    </div>
  </div>

  <div class="item">
    <div class="icon subscriber">
      <img handleError class="badge special subscriber" src="assets/badges/special/subscriber-1x.png" alt="">
    </div>

    <div class="text">
      <div class="intro">
        Solo suscriptores
      </div>

      <div class="description">
        Solo los usuarios suscritos a tu perfil tendrán acceso a este contenido.
      </div>
    </div>
  </div>

  <div class="item">
    <ion-icon class="icon one" src="assets/svg-icon/one2.svg"></ion-icon>

    <div class="text">
      <div class="intro">
        One
      </div>

      <div class="description">
        Pon un precio a tu contenido más valioso. Un curso o formación, una buena conferencia, un cortometraje, un documental o un podcast. Súbelos a la zona One y obtén ingresos por cada compra.
      </div>
    </div>
  </div>
</div>

<!-- Buttons -->
<div class="buttons">
  <ion-button color="darkteal" (click)="confirm()" *ngIf="confirmButton">
    Subir contenido
  </ion-button>
  <ion-button 
    fill="clear" 
    color="dark"
    class="go-back" 
    (click)="dismiss()">
    Cerrar
  </ion-button>
</div>