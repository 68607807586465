<div class="sidebar-section">
  <h4 class="section-title">Explorar</h4>
  <!-- <div class="button">
    <input
      type="checkbox"
      class="checkbox"
      [checked]="homeLayout === HomeLayout.Audio"
      (click)="
        homeLayout === HomeLayout.Audio
          ? setHomeLayout(HomeLayout.Video)
          : setHomeLayout(HomeLayout.Audio)
      "
    />
    <div class="knobs"></div>
    <div class="layer"></div>
  </div> -->

  <!--
  <ion-list class="dropdown" [ngClass]="{ open: homeModeDropdown }">
    <ion-item
      detail="false"
      lines="none"
      (click)="setHomeLayout(HomeLayout.Video)"
    >
      <ion-icon
        name="checkmark-outline"
        *ngIf="homeLayout === HomeLayout.Video"
      ></ion-icon>
      <ion-icon *ngIf="homeLayout !== HomeLayout.Video"></ion-icon>
      <ion-label class="no-icon-label modific-label">Contenido Vídeo</ion-label>
    </ion-item>
    <ion-item
      detail="false"
      lines="none"
      (click)="setHomeLayout(HomeLayout.Audio)"
    >
      <ion-icon
        name="checkmark-outline"
        *ngIf="homeLayout === HomeLayout.Audio"
      ></ion-icon>
      <ion-icon *ngIf="homeLayout !== HomeLayout.Audio"></ion-icon>
      <ion-label class="no-icon-label modific-label">Contenido Audio</ion-label>
    </ion-item>
  </ion-list>
  <hr class="mt-1" />
  -->
  <ul class="icon-list">
    <li>
      <a routerLink="/publications/view-all/lives" routerLinkActive="is-active">
        <ion-icon
          src="assets/svg-icon/sidebar/video_channels.svg"
          alt="icon"
          height="70"
          width="70"
        ></ion-icon>
        <span>Canales de vídeo</span>
      </a>
    </li>
    <li>
      <a
        routerLink="/publications/view-all/audios"
        routerLinkActive="is-active"
      >
        <ion-icon
          src="assets/svg-icon/sidebar/audio_channels.svg"
          alt="icon"
          height="70"
          width="70"
        ></ion-icon>
        <span>Salas de audio</span>
      </a>
    </li>
    <li>
      <a
        routerLink="/publications/view-all/onevideo"
        routerLinkActive="is-active"
      >
        <ion-icon
          src="assets/svg-icon/new-version/One.svg"
          alt="icon"
          height="70"
          width="70"
        ></ion-icon>
        <span>Estrim One</span>
      </a>
      <ul>
        <li>
          <a
            routerLink="/publications/view-all/onevideo"
            routerLinkActive="is-active"
            >Vídeos</a
          >
        </li>
        <li>
          <a
            routerLink="/publications/view-all/oneaudio"
            routerLinkActive="is-active"
            >Podcasts</a
          >
        </li>
      </ul>
    </li>
    <!-- <li>
      <a
        routerLink="/publications/view-all/oneaudio"
        routerLinkActive="is-active"
      >
        <img
          src="assets/svg-icon/sidebar/estrim_one.svg"
          alt="icon"
          height="70"
          width="70"
        />
        <span>Estrim One</span>
      </a>
    </li> -->
    <li>
      <a
        routerLink="/publications/view-all/recordvideos"
        routerLinkActive="is-active"
      >
        <ion-icon
          src="assets/svg-icon/new-version/Outline_Video.svg"
          alt="icon"
          height="70"
          width="70"
        ></ion-icon>
        <span>Vídeos</span>
      </a>
    </li>
    <li>
      <a
        routerLink="/publications/view-all/recordaudios"
        routerLinkActive="is-active"
      >
        <ion-icon
          src="assets/svg-icon/new-version/Outline_Podcast.svg"
          alt="icon"
          height="70"
          width="70"
        ></ion-icon>
        <span>Podcasts</span>
      </a>
    </li>
    <li>
      <a
        routerLink="/publications/view-all/events"
        routerLinkActive="is-active"
      >
        <ion-icon
          src="assets/svg-icon/new-version/Outline_Eventos.svg"
          alt="icon"
          height="70"
          width="70"
        ></ion-icon>
        <span>Eventos</span>
      </a>
    </li>
    <!-- <li>
      <a
        routerLink="/publications/view-all/premieres"
        routerLinkActive="is-active"
      >
        <img
          src="assets/svg-icon/sidebar/premiere.svg"
          alt="icon"
          height="70"
          width="70"
        />
        <span>Estrenos</span>
      </a>
    </li> -->
  </ul>
</div>
