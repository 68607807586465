<ion-header class="general-header-app">
  <app-header
    [title]="''"
    [menuButton]="false"
    [defaultBackAction]="false"
    (back)="!loading ? back() : null"
  >
  </app-header>
</ion-header>

<ion-content class="ion-padding" [ngClass]="{'hybrid': isHybrid}">
  <div class="flex-content">
    <div class="content has-logo">
      <!-- Title -->
      <div class="title">Categorías</div>

      <!-- Page description -->
      <div class="description">Elige que temas te interesan para empezar.</div>

      <!-- Categories -->
      <div class="categories">
        <app-category-button
          class="category"
          *ngFor="let category of categories"
          [category]="category"
          [active]="category.selected"
          (click)="!loading ? toggleCategorySelected(category) : null"
        >
        </app-category-button>
      </div>

      <!-- Categories error -->
      <div class="field">
        <ion-text class="field-error" *ngIf="error.categories" color="danger">
          <small>
            <ion-icon name="alert-circle-outline"></ion-icon>
            <span>Tienes que seleccionar 4 o más intereses.</span>
          </small>
        </ion-text>
      </div>

      <!-- Terms -->
      <ion-item class="terms" lines="none">
        <ion-label class="termsCheck">
          Acepto los
          <span class="termsLink" routerLink="/legal/useConditions"
            >términos y condiciones de uso</span
          >
          así como las
          <span class="termsLink" routerLink="/legal/community"
            >normas de la comunidad.</span
          >
        </ion-label>
        <ion-checkbox [(ngModel)]="termsAccepted" [disabled]="loading" slot="end"></ion-checkbox>
      </ion-item>

      <!-- Submit button -->
      <div class="submit-button">
        <ion-button
          fill="clear"
          [disabled]="loading || !termsAccepted"
          (click)="submit()"
        >
          Guardar
          <ion-spinner
            color="white"
            name="crescent"
            *ngIf="loading"
          ></ion-spinner>
        </ion-button>
      </div>
    </div>
  </div>
  <app-web-footer></app-web-footer>
</ion-content>
