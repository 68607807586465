import { Component, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Keyboard } from '@capacitor/keyboard';

@Component({
  selector: 'app-create-review',
  templateUrl: './create-review.component.html',
  styleUrls: ['./create-review.component.scss', '../../../theme/field.scss'],
})
export class CreateReviewComponent implements OnDestroy {
  @ViewChild('content', {static: false}) content: ElementRef;

  // currentInputText = '';
  score: number = 5;
  maxLength = 1000;

  review: string = '';

  error: any = {}; 
  listener: any = {};

  // inited: boolean = false;

  keyboardHeight: number = 0;
  keyboardOnScreen: boolean = false;

  constructor( 
    private modalCtrl: ModalController, 
    private platform: Platform,
    private cdRef: ChangeDetectorRef 
  ) {
    if (this.platform.is('hybrid')) {
      this.listener.keyboardWillShow = Keyboard.addListener('keyboardWillShow', info => {
        this.keyboardOnScreen = true;
        // @ts-ignore
        this.keyboardHeight = info.keyboardHeight;

        this.cdRef.detectChanges();
      });

      this.listener.keyboardWillHide = Keyboard.addListener('keyboardWillHide', () => {
        this.keyboardOnScreen = false;
        this.keyboardHeight = 0;

        this.cdRef.detectChanges();
      });
    }
  }

  ngOnDestroy() {
    this.listener?.keyboardWillShow?.remove();
    this.listener?.keyboardWillHide?.remove();
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  setScore(score) {
    if ( score === 1 && this.score === 1 ) 
      this.score = 0;
    else
      this.score = score;
  }

  createReview() {
    this.modalCtrl.dismiss({
      score: this.score,
      comment: this.review
    }, 'confirm');
  }

  dismiss() {
    this.modalCtrl.dismiss(null, 'cancel');
  }

  // isValid() {
  //   return this.score && this.review.length > 0 && this.review.length <= this.maxLength;
  // }

  getMarginBottom() {
    if (this.keyboardOnScreen) {
      // 141 es el alto del div que contiene las estrellas en el coponente send-stars
      return Math.abs(this.keyboardHeight) + 'px';
    } else {
      return '0px';
    }
  }
}
