import { Component, HostListener, OnInit, Input, Output, EventEmitter, SimpleChange, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ActionSheetController, ModalController, Platform } from '@ionic/angular';

import { LiveKitClient as LivekitService } from 'src/app/services/livekit.service';

import { environment } from 'src/environments/environment';

import { Event } from 'src/app/interfaces/event';
import { User } from 'src/app/interfaces/user';
import { Category } from 'src/app/interfaces/category';

import { RecordVideoThumbnailV2Component } from 'src/app/components/publication-thumbnails/record-video-thumbnail-v2/record-video-thumbnail-v2.component';
import { FiltersComponent } from './filters/filters.component';
import { DatesComponent } from './dates/dates.component';
import { FiltersBarComponent } from './filters-bar/filters-bar.component';

export enum ShortcutRange {
  None,
  Today,
  Tomorrow,
  ThisWeek,
  ThisMonth
}

@Component({
  selector: 'app-event-layout',
  templateUrl: './event-layout.component.html',
  styleUrls: ['../../home-v2.page.scss', './event-layout.component.scss'],
})
export class EventLayoutComponent implements OnInit {

  @ViewChildren(RecordVideoThumbnailV2Component) videoThumbnail: QueryList<RecordVideoThumbnailV2Component>;
  @ViewChildren(CdkVirtualScrollViewport) viewport: QueryList<CdkVirtualScrollViewport>;

  @ViewChildren('filtersBar') filtersBar: FiltersBarComponent;

  @Input() user: User | undefined = undefined;
  @Input() eventsList: Event[] = [];
  @Input() userCategories: Category[] = [];
  @Input() categoriesSelected: Category[] = [];
  @Input() recommendedUserList: User[] = [];
  @Input() loading = true;
  @Input() loadingCategories = true;
  @Input() loadingRecommendedUsers = true;
  @Input() total: number = 0;

  @Output() viewAllCategories = new EventEmitter();
  @Output() toggleCategorySelection: EventEmitter<Category> = new EventEmitter();
  @Output() viewAllUsers = new EventEmitter();
  @Output() removeUserSuggestion: EventEmitter<number> = new EventEmitter();
  @Output() viewAllLiveAudioRooms = new EventEmitter();
  @Output() search: EventEmitter<any> = new EventEmitter();

  public displayedVideos: number | undefined = undefined;

  itemSize: number = 190.5;        // Default data
  minBufferPx: number = 190.5 * 3; // Default data
  maxBufferPx: number = 190.5 * 4; // Default data
  
  checkVideoThumbnailOnViewportTimeout: any;

  eventsCdkLists: [][] = [];
  scrollColumns: number = 2;
  eventsCdkArray: any = new Array(this.scrollColumns);

  filters: any = {};
  
  constructor( 
    private cdRef: ChangeDetectorRef,
    private platform: Platform,
    public livekitService: LivekitService,
    private modalCtrl: ModalController,
    private actionSheetCtrl: ActionSheetController
  ) {
    // Initialize podcast cdk lists with empty list
    for( let i = 0; i < this.scrollColumns; i++ ) {
      this.eventsCdkLists.push([]);
    }
    this.calculateDisplayedVideos(this.platform);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.calculateDisplayedVideos(this.platform);
  }
  ngOnInit() {
    this.fillInfiniteScrollLists();
  }

  ngOnChanges(changes: SimpleChange) {
    // @ts-ignore
    if ( changes.eventsList ) {
      this.fillInfiniteScrollLists()
    }
  }
  onItemHeightCalculated($event, minBufferElements: number, maxBufferElements: number, margin: number = 0) {
    setTimeout(() => {
      this.itemSize = $event + margin;  // Include margin
      this.minBufferPx = this.itemSize * minBufferElements;
      this.maxBufferPx = this.itemSize * maxBufferElements;

      this.cdRef.detectChanges();
    })
  }
  public calculateDisplayedVideos(platform: Platform) {
    const sidebarSize = 240;
    const gridPadding = 30;
    const thumbSize = 200;
    if (platform.width() >= 480 && !platform.is('hybrid')) {
      this.displayedVideos = Math.floor((platform.width() - sidebarSize - gridPadding) / thumbSize);
    } else {
      this.displayedVideos = 10;
    }
  }

  fillInfiniteScrollLists() {
    // Clean each list of cdk
    for( let i = 0; i < this.scrollColumns; i++ ) {
      this.eventsCdkLists[i] = [];
    }

    let eventsToFill: Event[] = [];
    
    // if ( !this.filtersBar?.filtersLength ) {
    //   eventsToFill = this.eventsList.slice(10);
    // } else {
      eventsToFill = this.eventsList;
    // }

    // Distribute podcast across all cdk lists
    eventsToFill.forEach((event: Event, index: number) => {
      // @ts-ignore
      this.eventsCdkLists[index % this.scrollColumns].push(event);
    });

    this.cdRef.detectChanges();
    this.checkViewportSize();
  }

  checkViewportSize() {
    this.viewport.forEach((view: CdkVirtualScrollViewport) => {
      view.checkViewportSize();
    });
  }

  async showFilters() {
    const modal = await this.modalCtrl.create({
      cssClass: "",
      component: FiltersComponent,
      componentProps: {
        filters: this.filters,
        categories: this.userCategories
      },
      mode: "ios",
    });
    modal.present();

    await modal.onWillDismiss();
    this.search.emit(this.filters);
    this.cdRef.detectChanges();
  }
  async showDates() {
    const modal = await this.modalCtrl.create({
      cssClass: "",
      component: DatesComponent,
      componentProps: {
        startDate: this.filters.startDate,
        endDate: this.filters.endDate,
      },
      mode: "ios",
    });
    modal.present();

    const result = await modal.onWillDismiss();

    if ( result.role === 'confirm') {
      this.filters.startDate = result.data.startDate;
      this.filters.endDate = result.data.endDate;
      this.filters.shortcutRange = ShortcutRange.None;
      this.search.emit(this.filters);
      this.cdRef.detectChanges();
    }
  }
  setShortcutRange($event: ShortcutRange) {
    this.filters.startDate = undefined;
    this.filters.endDate = undefined;
    this.filters.shortcutRange = $event;
    this.search.emit(this.filters);
  }

  async presentOrderActionSheet() {
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Ordenar por',
      buttons: [
        {
          text: 'Fecha más cercana'
        },
        {
          text: 'Menor precio'
        },
        {
          text: 'Relevancia'
        },
        {
          text: 'Más asistencia'
        },
        {
          text: 'Cancelar',
          role: 'cancel',
          data: {
            action: 'cancel',
          },
        },
      ],
    });

    await actionSheet.present();
  }

  setSearchQuery($event: any) {
    this.filters.query = $event.detail.value;
    this.search.emit(this.filters);
  }
}
