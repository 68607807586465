import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ProfileService } from '../../services/profile.service';
import { PaymentService } from '../../services/payment.service';
import { AuthService } from '../../services/auth.service';
import { AlertController, MenuController, ModalController, Platform } from '@ionic/angular';
import { User } from '../../interfaces/user';
import { Events } from '../../services/events.service';
import { slideAnimation } from 'src/app/animations/slide-animation';
import { MediaService } from '../../services/media.service';
import { App } from '@capacitor/app';
import { Router } from '@angular/router';
import { FeedService } from 'src/app/services/feed.service';
import { UserService } from 'src/app/services/user.service';

import { HomeLayout } from 'src/app/enums/home-layout';
import { filter } from 'rxjs/operators';

import { PollComponent } from 'src/app/pages/extrim/poll/poll.component';
import { Wallet } from 'src/app/interfaces/wallet';
import { EstrimfestPopupComponent } from '../estrimfest-popup/estrimfest-popup.component';

import { ExtrimService } from 'src/app/services/extrim.service';
import { EstrimFestService } from 'src/app/services/estrimfest.service';
@Component({
  selector: 'app-lateral-menu',
  templateUrl: './lateral-menu.component.html',
  styleUrls: ['./lateral-menu.component.scss'],
})
export class LateralMenuComponent implements OnInit {

  HomeLayout = HomeLayout;

  user: User;
  slideAnimation = slideAnimation;
  configOpen = false;
  version = environment.APP_VERSION;

  isIos: boolean = false;
  profleOnScreen: boolean = false;
  isWeb: boolean = environment.isWeb;

  homeLayout: HomeLayout = HomeLayout.Video;
  homeModeDropdown: boolean = false;

  invitationsIndicator: boolean = false;

  walletBallance: number = 0;

  sub: any = {};

  loadingExtrimRacePool: boolean = false;
  loadingEstrimFest: boolean = false;

  constructor(private events: Events, private profileService: ProfileService, private paymentService: PaymentService,
              private auth: AuthService, private alertCtrl: AlertController, private menuCtrl: MenuController,
              private platform: Platform, private mediaService: MediaService, private router: Router,
              private feedService: FeedService, private userService: UserService,
              public modalCtrl: ModalController, private estrimFestService: EstrimFestService,
              private extrimRaceService: ExtrimService,
              private cdRef: ChangeDetectorRef
    ) {

    this.isIos = this.platform.is('ios');
  }

  get profileInfo() {
    return this.auth.user;
  }

  get paymentS() {
    return this.paymentService;
  }

  ngOnInit() {
    this.auth.watchUser()
      .pipe(filter(data => !!data))
      .subscribe((user: User) => {
        this.user = user

        this.sub.wallet?.unsubscribe();
        this.sub.wallet = this.paymentService.getWallet()
          .pipe(filter(data => !!data))
          .subscribe((wallet: Wallet) => {
            this.walletBallance = wallet.balance;
          });
      });


    this.events.subscribe(environment.EVENTS.USER_UPDATED, () => {
      this.user = this.auth.user;
    });

    // Get home layout
    this.feedService.homeLayout.subscribe((layout: HomeLayout) => {
      this.homeLayout = layout;
    })

    this.userService.invitationMenuIndicator
    .subscribe(status => {
      this.invitationsIndicator = status;
    })
  }

  getImgProfile() {
    return this.mediaService.generateImgProfileURL(this.user.id, this.user.imgProfile);
  }

  isMobile() {
    return this.platform.is('hybrid');
  }

  async presentAlertLogout() {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-alert-class',
      message: '¿Quieres cerrar tu sesión?',
      buttons: [
        {
          text: 'Cerrar sesión',
          handler: () => {
            this.logout();
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel'
        }
      ]
    });

    await alert.present();
  }

  public logout() {
    this.events.publish('user:logout');
  }

  legalTexts() {
    this.events.publish(environment.EVENTS.ALERT_TOAST_NOTICE, 'Disponible próximamente.');
  }

  async closeMenu() {
    await this.menuCtrl.enable(true, 'lateral');
    const opened = await this.menuCtrl.isOpen('lateral');

    if (opened) {
      await this.menuCtrl.close('lateral');
    }
  }

  menuWillOpen() {
    this.profleOnScreen = this.router.url.includes('profile');
  }

  setHomeLayout(layout: HomeLayout) {
    this.feedService.setHomeLayout(layout);
    this.homeModeDropdown = false;
    this.closeMenu();
  }

  async launchExtrimRacePoll() {
    this.loadingExtrimRacePool = true;
    this.cdRef.detectChanges();
    
    try {
      await this.extrimRaceService.getRanking().toPromise();
    } catch (e) {}

    this.loadingExtrimRacePool = false;
    this.cdRef.detectChanges();

    const modal = await this.modalCtrl.create({
      cssClass: "",
      component: PollComponent,
      mode: "ios",
    })

    modal.present();
  }

  /**
   * Launch Estrim Fest popup
   */
  async launchEstrimFestPopup() {
    let applicable = false;
    this.loadingEstrimFest = true;
    this.cdRef.detectChanges();

    // Check if user is applicable
    try {
      const data = await this.estrimFestService.getData().toPromise();

      // @ts-ignore
      if ( data && data.done && data.data ) {
        applicable = true;
      }
    } catch (e) {}

    this.loadingEstrimFest = false;
    this.cdRef.detectChanges();

    // Launch modal
    const modal = await this.modalCtrl.create({
      cssClass: "",
      component: EstrimfestPopupComponent,
      componentProps: { applicable },
      mode: "ios",
    })

    // On dismiss
    modal.onDidDismiss().then((data) => {
      // If user confirmed, close menu
      if ( data.role === 'confirm' ) {
        this.closeMenu();
      }
    });

    modal.present();
  }
}
