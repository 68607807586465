import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { ValidatorService } from '../../services/validator.service';

@Component({
  selector: 'app-k-form-error',
  templateUrl: './k-form-error.component.html',
  styleUrls: ['./k-form-error.component.scss'],
})
export class KFormErrorComponent implements OnInit {
  @Input() formName: string;
  @Input() form: UntypedFormGroup;

  constructor(private vs: ValidatorService) {
  }

  ngOnInit() {
  }

  errorMessage() {
    const formControl = this.form.get(this.formName);
    const errors = formControl.errors;
    let message = '';

    if (formControl) {
      if (errors) {
        const customError = this.vs.customErrors[this.formName]?.[Object.keys(errors)[0]];
        if (customError) {
          message = customError;
        } else {
          message = this.vs.defaultErrors[Object.keys(errors)[0]];
        }
      }
    }
    return message;
  }

  public checkFieldValid(formControl: AbstractControl) {
    return formControl.valid;
  }

  public checkFieldInvalid(formControl: AbstractControl) {
    return formControl.invalid && formControl.errors
      && (formControl.dirty || formControl.touched);
  }

}
