<ion-header class="general-header-app">
  <app-header 
    [title]="'Publicación nueva'" 
    [menuButton]="false"
    [defaultBackAction]="false"
    (back)="dismiss()">    
  </app-header>
</ion-header>

<ion-content>

  <!-- <div class="message warning" *ngIf="showldBeVideo">
    <ion-icon name="warning-outline"></ion-icon>
    <span>
      Parece que estás intentando subir un <b>vídeo</b> y no un clip. Los clips deben tener un formato vertical.
    </span>
  </div>
  <div class="message warning" *ngIf="showldBeClip">
    <ion-icon name="warning-outline"></ion-icon>
    <span>
      Parece que estás intentando subir un <b>clip</b> y no un vídeo. Los vídeos deben tener un formato horizontal. 
    </span>
  </div> -->
  <div class="message danger" *ngIf="type === ContentType.Clip && videoTooLong" (click)="setCorrectVideoContentType(ContentType.Video)">
    <ion-icon name="alert-circle-outline"></ion-icon>
    <span>
      Tú vídeo dura demasiado para ser un clip, <u>conviertelo en un video</u>.
    </span>
  </div>

  <!-- 
    -- Content 
    -->

  <!-- Video player -->
  <!-- <div class="field video-player" *ngIf="type === ContentType.Video || type === ContentType.Clip">
    <div class="title" *ngIf="type === ContentType.Video">Vídeo</div>
    <div class="title" *ngIf="type === ContentType.Clip">Clip</div>
  
    <div class="content"
      *ngIf="!(oversized && isHybrid) && !isIos"
      [ngClass]="videoPlayerMode">
      <app-video-player 
        #videoPlayer
        [videoSrc]="fileSrc"
        [canMaximize]="false"
        (medatadaLoaded)="onVideoMetadataLoaded($event)">  
      </app-video-player>
    </div>

    <div class="content not-available" *ngIf="(oversized && isHybrid) || isIos">
      <span>Vista previa no disponible</span>
    </div>
  </div> -->

  <!-- Audio player -->
  <div class="field podcast-player" *ngIf="type === ContentType.Audio">
    <div class="title">Podcast</div>

    <div class="content">
      <app-podcast-player 
        #podcastPlayer 
        [media]="fileSrc">    
      </app-podcast-player>
    </div>
  </div>

  <!-- Poster -->
  <div class="field poster"
    [ngClass]="{
      'video': type === ContentType.Video,
      'clip': type === ContentType.Clip,
      'podcast': type === ContentType.Audio
    }">
    <div class="title">Portada</div>

    <div class="content" (click)="pickPoster()">
      <ion-thumbnail class="thumbnail">
        <img handleError [src]="posterSrc" />
        <ion-skeleton-text *ngIf="posterLoading" @inOutFade [animated]="true"></ion-skeleton-text>
      </ion-thumbnail>

      <ion-button class="choose-file" fill="clear">
        <ion-icon name="repeat-outline"></ion-icon>
      </ion-button>
    </div>
  </div>

  <!-- Frame selector -->
  <div class="range-container"
    *ngIf="posterType === 'frame' && (type === ContentType.Video || type === ContentType.Clip)">
    <div class="range-bar-container">
      <div class="range-star-pin"
        [ngStyle]="{left: pinLeftPosition}">
        {{floor(timeBarValue) | duration}}
      </div>
      <ion-range
        *ngIf="mediaDuration"
        min="0" [max]="mediaDuration"
        step="1"
        dualKnobs="false" 
        pin="false"
        mode="ios"
        snaps="true"
        fill="clear"
        ticks="true" 
        [(ngModel)]="timeBar"
        (ionInput)="changeTimeValue($event)"
        (ionKnobMoveEnd)="changeTimeSetted()">
      </ion-range>
    </div>
  </div>

  <!-- Frame mode change button -->
  <ion-button
    color="light"
    class="get-frame-button"
    *ngIf="posterType === 'image' && (type === ContentType.Video || type === ContentType.Clip)"
    (click)="changeTimeSetted()">
    <ion-icon name="aperture-outline" slot="start"></ion-icon>
    <span>Obtener frame</span>
  </ion-button>

  <div class="next-button submit-button">
    <ion-button
      color="light"
      [disabled]="(type === ContentType.Clip && videoTooLong) || loading"
      (click)="goToForm()">
      <span>Siguiente</span>
      <ion-spinner color="dark" *ngIf="loading" name="crescent"></ion-spinner>
    </ion-button>
  </div>
</ion-content>