import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Platform, ModalController, NavController } from '@ionic/angular';
import { environment } from '../../../../environments/environment';

import { BaseComponent } from '../base.component';

import { SmsPage } from '../sms/sms.page';
import { InvitePage } from '../invite/invite.page';

import { FeedService } from '../../../services/feed.service';
import { AuthService } from '../../../services/auth.service';
import { Events } from '../../../services/events.service';
import { AnalyticsService } from '../../../services/analytics.service';

import { Category } from '../../../interfaces/category';

import { RegisterType } from '../../../enums/register-type';

import * as Sentry from "@sentry/capacitor";

@Component({
  selector: 'app-categories',
  templateUrl: './categories.page.html',
  styleUrls: ['./categories.page.scss', '../base.component.scss', '../../../../theme/field.scss'],
})
export class CategoriesPage extends BaseComponent {

  @Input() registerType: RegisterType = RegisterType.Code;
  @Input() invitation: any | undefined;
  @Input() form: any | undefined;
  @Input() profile: any | undefined;
  @Input() imageUploadPromise: Promise<{avatar: string, banner: string}>;

  categories: Category[] = [];

  isHybrid: boolean = true;

  currentInterestIds: number[] = [];
  termsAccepted: boolean = false;

  constructor(
    public platform: Platform,
    public modalCtrl: ModalController,
    public navCtrl: NavController,
    public cdRef: ChangeDetectorRef,
    public authService: AuthService,
    public events: Events,
    private feedService: FeedService,
    public analyticsService: AnalyticsService
  ) {
    super(platform, modalCtrl, navCtrl, cdRef, authService, events, analyticsService);

    // Get categories from feed service
    this.categories = this.feedService.categories;
    
    // If no categories available, read them
    if ( !this.categories.length ) {
      this.feedService.loadCategories().subscribe(
        res => {
          this.categories = res;
        }
      );
    }

    this.isHybrid = this.platform.is('hybrid');
  }

  ionViewDidEnter() {
    this.analyticsService.setScreenName('register_categories');
  }

  ionViewDidLeave() {
    this.feedService.cleanCategoriesSelection();
  }

  /**
   * Toggle category selection
   * @param {Category} category
   */
  toggleCategorySelected(category: Category) {
    category.selected = !category.selected;

    this.currentInterestIds = this.categories
      .filter(category => category.selected)
      .map(category => category.id);

    this.error.categories = false;
  }

  /**
   * Check all form is valid
   * @returns if is valid
   */
  formValid(): boolean {
    let status = true;

    if (this.currentInterestIds.length < 4 ) {
      this.error.categories = true;
      status = false;
    }

    if ( !this.termsAccepted ) {
      status = false;
    }

    return status;
  }

  /**
   * Submit form, check if is valid and completes register
   */
  async submit() {
    if ( !this.formValid() ) {
      return ; // BREAK EXECUTION
    }

    this.loading = true;
    this.nav.swipeGesture = !this.loading;

    let checkResult;

    /*
     * If user was invited by code, he has inserted his own phone,
     * we have to ensure that the inserted phone is correct so we
     * show up a modal screen so user can verify the inserted phone.
     */

    if ( this.registerType === RegisterType.Code ) {
      // Check phone number again
      checkResult = await this.reCheckPhone(this.form.phone, this.form.country);

      if ( checkResult !== undefined ) {
        // Update form data with verified phone
        this.form.phone = checkResult.phone;
        this.form.country = this.countries.find(c => Number(c.code) === Number(checkResult.code));
      }
    }

    if ( this.registerType === RegisterType.Code && (checkResult === undefined || !checkResult?.verified) ) {
      // Do nothing if inserted phone is not verified
      this.loading = false;
      this.nav.swipeGesture = !this.loading;
    } else {

      // Upload user images (avatar and banner)
      const images = await this.imageUploadPromise;

      if ( images.avatar !== undefined ) {
        // Prepare register data
        const registerData = {
          username: this.form.username,
          birthdate: this.form.birthdate,
          gender: this.form.gender,
          email: this.form.email,
          password: this.form.password,
          repeatPassword: this.form.repeatPassword,
          name: this.profile.name,
          interests: this.currentInterestIds,
          invitationCode: this.registerType === RegisterType.Code ? this.invitation.code : undefined,
          invitedPhone: this.registerType === RegisterType.Phone ? this.invitation.phone : undefined,
          phone: this.registerType === RegisterType.Code ? this.form.phone : this.invitation.phone,
          phoneCountryCode: this.registerType === RegisterType.Code ? this.form.country.code : this.invitation.country.code,
          imageFileProfile: images.avatar,
          imageFileBanner: images.banner
        }

        // Call register API
        this.authService.register(registerData).then((res) => {
          this.loading = false;
          this.nav.swipeGesture = !this.loading;

          if ( this.registerType === RegisterType.Code || (this.registerType === RegisterType.Phone && !this.invitation.phoneValidated) ) {
            
            /*
            * If user inserted his own phone or if user phone is not verified,
            * we have to verify the phone by sending an SMS OTC
            */

            // Send SMS OTC
            this.authService.sendSmsValidation(res.data.phone, res.data.id).subscribe(
              sms => {
                if (!sms.done) {
                  this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR,
                    'Ha ocurrido un error al enviar el sms. Inténtalo más tarde o contacta con soporte');
                  Sentry.captureMessage(`[REGISTER] sending SMS OTC code, response OK but done=false user ${this.form.username} - ${res.data.id}`, "error");
                }
              }, error => {
                Sentry.captureMessage(`[REGISTER] sending SMS OTC code, user ${this.form.username} - ${res.data.id}`, "error");
              }
            );
            // Inform
            this.events.publish('alert:toast:notice', 'Se le ha enviado un sms con un código para acceder.');
            // Go to sms page
            this.goToPage(SmsPage, 
              { 
                username: this.form.username,
                password: this.form.password,
                phone: res.data.phone,
                country: this.registerType === RegisterType.Code ? this.form.country : this.invitation.country.code,
                userId: res.data.id
              },
              true
            );
          } else {

            /*
            * If phone is verified, just login and go to invite screen
            */

            this.login(registerData.username, registerData.password);
            this.goToPage(InvitePage, {username: registerData.username}, true);
          }
        }).catch(err => {
          this.loading = false;
          this.nav.swipeGesture = !this.loading;

          let msg = null;
          if (err && err.error) {
            if (err.error.error && err.error.error === 'invalid_grant') {
              msg = 'Usuario o contraseña incorrectos.';
            } else if (err.error.msg) {
              msg = err.error.msg;
              if (msg === 'El usuario ya existe.') {
                return;
              }
            }
          }
          
          Sentry.captureMessage(`[REGISTER] user register with message (${msg}), user ${this.form.username}`, "error");
          this.events.publish('alert:toast:show', msg);
        });
      }
    }
  }
}
