<ion-header class="general-header-app">
  <app-header [title]="''" [menuButton]="false" [backButton]="false"></app-header>
</ion-header>

<ion-content class="ion-padding">
  <div class="flex-content">
    <div class="content" [ngClass]="{'on-web': isWeb()}">
      <div class="logo">
        <ion-icon class="logo" src="assets/estrim/logo.svg"></ion-icon>
      </div>

      <div class="centered-section" [ngClass]="{'on-web': isWeb()}">
        <h1 class="hi">Hola <ion-icon name="hand-right-outline"></ion-icon></h1>
        <!-- <h1 class="subtext">
          Para registrarte en Estrim necesitas una invitación
        </h1>
        <h5
          class="instructionsLink cursor-desktop-pointer"
          (click)="goToInstructions()"
        >
          Conseguir una invitación
        </h5> -->
      </div>

      <!-- <div class="pre-register-buttons" [ngClass]="{'on-web': isWeb()}">
        <ion-row>
          <div class="title">Registrarme</div>
        </ion-row>

        <ion-row>
          <ion-button
            class="principal"
            fill="clear"
            (click)="goToCodeInvitation()"
          >
            Con un código de invitación
          </ion-button>
        </ion-row>

        <div class="horizontal-line"></div>

        <ion-row>
          <ion-button 
            class="principal" 
            fill="clear" 
            (click)="goToPhoneInvitation()">
            Con mi número de teléfono
          </ion-button>
        </ion-row>
      </div> -->

      <div class="register-button submit-button" [ngClass]="{'on-web': isWeb()}">
        <ion-row>
          <ion-button
            class="principal"
            fill="clear"
            (click)="checkCode()"
          >
            Registrarme
            <ion-spinner
              color="white"
              name="crescent"
              *ngIf="loading"
            ></ion-spinner>
          </ion-button>
        </ion-row>
      </div>

      <div class="invitation" [ngClass]="{'on-web': isWeb()}">
        <div class="app-div">
          <!-- <hr> -->
          <p>¿Ya eres miembro?</p>
        </div>

        <div class="comprobar">
          <ion-button class="ion-margin-top ion-margin-bottom ctuinvit"
              expand="block"
              fill="clear"
              (click)="goToLogin()">
            Iniciar sesión
          </ion-button>
        </div>
      </div>
    </div>

    <app-web-footer></app-web-footer>
  </div>
</ion-content>
