import { Component, OnInit, Input } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SafeUrl } from '@angular/platform-browser';
import { CapacitorHttp, HttpResponse, HttpResponseType } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { environment } from 'src/environments/environment';

import { inOutFade, inOutFromBottom } from '../../animations/animations';

import { MediaService } from '../../services/media.service';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';
import { Events } from '../../services/events.service';

import { User } from '../../interfaces/user';

import * as htmlToImage from 'html-to-image';
import { toJpeg } from 'html-to-image';

@Component({
  selector: 'app-founder',
  templateUrl: './founder.component.html',
  styleUrls: ['./founder.component.scss'],
  animations: [ inOutFade, inOutFromBottom ]
})
export class FounderComponent implements OnInit {

  @Input() user: User = undefined;

  itsMe: boolean = false;

  profileImageUrl: string = '';

  print: string = undefined; 

  generating: boolean = false;
  generatedCardUrl: string | SafeUrl;

  constructor(
    private mediaService: MediaService, 
    private userService: UserService,
    private authService: AuthService,
    private events: Events,
    private platform: Platform
  ) { }

  ngOnInit() {
    this.authService.getUserDetails().then((user: User) => {
      this.itsMe = user.id === this.user.id;

      this.profileImageUrl = this.getImgProfile();
    })
  }

  getImgProfile() {
    return this.mediaService.generateImgProfileURL(this.user?.id, this.user?.imgProfile);
  }

  dismiss() {
    this.userService.closeFounder();
  }

  async generateImage(mode: string) {
    this.generating = true;

    this.generatedCardUrl = `https://generateimg.estrim.com?authorSrc=${this.user.imgProfile}&cardWidth=900&founder=true&username=${this.user.username}&format=png&template=${mode}&pro=${this.user.isPro ? 'true' : 'false'}`;

    if ( !this.platform.is('hybrid') ) {
      this.saveOnWeb();
    } else if ( this.platform.is('ios') ) {
      this.savePicture();
    } else if ( this.platform.is('android') ) {
      this.savePicture();
    }
  }

  saveOnWeb() {
    var link = document.createElement('a');
    link.download = `${this.user?.username}_founder.png`;
    link.href = this.generatedCardUrl as string;
    link.click();
    this.generating = false;
  }

  async savePicture() {
    this.generating = true;

    const options = {
      url: this.generatedCardUrl as string,
      responseType: ('blob' as HttpResponseType)
    };

    try {
      const response: HttpResponse = await CapacitorHttp.get(options);

      if ( response && response.status === 200 ) {
        const blob = await response.data;

        const fileName = Date.now() + '.png';

        const savedFile = await Filesystem.writeFile({
          path: fileName,
          data: blob,
          directory: Directory.Data
        });

        await this.userService.shareFile(savedFile.uri);
        this.generating = false;
      }
    } catch {
      this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, "Ha ocurrido un error, intentalo de nuevo.");
      this.generating = false;
    }
  }  
}
