<div class="subscription-status">
  <div *ngIf="subscriptionStatusLoading" class="ion-text-center">
    <ion-spinner name="crescent" [color]="btColor"></ion-spinner>
  </div>
  <div class="subscription-active" *ngIf="!subscriptionStatusLoading && subscription">
    <h3>¡Ya eres premium!</h3>
    <p>Suscripción activa hasta el <strong>{{subscription.end_period}}</strong></p>
    <p>{{extraTextIfSubscription}}</p>
  </div>
  <div class="no-subscription" *ngIf="!subscriptionStatusLoading && !subscription">
    <h3>Disfruta sin restricciones</h3>
    <p>{{extraTextIfNoSubscription}}</p>
    <div class="mb-40px">
      <ion-button [color]="btColor" expand="block" fill="outline"
                  [routerLink]="'/premium'" size="small">
        ¡Quiero ser premium!
        <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
      </ion-button>
    </div>
  </div>
</div>
