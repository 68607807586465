<ion-header class="general-header-app">
  <app-header
    [title]="''"
    [menuButton]="false"
    [defaultBackAction]="false"
    (back)="back()"
  >
  </app-header>
</ion-header>

<ion-content #content class="ion-padding">
  <div class="flex-content">
    <div class="content has-logo">
      <!-- Title -->
      <div class="title">Registro</div>

  <!-- Username -->
  <div class="field ion-item-input username">
    <div class="content">
      <ion-item>
        <ion-label position="floating">Nombre de usuario</ion-label>

        <ion-input
          required
          maxlength="25"
          autocomplete="false"
          spellcheck="false"
          [disabled]="loading"
          [(ngModel)]="username"
          (ionFocus)="inputFocused($event)"
          (ionChange)="error.username = false; error.usernameExists = false; error.usernameOther = false; checkUsernameExists($event)"
          (keydown)="usernameChangedKeydown($event)">
        </ion-input>
      </ion-item>

      <ion-text class="field-error" *ngIf="error.username" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>El nombre de usuario es obligatorio</span>
        </small>
      </ion-text>

      <ion-text class="field-error" *ngIf="error.usernameExists" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>El nombre de usuario ya existe</span>
        </small>
      </ion-text>

      <ion-text class="field-error" *ngIf="error.usernameOther" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>{{errorMessage.username}}</span>
        </small>
      </ion-text>
    </div>
  </div>

  <!-- Password -->
  <div class="field ion-item-input">
    <div class="content">
      <ion-item>
        <ion-label position="floating">Contraseña</ion-label>

        <ion-input
          required
          type="password"
          autocomplete="new-password"
          [disabled]="loading"
          [(ngModel)]="password"
          (ionFocus)="inputFocused($event)"
          (ionChange)="error.password = false; error.passLength = false">
        </ion-input>
      </ion-item>

      <ion-text class="field-error" *ngIf="error.password" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>La contraseña es obligatoria</span>
        </small>
      </ion-text>

      <ion-text class="field-error" *ngIf="error.passLength" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>La contraseña debe tener al menos 8 caracteres</span>
        </small>
      </ion-text>
    </div>
  </div>

  <!-- Password repeat -->
  <div class="field ion-item-input">
    <div class="content">
      <ion-item>
        <ion-label position="floating">Confirmar contraseña</ion-label>

        <ion-input
          required
          type="password"
          [disabled]="loading"
          [(ngModel)]="repeatPassword"
          (ionFocus)="inputFocused($event)"
          (ionChange)="error.repeatPassword = false">
        </ion-input>
      </ion-item>

      <ion-text class="field-error" *ngIf="error.repeatPassword" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>Las contraseñas no coinciden</span>
        </small>
      </ion-text>
    </div>
  </div>

  <!-- Email -->
  <div class="field ion-item-input">
    <div class="content">
      <ion-item>
        <ion-label position="floating">Email</ion-label>

        <ion-input
          required
          autocomplete="email"
          [disabled]="loading"
          [(ngModel)]="email"
          (ionFocus)="inputFocused($event)"
          (ionChange)="error.email = false; error.emailInvalid = false; error.emailExists = false">
        </ion-input>
      </ion-item>

      <ion-text class="field-error" *ngIf="error.email" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>El email es obligatorio</span>
        </small>
      </ion-text>

      <ion-text class="field-error" *ngIf="error.emailInvalid" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>El email no es correcto</span>
        </small>
      </ion-text>

      <ion-text class="field-error" *ngIf="error.emailExists" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>El email ya ha sido registrado</span>
        </small>
      </ion-text>
    </div>
  </div>

  <!-- Birthdate and Gender -->
  <div class="field-group">
    <!-- Birthday -->
    <div class="field ion-item-input birthdate">
      <div class="content">
        <ion-item (click)="!loading ? triggerBirthdateDatetime() : null">
          <ion-label position="floating">Fecha de nacimiento</ion-label>

          <ion-input
            required
            [disabled]="loading"
            [value]="getParsedBirthdate() | date:'dd/MM/yy'"
            (ionChange)="error.birthdate = false">
          </ion-input>

          <ion-button #birthdateButton id="open-birthdate-dialog" expand="block"></ion-button>

          <ion-modal class="fit-modal floating-modal" trigger="open-birthdate-dialog">
            <ng-template>
              <ion-datetime
                id="birtdate"
                locale="es-ES"
                displayFormat="DD/MMM/YYYY"
                presentation="date"
                [preferWheel]="true"
                monthShortNames="Ene, Feb, Mar, Abr, May, Jun, Jul, Ago, Sep, Oct, Nov, Dec"
                cancel-text="Atrás"
                done-text="Aceptar"
                [showDefaultButtons]="true"
                (ionChange)="error.birthdate = false; onBirthdateSet($event)">
              </ion-datetime>
            </ng-template>
          </ion-modal>
        </ion-item>
      </div>
    </div>

    <!-- Gender -->
    <div class="field ion-item-input gender">
      <div class="content">
        <ion-item>
          <ion-label position="floating">Género</ion-label>

          <ion-select
            class="select"
            cancelText="Cancelar"
            okText="Aceptar"
            [disabled]="loading"
            (ionChange)="error.gender = false; onGenderSet($event)">
            <ion-select-option [value]="gender.code" *ngFor="let gender of genders">
              <span>{{gender.name}}</span>
            </ion-select-option>
          </ion-select>
        </ion-item>
      </div>
    </div>

    <ion-text class="field-error" *ngIf="error.birthdate" color="danger">
      <small>
        <ion-icon name="alert-circle-outline"></ion-icon>
        <span>La fecha de nacimiento es obligatoria</span>
      </small>
    </ion-text>

    <ion-text class="field-error" *ngIf="error.minAge" color="danger">
      <small>
        <ion-icon name="alert-circle-outline"></ion-icon>
        <span>Debes ser mayor de 18 años</span>
      </small>
    </ion-text>

    <ion-text class="field-error" *ngIf="error.gender" color="danger">
      <small>
        <ion-icon name="alert-circle-outline"></ion-icon>
        <span>El género es obligatorio</span>
      </small>
    </ion-text>
  </div>

  <!-- Phone -->
  <div class="field less-margin phone" *ngIf="registerType === RegisterType.Code">
    <div class="content">
      <div class="country">
        <img handleError
          class="flag-image"
          [src]="'assets/country-flags/'+selectedCountry.countryImg+'.svg'" />

        <ion-select
          mode="md"
          class="select"
          value="34"
          cancelText="Cancelar"
          okText="Aceptar"
          [disabled]="loading"
          [selectedText]="getCountryPrefix()"
          (ionChange)="changeFlag($event)">
          <ion-select-option [value]="country" *ngFor="let country of countries">
            <span>{{country.countryName}} +{{country.code}} </span>
          </ion-select-option>
        </ion-select>
      </div>

      <div class="input">
        <ion-input
          required
          type="tel"
          placeholder="Introduce tu número"
          autocomplete="tel-national"
          [disabled]="loading"
          [(ngModel)]="phone"
          (ionFocus)="inputFocused($event)"
          (ionChange)="error.phone = false; error.phoneInvalid = false; error.phoneExists = false">
        </ion-input>
      </div>

      <ion-text class="field-error" *ngIf="error.phone" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>El teléfono es obligatorio</span>
        </small>
      </ion-text>

      <ion-text class="field-error" *ngIf="error.phoneInvalid" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>El número introducido no es correcto</span>
        </small>
      </ion-text>

      <ion-text class="field-error" *ngIf="error.phoneExists" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>{{ errorMessage.phoneExists }}</span>
        </small>
      </ion-text>
    </div>
  </div>

  <!-- Submit button -->
  <div class="submit-button">
    <ion-button
      fill="clear"
      [disabled]="loading || checkingUsername"
      (click)="submit()">
      Siguiente
      <ion-spinner color="white" name="crescent" *ngIf="loading || checkingUsername"></ion-spinner>
    </ion-button>
  </div>

  <div class="keyboard-space" [ngStyle]="{'height': keyboardHeight + 'px'}"></div>
    </div>
  </div>

  <app-web-footer></app-web-footer>

  <div class="ios-bottom-safe-area"></div>

</ion-content>
