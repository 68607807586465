import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgxCookiebotModule } from '@halloverden/ngx-cookiebot';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { PipesModule } from './pipes/pipes.module';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { TimeInterceptor } from './interceptors/time.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { DirectivesModule } from './directives/directives.module';
import { LoginPageModule } from './pages/login/login.module';
import { SuperTabsModule } from '@ionic-super-tabs/angular';
import { AuthService } from './services/auth.service';
import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { ContactListToSendPage } from './pages/contact-list-to-send/contact-list-to-send.page';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { Flashlight } from '@awesome-cordova-plugins/flashlight/ngx';
import { PreviousRouteService } from './services/previous-route.service';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { KCookiebotConfig } from './kcookiebot.config';
import { environment } from '../environments/environment';
import {LateralMenuModule} from './components/lateral-menu/lateral-menu.module';
import {AppLayoutModule} from './layout/app-layout/app-layout.module';
import { RegisterModule } from './pages/register/register.module';
import { MiniProfileModule } from './components/mini-profile/mini-profile.module';
import * as Sentry from "@sentry/capacitor";
import * as SentryAngular from "@sentry/angular";
import { SmsRetriever } from '@awesome-cordova-plugins/sms-retriever/ngx';
// import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { PollModule as ExtrimPollModule } from './pages/extrim/poll/poll.module';
import { ContactListToSendPageModule } from './pages/contact-list-to-send/contact-list-to-send.module';
import { CacheInterceptor } from './interceptors/cache.interceptor';

export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: {direction: Hammer.DIRECTION_ALL},
  } as any;
}

registerLocaleData(es);

Sentry.init(
  {
    dsn: 'https://2dbb39dda0714c63993adc6fb320cb72@o4505092320722944.ingest.sentry.io/4505147966750720',
    beforeSend(event, hint) {

      // Check if is non-error exception
      const isNonErrorException = 
        event?.exception?.values[0]?.value?.startsWith('Non-Error exception captured') ||
        hint?.originalException['message']?.startsWith('Non-Error exception captured') ||
        event?.exception?.values[0]?.value?.startsWith('Handled unknown error') ||
        hint?.originalException['message']?.startsWith('Handled unknown error');

      // Drop events for development environments
      // Drop Non-Error exceptions
      if (
        window.location.href.indexOf("localhost:4200") != -1 || 
        window.location.href.indexOf("localhost:8100") != -1 ||
        isNonErrorException) {
        console.log('Sentry exception dropped', event);
        return null;
      }

      return event;
    },
    // To set your release and dist versions
    release: 'estrim@' + environment.APP_VERSION,
    dist: '1',
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    // We recommend adjusting this value in production.
    tracesSampleRate: 1.0,
    environment: environment.production ? 'production' : 'development',

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // integrations: [new SentryAngular.Replay()],
  },
  // Forward the init method from @sentry/angular
  SentryAngular.init
);

@NgModule({
    declarations: [AppComponent],
    imports: [
        // NgxGoogleAnalyticsModule.forRoot(environment.production ? 'G-MPZJE39G3G' : ''),
        // NgxGoogleAnalyticsRouterModule,
        environment.isWeb ? NgxCookiebotModule.forRoot(KCookiebotConfig) : [],
        IonicModule.forRoot({
          scrollAssist: false,
          animated: true,
          innerHTMLTemplatesEnabled: true
        }),
        SuperTabsModule.forRoot(),
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserTransferStateModule,
        PipesModule,
        DirectivesModule,
        BrowserModule,
        LoginPageModule,
        HammerModule,
        LateralMenuModule,
        AppLayoutModule,
        RegisterModule,
        MiniProfileModule,
        ExtrimPollModule,
        ContactListToSendPageModule
    ],
    providers: [
        // HTTP,
        PreviousRouteService,
        FileTransfer,
        Flashlight,
        Insomnia,
        SmsRetriever,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: CacheInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TimeInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ErrorInterceptor,
          multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        { provide: LOCALE_ID, useValue: 'es' },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig,
        },
        [AuthService],
        {
          provide: ErrorHandler,
          // Attach the Sentry ErrorHandler
          useValue: SentryAngular.createErrorHandler(),
        }
    ],
    exports: [],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
