import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Platform, ModalController, NavController, AlertController } from '@ionic/angular';
import { take } from 'rxjs/operators';

import { Camera, CameraPermissionType, CameraPluginPermissions, CameraResultType, CameraSource } from '@capacitor/camera';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';

import { environment } from '../../../../environments/environment';

import { BaseComponent } from '../base.component';

import { CategoriesPage } from '../categories/categories.page';

import { FeedService } from '../../../services/feed.service';
import { MediaService } from '../../../services/media.service';
import { AuthService } from '../../../services/auth.service';
import { Events } from '../../../services/events.service';
import { FilePickerService } from '../../../services/file-picker.service';
import { AnalyticsService } from '../../../services/analytics.service';

import { ContentType } from '../../../enums/content-type';
import { RegisterType } from '../../../enums/register-type';

import * as Sentry from "@sentry/capacitor";

enum PickTarget {
  Banner,
  Avatar
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss', '../base.component.scss', '../../../../theme/field.scss'],
})
export class ProfilePage extends BaseComponent {

  @Input() registerType: RegisterType = RegisterType.Code;
  @Input() invitation: any | undefined;
  @Input() form: any | undefined;

  // pickTarget: PickTarget;

  name: string = '';
  banner: File | undefined = undefined;
  avatar: File | undefined = undefined;

  bannerSrc: string | SafeUrl;
  avatarSrc: string | SafeUrl;

  constructor(
    public platform: Platform,
    public modalCtrl: ModalController,
    public navCtrl: NavController,
    public cdRef: ChangeDetectorRef,
    public authService: AuthService,
    public events: Events,
    private mediaService: MediaService,
    private domSanitizer: DomSanitizer,
    private feedService: FeedService,
    private alertController: AlertController,
    private filePickerService: FilePickerService,
    public analyticsService: AnalyticsService
  ) {
    super(platform, modalCtrl, navCtrl, cdRef, authService, events, analyticsService);

    // Get categories in this step so they are ready in the next step
    this.feedService.loadCategories().subscribe();

    // Check if register restore point is setted
    this.authService.getRegisterState().then(data => {
      if ( data !== undefined && data.profile ) {
        this.registerType = data.registerType;
        this.invitation = data.invitation;
        this.form = data.form;

        this.name = data.profile.name;
      }

      this.authService.deleteRegisterState();
    })
  }

  ionViewDidEnter() {
    this.analyticsService.setScreenName('register_profile');
  }

  /**
   * Pick banner image
   */
  pickBanner() {
    this.platform.is('hybrid')
      ? this.addImage(CameraSource.Photos, PickTarget.Banner)
      : this.addImageOnDesktop(PickTarget.Banner)
  }

  /**
   * Pick avatar image
   */
  pickAvatar() {
    this.platform.is('hybrid')
      ? this.addImage(CameraSource.Photos, PickTarget.Avatar)
      : this.addImageOnDesktop(PickTarget.Avatar)
  }

  /**
   * Add image from source
   * @param {CameraSource} source
   * @param {PickTarget} target If image is for avatar or for banner
   */
  async addImage(source: CameraSource, pickTarget: PickTarget) {
    // Check permission
    const permissions = await Camera.checkPermissions();

    if ( permissions.photos !== 'granted' ) {
      const permissions: CameraPluginPermissions = { permissions: ['photos'] };
      const requestResult = await Camera.requestPermissions(permissions);

      if ( requestResult.photos !== 'granted' ) {
        /**
         * Si al volver a verificar los permisos, estos siguen sin estar
         * vigentes, sera necesario activar estos directamente desde
         * los ajustes de aplicacion del sistema operativo. Por ello
         * aqui lanza los ajustes del sistema.
         */

        const goToSettings = await this.presentAlertGoToSettings();

        if ( goToSettings === 'confirm' ) {
          this.authService.saveRegisterState({
            registerType: this.registerType,
            invitation: this.invitation,
            form: this.form,
            profile: { name: this.name }
          })
        }

        if ( goToSettings === 'confirm' && this.platform.is("ios") ) {
          NativeSettings.openIOS({
            option: IOSSettings.App,
          });
        }
        if ( goToSettings === 'confirm' && this.platform.is("android") ) {
          NativeSettings.openAndroid({
            option: AndroidSettings.ApplicationDetails,
          });
        }
      }
    }

    const image = await Camera.getPhoto({
      quality: 60,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      source
    });

    if ( image.base64String ) {
      try {
        const blobData = this.mediaService.b64toBlob(image.base64String, `image/${image.format}`);

        const croppedImage = await this.mediaService.generateUserCroppedImage(<File>blobData, pickTarget === PickTarget.Avatar ? 'profile' : 'banner', false);

        if ( croppedImage ) {
          if (pickTarget === PickTarget.Avatar) {
            this.avatar = croppedImage !== undefined ? croppedImage : this.mediaService.blobToFile(blobData, `image.${image.format}`);
            this.avatarSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(this.avatar));
          } else {
            this.banner = croppedImage !== undefined ? croppedImage : this.mediaService.blobToFile(blobData, `image.${image.format}`);
            this.bannerSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(this.banner));
          }
        } else {
          // Sentry log
          Sentry.captureMessage(`[REGISTER] ${pickTarget === PickTarget.Avatar ? 'profile' : 'banner'} image crop cancelled, user ${this.form.username}`, "log");
        }
      } catch (e) {
        // Sentry log
        Sentry.captureMessage(`[REGISTER] while cropping ${pickTarget === PickTarget.Avatar ? 'profile' : 'banner'} image, user ${this.form.username}`, "error");
      }
    } else {
      // Sentry log
      Sentry.captureMessage(`[REGISTER] undefined base64 getting ${pickTarget === PickTarget.Avatar ? 'profile' : 'banner'} image, user ${this.form.username}`, "warning");
    }
  }

  /**
   * Add image on desktop
   * @param {PickTarget} target If image is for avatar or for banner
   */
  async addImageOnDesktop(pickTarget: PickTarget) {
    this.filePickerService.filePicked
    .pipe(take(1))
    .subscribe(async (file: File) => {
      if ( file !== undefined && file.type.includes('image') ) {
        try {
          // Ask user to crop poster
          const croppedImage = await this.mediaService.generateUserCroppedImage(file, pickTarget === PickTarget.Avatar ? 'profile' : 'banner', false);

          if ( croppedImage ) {
            if (pickTarget === PickTarget.Avatar) {
              this.avatar = croppedImage;
              this.avatarSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(this.avatar));
            } else {
              this.banner = croppedImage;
              this.bannerSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(this.banner));
            }
          } else {
            // Sentry log
            // Sentry.captureMessage(`[REGISTER] ${pickTarget === PickTarget.Avatar ? 'profile' : 'banner'} image crop cancelled, user ${this.form.username}`, "log");
          }
        } catch (e) {
          // Sentry log
          Sentry.captureMessage(`[REGISTER] while cropping ${pickTarget === PickTarget.Avatar ? 'profile' : 'banner'} image, user ${this.form.username}`, "error");
        }
      }
    })

    this.filePickerService.pickFile(ContentType.Image);
  }

  /**
    * Alert user that is going to navigate to app settings
    * @param {CameraSource} source Indica si de camara o de galeria
    * @return {Promise<string>} 'cancel' | 'confirm'
    */
  async presentAlertGoToSettings(): Promise<string> {

    const alert = await this.alertController.create({
      message: 'Estrim necesita acceder a tus fotos, activa este permiso desde los ajustes.',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
        },
        {
          text: 'Ir a ajustes',
          role: 'confirm',
        }
      ]
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();

    return role;
 }

  /**
   * Check all form is valid
   * @returns if is valid
   */
  formValid(): boolean {
    let status = true;

    if (this.name === undefined || this.name.trim() === '') {
      this.error.name = true;
      status = false;
    }

    if ( this.avatar === undefined ) {
      this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Debes añadir una foto de perfil');
      status = false;
      this.analyticsService.logEvent('register_skip_image', {
        username: this.form.username
      });
    }

    return status;
  }

  /**
   * Submit form, check if is valid and navigates to categories page
   */
  submit() {
    if ( !this.formValid() ) {
      return ; // BREAK EXECUTION
    }

    // Start image upload
    const imageUploadPromise = this.saveImages({
      name: this.name,
      avatar: this.avatar,
      banner: this.banner,
      avatarSrc: this.avatarSrc,
      bannerSrc: this.bannerSrc
    });

    // Go to categories page
    this.goToPage(CategoriesPage, 
      { 
        registerType: this.registerType,
        invitation: this.invitation,
        form: this.form,
        profile: {
          name: this.name,
          avatar: this.avatar,
          banner: this.banner,
          avatarSrc: this.avatarSrc,
          bannerSrc: this.bannerSrc
        },
        imageUploadPromise: imageUploadPromise
      }
    );
  }
}
