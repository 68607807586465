import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { User } from '../../interfaces/user';
import { MediaService } from '../../services/media.service';
import { Preferences } from '@capacitor/preferences';
import { Category } from '../../interfaces/category';
import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { environment } from '../../../environments/environment';
import { ChatMessage, EventMessagePayload, ReceivedChatMessage, StarsMessagePayload } from 'src/app/interfaces/chat-message';
import { ChatMessageType } from 'src/app/enums/chat-message-type';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
  animations: [
    trigger('inOutLike', [
      transition(':enter', [
        animate('250ms ease', keyframes([
          style({transform: 'scale(0)', offset: 0}),
          style({transform: 'scale(1.3)', offset: 0.8}),
          style({transform: 'scale(1)', offset: 1})
        ]))
      ])
    ])
  ]
})
export class ChatMessageComponent implements OnInit {

  @Input() msg: ChatMessage | ReceivedChatMessage;
  @Input() isCreator: boolean = false;
  @Input() isCollaborator: boolean = false;
  @Input() isSubscribed: boolean = false;
  @Input() user: User;
  @Input() initialMessage: boolean = false;
  @Input() onlyAudio: boolean = false;

  @Output() reducedProfile: EventEmitter<User> = new EventEmitter();

  CACHE_USER_CHAT_DATA = 'cache_user_chat_data';
  mediaS: MediaService;
  userLoaded = false;
  initialMessageText: string = '';

  constructor(private mediaService: MediaService, private authService: AuthService) {
    this.mediaS = this.mediaService;
  }

  get isText(): boolean {
    return this.msg.type === ChatMessageType.Text;
  }
  get isFile(): boolean {
    return this.msg.type === ChatMessageType.File;
  }
  get isImage(): boolean {
    return this.msg.type === ChatMessageType.Image;
  }
  get isLocation(): boolean {
    return this.msg.type === ChatMessageType.Location;
  }
  get isContact(): boolean {
    return this.msg.type === ChatMessageType.Contact;
  }
  get isVoiceRecording(): boolean {
    return this.msg.type === ChatMessageType.Audio;
  }
  get isStar(): boolean {
    return this.msg.type === ChatMessageType.Stars;
  }

  get username(): string {
    return (this.msg as ReceivedChatMessage).username;
  }

  get starQuantity(): number {
    return (this.msg.data as StarsMessagePayload).quantity;
  }

  get filename(): string {
    return this.msg.data as string;
  }

  async ngOnInit() {
    if (!this.onlyAudio) {
      this.initialMessageText =
        '¡Te damos la bienvenida al canal! Interactúa con el resto de usuarios respetando las normas de la comunidad';
    } else {
      this.initialMessageText =
        '¡Te damos la bienvenida a la sala! Interactúa con el resto de usuarios respetando las normas de la comunidad';
    }

    if (!this.initialMessage) {
      const jsonUserData = await Preferences.get({key: this.CACHE_USER_CHAT_DATA + this.msg.userId});
      if (jsonUserData.value) {
        this.user = JSON.parse(jsonUserData.value);

        this.userLoaded = true;
      } else {
        this.authService.getUserDataByUsername((this.msg as ReceivedChatMessage).username).subscribe(res => {
          this.user = res.data;

          if ( res.data ) {
            Preferences.set({key: this.CACHE_USER_CHAT_DATA + this.msg.userId, value: JSON.stringify(res.data)});
          }
          
          this.userLoaded = true;
        });
      }
    }
  }

  getFileName(url: string) {
    return url.split('/').pop();
  }

  getBadgeSrc() {
    return this.mediaService.getBadgeSrc(this.user);
  }

  getImgProfileSrc() {
    if (this.initialMessage) {
      return 'assets/icono-notificacion.png';
    }
    return this.mediaService.generateImgProfileURL(this.user?.id, this.user?.imgProfile);
  }

  emitReducedProfile(user: User) {
    this.reducedProfile.emit(user);
  }

  downloadFile() {
    let file = this.msg.data as string;
    window.open(file, '_blank');
  }
}
