import { Component, OnInit, Input, Output, ViewChild, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Platform, IonTextarea, IonInfiniteScroll, ActionSheetController, AlertController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { inOutFade, inOutFromBottomFade } from 'src/app/animations/animations';
import { skipWhile, take } from 'rxjs/operators';
import { trigger, transition, animate, style } from '@angular/animations';

import { BasePage } from 'src/app/pages/base.page';

import { PublicationService } from 'src/app/services/publication.service';
import { ProfileService } from 'src/app/services/profile.service';
import { PaymentService } from 'src/app/services/payment.service';
import { Events } from 'src/app/services/events.service';
import { AuthService } from 'src/app/services/auth.service';
import { AnalyticsService } from 'src/app/services/analytics.service';

import { Publication } from 'src/app/interfaces/publication';
import { Comment } from 'src/app/interfaces/comment';
import { Collaborator } from 'src/app/interfaces/collaborator';
import { Subscription } from 'src/app/interfaces/subscription';
import { User } from 'src/app/interfaces/user';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.page.html',
  styleUrls: ['./comments.page.scss'],
  animations: [
    inOutFade,
    inOutFromBottomFade,
    trigger('inOutSendButton', [
      transition(':enter', [
        style({transform: 'translateX(100%)', opacity: '0'}),
        animate('400ms ease', style({transform: 'translateX(0%)', opacity: '1'}))
      ]),
      transition(':leave', [
        animate('400ms ease', style({transform: 'translateX(100%)', opacity: '0'}))
      ])
    ]),
    trigger('inOutStarBottom', [
      transition(':enter', [
        style({transform: 'translateY(100%)', opacity: '0'}),
        animate('400ms ease', style({transform: 'translateY(0%)', opacity: '1'}))
      ]),
      transition(':leave', [
        animate('400ms ease', style({transform: 'translateY(100%)', opacity: '0'}))
      ])
    ]),
  ]
})
export class CommentsPage extends BasePage implements OnInit {

  @ViewChild('infiniteScroll', {static: false}) infiniteScroll: IonInfiniteScroll;
  @ViewChild('textarea', {static: false}) textarea: IonTextarea;

  @Input() publication: Publication = undefined;
  @Input() headerEnabled: boolean = true;
  @Input() isEvent: boolean = false;
  @Input() canSendStars: boolean = false;

  @Output() showProfile: 
    EventEmitter<{user: User, isCreator: boolean, isCollaborator: boolean, isSubscriber: boolean}> 
    = new EventEmitter();
  @Output() dismiss: EventEmitter<any> = new EventEmitter();
  @Output() onCommentSent: EventEmitter<Comment> = new EventEmitter();

  collaborators: Collaborator[];
  subscribers: Subscription[];

  loading: boolean = true;
  sending: boolean = false;
  inputFocusStatus: boolean = false;

  commentsOffset: number = 0;
  commentsLimit: number = 20;

  comments: Comment[] = [];
  replies: Comment[][] = [];

  currentInputText: string = "";

  replyingComment: Comment = undefined;

  sendingTimeout: any = undefined;

  user: User = undefined;

  showStars: boolean = false;

  constructor( 
    private publicationService: PublicationService,
    private profileService: ProfileService,
    private paymentService: PaymentService,
    private events: Events,
    private authService: AuthService,
    private actionSheetCtrl: ActionSheetController,
    private alertController: AlertController,
    private analyticsService: AnalyticsService,
    protected platform: Platform,
    protected cdRef: ChangeDetectorRef
  ) {
    super(platform, cdRef);
  }

  ngOnInit() {
    if ( this.publication ) {
      this.authService.watchUser()
      .pipe(
        skipWhile(data => !data),
        take(1))
      .subscribe((user: User) => {
        this.user = user
      })

      if ( this.publication.comments?.length ) {
        this.comments = this.publication.comments;
        this.loading = false;
      }

      this.replies = this.publication.replies;

      this.loadComments();
      this.loadCollaborators();
      this.loadSubscribers();
    }
  }

  back() {
    this.publicationService.closeModal();
  }

  loadComments() {
    this.publicationService.loadComments(this.publication.id, this.commentsOffset, this.commentsLimit, this.isEvent).subscribe(res => {
      this.infiniteScroll?.complete();

      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let index = 0; index < res.data.comments.list.length; index++) {
        const messageFromServer = res.data.comments.list[index];
        const search = this.comments.find(x => x.id === messageFromServer.id);
        if( !search ){
          this.comments.push(messageFromServer);
        }
      }

      this.sortCommentsBy('updatedAt');
      this.commentsOffset += this.commentsLimit;

      if (res.data.comments.meta.remaining <= 0 && this.infiniteScroll !== undefined) {
        this.infiniteScroll.disabled = true;
      }

      this.replies = {...this.replies, ...res.data.replies};

      this.publication.comments = this.comments;
      this.publication.replies = this.replies;

      this.loading = false;
    });
  }
  loadCollaborators() {
    this.profileService.getCollaborators(this.publication.author.id).subscribe(res => {
      this.collaborators = res.data.accepted;
    });
  }
  loadSubscribers() {
    this.paymentService.loadSubscriptions(this.publication.author.id).subscribe((res) => {
      this.subscribers = res.data.toMe;
    });
  }

  sortCommentsBy(prop: string) {
    return this.comments.sort((b, a) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  }

  userIsCreator(userId: number) {
    if (this.publication)
      return Number(this.publication.author.id) === Number(userId);
    else
      return false;
  }
  userIsCollaborator(userId: number) {
    if (this.collaborators && this.collaborators.length)
      return this.collaborators.some(c => c.user.id === Number(userId));
    else
      return false;
  }
  userIsSubscribed(userId: number) {
    if (this.subscribers && this.subscribers.length)
      return this.subscribers.some(s => s.subscriberUser.id === Number(userId));
    else
      return false;
  }

  inputFocusChange(focus: boolean) {
    this.inputFocusStatus = focus;
  }

  getInputContainerPaddingBottom() {
    if (this.platform.is('ios') && !this.inputFocusStatus) {
      return 'calc(env(safe-area-inset-bottom) + 5px)';
    } else {
      return '5px';
    }
  }

  inputTextDirty() {
    return this.currentInputText !== null && this.currentInputText !== undefined && this.currentInputText.trim() !== '';
  }

  cancelReplyComment() {
    this.replyingComment = undefined;
  }

  send(event = null) {
    if (event && event.key !== 'Enter') {
      return;
    }

    if (this.currentInputText.trim() === '') {
      return;
    }
    
    if ( !this.profileService.checkUserValidated() ) {
      return ;  // BREAK EXECUTION
    }

    // Indico que se esta enviado un comentario para evitar enviar mensaje
    // Antes de tiempo
    this.sending = true;

    if (this.replyingComment === undefined) {
      this.publicationService.sendComment(this.currentInputText.trim(), this.publication.id, this.isEvent).subscribe(
        res => {
          if (res.done) {
            this.comments.unshift(res.data);
            this.publication.comments = this.comments;
            this.publication.commentsNumber++;
          } else {
            this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error al enviar tu comentario.');
          }
          this.currentInputText = '';

          // Acabo accion de envio de mensaje y feedback del usuario
          this.sending = false;
          this.onCommentSent.emit(res.data);
          //this.changeDetector.detectChanges();
        }, err => {
          // Acabo accion de envio de mensaje y feedback del usuario
          this.sending = false;
          //this.changeDetector.detectChanges();
        }
      );
    } else {
      this.publicationService.sendComment(this.currentInputText.trim(), this.publication.id, this.isEvent, this.replyingComment.id).subscribe(
        res => {
          if (res.done) {
            if (this.replies[this.replyingComment.id] === undefined) {
              this.replies[this.replyingComment.id] = [];
            }

            this.publication.commentsNumber++;

            this.replies[this.replyingComment.id].push(res.data);
            this.publication.replies = this.replies;

            this.cancelReplyComment();
          } else {
            this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error al enviar tu comentario.');
          }
          this.currentInputText = '';

          // Acabo accion de envio de mensaje y feedback del usuario
          this.sending = false;
          //this.changeDetector.detectChanges();
        }, err => {
          // Acabo accion de envio de mensaje y feedback del usuario
          this.sending = false;
          //this.changeDetector.detectChanges();
        }
      );
    }
  }

  triggerReplyComment($event, comment: Comment) {
    if ( this.textarea ) {
      this.cancelReplyComment();

      this.textarea.setFocus();

      this.replyingComment = comment;
    }
  }

  triggerShowProfile(user) {
    this.showProfile.emit({
      user, 
      isCreator: this.userIsCreator(user.id),
      isCollaborator: this.userIsCollaborator(user.id),
      isSubscriber: this.userIsSubscribed(user.id)
    })
  }

  canDeleteComment(comment: Comment): boolean {
    return comment.authorUser.id === this.user.id || 
      this.userIsCreator(this.user.id) ||
      this.userIsCollaborator(this.user.id);
  }

  async commentLongPress(comment: Comment) {
    if (this.canDeleteComment(comment)) {
      if (!comment.softDelete) {
        const buttons = [
          {
            text: 'Eliminar',
            role: 'destructive',
            icon: 'trash-outline',
            handler: () => {
              this.presentAlertCommentDelete(comment);
            }
          }
        ];

        const actionSheet = await this.actionSheetCtrl.create({
          buttons,
          mode: 'ios'
        });
        await actionSheet.present();

        const {data} = await actionSheet.onDidDismiss();
      }
    }
  }

  async presentAlertCommentDelete(comment: Comment) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-alert-class',
      message: 'Eliminar comentario',
      buttons: [
        {
          text: 'Eliminar',
          handler: () => {
            this.publicationService.removeComment(comment.id).subscribe(res => {
              if (res.done) {
                this.comments = this.comments.filter(c => c.id !== comment.id);
                this.publication.comments = this.publication.comments.filter(c => c.id !== comment.id);

                if (comment.replyTo) {
                  this.replies[comment.replyTo.id] = this.replies[comment.replyTo.id].filter(reply => reply.id !== comment.id);
                  this.publication.replies[comment.replyTo.id] = this.publication.replies[comment.replyTo.id].filter(reply => reply.id !== comment.id);
                }

                this.publication.commentsNumber--;
              }
            });
            this.alertController.dismiss();
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel'
        }
      ]
    });

    await alert.present();
  }

  showStarsModal() {
    this.showStars = true;
  }
  starsModalDismiss() {
    this.showStars = false;
  }

  onStarsSent($event: number) {
    this.publication.stars = Number(this.publication.stars) + Number($event);
    this.publication.sendStars = true;
    this.starsModalDismiss();
    this.dismiss.emit();

    this.analyticsService.logEvent('publication_star', {
      publication_type: 'clip',
      pulication_id: this.publication.id,
      ammount: Number($event)
    });
  }
}
