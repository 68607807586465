import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, BehaviorSubject, throwError, of } from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import { Events } from './events.service';
import { Preferences } from '@capacitor/preferences';
import { Device } from '@capacitor/device';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { JsonApiResponse, JsonApiResponseIngress } from '../interfaces/JsonApiResponse';
import { Subscription } from '../interfaces/subscription';
import { User } from '../interfaces/user';

import { CacheService } from './cache.service';

import { CacheData } from '../enums/cache-data';

import * as Sentry from "@sentry/capacitor";

@Injectable({
  providedIn: 'root'
})
export class AuthService extends CacheService {
  /* eslint-disable @typescript-eslint/naming-convention */
  public URL_NEWSLETTER = 'api/auth/user/newsletter';
  public URL_RECOVER = 'api/user/recover-pass';
  public URL_CHECK_LOGIN = 'api/user/check-login';
  public URL_LOGIN = 'api/user/login';
  public URL_LOGIN_SOCIAL = 'api/user/login/social';
  public URL_REFRESH_TOKEN = 'api/user/refresh-token';
  public URL_CHECK_INVITATION = 'api/user/check-invitation';
  public URL_CREATE_INVITATION = 'api/auth/user/create_direct_invitation';
  public URL_CANCEL_INVITATION = 'api/auth/user/cancel_direct_invitation';
  public URL_VERIFY_EMAIL = 'api/user/verify-email';
  public URL_REGISTER = 'api/user/register';
  public URL_REGISTER_SLIM = 'api/user/register/slim';
  public URL_REGISTER_EMPTY_FIELDS = 'api/user/registerEmptyFields';
  public URL_ADD_DEVICE = 'api/user/add-user-device';
  public URL_SAVE_USER_DETAILS = 'api/auth/user/profile-edit';
  public URL_GOOGLE_SIGNIN = 'api/user/google-signin';
  public URL_FACEBOOK_SIGNIN = 'api/user/facebook-signin';
  public BASE_URL_DETAIL = 'api/auth/user/detail';
  public TAIL_CHANGE_PASS = 'api/auth/user/password-change';
  public getDirectInvitationsUrl = 'api/auth/user/get_direct_invitations';

  public getSubscribersUrl = 'api/auth/aforum/get-subscribers';
  public sendSmsValidationUrl = 'api/user/send_sms_validation';
  public checkSmsCodeUrl = 'api/user/check/sms-code';
  public updateUserInterestsUrl = 'api/auth/user/update_user_interests';
  public getUserDataByUsernameUrl = 'api/auth/user/username/{username}';
  public getExtendedUserDataByUsernameUrl = 'api/auth/user/extended/{username}';

  public getUserDataByIdUrl = 'api/auth/user/get_user_data_by_id';
  public removeDeviceForNotificationsUrl = 'api/auth/user/remove_device_for_notifications';

  public checkRecoverTokenUrl = 'api/user/check_recover_password_token';
  public changePasswordRecoverUrl = 'api/user/change_password_recover';
  public uploadProfileTempImageUrl = 'api/user/upload_profile_temp_image';

  public getUserContactsLastUpdateUrl = 'api/auth/user/get_contacts_last_update';

  public updateConnectionCountryUrl = 'api/auth/user/update_connection_country';

  public checkPhoneBeforeRegisterUrl = 'api/user/check/phone/register';

  public getProInvitationCodeUrl = 'api/auth/user/get_pro_invitation_code';

  public saveProInvitationCodeUrl = 'api/auth/user/save_pro_invitation_code';

  public toggleProInvitationCodeUrl = 'api/auth/user/toggle_pro_invitation_code';

  public getSensibleDataUrl = 'api/auth/user/get_sensible_data';

  public getReputationUrl = 'api/auth/user/get_reputation';

  public getInterestsUrl = 'api/auth/user/get_interests';

  public deleteAccountUrl = 'api/auth/user/delete_account';

  public getMetaDataUrl = 'api/auth/user/info/livekit';

  public getKeyStreamUrl = 'api/livekit/ingress';


  public countryCode: string;
  public phone: string;
  public userId: string;
  public username: string;
  public password: string;
  public returnUrl: string;
  public invitationError = false;
  private isCheckingInvitation = false;
  private currentInvitationCode = '';

  private readonly KEY_USER_DETAILS = 'USER_DETAILS';
  private readonly KEY_REGISTER_STATE_RESTORE = 'REGISTER_STATE_RESTORE';
  private device;

  private fetchingUser: boolean = false;

  private _user: BehaviorSubject<User> = new BehaviorSubject<User>(undefined);

  constructor(protected http: HttpClient, private events: Events, private router: Router, private navController: NavController) {
    super(http);

    this.events.subscribe(environment.EVENTS.USER_LOGOUT, () => {
      this._user.next(undefined);
    });

    this.init();
  }

  protected fetchCacheData(type: CacheData) {
    switch ( type ) {
      case CacheData.MyUser:
        !this.fetchingUser ? this.syncUserDetails() : null;
      break;
    }
  }
  protected getCacheDataObservable(type: CacheData): BehaviorSubject<any> {
    switch ( type ) {
      case CacheData.MyUser: return this._user; break;
    }
  }
  protected getCacheDataObservableKey(type: CacheData): string {
    switch ( type ) {
      case CacheData.MyUser: return this.KEY_USER_DETAILS; break;
    }
  }

  async init() {
    this._user.next(await this.getUserDetails());
  }

  get user(): User | undefined {
    return this._user.value;
  }

  get validated(): boolean {
    return this.user?.validated || false;
    // return false;
  }

  set validated(status: boolean) {
    this.user.validated = status;
    this.updateUser(this.user);
  }

  watchUser(): Observable<User> {
    return this.watchCacheData(CacheData.MyUser);
  }

  updateUser(user: User) {
    this.setCacheData(CacheData.MyUser, user);
    this.events.publish(environment.EVENTS.USER_UPDATED, user);
  }

  public async getDevice() {
    if (!this.device) {
      this.device = await Device.getInfo();
    }

    return this.device;
  }

  public register(data: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(this.generateAbsoluteAPIURL(this.URL_REGISTER), data).subscribe(res => {
        if (res.done) {
          if (res.data.user) {
            this.updateUser(res.data.user);
          }

          this.currentInvitationCode = '';
          resolve(res);
        } else {
          this.events.publish('alert:toast:show', res.msg);
        }

      }, err => {
        reject(err);
      });
    });
  }

  public registerSlim(data: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(this.generateAbsoluteAPIURL(this.URL_REGISTER_SLIM), data).subscribe(res => {
        if (res.done) {
          if (res.data.user) {
            this.updateUser(res.data.user);
          }

          this.currentInvitationCode = '';
          resolve(res);
        } else {
          this.events.publish('alert:toast:show', res.msg);
        }

      }, err => {
        reject(err);
      });
    });
  }

  public loginSocial(data: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(this.generateAbsoluteAPIURL(this.URL_LOGIN_SOCIAL), data).subscribe(res => {
        if (res.done) {
          
        } else {

        }
      }, err => {
        reject(err);
      });
    });
  }

  public login(mEmail: string, pass: string, ignoreWelcome: boolean = false): Promise<any> {
    const event = this.events;
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(this.generateAbsoluteAPIURL(this.URL_LOGIN), {
        email: mEmail,
        password: pass,
      }).subscribe(
        async (res) => {
          if (res.done) {
            this.setUserEmail(mEmail);
            this.setAccessToken(res.data.access_token);
            this.setRefreshToken(res.data.refresh_token);
            if (this.getPushToken()) {
              this.setDevice(this.getPushToken());
            }
            this.updateConnectionCountry().subscribe();

            delete res.data.access_token;
            delete res.data.refresh_token;
            await this.setCacheData(CacheData.MyUser, res.data);

            if ( !res.data.welcome ) {
              if ( !ignoreWelcome )
                this.events.publish(environment.EVENTS.WELCOME);
              else
                this.events.publish(environment.EVENTS.WELCOME_IGNORED);
            }

            resolve(true);
          } else if (res.data) {
            reject(res.data);
          } else {
            reject(res.msg);
          }
        }, err => {
          reject(err);
        }
      );
    });
  }

  registerEmptyFields(data: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(this.generateAbsoluteAPIURL(this.URL_REGISTER_EMPTY_FIELDS), data)
        .subscribe(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }


  checkInvitationCode(code: string, countryCode = null): Observable<any> {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.URL_CHECK_INVITATION), {
      invitationCode: code,
      countryCode
    });
  }

  getIsCheckingInvitation() {
    return this.isCheckingInvitation;
  }

  setIsCheckingInvitation(value: boolean) {
    this.isCheckingInvitation = value;
  }

  getCurrentInvitationCode() {
    return this.currentInvitationCode;
  }

  setCurrentInvitationCode(code: string) {
    this.currentInvitationCode = code;
  }

  verifyEmail(email: string, hash: string) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.URL_VERIFY_EMAIL), {
      email,
      hash
    });
  }

  private syncUserDetails(callback?: string): void {
    const username = this.user?.username || this.getUserEmail();
    this.fetchingUser = true;
    this._user.next(undefined);


    this.getExtendedUserDataByUsername(username).subscribe(
      data => {
        if ( data && (data as String) !== 'notExists' ) {
          this.updateUser((data as User));

          this.fetchingUser = false;

          if (callback === 'back') {
            this.navController.back();
          }
        }
      }, error => {
        this.fetchingUser = false;
      }
    )
  }

  public async getUserDetails(): Promise<any> {
    const json = await Preferences.get({key: this.KEY_USER_DETAILS});
    if (json && json.value !== null && json.value !== 'undefined') {
      return JSON.parse(json.value);
    }

    return null;
  }

  // public saveUserDetails(user: User): void {
  //   Preferences.set({key: this.KEY_USER_DETAILS, value: JSON.stringify(user)}).then(() => {
  //     this.events.publish(environment.EVENTS.USER_UPDATED, user);
  //   });
  // }

  public saveUserDetailsToAPI(details: any) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.URL_SAVE_USER_DETAILS), details);
  }

  public changePassword(currentPassword: string, password: string): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.TAIL_CHANGE_PASS), {
      currentPassword,
      password
    });
  }

  public changeNewsLetterOption(): Observable<any> {
    return this.http.get<any>(environment.API_BASE_URL + this.URL_NEWSLETTER);
  }

  public async setDevice($token): Promise<any> {
    let uEmail = 'anonymous';
    const userEmail = this.getUserEmail();
    if (userEmail) {
      uEmail = userEmail;
    }

    if (!this.device) {
      this.device = await Device.getInfo();
    }

    const devId = await Device.getId();
    if (!devId || !this.device) {
      return null;
    }

    const data: any = {
      token: $token,
      platform: this.device.platform,
      model: this.device.model,
      uuid: devId.identifier,
      osSystem: this.device.operatingSystem,
      osVersion: this.device.osVersion,
      manufacturer: this.device.manufacturer,
      email: uEmail
    };

    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(this.generateAbsoluteAPIURL(this.URL_ADD_DEVICE), data).subscribe(
        res => {
          if (res.done) {
            this.setPushToken(data.token);
            resolve(true);
          } else {
            reject(res.msg);
          }
        },
        err => {
          reject(err);
        });
    });
  }

  public refreshToken() {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.URL_REFRESH_TOKEN), {
      refreshToken: this.getRefreshToken()
    }).pipe(
      tap((response: any) => {
        if (response.done) {
          this.setAccessToken(response.data.access_token);
          this.setRefreshToken(response.data.refresh_token);
        }
      })
    );
  }

  public recoverPass(username: string): Observable<any> {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.URL_RECOVER), {email: username});
  }

  public checkLogin(): Observable<any> {
    return this.http.get<any>(this.generateAbsoluteAPIURL(this.URL_CHECK_LOGIN));
  }

  public logout(): void {
    if (this.getPushToken()) {
      this.removeDeviceForNotifications(
        this.getPushToken())
        .subscribe(res => {
        });
    }


    this.removeAccessToken();
    this.removePushToken();
    this.removeUserEmail();
    this.removeUserLocation();
    localStorage.removeItem('refresh_token');
    Preferences.remove({key: this.KEY_USER_DETAILS}).catch();
  }

  public setCookiesAccepted(accepted: boolean): void {
    let $accepted = '0';
    if (accepted) {
      $accepted = '1';
    }
    localStorage.setItem('cookies_accepted', $accepted);
  }

  public areCookiesAccepted(): boolean {
    const $accepted = localStorage.getItem('cookies_accepted');
    return !(!$accepted || $accepted === '0');
  }

  public setPushToken(token: string): void {
    localStorage.setItem('push_token', token);
  }

  public getPushToken(): string {
    return localStorage.getItem('push_token');
  }

  public removePushToken(): void {
    localStorage.removeItem('push_token');
  }

  public setAccessToken(token: string): void {
    localStorage.setItem('access_token', token);
  }

  public getToken(): string {
    return localStorage.getItem('access_token');
  }

  public removeAccessToken(): void {
    localStorage.removeItem('access_token');
  }

  public setRefreshToken(token: string): void {
    localStorage.setItem('refresh_token', token);
  }

  public getRefreshToken(): string {
    return localStorage.getItem('refresh_token');
  }

  public isLoggedIn(): boolean {
    return !!this.getToken();
  }

  public setUserEmail(email: string): void {
    localStorage.setItem('user_email', email);
  }

  public getUserEmail(): string {
    return localStorage.getItem('user_email');
  }

  public removeUserEmail(): void {
    localStorage.removeItem('user_email');
  }

  public setUserLocation(mLat: number, mLng: number, mAddress: any = null): void {
    localStorage.setItem('user_location', JSON.stringify({lat: mLat, lng: mLng, address: mAddress}));
  }

  public getUserLocation(): any {
    const loc = localStorage.getItem('user_location');
    if (loc) {
      return JSON.parse(loc);
    }

    return null;
  }

  public removeUserLocation(): void {
    localStorage.removeItem('user_location');
  }

  public isAnonymous() {
    const email = this.getUserEmail();
    if (!email) {
      return true;
    }

    return email.indexOf('@anonymous.es') > -1;
  }

  createInvitation(sendInvitationInput: string, username: string) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.URL_CREATE_INVITATION),
      {
        fromUserUsername: username,
        invitedPhone: sendInvitationInput
      });
  }

  cancelInvitation(id) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.URL_CANCEL_INVITATION),
      {
        invitationId: id,
      });
  }

  getDirectInvitations(userID) {
    return this.http.post<any[]>(this.generateAbsoluteAPIURL(this.getDirectInvitationsUrl),
      {
        userID
      });
  }

  sendSmsValidation(invitedPhone: string, userId?: string) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.sendSmsValidationUrl),
      {
        invitedPhone,
        userId
      });
  }

  checkSmsCode(smsCode: string, introducedPhone: string) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.checkSmsCodeUrl),
      {
        smsCode: smsCode.toUpperCase(),
        introducedPhone
      });
  }

  updateUserInterests(interests) {
    return this.http.post(this.generateAbsoluteAPIURL(this.updateUserInterestsUrl), {interests})
      .pipe(map((res) => {
      if (res) {
        this.events.publish(environment.EVENTS.USER_INTEREST_UPDATED, interests);
      }
      return res;
    }));
  }

  getSubscribers(userID) {
    return this.http.post<JsonApiResponse<Subscription[]>>(this.generateAbsoluteAPIURL(this.getSubscribersUrl),
      {
        userID
      });
  }

  getUserDataByUsername(username) {
    if ( typeof username === 'string' ) {
      return this.http.get(
        this.generateAbsoluteAPIURL(this.getExtendedUserDataByUsernameUrl.replace('{username}', username)))
        .pipe(map(data => {
          return { data: data as User }
        }));
    } else {
      return throwError(`ERROR: getUserDataByUsername username is not string ${username}`);
    }
  }

  getUserDataById(userId) {
    return this.http.post<JsonApiResponse<User>>(this.generateAbsoluteAPIURL(this.getUserDataByIdUrl
      ),
      {
        userId
      });
  }

  getExtendedUserDataByUsername(username) {
    if ( typeof username === 'string' ) {
      return this.http.get(this.generateAbsoluteAPIURL(this.getExtendedUserDataByUsernameUrl.replace('{username}', username)))
        .pipe(map(data => {
          if ( (data as String) === 'notExists' ) {
            Sentry.captureMessage(`[PROFILE] trying to get profile of not existing user ${username}`, "log");
          } else if ( data ) {
            // Cache user data
            Preferences.set({key: `${username}_PROF_CACHED_PROFUSER`, value: JSON.stringify(data)});

            const localUsername = this.user?.username || this.getUserEmail();
            if ( username === localUsername ) {
              this.updateUser((data as User));
            }
          }

          return data;
        }));
    } else {
      return throwError(`ERROR: getExtendedUserDataByUsername username is not string ${username}`);
    }
  }

  uploadProfileTempImage(file, imgTarget = 'profile') {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('imgTarget', imgTarget);
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.uploadProfileTempImageUrl), formData);
  }

  private removeDeviceForNotifications(pushToken: string) {
    return this.http.post<any>(this.generateAbsoluteAPIURL(this.removeDeviceForNotificationsUrl),
      {
        pushToken
      });
  }

  checkRecoverToken(email, token) {
    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.checkRecoverTokenUrl),
      {
        email,
        token
      });
  }

  changePasswordRecover(password: string, repeatPassword: string, email, token) {
    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.changePasswordRecoverUrl),
      {
        password,
        repeatPassword,
        email,
        token
      });
  }

  getUserContactsLastUpdate() {
    return this.http.get<JsonApiResponse<any>>(
      this.generateAbsoluteAPIURL(this.getUserContactsLastUpdateUrl));
  }

  updateConnectionCountry(ip = null) {
    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.updateConnectionCountryUrl),
      {
        ip
      });
  }

  checkPhoneBeforeRegister(countryCode:
                             any, phone:
                             any
  ) {
    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.checkPhoneBeforeRegisterUrl),
      {
        countryCode,
        phone
      });
  }

  toggleProInvitationCode(checked:
                            any
  ) {
    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.toggleProInvitationCodeUrl), {
      checked
    });
  }

  getProInvitationCode() {
    return this.http.get<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.getProInvitationCodeUrl));
  }

  saveProInvitationCode(invitationCode) {
    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.saveProInvitationCodeUrl),
      {
        invitationCode
      });
  }

  getSensibleData() {
    return this.http.get<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.getSensibleDataUrl));
  }

  getReputation(userId:
                  number
  ) {
    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.getReputationUrl),
      {
        userId
      });
  }

  getInterests() {
    return this.http.get<JsonApiResponse<any[]>>(this.generateAbsoluteAPIURL(this.getInterestsUrl),);
  }

  deleteAccount() {
    return this.http.get<JsonApiResponse<any[]>>(this.generateAbsoluteAPIURL(this.deleteAccountUrl),);
  }

  /**
   * Get register state, if this exists, it means that a register is not completed
   * @returns Register state date
   */
  async getRegisterState(): Promise<any | undefined> {
    const json = await Preferences.get({key: this.KEY_REGISTER_STATE_RESTORE}).catch();

    if (json && json.value !== null && json.value !== 'undefined') {
      return JSON.parse(json.value);
    }

    return undefined;
  }

  /**
   * Save register state
   * @param {any} registerData Json containing register data
   */
  async saveRegisterState(registerData: any) {
    await Preferences.set({key: this.KEY_REGISTER_STATE_RESTORE, value: JSON.stringify(registerData)}).catch();
  }

  /**
   * Deletes register state
   */
  async deleteRegisterState() {
    await Preferences.remove({key: this.KEY_REGISTER_STATE_RESTORE}).catch();
  }

  getStreamKey(roomName: number, username: string) {
    const token = this.getToken();

    return this.http.post<JsonApiResponseIngress>(this.generateAbsoluteAPIURL(this.getKeyStreamUrl),
      {
        username,
        roomName,
        token
      });
  }

  getMetaData(username: string, roomId: string) {
    const token = this.getToken();

    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.getMetaDataUrl),
      {
        username,
        roomId,
        token
      });
  }
}
