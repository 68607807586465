import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

import { FeedService } from '../../../../services/feed.service';

import { Publication } from '../../../../interfaces/publication';

import { ContentType } from '../../../../enums/content-type';
import { FormMode } from '../../../../enums/form-mode';

export enum PageMode {
  Root,
  Child
}

@Component({
  selector: 'app-static-content',
  templateUrl: './static-content.component.html',
})
export class StaticContentComponent implements OnInit, OnDestroy {

  @Input() pageMode: PageMode = PageMode.Root;
  @Input() rootPage: any;
  @Input() type: ContentType;
  @Input() file: File;
  @Input() poster: File | string;
  @Input() posterSrc: SafeUrl | string;
  @Input() callback: any;
  @Input() mode: FormMode = FormMode.Create;
  @Input() publication: Publication;
  @Input() url: string;
  @Input() size: number;
  @Input() duration: number;
  @Input() isRecord: boolean = false;

  constructor(private feedService: FeedService) { }

  ngOnInit() {
    this.feedService.loadCategories().subscribe();
  }

  ngOnDestroy() {
    this.feedService.cleanCategoriesSelection();
  }
}
