import { Directive, ElementRef, HostListener } from "@angular/core";

import { ModalController } from '@ionic/angular';
import { ImageZoomComponent } from '../components/image-zoom/image-zoom.component';

@Directive({
  selector: "img[zoomable]",
})
export class ImgZoomableDirective {

  constructor(
    private element: ElementRef,
    private modalCtrl: ModalController
  ) {}

  @HostListener("click")
  private async zoom() {
    if ( !this.element.nativeElement.src.includes('assets/no-image') ) {
      const modal = await this.modalCtrl.create({
        component: ImageZoomComponent,
        mode: 'ios',
        componentProps: {
          imageSrc: this.element.nativeElement.src
        }
      });
      modal.present();

      const {} = await modal.onWillDismiss();
    }
  }
}