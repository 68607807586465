<div #content class="content">

  <div class="section">
    <div class="total">
      <app-like-counter 
        *ngIf="!loading"
        [count]="count" 
        [filled]="true">
      </app-like-counter>
      <ion-skeleton-text 
        *ngIf="loading"
        [animated]="true" 
        style="width: 40px; --border-radius: 5px;">
      </ion-skeleton-text>
    </div>

    <div class="list">
      <ng-container *ngIf="!loading">
        <!-- User -->
        <div class="element" *ngFor="let sender of likeSenders; let i = index">
          <div class="data" (click)="triggerShowProfile(sender)">
            <lazy-img class="avatar" [size]="150" [source]="getImgProfile(sender)"></lazy-img>

            <span class="user">
              <img handleError class="badge" *ngIf="sender.isFounder" src="assets/badges/founder-badge.svg" alt="">
              <img handleError class="badge special collaborator" *ngIf="userIsCollaborator(sender.id) && !userIsSubscribed(sender.id)"
                src="assets/badges/special/collaborator-1x.png" alt="">
            <img handleError class="badge special subscriber" *ngIf="!userIsCollaborator(sender.id) && userIsSubscribed(sender.id)"
                src="assets/badges/special/subscriber-1x.png" alt="">
              <img handleError class="badge" [src]="getBadgeSrc(sender)" alt="">{{sender.username}}
            </span>
          </div>

          <div class="buttons" *ngIf="!itsMe(sender)">
            <ion-button
              (click)="sender.following ? followUser(sender, false) : followUser(sender, true)"
              [color]="sender.following ? 'light' : 'darkteal'">
              {{sender.following ? 'Siguiendo' : 'Seguir'}}
            </ion-button>
          </div>
        </div>
      </ng-container>
      <!-- Skeleton -->
      <ng-container *ngIf="loading">
        <div class="element">
          <div class="data">
            <ion-thumbnail class="avatar">
              <ion-skeleton-text [animated]="true"></ion-skeleton-text>
            </ion-thumbnail>

            <span class="user">
              <ion-skeleton-text [animated]="true" style="width: 80px; --border-radius: 5px;"></ion-skeleton-text>
            </span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
