import { Component, OnInit } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { environment } from '../../../../../environments/environment';
import { filter, take } from 'rxjs';

import { BaseComponent as ExtrimPollBaseComponent } from '../base.component';

import { MediaService } from 'src/app/services/media.service';
import { ExtrimService } from 'src/app/services/extrim.service';

@Component({
  selector: 'app-final',
  templateUrl: './final.page.html',
  styleUrls: environment.isWeb ? ['./final.page.scss', '../../../../../assets/extrim/css/main.css', '../../../../../assets/extrim/css/main-desktop.css'] : ['./final.page.scss', '../../../../../assets/extrim/css/main.css'],
})
export class FinalPage extends ExtrimPollBaseComponent {

  winners: any[] = [];
  losers: any[] = [];

  constructor(
    protected nav: IonNav,
    protected modalCtrl: ModalController,
    public mediaService: MediaService,
    private extrimRace: ExtrimService,
  ) {
    super(nav, modalCtrl);
  }

  ngOnInit(): void {
    this.sub.ranking = this.extrimRace.ranking
    .pipe(
      filter(data => !!data),
    ).subscribe(data => {
      this.pilots = data;

      this.winners = this.pilots.filter(pilot => pilot.winner);
      this.losers = this.pilots.filter(pilot => pilot.loser);
    })
  }
}
