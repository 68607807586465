<ng-template #starTemplate>
  <svg *ngIf="fill===2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.19 27.76">
    <defs>
      <style>.cls-1 {
        /*fill: #d6913b;*/
        fill: #fff;
      }</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Capa_1" data-name="Capa 1">
        <polygon
          points="14.6 0 19.11 9.14 29.19 10.6 21.89 17.72 23.61 27.76 14.6 23.02 5.58 27.76 7.3 17.72 0 10.6 10.09 9.14 14.6 0"/>
      </g>
    </g>
  </svg>


  <svg *ngIf="fill===1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.19 27.76">
    <defs>
      <style>.cls-1 {
        /*fill: #d6913b;*/
        fill: #fff;
      }</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Capa_1" data-name="Capa 1">
        <polygon
          points="14.6 0  14.6 23.02 5.58 27.76 7.3 17.72 0 10.6 10.09 9.14 14.6 0"/>
      </g>
      <g id="Capa_1" data-name="Capa 1">
        <path
          d="M14.6,4.52,17.31,10l.47,1,1,.15,6.07.88L20.5,16.29l-.76.73.18,1,1,6.05-5.43-2.86-.93-.49-.94.49L8.23,24.11l1-6.05.18-1-.76-.73L4.3,12l6.07-.88,1-.15.47-1,2.72-5.5M14.6,0,10.08,9.14,0,10.6l7.3,7.12-1.72,10,9-4.74,9,4.74-1.73-10,7.3-7.12L19.11,9.14,14.6,0Z"/>
      </g>
    </g>
  </svg>


  <svg *ngIf="fill===0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.19 27.76">
    <defs>
      <style>.cls-1 {
        /*fill: #d6913b;*/
        fill: #fff;
      }</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Capa_1" data-name="Capa 1">
        <path
          d="M14.6,4.52,17.31,10l.47,1,1,.15,6.07.88L20.5,16.29l-.76.73.18,1,1,6.05-5.43-2.86-.93-.49-.94.49L8.23,24.11l1-6.05.18-1-.76-.73L4.3,12l6.07-.88,1-.15.47-1,2.72-5.5M14.6,0,10.08,9.14,0,10.6l7.3,7.12-1.72,10,9-4.74,9,4.74-1.73-10,7.3-7.12L19.11,9.14,14.6,0Z"/>
      </g>
    </g>
  </svg>
</ng-template>
