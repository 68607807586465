import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { TusUploadService } from './tus-upload.service';
import { Events } from './events.service';

@Injectable({
  providedIn: 'root'
})
export class CloudflareService extends TusUploadService {

  private _onReadyToStream: Subject<boolean> = new Subject<boolean>();
  onReadyToStream: Observable<boolean> = this._onReadyToStream.asObservable();

  private _onPollError: Subject<boolean> = new Subject<boolean>();
  onPollError: Observable<boolean> = this._onPollError.asObservable();

  private readonly _authorization: string = 'Bearer G-kBUt-JMcFknQsfeeP4uP7wTd8_f7UQcnYBkfYM';
  private _httpHeader: any;

  private readonly _pollIntervalTime = 3 * 1000;  // 3 seconds
  private readonly _maxPollRetries = 10;           // 5 retries max
  private _pollInterval: any = undefined;

  constructor( 
    protected platform: Platform,
    protected events: Events,
    private http: HttpClient
  ) {
    super(platform, events);

    this._httpHeader = {
      headers: new HttpHeaders()
        .set('Authorization',  `${this._authorization}`)
    }
  }

  onError(error){
    this._fileUploadError.next(null);
    this._onUploading.next(false);
    this._onProgress.next(0);
    this._uploading = false;
  }
  
  onSuccess(){
    this._fileUploaded.next(null);
    this.startVideoPoll();
  }
  
  onUploadProgress(bytesUploaded, bytesTotal){
    var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);

    if ( Number(percentage) < 99 ) {
      this._onProgress.next(Number(percentage));
    } else {
      this._onProgress.next(99);
    }
  }

  onAfterResponse(req, res) {
    return new Promise(resolve => {
      var mediaIdHeader = res.getHeader('stream-media-id');

      if (mediaIdHeader) {
        this._response = mediaIdHeader
      }

      resolve(true);
    });
  }

  uploadFile(file: File, title: string) {
    const headers: any = {
      Authorization: this._authorization,
    };

    const metadata: any = {
      name: title,
      filename: file.name,
      filetype: file.type
    };

    super.uploadFile(file, environment.CLOUDFLARE_UPLOAD_BASE_URL, headers, metadata);
  }

  startVideoPoll() {
    let retries = 0;

    this._pollInterval = setInterval(async () => {
      let response;

      try {
        response = await this.getStreamDetails(this.response);
      } catch(e) {
        this._onUploading.next(false);
        this._onProgress.next(0);
        this._uploading = false;
        this._onPollError.next(null);
        clearInterval(this._pollInterval);
      }

      // @ts-ignore
      if ( response.result.readyToStream ) {
        this._onUploading.next(false);
        this._onProgress.next(0);
        this._uploading = false;
        this._onReadyToStream.next(null);
        clearInterval(this._pollInterval);
      }
      
      if ( retries >= this._maxPollRetries ) {
        this._onUploading.next(false);
        this._onProgress.next(0);
        this._uploading = false;
        this._onPollError.next(null);
        clearInterval(this._pollInterval);
      }

      retries++;
    }, this._pollIntervalTime);
  }

  getStreamDetails(uid: string) {
    return this.http.get<any>(`${environment.CLOUDFLARE_UPLOAD_BASE_URL}/${uid}`, this._httpHeader).toPromise();
  }
}
