import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { IonSearchbar, Platform } from '@ionic/angular';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss'],
})
export class SearchbarComponent implements OnInit, AfterViewInit {
  @ViewChild('searchBar') searchBar: IonSearchbar;


  @Input() placeholder = 'Buscar';
  @Input() clearButton = 'always';
  @Input() autofocus = true;

  @Output() inputChange = new EventEmitter();
  @Output() ionInput = new EventEmitter();
  @Output() clear = new EventEmitter();

  constructor(public platform: Platform,) {
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.autofocus && this.setFocusSearchBar();
  }

  setFocusSearchBar(){
    setTimeout(() => {
      if (!this.platform.is('hybrid')) {
        this.searchBar.setFocus();
      }
    }, 1000);
  }
}
