<ion-nav 
  [root]="rootPage" 
  [rootParams]="{
    pageMode: pageMode,
    type: type,
    file: file,
    poster: poster,
    posterSrc: posterSrc,
    callback: callback,
    mode: mode,
    publication: publication,
    url: url,
    size: size,
    duration: duration,
    isRecord: isRecord
  }">      
</ion-nav>