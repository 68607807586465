import { Component, Input, Output, OnInit, OnDestroy, ViewChild, SimpleChanges, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { IonRange } from '@ionic/angular';

@Component({
  selector: 'app-podcast-player',
  templateUrl: './podcast-player.component.html',
  styleUrls: ['./podcast-player.component.scss'],
})
export class PodcastPlayerComponent implements OnInit, OnDestroy {

  @Input() media: any = undefined;
  @Input() loop: boolean = false;
  @Input() autoplay: boolean = false;

  @Output() audioLoaded = new EventEmitter();
  @Output() countViewer = new EventEmitter();

  /**
   * Control del rango para avanazr en el fichero de audio
   */
  @ViewChild('range', {static: false}) range: IonRange;

  /**
   * Variables para el control del reproductor de audio
   */
  audioPlayer = null;
  audioDuration = undefined;
  audioProgress = null;
  audioTimer = null;
  audioIsPaused = true;

  playbackRate = 1;
  secondsViewed = 0;
  countSecondsInterval;
  viewerCounted = false;

  currentTimeString: number = 0;
  timeRemainingString: number = 0;

  seeking: boolean = false;

  constructor(private cdRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    const audioPlayer = new Audio(this.media);
    audioPlayer.onloadedmetadata = (data) => {
      this.audioLoaded.emit(audioPlayer.duration);
      this.timeRemainingString = audioPlayer.duration;
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.media.firstChange) {
      this.media = this.media.changingThisBreaksApplicationSecurity ? this.media.changingThisBreaksApplicationSecurity : this.media;
      this.audioPlayer = null;
      this.audioDuration = undefined;
      this.audioProgress = null;
      this.audioTimer = null;
      this.audioIsPaused = true;
      this.playbackRate = 1;

      if ( this.autoplay ) {
        this.play();
      }
    }
  }

  ngOnDestroy() {
    this.destroyAudio();
  }

  /**
   * Play o Pausa del audio
   */
  playPlauseAudio() {
    if (this.media !== undefined) {
      this.media = this.media.changingThisBreaksApplicationSecurity ? this.media.changingThisBreaksApplicationSecurity : this.media;
      
      if (this.audioIsPaused) {
        this.play();
      } else if (!this.audioIsPaused) {
        this.pause();
      }
    }
  }

  /**
   * Reproduce el audio
   */
  public play() {
    if (!this.audioPlayer) {
      // create and play new track
      this.audioPlayer = new Audio(this.media);
      // this.audioPlayer = new Audio('https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3');
      this.audioPlayer.playbackRate = this.playbackRate;
      this.audioPlayer.loop = this.loop;
    }

    this.audioPlayer.play().then(() => {
      this.audioIsPaused = false;
      this.audioDuration = this.audioPlayer.duration;
      this.onPlaying();

      clearInterval(this.audioTimer);
      this.audioTimer = setInterval(() => {
        if (!this.seeking && !this.audioIsPaused){
          this.audioProgress = this.audioPlayer.currentTime;

          this.currentTimeString = this.audioProgress;
          this.timeRemainingString = this.audioDuration - this.audioProgress;

          this.audioPlayer.onended = () => {
            this.audioIsPaused = true;
          };
        }
      }, 1000);
    }).catch((e) => console.log(e));
  }

  /**
   * Pausa el audio
   */
  public pause() {
    if (!this.audioIsPaused) {
      this.audioPlayer.pause();
      this.onPause();
      this.audioIsPaused = true;
    }
  }



  /**
   * Avanza en el fichero
   * @param {any} event
   */
  seek(event: any) {
    if (this.audioPlayer) {
      this.seeking = false;

      try {
        let seekTo = event.target.value;

        this.audioPlayer.currentTime = seekTo;

        if (this.audioIsPaused == true) {
          this.audioPlayer.play().then().catch((e) => console.log(e));
          this.onPlaying();
          this.audioIsPaused = false;
        }
      } catch (e) {
      }
    }
  }

  /**
   * Avanza o retrocede x segundos
   * @param {number} seconds
   */
  seekBy(seconds: number) {
    if ( seconds && (this.audioPlayer.currentTime + seconds) >= this.audioDuration ) {
      this.playPlauseAudio();
      this.audioPlayer.currentTime = this.audioDuration;
    } else if ( !seconds && (this.audioPlayer.currentTime + seconds) < 0 ) {
      this.playPlauseAudio();
      this.audioPlayer.currentTime = 0;
    } else {
      this.audioPlayer.currentTime += seconds;
    }

    this.audioProgress = this.audioPlayer.currentTime;
  }

  /**
   * Actualiza datos en pantalla de tiempo actual y tiempo restante
   * @param {any} event
   */
  seekChange(event) {
    this.currentTimeString = event.target.value;
    this.timeRemainingString = this.audioDuration - event.target.value;
  }

  /**
   * Mientra se esta avanzando en el fichero de audio, se pausa
   * la reproduccion
   */
  pauseWhileSeeking() {
    if (this.audioPlayer) {
      this.seeking = true;
      this.pause();
    }
  }

  /**
   * Transforma segundos a formato string hh?:mm:ss
   * @param {number} seconds
   * @return {string}
   */
  transformSecondsToString(seconds: number) {
    if (seconds >= 0) {
      return new Date(Math.round(seconds) * 1000).toISOString().substr(14, 5);
    }
  }

  /**
   * Alterna entre las diferentes velocidades de reproduccion
   */
  changePlaybackRate() {
    let rate;

    switch (this.playbackRate) {
      case 0.5:
        rate = 1;
        break;
      case 1:
        rate = 1.5;
        break;
      case 1.5:
        rate = 2;
        break;
      case 2:
        rate = 0.5;
        break;
    }

    this.audioPlayer.playbackRate = rate;
    this.playbackRate = rate;
  }

  /**
   * Libera el reproductor
   */
  destroyAudio() {
    if (this.audioPlayer && !this.audioIsPaused) this.audioPlayer.pause();
    if (this.audioTimer) clearInterval(this.audioTimer);
    if (this.range) this.range.value = 0;
    this.audioPlayer = null;
    this.audioDuration = undefined;
    this.audioProgress = null;
    this.audioTimer = null;
    this.audioIsPaused = true;
    this.onPause();
  }

  onPlaying() {
    if (this.countSecondsInterval || this.viewerCounted) {
      return;
    }
    this.countSecondsInterval = setInterval(() => {
      this.secondsViewed += 1;
      if (this.secondsViewed > 4) {
        this.countViewer.emit();
        this.viewerCounted = true;
        this.onPause();
      }
    }, 1000);
  }

  private onPause() {
    clearInterval(this.countSecondsInterval);
    this.countSecondsInterval = null;
  }
}
