import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousandsK'
})
export class ThousandsKPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    const toFixed = (n, fixed) => Math.floor(Math.pow(10, fixed) * n) / Math.pow(10, fixed);

    if (value < 10000) {
      return value;
    } else if (value < 1000000) {
      const truncated = toFixed(value / 1000, 1);
      return truncated + 'K';

    } else {
      const truncated = toFixed(value / 1000000, 1);
      return truncated + 'm';

    }
  }

}
