import { Component } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { environment } from '../../../../../environments/environment';

import { BaseComponent as ExtrimPollBaseComponent } from '../base.component';

@Component({
  selector: 'app-info',
  templateUrl: './info.page.html',
  styleUrls: environment.isWeb ? ['./info.page.scss', '../../../../../assets/extrim/css/main.css', '../../../../../assets/extrim/css/main-desktop.css'] : ['./info.page.scss', '../../../../../assets/extrim/css/main.css'],
})
export class InfoPage extends ExtrimPollBaseComponent {

  constructor(
    protected nav: IonNav,
    protected modalCtrl: ModalController 
  ) {
    super(nav, modalCtrl)
  }
}
