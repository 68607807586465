import { Component, ContentChild, ChangeDetectorRef } from '@angular/core';
import { IonInput } from '@ionic/angular';

@Component({
    selector: 'app-show-hide-password',
    templateUrl: './show-hide-password.component.html',
    styleUrls: ['./show-hide-password.component.scss'],
})
export class ShowHidePasswordComponent {
    @ContentChild(IonInput) input: IonInput;
    public showPassword;

    constructor( private cdRef: ChangeDetectorRef ) {
      this.showPassword = false;
    }

    public toggleShow() {
      this.showPassword = !this.showPassword;
      this.input.type = this.showPassword ? 'text' : 'password';
      this.cdRef.detectChanges();
    }
}
