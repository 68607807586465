<h1>Conversaciones</h1>

<div *ngIf="loading" class="loading-container ion-margin-vertical">
  <ion-spinner color="primary" name="crescent"></ion-spinner>
</div>

<div *ngIf="!loading">
  <div class=" tabs display-flex ion-justify-content-evenly">
    <span [ngClass]="{'selected': tabNumber===1}" (click)="tabNumber = 1">Mensajes</span>
    <span [ngClass]="{'selected': tabNumber===2}" (click)="tabNumber = 2">Solicitudes <span class="alert-noti">6</span></span>
  </div>
  <div *ngIf="tabNumber === 1">

    <ul class="contact-list">
      <li *ngFor="let conversation of cachedRooms"
          routerLink="/messages/chat-with/{{conversation.otherUser.username}}">
        <div class="back-contact">
          <ion-avatar slot="start">
            <img handleError class="profile-pic"
                 routerLink="/home/user/{{conversation.otherUser.username}}"
                 [src]="getProfileImage(conversation.otherUser)"/>
          </ion-avatar>
        </div>
        <div>
          <label class="name-contact">{{conversation.otherUser.username}}</label>
          <span class="sub">
            <span class="info-acc">
              <span *ngIf="conversation.lastMessage?.userId === user.id.toString()">Tu: </span>
              <span>{{conversation.lastMessage?.text}}</span>
            </span>
            <span class="time">{{conversation.lastMessage.date | dateAgo}}</span>
          </span>
        </div>
        <ion-button >11</ion-button>
      </li>
    </ul>
  </div>
  <div *ngIf="tabNumber === 2"></div>
</div>

