import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {SidebarComponent} from './sidebar.component';
import {AudioRoomsComponent} from './components/audio-rooms/audio-rooms.component';
import {ItemsMenuComponent} from './components/items-menu/items-menu.component';
import {VideoChannelsComponent} from './components/video-channels/video-channels.component';
import {RecommendedComponent} from './components/recommended/recommended.component';
import {DirectivesModule} from '../../directives/directives.module';
import {LazyImgModule} from '../../components/lazy-img/lazy-img.module';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
    SidebarComponent,
    AudioRoomsComponent,
    ItemsMenuComponent,
    VideoChannelsComponent,
    RecommendedComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    DirectivesModule,
    LazyImgModule,
    RouterModule
  ],
  exports: [
    SidebarComponent,
  ]
})
export class SidebarModule { }
