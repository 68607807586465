export enum SearchSection {
  Global = 'global',
  Users = 'users',
  Videos = 'videos',
  Audios = 'audios',
  Premieres = 'premieres',
  PremieresVideos = 'premieres-videos',
  PremieresAudios = 'premieres-audios',
  Events = 'events',
  EventsVideos = 'events-videos',
  EventsAudios = 'events-audios',
  One = 'one',
  OneVideos = 'one-videos',
  OneAudios = 'one-audios',
  OneGeneral = 'one-general',
  Live = 'live',
  LiveVideos = 'liveVideos',
  LiveAudios = 'liveAudios',
  Clips = 'clips'
}
