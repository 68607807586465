import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ValorationStarComponent } from './valoration-star.component';

@NgModule({
  declarations: [ ValorationStarComponent ],
  imports: [ CommonModule ],
  exports: [ ValorationStarComponent ]
})
export class ValorationStarModule { }
