import {IonRefresher, ModalController, Platform} from '@ionic/angular';
import {environment} from '../../environments/environment';
import {AbstractControl, UntypedFormGroup} from '@angular/forms';

export abstract class KPage {
  public pageSlug: string;
  public pageTitle: string;
  public readonly IMG_BASE_URL: string;
  public pageLoading: boolean;
  public appRoutes: any;

  /**
   * Variables utilizadas para calcular dinamicament el ancho de cada
   * elemento en listas horizontales.
   * Estos valores iniciales se corresponden con el estilo en .scss (mixin)
   */
  public maxAudioElementWidth: string = '178px';
  public maxVideoElementWidth: string = '273px';
  public maxUserElementWidth: string = '178px';
  public maxEventElementWidth: string = '273px';

  public audioElementsToShow: number | undefined = undefined;
  public userElementsToShow: number | undefined = undefined;
  public videoElementsToShow: number | undefined = undefined;
  public eventElementsToShow: number | undefined = undefined;

  protected sub: any = {};

  protected constructor() {
    this.pageSlug = '';
    this.pageTitle = '';
    this.IMG_BASE_URL = environment.API_BASE_URL;
    this.appRoutes = environment.APP_ROUTES;
    this.pageLoading = true;
  }

  public goToAnchor(elem, id) {
    document.getElementById(id).scrollIntoView({block: 'start', behavior: 'smooth'});
  }

  public scrollToTop() {
    const elem = document.getElementById('top-page-' + this.pageSlug);
    if (elem) {
      elem.scrollIntoView({block: 'end', behavior: 'smooth'});
    }
  }

  public scrollToElement(elementId: string) {
    const elem = document.getElementById(elementId);
    if (elem) {
      elem.scrollIntoView({block: 'start', behavior: 'smooth'});
    }
  }

  public checkFieldValid(formControl: AbstractControl) {
    return formControl.valid;
  }

  public checkFieldInvalid(formControl: AbstractControl) {
    return formControl.invalid && formControl.errors
      && (formControl.dirty || formControl.touched);
  }

  public calculatePublicationElementWidthAndQuantity(platform: Platform) {
    if (platform.width() >= 480 && !platform.is('hybrid')) {
      // Audio
      this.audioElementsToShow = Math.floor(platform.width() / 178);  // 178px max element width
      this.maxAudioElementWidth = ((platform.width() / this.audioElementsToShow) - (2 * 4)) + 'px'; // 4px margin
      // Users
      this.userElementsToShow = Math.floor(platform.width() / 178);  // 178px max element width
      this.maxUserElementWidth = ((platform.width() / this.userElementsToShow) - (2 * 4)) + 'px'; // 4px margin

      // Event
      this.eventElementsToShow = Math.floor(platform.width() / 237);  // 237px max element width
      this.maxEventElementWidth = ((platform.width() / this.eventElementsToShow) - (2 * 2)) + 'px'; // 2px margin

      // Videos
      this.videoElementsToShow = Math.floor(platform.width() / 273);  // 273px max element width
      this.maxVideoElementWidth = ((platform.width() / this.videoElementsToShow) - (2 * 2)) + 'px'; // 2px margin
    } else {
      this.audioElementsToShow = 10;
      this.userElementsToShow = 10;
      this.eventElementsToShow = 10;
      this.videoElementsToShow = 10;
    }
  }

  protected async showModal(modalCtrl: ModalController, modalPage: any, data: any, $cssClass: string = '') {
    const modal = await modalCtrl.create({
      component: modalPage,
      componentProps: data,
      cssClass: $cssClass
    });
    return await modal.present();
  }

  protected checkPasswords(group: UntypedFormGroup) {
    const password = group.get('password').value;
    const confirmPassword = group.get('repeatPassword').value;
    if (password !== confirmPassword) {
      group.get('repeatPassword').setErrors({notSame: true});
    } else if ( confirmPassword.trim().length === 0 ) {
      group.get('repeatPassword').setErrors({required: true});
    } else {
      group.get('repeatPassword').setErrors(null);
    }
  }

  protected unsubscribeAll() {
    Object.keys(this.sub).forEach(key => {
      this.sub[key]?.unsubscribe();
    });
  }

  protected async completeRefresher(refresher: IonRefresher) {
    const progress = await refresher.getProgress();

    if ( progress > 0 ) {
      refresher.complete();
    }
  }
}
