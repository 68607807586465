import { Component, OnInit, OnDestroy } from '@angular/core';
import { Platform } from '@ionic/angular';
import { skipWhile, take } from 'rxjs/operators';

import { IntroPage } from './intro/intro.page';
import { IntroLivePage } from './intro-live/intro-live.page';
import { RankingPage } from './ranking/ranking.page';
import { FinalPage } from './final/final.page';

import { AuthService } from 'src/app/services/auth.service';
import { StatusbarService } from 'src/app/services/statusbar.service';
import { ExtrimService } from 'src/app/services/extrim.service';

import { User } from 'src/app/interfaces/user';

import { ExtrimState } from 'src/app/enums/extrim-state';

@Component({
  selector: 'app-poll',
  templateUrl: './poll.component.html',
  styleUrls: ['./poll.component.scss'],
})
export class PollComponent implements OnInit, OnDestroy {

  rootPage: any = undefined;
  user: User;

  state: ExtrimState;

  constructor( 
    private extrimRace: ExtrimService,
    private platform: Platform,
    private authService: AuthService,
    private statusbar: StatusbarService
  ) {
    this.state = this.extrimRace.getCurrentState();
  }

  async ngOnInit() {
    this.authService.watchUser()
      .pipe(
        skipWhile(data => !data),
        take(1))
      .subscribe((user: User) => {
        this.user = user;
      });

    if ( this.platform.is('ios') ) 
      this.statusbar.setTextLight();

    if ( this.state === ExtrimState.AfterRace || this.state === ExtrimState.Ended ) {
      this.rootPage = FinalPage;
    } else if ( this.extrimRace.alreadyVoted() || this.state === ExtrimState.PreLive ) {
      this.rootPage = RankingPage;
    } else if ( this.state === ExtrimState.BeforeRace ) {
      this.rootPage = IntroPage;
    } else if ( this.state === ExtrimState.Live ) {
      this.rootPage = IntroLivePage
    }
  }
  ngOnDestroy(): void {
    if ( this.platform.is('ios') ) 
      this.statusbar.setTextDark();
  }

}
