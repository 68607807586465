<ion-header class="general-header-app">
  <app-header 
    [title]="type === ContentType.Event ? 'Configuración del Evento' : 'Configuración del Estreno'" 
    [menuButton]="false"
    [defaultBackAction]="false"
    (back)="goBack()">    
  </app-header>
</ion-header>

<ion-content #content>

  <!-- 
    -- Content 
    -->

  <!-- Type -->
  <div class="field type selector">
    <div class="title">Tipo</div>
    
    <div class="content">
      <div class="segment" (click)="isVideo = !isVideo">
        <div class="select" [ngClass]="{'active': !isVideo}"></div>

        <div class="button">
          Vídeo
        </div>
        <div class="button">
          Audio
        </div>
      </div>
    </div>
  </div>

  <!-- Date -->
  <div class="field date">
    <div class="title">Fecha</div>
    
    <div class="content">
      <div class="day">
        <ion-datetime-button *ngIf="viewInited" color="white" datetime="date"></ion-datetime-button>
        <ion-icon name="calendar-clear-outline"></ion-icon>

        <ion-modal class="fit-modal datetime-modal" [keepContentsMounted]="true">
          <ng-template>
            <ion-datetime 
              id="date"
              presentation="date"
              placeholder="Día"
              [preferWheel]="true"
              displayFormat="DD/MM/YY"
              monthShortNames="Ene, Feb, Mar, Abr, May, Jun, Jul, Ago, Sep, Oct, Nov, Dec"
              minuteValues="0,05,10,15,20,25,30,35,40,45,50,55"
              cancel-text="Atrás"
              done-text="Aceptar"
              [min]="minimumDate"
              [max]="maximumDate"
              [showDefaultButtons]="true"
              [(ngModel)]="date"
              (ionChange)="changeDate($event)">
            </ion-datetime>
          </ng-template>
        </ion-modal>
      </div>

      <div class="hour">
        <ion-datetime-button *ngIf="viewInited" color="white" datetime="datetime-hour"></ion-datetime-button>
        <ion-icon name="time-outline"></ion-icon>

        <ion-modal class="fit-modal datetime-modal" [keepContentsMounted]="true">
          <ng-template>
            <ion-datetime 
              id="datetime-hour"
              presentation="time"
              locale="es-ES"
              displayFormat="HH:mm"
              placeholder="Hora"
              minute-values="0,05,10,15,20,25,30,35,40,45,50,55"
              cancel-text="Atrás"
              done-text="Aceptar"
              [min]="minimumHour"
              [showDefaultButtons]="true"
              [(ngModel)]="hour"
              (ionChange)="date !== '' && hour !== '' ? error.date = '' : ''">
            </ion-datetime>
          </ng-template>
        </ion-modal>
      </div>

      <ion-text class="field-error" *ngIf="error.date" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>Debes introducir la fecha</span>
        </small>
      </ion-text>
    </div>
  </div>

  <!-- Title -->
  <div class="field less-margin input">
    <div class="content">
      <ion-input
        required
        maxlength="200"
        placeholder="Introduce un título"
        [(ngModel)]="title"
        (ionFocus)="inputFocused($event)"
        (ionChange)="error.title = false">
      </ion-input>

      <ion-text class="field-error" *ngIf="error.title" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>Debes introducir un título</span>
        </small>
      </ion-text>
    </div>
  </div>

  <!-- Description -->
  <div class="field description input">
    <div class="content">
      <ion-textarea
        required
        rows="1"
        maxlength="10000"
        placeholder="Introduce una descripción"
        [autoGrow]="true"
        [(ngModel)]="description"
        (ionFocus)="inputFocused($event)"
        (ionChange)="error.description = false">
      </ion-textarea>

      <ion-text class="field-error" *ngIf="error.description" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>Debes introducir una descripción</span>
        </small>
      </ion-text>
    </div>
  </div>

  <!-- Duraion -->
  <div class="field duration" *ngIf="type === ContentType.Event">
    <div class="title">Duración aproximada</div>

    <div class="content">
      <div class="time">
        <ion-datetime-button color="white" datetime="datetime-duration"></ion-datetime-button>
        <ion-icon name="time-outline"></ion-icon>

        <ion-modal class="fit-modal datetime-modal" [keepContentsMounted]="true">
          <ng-template>
            <ion-datetime 
              id="datetime-duration"
              presentation="time"
              locale="es-ES"
              hourValues="00,01,02,03,04,05,06,07,08,09,10,11,12,13,14,15,16,17,18,19,20,21,22,23"
              minuteValues="00,05,10,15,20,25,30,35,40,45,50,55"
              cancel-text="Atrás"
              done-text="Aceptar"
              [(ngModel)]="time"
              [showDefaultButtons]="true">
            </ion-datetime>
          </ng-template>
        </ion-modal>
      </div>
    </div>
  </div>

  <!-- Categories -->
  <div class="field category select">
    <div class="title">Categoría</div>

    <div class="content" (click)="pickCategory()">
      <ion-select
        mode="md"
        placeholder="Selecciona una categoría"
        [(ngModel)]="category"
        (ionChange)="error.category = false">
        <ion-select-option *ngFor="let category of categories" [value]="category.id">
          {{category.name}}
        </ion-select-option>
      </ion-select>

      <ion-text class="field-error" *ngIf="error.category" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>Debes seleccionar una categoría</span>
        </small>
      </ion-text>
    </div>
  </div>

  <!-- Collaborators -->
  <div class="field collaborators">
    <div class="title">Invita colaboradores</div>

    <div class="content" (click)="openFollowersToInvite()">
      <div class="item add-button">
        <div class="icon">
          <ion-icon name="add-outline"></ion-icon>
        </div>
      </div>

      <div class="item collaborator" *ngFor="let collaborator of collaborators">
        <lazy-img 
          class="image"
          handleError
          [size]="150" 
          [source]="getCollaboratorImg(collaborator.id, collaborator.imgProfile)">
        </lazy-img>
        <span class="username">{{collaborator.username}}</span>
      </div>
    </div>
  </div>

  <!-- Retransmisión -->
  <div class="field less-margin input">
    <div class="title">Servidor de retransmisión<app-pro-tag></app-pro-tag></div>

    <div class="content">
      <ion-input
        required
        maxlength="200"
        placeholder="Disponible después de guardar"
        readonly="true"
        [(ngModel)]="streamUrl">
      </ion-input>
    </div>
  </div>
  <div class="field less-margin input">
    <div class="title">Clave de retransmisión<app-pro-tag></app-pro-tag></div>

    <div class="content">
      <ion-input
        required
        maxlength="200"
        placeholder="Disponible después de guardar"
        readonly="true"
        [(ngModel)]="streamKey">
      </ion-input>
    </div>
  </div>

  <!-- Submit -->
  <div class="submit-button">
    <ion-button
      fill="clear"
      (click)="submitForm()">
      Siguiente
    </ion-button>
  </div>  

  <div class="keyboard-space" [ngStyle]="{'height': keyboardHeight + 'px'}"></div>

  <div class="ios-bottom-safe-area"></div>

</ion-content>
