import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

import { AuthService } from 'src/app/services/auth.service';
import { ContactPayloadCustom, ContactService } from 'src/app/services/contact.service';
import { UserService } from 'src/app/services/user.service';
import { Events } from 'src/app/services/events.service';
import { AnalyticsService } from 'src/app/services/analytics.service';

import { User } from 'src/app/interfaces/user';
import { skipWhile, take } from 'rxjs/operators';

@Component({
  selector: 'app-local-contact-list',
  templateUrl: './local-contact-list.component.html',
  styleUrls: ['./local-contact-list.component.scss'],
})
export class LocalContactListComponent  implements OnInit {

  @Input() contacts: ContactPayloadCustom[] = [];

  user: User;

  constructor( 
    private modalCtrl: ModalController,
    private contactService: ContactService,
    private authService: AuthService,
    private userService: UserService,
    private events: Events,
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit() {
    this.authService.watchUser()
      .pipe(
        skipWhile(data => !data),
        take(1))
      .subscribe((user: User) => {
        this.user = user;
      });

    this.analyticsService.setScreenName('local_contact_list');
  }

  dismiss() {
    this.modalCtrl.dismiss(null, 'cancel');
  }

  createInvitation(contact: ContactPayloadCustom) {
    if (!contact?.phone) {return;}
    const normalizedPhone = this.contactService.normalizePhone(contact.phone).phone;

    this.authService.createInvitation(normalizedPhone, this.user.username)
      .subscribe(
        async (res) => {
          if (res.done) {
            contact.invited = true;
            await this.sendInvitation();
            this.modalCtrl.dismiss(null, 'confirm');
          } else {
            contact.invited = false;
            this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, res.msg);
          }
        },
        error => {
          contact.invited = false;
          this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR);
        },
      );
  }

  async sendInvitation() {
    return await this.userService.shareUrl(
      '¡Hola! Te invito a que te unas a Estrim. Descarga la App a través de las stores o bien accede a través de https://estrim.com y regístrate con tu número de teléfono.',
      '',
      `${this.user.username} te ha invitado a unirte a Estrim`
    );
  }
}
