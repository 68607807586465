export const goToUserProfile = (urlRoute: string, username: string) => {
  if(!!urlRoute.includes(('/home'))) {
    return'/home/user/' + username;
  } else if(!!urlRoute.includes('/me')) {
    return '/profile/user/' + username;
  } else {
    return '/user/' + username;
  }
};

export const goToOtherUsers = (urlRoute: string, id: string) => {
  if(!!urlRoute.includes(('/home'))) {
    return `/home/other-users/${id}`;
  } else if(!!urlRoute.includes('/profile')) {
    return !!id ? `/profile/me/other-users/${id}` : '/profile/me/other-users';
  } else {
    return `/user/other-users/${id}`;
  }
};
