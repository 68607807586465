import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Events } from './events.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RouterDataService {

  private _data: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  data = this._data.asObservable();

  constructor( private events: Events ) {
    this.events.subscribe(environment.EVENTS.USER_LOGOUT, () => {
      this._data.next(null);
    });
  }

  /**
   * Getter y setter del estado de la galeria (abierto o cerrado)
   */
  setData(data: any) {
    this._data.next(data);
  }
  getData(): any {
    return this._data.getValue();
  }
}
