import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input, OnChanges,
  OnInit, OnDestroy, Output,
  SimpleChanges,
  EventEmitter,
  ViewChild,
  SecurityContext
} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { MediaService } from '../../services/media.service';
import { inOutFade } from '../../animations/animations';
import { ScreenOrientationService } from 'src/app/services/screen-orientation.service';
import { Platform } from '@ionic/angular';
import { StatusBar } from '@capacitor/status-bar';
import { NavigationBar } from '@mauricewegner/capacitor-navigation-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import Hls from 'hls.js';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  animations: [inOutFade]
})
export class VideoPlayerComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @ViewChild('videoElement', {static: true}) videoElement: ElementRef;

  @Input() identifier: number = 0;
  @Input() videoSrc: string | SafeUrl = '';
  @Input() maximized: boolean = false;
  @Input() autoplay: boolean = true;
  @Input() loop: boolean = false;
  @Input() canMaximize: boolean = true;
  @Input() showOverlayInPaused: boolean = false;
  @Input() preload: string = "metadata";
  @Input() layoutButtons: boolean = true;
  @Input() seekButtons: boolean = true;
  @Input() poster: string | undefined = undefined;
  @Input() overlay: boolean = true;
  @Input() keepPlayPauseOverlay: boolean = false;
  @Input() keepRangeOverlay: boolean = false;
  @Input() rangeWithoutTime: boolean = false;
  @Input() rangeIndicatorAlwaysVisible: boolean = false;
  @Input() disableLoadingSpinner: boolean = false;
  @Input() disableOverlay: boolean = false;

  @Output() countViewer = new EventEmitter();
  @Output() overlayStatus = new EventEmitter();
  @Output() maximizeRequest = new EventEmitter();
  @Output() medatadaLoaded = new EventEmitter();
  @Output() aspectRatio = new EventEmitter();

  video: HTMLVideoElement;
  mediaServ: MediaService;
  duration;
  currentTime;
  currentRangeTime;
  overlayActivated = false;
  overlayTimeout: any;
  playing = false;
  countSecondsTimeout = null;
  viewerCounted = false;
  seeking: boolean = false;
  seekTime: number;

  videoPlayerFormat: string = 'landscape';

  backwardSeekTrigger: boolean = false;
  forwardSeekTrigger: boolean = false;
  backwardSeekAnimationTimeout = undefined;
  forwardSeekAnimationTimeout = undefined;

  fit: boolean = false;

  loading: boolean = true;

  hls: Hls = undefined;
  hlsMode: boolean = false;

  constructor(
    private mediaService: MediaService,
    private screenOrientation: ScreenOrientationService,
    private platform: Platform,
    private cdRef: ChangeDetectorRef,
    private sanitized: DomSanitizer
  ) {
    this.mediaServ = this.mediaService;
  }

  ngOnInit() {
    this.video = this.videoElement.nativeElement;

    try {
      if ( (this.videoSrc as string)?.split('.').pop() === 'm3u8' ) {
        this.loadHls();
      } else {
        this.video.src = (this.videoSrc as string);

        if ( this.autoplay ) {
          this.play();
        }
      }
    } catch(e) {
      this.video.src = this.sanitized.sanitize(SecurityContext.RESOURCE_URL, this.videoSrc);
    }
  }

  ngOnDestroy() {
    if ( this.playing ) {
      this.video?.pause();
      this.video?.removeAttribute('src');
      this.video?.load();
    }

    clearTimeout(this.countSecondsTimeout);
    this.countSecondsTimeout = null;

    if ( this.hlsMode ) {
      this.hls.detachMedia();
      this.hls.destroy();
    }
  }

  ngOnChanges(changes: SimpleChanges) {}

  ngAfterViewInit() {
    this.video = this.videoElement.nativeElement;

    if(this.showOverlayInPaused && this.video.paused){
      this.activateOverlay();
    }
  }

  loadHls() {
    this.video?.removeAttribute('src');
    this.video?.load();

    this.cdRef.detectChanges();

    if (Hls.isSupported()) {
      const config = {
        autoStartLoad: true,
        enableWorker: true,
        lowLatencyMode: true,
        backBufferLength: 90,
        startLevel: 4
      }

      // Create hls player
      this.hls = new Hls(config);
      // Attach to video tag
      this.hls.attachMedia(this.video);
      // Load source
      this.hls.loadSource( (this.videoSrc as string) );

      this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {});

      this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        this.loading = false;

        if ( this.autoplay ) {
          this.video.play();
        } else {
          // Force first frame load
          this.video.currentTime = 0.1;
        }

        this.calculateRatio(undefined);

        this.cdRef.detectChanges();

        this.hls.loadLevel = this.hls.maxAutoLevel;
      });

      this.hlsMode = true;
    }
    else if (this.video.canPlayType('application/vnd.apple.mpegurl')) {
      // Force first frame load
      this.video.src = this.videoSrc + '#t=0.1';
    }
  }

  /**
   * Avanza en el fichero
   * @param {any} event
   */
  seek(event: any) {
    if (this.video) {
      try {
        let seekTo = event.target.value;

        this.video.currentTime = seekTo;

        this.seeking = false;
      } catch (e) {
      }
    }
  }

  setDuration() {
    this.duration = this.video.duration;
  }

  updateCurrentTime() {
    this.currentTime = this.video.currentTime;

    if ( !this.seeking ) {
      this.currentRangeTime = this.currentTime;
    }
  }

  activateOverlay() {
    if ( !this.disableOverlay ) {
      this.overlayActivated = true;
      this.overlayStatus.emit(this.overlayActivated || !this.playing);
      this.cdRef.detectChanges();

      clearTimeout(this.overlayTimeout);

      this.overlayTimeout = setTimeout(() => {
        if(this.showOverlayInPaused && this.video.paused){ return; }
        this.overlayActivated = false;
        this.overlayStatus.emit(this.overlayActivated || !this.playing);
        this.cdRef.detectChanges();
      }, 2000);
    }
  }

  public pause() {
    this.hlsMode ? this.hls.stopLoad() : null;
    this.video.pause();
    this.playing = false;
    this.cdRef.detectChanges();
  }

  public play() {
    this.hlsMode ? this.hls.startLoad() : null;

    if (this.duration - this.video.currentTime < 0.2) {
      this.video.currentTime = 0;
    }
    this.video.play().then().catch((e) => console.log(e));
  }

  /**
   * Avanza o retrocede x segundos
   * @param {number} seconds
   */
  seekBy(seconds: number) {
    if ( seconds && (this.video.currentTime + seconds) >= this.duration ) {
      this.pause();
      this.video.currentTime = this.duration;
    } else if ( !seconds && (this.video.currentTime + seconds) < 0 ) {
      this.pause();
      this.video.currentTime = 0;
    } else {
      this.video.currentTime += seconds;
    }

    this.updateCurrentTime();
  }

  seekBackward() {
    this.seekBy(-10);

    clearTimeout(this.backwardSeekAnimationTimeout);

    this.backwardSeekTrigger = false;
    this.cdRef.detectChanges;
    this.backwardSeekTrigger = true;

    this.backwardSeekAnimationTimeout = setTimeout(() => {
      this.backwardSeekTrigger = false;
      this.cdRef.detectChanges;
    }, 1000)
  }

  seekForward() {
    this.seekBy(10);

    clearTimeout(this.forwardSeekAnimationTimeout);

    this.forwardSeekTrigger = false;
    this.cdRef.detectChanges;
    this.forwardSeekTrigger = true;

    this.forwardSeekAnimationTimeout = setTimeout(() => {
      this.forwardSeekTrigger = false;
      this.cdRef.detectChanges;
    }, 1000)
  }

  setMute(mute: boolean) {
    this.video.muted = mute;
  }

  onPlaying() {
    this.playing = true;
    this.cdRef.detectChanges();

    if (this.countSecondsTimeout || this.viewerCounted) {
      return
    }
    this.countSecondsTimeout = setTimeout(() => {
      this.countViewer.emit();
      this.viewerCounted = true;
      clearTimeout(this.countSecondsTimeout);
      this.countSecondsTimeout = null;
    }, 500);

    this.calculateRatio(undefined);
  }

  onLoadedData($event) {
    this.loading = false;
    this.cdRef.detectChanges();
  }

  onLoadedMetadata($event) {
    this.medatadaLoaded.emit($event);
    this.calculateRatio($event);
  }

  onPinchIn() {
    if ( this.maximized ) {
      this.fit = false;
    }
  }
  onPinchOut() {
    if ( this.maximized ) {
      this.fit = true;
    }
  }

  generateSeekingTo($event) {
    if ( this.seeking ) {
      this.seekTime = $event.detail.value;
      this.cdRef.detectChanges();
    }
  }

  maximize() {
    this.maximized = !this.maximized;

    if ( this.maximized && this.videoPlayerFormat === 'landscape' ) {
      // Force landscape mode
      this.screenOrientation.lockLandscape();
      StatusBar.hide();
      this.platform.is('android') ? NavigationBar.hide() : null;
    } else if ( this.maximized && this.videoPlayerFormat === 'vertical' ) {
      // Force portrait mode
      this.screenOrientation.lockPortrait();
      StatusBar.hide();
      this.platform.is('android') ? NavigationBar.hide() : null;
    }

    this.maximizeRequest.emit(this.maximized);
  }

  calculateRatio($event) {
    let ratio = 1;

    if ( !this.hlsMode && $event ) {
      ratio = $event.target.videoWidth / $event.target.videoHeight;
    } else {
      ratio = this.video.videoWidth / this.video.videoHeight;
    }

    if ( ratio > 1 ) {
      this.videoPlayerFormat = 'landscape';
    } else if ( ratio < 1 ) {
      this.videoPlayerFormat = 'vertical';
    } else {
      this.videoPlayerFormat = 'square';
    }

    this.aspectRatio.emit(ratio);
  }
}
