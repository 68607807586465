<ion-content>
  <a (click)="dismiss()" class="close">
    <img src="assets/extrim/img/icons/close-white.svg" width="30" />
  </a>
  
  <section id="vota-er" class="section">
    <div class="content">
      <!-- <a class="go-back" (click)="back()">
        <img class="d-block" src="assets/extrim/img/arrow-left.webp" width="100" />
      </a> -->
      <div class="h3 font-venera">VOTA POR TU<br/>PILOTO FAVORITO 
        <a (click)="showInfo()">
          <img width="18" class="trigger-info" src="assets/extrim/img/icons/icon_info.svg" alt="Icon Info" />
        </a>
      </div>
      
      <ul class="pilots">
        <li (click)="confirmVote(pilot)" *ngFor="let pilot of pilots">
          <div class="thumb" [ngClass]="{
            'icon': pilot.winner || pilot.loser,
            'winner': pilot.winner,
            'loser': pilot.loser,
          }">
            <img class="lock" *ngIf="pilot.winner || pilot.loser" src="assets/extrim/img/icons/lock.svg" width="20" />
            <lazy-img [source]="mediaService.generateThumbnailImgURL(pilot.thumbnail)" [size]="400"></lazy-img>
          </div>
          <h2>{{pilot.name}}</h2>
        </li>
      </ul>
    </div>
  </section>
</ion-content>
