import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import {LoginPageRoutingModule} from './login-routing.module';
import { LoginPage } from './login.page';
import {DirectivesModule} from '../../directives/directives.module';
import {ComponentsModule} from '../../components/components.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        LoginPageRoutingModule,
        ReactiveFormsModule,
        DirectivesModule,
        ComponentsModule
    ],
  declarations: [LoginPage]
})
export class LoginPageModule {}
