import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Participant, Track } from 'livekit-client';
import { UserStreamMetadata } from 'src/app/interfaces/stream-user';
import { Events } from 'src/app/services/events.service';
import { LiveKitClient } from 'src/app/services/livekit.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-stream-video',
  templateUrl: './video-stream.component.html',
  styleUrls: ['./video-stream.component.scss'],
})
export class LiveKitStreamComponent implements OnInit, OnDestroy {

  @Input() participant: Participant;
  @Input() track: Track;
  @Input() privateRoom: boolean;
  @Input() autoplay: boolean;
  @Input() local: boolean;
  @Input() isVideo: boolean;
  @Input() onlyAudio: boolean;
  @Input() isCollaborator: boolean;
  @Input() giratedVideos: any;
  @ViewChild('stream', { static: false }) videoElement: ElementRef;
  stream: any;
  girated: boolean;
  width: number;
  height: number;
  horizontal: boolean;
  peerUsername: string;

  constructor(
    private elementRef: ElementRef,
    private events: Events,
    private liveKitClient: LiveKitClient,
    ) {
  }
  ngOnDestroy(): void {
  }

  ngOnInit() {
    /**
     * Validate if its video or audio track
     */
    if (this.track.kind === 'video') {
      this.isVideo = true;
    } else {
      this.isVideo = false;
    }
  }

  async ngAfterViewInit() {
    this.stream = this.videoElement.nativeElement;
    this.setUsername();

    if (this.local) {
      this.stream.muted = true;
    }

    this.stream.controls = false;
    this.stream.autoplay = true;

    this.attachStream(this.track, this.local);
    this.stream = this.videoElement.nativeElement;

    if (this.giratedVideos && this.giratedVideos.some(s => s === this.participant.identity)) {
      this.girated = true;
    }

    try {
      const playing = await this.stream.play();
    } catch (error) {
      if(error.code === 20){ return; }

      try {
        this.stream.muted = true;
        const playing = await this.stream.play();
      } catch (err) {
      }

      this.events.publish(environment.EVENTS.ALERT_TOAST_NOTICE,
        'Haz click en cualquier parte de la pantalla para activar el sonido');
    }
  }



  isHorizontal(){
    if(!this.stream){ return this.horizontal; }
    if(this.stream.videoHeight > this.stream.videoWidth){
      this.horizontal = false;
    } else {
      this.horizontal = true;
    }
    return this.horizontal;
  }


  /**
   * Set username from metadata or participant identity (For references only)
   */
  setUsername() {
    this.peerUsername = this.participant.identity;

    if(!this.participant.metadata){
      //TODO: get metadata from livekit
    } else {
      const metadata: UserStreamMetadata = JSON.parse(this.participant.metadata);
      this.peerUsername = metadata.username;
    }
  }

  /**
   * Get stream, setup and attach to #stream [HTMLMediaElement]
   *
   * @param track - Track to attach
   * @param local - Is local or remote (boolean)
   * @returns HTMLMediaElement (audio or video)
   */
  private attachStream(track: Track, local: boolean): HTMLMediaElement {
    try {
      const video: any = track.attach(this.stream);

      if (local) {
        video.muted = true;
      } else {
        video.muted = false;
      }

      video.controls = false;
      video.autoplay = true;

      if (this.track.kind === 'video') {
        this.width = video.videoWidth;
        this.height = video.videoHeight;
        if(this.height > this.width){
          this.horizontal = false;
        } else {
          this.horizontal = true;
        }
      }
      return video;
    } catch (error) {
      console.error('attachStream->', error);
    }
  }
}
