<div class="steps" 
  [ngStyle]="{
    'transition': viewInited ? 'height .3s' : 'none',
    'height': stepsContainerHeight + 'px'
  }">

  <div class="close" (click)="dismiss()">
    <ion-icon name="close-outline"></ion-icon>
  </div>

  <div #step class="step" 
    *ngIf="slideStep === 0" 
    [@inOutAnimation]="animationDirection"
    [@.disabled]="!viewInited">
    <!-- User -->
    <div class="user">
      <div class="user-image">
        <img handleError class="badge special collaborator" src="assets/badges/special/collaborator-1x.png" alt="">

        <div class="avatar badge">
          <div class="pro-border" *ngIf="user?.isPro" >
            <div class="image-place"></div>
          </div>

          <lazy-img
            [ngClass]="{'pro': user?.isPro}"
            [size]="400"
            [source]="getImgProfile()">
          </lazy-img>
          <app-pro-tag *ngIf="user?.isPro" class="pro-tag"></app-pro-tag>
        </div>
      </div>

      <div class="data">
        <div class="name">
          <span>{{user?.firstname}}</span>
          <img handleError class="founder-badge" *ngIf="user?.isFounder" src="assets/badges/founder-badge.svg">
        </div>
        <div class="username">@{{user?.username}}</div>
      </div>
    </div>

    <div class="paragraphs">
      <div class="paragraph">
        Tu colaborador/a podrá emitir en tus Canales de vídeo o Salas de audio y viceversa.
      </div>

      <div class="paragraph">
        En tu ausencia, si un colaborador emite en tu canal/sala, tendrá el control y la gestión de la emisión pero no podrá acceder a la configuración de la misma.
      </div>
    </div>

    <!-- Buttons -->
    <div class="buttons">
      <ion-button color="darkteal" (click)="collaborateWithUser()" *ngIf="!loading">
        Solicitar colaboración
      </ion-button>

      <div class="loading" *ngIf="loading">
        <ion-spinner name="crescent"></ion-spinner>
      </div>
      
      <ion-button 
        fill="clear"
        color="dark" 
        class="go-back" 
        (click)="dismiss()">
        Cancelar
      </ion-button>
    </div>
  </div>

  <div #step class="step step-2" 
    *ngIf="slideStep === 1"
    [@inOutAnimation]="animationDirection"
    [@.disabled]="!viewInited">
    <div class="title">
      Tu solicitud ha sido enviada correctamente
    </div>

    <!-- Info -->
    <div class="info">
      <div class="item">
        <div class="icon stick pending">
          <ion-icon src="assets/svg-icon/stick-star.svg"></ion-icon>
        </div>

        <div class="text">
          <div class="intro">
            Solicitud pendiente
          </div>

          <div class="description">
            La solicitud está pendiente de ser aceptada.
          </div>
        </div>
      </div>

      <div class="item">
        <div class="icon stick accepted">
          <ion-icon src="assets/svg-icon/stick-star.svg"></ion-icon>
        </div>

        <div class="text">
          <div class="intro">
            Solicitud aceptada
          </div>

          <div class="description">
            La solicitud ha sido aceptada, ya eres colaborador de este perfil.
          </div>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="buttons">
      <ion-button 
        color="light"
        class="go-back" 
        (click)="dismiss()">
        Cerrar
      </ion-button>
    </div>
  </div>

</div>