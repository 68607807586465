import { trigger, transition, animate, style, query, group, keyframes, state } from '@angular/animations';

export const inOutFade = trigger('inOutFade', [
  transition(':enter', [
    style({opacity: '0'}),
    animate('300ms', style({opacity: '1'}))
  ]),
  transition(':leave', [
    animate('300ms', style({opacity: '0'}))
  ])
]);

export const inOutFromTop = trigger('inOutFromTop', [
  transition(':enter', [
    style({transform: 'translateY(-100%)'}),
    animate('300ms ease', style({transform: 'translateY(0%)'}))
  ]),
  transition(':leave', [
    animate('300ms ease', style({transform: 'translateY(-100%)'}))
  ])
]);

export const inOutFromBottom = trigger('inOutFromBottom', [
  transition(':enter', [
    style({transform: 'translateY(100%)'}),
    animate('300ms ease', style({transform: 'translateY(0%)'}))
  ]),
  transition(':leave', [
    animate('300ms ease', style({transform: 'translateY(100%)'}))
  ])
]);

export const inOutFromLeft = trigger('inOutFromLeft', [
  transition(':enter', [
    style({transform: 'translateX(-100%)'}),
    animate('300ms ease', style({transform: 'translateX(0%)'}))
  ]),
  transition(':leave', [
    animate('300ms ease', style({transform: 'translateX(-100%)'}))
  ])
]);

export const inOutFromRight = trigger('inOutFromRight', [
  transition(':enter', [
    style({transform: 'translateX(100%)'}),
    animate('300ms ease', style({transform: 'translateX(0%)'}))
  ]),
  transition(':leave', [
    animate('300ms ease', style({transform: 'translateX(100%)'}))
  ])
]);

export const inOutFromBottomFade = trigger('inOutFromBottomFade', [
  transition(':enter', [
    style({transform: 'translateY(100%)', opacity: '0'}),
    animate('300ms ease', style({transform: 'translateY(0%)', opacity: '1'}))
  ]),
  transition(':leave', [
    animate('300ms ease', style({transform: 'translateY(100%)', opacity: '0'}))
  ]) 
]);

export const inOutStarAnimation1 = trigger('inOutStarAnimation1', [
  transition(':enter', animate('700ms ease-out', keyframes([
    style({transform: 'scale(0)', opacity: '0', offset: 0}),
    style({transform: 'scale(1.2)', opacity: '1', offset: 0.5}),
    style({transform: 'scale(0.9)', opacity: '1', offset: 0.75}),
    style({transform: 'scale(1)', opacity: '1', offset: 1})
  ]))),
  transition(':leave', [
    animate('150ms ease', style({transform: 'scale(0.5)', opacity: '0'}))
  ]) 
]);

export const starAnimation1 = trigger('starAnimation1', [
  transition('0 => 1', animate('700ms ease-out', keyframes([
    style({transform: 'scale(0)', opacity: '0', offset: 0}),
    style({transform: 'scale(1.2)', opacity: '1', offset: 0.5}),
    style({transform: 'scale(0.9)', opacity: '1', offset: 0.75}),
    style({transform: 'scale(1)', opacity: '1', offset: 1})
  ]))),
]);
