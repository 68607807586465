import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ImageCroppedEvent, ImageTransform, ImageCropperComponent } from 'ngx-image-cropper';
import { ModalController, NavController } from '@ionic/angular';
import { ProfileService } from '../../services/profile.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { Events } from '../../services/events.service';
import { environment } from '../../../environments/environment';
import { MediaService } from '../../services/media.service';

@Component({
  selector: 'app-crop-image',
  templateUrl: './crop-image.page.html',
  styleUrls: ['./crop-image.page.scss'],
})
export class CropImagePage implements OnInit, AfterViewInit {

  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

  @Input() imageFile: File;
  @Input() imgTarget: string;
  @Input() canCancel: boolean = true;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  imageHTML;
  cropperHTML;
  roundCropper = false;
  aspectRatio = 4 / 4;
  transform: ImageTransform = {};

  constructor(private router: Router, private navController: NavController, private elem: ElementRef,
              private profileService: ProfileService, private authService: AuthService, private events: Events,
              private modalCtrl: ModalController, private mediaService: MediaService) {
  }

  ngOnInit() {
    console.log('target', this.imgTarget);
    if (this.imgTarget === 'thumbnail') {
      this.roundCropper = false;
    } else if (this.imgTarget === 'live') {
      this.roundCropper = false;
      this.aspectRatio = 16 / 9;
    } else if (this.imgTarget === 'clip') {
      this.roundCropper = false;
      this.aspectRatio = 9 / 16;
    } else if (this.imgTarget === 'audio') {
      this.aspectRatio = 1 / 1;
      this.roundCropper = false;
    } else if (this.imgTarget !== 'profile') {
      this.roundCropper = false;
      this.aspectRatio = 25 / 9;
    }
  }

  ngAfterViewInit() {
    this.imageHTML = this.elem.nativeElement.querySelector('.source-image');
    this.cropperHTML = this.elem.nativeElement.querySelector('.cropper');
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageLoaded() {
    /* show cropper */
  }

  cropperReady() {
    /* cropper ready */
  }

  loadImageFailed() {
    /* show mess*/
  }

  adjustZoom(event: any) {
    const zoomValue = event.detail.value / 10;
    this.transform = {
      ...this.transform,
      scale: zoomValue
    };

    //TODO hacer que el zoom de la imagen siga la posición del cropper,la librería no trae esta funcionalidad.
    // las lineas de abajo hacen que la imagen se escale con el transform-origin correcto pero no se guarda correctamente el recorte
    // const left = parseFloat(this.cropperHTML.style.left.replace('px', ''));
    // const top = parseFloat(this.cropperHTML.style.top.replace('px', ''));
    // const offsetX = this.cropperHTML.offsetWidth / 2 + left;
    // const offsetY = this.cropperHTML.offsetHeight / 2 + top;
    // this.imageHTML.style.cssText = `transform-origin: ${offsetX}px ${offsetY}px`;
    // this.imageHTML.style.transform = `scale(${zoomValue})`;
  }

  get profileInfo() {
    return this.profileService.profileInfo;
  }

  async saveImage() {
    // Crop image
    const imageCroppedEvent = await this.imageCropper.crop();
    // Get blob data
    const blobData = imageCroppedEvent.blob;
    // Create file
    const imageFile = new File([blobData], 'image.png');

    this.modalCtrl.dismiss({
      croppedFile: imageFile
    });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
