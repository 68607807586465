<!-- Icon -->
<div class="title-icon">
  <ion-icon class="icon upload" src="assets/svg-icon/camera3.svg"></ion-icon>
</div>

<!-- Title -->
<div class="title">
  Canales de Vídeo
</div>

<!-- Subtitle -->
<div class="subtitle">
  Crea un canal de vídeo y comienza una emisión en directo. Configura tu canal y elige tus preferencias para que otros usuarios puedan interactuar a través del “Chat” o “Levantar la mano” interviniendo en vídeo o audio.
</div>

<!-- Subtitle -->
<div class="subtitle">
  Una vez finalizada la emisión podrás volver a emitir en ese canal cada vez que quieras o interactuar con otros usuarios mediante los comentarios.
</div>

<!-- Buttons -->
<div class="buttons">
  <ion-button color="darkteal" (click)="confirm()" *ngIf="confirmButton">
    Crear canal de vídeo
  </ion-button>
  <ion-button 
    fill="clear" 
    color="dark"
    class="go-back" 
    (click)="dismiss()">
    Cerrar
  </ion-button>
</div>