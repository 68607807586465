import {
  ChangeDetectionStrategy,
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';

@Component({
  selector: 'lazy-img',
  templateUrl: './lazy-img.component.html',
  styleUrls: ['./lazy-img.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LazyImgComponent {
  @ViewChild('image') image: ElementRef = undefined;

  @Input() source = '';
  @Input() alt = '';
  @Input() size: number;
  @Input() zoomable: boolean = false;
  @Input() avatarZoomable: boolean = false;
  @Input() cover: boolean = true;

  @Output() onLoad: EventEmitter<HTMLImageElement> = new EventEmitter();

  public isLoaded = false;

  constructor(private cdf: ChangeDetectorRef) {}
  markForChange() {
    this.isLoaded = true;
    this.cdf.markForCheck();
    this.onLoad.emit(this.image?.nativeElement);
  }
}
