<div
  class="disabler"
  *ngIf="showMiniProfile"
  @inOutFade
  [ngStyle]="{'z-index': zIndex}"
  (click)="showMiniProfile = false"
></div>
<app-profile-header
  class="mini-profile"
  *ngIf="showMiniProfile"
  @inOutProfileHeader
  [ngStyle]="{'z-index': zIndex}"
  [reduced]="true"
  [canSendStars]="userToShowProfile.canSendStars"
  [canInvite]="userToShowProfile.canInvite"
  [canPing]="userToShowProfile.canPing"
  [canKick]="userToShowProfile.canKick"
  [canSilent]="userToShowProfile.canSilent"
  [user]="userToShowProfile.user"
  [isCreator]="userToShowProfile.isCreator"
  [isCollaborator]="userToShowProfile.isCollaborator"
  [isSubscribed]="userToShowProfile.isSubscribed"
  (userUpdated)="userToShowProfile.userUpdated($event)"
  (sendPingUser)="userToShowProfile.sendPingUser($event)"
  (sendInviteUser)="userToShowProfile.sendInviteUser($event)"
  (sendStars)="userToShowProfile.sendStars()"
  (kick)="userToShowProfile.kick($event)"
  (silent)="userToShowProfile.silent()"
  (closed)="closeProfile()"
></app-profile-header>