import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[stream]'
})
export class VideoDirective {
  @Input() video: HTMLMediaElement;
  @Input() autoplay: boolean;
  @Input() local: boolean;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    if(this.local){
        this.video.muted = true;
    }
    this.video.controls = false;
    this.video.autoplay = true;
    this.elementRef.nativeElement.appendChild(this.video);
  }

}
