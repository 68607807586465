export const addAndRemoveItems = <T>(newArray: T[], oldArray: T[], keyOfFind: keyof T): T[] => {
  //Agregar los nuevos
  newArray.forEach((item) => {
    if (!oldArray.some((existing) => existing[keyOfFind] === item[keyOfFind])) {
      oldArray.push(item);
    }
  });

  //Eliminar los que ya no están en la lista
  oldArray = oldArray.filter((existing) =>
    newArray.some((item) => item[keyOfFind] === existing[keyOfFind]));

  return oldArray;
};

export const shuffle = <T>(array: T[]): T[] => {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};
