<div class="title">
  {{ title }}
</div>

<form class="field phone" [formGroup]="phoneForm">
  <div class="content">
    <div class="country">
      <img handleError
        *ngIf="selectedCountry?.countryImg"
        class="flag-image"
        alt=""
        [src]="'assets/country-flags/'+selectedCountry.countryImg+'.svg'">
      <ion-select
        mode="md"
        class="select"
        value="34"
        cancelText="Cancelar"
        okText="Aceptar"
        [value]="selectedCountry"
        [selectedText]="'+' + selectedCountry.code"
        (ionChange)="changeFlag($event)">
        <ion-select-option [value]="country" *ngFor="let country of countries">
          <span>{{country.countryName}} +{{country.code}} </span>
        </ion-select-option>
      </ion-select>
    </div>

    <div class="input">
      <ion-input
        only-numeric
        type="tel"
        placeholder="Introduce tu número"
        formControlName="phone"
        (ionInput)="checkPhoneExists(true)">
      </ion-input>
    </div>

    <ion-text class="field-error" *ngIf="error.phoneInvalid" color="danger">
      <small>
        <ion-icon name="alert-circle-outline"></ion-icon>
        <span>El teléfono no es correcto</span>
      </small>
    </ion-text>
  
    <ion-text class="field-error" *ngIf="error.phoneExists" color="danger">
      <small>
        <ion-icon name="alert-circle-outline"></ion-icon>
        <span>{{ errorMessage }}</span>
      </small>
    </ion-text>
  </div>
</form>

<!-- Buttons -->
<div class="submit-button">
  <ion-button
    fill="clear"
    (click)="validate()"
    [disabled]="phoneHaveToBeChecked || checkingPhone">
    {{ okButtonText }}
    <ion-spinner *ngIf="phoneHaveToBeChecked || checkingPhone" name="crescent"></ion-spinner>
  </ion-button>
</div>
