import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class MetaTagsService {
    public META_TAGS: any;
    private defaultStr: string;

    constructor(private http: HttpClient) {
        this.defaultStr = 'default';
    }

    public loadSitemap() {
        return this.http.get('assets/data/site-map.html', {responseType: 'text'});
    }

    public load() {
        return this.http.get('assets/data/metas.json').toPromise();
    }

    public async getMeta($page: string) {
        if (!this.META_TAGS) {
            this.META_TAGS = await this.load();
        }

        if (this.META_TAGS) {
            if (this.META_TAGS[$page]) {
                return this.META_TAGS[$page];
            } else if (this.META_TAGS[this.defaultStr]) {
                return this.META_TAGS[this.defaultStr];
            }
        }

        return null;
    }
}
