<ion-header class="general-header-app">
  <app-header 
    [title]="type === ContentType.LiveAudio ? 'Crear sala de audio' : 'Crear canal de vídeo'" 
    [menuButton]="false"
    [defaultBackAction]="false"
    (back)="dismiss()">    
  </app-header>
</ion-header>

<ion-content>
  <div class="header" (click)="showInfo()">
    <h4 class="title" *ngIf="type === ContentType.LiveVideo">Canales de <span class="primary-color">vídeo</span></h4>
    <h4 class="title" *ngIf="type === ContentType.LiveAudio">Salas de <span class="primary-color">audio</span></h4>
    <ion-icon src="assets/svg-icon/info.svg"></ion-icon>
  </div>

  <div class="list">
    <div class="element add"
      [ngClass]="{
        'video': type === ContentType.LiveVideo,
        'audio': type === ContentType.LiveAudio
      }">
      <ion-button fill="clear" (click)="goToForm()">
        <ion-icon name="add-outline"></ion-icon>
      </ion-button>

      <!-- Audio preview -->
      <div class="preview" *ngIf="type === ContentType.LiveAudio">
        <ion-skeleton-text [animated]="false" style="width: 100%; --border-radius: 5px; margin-left: auto; margin-right: auto;"></ion-skeleton-text>
        <ion-skeleton-text [animated]="false" style="width: 70%; --border-radius: 5px; margin-left: auto; margin-right: auto;"></ion-skeleton-text>
      </div>

      <!-- Video preview -->
      <div class="preview video" *ngIf="type === ContentType.LiveVideo">
        <div class="profile-img">
          <ion-thumbnail>
            <ion-skeleton-text [animated]="false"></ion-skeleton-text>
          </ion-thumbnail>

          <div class="profile-name">
            <ion-skeleton-text [animated]="false" style="width: 80px; --border-radius: 5px;"></ion-skeleton-text>
          </div>
        </div>
        <div class="description">
          <p>
            <ion-skeleton-text [animated]="false" style="width: 90%; --border-radius: 5px;"></ion-skeleton-text>
            <ion-skeleton-text [animated]="false" style="width: 50%; --border-radius: 5px;"></ion-skeleton-text>
          </p>
        </div>
      </div>
    </div>

    <div class="loading" 
      *ngIf="loading" 
      [ngClass]="{
        'video': type === ContentType.LiveVideo,
        'audio': type === ContentType.LiveAudio
      }">
      <ion-spinner color="primary" name="crescent"></ion-spinner>
    </div>

    <ng-container *ngIf="!loading && type === ContentType.LiveVideo">
      <app-record-video-thumbnail *ngFor="let publication of publications" 
        [ngClass]="{'fromProfile' : user.id !== 0}"
        [publication]="publication" 
        [showCreatedAt]=true
        (click)="dismiss()">
      </app-record-video-thumbnail>
    </ng-container>

    <ng-container *ngIf="!loading && type === ContentType.LiveAudio">
      <app-record-audio-thumbnail *ngFor="let publication of publications" 
        [ngClass]="{'fromProfile' : user.id !== 0}"
        [publication]="publication" 
        [showCreatedAt]=true
        (click)="dismiss()">
      </app-record-audio-thumbnail>
    </ng-container>
  </div>

  <ion-infinite-scroll #infiniteScroll threshold="100px" (ionInfinite)="requestData()">
    <ion-infinite-scroll-content
      loadingSpinner="crescent"
      loadingText="">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
