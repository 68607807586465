<ion-content [scrollY]="false">
  <ion-list lines="none">
    <ion-item button detail="false" (click)="pickOption(AddContentPopoverOption.Gallery)">
      <ion-label>Galería</ion-label>
      <ion-icon slot="start" name="images-outline"></ion-icon>
    </ion-item>
    <ion-item button detail="false" (click)="pickOption(AddContentPopoverOption.File)">
      <ion-label>Archivo</ion-label>
      <ion-icon slot="start" name="document-outline"></ion-icon>
    </ion-item>
    <ion-item button detail="false" (click)="pickOption(AddContentPopoverOption.Location)">
      <ion-label>Ubicación</ion-label>
      <ion-icon slot="start" name="location-outline"></ion-icon>
    </ion-item>
    <ion-item button detail="false" (click)="pickOption(AddContentPopoverOption.Contact)">
      <ion-label>Contacto</ion-label>
      <ion-icon slot="start" name="id-card-outline"></ion-icon>
    </ion-item>
  </ion-list>
</ion-content>