<ion-fab #fab edge
  (click)="!hubInfoShown ? openInfoHub() : null" >
  <ion-fab-button 
    #fabOpenButton
    [color]="light ? 'light' : 'dark'" 
    size="small" 
    (click)="hubInfoShown ? openHub() : null" 
    [activated]="buttonActivated"
    [ngClass]="{'disabled': !hubInfoShown}">
    <ion-icon id="" name="add"></ion-icon>
  </ion-fab-button>

  <div 
    class="fabtop fab-list-active" 
    [ngClass]="{'displayed': buttonActivated}">

    <ion-button 
      class="fab-button"
      fill="clear" 
      [ngClass]="{'displayed': buttonActivated}" 
      (click)="createPremiere()">
      <ion-icon class="ticket" src="assets/svg-icon/ticket3.svg"></ion-icon>
    </ion-button>

    <ion-button 
      class="fab-button"
      fill="clear"
      [ngClass]="{'displayed': buttonActivated}"
      (click)="createLiveAudio()">
      <ion-icon class="micro" src="assets/svg-icon/micro2.svg"></ion-icon>
    </ion-button>

    <ion-button 
      class="fab-button"
      fill="clear"
      [ngClass]="{'displayed': buttonActivated}"
      (click)="uploadContent()">
      <ion-icon class="upload" src="assets/svg-icon/upload2.svg"></ion-icon>
    </ion-button>

    <ion-button 
      class="fab-button"
      fill="clear" 
      [ngClass]="{'displayed': buttonActivated}" 
      (click)="createLiveVideo()">
      <ion-icon class="camera" src="assets/svg-icon/camera3.svg"></ion-icon>
    </ion-button>

    <ion-button 
      class="fab-button"
      fill="clear" 
      [ngClass]="{'displayed': buttonActivated}" 
      (click)="createEvent()">
      <ion-icon class="calendar" src="assets/svg-icon/calendar2.svg"></ion-icon>
    </ion-button>

  </div>
</ion-fab>

<div class="disable" 
  *ngIf="buttonActivated" 
  @inOutFade 
  (click)="toggleButton()">
</div>
