import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { inOutStarAnimation1, starAnimation1 } from '../../animations/animations';

@Component({
  selector: 'app-heart-like',
  templateUrl: './heart-like.component.html',
  styleUrls: ['./heart-like.component.scss'],
  animations: [
    inOutStarAnimation1,
    starAnimation1
  ]
})
export class HeartLikeComponent  implements OnInit {

  @Output() animationDone: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  animateHeart: boolean = false;
  hideHeart: boolean = false;

  constructor() { 
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.animateHeart = true;
  }

  done() {
    setTimeout(() => {
      this.hideHeart = true;

      setTimeout(() => {
        this.animateHeart = false;
        this.animationDone.emit(true);
      }, 600)
    }, 1000)
    
  }
}
