import { Component, Input, Output, OnInit, OnDestroy, ViewChild, SimpleChanges, EventEmitter } from '@angular/core';
import { IonRange } from '@ionic/angular';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss'],
})
export class AudioPlayerComponent implements OnInit, OnDestroy {

  @Input() media: any = undefined;
  src;

  @Output() audioLoaded = new EventEmitter();
  @Output() countViewer = new EventEmitter();

  /**
   * Control del rango para avanazr en el fichero de audio
   */
  @ViewChild('range', {static: false}) range: IonRange;

  /**
   * Variables para el control del reproductor de audio
   */
  audioPlayer = null;
  audioDuration = undefined;
  audioProgress = null;
  audioTimer = null;
  audioIsPaused = true;

  playbackRate = 1;
  secondsViewed = 0;
  countSecondsInterval;
  viewerCounted = false;


  constructor() {
  }

  ngOnInit() {
    console.log('this.media', this.media);
    const audioPlayer = new Audio(this.media);
    audioPlayer.onloadedmetadata = (data) => {
      const time = audioPlayer.duration;
      const format = this.ft(time);
      this.audioLoaded.emit(format);
    };
  }

  ft(seconds) {
    if(seconds === Infinity){ return '00:00'; }
    let minutes;
    minutes = Math.floor(seconds / 60);
    minutes = (minutes >= 10) ? minutes : '0' + minutes;
    seconds = Math.floor(seconds % 60);
    seconds = (seconds >= 10) ? seconds : '0' + seconds;
    return minutes + ':' + seconds;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.media.firstChange) {
      this.media = this.media.changingThisBreaksApplicationSecurity ? this.media.changingThisBreaksApplicationSecurity : this.media;
      this.audioPlayer = null;
      this.audioDuration = undefined;
      this.audioProgress = null;
      this.audioTimer = null;
      this.audioIsPaused = true;
      this.playbackRate = 1;
    }

  }

  ngOnDestroy() {
    this.destroyAudio();
  }

  /**
   * Play o Pausa del audio
   */
  playPlauseAudio() {
    if (this.media !== undefined) {
      this.media = this.media.changingThisBreaksApplicationSecurity ? this.media.changingThisBreaksApplicationSecurity : this.media;
      if (!this.audioPlayer) {
        // create and play new track
        this.audioIsPaused = false;
        this.audioPlayer = new Audio(this.media);
        // this.audioPlayer = new Audio('https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3');
        this.audioPlayer.playbackRate = this.playbackRate;

        this.audioPlayer.play().then(() => {
          this.audioDuration = this.audioPlayer.duration;
          this.onPlaying();

          this.audioTimer = setInterval(() => {
            this.audioProgress = this.audioPlayer.currentTime;
            this.audioPlayer.onended = () => {
              this.destroyAudio();
            };
          }, 100);
        });
      } else if (this.audioIsPaused) {
        this.audioPlayer.play();
        this.onPlaying();
        this.audioIsPaused = false;
      } else if (!this.audioIsPaused) {
        this.audioPlayer.pause();
        this.onPause();
        this.audioIsPaused = true;
      }
    }
  }


  /**
   * Avanza en el fichero
   * @param {any} event
   */
  seek(event: any) {
    if (this.audioPlayer) {
      try {
        let seekTo = event.target.value;

        this.audioPlayer.currentTime = seekTo;

        if (this.audioIsPaused == true) {
          this.audioPlayer.play();
          this.onPlaying();
          this.audioIsPaused = false;
        }
      } catch (e) {
      }
    }
  }

  /**
   * Mientra se esta avanzando en el fichero de audio, se pausa
   * la reproduccion
   */
  pauseWhileSeeking() {
    if (this.audioPlayer) {
      this.audioIsPaused = true;
      this.audioPlayer.pause();
      this.onPause();
    }
  }

  /**
   * Devuelve el tiempo de reproduccion en formato mm:ss
   * @param {number} seconds
   * @return {string}
   */
  getAudioProgress(seconds: number) {
    if (seconds >= 0) {
      return new Date(seconds * 1000).toISOString().substr(14, 5);
    }
  }

  /**
   * Alterna entre las diferentes velocidades de reproduccion
   */
  changePlaybackRate() {
    let rate;

    switch (this.playbackRate) {
      case 0.5:
        rate = 1;
        break;
      case 1:
        rate = 1.5;
        break;
      case 1.5:
        rate = 2;
        break;
      case 2:
        rate = 0.5;
        break;
    }

    this.audioPlayer.playbackRate = rate;
    this.playbackRate = rate;
  }

  /**
   * Libera el reproductor
   */
  destroyAudio() {
    if (this.audioPlayer && !this.audioIsPaused) this.audioPlayer.pause();
    if (this.audioTimer) clearInterval(this.audioTimer);
    if (this.range) this.range.value = 0;
    this.audioPlayer = null;
    this.audioDuration = undefined;
    this.audioProgress = null;
    this.audioTimer = null;
    this.audioIsPaused = true;
    this.onPause();
  }

  onPlaying() {
    if (this.countSecondsInterval || this.viewerCounted) {
      return;
    }
    this.countSecondsInterval = setInterval(() => {
      this.secondsViewed += 1;
      if (this.secondsViewed > 4) {
        this.countViewer.emit();
        this.viewerCounted = true;
        this.onPause();
      }
    }, 1000);
  }

  private onPause() {
    clearInterval(this.countSecondsInterval);
    this.countSecondsInterval = null;
  }
}
