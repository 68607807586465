import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { MessagePopoverOption } from '../../../enums/message-popover-option';

@Component({
  selector: 'app-message-popover',
  templateUrl: './message-popover.component.html',
  styleUrls: ['./message-popover.component.scss'],
})
export class MessagePopoverComponent implements OnInit {
  @Input() like;
  @Input() copyable;
  @Input() downloadable;
  @Input() isForDeleted = false;
  @Input() canResponse = true;

  MessagePopoverOption = MessagePopoverOption;

  constructor(private popoverCtrl: PopoverController) {
  }

  ngOnInit() {
  }

  pickOption(option: MessagePopoverOption) {
    this.popoverCtrl.dismiss(option);
  }
}
