<ion-header class="general-header-app">
  <!-- <ion-toolbar color="white">
    <ion-buttons slot="start" >
      <ion-button (click)="goBack()" >
        <ion-icon slot="icon-only" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-center">
      <ion-searchbar showCancelButton="never"
      placeholder="Buscar"
      debounce="800"
      (ionChange)="search($event)">
    </ion-searchbar>
    </ion-title>
  </ion-toolbar> -->

  <app-header
    [searchbar]="true"
    [hideRightButtons]="true"
    [menuButton]="false"
    [clearButton]="'never'"
    [defaultBackAction]="false"
    (search)="search($event)"
    (back)="goBack()">
  </app-header>
</ion-header>

<ion-content class="ion-padding">
  <div slot="fixed" class="sub-header">
    <div class="title">
      <h4 class="section-subtitle">Categorías</h4>
    </div>
  </div>

  <div class="categories-container">
    <app-category-button
      class="category-button"
      *ngFor="let cat of filteredCategories"
      [category]="cat"
      [active]="cat.selected"
      (click)="toggleCategory(cat)">
    </app-category-button>
  </div>
</ion-content>
