import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Notification } from '../../interfaces/notification';
import { MediaService } from '../../services/media.service';
import { Router } from '@angular/router';
import { Collaborator } from '../../interfaces/collaborator';
import { EventCollaborator } from '../../interfaces/eventCollaborator';
import { ProfileService } from '../../services/profile.service';
import { AlertController } from '@ionic/angular';
import { User } from '../../interfaces/user';
import { Events } from '../../services/events.service';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { NotificationService } from '../../services/notification.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {goToUserProfile} from "../../utils/routing";

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
})
export class NotificationItemComponent implements OnInit {

  @Input() notification: Notification;
  @Input() skeleton: boolean = false;
  
  collaboration: Collaborator | EventCollaborator = null;
  collaborationRemove = false;
  collaborationIsEvent = false;
  following;
  extraData: any[];
  imgSrc = '';

  notificationContent: SafeHtml;

  constructor(private mediaService: MediaService, private router: Router, private profileService: ProfileService,
              private alertCtrl: AlertController, private events: Events, private changeDetector: ChangeDetectorRef,
              private authService: AuthService, private notificationService: NotificationService,
              private sanitized: DomSanitizer) {
  }

  ngOnInit() {
    if ( !this.skeleton ) {
      // Replace star emoji or star text with star icon
      this.notificationContent = this.sanitized.bypassSecurityTrustHtml(
        this.notification.content
          .replace('🌟', '<img handleError class="star" src="/assets/star-l.png"/>')
          .replace('estrellas', '<img handleError class="star" src="/assets/star-l.png"/>')
      );

      this.extraData = this.notificationService.notificationsData.value;
      this.notificationService.notificationsData.subscribe(response => {
        this.extraData = response;
      });
      const data = this.extraData.find(i => i.id === this.notification.id);

      if (data) {
        this.following = data.isFollowing;
        this.collaboration = data.collaboration;
      }

      if ( this.notification.id === -1 ) {
        this.following = this.notification.sender.following;
      }

      this.events.subscribe(environment.EVENTS.COLLABORATION_ANSWERED, res => {
        this.manageCollaborationAnswer(res);
      });
      this.getPublicationThumbnail();
      if (this.notification.content.includes('Te ha eliminado')) {
        this.collaborationRemove = true;
      } else {
        if (this.notification.extraElementType === 'collaborator') {
          this.collaborationIsEvent = false;

        } else if (this.notification.extraElementType === 'eventCollaborator') {
          this.collaborationIsEvent = true;
        }
      }


      if(this.collaboration === null && this.notification.collaborator){
        this.collaboration = this.notification.collaborator.collaborator;
      }
    }
  }

  getNotificationImg(noti: Notification) {
    const sender = noti.sender;
    const senderId = sender ? sender.id : null;
    const senderImg = sender ? sender.imgProfile : null;
    return this.mediaService.generateImgProfileURL(senderId, senderImg);
  }

  isImageRounded(noti: Notification) {
    // return noti.extraElementType === 'follower';
    return true;
  }

  manageNotificationRedirection(isFromImage = false) {
    if ( this.notification.id > 0 ) {

      if ( this.notification.extraElementPublicationType === 'clip' ) {
        this.router.navigate([`/publications/clip/${this.notification.extraElementId}`]);
      } else {
        switch (this.notification.extraElementType) {
          case 'follower':
            this.router.navigate([goToUserProfile(this.router.url, this.notification.sender.username)]);
            break;
          case 'subscription':
            this.router.navigate([goToUserProfile(this.router.url, this.notification.sender.username)]);
            break;
          case 'purchase':
            if (!isFromImage) {
              this.router.navigate([`/payment/my-wallet`]);
            } else {
              this.router.navigate([`/publications/pre-one/${this.notification.extraElementId}`]);
            }
            break;
          case 'event':
            this.router.navigate([`/publications/pre-event/${this.notification.extraElementId}`]);
            break;
          case 'premiere':
            this.router.navigate([`/publications/pre-event/${this.notification.extraElementId}`]);
            break;
          case 'live':
          case 'liveLike':
          case 'publicationLike':
            this.router.navigate([`/publications/view/${this.notification.extraElementId}`]);
            break;
          case 'donation':
            this.router.navigate([`/payment/my-wallet`]);
            break;
          case 'eventCollaborator':
            this.router.navigate([`/publications/pre-event/${this.notification.extraElementId}`]);
            break;
          case 'collaborator':
            this.router.navigate([goToUserProfile(this.router.url, this.notification.sender.username)]);
            break;
          case 'clip':
          case 'clipLike':
            this.router.navigate([`/publications/clip/${this.notification.extraElementId}`]);
            break;
          default:
            break;
        }
      }

      
    } else {
      switch ( this.notification.id ) {
      case -1: 
        this.router.navigate([`/invitations`]);
        break;
      }
    }
  }

  acceptCollaboration() {
    this.answerCollaborationInvitation(true, this.collaboration.id, this.collaborationIsEvent);
  }

  async presentAlertCancelCollaboration() {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-alert-class',
      message: `¿Deseas rechazar la invitación?`,
      buttons: [
        {
          text: 'Rechazar',
          handler: () => {
            this.answerCollaborationInvitation(false, this.collaboration.id, this.collaborationIsEvent);
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel'
        },
      ]
    });

    await alert.present();
  }

  answerCollaborationInvitation(accept: boolean, collaborationId: number, isEvent: boolean) {
    this.profileService.answerCollabInvitation(accept, collaborationId, isEvent).subscribe(
      res => {
        if (res.done) {
          this.collaboration = res.data;
          const previousId = collaborationId;
          this.events.publish(environment.EVENTS.COLLABORATION_ANSWERED, {previousId, collaboration: res.data, accept, isEvent});

          console.log('answer', this.collaboration);
        }
      }, error => {
        this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error, intentalo de nuevo más tarde.');
      }
    );
  }


  private manageCollaborationAnswer(res: { previousId: number; collaboration: Collaborator | EventCollaborator; accept: boolean; isEvent: boolean }) {
    if (this.collaboration && this.collaboration.id === res.previousId && this.collaborationIsEvent === res.isEvent) {
      this.collaboration = res.collaboration;
      this.changeDetector.detectChanges();
    }
  }

  followButtonClick() {
    if (!this.following) {
      this.followUser()
    } else {
      this.presentAlertUnfollow();
    }
  }

  followUser() {
    this.following = true;

    this.profileService.follow(this.notification.sender.id, true).subscribe(
      (data) => {
        this.following = data.following;
        this.changeDetector.detectChanges();
      }, (error) => {
        this.following = !this.following;
        this.changeDetector.detectChanges();
      });
  }

  unfollowUser() {
    this.following = false;

    this.profileService.follow(this.notification.sender.id, false).subscribe(
      (data) => {
        this.following = data.following;
        this.changeDetector.detectChanges();
      }, (error) => {
        this.following = !this.following;
        this.changeDetector.detectChanges();
      });
  }

  async presentAlertUnfollow() {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-alert-class',
      header: 'Dejar de seguir',
      message: '¿Quieres dejar de seguir a este usuario?',
      buttons: [
        {
          text: 'Dejar de seguir',
          handler: () => {
            this.unfollowUser();
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel'
        }
      ]
    });

    await alert.present();
  }

  shouldShowThumbnail() {
    return this.notification.extraElementType === 'purchase' || 
      this.notification.extraElementType === 'event' || 
      this.notification.extraElementType === 'premiere' || 
      this.notification.extraElementType === 'live' || 
      this.notification.extraElementType === 'clip' || 
      this.notification.extraElementType === 'clipLike' ||
      this.notification.extraElementType === 'liveLike' ||
      this.notification.extraElementType === 'publicationLike';
  }

  getPublicationThumbnail() {
    if (!this.shouldShowThumbnail() || !this.extraData) {
      return;
    }

    const data = this.extraData.find(i => i.id === this.notification.id);

    if (data) {
      this.imgSrc = this.mediaService.generateNotificationPublicationThumbnailImgURL(data.image);
    }

    return this.imgSrc;
  }

}
