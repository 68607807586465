<div class="title" (click)="showInfo()">
  ¿Qué contenido quieres subir?
  <ion-icon src="assets/svg-icon/info.svg"></ion-icon>
</div>

<!-- Buttons -->
<div class="buttons">
  <ion-button color="light" (click)="choose(ContentType.Video)">
    <ion-icon src="assets/svg-icon/camera-svg-svf.svg"></ion-icon>
    <span>Vídeo</span>
  </ion-button>

  <ion-button color="light" (click)="choose(ContentType.Clip)">
    <ion-icon src="assets/svg-icon/file-video.svg"></ion-icon>
    <span>Clip</span>
  </ion-button>

  <!-- <ion-button color="light" (click)="choose(ContentType.Image)" disabled>
    <ion-icon src="assets/svg-icon/camera2.svg"></ion-icon>
    <span>Foto</span>
  </ion-button> -->

  <ion-button color="light" (click)="choose(ContentType.Audio)">
    <ion-icon src="assets/svg-icon/mic-svg-svf.svg"></ion-icon>
    <span>Podcast</span>
  </ion-button>

  <ion-button 
    fill="clear" 
    color="dark"
    class="go-back" 
    (click)="dismiss()">
    Cerrar
  </ion-button>
</div>
