import { Component, OnInit, OnDestroy, AfterViewInit, AfterViewChecked, Input, ViewChild, ElementRef, ChangeDetectorRef, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { MediaService } from '../../services/media.service';
import { PaymentService } from '../../services/payment.service';
import { Events } from '../../services/events.service';
import { UserService } from '../../services/user.service';
import { InAppPurchaseService } from '../../services/in-app-purchase.service';

import { environment } from '../../../environments/environment';
import { trigger, transition, animate, style } from '@angular/animations';

import { User } from '../../interfaces/user';
import {goToUserProfile} from "../../utils/routing";

export type Orientation = ('prev' | 'next' | 'none');

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss', '../user-display/user-display.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition('next => prev', // <--- Leaving <---
        [
          style({transform: 'translateX(0%)'}),
          animate('300ms ease', style({transform: 'translateX(-100%)'}))
        ]),
      transition('prev => next', // ---> Leaving --->
        [
          style({transform: 'translateX(0%)'}),
          animate('300ms ease', style({transform: 'translateX(+100%)'}))
        ]),
      transition('* => prev', // ---> Entering --->
        [
          style({transform: 'translateX(-100%)'}),
          animate('300ms ease', style({transform: 'translateX(0%)'}))
        ]),
      transition('prev => *', // ---> Leaving --->
        [
          style({transform: 'translateX(0%)'}),
          animate('300ms ease', style({transform: 'translateX(+100%)'}))
        ]),
      transition('* => next', // <--- Entering <---
        [
          style({transform: 'translateX(+100%)'}),
          animate('300ms ease', style({transform: 'translateX(0%)'}))
        ]),
      transition('next => *', // <--- Leaving <---
        [
          style({transform: 'translateX(0%)'}),
          animate('300ms ease', style({transform: 'translateX(-100%)'}))
        ]),
    ]),
  ]
})
export class SubscribeComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {

  @ViewChild('step') step: ElementRef;

  @Input() user: User = undefined;

  animationDirection: Orientation;
  slideStep = 0;
  stepsContainerHeight: number;

  viewInited = false;

  loading = false;

  stepsHeightTimeout = undefined;

  sub: any = {};

  constructor( 
    private router: Router,
    private mediaService: MediaService,
    private events: Events,
    private paymentService: PaymentService,
    private userService: UserService,
    private cdRef: ChangeDetectorRef,
    private iapService: InAppPurchaseService,
    private zone: NgZone
  ) {
    this.animationDirection = 'next';
  }

  ngOnInit() {
    // Subscribe to product purchased successfully
    this.sub.purchaseVerified = this.iapService.productVerified  // UNCOMMENT FOR VERIFIED TRANSACTION
    // this.sub.purchaseVerified = this.iapService.productApproved     // REMOVE FOR VERIFIED TRANSACTION
      .subscribe((receipt: CdvPurchase.VerifiedReceipt) => {
        this.user.subscribed = true;
        this.user.following = true;

        this.userService.userChanged(this.user);

        // // Call endpoint to register the subscription
        // this.paymentService.acceptSubscription(this.user.username).subscribe(
        //   res => {
        //     if (res.done) {
        //       this.user.subscribed = true;
        //       this.user.following = true;

        //       this.userService.userChanged(this.user);

        //       this.activateStep(1);
        //     } else {
        //       this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, res.msg);
        //     }

        //     this.loading = false;
        //     this.cdRef.detectChanges();
        //   }, err => {
        //     this.loading = false;
        //     this.cdRef.detectChanges();

        //     this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error, intentalo de nuevo más tarde.');
        //   }
        // );
      })

    // Subscribe to product purchase error
    this.sub.purchaseError = this.iapService.productError
      .subscribe(() => this.iapPurchaseError())

    // Subscribe to not supported
    this.sub.notSupported = this.iapService.notSupported
      .subscribe(() => {
        this.loading = false;
        this.cdRef.detectChanges();
      })
  }
  ngOnDestroy() {
    this.sub.purchaseVerified?.unsubscribe();
    this.sub.purchaseCancelled?.unsubscribe();
    this.sub.purchaseError?.unsubscribe();
    this.sub.purchaseUnverified?.unsubscribe();
    this.sub.notSupported?.unsubscribe();
    clearTimeout(this.stepsHeightTimeout);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.viewInited = true;
      this.cdRef.detectChanges();
    }, 400);
  }

  ngAfterViewChecked() {
    this.zone.runOutsideAngular(() => {
      clearTimeout(this.stepsHeightTimeout);

      this.stepsHeightTimeout = setTimeout(() => {
        const height = this.step?.nativeElement.getBoundingClientRect().height;

        if (height !== this.stepsContainerHeight) {
          this.stepsContainerHeight = height;
          this.cdRef.detectChanges();
        }
      }, 10)
    })
  }

  /**
   * Get profile image
   */
  getImgProfile() {
    return this.mediaService.generateImgProfileURL(this.user?.id, this.user?.imgProfile);
  }

  /**
   * Dismiss this modal
   */
  dismiss() {
    this.userService.closeSubscribe();
  }

  /**
   * Navigate through modal pages (steps)
   * @param {number} step Page to navigate
   */
  activateStep(step) {
    if (this.slideStep === step) {
      return;
    }

    // Check steps direction effect
    if (this.slideStep < step) {
      this.animationDirection = 'next';
      this.cdRef.detectChanges();
    } else {
      this.animationDirection = 'prev';
      this.cdRef.detectChanges();
    }

    // Store current step
    this.slideStep = step;
    this.cdRef.detectChanges();
  }

  /**
   * Subscribe to user logic
   */
  subscribeToUser() {
    this.loading = true;
    this.iapService.purchaseSubscription(this.user.id, this.user.subscriptionPrice);
  }

  /**
   * Navigate to user profile
   */
  goToProfile() {
    this.dismiss();
    this.router.navigate([goToUserProfile(this.router.url, this.user.username)]);
  }

  /**
   * Stop loading and trigger info message
   */
  iapPurchaseError() {
    this.loading = false;
    this.cdRef.detectChanges();

    this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error, intentalo de nuevo más tarde.');
  }
}
