<!-- User -->
<div class="user">
  <div class="avatar"
    *ngIf="mode === InviteMode.Reply">
    <img handleError 
      [src]="getImgProfile()"
      alt="profile-image">

    <ion-icon 
      class="icon"
      src="assets/svg-icon/invite.svg">  
    </ion-icon>
  </div>

  <div class="title" *ngIf="mode === InviteMode.Send">
    <ion-icon class="icon" src="assets/svg-icon/invite.svg"></ion-icon>
    <span>Invitar al directo</span>
  </div>

  <span class="username">
    <img handleError 
      class="badge special creator" 
      src="assets/badges/special/creator-1x.png"
      *ngIf="viewerType === ViewerType.Creator">
    <img handleError 
      class="badge special collaborator" 
      src="assets/badges/special/collaborator-1x.png"
      *ngIf="viewerType === ViewerType.Collaborator">
    <img handleError 
      class="badge special subscriber" 
      src="assets/badges/special/subscriber-1x.png"
      *ngIf="viewerType === ViewerType.Subscriber">
    @{{user?.username}}
  </span>
</div>

<!-- Message -->
<div class="message" *ngIf="mode === InviteMode.Reply">Te ha invitado a entrar en directo</div>

<!-- Buttons -->
<div class="buttons" *ngIf="mode === InviteMode.Send">
  <div class="primary">
    <ion-button 
      color="darkteal"
      (click)="sendInvite(InviteType.Audio)">
      <ion-icon 
        class="mic"
        src="assets/svg-icon/mic-svg-svf-clear.svg">  
      </ion-icon>
      Audio
    </ion-button>
    <ion-button 
      color="darkteal"
      (click)="sendInvite(InviteType.Video)">
      <ion-icon 
        class="cam"
        src="assets/svg-icon/camera-svg-svf-clear.svg">  
      </ion-icon>
      Vídeo
    </ion-button>
  </div>
  
  <ion-button 
    fill="clear"
    color="dark" 
    class="go-back" 
    (click)="dismiss()">
    Cancelar
  </ion-button>
</div>

<!-- Buttons -->
<div class="buttons" *ngIf="mode === InviteMode.Reply">
  <ion-button 
    *ngIf="type === InviteType.Audio"
    color="darkteal"
    class="accept"
    (click)="acceptInvite()">
    <ion-icon 
      class="cam"
      src="assets/svg-icon/mic-svg-svf-clear.svg">  
    </ion-icon>
    Entrar con Audio
  </ion-button>

  <ion-button 
    *ngIf="type === InviteType.Video"
    color="darkteal"
    class="accept"
    (click)="acceptInvite()">
    <ion-icon 
      class="cam"
      src="assets/svg-icon/camera-svg-svf-clear.svg">  
    </ion-icon>
    Entrar con Vídeo
  </ion-button>
  
  <ion-button 
    fill="clear"
    color="dark" 
    class="go-back" 
    (click)="dismiss()">
    Rechazar
  </ion-button>
</div>
