<div class="background" (click)="dismiss()"></div>

<div class="card">  
  <img class="background-image" src="assets/founder-card-1x1.png"/>

  <div class="close" (click)="dismiss()">
    <ion-icon name="close-outline"></ion-icon>
  </div>
    
  <!-- User -->
  <div class="user">
    <div class="user-image">
      <div class="avatar badge">
        <lazy-img
          [ngClass]="{'pro': user?.isPro}"
          [source]="profileImageUrl"
          [alt]="'profile-image'"
        >
        </lazy-img>
        <app-pro-tag *ngIf="user?.isPro" class="pro-tag"></app-pro-tag>
      </div>
    </div>
  </div>

  <div class="data">
    <div class="username">@{{user?.username}}</div>
    <div class="title">Usuario <span>Founder</span></div>
  </div>
</div>

<div class="download" *ngIf="itsMe">
  <div class="title">¡Compártelo!</div>
  <div class="buttons">
    <ion-button fill="clear" (click)="generateImage('verticalFounder')">
      <div class="rectangle"></div>
    </ion-button>
    <ion-button fill="clear" (click)="generateImage('squareFounder')">
      <div class="square"></div>
    </ion-button>
  </div>
</div>

<div class="disabler" 
  *ngIf="generating" 
  @inOutFade
  (click)="print = undefined">    
</div>
<div class="print" *ngIf="generating" @inOutFromBottom>
  <div class="generating" @inOutFade>
    Generando...
    <ion-spinner name="crescent"></ion-spinner>
  </div>
</div>
<!-- 

  <div class="card canvas"
    [ngClass]="{
      'square': print === 'square',
      'portrait': print === 'portrait'
    }
  ">  
    <img class="background-image" 
      *ngIf="print === 'square'"
      src="assets/founder-card-1x1-print.png"/>
    <img class="background-image" 
      *ngIf="print === 'portrait'"
      src="assets/founder-card-print.png"/>
      
    <div class="user">
      <div class="user-image">
        <div class="avatar badge">
          <lazy-img 
            [ngClass]="{'pro': user?.isPro}"
            [source]="profileImageUrl"
            [alt]="'profile-image'">
          </lazy-img>
          <app-pro-tag *ngIf="user?.isPro" class="pro-tag"></app-pro-tag>
        </div>
      </div>
    </div>

    <div class="data">
      <div class="username">@{{user?.username}}</div>
      <div class="title">Usuario <span>Founder</span></div>
    </div>
  </div>
</div> -->