import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { Platform, ModalController, NavController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core/components';
import { skipWhile, take } from 'rxjs/operators';

import { BaseComponent } from '../base.component';

import { PostInvitePage } from '../post-invite/post-invite.page';

import { AuthService } from '../../../services/auth.service';
import { Events } from '../../../services/events.service';
import { ContactPayloadCustom, ContactService } from '../../../services/contact.service';
import { AnalyticsService } from '../../../services/analytics.service';

import { User } from 'src/app/interfaces/user';

import * as Sentry from "@sentry/capacitor";

@Component({
  selector: 'app-invite',
  templateUrl: './invite.page.html',
  styleUrls: ['./invite.page.scss', '../base.component.scss', '../../../../theme/field.scss'],
})
export class InvitePage extends BaseComponent implements OnInit {

  @Input() username: string = '';

  contacts: ContactPayloadCustom[] = [];

  showModal: boolean = false;

  constructor(
    public platform: Platform,
    public modalCtrl: ModalController,
    public navCtrl: NavController,
    public cdRef: ChangeDetectorRef,
    public authService: AuthService,
    public events: Events,
    private contactService: ContactService,
    public analyticsService: AnalyticsService
  ) {
    super(platform, modalCtrl, navCtrl, cdRef, authService, events, analyticsService);
  }

  ngOnInit(): void {
    this.loading = true;

    this.authService.watchUser()
      .pipe(
        skipWhile(data => !data),
        take(1))
      .subscribe((user: User) => {
        this.loading = false;
      });
  }

  ionViewDidEnter() {
    this.analyticsService.setScreenName('register_invite');
  }

  async invite() {
    this.loading = true;
    const contactPermission = await this.contactService.getContactsWithPromptAndSave(false, true);

    if ( !contactPermission[0] ) {
      Sentry.captureMessage(`[REGISTER] user ${this.username} rejected contacts permission`, "info");
      this.goHome();
    } else {
      this.contacts = contactPermission[1];
      this.showModal = true;
    }
  }

  exitWithoutInvitation() {
    this.analyticsService.logEvent('register_skip_invitation');
    this.goHome();
  }

  onWillDismiss($event: Event) {
    const ev = $event as CustomEvent<OverlayEventDetail<string>>;

    if (ev.detail.role === 'confirm') {
      this.analyticsService.logEvent('register_user_invitation');
      this.goToPage(PostInvitePage, {}, true);
    } else {
      this.analyticsService.logEvent('register_user_invitation_cancelled');
      this.goHome();
    }
  }
}
