<!-- Title -->
<div class="title">
  Crea cualquier tipo de contenido desde el Hub
</div>

<!-- Info -->
<div class="info">
  <div class="item" (click)="triggerCreateContent(ContentType.Video)">
    <ion-icon class="icon upload" src="assets/svg-icon/upload2.svg"></ion-icon>

    <div class="text">
      <div class="intro">
        Subir Contenido
      </div>

      <div class="description">
        Sube tus vídeos, podcasts o fotos desde tu dispositivo y elige dónde guardarlo.
      </div>
    </div>
  </div>

  <div class="item" (click)="triggerCreateContent(ContentType.LiveVideo)">
    <ion-icon class="icon camera" src="assets/svg-icon/camera3.svg"></ion-icon>

    <div class="text">
      <div class="intro">
        Canales de Vídeo
      </div>

      <div class="description">
        Crea un canal de vídeo y comienza una emisión en directo.
      </div>
    </div>
  </div>

  <div class="item" (click)="triggerCreateContent(ContentType.LiveAudio)">
    <ion-icon class="icon micro" src="assets/svg-icon/micro2.svg"></ion-icon>

    <div class="text">
      <div class="intro">
        Salas de Audio
      </div>

      <div class="description">
        Crea una sala de audio y conecta con otros usuarios.
      </div>
    </div>
  </div>

  <div class="item" (click)="triggerCreateContent(ContentType.Event)">
    <ion-icon class="icon calendar" src="assets/svg-icon/calendar2.svg"></ion-icon>

    <div class="text">
      <div class="intro">
        Eventos
      </div>

      <div class="description">
        Programa un evento de vídeo o audio e invita a colaboradores que aporten valor.
      </div>
    </div>
  </div>

  <div class="item" (click)="triggerCreateContent(ContentType.Premiere)">
    <ion-icon class="icon ticket" src="assets/svg-icon/ticket3.svg"></ion-icon>

    <div class="text">
      <div class="intro">
        Estrenos <app-coming-soon-tag></app-coming-soon-tag>
      </div>

      <div class="description">
        Elige una fecha para convocar a tu audiencia al estreno de tu podcast, corto, videoclip o serie.
      </div>
    </div>
  </div>
</div>

<!-- Buttons -->
<div class="buttons">
  <ion-button 
    fill="clear" 
    color="dark"
    class="go-back" 
    (click)="dismiss()">
    Cerrar
  </ion-button>
</div>