import { Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-month',
  templateUrl: './month.component.html',
  styleUrls: ['./month.component.scss'],
})
export class MonthComponent  implements OnInit, OnChanges {

  @Input() moment: moment.Moment;
  @Input() startDate: moment.Moment;
  @Input() endDate: moment.Moment;

  @Output() onDaySelected: EventEmitter<moment.Moment> = new EventEmitter<moment.Moment>();

  currentDate: moment.Moment = moment();

  daysInMonth: number = 31;
  startOfMonth: number = 1;
  days: any[] = [];
  empty: any[] = [];
  monthName: string = '';

  constructor() { }

  ngOnInit() {
    this.moment.locale('es');
    this.daysInMonth = this.moment.daysInMonth();
    this.startOfMonth = this.moment.startOf('month').get('day');
    this.days = Array.from({length: this.daysInMonth}, Object);
    this.empty = new Array(!this.startOfMonth ? 0 : this.startOfMonth - 1);
    this.monthName = this.moment.format('MMMM');

    // Disable past days from past month
    if (this.moment.isBefore(this.currentDate, 'month')) {
      this.days.forEach((day) => {day.disabled = true})
    }

    // Disable past days from current month
    if (this.moment.isSame(moment(), 'month')) {
      this.days.forEach((day, index) => {
        this.currentDate.date() > moment().set('date', index + 1).date()
          ? day.disabled = true
          : day.disabled = false;
      })
    }

    this.checkDates();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.checkDates();
  }

  selectDay(day: number) {
    this.onDaySelected.emit(this.moment.clone().set('date', day));
  }

  checkDates() {
    if ( this.days.length > 0 ) {
      // Check start date
      if ( this.startDate ) {
        const curentStartDay = this.days.find((day) => day.start);
        if ( curentStartDay ) {
          curentStartDay.selected = false;
          curentStartDay.start = false;
        }

        if ( this.moment.isSame(this.startDate, 'month') && this.moment.isSame(this.startDate, 'year') ) {
          this.days[this.startDate.date() - 1].start = true;
          this.days[this.startDate.date() - 1].selected = true;
        } 
      } else if ( !this.startDate ) {
        const curentStartDay = this.days.find((day) => day.start);
        if ( curentStartDay ) {
          curentStartDay.selected = false;
          curentStartDay.start = false;
        }

        this.days.forEach((day) => day.between = false);
      }

      // Check end date
      if ( this.endDate ) {
        const curentEndDay = this.days.find((day) => day.end);
        if ( curentEndDay ) {
          curentEndDay.selected = false;
          curentEndDay.end = false;
        }

        if ( this.moment.isSame(this.endDate, 'month') && this.moment.isSame(this.endDate, 'year') ) {
          this.days[this.endDate.date() - 1].end = true;
          this.days[this.endDate.date() - 1].selected = true;
        }
      } else if ( !this.endDate ) {
        const curentEndDay = this.days.find((day) => day.end);
        if ( curentEndDay ) {
          curentEndDay.selected = false;
          curentEndDay.end = false;
        }

        this.days.forEach((day) => day.between = false);
      }

      // Check between dates
      if ( this.startDate && this.endDate ) {
        this.days.forEach((day, index) => {
          if ( this.moment.clone().set('date', index + 1).isBetween(this.startDate, this.endDate) ) {
            day.between = true;
          } else {
            day.between = false;
          }
        })
      }
    }
  }
}
