import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonFab, IonFabButton } from '@ionic/angular';
import { trigger, transition, animate, style } from '@angular/animations';

import { CreateContentService } from '../../services/create-content.service';
import { CloudflareService } from 'src/app/services/cloudflare.service';
import { SettingsService } from 'src/app/services/settings.service';
import { ProfileService } from 'src/app/services/profile.service';

import { ContentType } from '../../enums/content-type';

@Component({
  selector: 'app-createfab-button',
  templateUrl: './createfab-button.component.html',
  styleUrls: ['./createfab-button.component.scss'],
  animations: [
    trigger('inOutFade', [
      transition(':enter', [
        style({opacity: '0'}),
        animate('400ms', style({opacity: '1'}))
      ]),
      transition(':leave', [
        animate('400ms', style({opacity: '0'}))
      ])
    ])
  ]
})
export class CreatefabButtonComponent implements OnInit {

  @ViewChild('fab') fab: IonFab;
  @ViewChild('fabOpenButton') fabOpenButton: IonFabButton;

  @Input() light: boolean = false;

  buttonActivated = false;

  hubInfoShown = false;

  constructor(
    private createContentService: CreateContentService,
    private cloudflareService: CloudflareService,
    private settingsService: SettingsService,
    private profileService: ProfileService
  ) {}

  async ngOnInit() {
    this.hubInfoShown = await this.createContentService.hubInfoShown();
  }

  openHub() {
    this.buttonActivated = !this.buttonActivated;
  }

  /**
   * Open info hub and waits for user interact. If user does not choose any
   * option then open normal hub
   */
  async openInfoHub() {
    const result = await this.createContentService.openHubInfoModal();
    this.hubInfoShown = true;

    // If no option selected, open normal hub
    if ( result === undefined ) {
      // @ts-ignore
      this.fabOpenButton.el.click();
    }
  }

  toggleButton() {
    this.buttonActivated = !this.buttonActivated;
    !this.buttonActivated ? this.fab.close() : null;
  }

  /**
   * Open create static content or its info if not shown yet
   */
  async uploadContent() {
    this.buttonActivated = false;

    if ( !this.profileService.checkUserValidated() ) {
      return ;  // BREAK EXECUTION
    }

    if ( this.cloudflareService.uploading )
      this.createContentService.showUploadingAlert();
    else if ( await this.createContentService.staticContentInfoShown() )
      this.createContentService.openChooseContentTypeModal();
    else
      this.createContentService.openStaticContentInfoModal();
  }

  /**
   * Open create live video or its info if not shown yet
   */
  async createLiveVideo() {
    this.buttonActivated = false;
    
    if ( !this.profileService.checkUserValidated() ) {
      return ;  // BREAK EXECUTION
    }

    if ( this.cloudflareService.uploading )
      this.createContentService.showUploadingAlert();
    else if ( await this.createContentService.liveVideoInfoShown() )
      this.createContentService.openCreateLiveChannel(ContentType.LiveVideo);
    else
      this.createContentService.openLiveVideoInfoModal();
  }

  /**
   * Open create live audio or its info if not shown yet
   */
  async createLiveAudio() {
    this.buttonActivated = false;

    if ( !this.profileService.checkUserValidated() ) {
      return ;  // BREAK EXECUTION
    }

    if ( this.cloudflareService.uploading )
      this.createContentService.showUploadingAlert();
    else if ( await this.createContentService.liveAudioInfoShown() )
      this.createContentService.openCreateLiveChannel(ContentType.LiveAudio);
    else
      this.createContentService.openLiveAudioInfoModal();
  }

  /**
   * Open create event or its info if not shown yet
   */
  async createEvent() {
    this.buttonActivated = false;

    if ( !this.profileService.checkUserValidated() ) {
      return ;  // BREAK EXECUTION
    }

    if ( this.cloudflareService.uploading )
      this.createContentService.showUploadingAlert();
    else if ( await this.createContentService.eventInfoShown() )
      this.createContentService.openCreateEventPremiere(ContentType.Event);
    else
      this.createContentService.openEventInfoModal();
  }

  /**
   * Open create premiere or its info if not shown yet
   */
  async createPremiere() {
    this.buttonActivated = false;

    if ( !this.profileService.checkUserValidated() ) {
      return ;  // BREAK EXECUTION
    }

    this.settingsService.openFunctionNotAvailableModal('Los estrenos estarán disponibles en breve.');

    return; // TEMPORARY BREAK EXECUTION UNTIL PREMIERES READY

    if ( this.cloudflareService.uploading )
      this.createContentService.showUploadingAlert();
    else if ( await this.createContentService.premiereInfoShown() )
      this.createContentService.openCreateEventPremiere(ContentType.Premiere);
    else
      this.createContentService.openPremiereInfoModal();
  }
}
