<ion-header class="general-header-app">
  <app-header 
    [title]="type === ContentType.Event ? 'Configuración del Evento' : 'Configuración del Estreno'" 
    [menuButton]="false"
    [defaultBackAction]="false"
    (back)="goBack()">    
  </app-header>
</ion-header>

<ion-content #content>

  <!-- 
    -- Content 
    -->

  <!-- Event only fields -->
  <ng-container *ngIf="type === ContentType.Event">
    <!-- Chat -->
    <div class="field chat select">
      <div class="title">Chat</div>

      <div class="content">
        <ion-select
          mode="md"
          ok-text="Seleccionar"
          cancel-text="Cancelar"
          placeholder="Seleccionar una opción"
          [disabled]="uploading"
          [(ngModel)]="chat"
          (ionChange)="error.chat = false">
          <ion-select-option value="all">Público</ion-select-option>
          <ion-select-option value="followers">Solo seguidores</ion-select-option>
          <ion-select-option value="subscribers">Solo suscriptores</ion-select-option>
          <ion-select-option value="disabled">Desactivado</ion-select-option>
        </ion-select>

        <ion-text class="field-error" *ngIf="error.chat" color="danger">
          <small>
            <ion-icon name="alert-circle-outline"></ion-icon>
            <span>Debes seleccionar la participación en el chat</span>
          </small>
        </ion-text>
      </div>

      <div class="info" *ngIf="chat === 'all'">
        Todo el mundo podrá participar en el chat
      </div>
      <div class="info" *ngIf="chat === 'followers'">
        Solo tus seguidores podrán participar en el chat
      </div>
      <div class="info" *ngIf="chat === 'subscribers'">
        Solo tus suscriptores podrán participar en el chat
      </div>
      <div class="info" *ngIf="chat === 'disabled'">
        El chat estará deshabilitado
      </div>
    </div>

    <!-- Rise hand -->
    <div class="field hand select">
      <div class="title">Levantar la mano</div>

      <div class="content">
        <ion-select
          mode="md"
          ok-text="Seleccionar"
          cancel-text="Cancelar"
          placeholder="Seleccionar una opción"
          [disabled]="uploading"
          [(ngModel)]="hand"
          (ionChange)="error.hand = false">
          <ion-select-option value="all">Público</ion-select-option>
          <ion-select-option value="followers">Solo seguidores</ion-select-option>
          <ion-select-option value="subscribers">Solo suscriptores</ion-select-option>
          <ion-select-option value="disabled">Desactivado</ion-select-option>
        </ion-select>

        <ion-text class="field-error" *ngIf="error.hand" color="danger">
          <small>
            <ion-icon name="alert-circle-outline"></ion-icon>
            <span>Debes seleccionar quién puede levantar la mano</span>
          </small>
        </ion-text>
      </div>

      <!-- <div class="content video-hand" *ngIf="form.isVideo">
        <div class="with-title">
          <div class="select-title">Vídeo</div>

          <ion-select
            mode="md"
            ok-text="Seleccionar"
            cancel-text="Cancelar"
            placeholder="Seleccionar una opción"
            [disabled]="uploading"
            [(ngModel)]="videoHand"
            (ionChange)="error.videoHand = false">
            <ion-select-option value="all" *ngIf="privacy !== 'subscribers'">Público</ion-select-option>
            <ion-select-option value="followers" *ngIf="privacy !== 'subscribers'">Solo seguidores</ion-select-option>
            <ion-select-option value="subscribers">{{privacy === 'subscribers' ? 'Activado' : 'Solo suscriptores'}}</ion-select-option>
            <ion-select-option value="disabled">Desactivado</ion-select-option>
          </ion-select>
        </div>

        <ion-text class="field-error" *ngIf="error.videoHand" color="danger">
          <small>
            <ion-icon name="alert-circle-outline"></ion-icon>
            <span>Debes seleccionar quién puede levantar la mano con vídeo</span>
          </small>
        </ion-text>
      </div>
      <div class="content" *ngIf="form.isVideo">
        <div class="with-title">
          <div class="select-title">Audio</div>

          <ion-select
            mode="md"
            ok-text="Seleccionar"
            cancel-text="Cancelar"
            placeholder="Seleccionar una opción"
            [disabled]="uploading"
            [(ngModel)]="audioHand"
            (ionChange)="error.audioHand = false">
            <ion-select-option value="all" *ngIf="privacy !== 'subscribers'">Público</ion-select-option>
            <ion-select-option value="followers" *ngIf="privacy !== 'subscribers'">Solo seguidores</ion-select-option>
            <ion-select-option value="subscribers">{{privacy === 'subscribers' ? 'Activado' : 'Solo suscriptores'}}</ion-select-option>
            <ion-select-option value="disabled">Desactivado</ion-select-option>
          </ion-select>
        </div>

        <ion-text class="field-error" *ngIf="error.audioHand" color="danger">
          <small>
            <ion-icon name="alert-circle-outline"></ion-icon>
            <span>Debes seleccionar quién puede levantar la mano con audio</span>
          </small>
        </ion-text>
      </div> -->
    </div>
  </ng-container>

  <!-- Premiere only fields -->
  <ng-container *ngIf="type === ContentType.Premiere">

    <!-- Upload video -->
    <div class="field video" *ngIf="form.isVideo">
      <div class="title">Subir un archivo de vídeo <span class="file-types">(.mp4, .avi, .mov)</span></div>

      <div class="content">
        <div *ngIf="fileSrc">
          <app-video-player id="videoPlayer" [videoSrc]="fileSrc" [canMaximize]="false">
          </app-video-player>
          <ion-button fill="clear" class="reduced" (click)="chooseFile(ContentType.Video)">Cambiar</ion-button>
        </div>

        <ion-button fill="clear" (click)="chooseFile(ContentType.Video)" *ngIf="!fileSrc">
          <div class="container-icon-back-white">
            <span class="icon-movie"></span>
          </div>
        </ion-button>
      </div>

      <ion-text color="danger" class="field-error" *ngIf="error.file">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>Debes seleccionar un archivo</span>
        </small>
      </ion-text>
    </div>

    <!-- Upload podcast -->
    <div class="field podcast" *ngIf="!form.isVideo">
      <div class="title">Subir un archivo de audio <span class="file-types">(.mp3, .wav, .aac)</span></div>

      <div class="content">
        <div *ngIf="fileSrc">
          <app-podcast-player class="podcast-player" [media]="fileSrc">
          </app-podcast-player>
          <ion-button class="reduced" (click)="chooseFile(ContentType.Audio)">Cambiar</ion-button>
        </div>

        <ion-button fill="clear" (click)="chooseFile(ContentType.Audio)" *ngIf="!fileSrc">
          <div class="container-icon-back-white">
            <ion-icon name="musical-notes"></ion-icon>
          </div>
        </ion-button>
      </div>

      <ion-text color="danger" class="field-error" *ngIf="error.file">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>Debes seleccionar un archivo</span>
        </small>
      </ion-text>
    </div>
  </ng-container>

  <!-- Thumbnail -->
  <div class="field thumb">
    <div class="title">Imagen de portada</div>

    <div class="content" (click)="pickPoster()">
      <ion-thumbnail class="thumbnail" *ngIf="posterSrc">
        <img 
          *ngIf="!poster && posterSrc"
          handleError 
          thumbnail 
          [size]="600" 
          [source]="asString(posterSrc)" />
        <img 
          *ngIf="poster || !(!poster && posterSrc)"
          handleError 
          [src]="posterSrc" />
      </ion-thumbnail>

      <ion-button class="choose-file" fill="clear" *ngIf="posterSrc">
        <ion-icon src="assets/svg-icon/camera2-clear.svg"></ion-icon>
      </ion-button>
    
      <div class="choose-button" *ngIf="!posterSrc">
        <ion-icon src="assets/svg-icon/camera2-clear.svg" *ngIf="!posterSrc"></ion-icon>
      </div>
    </div>

    <ion-text class="field-error" *ngIf="error.poster" color="danger">
      <small>
        <ion-icon name="alert-circle-outline"></ion-icon>
        <span>Debes seleccionar una imagen de portada</span>
      </small>
    </ion-text>
  </div>

  <!-- Free -->
  <div class="field free selector">
    <div class="title">Precio <app-pro-tag></app-pro-tag><app-coming-soon-tag></app-coming-soon-tag></div>
    
    <div class="content disabled">
      <div class="segment" (click)="switchPrice()">
        <div class="select" [ngClass]="{'active': !isFree}"></div>

        <div class="button">
          Gratuito
        </div>
        <div class="button">
          De pago
        </div>
      </div>
    </div>
  </div>

  <!-- Price -->
  <div class="field price input" *ngIf="!isFree">
    <div class="title">Importe <ion-icon class="coin" src="assets/coin.svg"></ion-icon></div>

    <div class="content">
      <ion-input
        only-numeric
        min="0"
        required
        type="tel"
        placeholder="Introduce cantidad"
        [disabled]="uploading"
        [(ngModel)]="price"
        (ionFocus)="inputFocused($event)"
        (ionChange)="error.price = false">
      </ion-input>
      <div class="equals">
        Equivale a
        <span *ngIf="price !== null && price !== undefined">{{(price) | number:'1.0-0'}}</span>
        <span *ngIf="price === null || price === undefined">-</span>€
      </div>

      <ion-text class="field-error" *ngIf="price < CONTENT_MIN_PRICE" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>El importe debe ser mayor o igual que {{ CONTENT_MIN_PRICE }}</span>
        </small>
      </ion-text>
      <ion-text class="field-error" *ngIf="price > CONTENT_MAX_PRICE" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>El importe no puede ser superior a {{ CONTENT_MAX_PRICE }}</span>
        </small>
      </ion-text>
      <ion-text class="field-error" *ngIf="error.price && !(price < CONTENT_MIN_PRICE || price > CONTENT_MAX_PRICE)" color="danger">
        <small>
          <ion-icon name="alert-circle-outline"></ion-icon>
          <span>El precio es incorrecto</span>
        </small>
      </ion-text>
    </div>
  </div>

  <!-- Save -->
  <div class="field save select" *ngIf="user?.isPro">
    <div class="title">Guardar emisión <app-pro-tag></app-pro-tag></div>

    <div class="content">
      <ion-select
        mode="md"
        [disabled]="uploading"
        [(ngModel)]="save"
        ok-text="Seleccionar"
        cancel-text="Cancelar"
        placeholder="Seleccionar">
        <ion-select-option [value]="0">No</ion-select-option>
        <ion-select-option [value]="1">Si</ion-select-option>
      </ion-select>
    </div>
  </div>

  <!-- Preview -->
  <div class="field preview">
    <div class="title">
      Preview

      <ion-button 
        id="preview-info-modal"
        class="info-button"
        fill="clear">
        <ion-icon class="info-icon" name="information-circle-outline"></ion-icon>
      </ion-button>

      <ion-modal #modal trigger="preview-info-modal" class="fit-modal info-modal">
        <ng-template>
          <div class="inner">
            <ion-button class="close" fill="clear" (click)="modal.dismiss()">
              <ion-icon name="close-outline"></ion-icon>
            </ion-button>

            <div class="icon">
              <ion-icon name="information-circle-outline"></ion-icon>
            </div>

            <div class="message">
              La preview puede ser tanto un archivo de vídeo como de audio
            </div>
          </div>
        </ng-template>
      </ion-modal>
    </div>

    <div class="content">
      <ion-button class="upload-preview" fill="clear" (click)="pickPreview()" *ngIf="!previewSrc">
        <ion-icon name="eye-outline"></ion-icon>
      </ion-button>

      <div class="file" *ngIf="previewSrc">
        <app-video-player
          *ngIf="previewType === ContentType.Video"
          [videoSrc]="previewSrc"
          [canMaximize]="false">
        </app-video-player>
        <app-audio-player
          *ngIf="previewType === ContentType.Audio"
          [media]="previewSrc">
        </app-audio-player>
        <ion-button fill="clear" class="change-button" (click)="pickPreview()">
          <ion-icon name="repeat-outline"></ion-icon>
        </ion-button>
      </div>
    </div>
  </div>

  <ion-progress-bar *ngIf="uploading" [value]="uploadProgress"></ion-progress-bar>

  <!-- Submit -->
  <div class="submit-button">
    <ion-button
      fill="clear"
      [disabled]="uploading"
      (click)="submitForm()">
      {{mode === FormMode.Create ? type === ContentType.Event ? 'Crear Evento' : 'Programar Estreno' : 'Guardar'}}
      <ion-spinner color="white" *ngIf="uploading" name="crescent"></ion-spinner>
    </ion-button>
  </div>  

  <div class="keyboard-space" [ngStyle]="{'height': keyboardHeight + 'px'}"></div>

  <div class="ios-bottom-safe-area"></div>

</ion-content>
