<!-- User -->
<app-user-display [user]="user"></app-user-display>

<!-- Reputation -->
<div class="reputation">
  <img handleError [src]="getBadgeSrc()">
  <span class="number">
    {{user.reputation}}
  </span>
</div>

<div class="text t1">Nivel de experiencia <span class="level">{{experienceLevel}}</span></div>

<div class="text t2" *ngIf="itsMe && remainingPointsToNextLevel">Consigue {{remainingPointsToNextLevel}} puntos más para subir al siguiente nivel.</div>
<div class="text t2" *ngIf="itsMe && !remainingPointsToNextLevel">Has alcanzado el nivel de experiencia más alto.</div>

<!-- Buttons -->
<div class="buttons">
  <ion-button 
    fill="clear" 
    color="dark"
    class="go-back" 
    (click)="dismiss()">
    Cerrar
  </ion-button>
</div>