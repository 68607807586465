<ion-header class="general-header-app">
  <app-header
    [menuButton]="false"
    [defaultBackAction]="false"
    (back)="dismiss()"
    [searchbar]="true"
    (search)="search($event)">
  </app-header>
</ion-header>

<ion-content class="ion-padding-horizontal">
  <span class="no-data" *ngIf="noPermissionMessage">
    Para utilizar esta función necesitamos tu permiso para acceder a tus contactos.
  </span>

  <div class="loading-container" *ngIf="inloadingContacts">
    <ion-spinner color="primary" name="crescent"></ion-spinner>
  </div>

  <div class="contact-list" *ngIf="!inloadingContacts">

    <cdk-virtual-scroll-viewport 
      itemSize="47"
      minBufferPx="940"
      maxBufferPx="1410">
      <div class="contact" *cdkVirtualFor="let contact of contacts">
        <ion-label>
          {{contact.name}}
        </ion-label>
        <ion-button 
                    color="darkteal"
                    class="action-button"
                    slot="end"
                    (click)="sendContact(contact)">
          <span>Enviar</span>
        </ion-button>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
</ion-content>
