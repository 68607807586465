import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { ToastController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class TimeInterceptor implements HttpInterceptor {

  toastPresent: boolean = false;

  constructor(
    private toastController: ToastController
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // let longRequestTimeout;

    // if ( request.url.includes(environment.API_BASE_URL) ) {
    //   longRequestTimeout = setTimeout(() => {
    //     this.presentErrorToast('Parece que tu conexión es inestable.');
    //   }, 90000);
    // }

    return next.handle(request).pipe(
      tap((response: HttpResponse<any>): void => {
        // if ( response instanceof HttpResponse ) {
        //   clearTimeout(longRequestTimeout);
        // }
      }),
    );
  }

  async presentErrorToast(message: string) {
    if ( !this.toastPresent ) {
      this.toastPresent = true;
      
      const toast = await this.toastController.create({
        message: message,
        buttons: [{icon: 'close-circle', role: 'cancel'}],
        duration: 10000,
        position: 'top',
        color: "warning",
      });

      await toast.present();
      await toast.onDidDismiss();
      
      this.toastPresent = false;
    }
  }
}
