import { Component, OnInit, Input } from '@angular/core';

import { MediaService } from '../../services/media.service';
import { UserService } from '../../services/user.service';
import { ReputationService } from '../../services/reputation.service';
import { AuthService } from '../../services/auth.service';

import { User } from '../../interfaces/user';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss'],
})
export class ExperienceComponent implements OnInit {

  @Input() user: User = undefined;

  itsMe: boolean = false;

  experienceLevel: number;
  remainingPointsToNextLevel: number | undefined;

  constructor( 
    private mediaService: MediaService, 
    private userService: UserService,
    private reputationService: ReputationService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.experienceLevel = this.reputationService.getExperienceLevel(this.user);
    this.remainingPointsToNextLevel = this.reputationService.getRemainingPointsToNextLevel(this.user);

    this.authService.getUserDetails().then((user: User) => {
      this.itsMe = user.id === this.user.id;
    })
  }

  getImgProfile() {
    return this.mediaService.generateImgProfileURL(this.user?.id, this.user?.imgProfile);
  }

  getBadgeSrc() {
    return this.mediaService.getBadgeSrc(this.user);
  }

  dismiss() {
    this.userService.closeCollaborate();
  }
}
