import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Publication } from '../../../interfaces/publication';
import { MediaService } from '../../../services/media.service';
import { Router } from '@angular/router';
import { AlertController, PopoverController } from '@ionic/angular';
import { environment } from '../../../../environments/environment';
import { PaymentService } from '../../../services/payment.service';
import { Events } from '../../../services/events.service';
import { User } from '../../../interfaces/user';
import { AuthService } from '../../../services/auth.service';
import { ProfileService } from '../../../services/profile.service';
import { BlockPopoverComponent } from '../../popovers/block-popover/block-popover.component';
import { BlockPopoverOption } from '../../../enums/block-popover-option';
import { UserService } from '../../../services/user.service';
import { RouterDataService } from '../../../services/router-data.service';
import {goToUserProfile} from "../../../utils/routing";
import { ContentType } from '../../../enums/content-type';

@Component({
  selector: 'app-record-audio-thumbnail',
  templateUrl: './record-audio-thumbnail.component.html',
  styleUrls: ['./record-audio-thumbnail.component.scss'],
})
export class RecordAudioThumbnailComponent implements OnInit, OnDestroy {

  @Input() publication: Publication;
  @Input() imageWidth = '100%';
  @Input() profileView = false;
  @Input() expanded = false;
  @Input() locked = null;
  @Input() userImage = true;
  @Input() showCreatedAt = false;
  @Input() skeleton = false;
  @Input() user: User;
  @Input() showDots = false;

  sub: any = {};

  mediaServ: MediaService;

  constructor(private mediaService: MediaService, private router: Router, private alertCtrl: AlertController,
              private paymentService: PaymentService, private events: Events, private authService: AuthService,
              private profileService: ProfileService, private popoverController: PopoverController,
              private alertController: AlertController, private userService: UserService,
              private routerDataService: RouterDataService) {
    this.mediaServ = this.mediaService;
  }

  ngOnInit() {
    if (!this.user && !this.skeleton) {
      this.user = this.authService.user;
    }

    this.sub.userChanged = this.userService.userStatusChanged.subscribe((user: User) => {
      if ( user.id === this.publication.author.id ) {
        this.publication.author = user;
      }
    });
  }
  ngOnDestroy() {
    this.sub.userChanged.unsubscribe();
  }

  lastEmissionDuration() {
    if (!this.publication.emitStartDate || !this.publication.emitEndDate) {
      return null;
    }
    const diffDates = ((new Date(this.publication.emitEndDate).getTime() - new Date(this.publication.emitStartDate).getTime()) /
      1000) / 60;

    return Math.abs(Math.round(diffDates)); // minutes
  }

  showLocker() {
    return this.locked !== null && this.publication.isPremium;
  }

  async enterPublication() {
    if (this.publication.isPremium && !(this.publication.collaborating || this.publication.subscribed || this.publication.author.id === this.user?.id)) {
      this.userService.subscribeToUser(this.publication.author);
    } else {
      this.routerDataService.setData(this.publication);
      this.router.navigate([`/publications/view/${this.publication.id}`]);
    }
  }

  getRouteUserProfile(username: string) {
    return goToUserProfile(this.router.url, username);
  }

  async presentBlockPopover(e) {
    e.preventDefault();
    e.stopPropagation();
    const popover = await this.popoverController.create({
      component: BlockPopoverComponent,
      componentProps: {
        blocked: false,
        itsMe: false,
        canEmit: false
      },
      event: e,
      animated: true,
      mode: 'ios',

    });

    await popover.present();

    const {data} = await popover.onDidDismiss();

    switch (data) {
      case BlockPopoverOption.Block:
        this.presentAlertBlockContent();
        break;
      case BlockPopoverOption.Report:
        this.presentAlertReportContent();
        break;
    }

    return false;
  }

  async presentAlertBlockContent() {

    this.profileService.blockPublication(ContentType.Audio, this.publication.id).subscribe(
      data => {
        if (data.msg === 'Ok') {
          this.events.publish(environment.EVENTS.ALERT_TOAST_NOTICE, 'No volverás a ver este contenido');
        } else {
          this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error, vuelve a intentarlo');
        }
      }, error => {
        this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error, vuelve a intentarlo');
      });
  }

  async presentAlertReportContent() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-alert-class',
      header: 'Denunciar contenido',
      message: '¿Estás seguro de que quieres denunciar este contenido?',
      buttons: [
        {
          text: 'Denunciar',
          cssClass: 'alert-button-danger',
          handler: () => {
            this.userService.report(this.publication.id, ContentType.Audio, true, false)
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'alert-button-dark'
        }
      ]
    });

    await alert.present();
  }
}
