import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Participant, Track } from 'livekit-client';
import { UserStream, UserStreamMetadata } from 'src/app/interfaces/stream-user';
import { User } from 'src/app/interfaces/user';
import { Events } from 'src/app/services/events.service';
import { MediaService } from 'src/app/services/media.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-stream-icon',
    templateUrl: './participant-icon.component.html',
    styleUrls: ['./participant-icon.component.scss'],
  })
export class ParticipantIconComponent implements OnInit, OnDestroy {
  @Input() user: UserStream;
  @Output() showProfileEvent: EventEmitter<User> = new EventEmitter();
  @Input() showStreams = false;
  @Input() isCenter = false;
  @Input() onlyAudio = true;
  @Input() iconMuted = false;


  userInfo: User;
  userImage?: string;

  constructor(
    private mediaServ: MediaService,
  ){
  }

  ngOnInit(): void {
    this.userImage = this.user.info.imgProfile;

    // If the user has a metadata with a imgProfile, return it
    if(this.userImage === "assets/no-photo.jpg"){
      if(this.user.participant && this.user.participant.metadata){
        const metadata = JSON.parse(this.user.participant.metadata);
        this.userImage = this.mediaServ.generateImgProfileURL(metadata.user.id, metadata.user.imgProfile);
      }
    }


  }

  ngOnDestroy(): void {
  }


  /**
   * User name
   * @description This method is used to get the username of the participant
   * @returns
   */
  get userName(){

    // If the user has a metadata with a username, return it
    if(this.user.participant.metadata){
      const metadata: UserStreamMetadata = JSON.parse(this.user.participant.metadata);
      if(metadata.username){
        return metadata.username;
      }
    } else {
      //TODO: Get the username from livekit
    }

    // If the user not have a metadata with a username, return the identity
    return this.user.participant.identity;
  }


  /**
   * * This method its in used for (track changes for elements in the iterable)
   *
   * @param peer Participant in the stream
   * @returns
   */
  trackTrack(index, peer) {
    return peer.sid;
  }
  showProfile() {
    if(this.user.local || !this.user.user){ return; }
    this.showProfileEvent.emit(this.user.user);
  }



  /**
   * This mode is used to check if the participant has a microphone in the stream
   *
   * @param peer Participant in the stream
   * @returns true | false
   */
  haveMic(peer: Participant): boolean{
    if(peer.tracks.size === 0){
      return false;
    }

    for (const [track, publication] of peer.tracks) {
      if (typeof publication.track  === 'undefined'){ continue; }
      if(publication.kind === 'audio' && !publication.track.isMuted){
        return true;
      }
    }

    return false;
  }



}
