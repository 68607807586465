<ion-header class="general-header-app">
  <app-header 
    [title]="'Invitar contactos'" 
    [menuButton]="false" 
    [backButton]="true"
    [defaultBackAction]="false"
    (back)="dismiss()">
  </app-header>
</ion-header>

<ion-content [scrollY]="false">
  <div cdkVirtualScrollingElement 
    #container
    class="content">

    <cdk-virtual-scroll-viewport 
      class="contacts"
      itemSize="47"
      minBufferPx="940"
      maxBufferPx="1410"
    >
      <div class="element" *cdkVirtualFor="let contact of contacts">
        <div class="data">
          <span class="user">
            {{contact.name}}
          </span>
        </div>

        <div class="buttons">
          <ion-button 
            *ngIf="!contact.invited"
            color="darkteal"
            (click)="createInvitation(contact)"
          >
            Invitar
          </ion-button>
          <ion-button 
            *ngIf="contact.invited"
            color="light"
            disabled
          >
            Invitado
          </ion-button>
        </div>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
</ion-content>