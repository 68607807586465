import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PipesModule} from '../pipes/pipes.module';
import {InFooterComponent} from './in-footer/in-footer.component';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {ScrollTopButtonComponent} from './scroll-top-button/scroll-top-button.component';
import {ShowHidePasswordComponent} from './show-hide-password/show-hide-password.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PremiumMessageComponent} from './premium-message/premium-message.component';
import {CategoriesButtonsComponent} from './categories-buttons/categories-buttons.component';
import {FormInputComponent} from './form-input/form-input.component';
import {ModalBaseComponent} from './modal-base/modal-base.component';
import {HeaderBackComponent} from './header-back/header-back.component';
import {ChatMessageComponent} from './chat-message/chat-message.component';
import {PrivateMessageComponent} from './private-message/private-message.component';
import {SwiperModule} from 'swiper/angular';
import {MyWalletTransactionEntryComponent} from './my-wallet-transaction-entry/my-wallet-transaction-entry.component';
import {NotificationItemComponent} from './notification-item/notification-item.component';
import {ChatWithUserContentComponent} from './messages/chat-with-user-content/chat-with-user-content.component';
import {ChatListContentComponent} from './messages/chat-list-content/chat-list-content.component';
import {CategoryButtonModule} from './category-button/category-button.module';
import {SearchContactItemComponent} from './search-contact-item/search-contact-item.component';
import {SearchVideoItemComponent} from './search-video-item/search-video-item.component';
import {KFormErrorComponent} from './k-form-error/k-form-error.component';
import {ProfileHeaderComponent} from './profile-header/profile-header.component';
import {MessagePopoverComponent} from './messages/message-popover/message-popover.component';
import {BlockPopoverComponent} from './popovers/block-popover/block-popover.component';
import {AddContentPopoverComponent} from './popovers/add-content-popover/add-content-popover.component';
import {RaiseHandPopoverComponent} from './popovers/raise-hand-popover/raise-hand-popover.component';
import {SearchbarComponent} from './searchbar/searchbar.component';
import {HeaderComponent} from './header/header.component';
import {AudioPlayerComponent} from './audio-player/audio-player.component';
import {PodcastPlayerComponent} from './podcast-player/podcast-player.component';
import {VideoPlayerComponent} from './video-player/video-player.component';
import {ReportComponent} from './report/report.component';
import {SendStarsComponent} from './send-stars/send-stars.component';
import {UserListComponent} from './user-list/user-list.component';
import {DirectivesModule} from '../directives/directives.module';
import {ProfileOptionsPopoverComponent} from './popovers/profile-options-popover/profile-options-popover.component';
import {HandsListComponent} from './hands-list/hands-list.component';
import {ImageZoomComponent} from './image-zoom/image-zoom.component';
import {ProfileAvatarZoomComponent} from './profile-avatar-zoom/profile-avatar-zoom.component';
import {ProgressCircleComponent} from './progress-circle/progress-circle.component';
import {SubscribeComponent} from './subscribe/subscribe.component';
import {CollaborateComponent} from './collaborate/collaborate.component';
import {IncomeInfoComponent} from './income-info/income-info.component';
import {WithdrawInfoComponent} from './withdraw-info/withdraw-info.component';
import {ProAlertComponent} from './pro-alert/pro-alert.component';
import {PinganilloComponent} from './pinganillo/pinganillo.component';
import {InvitacionDirectoComponent} from './invitacion-directo/invitacion-directo.component';
import {ChooseContentTypeComponent} from './choose-content-type/choose-content-type.component';
import {PhoneCheckComponent} from './phone-check/phone-check.component';
import {ExperienceComponent} from './experience/experience.component';
import {FounderComponent} from './founder/founder.component';
import {VersionBlockerComponent} from './version-blocker/version-blocker.component';
import {PingComponent} from './ping/ping.component';
import {InviteComponent} from './invite/invite.component';
import {LazyImgModule} from './lazy-img/lazy-img.module';
import {WelcomeComponent} from './welcome/welcome.component';
import {StaticContentInfoComponent} from './content-info/static-content-info/static-content-info.component';
import {LiveVideoInfoComponent} from './content-info/live-video-info/live-video-info.component';
import {LiveAudioInfoComponent} from './content-info/live-audio-info/live-audio-info.component';
import {EventInfoComponent} from './content-info/event-info/event-info.component';
import {PremiereInfoComponent} from './content-info/premiere-info/premiere-info.component';
import { LiveKitPreviaVideoComponent } from './livekit-previa-video/livekit-previa-video.component';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { VideoPreviewComponent } from './video-preview/video-preview.component';
import { UserDisplayComponent } from './user-display/user-display.component';
import { SelectComponent } from './select/select.component';
import { LivekitLiveStreamComponent } from './livekit-live-stream/livekit-live-stream.component';
import { LiveKitStreamComponent } from './livekit-live-stream/video-stream/video-stream.component';
import { ParticipantIconComponent } from './livekit-live-stream/participant-icon/participant-icon.component';
import {LineClampComponent} from './line-clamp/line-clamp.component';
import { CreateReviewComponent } from './create-review/create-review.component';
import { ValorationStarModule } from './valoration-star/valoration-star.module';
import { StarCounterComponent } from './star-counter/star-counter.component';
import { LikeCounterComponent } from './like-counter/like-counter.component';
import { StarLikeComponent } from './star-like/star-like.component';
import { HeartLikeComponent } from './heart-like/heart-like.component';
import {UserRecommendationModule} from './user-recommendation-thumbnail/user-recommendation.module';
import {ProTagModuleModule} from './pro-tag/pro-tag-module.module';
import {ComingSoonTagComponent} from './coming-soon-tag/coming-soon-tag.component';
import {KHeaderModule} from './k-header/k-header.module';
import {WebFooterModule} from './web-footer/web-footer.module';
import {CreatefabModule} from './createfab-button/createfab.module';
import {LateralMenuModule} from './lateral-menu/lateral-menu.module';
import { StarSenderListComponent } from './star-sender-list/star-sender-list.component';
import { LikeSenderListComponent } from './like-sender-list/like-sender-list.component';
import { AvailabilityAlertComponent } from './availability-alert/availability-alert.component';
import { CommentComponent } from './comment-list/comment/comment.component';
import { GenericAlertComponent } from './generic-alert/generic-alert.component';
import {PresentationComponent} from './presentation/presentation.component';
import { LocalContactListComponent } from './local-contact-list/local-contact-list.component';
import {NewProUserPopupComponent} from './new-pro-user-popup/new-pro-user-popup.component';
import {NewBadgeComponent} from './new-badge/new-badge.component';
import {RegisteredUserWithCodePopupComponent} from './registered-user-with-code-popup/registered-user-with-code-popup.component';
import { HubInfoComponent } from './content-info/hub-info/hub-info.component';
import { InfoBlockComponent } from './info-block/info-block.component';
import { EstrimfestPopupComponent } from './estrimfest-popup/estrimfest-popup.component';
import { ProfileVerificationModalComponent } from './profile-verification-modal/profile-verification-modal.component';
import { AccountVerifiedModalComponent } from './account-verified-modal/account-verified-modal.component';
@NgModule({
  declarations: [ShowHidePasswordComponent, InFooterComponent, ScrollTopButtonComponent, PremiumMessageComponent,
    CategoriesButtonsComponent, FormInputComponent, ModalBaseComponent, HeaderBackComponent,
    ChatMessageComponent, PrivateMessageComponent,
    MyWalletTransactionEntryComponent,
    SearchContactItemComponent,
    SearchVideoItemComponent,
    NotificationItemComponent,
    ChatWithUserContentComponent,
    ChatListContentComponent,
    KFormErrorComponent,
    ProfileHeaderComponent,
    MessagePopoverComponent,
    BlockPopoverComponent,
    ProfileOptionsPopoverComponent,
    AddContentPopoverComponent,
    RaiseHandPopoverComponent,
    SearchbarComponent,
    HeaderComponent,
    AudioPlayerComponent,
    PodcastPlayerComponent,
    VideoPlayerComponent,
    ReportComponent,
    SendStarsComponent,
    UserListComponent,
    ImageZoomComponent,
    ProfileAvatarZoomComponent,
    HandsListComponent,
    ProgressCircleComponent,
    SubscribeComponent,
    CollaborateComponent,
    IncomeInfoComponent,
    WithdrawInfoComponent,
    ProAlertComponent,
    PinganilloComponent,
    InvitacionDirectoComponent,
    ChooseContentTypeComponent,
    PhoneCheckComponent,
    ExperienceComponent,
    FounderComponent,
    VersionBlockerComponent,
    PingComponent,
    InviteComponent,
    WelcomeComponent,
    StaticContentInfoComponent,
    LiveVideoInfoComponent,
    LiveAudioInfoComponent,
    EventInfoComponent,
    PremiereInfoComponent,
    LiveKitPreviaVideoComponent,
    VideoPreviewComponent,
    UserDisplayComponent,
    SelectComponent,
    LivekitLiveStreamComponent,
    LiveKitStreamComponent,
    ParticipantIconComponent,
    LineClampComponent,
    CreateReviewComponent,
    StarCounterComponent,
    LikeCounterComponent,
    StarLikeComponent,
    HeartLikeComponent,
    StarSenderListComponent,
    LikeSenderListComponent,
    AvailabilityAlertComponent,
    CommentComponent,
    GenericAlertComponent,
    ComingSoonTagComponent,
    PresentationComponent,
    LocalContactListComponent,
    NewProUserPopupComponent,
    NewBadgeComponent,
    RegisteredUserWithCodePopupComponent,
    HubInfoComponent,
    InfoBlockComponent,
    EstrimfestPopupComponent,
    ProfileVerificationModalComponent,
    AccountVerifiedModalComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    RouterModule,
    IonicModule,
    ScrollingModule,
    ReactiveFormsModule,
    FormsModule,
    DirectivesModule,
    SwiperModule,
    CategoryButtonModule,
    LazyImgModule,
    PinchZoomModule,
    ValorationStarModule,
    UserRecommendationModule,
    ProTagModuleModule,
    KHeaderModule,
    WebFooterModule,
    CreatefabModule,
    LateralMenuModule,
  ],
  exports: [
    LateralMenuModule,
    WebFooterModule,
    CreatefabModule,
    KHeaderModule,
    ProTagModuleModule,
    UserRecommendationModule,
    ShowHidePasswordComponent,
    InFooterComponent,
    ScrollTopButtonComponent,
    PremiumMessageComponent,
    CategoriesButtonsComponent, FormInputComponent, ModalBaseComponent, HeaderBackComponent,
    ChatMessageComponent, PrivateMessageComponent,
    MyWalletTransactionEntryComponent,
    SearchContactItemComponent,
    SearchVideoItemComponent, ChatListContentComponent,
    NotificationItemComponent,
    ChatWithUserContentComponent,
    KFormErrorComponent,
    ProfileHeaderComponent,
    MessagePopoverComponent,
    BlockPopoverComponent,
    AddContentPopoverComponent,
    RaiseHandPopoverComponent,
    SearchbarComponent,
    HeaderComponent,
    AudioPlayerComponent,
    PodcastPlayerComponent,
    VideoPlayerComponent,
    ReportComponent,
    SendStarsComponent,
    UserListComponent,
    ImageZoomComponent,
    ProfileAvatarZoomComponent,
    HandsListComponent,
    ProgressCircleComponent,
    SubscribeComponent,
    CollaborateComponent,
    IncomeInfoComponent,
    WithdrawInfoComponent,
    ProAlertComponent,
    PinganilloComponent,
    InvitacionDirectoComponent,
    ChooseContentTypeComponent,
    PhoneCheckComponent,
    ExperienceComponent,
    FounderComponent,
    VersionBlockerComponent,
    PingComponent,
    InviteComponent,
    WelcomeComponent,
    StaticContentInfoComponent,
    LiveVideoInfoComponent,
    LiveAudioInfoComponent,
    EventInfoComponent,
    PremiereInfoComponent,
    LiveKitPreviaVideoComponent,
    VideoPreviewComponent,
    UserDisplayComponent,
    SelectComponent,
    LivekitLiveStreamComponent,
    LiveKitStreamComponent,
    ParticipantIconComponent,
    LineClampComponent,
    CreateReviewComponent,
    StarCounterComponent,
    LikeCounterComponent,
    StarLikeComponent,
    HeartLikeComponent,
    StarSenderListComponent,
    LikeSenderListComponent,
    AvailabilityAlertComponent,
    CommentComponent,
    GenericAlertComponent,
    ComingSoonTagComponent,
    PresentationComponent,
    LocalContactListComponent,
    NewProUserPopupComponent,
    NewBadgeComponent,
    RegisteredUserWithCodePopupComponent,
    HubInfoComponent,
    InfoBlockComponent,
    ProfileVerificationModalComponent,
    AccountVerifiedModalComponent
  ],
})

export class ComponentsModule {
}
