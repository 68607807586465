import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-account-verified-modal',
  templateUrl: './account-verified-modal.component.html',
  styleUrls: ['./account-verified-modal.component.scss'],
})
export class AccountVerifiedModalComponent implements OnInit {

  constructor( 
    private modalCtrl: ModalController,
    private router: Router
  ) { }

  ngOnInit() {}

  goHome() {
    this.router.navigate(['/home']);
    this.dismiss();
  }

  dismiss() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }
}
