import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform, IonNav, AlertController, ModalController } from '@ionic/angular';

import { BasePage } from '../../../../../pages/base.page';
import { AllCategoriesPage } from '../../../../../pages/all-categories/all-categories.page';

import { environment } from '../../../../../../environments/environment';

import { CreateContentService } from '../../../../../services/create-content.service';
import { FeedService } from '../../../../../services/feed.service';
import { FilePickerService } from '../../../../../services/file-picker.service';
import { MediaService } from '../../../../../services/media.service';
import { AuthService } from '../../../../../services/auth.service';
import { PublicationService } from '../../../../../services/publication.service';
import { Events } from '../../../../../services/events.service';

import { Category } from '../../../../../interfaces/category';
import { User } from '../../../../../interfaces/user';
import { Publication } from '../../../../../interfaces/publication';

import { ContentType } from '../../../../../enums/content-type';
import { FormMode } from '../../../../../enums/form-mode';

@Component({
  selector: 'app-form',
  templateUrl: './form.page.html',
  styleUrls: ['./form.page.scss', '../../../../../../theme/field.scss'],
})
export class FormPage extends BasePage implements OnInit {

  @Input() type: ContentType = ContentType.LiveVideo;
  @Input() mode: FormMode = FormMode.Create;
  @Input() publication: Publication;

  ContentType = ContentType;
  FormMode = FormMode;

  categories: Category[] = [];

  uploading: boolean;

  error: any = {};

  user: User;

  /**
   * Form data
   */
  title: string;
  description: string;
  privacy: string;
  chat: string;
  welcome: string;
  notification: string;
  category: number;
  hand: string;
  videoHand: string;
  audioHand: string;
  poster: File;
  posterSrc: SafeUrl | string;
  save: number = 0;
  streamKey: string;
  streamUrl: string;

  constructor(
    public platform: Platform,
    private nav: IonNav,
    private createContentService: CreateContentService,
    private feedService: FeedService,
    private mediaService: MediaService,
    private authService: AuthService,
    private publicationService: PublicationService,
    private filePickerService: FilePickerService,
    private events: Events,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private domSanitizer: DomSanitizer,
    private router: Router,
    public cdRef: ChangeDetectorRef
  ) {
    super(platform, cdRef);

    // Get categories
    this.categories = feedService.categories;
  }

  ngOnInit() {
    this.user = this.authService.user;

    if ( this.mode === FormMode.Edit && this.publication !== undefined ) {
      this.title = this.publication.title;
      this.description = this.publication.description;
      this.privacy = this.publication.isPremium ? 'subscribers' : 'all';
      this.chat = this.publication.chatConfiguration;
      this.category = this.publication.category.id;
      this.hand = this.publication.handConfigurationAudio;
      this.audioHand = this.publication.handConfigurationAudio;
      this.videoHand = this.publication.handConfigurationVideo;
      this.posterSrc = this.publication.thumbnail;
      this.save = this.publication.mustBeRecorded ? 1 : 0;
    }

    this.sub.backbutton = this.platform.backButton.subscribeWithPriority(101, () => {
      this.goBack();
    });

    // Get stream key
    const roomId = parseInt((environment.production ? 0 : 9) +  '222' + this.publication.id, 10);
    this.authService.getStreamKey(roomId, this.user.username).subscribe((data) => {
      if(data.done){
        this.streamKey = data.streamKey;
        this.streamUrl = data.url;
      }
    });
  }

  ionViewWillEnter() {
    // Subscribe to poster file pick
    this.sub.filePicked = this.filePickerService.filePicked.subscribe(async (file: File) => {
      if ( file !== undefined && file.type.includes('image') ) {
        let target = '';

        // Prepare props to crop image page
        switch ( this.type ) {
          case ContentType.LiveVideo: target = 'live'; break;
          case ContentType.LiveAudio: target = 'audio'; break;
          default: target = 'live';
        }

        // Ask user to crop poster
        const croppedImage = await this.mediaService.generateUserCroppedImage(file, target);

        this.poster = croppedImage !== undefined ? croppedImage : file;
        this.posterSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(this.poster));
      }
    });
  }

  ionViewWillLeave() {
    this.unsubscribeAll();
  }

  dismiss() {
    this.createContentService.closeModal();
  }

  goBack() {
    if ( !this.uploading ) {
      if ( this.mode === FormMode.Create )
        {this.nav.pop({ mode: 'ios' });}
      else
        {this.dismiss();}
    }
  }

  pickPoster() {
    this.filePickerService.pickFile(ContentType.Image);
  }

  async pickCategory() {
    const modal = await this.modalCtrl.create({
      component: AllCategoriesPage,
      // mode: 'ios',
      componentProps: {
        customCategories: this.categories,
        defaultBackAction: false,
        isModalPage: true,
        multipleSelection: false
      }
    });
    await modal.present();

    const { data } = await modal.onDidDismiss();

    if ( data.length ) {
      this.category = data[0].id;
    }
  }

  checkForm(): boolean {
    let status = true;

    if ( this.mode === FormMode.Create && this.poster === undefined ) {
      status = false;
      this.error.poster = true;
    }

    if ( !(this.title && this.title.trim() !== '') ) {
      status = false;
      this.error.title = true;
    }

    if ( !(this.description && this.description.trim() !== '') ) {
      status = false;
      this.error.description = true;
    }

    if ( !this.category ) {
      status = false;
      this.error.category = true;
    }

    if ( !this.privacy ) {
      status = false;
      this.error.privacy = true;
    }

    if ( !this.chat ) {
      status = false;
      this.error.chat = true;
    }

    if ( this.type === ContentType.LiveAudio ) {
      if ( !this.hand ) {
        status = false;
        this.error.hand = true;
      }
    }

    if ( this.type === ContentType.LiveVideo ) {
      if ( !this.videoHand ) {
        status = false;
        this.error.videoHand = true;
      }

      if ( !this.audioHand ) {
        status = false;
        this.error.audioHand = true;
      }
    }

    return status;
  }

  async checkSubscriptionContent(): Promise<boolean> {
    if (!this.user.subscriptionsActivated && this.privacy === 'subscribers') {

      const alert = await this.alertCtrl.create({
        message: `No puedes crear contenido para suscriptores porque no has activado tus suscripciones`,
        buttons: [
          {
            text: 'Configurar suscripciones',
            handler: () => {
              this.router.navigate(['/payment/my-subscriptions']);
              this.dismiss();
            }
          },
          {
            text: 'Cancelar',
            role: 'cancel'
          },
        ]
      });

      await alert.present();

      return false;
    }

    return true;
  }

  async submitForm() {
    if ( !(this.checkForm() && await this.checkSubscriptionContent()) ) {
      return ;  // BREAK EXECUTION
    }

    /*
     * Uploading started
     */

    this.uploading = true;
    let posterFileName: SafeUrl | string;
    let posterResult: any;
    let contentResult: any;

    /*
     * Upload poster
     */

    if ( this.mode === FormMode.Create || ( this.mode === FormMode.Edit && this.poster ) ) {
      try {
        posterResult = await this.publicationService
          .uploadPublicationTempImage((this.poster as File))
          .toPromise();
      } catch(e) {}

      if ( !(posterResult && posterResult.done && posterResult.msg === 'Ok') ) {
        // Throw error
        this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error, intentalo de nuevo más tarde');
        this.uploading = false;
        return ;  // BREAK EXECUTION
      }

      posterFileName = posterResult.file_cdn ? posterResult.file_cdn : posterResult.file;
    } else {
      posterFileName = this.posterSrc;
    }

    /*
     * Upload content
     */

    const data = {
      title: this.title,
      description: this.description,
      categorySelected: this.category,
      chat: this.chat,
      handVideo: this.videoHand,
      handAudio: this.type === ContentType.LiveAudio ? this.hand : this.audioHand,
      emission: this.privacy,
      onlyAudio: this.type === ContentType.LiveAudio,
      imageFile: posterFileName,
      save: this.save,
      welcomeText: this.welcome,
      notificationText: this.notification
    };

    try {
      contentResult = await this.publicationService.createLive(
        data,
        this.mode === FormMode.Edit ? this.publication?.id : undefined,
        this.type
      ).toPromise();
    } catch(e) {}

    if ( !(contentResult && contentResult.done) ) {
      // Throw error
      this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Ha ocurrido un error, intentalo de nuevo más tarde');
      this.uploading = false;
      return ;  // BREAK EXECUTION
    }

    /*
     * Uploading done
     */

    this.uploading = false;

    if ( this.mode === FormMode.Create ) {
      // Navigate to newly created content
      this.router.navigate(['/publications/view/' + contentResult.data], {replaceUrl: true});

      // Close modal
      this.dismiss();
    } else {
      // Update publication
      this.publication.title = this.title;
      this.publication.description = this.description;
      this.publication.isPremium = this.privacy === 'subscribers';
      this.publication.chatConfiguration = this.chat;
      this.publication.category.id = this.category;
      this.publication.handConfigurationAudio = this.type === ContentType.LiveAudio ? this.hand : this.audioHand;
      this.publication.handConfigurationVideo = this.videoHand;
      // @ts-ignore
      this.publication.thumbnail = posterFileName;
      this.publication.mustBeRecorded = !!this.save;

      return this.modalCtrl.dismiss(this.publication, 'confirm');
    }
  }

  privacyChanged() {
    this.error.privacy = false;

    this.chat = undefined;
    this.hand = undefined;
    this.videoHand = undefined;
    this.audioHand = undefined;

    this.cdRef.detectChanges();
  }
}
