import { Component, Input, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserListBaseComponent } from '../user-list-base.component';

import { MediaService } from 'src/app/services/media.service';
import { PublicationService } from 'src/app/services/publication.service';
import { ProfileService } from 'src/app/services/profile.service';
import { PaymentService } from 'src/app/services/payment.service';
import { AuthService } from 'src/app/services/auth.service';
import { Events } from 'src/app/services/events.service';

import { User } from 'src/app/interfaces/user';

import { identifyPublicationType } from 'src/app/utils/publication';

@Component({
  selector: 'app-like-sender-list',
  templateUrl: './like-sender-list.component.html',
  styleUrls: ['./like-sender-list.component.scss'],
})
export class LikeSenderListComponent extends UserListBaseComponent implements OnInit, OnDestroy {

  @Input() count: number = 0;
  @Input() likeSenders: User[];

  loading: boolean = true;

  constructor(
    private publicationService: PublicationService,
    private events: Events, 
    protected authService: AuthService,
    protected mediaService: MediaService,
    protected profileService: ProfileService,
    protected paymentService: PaymentService,
    protected cdRef: ChangeDetectorRef,
  ) {
    super(authService, mediaService, profileService, paymentService, cdRef);
  }

  ngOnInit() {
    super.ngOnInit();

    if ( this.likeSenders ) {
      this.loading = false;
    } else if ( this.publication && !this.publication.likeSenders ) {
      this.loading = true;
      this.likeSenders = [];
      this.getSenders();
    } else if ( this.publication && this.publication.likeSenders ) {
      this.likeSenders = this.publication.likeSenders;
      this.count = this.publication.likesNumber;
      this.loading = false;
      this.getSenders();
    } else {
      // Dismiss
    }

    this.sub.userFollow = this.events.subscribe(environment.EVENTS.USER_FOLLOW, (data) => {
      this.likeSenders.find((user: User) => user.id === data.userId).following = data.following;
    })
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }

  getSenders() {
    // Fetch transactions
    this.publicationService.getPublicationLikeHistory(this.publication.id, identifyPublicationType(this.publication)).subscribe(
      res => {
        if ( res.done ) {
          this.likeSenders = res.data.likes;
          this.publication.likeSenders = this.likeSenders;
          this.count = this.publication.likesNumber;
          this.loading = false;
          this.cdRef.detectChanges();
        } else {
          // Should we return a feedback?
          this.loading = false;
          this.cdRef.detectChanges();
        }
      }, err => {
        // Should we return a feedback?
        this.loading = false;
        this.cdRef.detectChanges();
      }
    );
  }
}
