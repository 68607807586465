<ion-menu
  side="end"
  type="overlay"
  menuId="lateral"
  contentId="main-content"
  class="my-custom-menu custom-separador-app"
  swipe-gesture="false"
  (ionWillOpen)="menuWillOpen()">
  <ion-content>
    <div class="statusbar-contrast" *ngIf="isIos && profleOnScreen"></div>

    <ion-row style="cursor: pointer" class="activity-cover header-profile-edit" *ngIf="user">
      <div class="image"
        (click)="closeMenu()"
        routerLink="/profile/edit">
        <lazy-img
          class="activity-img-wrapper"
          [size]="400"
          [source]="getImgProfile()">
        </lazy-img>

        <div class="ion-activatable ripple-parent modify-position">
          <ion-icon
            src="assets/svg-icon/editar.svg"
          >
          </ion-icon>
        </div>
      </div>
      <div (click)="closeMenu()" routerLink="/profile/me" class="profile-menu-header">
        <span class="info-username">{{user.nameToShow}}</span>
        <span class="info-email">@{{user.username}}</span>
      </div>
    </ion-row>

    <!-- Extrim Race pages -->
    <!-- <ng-container *ngIf="!isWeb">
      <div class="div-separator thin"></div>
      <ion-row class="dropdown-item">
        <ion-col>
          <ion-list class="custom-items">
            <ion-item class="extrim" detail="false" lines="none" (click)="launchExtrimRacePoll()">
              <lazy-img source="assets/extrim/img/logo_black.png" [cover]="false"></lazy-img>
              <ion-spinner name="crescent" color="primary" *ngIf="loadingExtrimRacePool"></ion-spinner>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>

      <div class="div-separator thin"></div>

      <ion-row class="dropdown-item">
        <ion-col>
          <ion-list class="custom-items">
            <ion-item class="estrimfest" detail="false" lines="none" (click)="launchEstrimFestPopup()">
              <lazy-img source="assets/estrimfest/img/estrimfest-logo.png" [cover]="false"></lazy-img>
              <ion-spinner name="crescent" color="primary" *ngIf="loadingEstrimFest"></ion-spinner>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
    </ng-container> -->

    <!-- Home mode selector -->
    <!-- <ng-container *ngIf="!isWeb">
      <div class="div-separator thin"></div>
      <ion-row class="dropdown-item">
        <ion-col>
          <ion-list>
            <ion-item detail="false" lines="none" (click)="homeModeDropdown = !homeModeDropdown">
              <ion-icon src="assets/svg-icon/camera-svg-svf.svg" *ngIf="homeLayout === HomeLayout.Video"></ion-icon>
              <ion-icon src="assets/svg-icon/mic-svg-svf.svg" *ngIf="homeLayout === HomeLayout.Audio"></ion-icon>
              <ion-label class="no-icon-label modific-label">{{homeLayout === HomeLayout.Video ? 'Contenido Vídeo' : 'Contenido Audio'}}</ion-label>
              <ion-icon class="chevron-down" name="chevron-down-outline" [ngClass]="{'rotated': homeModeDropdown}"></ion-icon>
            </ion-item>
          </ion-list>

          <ion-list class="dropdown" [ngClass]="{'open': homeModeDropdown}">
            <ion-item detail="false" lines="none" (click)="setHomeLayout(HomeLayout.Video)">
              <ion-icon name="checkmark-outline" *ngIf="homeLayout === HomeLayout.Video"></ion-icon>
              <ion-icon *ngIf="homeLayout !== HomeLayout.Video"></ion-icon>
              <ion-label class="no-icon-label modific-label">Contenido Vídeo</ion-label>
            </ion-item>
            <ion-item detail="false" lines="none" (click)="setHomeLayout(HomeLayout.Audio)">
              <ion-icon name="checkmark-outline" *ngIf="homeLayout === HomeLayout.Audio"></ion-icon>
              <ion-icon *ngIf="homeLayout !== HomeLayout.Audio"></ion-icon>
              <ion-label class="no-icon-label modific-label">Contenido Audio</ion-label>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
    </ng-container> -->
    <div class="div-separator thin"></div>
    <ion-row>
      <ion-col>
        <ion-list class="main-primary-right">
          <ion-item detail="false" (click)="closeMenu()" lines="none" routerLink="/payment/my-subscriptions">
            <ion-icon src="assets/svg-icon/menu/correct.svg"></ion-icon>
            <ion-label>Suscripciones</ion-label>
          </ion-item>
          <ion-item [ngClass]="{'notification-indicator': invitationsIndicator}" detail="false" (click)="closeMenu()" lines="none" routerLink="/invitations">
            <ion-icon src="assets/svg-icon/menu/message.svg"></ion-icon>
            <ion-label>Invitaciones</ion-label>
          </ion-item>
          <ion-item detail="false" (click)="closeMenu()" lines="none" routerLink="/wallet">
            <ion-icon src="assets/svg-icon/menu/wallet.svg"></ion-icon>
            <ion-label>
              Cartera
              <ion-badge class="wallet-badge"
                *ngIf="walletBallance > 0"
                color="primary">
                {{walletBallance | number : '1.2-2'}}
                <img src="assets/coin.svg" alt=""/>
              </ion-badge>
            </ion-label>
          </ion-item>
          <ion-item detail="false" (click)="closeMenu()" lines="none" routerLink="/payment/my-purchases">
            <ion-icon src="assets/svg-icon/menu/shopping-bag.svg"></ion-icon>
            <ion-label>Pases y compras</ion-label>
          </ion-item>
          <ion-item detail="false" (click)="closeMenu()" lines="none" routerLink="/saved-content">
            <ion-icon class="saved" src="assets/svg-icon/menu/bookmark.svg"></ion-icon>
            <ion-label>Guardado</ion-label>
          </ion-item>
          <ion-item detail="false" *ngIf="isMobile()" (click)="closeMenu()" lines="none" routerLink="/find-contacts">
            <ion-icon src="assets/svg-icon/menu/search-user.svg"></ion-icon>
            <ion-label>Encontrar amigos</ion-label>
          </ion-item>
          <ion-item detail="false" (click)="closeMenu()" lines="none" routerLink="/storage">
            <ion-icon src="assets/svg-icon/menu/cloud.svg"></ion-icon>
            <ion-label>Almacenamiento</ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
    <div class="div-separator thin"></div>
    <ion-row>
      <ion-col>
        <ion-list>
          <ion-item detail="false" (click)="closeMenu()" class="configBtn" lines="none" routerLink="/configuration">
            <ion-icon src="assets/svg-icon/menu/setting.svg"></ion-icon>
            <ion-label class="no-icon-label modific-label">Configuración</ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
    <div class="div-separator thin"></div>
    <ion-row>
      <ion-col>
        <ion-list class="auxiliary">
          <ion-item detail="false" (click)="closeMenu()" lines="none" routerLink="/user-guide">
            <ion-label class="no-icon-label">Guía de usuario</ion-label>
          </ion-item>
          <ion-item detail="false" (click)="closeMenu()" lines="none" routerLink="/help">
            <ion-label class="no-icon-label">Centro de ayuda</ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
    <div class="div-separator thin"></div>

    <ion-row class="version">
      <p>
        Versión: {{version}}
      </p>
    </ion-row>
  </ion-content>
</ion-menu>
