<div class="container">
  <ion-item>
    <ion-textarea placeholder="Escribe algo" [(ngModel)]="message" [maxlength]="200" [autoGrow]="true"
      value="">
    </ion-textarea>
  </ion-item>

  <ion-button (click)="sending()" class="button headset" color="darkteal" expand="block">
    Enviar
  </ion-button>
</div>
