<div class="audio-rooms" *ngIf="recommendedAudiosList.length > 0">
  <p class="d-flex ion-justify-content-between">
    <span class="font-medium">Salas de audio</span>
    <a (click)="goToViewAll()" class="font-medium color-gray">Ver más</a>
  </p>
  <ul class="thumb-list mb-4">
    <div *ngIf="isLoading">
      <div *ngFor="let recommendedAudio of [0, 1, 2, 3, 4]; let i = index">
        <div class="d-flex">
          <ion-skeleton-text
            [animated]="true"
            style="
              width: 2em;
              height: 2em;
              margin-right: 0.5em;
              border-radius: 0.2em;
            "
          ></ion-skeleton-text>
          <div>
            <ion-skeleton-text
              [animated]="true"
              style="width: 4em"
            ></ion-skeleton-text>
            <ion-skeleton-text
              [animated]="true"
              style="width: 7em"
            ></ion-skeleton-text>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!isLoading">
      <div
        *ngFor="
          let recommendedAudio of recommendedAudiosList.slice(0, 5);
          let i = index
        "
      >
        <li>
          <a (click)="goToPublication(recommendedAudio)">
            <div class="thumb">
              <div
                *ngIf="recommendedAudio.isPremium"
                class="locked-content"
                [ngClass]="{
                  open:
                    recommendedAudio.collaborating ||
                    recommendedAudio.subscribed ||
                    recommendedAudio.author.id === user?.id
                }"
              >
                <ion-icon
                  *ngIf="
                    !(
                      recommendedAudio.collaborating ||
                      recommendedAudio.subscribed ||
                      recommendedAudio.author.id === user?.id
                    )
                  "
                  name="lock-closed"
                ></ion-icon>
                <ion-icon
                  *ngIf="
                    recommendedAudio.collaborating ||
                    recommendedAudio.subscribed ||
                    recommendedAudio.author.id === user?.id
                  "
                  name="lock-open"
                ></ion-icon>
              </div>
              <lazy-img
                alt="image"
                [size]="400"
                [source]="getPublicationImg(recommendedAudio)"
              ></lazy-img>
            </div>
            <div>
              <strong>{{ recommendedAudio.author.username }}</strong>
              <span>{{ recommendedAudio.title }}</span>
            </div>
          </a>
        </li>
      </div>
    </div>
  </ul>
</div>
