import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { Upload } from 'tus-js-client';
import { environment } from 'src/environments/environment';

import { Events } from './events.service';

@Injectable({
  providedIn: 'root'
})
export abstract class TusUploadService {

  protected _fileUploaded = new Subject();
  fileUploaded = this._fileUploaded.asObservable();

  protected _fileUploadError = new Subject();
  fileUploadError = this._fileUploadError.asObservable();

  protected _onProgress: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  onProgress: Observable<number> = this._onProgress.asObservable();

  protected _onUploading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  onUploading: Observable<boolean> = this._onUploading.asObservable();

  protected _upload: Upload;
  protected _response: any;
  protected _uploading: boolean = false;

  constructor( protected platform: Platform, protected events: Events ) {
    this.platform.ready().then(() => {
      this.platform.pause.subscribe(async () => {
        this._upload?.abort();
      });

      this.platform.resume.subscribe(async () => {
        this.startOrResumeUpload();
      });
    });

    this.events.subscribe(environment.EVENTS.USER_LOGOUT, () => {
      this._onProgress.next(0);
      this._onUploading.next(false);
    });
  }

  abstract onError(error);
  abstract onSuccess();
  abstract onUploadProgress(bytesUploaded, bytesTotal);
  abstract onAfterResponse(req, res);

  get response(): any {
    return this._response;
  }

  get uploading(): boolean {
    return this._uploading;
  }

  uploadFile(
    file: File, 
    endpoint: string, 
    headers: any, 
    metadata: any,
    retryDelays: number[] = [0, 3000, 5000, 10000, 20000],
    chunkSize: number = 50 * 1024 * 1024
  ) {
    // Configure upload
    this._upload = new Upload(file, {
      endpoint: endpoint,
      headers: headers,
      retryDelays: retryDelays,
      chunkSize: chunkSize,
      metadata: metadata,
      onError: async (error) => {
        this.onError(error);
        return false;
      },
      onSuccess: () => {
        this.onSuccess();
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        this.onUploadProgress(bytesUploaded, bytesTotal);
      },
      onAfterResponse: (req, res) => {
        this.onAfterResponse(req, res);
      },
    })

    // Start upload
    this._upload.start();
    this._onUploading.next(true);
    this._uploading = true;
  }

  startOrResumeUpload() {
    // Check if there are any previous uploads to continue.
    this._upload?.findPreviousUploads().then((previousUploads) => {
      // Found previous uploads so we select the first one.
      if (previousUploads.length) {
        this._upload.resumeFromPreviousUpload(previousUploads[0])
      }

      // Start the upload
      this._upload.start();
      this._onUploading.next(true);
      this._uploading = true;
    })
  }
}
