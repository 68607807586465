import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef, Inject } from '@angular/core';
import { filter, skipWhile, take } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { MediaService } from '../services/media.service';
import { ProfileService } from '../services/profile.service';
import { PaymentService } from '../services/payment.service';

import { User } from '../interfaces/user';
import { Publication } from '../interfaces/publication';
import { Collaborator } from '../interfaces/collaborator';
import { Subscription } from '../interfaces/subscription';

import { CacheData } from 'src/app/enums/cache-data';

@Component({
  template: '',
})
export class UserListBaseComponent implements OnInit, OnDestroy {

  @Input() publication: Publication = undefined;
  @Input() collaborators: Collaborator[] = [];
  @Input() subscribers: Subscription[] = [];

  @Output() showProfile = new EventEmitter<{user: User, isCreator: boolean, isCollaborator: boolean, isSubscriber: boolean}>();
 
  me: User;

  sub: any = {};

  constructor(
    protected authService: AuthService,
    protected mediaService: MediaService,
    protected profileService: ProfileService,
    protected paymentService: PaymentService,
    protected cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.authService.watchUser()
      .pipe(
        skipWhile(data => !data),
        take(1))
      .subscribe((user: User) => {
        this.me = user;
        this.cdRef.detectChanges();
      });

    // Get collaborators
    this.sub.collaborators = this.profileService.watchCacheData(CacheData.MyCollaborators)
      .pipe(filter(data => !!data))
      .subscribe(data => {
        this.collaborators = data.accepted;
        this.cdRef.detectChanges();
      })

    // Get subscribers
    this.sub.subscribers = this.paymentService.watchCacheData(CacheData.MySubscribers)
      .pipe(filter(data => !!data))
      .subscribe(data => {
        this.subscribers = data;
        this.cdRef.detectChanges();
      })
  }
  ngOnDestroy() {
    this.unsubscribeAll();
  }

  protected unsubscribeAll() {
    Object.keys(this.sub).forEach(key => {
      this.sub[key]?.unsubscribe();
    });
  }

  /**
   * Get profile image of user
   * @param user 
   * @returns image source url
   */
  getImgProfile(user: User) {
    return this.mediaService.generateImgProfileURL(user.id, user.imgProfile);
  }

  /**
   * Get badge source of user
   * @param user 
   * @returns url of badge image
   */
  getBadgeSrc(user: User) {
    return this.mediaService.getBadgeSrc(user);
  }

  /**
   * Check if user is creator
   * @param userId 
   * @returns if is creator or not
   */
  userIsCreator(userId: number) {
    if (this.publication)
      return Number(this.publication.author.id) === Number(userId);
    else
      return false;
  }

  /**
   * Check if user is collaborator
   * @param userId 
   * @returns if is collaborator or not
   */
  userIsCollaborator(userId: number) {
    if (this.collaborators && this.collaborators.length)
      return this.collaborators.some(c => c.user.id === Number(userId));
    else
      return false;
  }

  /**
   * Check if user is subscriber
   * @param userId 
   * @returns if is subscribed or not
   */
  userIsSubscribed(userId: number) {
    if (this.subscribers && this.subscribers.length)
      return this.subscribers.some(s => s.subscriberUser.id === Number(userId));
    else
      return false;
  }

  /**
   * Follow or unfollow user
   * @param user User to follow
   * @param followStatus Follow status
   */
  followUser(user: User, followStatus: boolean) {
    user.following = followStatus;

    this.profileService.follow(user.id, followStatus).subscribe(
      (data) => {
        data.done
          ? user.following = data.following
          : user.following = !user.following;

        this.cdRef.detectChanges();
      }, (error) => {
        user.following = !user.following;
        this.cdRef.detectChanges();
      });
  }

  /**
   * Check if user is me
   * @param user 
   * @returns 
   */
  itsMe(user: User): Boolean {
    return Number(user.id) === this.me?.id;
  }

  /**
   * Show user mini profile
   * @param user 
   */
  triggerShowProfile(user) {
    this.showProfile.emit({
      user, 
      isCreator: this.userIsCreator(user.id),
      isCollaborator: this.userIsCollaborator(user.id),
      isSubscriber: this.userIsSubscribed(user.id)
    })
  }
}
