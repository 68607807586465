import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Publication } from 'src/app/interfaces/publication';
import { LiveKitClient } from 'src/app/services/livekit.service';
import { Events } from 'src/app/services/events.service';
import Room from 'livekit-client/dist/src/room/Room';
import { environment } from 'src/environments/environment';
import { Collaborator } from 'src/app/interfaces/collaborator';
import { ProfileService } from 'src/app/services/profile.service';
import { UserService } from 'src/app/services/user.service';
import { AlertController } from '@ionic/angular';
import { RouterDataService } from 'src/app/services/router-data.service';
import { Router } from '@angular/router';
import { User } from 'src/app/interfaces/user';


@Component({
  selector: 'app-live-video',
  templateUrl: './livekit-previa-video.component.html',
  styleUrls: ['./livekit-previa-video.component.scss'],
})
export class LiveKitPreviaVideoComponent implements OnInit, OnDestroy {
  @Input() liveKit: LiveKitClient;
  @Input() publication: Publication;
  @Input() user: User;
  @Input() isThumbnail = false;
  @Output() exitLiveEvent = new EventEmitter<string>();

  @ViewChild('videoElement') zoneVideo;



  room: Room;
  connected: boolean;
  remoteStream: boolean;
  showSpinner: boolean;

  /** Controling time in previa */
  timeInRoom: number;
  intervalTimeInRoom;


  /** For enter in publication */
  collaborators: Collaborator[] = null;
  livekitRoomId: number;

  constructor(
    private profileService: ProfileService,
    private userService: UserService,
    private routerDataService: RouterDataService,
    private router: Router,
    private alertCtrl: AlertController,
    private cdRef: ChangeDetectorRef,
    private events: Events,
  ) {
    this.showSpinner = false;
    this.remoteStream = false;
    this.timeInRoom = 0;
  }



  ngOnInit() {
    this.livekitRoomId = parseInt((environment.production ? 0 : 9) +  '222' + this.publication.id, 10);
    this.connect();
  }

  ngOnDestroy(){
    if(this.connected){ this.disconnect(); }
  }

  ionViewDidEnter() {
    console.log('enter');
  }

  ionViewDidLeave() {
    console.log('leave');
    if(this.connected){ this.disconnect(); }
  }



  /** Connected to Room */
  async connect(){
    if(this.liveKit === null){ return; }
    await this.disconnect();

    const liveKitToken = await this.liveKit?.getTokenPreview(this.livekitRoomId, false);
    if(liveKitToken?.state){
      //document.querySelector('#r_'+this.publication.id).classList.add('show');
      //document.querySelector('#r_'+this.publication.id+' ion-spinner').classList.add('show');
      this.showSpinner = true;
      this.cdRef.detectChanges();

        await this.liveKit.connect(liveKitToken.token,
        (data: any)=>{
          this.connected = true;
          this.showSpinner = false;
          console.log('connected', this.connected);

          /** For limit time in the room */
          // this.intervalTimeInRoom = setInterval(async ()=>{
          //   this.timeInRoom++;
          //   if(this.timeInRoom >= 2){
          //     await this.disconnect();
          //   }
          // }, 1000);

          this.cdRef.detectChanges();
        }, (local: boolean, track: any, participant: any)=>{

            const interval = setInterval(()=>{
              if(!track.track){ return; }
              if(this.zoneVideo == null){ return; }
              if(this.zoneVideo.nativeElement == null){ return; }
              if(this.zoneVideo.nativeElement.querySelector('.videoRemote') == null){ return; }

              /** If have old video */
              const haveVideo = this.zoneVideo.nativeElement.querySelector('.videoRemote video');
              if(haveVideo){ return; }

              //Not accept audio
              if(track.track.kind === 'audio'){ return; }

              /** Get video player */
              const elementTemp = track.track.attach();
              this.liveKit.setAttrPrepared(elementTemp);
              elementTemp.setAttribute('poster', '../../../assets/estrim/poster/logo-light-square.png');
              elementTemp.classList.add('sid' + track.track.sid);

              // Forced muted video elements (Redundant)
              elementTemp.muted = true;
              elementTemp.play();


              this.zoneVideo.nativeElement.querySelector('.videoRemote').append(elementTemp);
              //document.querySelector(zoneVideos).appendChild(elementTemp);
              //document.querySelector('#r_'+publicationPreviewVideo.id).classList.add('show');
              //document.querySelector('#r_'+publicationPreviewVideo.id+' ion-spinner').classList.remove('show');
              this.showSpinner = false;
              clearInterval(interval);
            }, 100);

        }, (track: any)=>{
            const videoRemote =document.querySelector('.sid'+track.sid);
            if(videoRemote){ videoRemote.remove(); }

        }, (data: any)=>{
            console.log('onRemoveMediaLocal->', data);
        }, (data: any)=>{
            console.log('onSpeaker->', data);
        }, (data: any)=>{
            console.log('disconnected');
            this.connected = false;
            this.showSpinner = false;
            this.exitLiveEvent.emit();

            if(this.intervalTimeInRoom != null){
              clearInterval(this.intervalTimeInRoom);
              this.timeInRoom = 0;
            }

            try {
              const haveVideo = this.zoneVideo.nativeElement.querySelector('.videoRemote video');
              if(haveVideo){ haveVideo.remove(); }
            } catch (error) {
              console.log('error', error);
            }

            this.cdRef.detectChanges();
        },(data: any)=>{
            console.log('onLocalTrack->', data);
        },(data: any)=>{
            console.log('onMute->', data);
        },(data: any)=>{
            console.log('onUnMute->', data);
        },(data: any)=>{
            //console.log('onParticipantConnected->', data);
        },(data: any)=>{
            console.log('onParticipantDisconnected->', data);
            // this.disconnect();
        });

        this.showSpinner = false;
    } else {
      this.exitLiveEvent.emit();
    }
  }
  /** Disconnected to Room */
  async disconnect(){
    await this.liveKit?.disconnect(()=>{});
    this.connected = false;
  }
  async enterPublication() {
    if (!this.collaborators) {
      const res = await this.profileService.getCollaborators(this.publication.author.id).toPromise();
      this.collaborators = res.data.accepted;
    }
    // eslint-disable-next-line max-len
    if (this.publication.isPremium && !(this.publication.collaborating || this.publication.subscribed || this.publication.author.id === this.user?.id)) {

      this.userService.subscribeToUser(this.publication.author);

      // const alert = await this.alertCtrl.create({
      //   cssClass: 'my-custom-alert-class',
      //   message: `Este contenido es solo para suscriptores. ¿Suscribirte por ${this.publication.author.subscriptionPrice} créditos?`,
      //   buttons: [
      //     {
      //       text: 'Aceptar',
      //       handler: () => {
      //         this.paymentService.subscribeToUser(this.publication.author).subscribe(res => {
      //           if (res.done) {
      //             this.events.publish(environment.EVENTS.ALERT_TOAST_NOTICE, 'Te has suscrito con éxito.');
      //             this.paymentService.wallet = res.data.wallet;
      //             this.router.navigate([`/publications/view/${this.publication.id}`]);
      //           } else {
      //             this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, res.msg);
      //           }
      //         });
      //       }
      //     },
      //     {
      //       text: 'Cancelar',
      //       role: 'cancel'
      //     },
      //   ]
      // });
      // await alert.present();
    } else if (this.publication.kicked) {
      const alert = await this.alertCtrl.create({
        cssClass: 'my-custom-alert-class',
        message: `Has sido expulsado de esta emisión.`,
        buttons: [
          {
            text: 'Aceptar',
            role: 'cancel'
          },
        ]
      });
      await alert.present();
    } else {
      this.routerDataService.setData(this.publication);
      this.router.navigate([`/publications/view/${this.publication.id}`]);
      this.disconnect();
    }
  }

}
