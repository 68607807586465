export enum ChatEvent {
  WELCOME = 'welcome',
  JOIN_ROOM = 'joinRoom',
  JOINED_TO_ROOM = 'joinedToRoom',
  SEND_MESSAGE_TO_ROOM = 'sendMessageToRoom',
  KICK_USER = 'kickUser',
  MESSAGE_LIKE = 'toggleMessageLike',
  ACCEPT_HANDS_UP = 'acceptHandsUp',
  EVENT_ON_JOIN_ROOM = 'eventOnJoinRoom',
  EVENT_ON_MESSAGE = 'eventOnMessage',
  EVENT_ON_MESSAGE_LIKE = 'eventOnLikesUpdate',
}
