import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { User } from '../../interfaces/user';
import { MediaService } from '../../services/media.service';
import { environment } from '../../../environments/environment';
import { trigger, transition, animate, style, keyframes } from '@angular/animations';
import { VideoPlayerComponent } from '../video-player/video-player.component';
import { ChatMessage, ContactMessagePayload, EventMessagePayload, LocationMessagePayload, ReceivedChatMessage, StarsMessagePayload } from 'src/app/interfaces/chat-message';
import { ChatEventMessageType, ChatMessageType } from "src/app//enums/chat-message-type";

@Component({
  selector: 'app-private-message',
  templateUrl: './private-message.component.html',
  styleUrls: ['./private-message.component.scss'],
  animations: [
    trigger('inOutLike', [
      transition(':enter', [
        animate('250ms ease', keyframes([
          style({transform: 'scale(0)', offset: 0}),
          style({transform: 'scale(1.3)', offset: 0.8}),
          style({transform: 'scale(1)', offset: 1})
        ]))
      ])
    ])
  ]
})
export class PrivateMessageComponent implements OnInit {
  @Output() videoCallAnswered: EventEmitter<any> = new EventEmitter();
  @Output() scrollToMessage: EventEmitter<any> = new EventEmitter();
  @ViewChild('VideoPlayer') private videoPlayer: VideoPlayerComponent;

  @Input() user: User;
  @Input() userToTalk: User;
  @Input() msg: ReceivedChatMessage;
  @Input() shouldShowProfileImage: boolean = false;


  messageRef: ReceivedChatMessage;

  replyData;

  audioDuration: string;
  audioLoading = false;

  imageLoading: boolean = true;

  get isText(): boolean {
    return this.msg.type === ChatMessageType.Text;
  }
  get isFile(): boolean {
    return this.msg.type === ChatMessageType.File;
  }
  get isImage(): boolean {
    return this.msg.type === ChatMessageType.Image;
  }
  get isLocation(): boolean {
    return this.msg.type === ChatMessageType.Location;
  }
  get isContact(): boolean {
    return this.msg.type === ChatMessageType.Contact;
  }
  get isVoiceRecording(): boolean {
    return this.msg.type === ChatMessageType.Audio;
  }
  get isStar(): boolean {
    return this.msg.type === ChatMessageType.Stars;
  }
  get isVideoCall(): boolean {
    return this.msg.type === ChatMessageType.Event && (this.msg.data as EventMessagePayload).type === ChatEventMessageType.VideoCall;
  }
  get isCall(): boolean {
    return this.msg.type === ChatMessageType.Event && (this.msg.data as EventMessagePayload).type === ChatEventMessageType.AudioCall;
  }

  get starQuantity(): number {
    return (this.msg.data as StarsMessagePayload).quantity;
  }

  get contactName(): string {
    return (this.msg.data as ContactMessagePayload).name;
  }
  get contactPhone(): string {
    return (this.msg.data as ContactMessagePayload).phone;
  }

  get latitude(): number {
    return (this.msg.data as LocationMessagePayload).latitude;
  }
  get longitude(): number {
    return (this.msg.data as LocationMessagePayload).longitude;
  }

  get filename(): string {
    return this.msg.data as string;
  }

  get callStatus(): number {
    return (this.msg.data as EventMessagePayload).data.status;
  }
  get callType(): string {
    return (this.msg.data as EventMessagePayload).data.type;
  }

  constructor(
    private mediaService: MediaService
  ) {}

  ngOnInit() {
    this.decodeReply();

    if(this.msg.reply){
       this.messageRef = this.msg.reply;
    }
  }



  isVideo(message): boolean{
    if(!message){ return false; }
    if (message.file && message.file.type === 'File' && message.file.data.filename.includes('.mp4')) {
      return true;
    }
    return false;
  }

  isImages(message: ReceivedChatMessage): boolean{
    if(!message){ return false; }
    return message.type === 'image';
  }

  getProfileImg() {
    return this.mediaService.generateImgProfileURL(this.userToTalk.id, this.userToTalk.imgProfile);
  }

  /**
   * Check if the reply is valid
   * @returns
   */
  isValidResponse(){
    return this.msg.reply && Object.keys(this.msg.reply).length > 0;
  }

  checkBigMessage(msg) {
    if ( msg.type === ChatMessageType.Text ) {
      const message = msg.text || msg.data || '';
      const lines = message.split(/\r\n|\r|\n/).length;
      return message.length > 45 || lines > 2;
    } else {
      return true;
    }
  }

  getMedia(mine = false) {
    let filename = '';
    filename = this.msg.data as string;

    if ( filename.includes(environment.CDN_BASE_URL) ) {
      return filename
    } else if (mine) {
      // DEPRECATED
      return `${environment.API_BASE_URL}private_sended_files/${this.user.id}/${this.userToTalk.id}/${filename}`;
    } else {
      // DEPRECATED
      return `${environment.API_BASE_URL}private_sended_files/${this.userToTalk.id}/${this.user.id}/${filename}`;
    }
  }

  getMediaRef(mine = false) {
    let filename = '';
    if (this.messageRef.data) {
      //filename = this.messageRef.data;
    } else {
      //filename = this.messageRef.file.filename;
    }

    if ( filename.includes(environment.CDN_BASE_URL) ) {
      return filename
    } else if (mine) {
      // DEPRECATED
      return `${environment.API_BASE_URL}private_sended_files/${this.user.id}/${this.userToTalk.id}/${filename}`;
    } else {
      // DEPRECATED
      return `${environment.API_BASE_URL}private_sended_files/${this.userToTalk.id}/${this.user.id}/${filename}`;
    }
  }
  getPrivatePhotoSrc(mine = false) {
    let filename = '';
    filename = this.msg.data as string;

    return this.msg.data as string;
  }
  getPrivatePhotoReplySrc(mine = false) {
    let filename = '';
    if (this.messageRef.data && typeof this.messageRef.data === 'string') {
      filename = this.messageRef.data;
    } else {
      //filename = this.messageRef.file.filename;
    }

    return filename;
  }

  goToLocation(latitude: number, longitude: number) {
    window.open(`http://maps.google.com/maps?q=${latitude},${longitude}`, '_blank');
  }

  downloadFile(mine = false) {
    let filename = '';
    filename = this.msg.data as string;

    let url = '';
    if ( filename.includes(environment.CDN_BASE_URL) ) {
      url = filename;
    } else if (mine) {
      // DEPRECATED
      url = `${environment.API_BASE_URL}private_sended_files/${this.user.id}/${this.userToTalk.id}/${filename}`;
    } else {
      // DEPRECATED
      url = `${environment.API_BASE_URL}private_sended_files/${this.userToTalk.id}/${this.user.id}/${filename}`;
    }

    window.open(url, '_blank');
  }

  downloadThisFile(mine = false) {
    let filename = '';
    if (this.messageRef.data && typeof this.messageRef.data === 'string') {
      filename = this.messageRef.data;
    } else {
      //filename = this.messageRef.data;
    }

    let url = '';
    if (mine) {
      url = `${environment.API_BASE_URL}private_sended_files/${this.user.id}/${this.userToTalk.id}/${filename}`;
    } else {
      url = `${environment.API_BASE_URL}private_sended_files/${this.userToTalk.id}/${this.user.id}/${filename}`;
    }

    // this.fileTransfer.downloadFile(url).subscribe(res => {
    //   console.log(res);
    //
    // });
    window.open(url, '_blank');
    // const fileTransfer: FileTransferObject = this.transfer.create();
    // fileTransfer.download(url, 'my.pdf').then((entry) => {
    //   console.log('download complete: ' + entry.toURL());
    // }, (error) => {
    //   // handle error
    // });
  }


  answerVideoCall(status, type = '', event: MouseEvent) {
    console.log('mensaje', this.msg)
    event.stopPropagation();

    let idMessage;
    // if (this.msg._id) {
    //   idMessage = this.msg._id;
    // } else {
    //   idMessage = this.msg.idMensaje
    // }
    this.videoCallAnswered.emit({status, type, msgId: idMessage});
  }

  answerCall(status, type = '') {
    event.stopPropagation();
    console.log('mensaje', this.msg)

    let idMessage;
    // if (this.msg._id) {
    //   idMessage = this.msg._id;
    // } else {
    //   idMessage = this.msg.idMensaje
    // }
    this.videoCallAnswered.emit({status, type, msgId: idMessage});
  }

  isMyMessage(): boolean {
    return (this.msg as ReceivedChatMessage).userId === this.user?.id;
  }

  stopPropagation($event) {
    $event.stopPropagation();
  }

  private decodeReply() {
    // const separator = '||replyMark||';

    // let message = this.msg.data || '';

    // const replyArray = (message as string).split(separator);
    // if (replyArray.length > 1) {
    //   this.replyData = JSON.parse((message as string).split(separator).slice(0, 1).join(separator));
    //   message = (message as string).split(separator).slice(1).join(separator);
    // }
  }

  getReplyContent() {
    if (this.messageRef.type === 'audio') {
      return 'Mensaje de voz';
    } else {
      return this.messageRef.data;
    }
  }

  emitScrollToMessage() {
    console.log('this.messageRef', this.messageRef);
    this.scrollToMessage.emit(this.messageRef.messageId);
  }

  getFileName(url: string) {
    return url.split('/').pop();
  }

  audioLoaded($event) {
    console.log('audioLoaded', this.msg, $event);
    this.audioDuration = $event;
    return;
    try {
      //this.audioDuration = new Date($event * 1000).toISOString().substring(14, 19);
      this.audioDuration = $event;
      console.log('audioLoaded', this.audioDuration, this.isVoiceRecording);
    } catch (error) {
      this.audioDuration = '00:00';
      console.log('ErrorAudioLoaded', error);
    }
    return;
    if (Number.isFinite($event)) {
      this.audioDuration = new Date($event * 1000).toISOString().substring(14, 19);
      console.log(this.audioDuration);
      console.log('audioLoaded', this.audioDuration, this.isVoiceRecording);

    } else {
      this.audioDuration = '00:00';
    }
  }
}
