export enum ContentType {
  LiveVideo = 'live-channel',
  LiveAudio = 'audio-room',
  OneVideo = 'one-video',
  OneAudio = 'one-podcast',
  Event = 'event',
  Premiere = 'premiere',
  Video = 'video',
  Audio = 'podcast',
  Image = 'image',
  Clip = 'clip'
}