import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { ContentInfoComponent } from '../content-info.component';

@Component({
  selector: 'app-static-content-info',
  templateUrl: './static-content-info.component.html',
  styleUrls: ['../content-info.component.scss', './static-content-info.component.scss'],
})
export class StaticContentInfoComponent extends ContentInfoComponent {

  constructor( protected modalCtrl: ModalController ) {
    super(modalCtrl);
  }

}
