<div class="audio-container">
  

  <div class="range">
       <!-- [ngClass]="{'playing': !audioIsPaused}"> -->

    <!-- <div id="waveform"></div> -->

    <ion-range
      #range
      min="0"
      [max]="audioDuration !== undefined ? audioDuration : 100"
      [value]="audioDuration !== undefined ? audioProgress : 0"
      color="primary"
      pin="false"
      mode="md"
      debounce="0"
      (touchstart)="pauseWhileSeeking()"
      (mousedown)="pauseWhileSeeking()"
      (touchend)="seek($event)"
      (ionChange)="seekChange($event)"
      (mouseup)="seek($event)">
    </ion-range>

    <div class="times">
      <div class="current">
        {{currentTimeString | duration}}
      </div>
      <div class="total" *ngIf="timeRemainingString !== 0">
        -<span>{{timeRemainingString | duration}}</span>
      </div>
    </div>
  </div>

  <div class="buttons">
    <ion-button
      fill="clear"
      class="speed-control"
      (click)="changePlaybackRate()">
      <ion-chip>
        <ion-label>{{playbackRate}}x</ion-label>
      </ion-chip>
    </ion-button>

    <ion-button
      fill="clear"
      class="seek-button"
      (click)="seekBy(-10)">
      <ion-icon src="assets/svg-icon/seek-10-backward.svg"></ion-icon>
    </ion-button>

    <ion-button
      fill="clear"
      class="play-button"
      aria-label="play"
      (click)="playPlauseAudio()">

      <ion-icon *ngIf="audioIsPaused" name="play"></ion-icon>
      <ion-icon *ngIf="!audioIsPaused" name="pause"></ion-icon>
    </ion-button>

    <ion-button
      fill="clear"
      class="seek-button"
      (click)="seekBy(10)">
      <ion-icon src="assets/svg-icon/seek-10-forward.svg"></ion-icon>
    </ion-button>

    <ion-button class="ghost-right" disabled></ion-button>
  </div>
</div>
