<div class="users">
  <p class="d-flex ion-justify-content-between">
    <span class="font-medium">Usuarios</span>
    <a
      style="cursor: pointer"
      (click)="navigateToViewAll()"
      class="font-medium color-gray"
      >Ver más</a
    >
  </p>
  <ul class="thumb-list">
    <div *ngIf="!recommendedUserList.length">
      <div *ngFor="let user of [0,1,2,3,4]">
        <div class="d-flex">
          <ion-skeleton-text
            [animated]="true"
            style="
              width: 2em;
              height: 2em;
              margin-right: 0.5em;
              border-radius: 0.2em;
            "
          ></ion-skeleton-text>
          <div>
            <ion-skeleton-text
              [animated]="true"
              style="width: 4em"
            ></ion-skeleton-text>
            <ion-skeleton-text
              [animated]="true"
              style="width: 7em"
            ></ion-skeleton-text>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="recommendedUserList.length">
      <div *ngFor="let user of recommendedUserList" 
        scoreDebug
        [debugDataId]="user.id"
        [debugCacheKey]="'FEED_CACHE_DEBUG_RECOMMENDED_LIST'">
        <li [class]="user.isPro ? 'pro' : ''">
          <a (click)="navigateToUserProfile(user.username)">
            <div class="thumb">
              <lazy-img
                alt="image"
                [size]="400"
                [source]="getImgProfile(user)"
                [ngClass]="{ pro: user.isPro }"
              ></lazy-img>
            </div>
            <div>
              <strong class="d-flex name">
                <span>{{ user.nameToShow }}</span>
                <img
                  handleError
                  width="10"
                  height="auto"
                  alt="Founder badge"
                  class="founder-badge"
                  *ngIf="user.isFounder"
                  src="assets/badges/founder-badge.svg"
                />
              </strong>
              <span class="username">@{{ user.username }}</span>
            </div>
          </a>
        </li>
      </div>
    </div>
  </ul>
</div>
