import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { ContentInfoComponent } from '../content-info.component';

@Component({
  selector: 'app-event-info',
  templateUrl: './event-info.component.html',
  styleUrls: ['../content-info.component.scss', './event-info.component.scss'],
})
export class EventInfoComponent extends ContentInfoComponent {

  constructor( protected modalCtrl: ModalController ) {
    super(modalCtrl);
  }

}
