<div class="months">
  <app-month
    *ngFor="let month of months"
    [moment]="month"
    [startDate]="startDate"
    [endDate]="endDate"
    (onDaySelected)="onDaySelected($event)"
  >
  </app-month>
</div>

<div class="selection" *ngIf="startDate" @inOutSelection>
  <div class="dates">{{formatDate(startDate)}} <ng-container *ngIf="endDate">- {{formatDate(endDate)}}</ng-container></div>
  <ion-button fill="clear" (click)="done()">Hecho</ion-button>
</div>