import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Category } from '../../interfaces/category';
import { MediaService } from '../../services/media.service';

@Component({
  selector: 'app-category-button',
  templateUrl: './category-button.component.html',
  styleUrls: ['./category-button.component.scss'],
})
export class CategoryButtonComponent implements OnInit {

  @Input() category: Category = undefined;
  @Input() active: boolean = false;
  @Input() fit: boolean = false;
  @Input() skeleton: boolean = false;

  @Output() categorySelected = new EventEmitter();

  mediaServ: MediaService;

  constructor( private mediaService: MediaService ) { 
    this.mediaServ = this.mediaService;
  }

  ngOnInit() {}

}
