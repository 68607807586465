import { NgModule } from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { NoAuthGuard } from './guards/no-auth.guard';
import { environment } from '../environments/environment';
import { RegisterComponent } from './pages/register/register.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [AuthGuard],
  },
  // {
  //   path: environment.APP_ROUTES.LOGIN,
  //   loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
  //   canActivate: [NoAuthGuard],
  // },
  {
    path: 'user',
    loadChildren: () => import('./pages/profile/profile-external.module').then(m => m.ProfileExternalModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'login/:register',
  //   loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
  //   canActivate: [NoAuthGuard]
  // },
  {
    path: environment.APP_ROUTES.LOGIN,
    loadChildren: () => import('./pages/login-v2/login-v2.module').then( m => m.LoginV2PageModule),
    canActivate: [NoAuthGuard]
  },
  {
    path: 'publications',
    loadChildren: () => import('./pages/publications/publications.module').then(m => m.PublicationsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'payment',
    loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'messages',
    loadChildren: () => import('./pages/messages/messages.module').then(m => m.MessagesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'verify-email',
    loadChildren: () => import('./pages/verify-email/verify-email.module').then(m => m.VerifyEmailPageModule)
  },
  {
    path: 'find-contacts',
    loadChildren: () => import('./pages/find-contacts/find-contacts.module').then(m => m.FindContactsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'all-categories',
    loadChildren: () => import('./pages/all-categories/all-categories.module').then(m => m.AllCategoriesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'recover-password/:email/:token',
    loadChildren: () => import('./pages/recover-password/recover-password.module').then(m => m.RecoverPasswordPageModule)
  },
  {
    path: 'invitations',
    loadChildren: () => import('./pages/invitations/invitations.module').then(m => m.InvitationsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'saved-content',
    loadChildren: () => import('./pages/saved-content/saved-content.module').then(m => m.SavedContentPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'storage',
    loadChildren: () => import('./pages/storage/storage.module').then(m => m.StoragePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'legal',
    loadChildren: () => import('./pages/legal/legal.module').then(m => m.LegalPageModule),
  },
  {
    path: 'user-guide',
    loadChildren: () => import('./pages/legal/legal.module').then(m => m.LegalPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'help',
    loadChildren: () => import('./pages/help/help.module').then(m => m.HelpPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'configuration',
    loadChildren: () => import('./pages/configuration/configuration.module').then(m => m.ConfigurationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactPageModule)
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'app-download',
    loadChildren: () => import('./pages/register/redirect/redirect.module').then(m => m.RedirectPageModule)
  },
  {
    path: 'register/:code',
    component: RegisterComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'registro/:code',
    component: RegisterComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: '**',
    redirectTo: 'tabs/home'
  },
  {
    path: 'wallet',
    loadChildren: () => import('./pages/wallet/wallet.module').then( m => m.WalletPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'enabled'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
