import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { QRCodeModule } from 'angularx-qrcode';

import { RedirectPageRoutingModule } from './redirect-routing.module';

import { RedirectPage } from './redirect.page';

import { ComponentsModule } from '../../../components/components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RedirectPageRoutingModule,
    ComponentsModule,
    QRCodeModule
  ],
  declarations: [RedirectPage]
})
export class RedirectPageModule {}
