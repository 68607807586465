<div class="section-header">
  <h4 class="section-title">Eventos</h4>
</div>

<!-- Filters -->
<div class="searchbar-filters">
  <app-searchbar 
    [clearButton]="'always'"
    [placeholder]="'Busca tu evento'"
    [autofocus]="false"
    (ionInput)="setSearchQuery($event)"
  >
  </app-searchbar>
  <app-filters-bar
    #filtersBar
    [filters]="filters"
    (triggerFilters)="showFilters()"
    (triggerDates)="showDates()"
    (setShortcutRange)="setShortcutRange($event)"
  >
  </app-filters-bar>
</div>

<!-- Featured events -->
<app-featured-events-section
  *ngIf="(eventsList.length || loading) && !filtersBar.filtersLength"
  [publications]="eventsList"
  [loading]="loading"
>
</app-featured-events-section>

<div class="list-header" *ngIf="!loading">
  <span class="quantity text-darkgray">{{ total | shortNumber }} eventos</span>

  <!-- <span class="sort">
    <ion-button
      fill="clear"
      (click)="presentOrderActionSheet()"
    >
      <span class="name">Ordenar por </span>
      <ion-icon name="chevron-down-outline" class="chevron"></ion-icon>
    </ion-button>
  </span> -->
</div>
<div class="list-header" *ngIf="loading">
  <ion-skeleton-text [animated]="true" class="rounded" style="width: 25%; margin: 1px 0;"></ion-skeleton-text>
</div>

<!-- Remaining events -->
<cdk-virtual-scroll-viewport
  #viewport
  *ngFor="let list of eventsCdkArray; let i = index"
  class="cdk-event-list"
  [ngClass]="{ even: i === 0, odd: i === 1 }"
  itemSize="{{ itemSize }}"
  minBufferPx="{{ minBufferPx }}"
  maxBufferPx="{{ maxBufferPx }}"
>
  <ng-container>
    <app-event-thumbnail-v2
      class="element"
      *cdkVirtualFor="let publication of eventsCdkLists[i]; let j = index"
      [publication]="publication"
      [user]="user"
      [showDots]="true"
      [skeleton]="loading"
      [calculateHeight]="i === 0 && j === 0"
      (heightCalculated)="onItemHeightCalculated($event, 6, 7, 12)"
    >
    </app-event-thumbnail-v2>
  </ng-container>
</cdk-virtual-scroll-viewport>

<div *ngIf="loading" class="list">
  <app-event-thumbnail-v2 class="element" [skeleton]="true"></app-event-thumbnail-v2>
  <app-event-thumbnail-v2 class="element" [skeleton]="true"></app-event-thumbnail-v2>
  <app-event-thumbnail-v2 class="element" [skeleton]="true"></app-event-thumbnail-v2>
  <app-event-thumbnail-v2 class="element" [skeleton]="true"></app-event-thumbnail-v2>
  <app-event-thumbnail-v2 class="element" [skeleton]="true"></app-event-thumbnail-v2>
  <app-event-thumbnail-v2 class="element" [skeleton]="true"></app-event-thumbnail-v2>
</div>
