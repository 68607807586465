import { Directive, OnInit, OnChanges, Input, ElementRef, SimpleChanges } from '@angular/core';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { MediaService } from '../services/media.service';

@Directive({
  selector: 'img[cache], ion-img[cache]'
})
export class CacheDirective implements OnInit, OnChanges {

  @Input() source: string;

  constructor(
    protected mediaService: MediaService,
    protected element: ElementRef
  ) { }

  ngOnInit() {}
  
  ngOnChanges(changes: SimpleChanges) {
    this.generateSrc();
  }

  private generateSrc() {
    // Prevent cache images from ui-avatars.com
    if ( this.source && this.source.includes('ui-avatars') ) {
      this.element.nativeElement.src = this.source;
    } else if ( this.source ) {
      let url = this.source;

      const imageName = url.split('/').pop();
      let fileType = imageName.split('.').pop();

      if ( fileType === 'svg' ) 
        fileType += '+xml';

      Filesystem.readFile({
        directory: Directory.Cache,
        path: `${this.mediaService.cacheFolderName}/${imageName}`
      }).then(file => {
        // Set src from local cache
        this.element.nativeElement.src = `data:image/${fileType};base64,${file.data}`;
      }).catch(async error => {
        // Set src from url
        this.element.nativeElement.src = url;
        // Cache image
        this.mediaService.cacheImage(url, imageName);
      })
    }
  }
}
