<div class="content">
  <div class="icon">
    <img src="assets/estrim/128/icon-dark.png" />
  </div>

  <div class="title">
    Actualiza tu aplicación a la última versión
  </div>

  <div class="subtitle">
    Una nueva versión de la app de Estrim está disponible en la 
    <span *ngIf="isIos">App Store</span>
    <span *ngIf="isAndroid">Play Store</span>
    <span *ngIf="!isIos && !isAndroid">tienda de aplicaciones</span>.
    Por favor, actualiza la app para poder seguir distrutando de tu contenido.
  </div>
</div>

<div class="buttons">
  <ion-button
    color="darkteal" 
    (click)="openStore()">
    Actualizar ahora
  </ion-button>
</div>