import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { StatusBar, Style } from '@capacitor/status-bar';
import { NavigationBar } from '@mauricewegner/capacitor-navigation-bar';

import { AppearenceService } from './appearence.service';

@Injectable({
   providedIn: 'root'
})
export class StatusbarService {

   constructor(
      private platform: Platform,
      private appearenceService: AppearenceService
   ) {
      this.appearenceService.prefersDarkStatus.subscribe(prefersDark => {
         if ( this.platform.is('hybrid') ) {
            prefersDark 
               ? this.setTextDark() 
               : this.setTextLight();
         }
      });
   }

   /**
    * Establece el color de fondo del statusbar de un determinado color
    * Compatible solo con dispositivos Android
    *
    * @param color En formato hexadecimal
    */
   async setBackgroundColor(color: string) {
      if ( this.platform.is('android') ) {
         await StatusBar.setBackgroundColor({ color });
      }
   }
   /**
    * Establece el color de texto del statusbar de color oscuro
    */
   async setTextDark(force: boolean = false, androidOnlyStatusbar: boolean = false) {
      if ( this.platform.is('hybrid') ) {
         const prefersDark = this.appearenceService.prefersDark;

         if ( force ) {
            await StatusBar.setStyle({ style: Style.Light });
            !androidOnlyStatusbar && this.platform.is('android') ? NavigationBar.setColor({ color: '#FFFFFF' }) : null;
            this.setBackgroundColor('#FFFFFF');
         } else if ( !prefersDark ) {
            await StatusBar.setStyle({ style: Style.Light });
            !androidOnlyStatusbar && this.platform.is('android') ? NavigationBar.setColor({ color: '#FFFFFF' }) : null;
            this.setBackgroundColor('#FFFFFF');
         } else {
            await StatusBar.setStyle({ style: Style.Dark });
            !androidOnlyStatusbar && this.platform.is('android') ? NavigationBar.setColor({ color: '#000000' }) : null;
            this.setBackgroundColor('#000000');
         }
      }
   }
   /**
    * Establece el color de texto del statusbar de color claro
    */
   async setTextLight(force: boolean = false, androidOnlyStatusbar: boolean = false) {
      if ( this.platform.is('hybrid') ) {
         const prefersDark = this.appearenceService;

         if ( force ) {
            await StatusBar.setStyle({ style: Style.Dark });
            !androidOnlyStatusbar && this.platform.is('android') ? NavigationBar.setColor({ color: '#000000' }) : null;
            this.setBackgroundColor('#000000');
         } else if ( !prefersDark ) {
            await StatusBar.setStyle({ style: Style.Dark });
            !androidOnlyStatusbar && this.platform.is('android') ? NavigationBar.setColor({ color: '#000000' }) : null;
            this.setBackgroundColor('#000000');
         } else {
            await StatusBar.setStyle({ style: Style.Light });
            !androidOnlyStatusbar && this.platform.is('android') ? NavigationBar.setColor({ color: '#FFFFFF' }) : null;
            this.setBackgroundColor('#FFFFFF');
         }
      }
   }
}
