<div class="resumen-container" *ngIf="transaction && !skeleton">
  <div class="profile-container"
    *ngIf="transaction.toUser !== undefined">
    <lazy-img
      [size]="400"
      class="profile-img"
      [source]="getImgProfile()"
      (click)="getGoToUserProfile(transaction)"
    >
    </lazy-img>
  </div>
  <div class="datos-resumen-user" *ngIf="transaction">
    <div
      *ngIf="(transaction.profit && transaction?.fromUser !== undefined) || (!transaction.profit && transaction.toUser !== undefined)"
      class="n-profile">
      {{transaction.profit ? transaction?.fromUser?.username : transaction?.toUser?.username}}
    </div>
    <div class="datetime">
      <div class="n-date">{{transaction.createdAt.date.replace(' ', 'T') | date:'dd/MM/yyyy'}}</div>
      <div class="n-hour">{{transaction.createdAt.date.replace(' ', 'T') | date:'H:mm'}}</div>
    </div>
    <div class="n-text" [innerHTML]="transactionContent"></div>
  </div>
  <div class="star-dat-container">
    <div class="value-star">
      <ng-container *ngIf="transaction.transactionKind !== 'withdraw'">
        {{transaction.profit ? transaction.quantity : -transaction.quantity}}
      </ng-container>
      <ng-container *ngIf="transaction.transactionKind === 'withdraw'">
        {{transaction.quantity / 2}}
      </ng-container>
      <span *ngIf="transaction.transactionKind === 'withdraw'">€</span>
    </div>
    <span *ngIf="transaction.transactionKind === 'donation'" class="icon-star"></span>
    <span *ngIf="transaction.transactionKind === 'payment'" class="money-icon"></span>
  </div>
</div>

<div class="resumen-container" *ngIf="!transaction || skeleton">
  <div class="profile-container">
    <ion-skeleton-text class="profile-img" [animated]="true"></ion-skeleton-text>
  </div>
  <div class="datos-resumen-user">
    <div class="n-profile">
      <ion-skeleton-text [animated]="true" style="width: 170px; margin-top: 10px; --border-radius: 5px;"></ion-skeleton-text>
    </div>
    <div class="datetime">
      <div class="n-date"><ion-skeleton-text [animated]="true" style="width: 100px; --border-radius: 5px;"></ion-skeleton-text></div>
      <div class="n-hour"><ion-skeleton-text [animated]="true" style="width: 60px; --border-radius: 5px;"></ion-skeleton-text></div>
    </div>
    <div class="n-text">
      <ion-skeleton-text [animated]="true" style="width: 150px; margin-bottom: 15px; --border-radius: 5px;"></ion-skeleton-text>
    </div>
  </div>
  <div class="star-dat-container">
    <div class="value-star">
      <ion-skeleton-text [animated]="true" style="width: 40px; --border-radius: 5px;"></ion-skeleton-text>
    </div>
  </div>
</div>
