import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[only-numeric]'
})
export class OnlyNumericDirective {

  constructor() { }

  @HostListener("keydown",  ['$event'])
  private onKeydown(event) {
    if ( event.key !== 'Backspace' && event.key !== 'Delete' && !(/^\d+$/.test(event.key)) ) {
      event.preventDefault();
    }
  }
}
