import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-dates',
  templateUrl: './dates.component.html',
  styleUrls: ['./dates.component.scss'],
})
export class DatesComponent  implements OnInit {

  @Input() startDate: moment.Moment | undefined;
  @Input() endDate: moment.Moment | undefined;

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}

  dismiss() {
    this.modalCtrl.dismiss();
  }

  datesSelected($event) {
    this.modalCtrl.dismiss($event, 'confirm')
  }
}
