<ion-content>
  <a (click)="dismiss()" class="close">
    <img src="assets/extrim/img/icons/close-white.svg" width="30" />
  </a>

  <section id="hero-er" class="section">
    <div class="content">
      <!-- <a class="go-back" (click)="dismiss()">
        <img class="d-block" src="assets/extrim/img/arrow-left.webp" width="100" />
      </a> -->
      <img
        class="logo"
        src="assets/extrim/img/logo.png"
        alt="Logo Extrim Race"
      />
      <div class="h3 font-venera">
        VOTA POR TU<br />PILOTO FAVORITO
        <a (click)="showInfo()">
          <img
            width="18"
            class="trigger-info"
            src="assets/extrim/img/icons/icon_info.svg"
            alt="Icon Info"
          />
        </a>
      </div>
      <div class="er-features">
        <div class="bordered-content">
          <img class="icon" src="assets/extrim/img/icons/i1.svg" alt="Icon 1" />
          <div>
            Vota y entra en el sorteo de uno de los 3 cascos Extrim Race firmado
            por todos los pilotos.
          </div>
        </div>
        <div class="bordered-content">
          <img class="icon" src="assets/extrim/img/icons/i2.svg" alt="Icon 2" />
          <div>Los 3 pilotos más votados recibirán un escudo de inmunidad.</div>
        </div>
        <div class="bordered-content">
          <img class="icon" src="assets/extrim/img/icons/i3.svg" alt="Icon 3" />
          <div>
            Los 3 pilotos menos votados contarán con un copiloto un tanto
            especial, un culturista de 120kg que les pondrá las cosas difíciles.
          </div>
        </div>
      </div>

      <a *ngIf="!loading" (click)="nextPage()" class="button w-full">VOTAR</a>
      <a *ngIf="loading" class="button w-full">
        <ion-spinner color="light" name="crescent"></ion-spinner>
      </a>
      <p>
        <a
          href="https://extrimrace.com"
          target="_blank"
          class="font-venera td-none"
          >¿QUÉ ES EXTRIM RACE?</a
        >
      </p>
    </div>
  </section>
</ion-content>
