import { Component, Input, OnDestroy } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';

import { User } from 'src/app/interfaces/user';

import { ExtrimState } from 'src/app/enums/extrim-state';

@Component({
  template: '',
})
export class BaseComponent implements OnDestroy {

  @Input() user: User;
  @Input() votedPilot: any;
  @Input() state: ExtrimState;

  pilots: any[] = [
    // { id: 1, name: 'DANI CLOS', image: 'assets/extrim/img/pilotos/dani-clos.webp' },
    // { id: 2, name: 'GEORGE SG', image: 'assets/extrim/img/pilotos/george.webp' },
    // { id: 3, name: 'MARÍA HERRERA', image: 'assets/extrim/img/pilotos/maria-herrera.webp' },
    // { id: 4, name: 'HECTOR EG', image: 'assets/extrim/img/pilotos/hector.webp' },
    // { id: 5, name: 'ANN SUKI', image: 'assets/extrim/img/pilotos/ann-suki.webp' },
    // { id: 6, name: 'AXEL ESPXNUR', image: 'assets/extrim/img/pilotos/axel.webp' },
    // { id: 7, name: 'CXSOUND', image: 'assets/extrim/img/pilotos/cxsound.webp' },
    // { id: 8, name: 'IRATI ETXANDI', image: 'assets/extrim/img/pilotos/irati.webp' },
    // { id: 9, name: 'VICESAT', image: 'assets/extrim/img/pilotos/vicesat.webp' },
    // { id: 10, name: 'MIGUEL ASSAL', image: 'assets/extrim/img/pilotos/miguel-assal.webp' },
    // { id: 11, name: 'PEDRITO GT', image: 'assets/extrim/img/pilotos/pedrigo-gt.webp' },
    // { id: 12, name: 'KIDI', image: 'assets/extrim/img/pilotos/kidi.webp' },
    // { id: 13, name: 'KIKE GTR', image: 'assets/extrim/img/pilotos/kikegtr.webp' },
    // { id: 14, name: 'DOMINGUERO', image: 'assets/extrim/img/pilotos/dominguero.webp' },
    // { id: 15, name: 'TORETE', image: 'assets/extrim/img/pilotos/torete.webp' },
    // { id: 16, name: 'KIDD KEO', image: 'assets/extrim/img/pilotos/kidd-keo.webp' },
    // { id: 17, name: 'NYNO VARGAS', image: 'assets/extrim/img/pilotos/nyno-vargas.webp' },
    // { id: 19, name: 'MAJES', image: 'assets/extrim/img/pilotos/majes.webp' },
    // { id: 20, name: 'BERTH', image: 'assets/extrim/img/pilotos/berth.webp' },
    // { id: 21, name: 'JUCA', image: 'assets/extrim/img/pilotos/juca.webp' },
    // { id: 22, name: 'BEA EGUIRAUN', image: 'assets/extrim/img/pilotos/bea-eguiraun.webp' },
    // { id: 23, name: 'REBE TXIKI', image: 'assets/extrim/img/pilotos/rebe-txiki.webp' },
    // { id: 24, name: 'GHOST DRIVER', image: 'assets/extrim/img/pilotos/ghost-driver.webp' }

  ]

  sub: any = {};

  constructor(
    protected nav: IonNav,
    protected modalCtrl: ModalController 
  ) { }

  ngOnDestroy(): void {
    Object.keys(this.sub).forEach(key => {
      this.sub[key]?.unsubscribe();
    });
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  next(page: any, user: User, votedPilot?: any, state?: ExtrimState) {
    this.nav.push(
      page, 
      {
        user,
        votedPilot,
        state
      }, 
      { mode: 'ios' }
    );
  }

  back() {
    this.nav.pop({ mode: 'ios' });
  }
}
