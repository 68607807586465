import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-content-info',
  template: ''
})
export class ContentInfoComponent {

  @Input() confirmButton: boolean = true;

  constructor( protected modalCtrl: ModalController ) { }

  /**
   * Confirm modal
   */
  confirm() {
    return this.modalCtrl.dismiss(null, 'confirm');
  }

  /**
   * Close modal
   */
  dismiss() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }
}
