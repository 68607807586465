import {Directive, HostListener, Input, OnInit, Renderer2} from '@angular/core';
import {DomController} from '@ionic/angular';

@Directive({
    selector: '[appHideHeader]'
})
export class HideHeaderDirective implements OnInit {
    @Input() header: any;
    @Input() content: any;
    @Input() tolerance: string;
    private lastY = 0;
    private isHidden: boolean;
    private paddingSet: boolean;

    constructor(private renderer: Renderer2, private domCtrl: DomController) {
        this.isHidden = false;
        this.paddingSet = false;
        this.tolerance = 'client-height';
    }

    ngOnInit(): void {
        this.header = this.header.el;
        // this.content = this.content.el;
        this.domCtrl.write(() => {
            this.renderer.setStyle(this.header, 'transition', 'margin-top 700ms');
        });
    }

    @HostListener('ionScroll', ['$event']) onContentScroll($event: any) {
        $event.preventDefault();
        this.doAction($event);
    }

    private doAction($event) {
        let marginTop = 0;
        if ($event.detail.scrollTop > this.lastY) {
            if ((this.tolerance === 'client-height' && $event.detail.scrollTop > this.header.clientHeight) || this.tolerance !== 'client-height') {
                if (this.isHidden) {
                    this.lastY = $event.detail.scrollTop;
                    return;
                }
                marginTop = -1 * (this.header.clientHeight);
            }
        } else if ($event.detail.scrollTop > this.header.clientHeight && $event.detail.scrollTop + this.header.clientHeight > this.lastY) {
            return;
        }

        if (marginTop === 0 && !this.isHidden) {
            this.lastY = $event.detail.scrollTop;
            return;
        }

        const margin = marginTop;
        this.domCtrl.write(() => {
            this.renderer.setStyle(this.header, 'margin-top', margin + (margin < 0 ? 'px' : ''));
            this.isHidden = margin < 0;
        });

        this.lastY = $event.detail.scrollTop;
    }
}
