import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

import { User } from '../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  private _webInitialized: boolean = false;

  constructor(
    private platform: Platform
  ) {}

  /**
   * Initialize Firebase Analytics (web only)
   * @param user 
   */
  initializeWeb(user: User | undefined) {
    if ( !this._webInitialized ) {
      FirebaseAnalytics.initializeFirebase({
        apiKey: "AIzaSyDEe-l8qOUwtPvDQAFxVtInZOHoT7WwKDM",
        authDomain: "aforum-324711.firebaseapp.com",
        projectId: "aforum-324711",
        storageBucket: "aforum-324711.appspot.com",
        messagingSenderId: "185217985479",
        appId: "1:185217985479:web:8645be74be2e54b638c7e2",
        measurementId: "G-LZ2MYPGMKQ"
      }).then(() => {
        this._webInitialized = true;
        this.configureFirebaseAnalytics(user);
      });
    } else {
      this.configureFirebaseAnalytics(user);
    }
  }

  /**
   * Configure Firebase Analytics
   * @param user current logged user
   */
  configureFirebaseAnalytics(user: User | undefined) {
    this.setUserId(user?.username);

    this.logEvent("app_start");

    FirebaseAnalytics.setCollectionEnabled({ enabled: true });
  }

  /**
   * Set user id
   * @param userId 
   */
  setUserId(userId: string | undefined) {
    userId && FirebaseAnalytics.setUserId({ userId: userId });
  }

  /**
   * Set screen name
   * @param name 
   */
  setScreenName(name: string) {
    // This function only works on Android and iOS
    if ( this.platform.is('hybrid') ) {
      FirebaseAnalytics.setScreenName({
        screenName: name,
        nameOverride: "Estrim",
      });
    }
  }

  /**
   * Log an event
   * @param name 
   * @param params 
   */
  logEvent(name: string, params: any = {}) {
    FirebaseAnalytics.logEvent({
      name: name, params: params
    });
  }
}
