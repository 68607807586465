<ion-toolbar>
  <ion-buttons slot="start">
    <!-- Ghost -->
    <ion-button class="ghost" *ngIf="!backButton"></ion-button>
    <!-- Back button -->
    <ion-button fill="clear" (click)="goBack()" *ngIf="backButton && hidebackButton">
      <ion-icon slot="icon-only" name="chevron-back"></ion-icon>
    </ion-button>
  </ion-buttons>
  <ion-title 
    class="ion-text-center" 
    *ngIf="!searchbar" 
    [innerHTML]="title">    
  </ion-title>
  <ion-title 
    class="ion-text-center" 
    *ngIf="searchbar"
    [ngClass]="{'hideRightButtons': hideRightButtons}">
    <app-searchbar
      [clearButton]="clearButton"
      (inputChange)="search.emit($event)"
      (clear)="null">
    </app-searchbar>
    <div class="filter-button" *ngIf="filterButton" (click)="filter.emit()">
      <ion-icon src="assets/svg-icon/filter.svg"></ion-icon>
    </div>
  </ion-title>
  <ion-buttons slot="end" *ngIf="!hideRightButtons">
    <!-- Ghost -->
    <ion-button class="ghost" *ngIf="!saveButton && !shareButton && !optionsButton && !menuButton"></ion-button>
    <!-- Save publication button -->
    <ion-button *ngIf="saveButton" (click)="save.emit()">
      <ion-icon slot="icon-only" [name]="saved ? 'bookmark' : 'bookmark-outline'"></ion-icon>
    </ion-button>
    <!-- Share publication button -->
    <ion-button *ngIf="shareButton" (click)="share.emit()">
      <ion-icon slot="icon-only" class="share" src="assets/item/share.svg"></ion-icon>
    </ion-button>
    <!-- Configure publication button -->
    <ion-button *ngIf="configureButton" (click)="configure.emit()">
      <ion-icon slot="icon-only" src="assets/svg-icon/setting-clear.svg"></ion-icon>
    </ion-button>
    <!-- Option button -->
    <ion-button class="reduced dots" *ngIf="optionsButton" (click)="showOptions.emit($event)">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.12 45.53">
        <g id="Layer_2" data-name="Layer 2">
          <g id="Capa_1" data-name="Capa 1">
            <g id="More">
              <circle cx="5.06" cy="40.47" r="5.06"/>
              <circle cx="5.06" cy="22.76" r="5.06"/>
              <circle cx="5.06" cy="5.06" r="5.06"/>
            </g>
          </g>
        </g>
      </svg>
    </ion-button>
    <!-- Lateral menu button -->
    <ion-button class="reduced dots" (click)="openLateralMenu()" *ngIf="menuButton">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.12 45.53">
        <g id="Layer_2" data-name="Layer 2">
          <g id="Capa_1" data-name="Capa 1">
            <g id="More">
              <circle cx="5.06" cy="40.47" r="5.06"/>
              <circle cx="5.06" cy="22.76" r="5.06"/>
              <circle cx="5.06" cy="5.06" r="5.06"/>
            </g>
          </g>
        </g>
      </svg>
    </ion-button>
  </ion-buttons>
</ion-toolbar>
