<div #scrollList 
  class="scroll-element-list" 
  *ngIf="categories"
  (scroll)="onListScroll($event)">

  <div class="nav-button-wrap nav-button-wrap-prev"
    *ngIf="isWeb && scrollPosition !== 'start'" 
    @inOutFade
    (click)="scrollLeft()">
    <i></i>
    <span class="tooltip">Anterior</span>
  </div>

  <div class="category-button">
    <ion-button
      fill="clear"
      [ngClass]="{ active: currentCategory === null, fit: true }"
      (click)="selectCategory(null)"
    >
      <span> Todo </span>
    </ion-button>
  </div>

  <app-category-button
    class="category-button"
    *ngFor="let cat of categories"
    [category]="cat"
    [active]="currentCategory === cat"
    [fit]="true"
    (click)="
      currentCategory === cat ? selectCategory(null) : selectCategory(cat)
    "
  >
  </app-category-button>

  <div class="nav-button-wrap nav-button-wrap-next"
    *ngIf="isWeb  && scrollPosition !== 'end'" 
    @inOutFade
    (click)="scrollRight()">
    <i></i>
    <span class="tooltip">Siguiente</span>
  </div>
</div>
