import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { SettingsService } from 'src/app/services/settings.service';
import { ProfileService } from 'src/app/services/profile.service';

import { ContentInfoComponent } from '../content-info.component';

import { ContentType } from '../../../enums/content-type';

@Component({
  selector: 'app-hub-info',
  templateUrl: './hub-info.component.html',
  styleUrls: ['../content-info.component.scss', './hub-info.component.scss'],
})
export class HubInfoComponent extends ContentInfoComponent {

  ContentType = ContentType;

  constructor( 
    protected modalCtrl: ModalController,
    private settingsService: SettingsService,
    private profileService: ProfileService
  ) {
    super(modalCtrl);
  }

  /**
   * Return type to open
   *
   * @param type Type of content to create
   */
  triggerCreateContent(type: ContentType) {
    if ( !this.profileService.checkUserValidated() ) {
      this.dismiss();
    }

    if ( type === ContentType.Premiere ) {
      this.settingsService.openFunctionNotAvailableModal('Los estrenos estarán disponibles en breve.');
      return; // TEMPORARY BREAK EXECUTION UNTIL EVENTS READY
    }

    return this.modalCtrl.dismiss({
      type
    }, 'confirm');
  }

}
