import { Component, OnDestroy, OnInit } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { environment } from '../../../../../environments/environment';
import { filter, take } from 'rxjs';

import { ExtrimService } from 'src/app/services/extrim.service';
import { MediaService } from 'src/app/services/media.service';

import { BaseComponent as ExtrimPollBaseComponent } from '../base.component';

import { InfoPage } from '../info/info.page';
import { InfoLivePage } from '../info-live/info-live.page';

import { VoteConfirmComponent } from '../vote-confirm/vote-confirm.component';

import { ExtrimState } from 'src/app/enums/extrim-state';

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.page.html',
  styleUrls: environment.isWeb ? ['./ranking.page.scss', '../../../../../assets/extrim/css/main.css', '../../../../../assets/extrim/css/main-desktop.css'] : ['./ranking.page.scss', '../../../../../assets/extrim/css/main.css'],
})
export class RankingPage extends ExtrimPollBaseComponent implements OnInit, OnDestroy {

  ExtrimState = ExtrimState;

  winners: any[] = [];
  losers: any[] = [];

  constructor(
    private extrimRace: ExtrimService,
    public mediaService: MediaService,
    protected nav: IonNav,
    protected modalCtrl: ModalController 
  ) {
    super(nav, modalCtrl)
  }

  ngOnInit(): void {
    this.sub.ranking = this.extrimRace.ranking
    .pipe(
      filter(data => !!data),
    ).subscribe(data => {
      this.pilots = data.filter(pilot => !pilot.winner && !pilot.loser);
      this.pilots.sort((a,b) => b.percent - a.percent);

      this.winners = data.filter(pilot => pilot.winner);
      this.losers = data.filter(pilot => pilot.loser);
    })
  }

  showInfo() {
    if ( this.state === ExtrimState.Live ) {
      this.next(InfoLivePage, this.user);
    } else {
      this.next(InfoPage, this.user);
    }
  }

  async confirmVote(pilot: any) {
    if ( this.state === ExtrimState.BeforeRace || this.state === ExtrimState.Live ) {
      const modal = await this.modalCtrl.create({
        cssClass: "fit-modal floating-modal box-shadow-none",
        component: VoteConfirmComponent,
        componentProps: { pilot, state: this.state },
        mode: "ios",
      });
      modal.present();
      modal.onWillDismiss().then(result => {
        if ( result.role === 'confirm' ) {
          this.votedPilot = result.data;

          this.pilots.forEach(pilot => pilot.voted = false);
          this.pilots.find(pilot => pilot.name === this.votedPilot.name)
            .voted = true;
          
          this.extrimRace.getRanking().subscribe();
        }
      })
    }
  }
}
