<div class="content" 
  pinch
  (click)="!loading ? activateOverlay() : null"
  [ngClass]="{'maximized': maximized}"
  (pinchIn)="onPinchIn()"
  (pinchOut)="onPinchOut()">

  <div class="range" *ngIf="((overlayActivated || seeking) && (overlay || keepRangeOverlay)) || rangeIndicatorAlwaysVisible" @inOutFade>
    <ion-range 
      min="0"
      color="primary" 
      step="0.0001"
      pin="false"
      mode="md"
      [ngClass]="{'overlay': (overlayActivated || seeking)}"
      [max]="duration !== undefined ? duration : 100"
      [value]="duration !== undefined ? currentRangeTime : 0"
      (ionInput)="generateSeekingTo($event)"
      (touchstart)="seeking = true"
      (mousedown)="seeking = true"
      (touchend)="seek($event)"
      (mouseup)="seek($event)">    
    </ion-range>
  </div>

  <div class="loading" *ngIf="loading && !disableLoadingSpinner">
    <ion-spinner name="crescent"></ion-spinner>
  </div>

  <div class="video-overlay" *ngIf="(overlayActivated || seeking) && layoutButtons && (overlay || keepPlayPauseOverlay)" @inOutFade>
    <ion-icon *ngIf="playing" name="pause-outline" id="pause" (click)="pause()"></ion-icon>

    <ion-icon *ngIf="!playing" name="play" id="play" (click)="play()"></ion-icon>

    <ng-container>
      <div class="timeMark" *ngIf="!rangeWithoutTime">{{currentTime | duration}} / {{duration | duration}}</div>

      <div class="seek-time" *ngIf="seeking">
        {{seekTime | duration}}
      </div>

      <div class="buttons">
        <ion-button 
          fill="clear" 
          class="maximize"
          *ngIf="canMaximize" 
          (click)="maximize()">
          <ion-icon name="expand-outline" *ngIf="!maximized"></ion-icon>
          <ion-icon name="contract-outline" *ngIf="maximized"></ion-icon>
        </ion-button>
      </div>
    </ng-container>
  </div>

  <div class="video-seek" *ngIf="seekButtons && overlay">
    <div class="seek-button backward" 
      double-tap 
      (dobleTapTrigger)="seekBackward()">
      <div class="area" [ngClass]="{'trigger': backwardSeekTrigger}">
        <ion-icon src="assets/svg-icon/seek-10-backward.svg"></ion-icon>
      </div>
    </div>

    <div class="seek-button forward" 
      double-tap 
      (dobleTapTrigger)="seekForward()">
      <div class="area" [ngClass]="{'trigger': forwardSeekTrigger}">
        <ion-icon src="assets/svg-icon/seek-10-forward.svg"></ion-icon>
      </div>
    </div>
  </div>

  <lazy-img
    class="thumb"
    *ngIf="poster && loading"
    alt="image"
    [size]="600"
    [source]="poster"
  >
  </lazy-img>

  <video #videoElement 
    class="video-owner"
    overrideVideoPoster
    playsinline
    preload="{{preload}}"
    [ngClass]="{'fit': fit}" 
    [autoplay]="autoplay"
    [loop]="loop"
    [src]="videoSrc"
    (timeupdate)="updateCurrentTime()" 
    (canplay)="setDuration(); updateCurrentTime()" 
    (pause)="pause()"
    (ended)="pause()"
    (playing)="onPlaying()"
    (loadeddata)="onLoadedData($event)"
    (loadedmetadata)="onLoadedMetadata($event)">
  </video>
</div>
