import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { ContentInfoComponent } from '../content-info.component';

@Component({
  selector: 'app-premiere-info',
  templateUrl: './premiere-info.component.html',
  styleUrls: ['../content-info.component.scss', './premiere-info.component.scss'],
})
export class PremiereInfoComponent extends ContentInfoComponent {

  constructor( protected modalCtrl: ModalController ) {
    super(modalCtrl);
  }

}
