<ion-content class="ion-padding" scroll-y="false">
  <div class="content">
    <div class="blur top"></div>
    <div class="blur bottom"></div>
    <ion-icon class="logo" src="assets/estrim/logo.svg"></ion-icon>
    <div class="centered-section p-desktop-0">
      <div class="title mb-desktop-4">¡Enhorabuena!</div>
      <p>
        Has finalizado el registro correctamente. Recibirás
        <b>10 Estrellas (0,20€) y 2 puntos de experiencia</b>
        cuando tu amig@ se registre en Estrim.
      </p>
    </div>

    <!-- Submit button -->
    <div class="submit-button">
      <ion-button fill="clear" (click)="goHome()"> ¡Empezar! </ion-button>
    </div>
  </div>
</ion-content>
