<ion-split-pane id="desktop-layout" contentId="main" [ngClass]="{'onLogin': onLoginPage}">
  <ion-menu [disabled]="!showLayout()" contentId="main">
    <app-sidebar *ngIf="showLayout()" />
  </ion-menu>
  <div class="ion-page" id="main">
    <app-k-header-desktop *ngIf="showLayout()" (publicationCreated)="loggedActions()"></app-k-header-desktop>
    <ion-content scrollY="false">
      <ion-router-outlet
        [animated]="isIOSApp()"
        mode="ios"
        id="main-content"
      ></ion-router-outlet>
    </ion-content>
  </div>
</ion-split-pane>
