import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { User } from '../../interfaces/user';
import { MediaService } from '../../services/media.service';
import { ProfileService } from '../../services/profile.service';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../environments/environment';
import { AlertController } from '@ionic/angular';
import { Events } from '../../services/events.service';
import {Router} from '@angular/router';
import {goToUserProfile} from '../../utils/routing';

@Component({
  selector: 'app-user-recommendation-thumbnail',
  templateUrl: './user-recommendation-thumbnail.component.html',
  styleUrls: ['./user-recommendation-thumbnail.component.scss'],
})
export class UserRecommendationThumbnailComponent implements OnInit {
  @Input() user: User;
  @Input() listMode = false;
  @Input() removeButton = true;
  @Input() skeleton = false;

  @Output() removeSuggestion = new EventEmitter();
  following = false;

  removingUser: boolean = false;

  constructor(private mediaService: MediaService, private profileService: ProfileService, private authService: AuthService,
              private alertCtrl: AlertController, private events: Events,
              private router: Router, private cdRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    if ( !this.skeleton ) {
      this.following = this.user.following;
    }
  }

  getImgProfile() {
    return this.mediaService.generateImgProfileURL(this.user.id, this.user.imgProfile);
  }

  followUser() {
    this.following = true;

    this.profileService.follow(this.user.id, true).subscribe(
    (data) => {
      this.following = data.following;
      this.cdRef.detectChanges();
    }, (error) => {
      this.following = !this.following;
      this.cdRef.detectChanges();
    });
  }

  unfollowUser() {
    this.following = false;

    this.profileService.follow(this.user.id, false).subscribe(
    (data) => {
      this.following = data.following;
      this.cdRef.detectChanges();
    }, (error) => {
      this.following = !this.following;
      this.cdRef.detectChanges();
    });
  }


  followOrUnfollow(e) {
    e.stopPropagation();
    if (this.following) {
      this.unfollowUser();
    } else {
      this.followUser();
    }
  }

  getBadgeSrc() {
    return this.mediaService.getBadgeSrc(this.user);
  }

  getRouteUserProfile() {
    this.router.navigate([goToUserProfile(this.router.url, this.user.username)]);
  }

  removeUserSuggestion(e) {
    this.removingUser = true;

    this.profileService.removeUserSuggestion(this.user.id).subscribe(
      res => {
        if (res.done) {
          this.removeSuggestion.emit(this.user.id);
        } else {
          this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, res.msg);
        }

        this.removingUser = false;
        this.cdRef.detectChanges();
      }, error => {
        this.removingUser = false;
        this.cdRef.detectChanges();
      });
  }
}
