import {Component, ElementRef, HostListener, Injector, NgZone, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {
  ActionSheetController, AlertController,
  IonContent,
  IonRouterOutlet,
  MenuController,
  ModalController, NavController,
  Platform,
  PopoverController,
  ToastController
} from '@ionic/angular';
import { MetaTagsService } from './services/meta-tags.service';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token
} from '@capacitor/push-notifications';
import { Events } from './services/events.service';
import { environment } from '../environments/environment';
import { AuthService } from './services/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Preferences } from '@capacitor/preferences';
import { ProfileService } from './services/profile.service';
import { NotificationService } from './services/notification.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { SettingsService } from './services/settings.service';
import { FeedService } from './services/feed.service';
import {goToUserProfile} from './utils/routing';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { NgxCookiebotService } from '@halloverden/ngx-cookiebot';
import { NavigationBar } from '@mauricewegner/capacitor-navigation-bar';
import { StatusbarService } from './services/statusbar.service';
import { ScreenOrientationService } from './services/screen-orientation.service';
import { Network } from '@capacitor/network';
import { InAppPurchaseService } from './services/in-app-purchase.service';
import { filter, skipWhile, take} from 'rxjs/operators';
import { UserService } from './services/user.service';
import { AnalyticsService } from './services/analytics.service';
import { User } from './interfaces/user';
import { WebsocketIoService } from './services/websocket-io.service';
import { OnLiveRecordedEvent } from './interfaces/websocket-io-events';
import { CreateContentService } from './services/create-content.service';
import { FormMode } from './enums/form-mode';
import { ContentType } from './enums/content-type';
import { CacheData } from './enums/cache-data';
import { AppearenceService } from './services/appearence.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  @ViewChild(IonRouterOutlet, {static: true}) routerOutlet: IonRouterOutlet;

  sub: any = {};
  private cookieBotService;

  private lastTimeBackPress = 0;
  private timePeriodToExit = 2000;

  constructor(
    private metas: MetaTagsService,
    private menuCtrl: MenuController,
    private events: Events,
    private toast: ToastController,
    public auth: AuthService,
    private notificationService: NotificationService,
    public profileService: ProfileService,
    private router: Router,
    private platform: Platform,
    private actionSheetCtrl: ActionSheetController,
    private popoverCtrl: PopoverController,
    public modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private settingsService: SettingsService,
    private feedService: FeedService,
    private navCtrl: NavController,
    private injector: Injector,
    private iap: InAppPurchaseService,
    private statusbar: StatusbarService,
    private screenOrientation: ScreenOrientationService,
    private elementRef: ElementRef,
    private zone: NgZone,
    private userService: UserService,
    private analyticsService: AnalyticsService,
    private websocketIoService: WebsocketIoService,
    private createContentService: CreateContentService,
    private appearenceService: AppearenceService
  ) {
    this.initializeApp();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    this.cleanTempCachedData();
  }

  isIOSApp() {
    return this.platform.is('ios');
  }

  private initializeApp() {
    this.elementRef.nativeElement.removeAttribute('ng-version');
    this.deepLinks();
    if (this.auth.isLoggedIn()) {
      this.feedService.loadCachedHomeContent();
      this.auth.updateConnectionCountry().subscribe();
    }
    if (!this.platform.is('hybrid') && environment.isWeb) {
      this.cookieBotService = this.injector.get<any>(NgxCookiebotService);
    }
    this.listenToEvents();
    this.registerNotifications();
    this.registerBackButton();
    this.platform.ready().then(() => {
      if ( this.platform.is('android') ) {
        NavigationBar.setColor({ color: '#FFFFFF', darkButtons: true });
      }

      if (Capacitor.isNativePlatform()) {
        const setStatusBarStyleDark = async () => {
          await StatusBar.setStyle({style: Style.Light});
        };

        setStatusBarStyleDark();
      }
      this.onPlatformReady();
    });

    /*
     * Subscribe to router events to set correct statusbar
     * style for each page on iOS devices
     */
    this.router.events.subscribe((route) => {
      const prefersDark = this.appearenceService.prefersDark;

      if ( !(route instanceof NavigationEnd && this.platform.is('hybrid')) ) {
        return ; // Only interested on navigation end and events on hybrid devices
      } else if ( (route.url.includes('publications') || route.url.includes('clip')) && !route.url.includes('view-all') ) {
        // Set statusbar light
        route.url.includes('publications')
          ? this.statusbar.setTextLight(true, true)
          : this.statusbar.setTextLight(true);
          
      } else if ( this.platform.is('ios') && (route.url.includes('profile') || route.url.includes('user')) && !route.url.includes('other-users') ) {
        let profileContent: IonContent = undefined;

        // Get profile content page
        if ( route.url.includes('user') ) {
          const profiles: HTMLCollection = document.getElementsByClassName('profile-content');
          // @ts-ignore
          profileContent = profiles[profiles.length - 1] as IonContent
        } else {
          const profile: HTMLCollection = document.getElementsByClassName('my-profile');
          // @ts-ignore
          profileContent = profile[0] as IonContent
        }
        
        /*
         * Statusbar style on profile depends on its current scroll,
         * below 135px statusbar should be light and over that point
         * it should be dark
         */

        setTimeout(() => {
          // Wait needed to ensure correct value
          profileContent?.getScrollElement().then(data => {
            data.scrollTop > 135 
              ? this.statusbar.setTextDark()
              : this.statusbar.setTextLight(true);
          });
        }, 300);
      } else if ( prefersDark ) {
        // Set statusbar dark
        this.statusbar.setTextDark();
      } else {
        // Set statusbar light
        this.statusbar.setTextDark();
      }
    });

    this.auth.watchUser()
      .pipe(filter(data => !!data))
      .subscribe((user: User) => {
        this.analyticsService.setUserId(user.username);
      });
    
    if ( !this.platform.is('hybrid') ) {
      this.analyticsService.initializeWeb(undefined);
    } else {
      this.analyticsService.configureFirebaseAnalytics(undefined);
    }

    // Subscribe to live record events to trigger publication create
    this.websocketIoService.onLiveRecorded.subscribe((data: OnLiveRecordedEvent) => {
      this.createContentService.openCreateStaticContent(
        data.publication.onlyAudio ? ContentType.Audio : ContentType.Video, 
        FormMode.Create, 
        undefined, 
        data.publication,
        data.url,
        data.size,
        data.duration,
        true
      );
    })
  }

  private onPlatformReady() {
    if (!this.platform.is('hybrid') && environment.isWeb) {
      const cookiesAccepted = this.auth.areCookiesAccepted();
      if (!cookiesAccepted) {
        this.cookieBotService.onServiceReady$.pipe(
          filter((ready: boolean) => ready)
        ).subscribe(() => {
          this.cookieBotService.onAccept$.subscribe(() => {
            this.auth.setCookiesAccepted(true);
          });

          this.cookieBotService.onDecline$.subscribe(() => {
            this.auth.setCookiesAccepted(false);
          });
        });
      }
    }
    if (!this.platform.is('tablet') && this.platform.is('hybrid')) {
      this.screenOrientation.lockPortrait();
    }

    // this.events.subscribe(environment.EVENTS.TOGGLE_FULLSCREEN, res => {
    //   if (res) {
    //     this.screenOrientation.unlock();
    //   } else if (!this.platform.is('tablet') && this.platform.is('hybrid')) {
    //     this.screenOrientation.lockPortrait();
    //   }
    // });

    this.platform.pause.subscribe(async () => {
      this.events.publish(environment.EVENTS.MINIMIZE_APP);
    });

    this.platform.resume.subscribe(async () => {
      if ( this.auth.isLoggedIn() ) {
        this.settingsService.checkCurrentVersion();
        this.notificationService.watchCacheData(CacheData.Notifications, true).subscribe();
        this.userService.checkGamificationEvents();
        this.events.publish(environment.EVENTS.RESUME_APP);
      }
    });
  }

  private listenToEvents() {
    this.events.subscribe(environment.EVENTS.USER_LOGIN, (destination: string = environment.APP_ROUTES.HOME) => {
      this.navCtrl.navigateRoot(destination, { animationDirection: 'forward' });
    });

    this.events.subscribe(environment.EVENTS.USER_LOGOUT, () => {
      this.logoutActions();
      this.navCtrl.navigateRoot('/login', { animationDirection: 'back' });
    });

    this.events.subscribe(environment.EVENTS.ALERT_TOAST_ERROR, (
      msg: string = 'Ocurrió un error inesperado. Por favor reporta el error a soporte@estrim.com',
      duration: number = 2500,
      position: string = 'bottom') => {
      if (!msg) {
        msg = 'Ocurrió un error inesperado. Por favor reporta el error a soporte@estrim.com';
      }

      return this.presentToast(msg, duration, position, 'error');
    });

    this.events.subscribe(environment.EVENTS.ALERT_TOAST_NOTICE,
      (msg: string, duration: number = 2500, position: string = 'bottom') => this.presentToast(msg, duration, position, 'notice'));

      this.events.subscribe(environment.EVENTS.ALERT_TOAST,(data) => {
        !data[0].duration ? data[0].duration = 2500 : null;
        !data[0].position ? data[0].position = 'top' : null;
        !data[0].type ? data[0].type = 'notice' : null;

        this.presentToast(data[0].msg, data[0].duration, data[0].position, data[0].type)
      });

    this.events.subscribe(environment.EVENTS.USER_UPDATED, () => {
    });
  }

  private async registerNotifications() {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive === 'granted') {
      PushNotifications.register();
    }

    PushNotifications.addListener('registration',
      (token: Token) => {
        this.auth.setDevice(token.value);
      }
    );

    PushNotifications.addListener('registrationError',
      (error: any) => {
        console.error('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log('Notification received (open app): ' + JSON.stringify(notification), this.router.url);
        const data: any = notification.data;
        if (data.element_type === 'private_chat') {
          return;
        }

        this.notificationService.watchCacheData(CacheData.Notifications, true).subscribe();
        this.userService.checkGamificationEvents();
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
        const extra = notification.notification.data;
        if (!extra) {
          return;
        }

        this.goToNotificationElement(extra.element_type, extra.element_id);
      }
    );
  }

  private goToNotificationElement($type: string, id: any) {
    const DEFAULT_GO_TO_PAGES = '/notifications';

    const GO_TO_PAGES: Record<string, string> = {
      collaborator: '/notifications',
      eventCollaborator: '/notifications',
      follower: goToUserProfile('home', id),
      subscription: goToUserProfile('home', id),
      purchase: '/payment/my-wallet',
      live: '/publications/view/' + id,
      event: '/publications/pre-event/' + id,
      private_chat: '/messages/chat-with/' + id,
      premiere: '/publications/pre-event/' + id,
      donation: '/payment/my-wallet',
    };

    const page = GO_TO_PAGES[$type] || DEFAULT_GO_TO_PAGES;

    this.router.navigate([page]);

    this.platform.ready().then(async () => {
      // Oculta el splash screen despues de haber cargado la APP
      await SplashScreen.hide();
    });
  }

  private async presentToast(msg: string, tDuration: number, tPosition, mType: string, closable: boolean = true) {
    let color = 'dark';

    switch ( mType ) {
      case 'error': color = 'danger'; break;
      case 'notice': color = 'dark'; break;
      case 'warning': color = 'warning'; break;
    }
    const mToast = await this.toast.create({
      message: msg,
      position: tPosition,
      buttons: closable ? [{icon: 'close-circle', role: 'cancel'}] : undefined,
      duration: tDuration,
      color: color,
      mode: this.platform.is('android') ? 'md' : 'ios'
    });

    return mToast.present();
  }

  private registerBackButton() {
    this.platform.backButton.subscribeWithPriority(11, async (processNextHandler) => {
      await this.backButtonEvent(event, processNextHandler);
    });
  }

  private async backButtonEvent(event, processNextHandler) {
    this.events.publish(environment.EVENTS.BACK_BUTTON_PRESSED);

    try {
      const element = await this.actionSheetCtrl.getTop();
      if (element) {
        await element.dismiss();
        return;
      }
    } catch (error) {}

    //close alert
    try {
      const element = await this.alertCtrl.getTop();
      if (element) {
        await element.dismiss();
        return;
      }
    } catch (error) {}

    const menuOpened = await this.menuCtrl.isOpen('lateral');
    if (menuOpened) {
      await this.menuCtrl.close('lateral');
    } else if (this.router.url === '/home') {
      if (new Date().getTime() - this.lastTimeBackPress < this.timePeriodToExit) {
        this.cleanTempCachedData();
        App.exitApp();
      } else {
        await this.presentToast('Presiona atrás otra vez para cerrar la app.', 2500, 'center', 'notice');
        this.lastTimeBackPress = new Date().getTime();
      }
    }

    processNextHandler();
  }

  private logoutActions() {
    this.closeMenu();
    this.auth.logout();
    this.cleanCachedData();
  }

  private async closeMenu() {
    await this.menuCtrl.enable(true, 'lateral');
    const opened = await this.menuCtrl.isOpen('lateral');
    if (opened) {
      await this.menuCtrl.close('lateral');
    }
  }

  private cleanTempCachedData() {
    Preferences.keys().then(({keys}) => {
      keys.forEach(key => {
        if (key.includes('TEMP')) {
          Preferences.remove({key});
        }
      });
    });
  }

  private cleanCachedData() {
    Preferences.keys().then(({keys}) => {
      keys.forEach(key => {
        if (!key.includes('NODELETE')) {
          Preferences.remove({key});
        }
      });
    });
  }

  private deepLinks(){
    App.addListener('appUrlOpen', ( event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        //TODO: check if domain is OK
        const domain = 'estrim.com';
        const pathArray = event.url.split(domain);
        const appPath = pathArray.pop();
        if(appPath){
          this.router.navigateByUrl(appPath);
          
          this.platform.ready().then(async () => {
            // Oculta el splash screen despues de haber cargado la APP
            await SplashScreen.hide();
          });
        }
      });
    });
  }
}
