import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CategoryButtonComponent } from './category-button.component';
import { IonicModule } from "@ionic/angular";

import { DirectivesModule } from '../../directives/directives.module';

@NgModule({
  declarations: [ CategoryButtonComponent ],
  imports: [
    CommonModule,
    IonicModule,
    DirectivesModule
  ],
  exports: [ CategoryButtonComponent ]
})
export class CategoryButtonModule { }
