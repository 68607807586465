import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {IonContent, Platform} from '@ionic/angular';
import {NavigationEnd, Router} from '@angular/router';
import {WIDTH_TO_SHOW_DESKTOP_LAYOUT} from '../../utils/layout';
import { AuthService } from '../../services/auth.service';
import { environment } from 'src/environments/environment';

const ROUTES_OMITTING = [
  '/login', '/register','/contact', '/user-guide', '/help', '/recover-password'
];

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
})
export class AppLayoutComponent implements OnInit {
  @ViewChild('homeContent') homeContent: IonContent;
  showDesktopLayout = environment.isWeb ? window.innerWidth >= WIDTH_TO_SHOW_DESKTOP_LAYOUT : false;
  thisPageMustShowLayout = true;
  public isLoggedIn: boolean;

  onLoginPage: boolean = false;

  constructor(private platform: Platform,
              private router: Router,
              public auth: AuthService,
  ) {

    if (!this.auth.isLoggedIn()) {
      ROUTES_OMITTING.push('/legal');
    }
    this.router.events.subscribe((event) => {
      if ( event instanceof NavigationEnd  || 'urlAfterRedirects' in event) {
        this.onLoginPage = event.url.includes('/login');

        this.thisPageMustShowLayout = !ROUTES_OMITTING.find(r => {
          const patron = new RegExp(`^\\` + r + `.*$`);
          return r === event.urlAfterRedirects || patron.test(event.urlAfterRedirects);
        });
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if ( environment.isWeb ) {
      if(this.showDesktopLayout && window.innerWidth >= WIDTH_TO_SHOW_DESKTOP_LAYOUT) {return;}
      if(!this.showDesktopLayout && window.innerWidth < WIDTH_TO_SHOW_DESKTOP_LAYOUT) {return;}
      this.showDesktopLayout = window.innerWidth >= WIDTH_TO_SHOW_DESKTOP_LAYOUT;
    }
  }

  ngOnInit() {
  }

  showLayout() {
    return this.showDesktopLayout && this.thisPageMustShowLayout;
  }


  isIOSApp() {
    return this.platform.is('ios');
  }

  loggedActions() {

  }

}
