import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { KComponent } from '../k.component';
import { ValidatorService } from '../../services/validator.service';
import { Debouncer } from './debouncer';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
})
export class FormInputComponent extends KComponent implements OnInit {
  @Input() kLabel = '';
  @Input() kName = '';
  @Input() kFormGroup: UntypedFormGroup;
  @Input() kType = 'text';
  @Input() kReadOnly = false;
  @Input() kValue: any = '';
  @Input() kPosition = 'floating';
  @Input() hasDebouncer = false;
  @Input() hasKeyUp = false;
  @Input() debouncer: Debouncer;
  @Input() debouncerDelay: number;
  @Input() debouncerFunction: string;
  @Input() lowerCase: boolean;
  @Input() kClass: boolean;

  @Output() checkMethod = new EventEmitter<string>();
  @Output() focus = new EventEmitter();

  constructor(private vs: ValidatorService, private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    if (this.hasDebouncer) {
      const debouncer = new Debouncer(this.debouncerFunction, this.debouncerDelay);
      this.vs.registerDebouncer(debouncer, this.kName, this.kFormGroup.get(this.kName));
    }
    if (this.kType !== 'checkbox') {
      this.kFormGroup.get(this.kName).setValue(this.kValue);
    }


  }

  get validatorService() {
    return this.vs;
  }

  checkFieldValid(formControl: AbstractControl) {
    return this.vs.checkFieldValid(formControl);
  }

  checkFieldInvalid(formControl: AbstractControl) {
    return this.vs.checkFieldInvalid(formControl);
  }

  get loading() {
    return this.vs.debouncers[this.kName]?.loading;
  }

  get errorMessage() {
    const formControl = this.kFormGroup.get(this.kName);
    const errors = formControl.errors;
    let message = '';

    if (formControl) {
      if (errors) {
        const customError = this.vs.customErrors[this.kName]?.[Object.keys(errors)[0]];
        if (customError) {
          message = customError;
        } else {
          message = this.vs.defaultErrors[Object.keys(errors)[0]];
        }
      }
    }
    return message;
  }

  triggerDatetime() {
    document.getElementById('open-form-input-datetime-dialog').click();
  }

  datetimeChange($event) {
    this.cdRef.detectChanges();
  }
}
