import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[overrideVideoPoster]'
})
export class VideoPosterDirective {

  constructor( private element: ElementRef ) {
    this.element.nativeElement.poster = "assets/no-video-black.jpg";
  }

}
