<aside id="sidebar">
  <div>
    <!-- Extrim Race pages -->
    <!-- <h4 class="section-title">Vota</h4>
    <ul class="icon-list">
      <li>
        <a class="extrim-race-link" (click)="launchExtrimRacePoll()">
          <ion-icon src="assets/extrim/img/x.svg"></ion-icon>
          <label>Extrim Race</label>
          <ion-spinner name="crescent" color="primary" *ngIf="loadingExtrimRacePool"></ion-spinner>
        </a>
      </li>
    </ul> 

    <hr /> -->

    <!-- EXPLORE SECTION  -->
    <app-sidebar-items-menu />
    <hr />
    <!-- RECOMENDADOS SECTION -->
    <div class="sidebar-section">
      <h4 class="section-title">Recomendados</h4>
      <app-sidebar-recommended />
      <app-sidebar-video-channels />
      <app-sidebar-audio-rooms />
    </div>
  </div>
</aside>
