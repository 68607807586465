import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators';
import { Preferences } from '@capacitor/preferences';

import { JsonApiResponse } from '../interfaces/JsonApiResponse';

@Injectable({
  providedIn: 'root'
})
export class EstrimFestService {

  getDataUrl = 'api/auth/user/estrim-fest';

  constructor(
    protected http: HttpClient
  ) { }

  public generateAbsoluteAPIURL($tail: string) {
    return environment.API_BASE_URL + $tail;
  }

  getData() {
    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.getDataUrl), {});
  }
}
