import { Injectable } from '@angular/core';
import { JsonApiResponse } from '../interfaces/JsonApiResponse';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { KService } from './k.service';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { User } from '../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class LiveKitService extends KService {
  public getLivekitTokenUrl = 'api/auth/user/livekit/token/unique';
  public getLivekitTokenPreviewUrl = 'api/auth/user/livekit/token/preview';
  public livekitBase = environment.LIVEKIT_BASE_URL;
  public changeLiveKitPermissionsUrl = this.livekitBase+'/twirp/livekit.RoomService/UpdateParticipant';
  public accessVideoCallUrl = 'api/auth/user/access_videocall';

  constructor(protected http: HttpClient, private authService: AuthService) {
    super(http, 'user', '');
  }

  getLivekitToken(roomId, isEvent = false) {
    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.getLivekitTokenUrl), {
      roomId,
      isEvent
    });
  }

  getLivekitTokenPreview(roomId, isEvent = false) {
    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.getLivekitTokenPreviewUrl), {
      roomId,
      isEvent
    });
  }

  changeLiveKitPermissions(livekitToken: string, roomId: string, userId: number, canPublish: boolean) {
    return new Promise<any>((resolve, reject) => {
      this.authService.getUserDataById(userId).subscribe(res => {
        const username = res.data.username;

        this.http.post<any>(this.changeLiveKitPermissionsUrl,
          {
            room: (environment.production ? '' : '9') + roomId.toString(),
            identity: username,
            permission: {
              can_subscribe: true,
              can_publish: canPublish,
              can_publish_data: canPublish
            }
          }, {headers: new HttpHeaders().set('Authorization', 'Bearer ' + livekitToken)}).subscribe(response => {
          resolve(response);
        }, err => {
          reject(err);
        });
      });
    });
  }

  accessVideoCall(roomId, userToTalkId: number) {
    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.accessVideoCallUrl), {
      roomId,
      userToTalkId
    });
  }
}
