<div #content class="review-content" [ngStyle]="{'margin-bottom': getMarginBottom()}">
  <!-- Description -->
  <div class="field review input">
    <div class="content text">
      <ion-textarea
        required
        rows="6"
        maxlength="1000"
        placeholder="Introduce un comentario"
        [autoGrow]="false"
        [(ngModel)]="review">
      </ion-textarea>
    </div>

    <div class="content">
      <div class="stars">
        <div class="star" (click)="setScore(1)">
          <app-valoration-star #starComponent1 [fill]="score >= 1 ? 2 : 0"></app-valoration-star>
          <ng-container *ngTemplateOutlet="starComponent1.starTemplate"></ng-container>
        </div>
        <div class="star" (click)="setScore(2)">
          <app-valoration-star #starComponent2 [fill]="score >= 2 ? 2 : 0"></app-valoration-star>
          <ng-container *ngTemplateOutlet="starComponent2.starTemplate"></ng-container>
        </div>
        <div class="star" (click)="setScore(3)">
          <app-valoration-star #starComponent3 [fill]="score >= 3 ? 2 : 0"></app-valoration-star>
          <ng-container *ngTemplateOutlet="starComponent3.starTemplate"></ng-container>
        </div>
        <div class="star" (click)="setScore(4)">
          <app-valoration-star #starComponent4 [fill]="score >= 4 ? 2 : 0"></app-valoration-star>
          <ng-container *ngTemplateOutlet="starComponent4.starTemplate"></ng-container>
        </div>
        <div class="star" (click)="setScore(5)">
          <app-valoration-star #starComponent5 [fill]="score >= 5 ? 2 : 0"></app-valoration-star>
          <ng-container *ngTemplateOutlet="starComponent5.starTemplate"></ng-container>
        </div>
      </div>
    </div>
  </div>

  <!-- Buttons -->
  <div class="buttons">
    <ion-button color="darkteal" (click)="createReview()">
      Aceptar
    </ion-button>
    
    <ion-button 
      fill="clear"
      color="dark" 
      class="go-back" 
      (click)="dismiss()">
      Cancelar
    </ion-button>
  </div>
</div>
