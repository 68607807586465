import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import { Publication } from 'src/app/interfaces/publication';
import { environment } from 'src/environments/environment';
import { SafeUrl } from '@angular/platform-browser';
import { MediaService } from 'src/app/services/media.service';


@Component({
  selector: 'app-video-preview',
  templateUrl: './video-preview.component.html',
  styleUrls: ['./video-preview.component.scss'],
})
export class VideoPreviewComponent implements OnInit, OnDestroy {
  @Input() publication: Publication;
  @ViewChild('videoElement', {static: false}) videoElement: ElementRef;

  readonly cdnBaseUrl = environment.CDN_BASE_URL;


  /** Controling time in previa */
  timeInPreview: number;
  intervalTimeInPreview;
  videoSrc: string | SafeUrl = '';
  video: HTMLVideoElement;
  isLoaded: boolean;

  // mediaServ: MediaService;

  constructor(
    public mediaService: MediaService
  ) {
    this.timeInPreview = 0;
    this.isLoaded = true;
  }

  ngOnInit() {
    if(this.publication.videoFile !== null){
      this.videoSrc = this.cdnBaseUrl + 'video/publications/'+this.publication.id+'/'+this.publication.videoFile; 
    }
  }

  ngAfterViewInit(){
    this.video = this.videoElement.nativeElement.querySelector('video');
    if(this.publication.videoFile !== null){
      this.video.autoplay = true;
      this.video.muted = true;
      this.video.load();
    }
  }

  ngOnDestroy(){
    this.video.pause();
    this.videoSrc = '';
    this.video.load();
  }

  /** Connected to Room */
  async loaded(){
    this.isLoaded = true;
    this.video.muted = true;

    this.intervalTimeInPreview = setInterval(()=>{
      this.timeInPreview++;
      if(this.timeInPreview >= 30){
        this.video.pause();
        this.videoSrc = '';
        this.video.load();
        clearInterval(this.intervalTimeInPreview);
        this.isLoaded = false;
        this.video.muted = true;
      }
    }, 1000);
  }
  async playing(){    
    // this.video.muted = true;
  }

}
