<ion-header>
  <ion-toolbar color="black">
    <ion-buttons slot="start" *ngIf="canCancel">
      <ion-button (click)="closeModal()">
        CANCELAR
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-center">Editar</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="saveImage()">
        GUARDAR
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div>
    <!--    [resizeToWidth]="256" para escalar la imagen antes de cropearla-->
    <image-cropper
      [autoCrop]="false"
      [imageChangedEvent]="imageChangedEvent"
      [imageFile]="imageFile"
      [maintainAspectRatio]="true"
      [aspectRatio]=aspectRatio
      [roundCropper]=roundCropper
      format="png"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"
      [transform]="transform"
      [cropperMinWidth]=450>
    </image-cropper>
  </div>
</ion-content>
