import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {KService} from './k.service';
import {JsonApiResponse} from '../interfaces/JsonApiResponse';
import {ModalController, Platform} from '@ionic/angular';
import {environment} from '../../environments/environment';
import {VersionBlockerComponent} from '../components/version-blocker/version-blocker.component';

import { AvailabilityAlertComponent } from '../components/availability-alert/availability-alert.component';

@Injectable({
  providedIn: 'root'
})
export class SettingsService extends KService {
  private getUserConfigURL = 'api/auth/user/get_user_config';
  private pauseNotificationsURL = 'api/auth/user/pause_notifications';
  private saveMinimumStarsURL = 'api/auth/user/save_minimum_stars';
  private setMessagesConfigURL = 'api/auth/user/set_messages_config';
  private setMentionsConfigURL = 'api/auth/user/set_mentions_config';
  private activateCollaborationRequestsURL = 'api/auth/user/activate_collaboration_requests';
  private activatePublicListsURL = 'api/auth/user/activate_public_lists';
  private checkAppVersion = 'api/check-app-version';

  /**
   * Objeto modal
   */
  private _modal: HTMLIonModalElement;

  constructor(
    protected http: HttpClient,
    public platform: Platform,
    private auth: AuthService,
    private modalCtrl: ModalController) {
    super(http);

    this.checkCurrentVersion();
  }

  getUserConfig(userId = null) {
    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.getUserConfigURL), {
      userId
    });
  }

  pauseNotifications(time: string) {
    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.pauseNotificationsURL), {
      time
    });
  }

  saveMinimumStars(stars: number) {
    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.saveMinimumStarsURL), {
      stars
    });
  }

  setMessagesConfig(messages: string) {
    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.setMessagesConfigURL), {
      messages
    });
  }

  setMentionsConfig(mentions: string) {
    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.setMentionsConfigURL), {
      mentions
    });
  }

  activateCollaborationRequests(activated) {
    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.activateCollaborationRequestsURL), {
      activated
    });
  }

  activatePublicLists(activated) {
    return this.http.post<JsonApiResponse<any>>(this.generateAbsoluteAPIURL(this.activatePublicListsURL), {
      activated
    });
  }

  /**
   * Check if APP can work with current backend version
   */
  checkCurrentVersion() {
    if ( this.platform.is('android') || this.platform.is('ios') ) {
      this.http.post(this.generateAbsoluteAPIURL(this.checkAppVersion), {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        app_version: environment.APP_VERSION
      }).subscribe((response) => {
        // @ts-ignore
        if ( (response.done && response.data?.must_update) ) {
          this.openVersionBlockerModal();
        }
      }, (error) => {
        /**
         * Version blocker disabled if error on request
         */
        // this.openVersionBlockerModal();
      });
    }
  }

  /**
   * Open a modal to block user interaction capability due to
   * unsupported versions
   */
  private async openVersionBlockerModal() {
    // eslint-disable-next-line no-underscore-dangle
    this._modal = await this.modalCtrl.create({
      cssClass: 'page-modal',
      component: VersionBlockerComponent,
      mode: 'ios',
      backdropDismiss: false,
      canDismiss: false
    });
    // eslint-disable-next-line no-underscore-dangle
    await this._modal.present();
  }

  async openFunctionNotAvailableModal(text: string) {
    this._modal = await this.modalCtrl.create({
      cssClass: "fit-modal floating-modal",
      component: AvailabilityAlertComponent,
      componentProps: { text },
      mode: "ios",
    });
    this._modal.present();
  }
}
