import { Component, OnInit } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { environment } from '../../../../../environments/environment';

import { BaseComponent as ExtrimPollBaseComponent } from '../base.component';

@Component({
  selector: 'app-info-live',
  templateUrl: './info-live.page.html',
  styleUrls: environment.isWeb ? ['./info-live.page.scss', '../../../../../assets/extrim/css/main.css', '../../../../../assets/extrim/css/main-desktop.css'] : ['./info-live.page.scss', '../../../../../assets/extrim/css/main.css'],
})
export class InfoLivePage extends ExtrimPollBaseComponent {

  constructor(
    protected nav: IonNav,
    protected modalCtrl: ModalController 
  ) {
    super(nav, modalCtrl)
  }
}
