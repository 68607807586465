import { Component, ViewChild, OnInit } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { IonNav, Platform } from '@ionic/angular';

import { PrePage } from './pre/pre.page';
import { RedirectPage } from './redirect/redirect.page';

// import { InvitePage } from './invite/invite.page';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit {

  @ViewChild('nav') nav: IonNav

  rootPage: any = PrePage;
  // rootPage: any = InvitePage;

  constructor( public platform: Platform ) {
    if ( !platform.is('hybrid') ) {
      this.rootPage = RedirectPage;
    }
  }

  ngOnInit() {
    this.platform.ready().then(async () => {
      // Oculta el splash screen despues de haber cargado la APP
      await SplashScreen.hide();
    });
  }
}
