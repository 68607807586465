<ion-content [scrollY]="false">
  <ion-list lines="none">
    <ion-item button detail="false" (click)="pickOption(ProfileOptionsPopoverOption.Share)">
      <ion-label>{{shareLabelText}}</ion-label>
      <ion-icon slot="end" name="share-social-outline"></ion-icon>
    </ion-item>
    <ion-item button *ngIf="!blocked" detail="false" (click)="pickOption(ProfileOptionsPopoverOption.Block)">
      <ion-label>{{blockLabelText}}</ion-label>
      <ion-icon slot="end" name="remove-circle-outline"></ion-icon>
    </ion-item>
    <ion-item button *ngIf="blocked" detail="false" (click)="pickOption(ProfileOptionsPopoverOption.Unlock)">
      <ion-label>Desbloquear</ion-label>
      <ion-icon slot="end" name="lock-open-outline"></ion-icon>
    </ion-item>
    <ion-item button detail="false" *ngIf="reportable" (click)="pickOption(ProfileOptionsPopoverOption.Report)">
      <ion-label color="danger">Denunciar</ion-label>
      <ion-icon slot="end" color="danger" name="warning-outline"></ion-icon>
    </ion-item>
  </ion-list>
</ion-content>
