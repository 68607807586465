import { Subject } from 'rxjs';

export class Debouncer {
  debouncer: Subject<string> = new Subject<string>();
  delay = 1000;
  available = false;
  loading = false;
  method = '';

  constructor(method: string, delay?: number) {
    this.delay = delay;
    this.method = method;
  }
}
