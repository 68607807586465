import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChild, ElementRef } from '@angular/core';
import { KComponent } from '../k.component';
import { slideAnimation } from '../../animations/slide-animation';
import {IonContent, IonRouterOutlet, MenuController, ModalController} from '@ionic/angular';
import { AuthService } from '../../services/auth.service';
import { ProfileService } from '../../services/profile.service';
import { User } from '../../interfaces/user';
import { Notification } from '../../interfaces/notification';
import { Events } from '../../services/events.service';
import { environment } from '../../../environments/environment';
import { NotificationService } from '../../services/notification.service';
import {Router} from '@angular/router';
import { CloudflareService } from 'src/app/services/cloudflare.service';
import { Platform } from '@ionic/angular';
import { UserService } from 'src/app/services/user.service';
import { Badge } from '@capawesome/capacitor-badge';

@Component({
  selector: 'app-k-header',
  templateUrl: './k-header.component.html',
  styleUrls: ['./k-header.component.scss'],
})
export class KHeaderComponent extends KComponent implements OnInit, OnDestroy {
  
  @ViewChild('fileProgressText', {static: false}) fileProgressText: ElementRef;
  
  @Input() title: string;
  @Input() homeContent?: IonContent;

  @Output() publicationCreated: EventEmitter<boolean> = new EventEmitter();
  @Output() scrollViewToTop = new EventEmitter();

  slideAnimation = slideAnimation;
  user: User;
  notifications: Notification[];
  unread = 0;
  unreadChatNumber = 0;
  unreadChat = [];

  sub: any = {};

  fileUploading: boolean = true;
  fileProgress: number = 0;
  fileProgressTextLeft: string = '0';
  fileProgressTextColor: string = 'black';

  invitationsIndicator: boolean = false;

  constructor(private router: Router,
              private modalCtrl: ModalController,
              private routerOutlet: IonRouterOutlet,
              private authService: AuthService,
              private profileService: ProfileService,
              private events: Events,
              private menuCtrl: MenuController,
              private notificationService: NotificationService,
              private changeDetector: ChangeDetectorRef,
              private cloudflareService: CloudflareService,
              private platform: Platform,
              private userService: UserService) {
    super();
    this.title = '';
  }

  ngOnInit() {
    this.notificationService.notifications.subscribe(response => {
      this.notifications = response;
      this.changeDetector.detectChanges();
    });
    this.notificationService.unread.subscribe(response => {
      this.unread = response;
      this.changeDetector.detectChanges();
    
      Badge.set({ count: (this.unread + this.unreadChatNumber) });
    });
    this.notificationService.unreadChat.subscribe(response => {
      this.unreadChat = response;
      this.unreadChatNumber = Object.keys(this.unreadChat).filter(key => !this.unreadChat[key].isRequest).length;

      this.changeDetector.detectChanges();

      Badge.set({ count: (this.unread + this.unreadChatNumber) });
    });

    this.events.subscribe((environment.EVENTS.BACK_BUTTON_PRESSED), async () => {
      await this.menuCtrl.enable(true, 'lateral');
      const opened = this.menuCtrl.isOpen('lateral');
      if (opened) {
        await this.menuCtrl.close('lateral');
      }
    });

    this.sub.fileUploading = this.cloudflareService.onUploading.subscribe((data: boolean) => {
      this.fileUploading = data;
      this.changeDetector.detectChanges();
    })
    this.sub.fileProgress = this.cloudflareService.onProgress.subscribe((data: number) => {
      this.fileProgress = Math.floor(data);
      this.calculateFileProgressTextLeft();
      this.changeDetector.detectChanges();
    })

    this.userService.invitationMenuIndicator
    .subscribe(status => {
      this.invitationsIndicator = status;
      this.changeDetector.detectChanges();
    })
  }
  ngOnDestroy(): void {
    this.sub.fileUploading.unsubscribe();
    this.sub.fileProgress.unsubscribe();
  }

  async openLateralMenu() {
    await this.menuCtrl.enable(true, 'lateral');
    const opened = await this.menuCtrl.isOpen('lateral');
    if (opened) {
      await this.menuCtrl.close('lateral');
    } else {
      await this.menuCtrl.open('lateral');
    }
  }

  goTo(route: string) {
    this.router.navigate([route]);
  }

  scrollToTop() {
    if( this.homeContent ) {
      this.homeContent.scrollToTop(500);
    } else {
      this.scrollViewToTop.emit();
    }
  }

  calculateFileProgressTextLeft() {
    const windowWidth = this.platform.width();
    const textElementData = this.fileProgressText?.nativeElement.getBoundingClientRect();

    if ( textElementData ) {
      if ( this.fileProgress > 45 ) {
        this.fileProgressTextLeft = `${windowWidth * (this.fileProgress / 100) - 5 - textElementData.width}px`;
        this.fileProgressTextColor = 'white';
      } else {
        this.fileProgressTextLeft = `${windowWidth * (this.fileProgress / 100) + 5}px`;
        this.fileProgressTextColor = 'black';
      }
    }
  }
}
