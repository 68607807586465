import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Events } from '../../services/events.service';
import { ActivatedRoute, Router } from '@angular/router';
import { KPage } from '../k.page';
import { environment } from '../../../environments/environment';
import {NavController, Platform} from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import {StatusbarService} from "../../services/statusbar.service";
import { App } from '@capacitor/app';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss']
})

export class LoginPage extends KPage implements OnInit {
  @Input() previousUrl: string;
  public loginForm: UntypedFormGroup;
  public invitationCodeForm: UntypedFormGroup;
  public invitationPhoneCodeForm: UntypedFormGroup;
  public loading: boolean;
  public formSelected: number;
  errorInvitacion = false;
  authServ: AuthService;

  constructor(public platform: Platform, private events: Events,
              private formBuilder: UntypedFormBuilder, private router: Router, private authService: AuthService,
              private route: ActivatedRoute,
              private statusbar: StatusbarService,
              private navCtrl: NavController,
  ) {
    super();
    this.authServ = this.authService;
    this.loading = false;
    this.formSelected = 1;
    this.previousUrl = '';

    if (route.snapshot.params.register === 'register') {
      this.formSelected = 3;
    }
  }

  ngOnInit() {
    this.platform.ready().then(() => {
      this.registerBackButtonAction();
    });
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: [null, Validators.required],
    });

    this.invitationCodeForm = this.formBuilder.group({
      phone: ['', [Validators.required]],
      countryCode: ['34', [Validators.required]],
    });

    this.invitationPhoneCodeForm = this.formBuilder.group({});
  }

  async ionViewWillEnter() {
    // Check if register restore point is setted
    if ( (await this.authService.getRegisterState()) !== undefined ) {
      this.router.navigate(['/register']);
    }
  }

  ionViewDidEnter() {
    if (this.platform.is('ios')) {
      this.statusbar.setTextDark();
    }
    this.events.publish(environment.EVENTS.ON_LOGIN_SCREEN);

    this.platform.ready().then(async () => {
      // Oculta el splash screen despues de haber cargado la APP
      await SplashScreen.hide();
    });
  }

  goBack() {
    this.formSelected = 1;
  }

  onFormSubmit(form: NgForm) {
    if (this.loading) {return;}

    this.loginForm.controls.username.markAsTouched();
    this.loginForm.controls.password.markAsTouched();
    if (!this.loginForm.valid) {return;}

    this.loading = true;
    this.login(this.loginForm.controls.username.value, this.loginForm.controls.password.value);
  }

  login(username: string, password: string) {
    this.authService.login(username, password).then(
      res => {
        if (res) {
          this.events.publish(environment.EVENTS.USER_LOGIN, environment.APP_ROUTES.HOME);
        } else {
          this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR);
        }
        this.loading = false;
      }).catch(err => {
      this.loading = false;
      if (err && err.error && err.error.error && err.error.error === 'invalid_grant') {
        this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, 'Usuario o contraseña incorrectos.');
      } else if (err.userId) {
        this.authService.sendSmsValidation(err.phone, err.userId).subscribe(sms => {
          if (!sms.done) {
            this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR,
              'Ha ocurrido un error al enviar el sms. Inténtalo más tarde o contacta con soporte');
          } else {
            this.authService.phone = err.phone;
            this.authService.userId = err.userId;
            this.authService.username = username;
            this.authService.password = password;
            this.events.publish('alert:toast:notice', 'Se le ha enviado un sms con un código para acceder.');
            this.router.navigate(['/registro-usuario/resend']).catch();
          }
        });
      } else {
        this.events.publish(environment.EVENTS.ALERT_TOAST_ERROR, err);
      }
    });
  }

  onInvitationSubmit(form: NgForm) {
    console.log('onInvitationSubmit');
    this.authService.countryCode = this.invitationCodeForm.controls.countryCode.value;
    this.authService.phone = this.invitationCodeForm.controls.phone.value;
    this.router.navigate([environment.APP_ROUTES.REGISTER]);

  }

  onInvitationPhoneSubmit(value: any) {
    this.authService.checkInvitationCode(this.invitationPhoneCodeForm.controls.invitationCode.value,
      this.invitationPhoneCodeForm.controls.countryCode.value).subscribe(res => {

    });
  }


  get invitationError() {
    return this.authService.invitationError;
  }

  cleanInvitationError() {
    this.authService.invitationError = false;
  }

  isWeb() {
    return !this.platform.is('hybrid');
  }

  private registerBackButtonAction() {
    App.addListener('backButton', () => {
      this.formSelected = 1;
    });
  }

  goToRegister() {
    if ( (this.platform.is('ios') || this.platform.is('android') || this.platform.is('mobile')) && !this.platform.is('hybrid') ) {
      if ( this.platform.is('ios') ) {
        window.open("https://apps.apple.com/es/app/estrim/id1622628431?l=es","_system");
      } else {
        window.open("https://play.google.com/store/apps/details?id=com.estrim.app&hl=es","_system");
      }
    } else {
      this.navCtrl.navigateBack('/register');
    }
  }
}
