import { Directive, OnDestroy, ElementRef, Output, EventEmitter } from '@angular/core';

import { createGesture, GestureDetail } from "@ionic/angular";

@Directive({
  selector: '[drag]'
})
export class DragGestureDirective implements OnDestroy {

  @Output() public drag: EventEmitter<GestureDetail> = new EventEmitter();
  @Output() public dragEnd: EventEmitter<GestureDetail> = new EventEmitter();

  private gesture: any;

  constructor( private element: ElementRef ) {
    this.gesture = createGesture({
      gestureName: 'drag',
      el: element.nativeElement,
      threshold: 0,
      onMove: (detail: GestureDetail) => { this.drag.emit(detail); },
      onEnd: (detail: GestureDetail) => { this.dragEnd.emit(detail); }
    });

    this.gesture.enable();
  }

  ngOnDestroy() {
    this.gesture.destroy();
  }
}
