import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { FeedService } from 'src/app/services/feed.service';
import { HomeLayout } from 'src/app/enums/home-layout';

@Component({
  selector: 'app-sidebar-items-menu',
  templateUrl: './items-menu.component.html',
  styleUrls: ['./items-menu.component.scss', '../../sidebar.component.scss'],
})
export class ItemsMenuComponent  implements OnInit {


  HomeLayout = HomeLayout;

  homeLayout: HomeLayout = HomeLayout.Video;
  homeModeDropdown: boolean = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private feedService: FeedService) { }

  ngOnInit() {
    // Get home layout
    this.feedService.homeLayout.subscribe((layout: HomeLayout) => {
      this.homeLayout = layout;
    })
  }

  goTo(route: string) {
    this.router.navigate([route]);
  }
  setHomeLayout(layout: HomeLayout) {
    this.feedService.setHomeLayout(layout);
    this.homeModeDropdown = false;
  }

}
