<div class="content">
  <div class="close" (click)="dismiss()">
    <ion-icon name="close-outline"></ion-icon>
  </div>

  <div class="title">
    Hay múltiples formas de generar ingresos en Estrim.
  </div>

  <!-- Info -->
  <div class="info">
    <div class="item">
      <ion-icon class="icon subscriber" src="assets/svg-icon/subscriber.svg"></ion-icon>

      <div class="text">
        <div class="intro">
          Suscripciones
        </div>

        <div class="description">
          Establece un precio y genera una suscripción mensual para tus seguidores más fieles y genera contenido exclusivo para ellos.
        </div>
      </div>
    </div>

    <div class="item">
      <ion-icon class="icon star" src="assets/svg-icon/star2.svg"></ion-icon>

      <div class="text">
        <div class="intro">
          Estrellas
        </div>

        <div class="description">
          <div class="description-title">En directo:</div>
          Da lo mejor de ti en Canales de vídeo, Salas de audio, eventos y estrenos. Tus seguidores podrán apoyar tu contenido enviándote Estrellas durante la emisión que recibirás en tu cartera. También podrás seguir recibiendo Estrellas en Canales de vídeo y Salas de audio incluso cuando no hay nadie emitiendo.
        </div>
        <div class="description">
          <div class="description-title">En Vídeos y Podcasts:</div>
          Publica tus vídeos, podcasts y canciones en la zona pública o sólo para tus suscriptores, para que tus seguidores disfruten del mejor contenido y puedan enviarte Estrellas.
        </div>
        <div class="description">
          <div class="description-title">En privado:</div>
          Realiza consultorías y sesiones privadas de cualquier ámbito y recibe Estrellas en el chat privado.
        </div>
      </div>
    </div>

    <div class="item">
      <ion-icon class="icon one" src="assets/svg-icon/one2.svg"></ion-icon>

      <div class="text">
        <div class="intro">
          Contenido One
        </div>

        <div class="description">
          Pon un precio a tu contenido más valioso. Un curso o formación, una buena conferencia, un cortometraje o documental, un podcast... Súbelos a la zona One y obtén ingresos por cada compra.
        </div>
      </div>
    </div>

    <div class="item">
      <ion-icon class="icon calendar" src="assets/svg-icon/calendar2.svg"></ion-icon>

      <div class="text">
        <div class="intro">
          Eventos virtuales
        </div>

        <div class="description">
          Programa un evento único e invita a colaboradores que aporten valor. Puedes poner un precio y generar ingresos con cada pase.
        </div>
      </div>
    </div>

    <div class="item">
      <ion-icon class="icon ticket" src="assets/svg-icon/ticket3.svg"></ion-icon>

      <div class="text">
        <div class="intro">
          Estrenos <app-coming-soon-tag></app-coming-soon-tag>
        </div>

        <div class="description">
          Elige una fecha para convocar a tu audiencia al estreno en primicia de tu podcast, corto, videoclip, serie... Puedes establecer un precio y obtener beneficios con la venta de cada pase.
        </div>
      </div>
    </div>
  </div>
</div>