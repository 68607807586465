<div class="icon">
  <ion-icon name="warning" color="warning"></ion-icon>
</div>

<div class="message">
  {{ message }}
</div>

<!-- Buttons -->
<div class="buttons">
  <ion-button 
    fill="clear" 
    color="dark"
    class="go-back" 
    (click)="dismiss()">
    Cerrar
  </ion-button>
</div>
