import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

export abstract class KService {
  protected readonly ENTITY: string;
  protected readonly URL: string;
  public readonly DEFAULT_IMAGE = '/assets/default-establishment-image.jpg';

  protected constructor(protected http: HttpClient, entity?: string, tail: string = 'api/autogen/') {
    this.ENTITY = entity;
    this.URL = environment.API_BASE_URL + tail;
  }

  public generateAbsoluteAPIURL($tail: string) {
    return environment.API_BASE_URL + $tail;
  }
  public generateAbsoluteCDNURL($tail: string) {
    return environment.CDN_BASE_URL + $tail;
  }

  public remove(id: number) {
    return this.http.get<any>(this.URL + 'remove/' + this.ENTITY + '/' + id);
  }

  public list(offset: number, limit: number = 30, ...getParams): Observable<any> {
    return this.http.get(this.URL + 'list/' + this.ENTITY + '/' + limit + '/' + offset + this.paramsToGetStr(...getParams));
  }

  public detail(id: number) {
    return this.http.get(this.URL + 'detail/' + this.ENTITY + '/' + id);
  }

  public search(pattern: string, category: number = 0, page: number = 1) {
    return this.http.get<any>(this.URL + 'search/' + this.ENTITY + '/' + pattern + '/' + category + '/' + page);
  }

  public filter(array: Array<any>, query: string) {
    if (array.length <= 0) {
      return null;
    }

    const q = this.normalize(query);
    const filterKeys = Object.keys(array[0]);
    return array.filter(item => {
      for (const i of filterKeys) {
        const val = item[i];
        if (!val || typeof val === 'object') {
          continue;
        }

        if (this.normalize(val + '').includes(q)) {
          return true;
        }
      }

      return false;
    });
  }

  public normalize(s) {
    let r = s.toLowerCase();
    const nonAsciis = {
      a: '[àáâãäå]',
      ae: 'æ',
      c: 'ç',
      e: '[èéêë]',
      i: '[ìíîï]',
      n: 'ñ',
      o: '[òóôõö]',
      oe: 'œ',
      u: '[ùúûűü]',
      y: '[ýÿ]'
    };
    for (const key of Object.keys(nonAsciis)) {
      r = r.replace(new RegExp(nonAsciis[key], 'g'), key);
    }
    return r;
  }

  /**
   * @deprecated use SlugifyPipe
   */
  public makeDashed(str) {
    let dashed = str.replace(/[A-Z]/g, '-$&').toLowerCase();
    if (dashed.indexOf('-') === 0) {
      dashed = dashed.substring(1, dashed.length);
    }

    return dashed.split(' ').join('-');
  }

  protected paramsToGetStr(...params) {
    if (!params || params.length === 0) {
      return '';
    }

    let get = '';
    let sep = '?';
    for (const param of params) {
      get += sep + param;
      sep = '&';
    }

    return get;
  }
}
