import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-version-blocker',
  templateUrl: './version-blocker.component.html',
  styleUrls: ['./version-blocker.component.scss'],
})
export class VersionBlockerComponent implements OnInit {

  isIos: boolean = false;
  isAndroid: boolean = false;

  constructor(private platform: Platform) { }

  ngOnInit() {
    this.platform.ready().then(async () => {
      this.isIos = this.platform.is('ios');
      this.isAndroid = this.platform.is('android');
    });
  }

  openStore() {
    if ( this.platform.is('android') ) {
      window.open("https://play.google.com/store/apps/details?id=com.estrim.app&hl=es","_system");
    }

    if ( this.platform.is('ios') ) {
      window.open("https://apps.apple.com/es/app/estrim/id1622628431?l=es","_system");
    }
  }
}
