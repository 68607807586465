<swiper #swiper [config]="swiperParams">
  <ng-template class="new-pro-slide1" swiperSlide>
    <div
      class="content"
      [ngStyle]="{
        backgroundImage:
          'linear-gradient(to top, ' + levelColor + ' 17%, #ffffff)'
      }"
    >
      <ion-icon
        class="badge"
        src="assets/popups/badges/badge{{ level }}.svg"
      ></ion-icon>
      <ion-img class="halo" src="assets/popups/halo.webp" />
      <div>
        <h2 class="title text-white mt-40px">¡ENHORABUENA @{{user.username}}!</h2>
        <h4 class="text-white mb-3">
          Has conseguido un nuevo emblema de experiencia.
        </h4>
        <ion-button color="transparent" (click)="close()"> Cerrar </ion-button>
      </div>
    </div>
  </ng-template>
</swiper>
