<ion-header class="general-header-app">
  <app-header
    [title]="''"
    [menuButton]="false"
    [defaultBackAction]="false"
    (back)="back()"
  >
  </app-header>
</ion-header>

<ion-content class="ion-padding">
  <div class="flex-content">
    <div class="content">
      <ion-icon class="logo" src="assets/estrim/logo.svg"></ion-icon>
      <ion-text class="page-title"> Descarga la aplicación </ion-text>

      <div class="stores">
        <div
          class="store"
          *ngIf="isIos || (!isHybrid && !isAndroid)"
          (click)="openAppleStore()"
        >
          <img handleError src="assets/app/apple-es.png" />
        </div>
        <div
          class="store"
          *ngIf="isAndroid || (!isHybrid && !isIos)"
          (click)="openGoogleStore()"
        >
          <img handleError src="assets/app/google-es.png" />
        </div>
      </div>

      <qrcode
        class="qr"
        *ngIf="!isHybrid"
        [qrdata]="mobileUrl"
        [width]="200"
        [errorCorrectionLevel]="'M'"
        [colorDark]="'#111'"
        [imageSrc]="'assets/estrim/128/icon-light.png'"
        [margin]="0"
      >
      </qrcode>
    </div>
  </div>
  <app-web-footer></app-web-footer>
</ion-content>
