<div class="add-bubble-overlay" [ngClass]="{'opened':addBubbleOpened}"
     (click)="addBubbleOpened = false"></div>

<!-- <div class="div-separator thin"></div> -->

<ion-content class="ion-padding" #content [scrollEvents]="true" (ionScrollEnd)="checkScroll($event)">
  <div #divChat class="chat-wrapper">
    <ion-grid class="ion-no-padding">
      <ng-container *ngFor="let msg of cachedMessages; let i = index">
        <div class="timeSeparator"
             *ngIf="isAnotherDate(msg, cachedMessages[i-1]) || !cachedMessages[i-1]">{{isToday(msg) ? 'Hoy' : msg.date | date: 'd/MM/yy'}}</div>
        <app-private-message (click)="likeMessage($event, msg)" (contextmenu)="onRightClick($event,msg)"
                             (touchstart)="enableLongPress($event,msg)"
                             (touchend)="cancelLongPress()"
                             (touchmove)="cancelLongPress()"
                             [msg]="msg" [user]="cachedUser"
                             (videoCallAnswered)="answerVideoCall($event)"
                             [userToTalk]="cachedUserToTalk"
                             [shouldShowProfileImage]="checkShouldShowProfileImage(i)">                  
        </app-private-message>
      </ng-container>
    </ion-grid>
  </div>

</ion-content>

<ion-footer>
  <!--  <ion-toolbar color="light">-->
  <!--    <ion-row class="ion-align-items-center ion-no-padding">-->
  <!--      <ion-col size="10">-->
  <!--        &lt;!&ndash;        <textarea autosize maxRows=3 [(ngModel)]="newMsg" class="msg-input"></textarea>&ndash;&gt;-->
  <!--      </ion-col>-->
  <!--      <ion-col size="2">-->
  <!--        <ion-button expand="block" fill="clear" color="primary" [disabled]="newMsg === ''"-->
  <!--                    class="msg-btn" (click)="sendMessage()">-->
  <!--          <ion-icon slot="icon-only" name="send-outline"></ion-icon>-->
  <!--        </ion-button>-->
  <!--      </ion-col>-->
  <!--    </ion-row>-->
  <!--  </ion-toolbar>-->
  <div class="add-bubble" [ngClass]="{'opened': addBubbleOpened}">
    <div class="lines-div"></div>
    <div class="lines-div"></div>
    <div class="lines-div"></div>
    <div class="lines-div"></div>
    <div class="cover-shadow-div"></div>
    <ion-button fill="clear" (click)="pickFromGallery()">
      <div>
        <ion-icon color="dark" name="image-outline"></ion-icon>
        <ion-label color="dark">Galería</ion-label>
      </div>
    </ion-button>
    <ion-button fill="clear" (click)="addFile()">
      <div>
        <input type="file"
               #fileInput
               (change)="uploadFile($event)"
               hidden
               accept="*">
        <ion-icon color="dark" name="document-text-outline"></ion-icon>
        <ion-label color="dark">Archivo</ion-label>
      </div>
    </ion-button>
    <ion-button fill="clear" (click)="presentAlertSendLocation()">
      <div>
        <ion-icon color="dark" name="location-outline"></ion-icon>
        <ion-label color="dark">Ubicación</ion-label>
      </div>
    </ion-button>
    <ion-button fill="clear" *ngIf="isMobile()" (click)="presentContactsModal()">
      <div>
        <ion-icon color="dark" name="id-card-outline"></ion-icon>
        <ion-label color="dark">Contacto</ion-label>
      </div>
    </ion-button>
  </div>

  <ion-row class="chat-input-div">

    <div class="side-input-button left">
      <ion-button (click)="addBubbleOpened = !addBubbleOpened" expand="block" fill="clear" size="small">
        <ion-icon name="add" color="dark"></ion-icon>
      </ion-button>
    </div>
    <div class="inner-input-div" *ngIf="!recordingAudio">
      <ion-textarea rows="1" auto-grow="true" (keyup)="updateInputText($event)" [(ngModel)]="currentInputText"
                    type="text" id="myMessage" color="dark"
                    placeholder="Escribe algo..."></ion-textarea>
      <ion-button 
        class="star"
        (click)="starsDivOpened = !starsDivOpened" 
        expand="block" 
        fill="clear" 
        size="small">
        <img handleError src="/assets/star-m.png" />
      </ion-button>
      <ion-button (click)="takePhoto()" expand="block" fill="clear" size="small">
        <ion-icon name="camera-outline" color="dark"></ion-icon>
      </ion-button>
    </div>
    <div *ngIf="recordingAudio" class="recordingDiv">
      <ion-icon name="ellipse"></ion-icon>
      <span>{{recordingDurationDisplay}}</span>
    </div>

    <div class="side-input-button right">
      <ion-button (click)="currentInputText.length>0 ? sendChatMessage() : recordAction()" expand="block" fill="clear"
                  size="small">
        <ion-icon class="dynamic-button send" [ngClass]="{'visible': currentInputText.length>0}" name="send-outline"
                  color="dark"></ion-icon>
        <ion-icon class="dynamic-button" [ngClass]="{'visible': currentInputText.length === 0}" name="mic-outline"
                  color="dark"></ion-icon>
      </ion-button>
    </div>
  </ion-row>

  <div *ngIf="uploadLoading" class="loading-upload">
    <ion-spinner name="crescent"></ion-spinner>
  </div>

  <div class="stars-div" *ngIf="starsDivOpened" @inOutFromBottom>
    <div class="stars-input-row">
      <ion-button class="icon-star-send" fill="clear" (click)="starsDivOpened = false">
        <ion-icon name="arrow-back-outline"
                  color="dark"></ion-icon>
      </ion-button>

      <ion-input type="number" [(ngModel)]="starsQuantity"></ion-input>
      
      <ion-button class="icon-star-send" fill="clear" (click)="presentAlertSendStars(null)">
        <ion-icon name="star" color="primary"></ion-icon>
      </ion-button>
    </div>
    <div *ngIf="getCreditsBalance()" class="credits-div">
      <div class="content">
        <ion-icon name="ellipse"></ion-icon>
        <span>{{getCreditsBalance()}}</span>
      </div>
    </div>
    <div class="stars-options">
      <ion-button fill="clear" (click)="presentAlertSendStars(10)">
        <div>
          <ion-icon color="primary" name="star"></ion-icon>
          <ion-label color="dark">10</ion-label>
        </div>
      </ion-button>
      <ion-button fill="clear" (click)="presentAlertSendStars(50)">
        <div>
          <ion-icon color="primary" name="star"></ion-icon>
          <ion-label color="dark">50</ion-label>
        </div>
      </ion-button>
      <ion-button fill="clear" (click)="presentAlertSendStars(100)">
        <div>
          <ion-icon color="primary" name="star"></ion-icon>
          <ion-label color="dark">100</ion-label>
        </div>
      </ion-button>
      <ion-button fill="clear" (click)="presentAlertSendStars(200)">
        <div>
          <ion-icon color="primary" name="star"></ion-icon>
          <ion-label color="dark">200</ion-label>
        </div>
      </ion-button>
    </div>
  </div>

  <div>

  </div>
</ion-footer>
