<!-- DISABLE FOR LAZY LOAD -->
<img
  #image
  *ngIf="!avatarZoomable && !zoomable"
  handleError
  draggable="false"
  [class.loaded]="isLoaded"
  [class.contain]="!cover"
  thumbnail
  [size]="size"
  [source]="source"
  [alt]="alt"
  (load)="markForChange()"
/>

<img
  #image
  *ngIf="avatarZoomable && !zoomable"
  handleError
  avatarZoomable
  draggable="false"
  [class.loaded]="isLoaded"
  [class.contain]="!cover"
  thumbnail
  [size]="size"
  [source]="source"
  [zoomableSrc]="source"
  [alt]="alt"
  (load)="markForChange()"
/>

<img
  #image
  *ngIf="!avatarZoomable && zoomable"
  handleError
  zoomable
  draggable="false"
  [class.loaded]="isLoaded"
  [class.contain]="!cover"
  thumbnail
  [size]="size"
  [source]="source"
  [alt]="alt"
  (load)="markForChange()"
/>

<ion-skeleton-text *ngIf="!isLoaded" [animated]="true" [class.contain]="!cover"></ion-skeleton-text>

<!-- ENABLE FOR LAZY LOAD -->
<!-- <img
  handleError
  draggable="false"
  loading="lazy"
  [class.loaded]="isLoaded"
  [src]="src"
  [alt]="alt"
  (load)="markForChange()"
  decoding="async"
/> -->
<!-- -------------------- -->


<!-- <ion-img
  handleError
  loading="lazy"
  [class.loaded]="isLoaded"
  [src]="src"
  [alt]="alt"
  (ionImgDidLoad)="markForChange()"
  decoding="async">    
</ion-img> -->

