import { Publication } from "../interfaces/publication";
import { Event } from "../interfaces/event";

import { ContentType } from "../enums/content-type";

export const identifyPublicationType = (publication: Publication | Event): ContentType => {
  if ( (publication as Publication)?.publicationType ) {
    if ( (publication as Publication)?.publicationType === 'clip' ) {
      return ContentType.Clip
    } else if ( (publication as Publication).onlyAudio ) {
      if ( (publication as Publication)?.publicationType === 'live' ) {
        return ContentType.LiveAudio
      } else if ( (publication as Publication)?.publicationType === 'uploaded' && !(publication as Publication)?.isOne ) {
        return ContentType.Audio
      } else if ( (publication as Publication)?.publicationType === 'uploaded' && (publication as Publication)?.isOne ) {
        return ContentType.OneAudio
      }
    } else if ( !(publication as Publication).onlyAudio ) { 
      if ( (publication as Publication)?.publicationType === 'live' ) {
        return ContentType.LiveVideo
      } else if ( (publication as Publication)?.publicationType === 'uploaded' && !(publication as Publication)?.isOne ) {
        return ContentType.Video
      } else if ( (publication as Publication)?.publicationType === 'uploaded' && (publication as Publication)?.isOne ) {
        return ContentType.OneVideo
      }
    }
  } else {
    if ( (publication as Event).eventType === 'premiere' ) {
      return ContentType.Premiere;
    } else {
      return ContentType.Event;
    }
  }
};