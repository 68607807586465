import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { Preferences } from '@capacitor/preferences';

import { ChooseContentTypeComponent } from '../components/choose-content-type/choose-content-type.component';
import { HubInfoComponent } from '../components/content-info/hub-info/hub-info.component';
import { StaticContentInfoComponent } from '../components/content-info/static-content-info/static-content-info.component';
import { LiveVideoInfoComponent } from '../components/content-info/live-video-info/live-video-info.component';
import { LiveAudioInfoComponent } from '../components/content-info/live-audio-info/live-audio-info.component';
import { EventInfoComponent } from '../components/content-info/event-info/event-info.component';
import { PremiereInfoComponent } from '../components/content-info/premiere-info/premiere-info.component';
import { GenericAlertComponent } from '../components/generic-alert/generic-alert.component';

import { StaticContentComponent } from '../pages/publications/create/static-content/static-content.component';
import { MediaPage as StaticContentMediaPage} from '../pages/publications/create/static-content/media/media.page';
import { FormPage as StaticContentFormPage} from '../pages/publications/create/static-content/form/form.page';

import { LiveChannelComponent } from '../pages/publications/create/live-channel/live-channel.component';
import { ListPage as LiveChannelListPage } from '../pages/publications/create/live-channel/list/list.page';
import { FormPage as LiveChannelFormPage } from '../pages/publications/create/live-channel/form/form.page';

import { EventPremiereComponent } from '../pages/publications/create/event-premiere/event-premiere.component';
import { ListPage as EventPremiereListPage } from  '../pages/publications/create/event-premiere/list/list.page';
import { FormPage as EventPremiereFormPage } from  '../pages/publications/create/event-premiere/form/form.page';

import { StatusbarService } from '../services/statusbar.service';

import { Publication } from '../interfaces/publication';
import { Event } from '../interfaces/event';

import { ContentType } from '../enums/content-type';
import { FormMode } from '../enums/form-mode';

@Injectable({
  providedIn: 'root'
})
export class CreateContentService {

  HUB_INFO_SHOWED_KEY = 'NODELETE_HUB_INFO_SHOWED';
  STATIC_CONTENT_INFO_SHOWED_KEY = 'NODELETE_STATIC_CONTENT_INFO_SHOWED';
  LIVE_VIDEO_INFO_SHOWED_KEY = 'NODELETE_LIVE_VIDEO_INFO_SHOWED';
  LIVE_AUDIO_INFO_SHOWED_KEY = 'NODELETE_LIVE_AUDIO_INFO_SHOWED';
  EVENT_INFO_SHOWED_KEY = 'NODELETE_EVENT_INFO_SHOWED';
  PREMIERE_INFO_SHOWED_KEY = 'NODELETE_PREMIERE_INFO_SHOWED';

  /**
   * Objeto modal
   */
  private _modal: HTMLIonModalElement[] = [];

  constructor(
    private modalCtrl: ModalController,
    private router: Router,
    private statusbar: StatusbarService
  ) { }

  /**
   * Abre modal para elegir tipo de contenido a crear
   */
  async openChooseContentTypeModal() {
    this._modal[this._modal.length] = await this.modalCtrl.create({
      cssClass: 'fit-modal floating-modal',
      component: ChooseContentTypeComponent,
      mode: 'ios',
    });
    this._modal[this._modal.length - 1].present();
  }

  /**
   * Abre modal para crear contenido estatico
   *
   * @param type Tipo de contenido estatico
   */
  async openCreateStaticContent(
    type: ContentType, 
    mode: FormMode = FormMode.Create, 
    file?: File, 
    publication?: Publication, 
    url?: string,
    size?: number,
    duration?: number,
    isRecord?: boolean
  ) {
    this._modal[this._modal.length] = await this.modalCtrl.create({
      cssClass: '',
      component: StaticContentComponent,
      componentProps: {
        rootPage: mode === FormMode.Create && !isRecord ?  StaticContentMediaPage : StaticContentFormPage,
        type,
        file,
        mode,
        publication,
        url,
        size,
        duration,
        isRecord
      },
      mode: 'ios',
    });

    this.setStatusbarTextColor(true);
    this._modal[this._modal.length - 1].present();

    if ( mode === FormMode.Edit ) {
      const { data, role } = await this._modal[this._modal.length - 1].onWillDismiss();
      this._modal.pop();

      this.setStatusbarTextColor();

      return { data, role };
    }
  }

  /**
   * Abre modal para crear canales en vivo
   *
   * @param type Tipo de canal en vivo
   */
  async openCreateLiveChannel(type: ContentType, mode: FormMode = FormMode.Create, publication?: Publication) {
    this._modal[this._modal.length] = await this.modalCtrl.create({
      cssClass: '',
      component: LiveChannelComponent,
      componentProps: {
        rootPage: mode === FormMode.Create ?  LiveChannelListPage : LiveChannelFormPage,
        type,
        mode,
        publication
      },
      mode: 'ios',
    });

    this.setStatusbarTextColor(true);
    this._modal[this._modal.length - 1].present();

    if ( mode === FormMode.Edit ) {
      const { data, role } = await this._modal[this._modal.length - 1].onWillDismiss();
      this._modal.pop();

      // this.setStatusbarTextColor();

      return { data, role };
    }
  }

  /**
   * Abre modal para crear eventos o estrenos
   *
   * @param type Tipo de evento
   */
  async openCreateEventPremiere(type: ContentType, mode: FormMode = FormMode.Create, event?: Event) {
    this._modal[this._modal.length] = await this.modalCtrl.create({
      cssClass: 'no-ion-datetime',
      component: EventPremiereComponent,
      componentProps: {
        rootPage: mode === FormMode.Create ?  EventPremiereListPage : EventPremiereFormPage,
        type,
        mode,
        event
      },
      mode: 'ios',
    });

    this.setStatusbarTextColor(true);
    this._modal[this._modal.length - 1].present();

    if ( mode === FormMode.Edit ) {
      const { data, role } = await this._modal[this._modal.length - 1].onWillDismiss();
      this._modal.pop();

      this.setStatusbarTextColor();

      return { data, role };
    }
  }

  /**
   * INFO MODALS
   */

  /**
   * Abre modal de informacion del Hub
   */
  async openHubInfoModal() {
    Preferences.set({key: this.HUB_INFO_SHOWED_KEY, value: '1'});

    this._modal[this._modal.length] = await this.modalCtrl.create({
      cssClass: 'fit-modal floating-modal',
      component: HubInfoComponent,
      mode: 'ios'
    });
    this._modal[this._modal.length - 1].present();

    const { data, role } = await this._modal[this._modal.length - 1].onWillDismiss();
    this._modal.pop();

    if ( role === 'confirm' ) {
      switch( data.type ) {
      case ContentType.Video:
        if ( await this.staticContentInfoShown() )
          {this.openChooseContentTypeModal();}
        else
          {this.openStaticContentInfoModal();}

        break;
      case ContentType.LiveVideo:
        this.openLiveVideoInfoModal();

        break;
      case ContentType.LiveAudio:
        this.openLiveAudioInfoModal();

        break;
      case ContentType.Event:
        this.openEventInfoModal();

        break;
      case ContentType.Premiere:
        this.openPremiereInfoModal();

        break;
      }

      return data.type;
    } else {
      return undefined;
    }
  }

  /**
   * Comprueba si se ha mostrado la info del hub
   *
   * @return Si se ha mostrado o no
   */
  async hubInfoShown() {
    const status = await Preferences.get({key: this.HUB_INFO_SHOWED_KEY});
    return status.value === '1';
  }

  /**
   * Abre modal de informacion sobre contenido estatico
   */
  async openStaticContentInfoModal(confirmButton: boolean = true) {
    Preferences.set({key: this.STATIC_CONTENT_INFO_SHOWED_KEY, value: '1'});

    this._modal[this._modal.length] = await this.modalCtrl.create({
      cssClass: 'fit-modal floating-modal',
      component: StaticContentInfoComponent,
      componentProps: { confirmButton },
      mode: 'ios'
    });
    this._modal[this._modal.length - 1].present();

    const { data, role } = await this._modal[this._modal.length - 1].onWillDismiss();
    this._modal.pop();

    if ( role === 'confirm' ) {
      this.openChooseContentTypeModal();
    }
  }

  /**
   * Comprueba si se ha mostrado la info del contenido estatico
   *
   * @return Si se ha mostrado o no
   */
  async staticContentInfoShown() {
    const status = await Preferences.get({key: this.STATIC_CONTENT_INFO_SHOWED_KEY});
    return status.value === '1';
  }

  /**
   * Abre modal de informacion sobre salas de video
   */
  async openLiveVideoInfoModal(confirmButton: boolean = true) {
    Preferences.set({key: this.LIVE_VIDEO_INFO_SHOWED_KEY, value: '1'});

    this._modal[this._modal.length] = await this.modalCtrl.create({
      cssClass: 'fit-modal floating-modal',
      component: LiveVideoInfoComponent,
      componentProps: { confirmButton },
      mode: 'ios'
    });
    this._modal[this._modal.length - 1].present();

    const { data, role } = await this._modal[this._modal.length - 1].onWillDismiss();
    this._modal.pop();

    if ( role === 'confirm' ) {
      this.openCreateLiveChannel(ContentType.LiveVideo);
    }
  }

  /**
   * Comprueba si se ha mostrado la info de las salas de video
   *
   * @return Si se ha mostrado o no
   */
  async liveVideoInfoShown() {
    const status = await Preferences.get({key: this.LIVE_VIDEO_INFO_SHOWED_KEY});
    return status.value === '1';
  }

  /**
   * Abre modal de informacion sobre salas de audio
   */
  async openLiveAudioInfoModal(confirmButton: boolean = true) {
    Preferences.set({key: this.LIVE_AUDIO_INFO_SHOWED_KEY, value: '1'});

    this._modal[this._modal.length] = await this.modalCtrl.create({
      cssClass: 'fit-modal floating-modal',
      component: LiveAudioInfoComponent,
      componentProps: { confirmButton },
      mode: 'ios'
    });
    this._modal[this._modal.length - 1].present();

    const { data, role } = await this._modal[this._modal.length - 1].onWillDismiss();
    this._modal.pop();

    if ( role === 'confirm' ) {
      this.openCreateLiveChannel(ContentType.LiveAudio);
    }
  }

  /**
   * Comprueba si se ha mostrado la info de las salas de audio
   *
   * @return Si se ha mostrado o no
   */
  async liveAudioInfoShown() {
    const status = await Preferences.get({key: this.LIVE_AUDIO_INFO_SHOWED_KEY});
    return status.value === '1';
  }

  /**
   * Abre modal de informacion sobre eventos
   */
  async openEventInfoModal(confirmButton: boolean = true) {
    Preferences.set({key: this.EVENT_INFO_SHOWED_KEY, value: '1'});

    this._modal[this._modal.length] = await this.modalCtrl.create({
      cssClass: 'fit-modal floating-modal',
      component: EventInfoComponent,
      componentProps: { confirmButton },
      mode: 'ios'
    });
    this._modal[this._modal.length - 1].present();

    const { data, role } = await this._modal[this._modal.length - 1].onWillDismiss();
    this._modal.pop();

    if ( role === 'confirm' ) {
      this.openCreateEventPremiere(ContentType.Event);
    }
  }

  /**
   * Comprueba si se ha mostrado la info de los eventos
   *
   * @return Si se ha mostrado o no
   */
  async eventInfoShown() {
    const status = await Preferences.get({key: this.EVENT_INFO_SHOWED_KEY});
    return status.value === '1';
  }

  /**
   * Abre modal de informacion sobre estrenos
   */
  async openPremiereInfoModal(confirmButton: boolean = true) {
    Preferences.set({key: this.PREMIERE_INFO_SHOWED_KEY, value: '1'});

    this._modal[this._modal.length] = await this.modalCtrl.create({
      cssClass: 'fit-modal floating-modal',
      component: PremiereInfoComponent,
      componentProps: { confirmButton },
      mode: 'ios'
    });
    this._modal[this._modal.length - 1].present();

    const { data, role } = await this._modal[this._modal.length - 1].onWillDismiss();
    this._modal.pop();

    if ( role === 'confirm' ) {
      this.openCreateEventPremiere(ContentType.Premiere);
    }
  }

  /**
   * Comprueba si se ha mostrado la info de los estrenos
   *
   * @return Si se ha mostrado o no
   */
  async premiereInfoShown() {
    const status = await Preferences.get({key: this.PREMIERE_INFO_SHOWED_KEY});
    return status.value === '1';
  }

  async showUploadingAlert() {
    this._modal[this._modal.length] = await this.modalCtrl.create({
      cssClass: 'fit-modal floating-modal',
      component: GenericAlertComponent,
      componentProps: { 
        message: 'Hay una subida en curso, no podrás crear más contenido hasta que esta acabe.'
      },
      mode: 'ios',
    });
    this._modal[this._modal.length - 1].present();
  }

  /**
   * Cierra modal
   */
  closeModal() {
    this._modal[this._modal.length - 1]?.dismiss();
    this._modal?.pop();
  }

  /**
   * Configura el color del texto del statusbar
   * @param {boolean} openMode Indica si se esta abriendo un modal 
   */
  setStatusbarTextColor(openMode: boolean = false) {
    if ( openMode ) {
      this.statusbar.setTextDark();
    } else if ( this.router.url.includes('profile') || this.router.url.includes('publications') ) {
      this.statusbar.setTextLight();
    } else {
      this.statusbar.setTextDark();
    }
  }
}
