<!-- Profile image and avatar -->
<div class="media" *ngIf="user && !skeleton" [ngClass]="{ reduced: reduced }">
  <!-- Avatar -->
  <div class="profile-img-container" (click)="canEditProfile() ? pickAvatar() : null">
    <div class="pro-border" *ngIf="user.isPro">
      <div class="image-place"></div>
    </div>

    <lazy-img
      class="profile-img"
      [ngClass]="{ pro: user.isPro }"
      [size]="400"
      [source]="getImgProfile()"
      [avatarZoomable]="!canEditProfile()"
    >
    </lazy-img>
    <ion-skeleton-text 
      class="skeleton overlay-skeleton" 
      *ngIf="uploadingAvatar"
      @inOutFade
      [animated]="true">
    </ion-skeleton-text>

    <app-pro-tag
      class="pro-tag"
      *ngIf="user.isPro"
      [ngClass]="{ emitting: user.emitting }"
    >
    </app-pro-tag>

    <div class="online video" *ngIf="user.emitting">EN DIRECTO</div>
  </div>
  <!-- Image -->
  <div class="banner-container" *ngIf="!user.profileCoverIsVideo && !reduced" (click)="canEditProfile() ? pickBanner() : null">
    <lazy-img
      [size]="1024"
      [source]="getImgBanner()"
      (onLoad)="onBannerLoad($event)"
    ></lazy-img>

    <ion-thumbnail *ngIf="uploadingBanner" @inOutFade>
      <ion-skeleton-text [animated]="true"></ion-skeleton-text>
    </ion-thumbnail>
  </div>
</div>
<!-- Profile image and avatar SKELETON -->
<div class="media" *ngIf="skeleton" [ngClass]="{ reduced: reduced }">
  <!-- Avatar -->
  <div class="profile-img-container">
    <ion-skeleton-text class="skeleton" [animated]="true"></ion-skeleton-text>
  </div>
  <!-- Image -->
  <div class="banner-container" *ngIf="!reduced">
    <ion-skeleton-text [animated]="true" style="margin: 0"></ion-skeleton-text>
  </div>
</div>

<!-- User data -->
<div
  class="user-data"
  *ngIf="user && !skeleton"
  [ngClass]="{ reduced: reduced }"
>
  <div class="soft-loading" *ngIf="softLoading" @inOutFade>
    <ion-spinner color="primary" name="crescent"></ion-spinner>
  </div>

  <!-- User -->
  <div class="display-flex username-row">
    <ion-row class="first-profile-line">
      <div *ngIf="user.nameToShow" class="username-profile">
        <span class="username">{{ user.nameToShow }}</span>
        <img
          handleError
          class="founder-badge"
          *ngIf="user.isFounder"
          src="assets/badges/founder-badge.svg"
          (click)="showFounder()"
        />
      </div>
      <span class="reputation-span"
        *ngIf="user?.reputation !== undefined"
        (click)="showExperience()">
        <img handleError [src]="getBadgeSrc()" />
        <span class="number-reputation">
          {{ user.reputation }}
        </span>
      </span>
    </ion-row>
    <ion-row class="second-profile-line">
      <h5 class="name-profile">@{{ user.username }}</h5>
      <!-- <span class="register-date">Desde {{user.createdAt.toString().replace(' ', 'T') | date:'MMMM YYYY'}}</span> -->
    </ion-row>
  </div>

  <!-- Steps -->
  <div
    class="steps"
    [ngStyle]="{
      transition: viewInited ? 'height .3s' : 'none',
      height: stepsContainerHeight + 'px'
    }"
  >
    <div
      #step
      class="step"
      *ngIf="settingsSlideStep === 0"
      [@inOutAnimation]="animationDirection"
      [@.disabled]="!viewInited"
    >
      <!-- Badges -->
      <div
        class="badge-container collaborator"
        *ngIf="!isCreator && isCollaborator"
        [ngClass]="{ emitting: user.emitting }"
      >
        <img
          handleError
          src="assets/badges/special/collaborator-white-1x.png"
          alt=""
        />
        <div class="text">Colaborador</div>
      </div>
      <div
        class="badge-container subscriber"
        *ngIf="!isCreator && !isCollaborator && isSubscribed"
        [ngClass]="{ emitting: user.emitting }"
      >
        <img
          handleError
          src="assets/badges/special/subscriber-white-1x.png"
          alt=""
        />
        <div class="text">Suscriptor</div>
      </div>
      <div
        class="badge-container creator"
        *ngIf="isCreator"
        [ngClass]="{ emitting: user.emitting }"
      >
        <img
          handleError
          src="assets/badges/special/creator-white-1x.png"
          alt=""
        />
        <div class="text">Anfitrión</div>
      </div>

      <!-- Stream buttons -->
      <div
        class="stream-buttons"
        *ngIf="canInvite || canPing"
        [ngClass]="{ emitting: user.emitting }"
      >
        <!-- Invite -->
        <ion-button
          class="button hand"
          [disabled]="inLiveStreaming()"
          color="darkteal"
          *ngIf="canInvite"
          (click)="inviteUser()"
        >
          <ion-icon src="assets/svg-icon/invite.svg"></ion-icon>

          Invitar al directo
        </ion-button>

        <!-- DM -->
        <ion-button
          class="button headset"
          color="darkteal"
          *ngIf="canPing"
          (click)="pingUser()"
        >
          <ion-icon src="assets/svg-icon/headset.svg"></ion-icon>
          Pinganillo
        </ion-button>
      </div>

      <!-- User statistics -->
      <ion-grid class="separation-profile" *ngIf="!userBlocked">
        <ion-row class="ion-justify-content-center">
          <ion-col
            size="4"
            class="ion-text-center"
            (click)="triggerFollowers()"
          >
            <div class="estrimapp-number-profile">
              {{
                user && user.numFollowers
                  ? (user.numFollowers | thousandsK)
                  : (0 | thousandsK)
              }}
            </div>
            <div class="estrimapp-list-info">Seguidores</div>
          </ion-col>
          <ion-col size="4" class="ion-text-center" (click)="triggerFollowed()">
            <div class="estrimapp-number-profile">
              {{
                user && user.numFollowed
                  ? (user.numFollowed | thousandsK)
                  : (0 | thousandsK)
              }}
            </div>
            <!-- <div class="estrimapp-list-info">{{followed && getNumFollowed() === 1 ? 'Seguido' : 'Seguidos'}}</div> -->
            <div class="estrimapp-list-info">Siguiendo</div>
          </ion-col>
          <ion-col
            *ngIf="user && user?.numCollaborators > 0"
            size="4"
            class="ion-text-center"
            (click)="triggerCollaborators()"
          >
            <div class="estrimapp-number-profile">
              {{ user.numCollaborators | thousandsK }}
            </div>
            <div class="estrimapp-list-info">Colaboradores</div>
          </ion-col>
        </ion-row>
      </ion-grid>

      <!-- Biography -->
      <div class="biography" *ngIf="!userBlocked && user.biography">
        <div class="biography-summary">
          <app-line-clamp [text]="user.biography" />
        </div>
      </div>

      <!-- Action buttons -->
      <div
        class="ion-margin-vertical display-flex profile-buttons"
        *ngIf="!itsMe"
      >
        <!-- Follow -->
        <ion-button
          class="profile-action-button button follow-button"
          *ngIf="!userBlocked"
          [ngClass]="{ large: !user.subscriptionsActivated }"
          (click)="followButtonClick()"
          [color]="userFollowingStatus() ? 'light' : 'darkteal'"
        >
          {{ userFollowingStatus() ? "Siguiendo" : "Seguir" }}
        </ion-button>

        <!-- Subscribe -->
        <ion-button
          class="profile-action-button button"
          *ngIf="user.subscriptionsActivated && !userBlocked"
          [color]="user.subscribed ? 'light' : 'darkteal'"
          (click)="subscribeButtonClick()"
        >
          {{ user.subscribed ? "Suscrito" : "Suscribirse" }}
        </ion-button>

        <div class="aux">
          <!-- Collaborate -->
          <ion-button
            class="profile-action-button icon collaboration-btn"
            *ngIf="!userBlocked && user?.collaborationsActivated"
            fill="clear"
            (click)="collaboratorButtonClick()"
            [ngClass]="{
              accepted:
                user.collaborating === 1,
              pending:
                user.collaborationRequests && user.collaborating !== 1
            }"
          >
            <ion-icon src="assets/svg-icon/stick-star.svg"></ion-icon>
          </ion-button>

          <!-- Chat -->
          <ion-button
            class="profile-action-button icon"
            *ngIf="!userBlocked && userValidated"
            fill="clear"
            [disabled]="!user.privateMessages"
            (click)="this.closed.emit()"
            routerLink="/messages/chat-with/{{ this.user.username }}"
          >
            <ion-icon src="assets/svg-icon/mail-s.svg"></ion-icon>
          </ion-button>
        </div>
      </div>
      <div *ngIf="showSuggestions && recommendedUserList.length">
        <div class="scroll-element-list">
          <app-user-recommendation-thumbnail
            class="element user"
            *ngFor="let user of recommendedUserList"
            [user]="user"
            [removeButton]="false"
            [ngStyle]="{ 'max-width': '160px' }"
          >
          </app-user-recommendation-thumbnail>
        </div>
      </div>

      <!-- Mensaje de usuario bloqueado -->
      <span class="no-data" *ngIf="userBlocked"> Usuario bloqueado </span>

      <!-- Desbloquear usuario -->
      <div class="unlockUser" *ngIf="!reduced && userBlocked">
        <ion-button class="unlock" color="darkteal" (click)="unlockUserClick()"
          >Desbloquear</ion-button
        >
      </div>

      <!-- Go to profile -->
      <div class="goToProfile" *ngIf="reduced && !itsMe">
        <ion-button
          class="goTo with-star"
          color="light"
          [ngClass]="{ 'with-star': canSendStars }"
          (click)="closed.emit()"
          routerLink="/user/{{ user.username }}"
        >
          Ir al perfil
        </ion-button>

        <div>
          <ion-button
            class="star"
            *ngIf="canSendStars"
            (click)="sendStars.emit()"
          >
            <img handleError src="/assets/star-l.png" />
          </ion-button>

          <ion-button class="settings" fill="clear" (click)="activateStep(1)">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.12 45.53">
              <g id="Layer_2" data-name="Layer 2">
                <g id="Capa_1" data-name="Capa 1">
                  <g id="More">
                    <circle cx="5.06" cy="40.47" r="5.06" />
                    <circle cx="5.06" cy="22.76" r="5.06" />
                    <circle cx="5.06" cy="5.06" r="5.06" />
                  </g>
                </g>
              </g>
            </svg>
          </ion-button>
        </div>
      </div>
    </div>

    <div
      #step
      class="step"
      *ngIf="settingsSlideStep === 1"
      [@inOutAnimation]="animationDirection"
      [@.disabled]="!viewInited"
    >
      <div class="buttons">

        <ion-button fill="clear" *ngIf="canSilent" (click)="silentUserClick()">
          Silenciar
        </ion-button>

        <ion-button fill="clear" *ngIf="canKick" (click)="activateStep(2)"
          >Expulsar</ion-button
        >
        <!-- <ion-button fill="clear">Silenciar</ion-button> -->
        <ion-button fill="clear" *ngIf="!userBlocked" (click)="blockUserClick()"
          >Bloquear</ion-button
        >
        <ion-button fill="clear" *ngIf="userBlocked" (click)="unlockUserClick()"
          >Desbloquear</ion-button
        >
        <ion-button fill="clear" (click)="report()">Denunciar</ion-button>
      </div>

      <!-- Go to profile -->
      <div class="goToProfile" *ngIf="reduced && !itsMe">
        <ion-button fill="clear" class="back" (click)="activateStep(0)">
          <ion-icon slot="icon-only" name="chevron-back"></ion-icon>
        </ion-button>
        <ion-button
          class="goTo"
          color="light"
          [routerLink]="getRouteGoToProfile()"
          >Ir al perfil
        </ion-button>
      </div>
    </div>

    <div
      #step
      class="step"
      *ngIf="settingsSlideStep === 2"
      [@inOutAnimation]="animationDirection"
      [@.disabled]="!viewInited"
    >
      <div class="buttons">
        <!-- <ion-button fill="clear" (click)="activateStep(3, true)">Borrar todos los mensajes</ion-button>
        <ion-button fill="clear" (click)="activateStep(3, false)">No borrar mensajes</ion-button> -->
        <ion-button fill="clear" (click)="activateStep(3); kickConfig(true)"
          >Borrar todos los mensajes</ion-button
        >
        <ion-button fill="clear" (click)="activateStep(3); kickConfig(false)"
          >No borrar mensajes</ion-button
        >
      </div>

      <!-- Go to profile -->
      <div class="goToProfile" *ngIf="reduced && !itsMe">
        <ion-button fill="clear" class="back" (click)="activateStep(1)">
          <ion-icon slot="icon-only" name="chevron-back"></ion-icon>
        </ion-button>
        <ion-button
          class="goTo"
          color="light"
          [routerLink]="getRouteGoToProfile()"
          >Ir al perfil
        </ion-button>
      </div>
    </div>

    <div
      #step
      class="step"
      *ngIf="settingsSlideStep === 3"
      [@inOutAnimation]="animationDirection"
      [@.disabled]="!viewInited"
    >
      <div class="buttons">
        <!-- <ion-button fill="clear" (click)="activateStep(3, true)">Borrar todos los mensajes</ion-button>
        <ion-button fill="clear" (click)="activateStep(3, false)">No borrar mensajes</ion-button> -->
        <ion-button fill="clear" (click)="kickUser(1)">15 minutos</ion-button>
        <ion-button fill="clear" (click)="kickUser(2)">1 hora</ion-button>
        <ion-button fill="clear" (click)="kickUser(3)">4 horas</ion-button>
        <ion-button fill="clear" (click)="kickUser(4)">24 horas</ion-button>
      </div>

      <!-- Go to profile -->
      <div class="goToProfile" *ngIf="reduced && !itsMe">
        <ion-button fill="clear" class="back" (click)="activateStep(2)">
          <ion-icon slot="icon-only" name="chevron-back"></ion-icon>
        </ion-button>
        <ion-button
          class="goTo"
          color="light"
          [routerLink]="getRouteGoToProfile()"
          >Ir al perfil
        </ion-button>
      </div>
    </div>

    <!--     <div #step class="step" *ngIf="settingsSlideStep === 3" [@inOutAnimation]="animationDirection"
             [@.disabled]="!viewInited">
          <div class="buttons">
            <ion-button fill="clear" (click)="kickUser(0)">Solo expulsar</ion-button>
            <ion-button fill="clear" (click)="kickUser(30)">Expulsar 30 min</ion-button>
          </div>

          <div class="goToProfile" *ngIf="reduced && !itsMe">
            <ion-button fill="clear" class="back" (click)="activateStep(2)">
              <ion-icon slot="icon-only" name="chevron-back"></ion-icon>
            </ion-button>
            <ion-button class="goTo" color="light" routerLink="/user/{{user.username}}">Ir al perfil
            </ion-button>
          </div>
        </div> -->
  </div>
</div>
<!-- User data SKELETON-->
<div class="user-data" *ngIf="skeleton" [ngClass]="{ reduced: reduced }">
  <!-- User -->
  <div class="display-flex username-row">
    <ion-row class="first-profile-line">
      <div class="username-profile">
        <span class="username">
          <ion-skeleton-text
            [animated]="true"
            style="width: 100px; --border-radius: 5px"
          ></ion-skeleton-text>
        </span>
      </div>
      <span class="reputation-span">
        <span class="number-reputation">
          <ion-skeleton-text
            [animated]="true"
            style="width: 50px; --border-radius: 5px"
          ></ion-skeleton-text>
        </span>
      </span>
    </ion-row>
    <ion-row class="second-profile-line">
      <h5 class="name-profile">
        <ion-skeleton-text
          [animated]="true"
          style="width: 70px; --border-radius: 5px"
        ></ion-skeleton-text>
      </h5>
    </ion-row>
  </div>

  <!-- Steps -->
  <div class="steps" [ngStyle]="{ height: stepsContainerHeight + 'px' }">
    <div #step class="step">
      <!-- User statistics -->
      <ion-grid class="separation-profile">
        <ion-row class="ion-justify-content-center">
          <ion-col size="4" class="ion-text-center">
            <div class="estrimapp-list-info">
              <ion-skeleton-text
                [animated]="true"
                style="width: 70px; margin: 0 auto; --border-radius: 5px"
              ></ion-skeleton-text>
            </div>
          </ion-col>
          <ion-col size="4" class="ion-text-center">
            <div class="estrimapp-list-info">
              <ion-skeleton-text
                [animated]="true"
                style="width: 70px; margin: 0 auto; --border-radius: 5px"
              ></ion-skeleton-text>
            </div>
          </ion-col>
          <ion-col size="4" class="ion-text-center">
            <div class="estrimapp-list-info">
              <ion-skeleton-text
                [animated]="true"
                style="width: 70px; margin: 0 auto; --border-radius: 5px"
              ></ion-skeleton-text>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>

      <!-- Biography -->
      <div class="biography">
        <div class="biography-summary">
          <span>
            <ion-skeleton-text
              [animated]="true"
              style="width: 100%; --border-radius: 5px"
            ></ion-skeleton-text>
            <ion-skeleton-text
              [animated]="true"
              style="width: 80%; --border-radius: 5px"
            ></ion-skeleton-text>
          </span>
        </div>
      </div>

      <!-- Action buttons -->
      <div
        class="ion-margin-vertical display-flex profile-buttons"
        *ngIf="!itsMe"
      >
        <!-- Follow -->
        <ion-button class="profile-action-button button" color="light">
          <ion-skeleton-text
            [animated]="true"
            style="width: 80%; --border-radius: 5px"
          ></ion-skeleton-text>
        </ion-button>

        <!-- Subscribe -->
        <ion-button class="profile-action-button button" color="light">
          <ion-skeleton-text
            [animated]="true"
            style="width: 80%; --border-radius: 5px"
          ></ion-skeleton-text>
        </ion-button>

        <div class="aux">
          <!-- Collaborate -->
          <ion-button
            class="profile-action-button icon collaboration-btn"
            fill="clear"
          >
            <ion-skeleton-text
              [animated]="true"
              style="--border-radius: 5px"
            ></ion-skeleton-text>
          </ion-button>

          <!-- Chat -->
          <ion-button class="profile-action-button icon" fill="clear">
            <ion-skeleton-text
              [animated]="true"
              style="--border-radius: 5px"
            ></ion-skeleton-text>
          </ion-button>
        </div>
      </div>
    </div>
  </div>
</div>
