import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "img[handleError], ion-img[handleError]",
})
export class ImgErrorDirective {

  constructor(private element: ElementRef) {
  }

  @HostListener("error")
  @HostListener("ionError")
  private onError() {
    this.element.nativeElement.src = "assets/no-image.jpg";
  }
}