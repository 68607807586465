<ion-header class="general-header-app" *ngIf="headerEnabled">
  <app-header 
    [menuButton]="false" 
    [title]="'Comentarios'" 
    [searchbar]="false"
    [defaultBackAction]="false"
    (back)="back()"
  >
  </app-header>
</ion-header>

<ion-content 
  [fullscreen]="true"
  [ngClass]="{'withoutHeader': !headerEnabled}">
  <div class="comments">
    <ng-container *ngIf="!loading">
      <app-comment
        *ngFor="let comment of comments"
        [comment]="comment"
        [user]="comment.authorUser"
        [replies]="replies[comment.id]"
        [isCreator]="userIsCreator(comment.authorUser.id)"
        [isCollaborator]="userIsCollaborator(comment.authorUser.id)"
        [isSubscribed]="userIsSubscribed(comment.authorUser.id)"
        (reply)="triggerReplyComment($event, comment)"
        (reducedProfile)="triggerShowProfile($event)"
        (longPressed)="commentLongPress(comment)"
      >
      </app-comment>
    </ng-container>

    <ng-container *ngIf="loading">
      <app-comment [skeleton]="true"></app-comment>
      <app-comment [skeleton]="true"></app-comment>
      <app-comment [skeleton]="true"></app-comment>
      <app-comment [skeleton]="true"></app-comment>
      <app-comment [skeleton]="true"></app-comment>
      <app-comment [skeleton]="true"></app-comment>
      <app-comment [skeleton]="true"></app-comment>
    </ng-container>

    <span class="no-data" *ngIf="!loading && (!comments || !comments.length)">Aún no se han publicado comentarios</span>
  </div>

  <ion-infinite-scroll 
    #infiniteScroll
    threshold="100px"
    (ionInfinite)="loadComments()"
  >
    <ion-infinite-scroll-content 
      loadingSpinner="crescent" 
      loadingText=""
    >
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>

  <!-- Input -->
  <div #inputContainer
    class="input-container"
    [ngClass]="{
      'focused': inputFocusStatus
    }"
    [ngStyle]="{
      'padding-bottom': getInputContainerPaddingBottom(),
      'bottom': inputFocusStatus ? keyboardHeight + 'px' : '0px'
    }"
  >
    <div class="reply-content"
      [ngStyle]="{'bottom': 'calc(45px + ' + getInputContainerPaddingBottom() + ')'}"
    >
      <div class="replying-to" *ngIf="replyingComment" @inOutFromBottomFade>
        <span
          >Respondiendo a
          <b>{{replyingComment.authorUser.username}}</b></span
        >
        <ion-button fill="clear" (click)="cancelReplyComment()">
          <ion-icon name="close-outline"></ion-icon>
        </ion-button>
      </div>
    </div>

    <div class="input">
      <ion-textarea
        #textarea
        id="myMessage"
        rows="1"
        auto-grow="true"
        autocapitalize="on"
        type="text"
        color="dark"
        maxlength="3000"
        [(ngModel)]="currentInputText"
        placeholder="Escribe algo..."
        (ionFocus)="inputFocusChange(true)"
        (ionBlur)="inputFocusChange(false)"
        (keyup)="send($event)"
      >
      </ion-textarea>

      <!-- Send -->
      <div class="button">
        <div
          class="activity-indicator"
          *ngIf="sending"
          @inOutFade
        >
          <ion-spinner color="darkteal" name="crescent"></ion-spinner>
        </div>

        <ion-button
          fill="clear"
          (click)="send()"
          class="send no-ripple"
          *ngIf="inputTextDirty() || !canSendStars"
          @inOutSendButton
        >
          <img handleError src="/assets/send-button.png" />
        </ion-button>

        <ion-button
          fill="clear"
          class="star"
          (click)="showStarsModal()"
          *ngIf="!inputTextDirty() && canSendStars"
          @inOutStarBottom
        >
          <img handleError src="/assets/star-l.png" />
        </ion-button>

        <ion-modal
          class="inline-modal stars-modal"
          [isOpen]="showStars"
          [initialBreakpoint]="1"
          [breakpoints]="[0, 1]"
          (didDismiss)="starsModalDismiss()"
          handleBehavior="cycle"
        >
          <ng-template>
            <app-send-stars
              [user]="publication.author"
              [userId]="publication.author.id"
              origin="publication"
              [idOrigin]="publication.id"
              [subText]="publication.author.id === publication.author.id"
              (close)="starsModalDismiss()"
              (sent)="onStarsSent($event)"
            >
            </app-send-stars>
          </ng-template>
        </ion-modal>
      </div>
    </div>
  </div>

  <div
    class="keyboard-space"
    [ngStyle]="{'height': keyboardHeight + 'px'}"
  >
  </div>
</ion-content>
