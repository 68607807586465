<ion-content [scrollY]="false">
  <ion-list lines="none">
    <ion-item button *ngIf="!blocked && !itsMe" detail="false"
              (click)="pickOption(BlockPopoverOption.Block)">
      <ion-label>{{blockLabelText}}</ion-label>
      <ion-icon slot="end" name="remove-circle-outline"></ion-icon>
    </ion-item>
    <ion-item button *ngIf="blocked && !itsMe" detail="false"
              (click)="pickOption(BlockPopoverOption.Unlock)">
      <ion-label>Desbloquear</ion-label>
      <ion-icon slot="end" name="lock-open-outline"></ion-icon>
    </ion-item>
    <ion-item button detail="false" *ngIf="reportable && !itsMe"
              (click)="pickOption(BlockPopoverOption.Report)">
      <ion-label color="danger">Denunciar</ion-label>
      <ion-icon slot="end" color="danger" name="warning-outline"></ion-icon>
    </ion-item>
    <ion-item button detail="false" *ngIf="itsMe && editable" (click)="pickOption(BlockPopoverOption.Edit)">
      <ion-label>Editar</ion-label>
      <ion-icon slot="end" name="create-outline"></ion-icon>
    </ion-item>
    <ion-item button detail="false" *ngIf="itsMe && removable" (click)="pickOption(BlockPopoverOption.Delete)">
      <ion-label color="danger">Eliminar</ion-label>
      <ion-icon slot="end" color="danger" name="trash-outline"></ion-icon>
    </ion-item>
  </ion-list>
</ion-content>
