import { Component, ChangeDetectorRef } from '@angular/core';
import { Platform, ModalController, NavController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

import { BaseComponent } from '../base.component';

import { AuthService } from '../../../services/auth.service';
import { Events } from '../../../services/events.service';
import { AnalyticsService } from '../../../services/analytics.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.page.html',
  styleUrls: ['./redirect.page.scss', '../base.component.scss'],
})
export class RedirectPage extends BaseComponent {

  isHybrid = this.platform.is('hybrid');
  isIos = this.platform.is('ios');
  isAndroid = this.platform.is('android');

  mobileUrl: string = `${environment.BASE_URL}/app-download`;

  constructor(
    public platform: Platform,
    public modalCtrl: ModalController,
    public navCtrl: NavController,
    public authService: AuthService,
    public events: Events,
    public cdRef: ChangeDetectorRef,
    public analyticsService: AnalyticsService
  ) {
    super(platform, modalCtrl, navCtrl, cdRef, authService, events, analyticsService);
  }

  ionViewDidEnter() {
    this.analyticsService.setScreenName('register_redirect');
  }

  openAppleStore() {
    window.open("https://apps.apple.com/es/app/estrim/id1622628431?l=es","_system");
  }
  openGoogleStore() {
    window.open("https://play.google.com/store/apps/details?id=com.estrim.app&hl=es","_system");
  }
}
