import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Platform, ModalController, NavController, IonButton } from '@ionic/angular';

import { BaseComponent } from '../base.component';

import { ProfilePage } from '../profile/profile.page';

import { ValidatorService } from '../../../services/validator.service';
import { AuthService } from '../../../services/auth.service';
import { Events } from '../../../services/events.service';
import { AnalyticsService } from '../../../services/analytics.service';

import { RegisterType } from '../../../enums/register-type';

@Component({
  selector: 'app-form',
  templateUrl: './form.page.html',
  styleUrls: ['./form.page.scss', '../base.component.scss', '../../../../theme/field.scss'],
})
export class FormPage extends BaseComponent {

  @ViewChild('birthdateButton', {static: false}) birthdateButton: IonButton;

  @Input() registerType: RegisterType = RegisterType.Code;
  @Input() invitation: any | undefined;

  RegisterType = RegisterType;

  genders: any = [
    { code: 0, name: 'Masculino' },
    { code: 1, name: 'Femenino' },
    { code: 2, name: 'Indeterminado' }
  ];

  checkingUsername: boolean = false;

  username: string = '';
  password: string = '';
  repeatPassword: string = '';
  email: string = '';
  birthdate: string = '';
  gender: number | undefined = undefined;

  errorMessage: any = {};

  checkUsernameTimeout;

  constructor( 
    public platform: Platform,
    public modalCtrl: ModalController,
    public navCtrl: NavController,
    public cdRef: ChangeDetectorRef,
    public authService: AuthService,
    public events: Events,
    private validatorService: ValidatorService,
    public analyticsService: AnalyticsService
  ) {
    super(platform, modalCtrl, navCtrl, cdRef, authService, events, analyticsService);

    // Check if register restore point is setted
    this.authService.getRegisterState().then(data => {
      if ( data !== undefined && data.form ) {
        this.registerType = data.registerType;
        this.invitation = data.invitation;

        this.username = data.form.username;
        this.password = data.form.password;
        this.repeatPassword = data.form.repeatPassword;
        this.email = data.form.email;
        this.birthdate = data.form.birthdate;
        this.gender = data.form.gender;
        this.phone = data.form.phone;
        this.selectedCountry = data.form.country;

        this.submit();
      } else {
        this.authService.deleteRegisterState();
      }
    })
  }

  ionViewDidEnter() {
    this.analyticsService.setScreenName('register_form');
  }

  /**
   * Show up date modal for birthday
   */
  triggerBirthdateDatetime() {
    // @ts-ignore
    this.birthdateButton.el.click();
  }

  /**
   * Returns parsed birthdate string
   */
  getParsedBirthdate() {
    return this.birthdate.replace(' ', 'T');
  }

  /**
   * Sets birthdate value and check minimum age
   * {CustomEvent} $event
   */
  onBirthdateSet($event) {
    this.birthdate = $event.detail.value;

    if ( !this.ageValid() ) {
      this.error.minAge = true;
    } else {
      this.error.minAge = false;
    }
  }

  /**
   * Sets gender value
   * {CustomEvent} $event
   */
  onGenderSet($event) {
    this.gender = $event.detail.value;
  }

  /**
   * Check if inserted username already exists
   * {CustomEvent} $event
   */
  checkUsernameExists($event) {
    if(!$event.target.value) {
      return;
    }

    $event.target.value = $event.target.value.toLowerCase().replace(/((?![a-z]|[0-9]|\.|\_).)/g, "");
    this.username = $event.target.value;
    this.cdRef.detectChanges();

    clearTimeout(this.checkUsernameTimeout);
    this.checkUsernameTimeout = setTimeout(() => {
      this.checkingUsername = true;

      this.validatorService.checkUsernameExists(this.username).subscribe(
        res => {
          if ( !res.done ) {
            this.error.usernameOther = true;
            this.errorMessage.username = res.msg;
          } else if ( res.exists ) {
            this.error.usernameExists = true;
          }

          this.checkingUsername = false;
        }, err => {
          this.checkingUsername = false;
        }
      );
    }, 700);
  }

  /**
   * On username input change to prevent special characters
   * {KeyboardEvent} $event
   */
  usernameChangedKeydown($event) {
    if ( !this.platform.is('android') ) {
      console.log('NOT ANDROID');
      if ( !$event.key.toLowerCase().match(/[a-z]|[0-9]|\.|\_/g) ) {
        $event.preventDefault();
      }
    } 
  }

  /**
   * Check if age is valid
   * @returns if is valid
   */
  ageValid(): boolean {
    const currentDate = new Date();
    const birthdate = new Date(this.birthdate);
    const ageDifMs = currentDate.getTime() - birthdate.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    const years = Math.abs(ageDate.getUTCFullYear() - 1970);

    if (years >= 18) {
      return true;
    } else {
      return false;
    }
  }
  
  /**
   * Check if phone is valid
   * @returns if is valid
   */
  phoneValid(): boolean {
    if ( this.selectedCountry.code === '34' ) {
      return this.phone.trim().match(this.mobilePhoneESRegex) !== null;
    } else {
      return this.phone.trim().match(this.phoneGlobalRegex) !== null;
    }
  }

  /**
   * Check if email is valid
   * @returns if is valid
   */
  emailValid(): boolean {
    return this.email.trim().match(this.emailRegex) !== null;
  }

  /**
   * Check if email exists
   * @returns if exists
   */
  async emailExists(): Promise<boolean> {
    try {
      const result = await this.validatorService.checkEmailExists(this.email).toPromise();
      
      // @ts-ignore
      return result?.exists;
    } catch (e) {
      return true;
    }
  }

  /**
   * Check if phone exists
   * @returns if exists
   */
  async phoneExists(): Promise<boolean> {
    try {
      const result = await this.validatorService.checkPhoneExists(this.phone, this.selectedCountry.code).toPromise();
      
      if ( result?.exists ) {
        this.errorMessage.phoneExists = 'El teléfono ya existe';
      } else if ( !result?.is_valid ) {
        this.errorMessage.phoneExists = result.msg;
      }

      // @ts-ignore
      return result?.exists;
    } catch (e) {
      if ( e.error.msg ) {
        this.errorMessage.phoneExists = e.error.msg;
      }

      return true;
    }
  }

  /**
   * Check all form is valid
   * @returns if is valid
   */
  async formValid(): Promise<boolean> {
    let status = true;

    if (this.username === undefined || this.username.trim() === '') {
      this.error.username = true;
      status = false;
    } else if ( this.error.usernameExists || this.error.usernameOther ) {
      status = false;
    }

    if (this.password === undefined || this.password.trim() === '') {
      this.error.password = true;
      status = false;
    } else if ( this.password.trim().length < 8 ) {
      this.error.passLength = true;
      status = false;
    } 

    if ( this.password !== this.repeatPassword ) {
      this.error.repeatPassword = true;
      status = false;
    }

    if (this.email === undefined || this.email.trim() === '') {
      this.error.email = true;
      status = false;
    } else if ( !this.emailValid() ) {
      this.error.emailInvalid = true;
      status = false;
    } else if ( await this.emailExists() ) {
      this.error.emailExists = true;
      status = false;
    }

    if (this.birthdate === undefined || this.birthdate.trim() === '') {
      this.error.birthdate = true;
      status = false;
    } else if ( !this.ageValid() ) {
      this.error.minAge = true;
      status = false;
    }

    if (this.gender === undefined ) {
      this.error.gender = true;
      status = false;
    }

    if ( this.registerType === RegisterType.Code ) {
      if (this.phone === undefined || this.phone.trim() === '') {
        this.error.phone = true;
        status = false;
      } else if ( !this.phoneValid() ) {
        this.error.phoneInvalid = true;
        status = false;
      } else if ( await this.phoneExists() ) {
        this.error.phoneExists = true;
        status = false;
      } else {
        this.error.phone = false;
        this.error.phoneInvalid = false;
        this.error.phoneExists = false;
      }
    }

    return status;
  }

  /**
   * Submit form, check if is valid and navigates to profile page
   */
  async submit() {
    this.loading = true;

    if ( !(await this.formValid()) ) {
      setTimeout(() => {
        this.loading = false;
      }, 500);

      return ; // BREAK EXECUTION
    }

    this.loading = false;

    this.goToPage(ProfilePage, 
      { 
        registerType: this.registerType,
        invitation: this.invitation,
        form: {
          username: this.username,
          password: this.password,
          repeatPassword: this.repeatPassword,
          email: this.email,
          birthdate: this.birthdate,
          gender: this.gender,
          phone: this.registerType === RegisterType.Code ? this.phone : undefined,
          country: this.registerType === RegisterType.Code ? this.selectedCountry : undefined
        }
      }
    );
  }
}
