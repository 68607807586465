import { Directive, AfterViewInit, OnDestroy, Output, ElementRef, EventEmitter } from '@angular/core';

import { createGesture, GestureDetail } from "@ionic/angular";

@Directive({
  selector: '[double-tap]'
})
export class DoubleTapDirective implements OnDestroy {

  @Output() public dobleTapTrigger: EventEmitter<boolean> = new EventEmitter();

  private DOUBLE_CLICK_THRESHOLD = 500;
  private pressGesture: any;
  private lastOnStart = 0;

  constructor( private element: ElementRef ) {
    this.pressGesture = createGesture({
      gestureName: 'doubleTap',
      el: element.nativeElement,
      threshold: 0,
      onStart: (detail: GestureDetail) => { this.onStart(detail); }
    });

    this.pressGesture.enable();
  }

  ngOnDestroy() {
    this.pressGesture.destroy();
  }

  onStart = (detail: GestureDetail) => {
    const now = Date.now();

    if (Math.abs(now - this.lastOnStart) <= this.DOUBLE_CLICK_THRESHOLD) {
      this.dobleTapTrigger.emit(true);
      this.lastOnStart = 0;
    } else {
      this.lastOnStart = now;
    }
  }
}
