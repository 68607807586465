import { Component, ViewChild, OnInit, Input } from '@angular/core';
import {SwiperOptions} from 'swiper';
import {SwiperComponent} from 'swiper/angular';
import { ModalController } from '@ionic/angular';
import { skipWhile, take } from 'rxjs/operators';

import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/interfaces/user';

@Component({
  selector: 'app-new-badge',
  templateUrl: './new-badge.component.html',
  styleUrls: ['./new-badge.component.scss'],
})
export class NewBadgeComponent implements OnInit {

  @Input() level: number;

  @ViewChild('swiper', { static: true }) swiper: SwiperComponent;

  user: User;

  swiperParams: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 30,
    simulateTouch: false,
    //pagination: {clickable: true},
    pagination: {
      el: '.swiper-pagination',
    },
  };

  public levelColor: string;

  constructor(
    private authService: AuthService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    if ( !this.user ) {
      this.authService.watchUser()
      .pipe(
        skipWhile(data => !data),
        take(1))
      .subscribe((user: User) => {
        this.user = user;
      });
    }

    this.getLevelColor();
  }

  nextSlide() {
    this.swiper.swiperRef.slideNext();
  }

  getLevelColor() {
    switch (this.level) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        this.levelColor = '#d47c42';
        break;
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        this.levelColor = '#7a7b7e';
        break;
      case 11:
      case 12:
      case 13:
      case 14:
      case 15:
        this.levelColor = '#b48133';
        break;
      case 16:
      case 17:
      case 18:
      case 19:
      case 20:
        this.levelColor = '#ff4040';
        break;
  }
}
  close() {
    this.modalCtrl.dismiss();
  }
}
