<div class="content">
  <div class="close" (click)="dismiss()">
    <ion-icon name="close-outline"></ion-icon>
  </div>

  <div class="title">
    Retirar fondos
  </div>

  <!-- Info -->
  <div class="info">
    <div class="item">
      <img class="icon coin" src="assets/coin.png" />

      <div class="text">
        <div class="description">
          Para retirar fondos, ponte en contacto con nosotros en el siguiente correo electrónico: 
        </div>
      </div>

      <a class="email-link" href="mailto:payments@estrim.app">payments@estrim.app</a>
    </div>
  </div>
</div>